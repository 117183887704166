import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import WheelChair from "../../../../images/avatar/Wheelchair.png";
import PaginationButtons from "../../../Custom/Pagination";
import { post } from "../../../Helper/api_helper";
import {
  POST_GetEquipmentsList,
  POST_EquipmentsSetActiveInActive,
  POST_EquipmentsDelete,
} from "../../../Helper/url_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
const EquipmentsList = () => {
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [equipmentsList, setEquipmentsList] = useState([]);

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const handleOnChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getData();
  }, [search]);

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_GetEquipmentsList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: search,
      Sorting: "",
      LoginID: objLogin.LoginIDEncrypt,
    });
    setIsLoading(false);
    setRecordCount(response.TotalRecordCount);
    setEquipmentsList(response.EquipmentsList);
    //setCurrentPage(page);
    setCurrentSizePerPage(sizePerPage);
    if (page > 1 && response?.EquipmentsList?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  const delete_Equipment = (eID) => {
    swal({
      title: "Are you sure you want to delete the equipment?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_EquipmentConfirm(eID);
      }
    });
  };

  const delete_EquipmentConfirm = async (eID) => {
    setIsLoading(true);
    let response = await post(POST_EquipmentsDelete, {
      EncryptedEquipmentID: eID,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_Equipment = async (eID, eStatus) => {
    swal({
      title: `Are you sure you want to ${
        eStatus ? "inactive" : "active"
      } the equipment?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_EquipmentConfirm(eID, eStatus);
      }
    });
  };

  const ActiveInactive_EquipmentConfirm = async (eID, eStatus) => {
    setIsLoading(true);
    let response = await post(POST_EquipmentsSetActiveInActive, {
      EncryptedEquipmentID: eID,
      IsActive: !eStatus,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: eStatus ? "Inactivated" : "Activated",
        text: eStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Equipments" />

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 9 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <Link
                  to="/add-equipments"
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add Equipment
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                value={search}
                onChange={handleOnChangeSearch}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="equipment_list"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Image: activate to sort column ascending"
                          style={{ width: "5%" }}
                        ></th> */}

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff No.: activate to sort column ascending"
                          style={{ width: "40%" }}
                        >
                          Equipment Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Name: activate to sort column ascending"
                          style={{ width: "22%" }}
                        >
                          Equipment Type
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Total Qty: activate to sort column ascending"
                          style={{ width: "13%", textAlign: "center" }}
                        >
                          Total Qty
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Available Qty: activate to sort column ascending"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Available Qty
                        </th>
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Is Rent
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Is Sale
                        </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>

                    {recordCount > 0 ? (
                      <tbody>
                        {equipmentsList?.map((element, i) => (
                          <tr role="row" className="odd">
                            {/* <td>
                            <img
                              alt="Staff"
                              width="45"
                              src={WheelChair}
                              className="rounded-circle"
                            />
                          </td> */}
                            <td>{element.EquipmentName}</td>
                            <td>{element.EquipmentTypeValue}</td>
                            <td style={{ textAlign: "center" }}>
                              {element.TotalQty}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.AvailableQty}
                            </td>
                            {/* <td style={{ textAlign: "center" }}>
                              {element.IsRentValue}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.IsSaleValue}
                            </td> */}
                            <td>
                              <div className="d-flex">
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 9 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <Link
                                      to={`/edit-equipments/${window.btoa(
                                        element.EquipmentIDEncrypt
                                      )}`}
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                      title={
                                        element.IsActive ? "Active" : "Inactive"
                                      }
                                      onClick={() => {
                                        ActiveInactive_Equipment(
                                          element.EquipmentIDEncrypt,
                                          element.IsActive
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle mt-1"
                                        style={{
                                          color: element.IsActive
                                            ? "green"
                                            : "red",
                                        }}
                                      ></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 9 && item.DeleteAction
                                  ).length > 0) ? (
                                  <Link
                                    to="#"
                                    className="btn btn-light shadow btn-xs sharp"
                                    title="Delete"
                                    onClick={() => {
                                      delete_Equipment(
                                        element.EquipmentIDEncrypt
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="mt-3 ml-3">{"No Record Found"}</div>
                    )}
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default EquipmentsList;
