import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik, FieldArray } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Button } from "react-bootstrap";
import defaultIconBanner from "../../../../images/avatar/1024x330.png";
import defaultIcon from "../../../../images/avatar/500x500.png";
import { Label } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import {
  POST_GetPackageDetailByID,
  POST_PackageInsert,
  POST_PackageUpdate,
  POST_PackageUpdateIcon,
  POST_GetServiceDDLList,
  POST_AdminEquipmentsListDDLByEquipmentsTypeID,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

const AddPackage = () => {
  const { PackageIDEncryptedParam } = useParams();
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);

  const [optionService, setOptionService] = useState([]);
  const [optionEquipment, setOptionEquipment] = useState([]);

  const [icon, setIcon] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [fileKeyIcon, setFileKeyIcon] = useState();
  const [iconPathx, setIconPathx] = useState("");
  const [validationIcon, setValidationIcon] = useState("");

  const [banner, setBanner] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [fileKeyBanner, setFileKeyBanner] = useState();
  const [bannerPathx, setBannerPathx] = useState("");
  const [validationBanner, setValidationBanner] = useState("");

  const validationSchema = Yup.object().shape({
    PackageName: Yup.string()
      .nullable()
      .required("Package Name is required field"),
    PackageDurationinDays: Yup.string()
      .nullable()
      .required("Package Duration is required field"),
    AdvancePercentage: Yup.number()
      .nullable()
      .min(1, "Package Advance % must be greater than 0.")
      .required("Package Advance % is required field"),
  });

  const initListOfPackageInclusionServices = {
    ServiceIDEncrypted: "",
    ServiceQty: "",
  };

  const initListOfPackageInclusionEquipments = {
    EquipmentIDEncrypt: "",
    EquipmentQty: "",
  };

  const initListOfPackageDescription = {
    Description: "",
  };

  const formikPackage = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitPackage(values, formikHelpers),
    initialValues: {
      PackageName: "",
      PackageDurationinDays: "",
      AdvancePercentage: 50,
      ListOfDescriptions: [initListOfPackageDescription],
      ListOfInclusionServices: [initListOfPackageInclusionServices],
      ListOfInclusionEquipments: [initListOfPackageInclusionEquipments],
    },
    validateOnBlur: false,
  });
  const {
    setFieldValue,
    setValues,
    values: {
      ListOfInclusionServices,
      ListOfInclusionEquipments,
      ListOfDescriptions,
    },
  } = formikPackage;

  useEffect(() => {
    getServiceList();
    getEquipmentList();

    if (PackageIDEncryptedParam) {
      post(POST_GetPackageDetailByID, {
        PackageIDEncrypted: window.atob(PackageIDEncryptedParam),
      }).then((res) => {
        setIsLoading(true);
        if (res.IsSuccess) {
          setValues({
            PackageName: res.PackageDetail.PackageName,
            PackageDurationinDays: res.PackageDetail.PackageDurationInDays,
            AdvancePercentage: res.PackageDetail.AdvancePercentage,
            ListOfDescriptions: res.DescriptionList,
            ListOfInclusionServices: res.ServiceList,
            ListOfInclusionEquipments: res.EquipmentList,
            Status: res.PackageDetail.IsActive,
          });
          setIcon(res.PackageDetail.PackageIcon);
          setBanner(res.PackageDetail.PackageBanner);
        }
        setIsLoading(false);
      });
    }
  }, []);

  const getServiceList = () => {
    setIsLoading(true);
    get(POST_GetServiceDDLList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionService(
            res.ServiceDDLList.map((item) => ({
              label: item.ServiceName,
              value: item.ServiceIDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const getEquipmentList = () => {
    setIsLoading(true);
    post(POST_AdminEquipmentsListDDLByEquipmentsTypeID, {
      EquipmentTypeID: 0,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionEquipment(
            res.AdminEquipmentsListDDLByEquipmentsTypeID.map((item) => ({
              label: item.EquipmentName,
              value: item.EquipmentIDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const uploadIcon = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        setIconFile(null);
        setIcon(null);
        setFileKeyIcon(Math.random().toString(9).substring(2, 9));
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== 500 || width !== 500) {
              swal({
                title: "Error",
                text: "Image size must be of 500x500",
                icon: "error",
                button: "OK",
              });
              setIconFile(null);
              setIcon(null);
            } else {
              setIconFile(file);
              setIconPathx("");
              setIcon(URL.createObjectURL(file));
              setValidationIcon("");
            }
          };
        };
      }
    } else {
      setIconFile(null);
      setIcon(null);
    }
  };
  const uploadBanner = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        setBannerFile(null);
        setBanner(null);
        setFileKeyBanner(Math.random().toString(9).substring(2, 9));
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== 330 || width !== 1024) {
              swal({
                title: "Error",
                text: "Image size must be of 1024x330",
                icon: "error",
                button: "OK",
              });
              setBannerFile(null);
              setBanner(null);
            } else {
              setBannerFile(file);
              setBannerPathx("");
              setBanner(URL.createObjectURL(file));
              setValidationBanner("");
            }
          };
        };
      }
    } else {
      setBannerFile(null);
      setBanner(null);
    }
  };

  const SaveUploadImages = (pID) => {
    if (iconFile || bannerFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("EncryptPackageID", pID);
      formData.append("EncryptCreatedBy", objLogin.LoginIDEncrypt);
      formData.append("Icon", iconFile);
      formData.append("Banner", bannerFile);

      postFormData(POST_PackageUpdateIcon, formData)
        .then((response) => {
          if (response.IsSuccess) {
            setIsLoading(false);
          } else {
            console.log(response);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const handleValidSubmitPackage = (values) => {
    var IsValid = true;

    for (var i = 0; i < ListOfInclusionServices.length; i++) {
      for (var j = 0; j < ListOfInclusionServices.length; j++) {
        if (
          i !== j &&
          ListOfInclusionServices[i].ServiceIDEncrypted ===
            ListOfInclusionServices[j].ServiceIDEncrypted
        ) {
          swal({
            text: "Service has multiple record.",
            icon: "warning",
            button: "OK",
          });
          IsValid = false;
          return;
        }
      }
    }

    for (var i = 0; i < ListOfInclusionEquipments.length; i++) {
      for (var j = 0; j < ListOfInclusionEquipments.length; j++) {
        if (
          i !== j &&
          ListOfInclusionEquipments[i].EquipmentIDEncrypt ===
            ListOfInclusionEquipments[j].EquipmentIDEncrypt
        ) {
          swal({
            text: "Equipment has multiple record.",
            icon: "warning",
            button: "OK",
          });
          IsValid = false;
          return;
        }
      }
    }

    if (!IsValid) {
      return;
    }

    if (
      ListOfDescriptions.filter((x) => x.Description?.trim() == "").length > 0
    ) {
      swal({
        text: "Fill the details of Description or remove it.",
        icon: "warning",
        button: "OK",
      });
      return;
    } else if (
      ListOfInclusionServices.filter(
        (x) => x.ServiceIDEncrypted == "" || x.ServiceQty == ""
      ).length > 0
    ) {
      swal({
        text: "Fill the details of Inclusion Services or remove it.",
        icon: "warning",
        button: "OK",
      });
      return;
    } else if (
      ListOfInclusionEquipments.filter(
        (x) => x.EquipmentIDEncrypt == "" || x.EquipmentQty == ""
      ).length > 0
    ) {
      swal({
        text: "Fill the details of Inclusion Equipments or remove it.",
        icon: "warning",
        button: "OK",
      });
      return;
    } else if (
      ListOfInclusionServices?.length == 0 &&
      ListOfInclusionEquipments?.length == 0
    ) {
      swal({
        text: "Inclusion Services OR Inclusion Equipments is required.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    if (iconFile === null && icon === null) {
      setValidationIcon("Package Icon required");
      setIsLoading(false);
      return false;
    }

    if (bannerFile === null && banner === null) {
      setValidationBanner("Package Banner Required");
      setIsLoading(false);
      return false;
    }

    const data = {
      PackageName: values.PackageName,
      PackageDurationInDays: values.PackageDurationinDays,
      AdvancePercentage: values.AdvancePercentage.toString(),
      ListOfDescription: ListOfDescriptions,
      ListOfService: ListOfInclusionServices,
      ListOfEquipment: ListOfInclusionEquipments,
      EncryptCreatedBy: objLogin.LoginIDEncrypt,
      IsActive: values.Status,
      EncryptModifiedBy: objLogin.LoginIDEncrypt,
      EncryptPackageID: PackageIDEncryptedParam
        ? window.atob(PackageIDEncryptedParam)
        : "",
    };

    setIsLoading(false);
    post(
      PackageIDEncryptedParam ? POST_PackageUpdate : POST_PackageInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          SaveUploadImages(response.EncryptID);

          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formikPackage.resetForm();
          history.push("/packages-list");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={PackageIDEncryptedParam ? "Edit Package" : "Add Package"}
          motherMenu={<Link to="/packages-list">Packages</Link>}
        />

        <div className="card">
          <div className="card-body">
            <div className="basic-form">
              <FormikProvider value={formikPackage}>
                <Form onSubmit={formikPackage.handleSubmit}>
                  <Row>
                    <Col lg={11}>
                      <Row>
                        <Col lg={6}>
                          <Field
                            type="text"
                            label={"Package Name"}
                            name="PackageName"
                            placeholder={"Enter Package Name"}
                            component={CustomInput}
                            required
                            maxLength={200}
                            // onChange={(e) => {
                            //   setFieldValue(
                            //     "PackageName",
                            //     e.target.value.replace(
                            //       /[^A-Za-z 0-9 - / () + ]/g,
                            //       ""
                            //     )
                            //   );
                            // }}
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Package Duration (In Days)"}
                            name="PackageDurationinDays"
                            placeholder={"Enter Package Duration"}
                            component={CustomInput}
                            required
                            min={0}
                            maxLength={3}
                            onChange={(e) => {
                              setFieldValue(
                                "PackageDurationinDays",
                                e.target.value.slice(0, 3)
                              );
                            }}
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Package Advance %"}
                            name="AdvancePercentage"
                            placeholder={"Enter Package Advance %"}
                            component={CustomInput}
                            required
                            min={0}
                            maxLength={5}
                            onChange={(e) => {
                              if (e.target.value <= 100) {
                                setFieldValue(
                                  "AdvancePercentage",
                                  e.target.value.slice(0, 5)
                                );
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <h6 className="mb-3">Package Descriptions</h6>
                  <FieldArray
                    name="ListOfDescriptions"
                    render={(arrayHelpers) => {
                      return (
                        <Fragment>
                          {ListOfDescriptions?.map((DescriptionList, index) => (
                            <div data-repeater-list="group-a" key={index}>
                              <Row>
                                <Col lg={6}>
                                  <Field
                                    label={"Description"}
                                    name={`ListOfDescriptions.[${index}].Description`}
                                    placeholder={"Enter Description"}
                                    component={CustomInput}
                                    required
                                    maxLength={500}
                                  />
                                </Col>
                                <Col lg={2} className="align-self-center mb-3">
                                  <div className="d-grid">
                                    <label className="form-label">
                                      <span
                                        style={{
                                          color: "white",
                                        }}
                                      >
                                        .
                                      </span>
                                    </label>
                                    <input
                                      data-repeater-delete
                                      type="button"
                                      className="btn btn-primary btn-xs mt-4"
                                      value="Delete"
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <Row>
                            <Col lg={12} style={{ textAlign: "right" }}>
                              <Button
                                color="success"
                                className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                onClick={() =>
                                  arrayHelpers.push(
                                    initListOfPackageDescription
                                  )
                                }
                              >
                                <i className="fa fa-plus"></i> &nbsp; Add
                              </Button>
                            </Col>
                          </Row>
                        </Fragment>
                      );
                    }}
                  />
                  <hr></hr>
                  <h6 className="mb-3">Package Inclusion Services</h6>
                  <FieldArray
                    name="ListOfInclusionServices"
                    render={(arrayHelpers) => {
                      return (
                        <Fragment>
                          {ListOfInclusionServices?.map(
                            (InclusionServicesList, index) => (
                              <div data-repeater-list="group-a" key={index}>
                                <Row>
                                  <Col lg={4}>
                                    <Field
                                      label={"Service"}
                                      name={`ListOfInclusionServices.[${index}].ServiceIDEncrypted`}
                                      placeholder={"Select Service"}
                                      options={optionService}
                                      component={CustomSelect}
                                      required
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="number"
                                      label={"Service Quantity"}
                                      name={`ListOfInclusionServices.[${index}].ServiceQty`}
                                      placeholder={"Enter Service Quantity"}
                                      component={CustomInput}
                                      required
                                      min={0}
                                      maxLength={3}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `ListOfInclusionServices.[${index}].ServiceQty`,
                                          e.target.value.slice(0, 3)
                                        );
                                      }}
                                    />
                                  </Col>

                                  <Col
                                    lg={2}
                                    className="align-self-center mb-3"
                                  >
                                    <div className="d-grid">
                                      <label className="form-label">
                                        <span
                                          style={{
                                            color: "white",
                                          }}
                                        >
                                          .
                                        </span>
                                      </label>
                                      <input
                                        data-repeater-delete
                                        type="button"
                                        className="btn btn-primary btn-xs mt-4"
                                        value="Delete"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                          <Row>
                            <Col lg={12} style={{ textAlign: "right" }}>
                              <Button
                                color="success"
                                className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                onClick={() =>
                                  arrayHelpers.push(
                                    initListOfPackageInclusionServices
                                  )
                                }
                              >
                                <i className="fa fa-plus"></i> &nbsp; Add
                              </Button>
                            </Col>
                          </Row>
                        </Fragment>
                      );
                    }}
                  />
                  <hr></hr>
                  <h6 className="mb-3">Package Inclusion Equipments</h6>
                  <FieldArray
                    name="ListOfInclusionEquipments"
                    render={(arrayHelpers) => {
                      return (
                        <Fragment>
                          {ListOfInclusionEquipments?.map(
                            (InclusionEquipmentsList, index) => (
                              <div data-repeater-list="group-a" key={index}>
                                <Row>
                                  <Col lg={3}>
                                    <Field
                                      label={"Equipment"}
                                      name={`ListOfInclusionEquipments.[${index}].EquipmentIDEncrypt`}
                                      placeholder={"Select Equipment"}
                                      options={optionEquipment}
                                      component={CustomSelect}
                                      required
                                    />
                                  </Col>
                                  <Col lg={3}>
                                    <Field
                                      type="number"
                                      label={"Equipment Quantity"}
                                      name={`ListOfInclusionEquipments.[${index}].EquipmentQty`}
                                      placeholder={"Enter Equipment Quantity"}
                                      component={CustomInput}
                                      required
                                      min={0}
                                      maxLength={3}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `ListOfInclusionEquipments.[${index}].EquipmentQty`,
                                          e.target.value.slice(0, 3)
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="align-self-center mb-3"
                                  >
                                    <div className="d-grid">
                                      <label className="form-label">
                                        <span
                                          style={{
                                            color: "white",
                                          }}
                                        >
                                          .
                                        </span>
                                      </label>
                                      <input
                                        data-repeater-delete
                                        type="button"
                                        className="btn btn-primary btn-xs mt-4"
                                        value="Delete"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                          <Row>
                            <Col lg={12} style={{ textAlign: "right" }}>
                              <Button
                                color="success"
                                className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                onClick={() =>
                                  arrayHelpers.push(
                                    initListOfPackageInclusionEquipments
                                  )
                                }
                              >
                                <i className="fa fa-plus"></i> &nbsp; Add
                              </Button>
                            </Col>
                          </Row>
                        </Fragment>
                      );
                    }}
                  />
                  <Row className="mb-3">
                    <Col lg={3}>
                      <Label>
                        Package Icon <span className="text-danger">*</span>{" "}
                        <span style={{ color: "red", fontSize: "10px" }}>
                          (500 x 500)
                        </span>
                      </Label>

                      <div className="image-placeholder serviceIcon-placeholder">
                        <div className="avatar-edit">
                          <input
                            type="file"
                            id="imageUpload"
                            onChange={uploadIcon}
                            key={fileKeyIcon || ""}
                          />
                          <label htmlFor="imageUpload" name=""></label>
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview">
                            <img
                              id="saveImageFile"
                              src={
                                iconFile || icon
                                  ? iconFile
                                    ? URL.createObjectURL(iconFile)
                                    : icon
                                  : defaultIcon
                              }
                              alt={iconFile ? iconFile.name : null}
                            />
                          </div>
                        </div>
                      </div>
                      <span
                        style={{
                          color: "#ec4561",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                        }}
                      >
                        {validationIcon}
                      </span>
                    </Col>

                    <Col lg={9}>
                      <Label>
                        Package Banner <span className="text-danger">*</span>{" "}
                        <span style={{ color: "red", fontSize: "10px" }}>
                          (1024 x 330)
                        </span>
                      </Label>

                      <div className="image-placeholder packageBanner-placeholder">
                        <div className="avatar-edit">
                          <input
                            type="file"
                            id="BannerUpload"
                            onChange={uploadBanner}
                            key={fileKeyBanner || ""}
                          />
                          <label htmlFor="BannerUpload" name=""></label>
                        </div>
                        <div className="avatar-preview">
                          <div id="BannerPreview">
                            <img
                              id="saveBannerFile"
                              src={
                                bannerFile || banner
                                  ? bannerFile
                                    ? URL.createObjectURL(bannerFile)
                                    : banner
                                  : defaultIconBanner
                              }
                              alt={bannerFile ? bannerFile.name : null}
                            />
                          </div>
                        </div>
                      </div>
                      <span
                        style={{
                          color: "#ec4561",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                        }}
                      >
                        {validationBanner}
                      </span>
                    </Col>
                  </Row>
                  <button type="submit" className="btn mr-2 btn-primary btn-sm">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-light"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </button>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddPackage;
