import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Label, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  POST_AdminPackageServiceScheduleDetail,
  POST_AdminPackageHealthCheckupPackageInsert,
  POST_GetClientStaffListByServiceID,
} from "../../../Helper/url_helper";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import ModalPatientDetails from "../../../Custom/ModalPatientDetails";
import moment from "moment";
import { post } from "../../../Helper/api_helper";

const AddPackageSchedule = () => {
  const { OrderBookingIDEncryptParam, HealthCheckupOrPackageIDEncryptParam } =
    useParams();
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);
  const [patientDetailModel, setPatientDetailModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [orderBookingID, setOrderBookingID] = useState("");
  const [patientID, setPatientID] = useState("");

  const [isOngoing, setIsOngoing] = useState(false);

  const [isOnceCalled, setIsOnceCalled] = useState(false);

  const initislListOfService = {
    ServiceName: "",
    ServiceQty: "",
    ServiceIDEncrypted: "",
    ServiceHoursOptions: [],
    HourlyServiceType: "",
    ServiceDetail: [],
  };

  const initislListOfEquipment = {
    EquipmentName: "",
    EquipmentQty: "",
    ScheduleDateTime: "",
  };

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      PackageName: "",
      MobileNo: "",
      OrderBookingNo: "",
      PackageDuration: "",
      PatientName: "",
      PatientNo: "",
      ListOfSevices: [initislListOfService],
      ListOfEquipment: [initislListOfEquipment],
      Latitude: "",
      Longitude: "",
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: {
      PackageName,
      MobileNo,
      OrderBookingNo,
      PackageDuration,
      PatientName,
      PatientNo,
      ListOfSevices,
      ListOfEquipment,
      Latitude,
      Longitude,
    },
  } = formik;

  useEffect(() => {
    setIsLoading(true);
    post(POST_AdminPackageServiceScheduleDetail, {
      OrderBookingIDEncrypt: window.atob(OrderBookingIDEncryptParam),
      PackageIDEncrypt: window.atob(HealthCheckupOrPackageIDEncryptParam),
      ScheduleTypeID: 5,
    }).then((res) => {
      if (res.IsSuccess) {
        setIsOngoing(res.IsOngoing);
        setValues({
          PackageName: res.PackageName,
          MobileNo: res.MobileNo,
          OrderBookingNo: res.OrderBookingNo,
          PackageDuration: res.PackageDurationInDays,
          PatientName: res.PatientName,
          PatientNo: res.PatientNo,
          Latitude: res.Latitude,
          Longitude: res.Longitude,
          ListOfSevices:
            res.AdminPackageInclusionServicesList?.length > 0
              ? res.AdminPackageInclusionServicesList.map((x) => {
                  let newArray = [];
                  if (x.ServiceQty != res.PackageDurationInDays) {
                    for (var i = 0; i < x.ServiceQty; i++) {
                      newArray.push({
                        ...x,
                        ServiceIDEncrypt: x.ServiceIDEncrypt,
                        StaffNameID: "",
                        RotationalStaffNameID: "",
                        ServiceHourIDEncrypt: "",
                        ScheduledPunchIn: new Date(),
                        ScheduledPunchOut: new Date(),
                        ScheduleDateTime: "",
                        ServiceStaffOptions: [],
                      });
                    }
                  } else {
                    newArray.push({
                      ...x,
                      ServiceIDEncrypt: x.ServiceIDEncrypt,
                      StaffNameID: "",
                      RotationalStaffNameID: "",
                      ServiceHourIDEncrypt: "",
                      ScheduledPunchIn: new Date(),
                      ScheduledPunchOut: new Date(),
                      ScheduleDateTime: "",
                      ServiceStaffOptions: [],
                    });
                  }

                  return {
                    ...x,
                    ServiceName: x.ServiceName,
                    ServiceIDEncrypted: x.ServiceIDEncrypt,
                    ServiceHoursOptions: x.AdminServiceHoursDDLList.map(
                      (item) => ({
                        label: item.HoursCaption,
                        value: item.ServiceHourIDEncrypt,
                        Hours: item.Hours,
                        HourlyServiceType: item.HourlyServiceType,
                      })
                    ),
                    ServiceDetail:
                      x.AdminPackageInclusionServicesDetailsList?.length > 0
                        ? x.AdminPackageInclusionServicesDetailsList.map(
                            (y) => {
                              return {
                                ...y,
                                ServiceIDEncrypted: x.ServiceIDEncrypt,
                                HourlyServiceType:
                                  x.AdminServiceHoursDDLList?.filter(
                                    (a) =>
                                      a.ServiceHourIDEncrypt ===
                                      y.ServiceHourIDEncrypt
                                  )[0]?.HourlyServiceType,
                                StaffNameID: y.StaffIDEncrypt,
                                RotationalStaffNameID:
                                  y.RotationalStaffIDEncrypt,
                                ServiceHourIDEncrypt: y.ServiceHourIDEncrypt,
                                ScheduledPunchIn: moment(
                                  `2023-05-02 ${y.ServiceTime.trim()}`
                                ).format("DD-MMM-yyyy hh:mm A"),
                                ScheduleDateTime: y.ServiceDate
                                  ? new Date(y.ServiceDate)
                                  : "",
                                ScheduledPunchOut: y.ScheduledPunchOut
                                  ? y.ScheduledPunchOut
                                  : "",
                                ServiceStaffOptions: [],
                              };
                            }
                          )
                        : newArray,
                  };
                })
              : [initislListOfService],
          ListOfEquipment:
            res.AdminPackageInclusionEquipmentsList?.length > 0
              ? res.AdminPackageInclusionEquipmentsList.map((x) => {
                  return {
                    ...x,
                    EquipmentName: x.EquipmentName,
                    EquipmentQty: x.EquipmentQty,
                    ScheduleDateTime: x.ScheduleDate
                      ? new Date(x.ScheduleDate)
                      : "",
                  };
                })
              : [initislListOfEquipment],
        });
        setOrderBookingID(res.OrderBookingIDEncrypt);
        setPatientID(res.PatientIDEncrypt);
        setIsLoading(false);
        setIsOnceCalled(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isOnceCalled) {
      ListOfSevices?.map((x, index) => {
        x.ServiceDetail?.map((y, i) => {
          getStaffList(
            y,
            y.ServiceHourIDEncrypt,
            y.ScheduledPunchIn,
            y.ScheduleDateTime,
            index,
            i
          );
        });
      });
    }
  }, [isOnceCalled]);

  const getStaffList = async (x, HourId, Time, Date, index, i) => {
    if (
      (x.ChargeType === 1 && HourId === "") ||
      Time === "" ||
      // x.ScheduleDateTime === "" ||
      Date === ""
    ) {
      setFieldValue(
        `ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceStaffOptions`,
        []
      );
    } else {
      const Data = {
        CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
        CurrentPage: 1,
        PageSize: 1000,
        Search: "",
        Sorting: "",
        CityName: "",
        ServiceIDEncrypt: x.ServiceIDEncrypt,
        FromDate: `${moment(Date)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        ToDate: `${moment(Date)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        ServiceHourIDEncrypt: x.ChargeType === 1 ? HourId : "",
        FromTime: moment(Time).format("h:mm A"),
        Latitude: Latitude,
        Longitude: Longitude,
      };
      // console.log("data", Data);

      await post(POST_GetClientStaffListByServiceID, Data)
        .then((res) => {
          if (res.IsSuccess) {
            let NewOptions = [];

            res.ClientServiceWiseStaffList.map((item) =>
              NewOptions.push({
                label: item.StaffName,
                value: item.StaffIDEncrypt,
                staffName: item.StaffName,
                staffRate: item.SpecialRate,
                startTime: item.StartTime,
                finishTime: item.FinishTime,
                isBooked: item.IsBooked,
              })
            );

            setFieldValue(
              `ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceStaffOptions`,
              NewOptions
            );
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    var Isvalid = true;
    ListOfSevices.map((x, i) => {
      if (Isvalid) {
        if (
          x.ServiceDetail?.filter(
            (y, i) =>
              y.RotationalStaffNameID === "" && y.HourlyServiceType === 2
          ).length > 0
        ) {
          swal({
            text: "Fill the details of Inclusion Service Schedule.",
            icon: "warning",
            button: "OK",
          });
          Isvalid = false;
          return;
        }

        if (
          x.ServiceDetail?.filter(
            (y, i) =>
              y.ScheduleDateTime === "" ||
              (y.ServiceHourIDEncrypt === "" && y.ChargeType == 1) ||
              y.StaffNameID === ""
          ).length > 0
        ) {
          swal({
            text: "Fill the details of Inclusion Service Schedule..",
            icon: "warning",
            button: "OK",
          });

          Isvalid = false;
          return;
        }

        x.ServiceDetail.map((y, index) => {
          if (x.ServiceDetail.length > 1) {
            for (var i = 0; i < x.ServiceDetail.length; i++) {
              if (
                i != index &&
                `${moment(x.ServiceDetail[i].ScheduleDateTime)
                  .format("YYYY-MM-DD")
                  .replace(/^\-/, "")} 00:00:00.000` ===
                  `${moment(y.ScheduleDateTime)
                    .format("YYYY-MM-DD")
                    .replace(/^\-/, "")} 00:00:00.000`
              ) {
                swal({
                  text: "Service Schedule has same date.",
                  icon: "warning",
                  button: "OK",
                });
                Isvalid = false;
                return;
              }
            }
          }
        });
      }
    });

    if (Isvalid === false) {
      return;
    }
    if (ListOfEquipment.filter((x) => x.ScheduleDateTime == "").length > 0) {
      swal({
        text: "Fill the details of Inclusion Equipment Schedule.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);
    let newData = [];
    ListOfSevices.map((x, i) => {
      x.ServiceDetail.map((y) => {
        newData.push({
          OrderBookingServiceScheduleIDEncrypted:
            x.OrderBookingPackageInclusionServiceIDEncrypt,
          ServiceIDEncrypted: x.ServiceIDEncrypted,
          ReferenceIDFEncrypted: x.OrderBookingPackageInclusionServiceIDEncrypt,
          ReferenceType: 5,
          StaffPunchIn: "",
          StaffPunchOut: "",
          ServiceRemarks: "",
          StaffIDEncrypted: y.StaffNameID,
          RotationalStaffIDEncrypted: y.RotationalStaffNameID
            ? y.RotationalStaffNameID
            : "",
          ServiceHourIDEncrypted: y.ServiceHourIDEncrypt,
          ScheduledPunchIn: `${moment(y.ScheduleDateTime)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} ${moment(y.ScheduledPunchIn).format(
            "h:mm A"
          )}`,
          ScheduledPunchOut: `${moment(y.ScheduleDateTime)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} ${y.ScheduledPunchOut}`,
          ScheduleDateTime: `${moment(y.ScheduleDateTime)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`,
        });
      });
    });
    const data = {
      OrderBookingIDEncrypt: window.atob(OrderBookingIDEncryptParam),
      ReferenceType: 5,
      AdminPackageInclusionServicesModel: newData,
      AdminPackageInclusionEquipmentModel: ListOfEquipment.map((x) => {
        return {
          OrderBookingEquipmentScheduleIDEncrypted:
            x.OrderBookingEquipmentScheduleIDEncrypt,
          ReferenceIDFEncrypted: x.ReferenceIDFEncrypt,
          EquipmentIDEncrypted: x.EquipmentIDEncrypt,
          BookingRemarks: "",
          EquipmentDeliveryDate: "",
          ReferenceType: 5,
          ScheduleDateTime: `${moment(x.ScheduleDateTime)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`,
        };
      }),
    };

    post(POST_AdminPackageHealthCheckupPackageInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/schedule-package-and-healthcheckup");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu="Package Schedule"
          motherMenu={
            <Link to="/schedule-package-and-healthcheckup">
              Schedule Package & Health Checkup
            </Link>
          }
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Order No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{OrderBookingNo}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Patient Name</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{PatientName}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Patient No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{PatientNo}</Col>
                          </Row>
                        </Col>

                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Mobile No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{MobileNo}</Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ textAlign: "end" }}>
                          <div
                            onClick={() => {
                              setOrderDetailModal(true);
                            }}
                            className="btn btn-light btn-xxs btn-rounded mt-4"
                          >
                            Order Detail
                          </div>
                          &nbsp;&nbsp;
                          <div
                            onClick={() => {
                              setPatientDetailModal(true);
                            }}
                            className="btn btn-light btn-xxs btn-rounded mt-4"
                          >
                            Patient Detail
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row className="mt-3">
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <h6>
                                <b>Package Name</b>
                              </h6>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{PackageName}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <h6>
                                <b>Package Duration</b>
                              </h6>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{PackageDuration}</Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      {ListOfSevices.length > 0 ? (
                        <>
                          <h6 className="mb-3">Package Inclusion Services</h6>

                          {ListOfSevices?.map((element, index) => (
                            <>
                              <Row className="mt-3" key={index}>
                                <Col lg={4}>
                                  <Field
                                    label={"Service Name"}
                                    name={`ListOfSevices.[${index}].ServiceName`}
                                    placeholder={"Select Service Name"}
                                    component={CustomInput}
                                    value={element.ServiceName}
                                    style={{ background: "#e9ecef" }}
                                    disabled={true}
                                    required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="number"
                                    label={"Quantity"}
                                    name={`ListOfSevices.[${index}].ServiceQty`}
                                    placeholder={"Enter Quantity"}
                                    component={CustomInput}
                                    value={element.ServiceQty}
                                    disabled={true}
                                    min={0}
                                    required
                                  />
                                </Col>
                              </Row>
                              {element.ServiceDetail?.map((x, i) => (
                                <Row>
                                  <Col lg={2}>
                                    <Field
                                      label={"Service Date"}
                                      name={`ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduleDateTime`}
                                      placeholder={"Service Date"}
                                      component={CustomFlatpickr}
                                      onChange={(date) => {
                                        if (date) {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduleDateTime`,
                                            new Date(date)
                                          );
                                          getStaffList(
                                            x,
                                            x.ServiceHourIDEncrypt,
                                            x.ScheduledPunchIn,
                                            date ? date : "",
                                            index,
                                            i
                                          );
                                        } else {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduleDateTime`,
                                            ""
                                          );
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceStaffOptions`,
                                            []
                                          );
                                        }
                                      }}
                                      // disabled={isOngoing.toString()}
                                      disabled={isOngoing}
                                      options={{
                                        // altInput: true,
                                        altFormat: "j-M-Y",
                                        // dateFormat: "d-m-Y",
                                        dateFormat: "d-M-Y",
                                      }}
                                      required
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <Label>
                                      Time
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <TimePicker
                                        autoOk
                                        name={`ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduledPunchIn`}
                                        placeholder={"Select Start Time"}
                                        value={x.ScheduledPunchIn}
                                        ampmInClock={true}
                                        onChange={(newValue) => {
                                          if (newValue) {
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduledPunchIn`,
                                              new Date(newValue)
                                            );
                                            getStaffList(
                                              x,
                                              x.ServiceHourIDEncrypt,
                                              newValue
                                                ? new Date(newValue)
                                                : "",
                                              x.ScheduleDateTime,
                                              index,
                                              i
                                            );
                                          } else {
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduledPunchIn`,
                                              ""
                                            );
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceStaffOptions`,
                                              []
                                            );
                                          }
                                        }}
                                        disabled={isOngoing}
                                        format={"h:mm a"}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Col>
                                  {element.ChargeType === 1 ? (
                                    <Col lg={4}>
                                      <Field
                                        label={"Service Hours"}
                                        name={`ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceHourIDEncrypt`}
                                        placeholder={"Select Service Hours"}
                                        options={element.ServiceHoursOptions}
                                        onChange={(event) => {
                                          if (event) {
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceHourIDEncrypt`,
                                              event?.value
                                            );
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].HourlyServiceType`,
                                              event?.HourlyServiceType
                                            );
                                            getStaffList(
                                              x,
                                              event?.value,
                                              x.ScheduledPunchIn,
                                              x.ScheduleDateTime,
                                              index,
                                              i
                                            );
                                          } else {
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceHourIDEncrypt`,
                                              ""
                                            );
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].HourlyServiceType`,
                                              ""
                                            );
                                            setFieldValue(
                                              `ListOfSevices.[${index}].ServiceDetail.[${i}].ServiceStaffOptions`,
                                              []
                                            );
                                          }
                                        }}
                                        component={CustomSelect}
                                        isDisabled={isOngoing}
                                        required
                                      />
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  <Col lg={4}>
                                    <Field
                                      //label={"Staff Name"}
                                      label={`Staff Name${
                                        x.HourlyServiceType === 2
                                          ? " (Morning)"
                                          : ""
                                      }`}
                                      name={`ListOfSevices.[${index}].ServiceDetail.[${i}].StaffNameID`}
                                      placeholder={"Select Staff Name"}
                                      options={x.ServiceStaffOptions}
                                      component={CustomSelect}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceDetail.[${i}].StaffNameID`,
                                            e?.value
                                          );
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduledPunchOut`,
                                            e?.finishTime ? e?.finishTime : ""
                                          );
                                        } else {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceDetail.[${i}].StaffNameID`,
                                            ""
                                          );
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceDetail.[${i}].ScheduledPunchOut`,
                                            new Date()
                                          );
                                        }
                                      }}
                                      isDisabled={isOngoing}
                                      required
                                    />
                                  </Col>
                                  {x.HourlyServiceType === 2 ? (
                                    <Col lg={4}>
                                      <Field
                                        label={
                                          "Rotational Staff Name (Evening)"
                                        }
                                        name={`ListOfSevices.[${index}].ServiceDetail.[${i}].RotationalStaffNameID`}
                                        placeholder={"Select Staff Name"}
                                        options={
                                          x.ServiceStaffOptions
                                            ? x.ServiceStaffOptions.filter(
                                                (y) => y.value !== x.StaffNameID
                                              )
                                            : x.ServiceStaffOptions
                                        }
                                        component={CustomSelect}
                                        isDisabled={isOngoing}
                                        required
                                      />
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              ))}
                            </>
                          ))}
                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}

                      {ListOfEquipment.length > 0 ? (
                        <>
                          <h6 className="mb-3">Package Inclusion Equipment</h6>
                          {ListOfEquipment?.map((element, index) => (
                            <>
                              <Row className="mt-3">
                                <Col lg={4}>
                                  <Field
                                    label={"Equipment Name"}
                                    name={`ListOfEquipment.[${index}].EquipmentName`}
                                    placeholder={"Enter Equipment Name"}
                                    component={CustomInput}
                                    value={element.EquipmentName}
                                    style={{ background: "#e9ecef" }}
                                    disabled={true}
                                    required
                                  />
                                </Col>
                                <Col lg={2}>
                                  <Field
                                    type="number"
                                    label={"Quantity"}
                                    name={`ListOfEquipment.[${index}].EquipmentQty`}
                                    placeholder={"Enter Quantity"}
                                    component={CustomInput}
                                    value={element.EquipmentQty}
                                    disabled={true}
                                    min={0}
                                    required
                                  />
                                </Col>
                                <Col lg={2}>
                                  <Field
                                    label={"From Date"}
                                    name={`ListOfEquipment.[${index}].ScheduleDateTime`}
                                    placeholder={"From Date"}
                                    component={CustomFlatpickr}
                                    onChange={(date) => {
                                      if (date) {
                                        setFieldValue(
                                          `ListOfEquipment.[${index}].ScheduleDateTime`,
                                          new Date(date)
                                        );
                                      } else {
                                        setFieldValue(
                                          `ListOfEquipment.[${index}].ScheduleDateTime`,
                                          ""
                                        );
                                      }
                                    }}
                                    disabled={isOngoing}
                                    options={{
                                      // altInput: true,
                                      altFormat: "j-M-Y",
                                      // dateFormat: "d-m-Y",
                                      dateFormat: "d-M-Y",
                                    }}
                                    // disabled={isOngoing.toString()}
                                    required
                                  />
                                </Col>
                              </Row>
                            </>
                          ))}
                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}

                      <Row className="mt-3">
                        <Col>
                          {isOngoing ? (
                            ""
                          ) : (
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                          )}{" "}
                          <button
                            type="button"
                            onClick={(e) => {
                              history.goBack();
                            }}
                            className="btn btn-light"
                          >
                            {" "}
                            <i className="flaticon-delete-1"></i> Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal fade"
          show={orderDetailModal}
          onHide={setOrderDetailModal}
          size="xl"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Order Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setOrderDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalOrderBookingDetail
              orderBookingID={orderBookingID}
              setOrderDetailModal={setOrderDetailModal}
            ></ModalOrderBookingDetail>
          </div>
        </Modal>

        <Modal
          className="modal fade"
          show={patientDetailModel}
          onHide={setPatientDetailModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Patient Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setPatientDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalPatientDetails
              patientID={patientID}
              setPatientDetailModal={setPatientDetailModal}
            ></ModalPatientDetails>
          </div>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default AddPackageSchedule;
