import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Row, Col, Modal } from "react-bootstrap";
import PaginationButtons from "../../../Custom/Pagination";
import { post } from "../../../Helper/api_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import {
  POST_GetExpensesList,
  POST_ExpensesInsert,
  POST_ExpensesUpdate,
  POST_ExpensesStatusUpdate,
  POST_ExpensesDelete,
  POST_GetExpensesDetailsByID,
} from "../../../Helper/url_helper";
const Expense = () => {
  const [expenseModal, setExpenseModal] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [expenseList, setExpenseList] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchemaExpense = Yup.object().shape({
    ExpenseName: Yup.string().required("Expense Name is required field"),
  });

  const formikExpense = useFormik({
    validationSchema: validationSchemaExpense,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitExpense(values, formikHelpers),
    initialValues: {
      ExpenseID: "",
      ExpenseName: "",
      Status: true,
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: { ExpenseID },
  } = formikExpense;

  const handleOnChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getData();
  }, [search]);

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_GetExpensesList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: search,
      Sorting: "",
      LoginID: objLogin.LoginIDEncrypt,
    });
    setIsLoading(false);
    setRecordCount(response.TotalRecordCount);
    setExpenseList(response.List);
    //setCurrentPage(page);
    setCurrentSizePerPage(sizePerPage);
    if (page > 1 && response?.List?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  const delete_Expense = (eID) => {
    swal({
      title: "Are you sure you want to delete the expense?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_ExpenseConfirm(eID);
      }
    });
  };

  const delete_ExpenseConfirm = async (eID) => {
    setIsLoading(true);
    let response = await post(POST_ExpensesDelete, {
      ExpenseIDEncrypted: eID,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_Expense = async (eID, eStatus) => {
    swal({
      title: `Are you sure you want to ${
        eStatus ? "inactive" : "active"
      } the expense?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_ExpenseConfirm(eID, eStatus);
      }
    });
  };

  const ActiveInactive_ExpenseConfirm = async (eID, eStatus) => {
    setIsLoading(true);
    let response = await post(POST_ExpensesStatusUpdate, {
      ExpenseIDEncrypted: eID,
      IsActive: !eStatus,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: eStatus ? "Inactivated" : "Activated",
        text: eStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const get_Expense = async (eID) => {
    setIsLoading(true);
    let response = await post(POST_GetExpensesDetailsByID, {
      ExpenseID: eID,
    });
    if (response.IsSuccess) {
      setValues({
        ExpenseID: response.ExpenseIDEncrypted,
        ExpenseName: response.ExpenseName,
        Status: response.IsActive,
      });
      setExpenseModal(true);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const handleValidSubmitExpense = (values, { setSubmitting }) => {
    setIsLoading(true);
    const data = {
      ExpenseIDEncrypted: ExpenseID,
      ExpenseName: values.ExpenseName.trim(),
      IsActive: values.Status,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    };
    post(ExpenseID ? POST_ExpensesUpdate : POST_ExpensesInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          setExpenseModal(false);
          getData(1, currentSizePerPage);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
          setExpenseModal(false);
          formikExpense.resetForm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  function add_Expense() {
    formikExpense.resetForm();
    setValues({
      ExpenseID: "",
      ExpenseName: "",
      Status: true,
    });
    setExpenseModal(!expenseModal);
  }

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Expense" />

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 14 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <div
                  onClick={() => add_Expense()}
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add Expense
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                value={search}
                onChange={handleOnChangeSearch}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="patient_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Expense Name: activate to sort column ascending"
                          style={{ width: "90%" }}
                        >
                          Expense Name
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {recordCount > 0 ? (
                      <tbody>
                        {expenseList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td key={i}>{element.ExpenseName}</td>
                            <td>
                              <div className="d-flex">
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 14 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <div
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                      onClick={() => {
                                        get_Expense(element.ExpenseIDEncrypted);
                                      }}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </div>
                                    <Link
                                      // to="#"
                                      className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                      title={
                                        element.IsActive ? "Active" : "Inactive"
                                      }
                                      onClick={() => {
                                        ActiveInactive_Expense(
                                          element.ExpenseIDEncrypted,
                                          element.IsActive
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle mt-1"
                                        style={{
                                          color: element.IsActive
                                            ? "green"
                                            : "red",
                                        }}
                                      ></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 14 && item.DeleteAction
                                  ).length > 0) ? (
                                  <Link
                                    // to="#"
                                    className="btn btn-light shadow btn-xs sharp"
                                    title="Delete"
                                    onClick={() => {
                                      delete_Expense(
                                        element.ExpenseIDEncrypted
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="mt-3 ml-3">{"No Record Found"}</div>
                    )}
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            className="modal fade"
            show={expenseModal}
            onHide={setExpenseModal}
          >
            <div className="">
              <div className="">
                <FormikProvider value={formikExpense}>
                  <Form onSubmit={formikExpense.handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Expense</h4>
                      <button
                        type="button"
                        className="btn close"
                        onClick={() => setExpenseModal(false)}
                      >
                        <span>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <Row>
                            <Col lg={12}>
                              <Field
                                type="text"
                                label={"Expense Name"}
                                name="ExpenseName"
                                placeholder={"Enter Expense Name"}
                                component={CustomInput}
                                // onChange={(e) => {
                                //   setFieldValue(
                                //     "ExpenseName",
                                //     e.target.value.replace(/[^A-Za-z ]/g, "")
                                //   );
                                // }}
                                required
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setExpenseModal(false)}
                        className="btn btn-light"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default Expense;
