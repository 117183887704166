import React, { Fragment, useEffect, useState } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import { Row, Col } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import { Spin } from "antd";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_GSTMonthlyReportOfEquipmentByYearInExcel,
  POST_GetGSTYearlyReportOfEquipment,
  POST_GetYearlyAnalysisDataWiseYearDDLList,
} from "../../../Helper/url_helper";

const GSTReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [yearReportAnalysisOptions, setYearAnalysisReportOptions] = useState(
    []
  );

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchemaReports = Yup.object().shape({
    GSTReportID: Yup.string().required("GST Report Type is required field"),
    Year: Yup.string().when("GSTReportID", {
      is: (GSTReportID) => GSTReportID == 1,
      then: Yup.string().required("Year is required field"),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    validationSchema: validationSchemaReports,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      GSTReportID: "",
      Year: "",
      CompanyID: props.companyID,
      Report: props.reportID,
    },
    validateOnBlur: false,
  });

  const {
    values: { CompanyID, GSTReportID },
  } = formik;

  useEffect(() => {
    loadYearlyAnalysisDataDDL();
  }, []);

  const gSTReportOptions = [
    { label: "GST Monthly Report of Equipment", value: 1 },
    { label: "GST Yearly Report of Equipment", value: 2 },
  ];

  const loadYearlyAnalysisDataDDL = () => {
    setIsLoading(true);
    post(POST_GetYearlyAnalysisDataWiseYearDDLList, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setYearAnalysisReportOptions(
            res.AdminGetYearDDLList.map((item) => ({
              label: item.YearName,
              value: item.YearName,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      Year: values.Year,
      CompanyIDEncrypt: CompanyID,
    };

    // console.log("Data", Data);

    //let response = await post(POST_EquipmentReportInExcelDownload, Data);

    let response = "";

    if (GSTReportID == 1) {
      response = await post(
        POST_GSTMonthlyReportOfEquipmentByYearInExcel,
        Data
      );
    } else if (GSTReportID == 2) {
      response = await post(POST_GetGSTYearlyReportOfEquipment, Data);
    }

    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} style={{ textAlign: "left", fontSize: "15px" }}>
                <div style={{ color: "black" }}>Filter</div> <br></br>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <Field
                  label={"GST Report Type"}
                  name="GSTReportID"
                  options={gSTReportOptions}
                  placeholder={"Select GST Report Type"}
                  component={CustomSelect}
                  isClearable={false}
                  required
                />
              </Col>
              {GSTReportID == 1 ? (
                <Col lg={3}>
                  <Field
                    label={"Year"}
                    name="Year"
                    options={yearReportAnalysisOptions}
                    placeholder={"Select Year"}
                    component={CustomSelect}
                    isClearable={false}
                    required
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>

            <hr></hr>
            <Row className="mt-3">
              <Col lg={12}>
                <button type="submit" className="btn mr-2 btn-primary btn-sm">
                  View Report
                </button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Spin>
    </Fragment>
  );
};

export default GSTReport;
