import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import swal from "sweetalert";
import PaginationButtons from "../../../Custom/Pagination";
import { get, post } from "../../../Helper/api_helper";
import * as Yup from "yup";
import { t } from "i18next";
import { Spin } from "antd";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field, FormikProvider, useFormik } from "formik";
import CustomSelect from "../../../Custom/CustomSelect";
import {
  GET_CompanyDropDownList,
  POST_GetStaffList,
  POST_StaffActiveInactive,
  POST_StaffDelete,
  POST_StaffProfileDownload,
} from "../../../Helper/url_helper";

const StaffList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [StaffDataList, setStaffDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [searchValue, setSearchValue] = useState("");

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CompanyID },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);

    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: searchValue,
      Sorting: "",
      CompanyIDEncrypted: CompanyID,
    };

    await post(POST_GetStaffList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setStaffDataList(response?.StaffList);
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (page > 1 && response?.StaffList?.length === 0) {
          setCurrentPage(page - 1);
          getData(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    //getData();
    loadCompanyDDL();
  }, []);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const ActiveInactive_Staff = async (ID, Status) => {
    swal({
      title: `Are you sure you want to ${
        Status ? "inactive" : "active"
      } the staff?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmResult) => {
      if (confirmResult) {
        ActiveInactive_StaffConfirm(ID, Status);
      }
    });
  };

  const ActiveInactive_StaffConfirm = async (ID, Status) => {
    setIsLoading(true);
    await post(POST_StaffActiveInactive, {
      StaffIDEncrypted: ID,
      IsActive: !Status,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: Status ? "Inactivated" : "Activated",
            text: Status
              ? "Record has been Inactivated."
              : "Record has been Activated.",
            icon: "success",
            button: "OK",
          });
          getData(currentPage, currentSizePerPage);
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const delete_Staff = (ID) => {
    swal({
      title: "Are you sure you want to delete the staff?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_StaffConfirm(ID);
      }
    });
  };

  const delete_StaffConfirm = async (ID) => {
    setIsLoading(true);
    await post(POST_StaffDelete, {
      StaffIDEncrypted: ID,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Deleted",
            text: "Record has been deleted.",
            icon: "success",
            button: "OK",
          });
          getData(currentPage, currentSizePerPage);
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const download_StaffProfile = async (ID) => {
    setIsLoading(true);
    await post(POST_StaffProfileDownload, {
      StaffIDEncrypted: ID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          window.open(response.FilePath);
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Staffs" />

          {AuthUser?.CompanyType === 1 && AuthUser?.UserType == 1 ? (
            <Row className="mb-3">
              <Col lg={12}>
                <Accordion
                  className="accordion accordion-no-gutter accordion-header-bg"
                  defaultActiveKey={0}
                >
                  <div className="accordion__item" key={1}>
                    <Accordion.Toggle
                      // as={Card.Text}
                      eventKey={1}
                      className={`accordion__header  ${
                        activeDefault === 1 ? "" : "collapsed"
                      }`}
                      style={{
                        minWidth: "100%",
                        // backgroundColor: "#36C95F",
                        // borderColor: "#d1d1d1",
                      }}
                      onClick={() =>
                        setActiveDefault(activeDefault === 1 ? -1 : 1)
                      }
                    >
                      <span
                        className="accordion__header--text"
                        style={{ float: "left" }}
                      >
                        <i
                          class="fa fa-filter"
                          aria-hidden="true"
                          style={{ fontSize: "20px" }}
                        ></i>
                        &nbsp;
                        {"Filter Staff List"}
                      </span>
                      <span className="accordion__header--indicator"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={1}>
                      <div className="accordion__body--text">
                        <FormikProvider value={formikFilter}>
                          <Form onSubmit={formikFilter.handleSubmit}>
                            <Row>
                              <Col lg={4}>
                                <Field
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                              <Col lg={4}>
                                <label></label>
                                <div className="d-flex">
                                  <div className="d-lg-block">
                                    <Link
                                      className="btn btn-primary btn-xs btn-rounded"
                                      onClick={(e) => {
                                        getData();
                                      }}
                                    >
                                      Search
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </FormikProvider>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 5 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <Link
                  to="/add-staff"
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add Staff
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="staff_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        {/* <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Staff Image: activate to sort column ascending"
                        style={{ width: "20%" }}
                      ></th> */}
                        {/* <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Company Name: activate to sort column ascending"
                        style={{ width: "20%" }}
                      >
                        Company Name
                      </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Staff No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Name: activate to sort column ascending"
                          style={{ width: "25%" }}
                        >
                          Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Designation
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile Number.: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Mobile Number
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="EmailID: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          EmailID
                        </th>
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Gender: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Gender
                        </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Gender: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Status
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        StaffDataList?.map((element, index) => (
                          <tr role="row" className="odd" key={index}>
                            <td>{element.StaffNo}</td>
                            <td>{element.StaffName}</td>
                            <td>{element.Designation}</td>
                            <td>{element.PrimaryContactNumber}</td>
                            <td>{element.EmailID}</td>
                            {/* <td>{element.Gender}</td> */}
                            <td>{element.BackgroundVerificationStatusName}</td>
                            <td>
                              <div className="d-flex">
                                {AuthUser?.UserType == 1 ||
                                (AuthUser?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 5 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <Link
                                      to={`/edit-staff/${window.btoa(
                                        element.StaffIDEncrypt
                                      )}`}
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>

                                    <Link
                                      className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                      title={
                                        element.IsActive ? "Active" : "Inactive"
                                      }
                                      onClick={() => {
                                        ActiveInactive_Staff(
                                          element.StaffIDEncrypt,
                                          element.IsActive
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle mt-1"
                                        style={{
                                          color: element.IsActive
                                            ? "green"
                                            : "red",
                                        }}
                                      ></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {AuthUser?.UserType == 1 ||
                                (AuthUser?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 5 && item.DeleteAction
                                  ).length > 0) ? (
                                  <Link
                                    className="btn btn-light shadow btn-xs sharp  mr-1"
                                    title="Delete"
                                    onClick={() => {
                                      delete_Staff(element.StaffIDEncrypt);
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                  title="Profile Download"
                                  onClick={() => {
                                    download_StaffProfile(
                                      element.StaffIDEncrypt
                                    );
                                  }}
                                >
                                  <i className="fa fa-download"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3 mb-3">
                              {"No Record Found"}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers d-flex  justify-content-center align-items-center pb-3">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default StaffList;
