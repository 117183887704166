import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { POST_GetPatientDetailByID } from "../Helper/url_helper";
import { post } from "../Helper/api_helper";
import { Spin } from "antd";

const ModalPatientDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState("");

  useEffect(() => {
    if (props.patientID) {
      getPatientDetailByID();
    }
  }, []);

  const getPatientDetailByID = async () => {
    setIsLoading(true);
    let response = await post(POST_GetPatientDetailByID, {
      PatientIDEncrypted: props.patientID,
    });
    setIsLoading(false);
    setPatientDetails(response);
    //console.log("setPatientDetails", response);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <Row>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Patient No.</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.PatientNo}</Col>
              </Row>
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Patient Name</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>
                  {patientDetails.FirstName} {patientDetails.MiddleName}{" "}
                  {patientDetails.LastName}
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Client Name</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.ClientName}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Date of Birth</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.DateOfBirth}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Gender</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.GenderName}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Blood Group</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.BloodGroup}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Relation with Client</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.RelationWithClient}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Marital Status</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.MaritalStatusName}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Height</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.Height}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Weight</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.Weight}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Mobile No.</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.MobileNo}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Email ID</b>
                </Col>
                <Col lg={1} style={{ marginLeft: "-35px" }}>
                  :
                </Col>
                <Col lg={5}>{patientDetails.EmailID}</Col>
              </Row>
            </Col>
          </Row>
          <button
            style={{ float: "right" }}
            className="btn mt-5 mr-2 btn-xm btn-light"
            onClick={() => {
              props.setPatientDetailModal(false);
            }}
          >
            Close
          </button>
        </Spin>
      </Fragment>
    </>
  );
};

export default ModalPatientDetails;
