import ReactPaginate from "react-paginate";
//import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { motion } from "framer-motion";
const PaginationButtons = ({ getData, currentPage, totalPages }) => {
  const handlePageClick = ({ selected }) => {
    getData(selected + 1);
  };
  const paginationVariants = {
    hidden: {
      opacity: 0,
      y: 200,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 2,
      },
    },
  };
  const showNextButton = currentPage !== totalPages - 1;
  const showPrevButton = currentPage !== 0;
  return (
    <motion.div
      variants={paginationVariants}
      // initial="hidden"
      animate="visible"
    >
      <ReactPaginate
        breakLabel={
          <span className="">
            <div style={{ padding: "10px" }}>...</div>
          </span>
        }
        nextLabel={
          showNextButton ? (
            <span
              className="w-10 h-10 flex items-center justify-center rounded"
              style={{ color: "#3e4954" }}
            >
              {/* <BsChevronRight
                
              /> */}
              <div
                style={{
                  padding: "10px",
                  marginLeft: "10px",
                  borderRadius: "30px",
                }}
                className="paginationButton"
              >
                {" "}
                Next
              </div>
            </span>
          ) : null
        }
        forcePage={currentPage - 1}
        onPageChange={handlePageClick}
        pageRangeDisplayed={0}
        pageCount={totalPages}
        marginPagesDisplayed={1}
        previousLabel={
          showPrevButton ? (
            <span
              className="w-10 h-10 flex items-center  justify-center rounded"
              style={{ color: "#3e4954" }}
            >
              {/* <BsChevronLeft
              /> */}
              <div
                className="paginationButton"
                style={{
                  padding: "10px",
                  marginRight: "10px",
                  borderRadius: "30px",
                }}
              >
                Previous
              </div>
            </span>
          ) : null
        }
        containerClassName="flex items-center justify-center"
        pageClassName="block border-solid border-light paginationButton hover:bg-light pagination_width w-10 h-10 flex items-center justify-center rounded-md"
        activeClassName="bg-primary rounded pagination_active"
      />
    </motion.div>
  );
};

export default PaginationButtons;
