import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, post } from "../../../Helper/api_helper";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import swal from "sweetalert";
import { t } from "i18next";
import moment from "moment";
import {
  GET_CompanyDropDownList,
  POST_AdminStaffAwardsDetailByID,
  POST_AdminStaffAwardsInsert,
  POST_AdminStaffAwardsUpdate,
  POST_AdminStaffsListDDLByCompanyID,
  POST_GetAwardsList,
} from "../../../Helper/url_helper";
import { Spin } from "antd";

const AddStaffAwards = () => {
  const { StaffAwardIDEncryptParam } = useParams();
  const history = useHistory();
  const objLogin = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [awardsOptions, setAwardsOptions] = useState([]);

  const validationSchema = Yup.object().shape({
    CompanyID: Yup.string().nullable().required("Company is required field"),
    StaffID: Yup.string().nullable().required("Staff is required field"),
    FromDate: Yup.string().nullable().required("From Date is required field"),
    ToDate: Yup.string().nullable().required("To Date is required field"),
    AwardID: Yup.string().nullable().required("Award is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      StaffAwardIDEncrypt: StaffAwardIDEncryptParam
        ? window.atob(StaffAwardIDEncryptParam)
        : "",
      CompanyID: objLogin?.CompanyIDEncrypt,
      StaffID: "",
      AwardID: "",
      FromDate: "",
      ToDate: "",
      AwardID: "",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: { CompanyID },
  } = formik;

  useEffect(() => {
    if (CompanyID) {
      getStaffList(CompanyID);
    } else {
      formik.setFieldValue("CompanyID", "");
      setStaffOptions([]);
    }
  }, [CompanyID]);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadAwardsDDL = async () => {
    await post(POST_GetAwardsList)
      .then((res) => {
        if (res.IsSuccess) {
          setAwardsOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getStaffList = async (companyID) => {
    await post(POST_AdminStaffsListDDLByCompanyID, {
      CompanyIDEncrypt: companyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setStaffOptions(
            res.StaffsList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadCompanyDDL();
    loadAwardsDDL();
    if (StaffAwardIDEncryptParam) {
      setIsLoading(true);
      post(POST_AdminStaffAwardsDetailByID, {
        StaffAwardIDEncrypted: window.atob(StaffAwardIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            ...res,
            StaffAwardIDEncrypt: res.StaffAwardIDEncrypt,
            CompanyID: res.CompanyIDEncrypt,
            AwardID: res.AwardID,
            StaffID: res.StaffIDEncrypt,
            FromDate: new Date(res.FromDate),
            ToDate: new Date(res.ToDate),
            // FromDate: `${moment(res.FromDate)
            //   .format("YYYY-MM-DD")
            //   .replace(/^\-/, "")} 00:00:00.000`,
            // ToDate: `${moment(res.ToDate)
            //   .format("YYYY-MM-DD")
            //   .replace(/^\-/, "")} 00:00:00.000`,
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (
      `${moment(values.FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(values.ToDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    const data = {
      ...values,
      ModifiedByEncrypt: objLogin?.LoginIDEncrypt,
      CreatedByEncrypt: objLogin?.LoginIDEncrypt,
      AwardID: values.AwardID,
      StaffIDEncrypt: values.StaffID,
      FromDate: `${moment(values.FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      ToDate: `${moment(values.ToDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
    };

    post(
      StaffAwardIDEncryptParam
        ? POST_AdminStaffAwardsUpdate
        : POST_AdminStaffAwardsInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/staff-awards");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            StaffAwardIDEncryptParam ? "Edit Staff Awards" : "Add Staff Awards"
          }
          motherMenu={<Link to="/staff-awards">Staff Awards</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        {objLogin?.CompanyType === 1 &&
                        objLogin?.UserType == 1 ? (
                          <Col lg={4}>
                            <Field
                              label={"Company"}
                              name="CompanyID"
                              placeholder={"Select Company"}
                              options={companyOptions}
                              component={CustomSelect}
                              required
                              isClearable={false}
                              isDisabled={
                                StaffAwardIDEncryptParam ? true : false
                              }
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col lg={4}>
                          <Field
                            label={"Staff Name"}
                            name="StaffID"
                            options={staffOptions}
                            placeholder={"Select Staff Name"}
                            component={CustomSelect}
                            isDisabled={StaffAwardIDEncryptParam ? true : false}
                            isClearable={false}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>

                      <Row className="mb-2">
                        <Col lg={4}>
                          <Field
                            label={"Award"}
                            name="AwardID"
                            placeholder={"Select Award"}
                            options={awardsOptions}
                            component={CustomSelect}
                            required
                            isClearable={false}
                          />
                        </Col>

                        <Col lg={4}>
                          <Field
                            label={"From Date"}
                            name="FromDate"
                            placeholder={"Select From Date"}
                            component={CustomFlatpickr}
                            options={{
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"To Date"}
                            name="ToDate"
                            placeholder={"Select To Date"}
                            component={CustomFlatpickr}
                            options={{
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default AddStaffAwards;
