import React, { useState, Fragment, useEffect } from "react";

import PageTitle from "../../../layouts/PageTitle";
import PaginationButtons from "../../../Custom/Pagination";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import * as Yup from "yup";
import { Row, Col, Modal, Accordion } from "react-bootstrap";
import { get, post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import CustomSelect from "../../../Custom/CustomSelect";
import {
  POST_AdminEquipmentInstallationScheduleList,
  POST_AdminEquipmentInstallationAssignStaff,
  POST_AdminStaffDropDown,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";

import moment from "moment";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";

const EquipmentInstallationScheduleList = () => {
  const [
    equipmentInstallationScheduleModal,
    setEquipmentInstallationScheduleModal,
  ] = useState(false);
  const [
    equipmentInstallationScheduleList,
    setEquipmentInstallationScheduleList,
  ] = useState([]);

  const [staffOptions, setStaffDropdownOptions] = useState([]);

  const [recordCount, setRecordCount] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      ActivityStatus: -1,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate, ActivityStatus },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);
    let response = await post(POST_AdminEquipmentInstallationScheduleList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      StaffIDEncrypted: "",
      CompanyIDEncrypted: CompanyID,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",

      ActivityStatus:
        ActivityStatus == 0
          ? -1
          : ActivityStatus == 1
          ? 0
          : ActivityStatus == 2
          ? 1
          : -1,
    });
    if (response?.IsSuccess) {
      setRecordCount(response?.TotalRecordCount);
      setEquipmentInstallationScheduleList(
        response?.AdminEquipmentInstallationScheduleList
      );
      setCurrentSizePerPage(sizePerPage);
      if (
        page > 1 &&
        response?.AdminEquipmentInstallationScheduleList?.length === 0
      ) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCompanyDDL();
    getData();
    loadStaffDDL();
  }, []);

  const activityStatusOptions = [
    { label: "All", value: 0 },
    { label: "Assign Pending", value: 1 },
    { label: "Installation Pending", value: 2 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadStaffDDL = async () => {
    await post(POST_AdminStaffDropDown, {
      CompanyIDEncrypt: objLogin?.CompanyIDEncrypt,
    })
      .then((res) => {
        if (res?.IsSuccess) {
          setStaffDropdownOptions(
            res?.AdminStaffDropDownList?.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const validationSchemaEquipmentInstallationSchedule = Yup.object().shape({
    EquipmentInstallationStaffID: Yup.string()
      .nullable()
      .required("Staff is required field"),
  });

  const formikEquipmentInstallationSchedule = useFormik({
    validationSchema: validationSchemaEquipmentInstallationSchedule,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitEquipmentInstallationSchedule(values, formikHelpers),
    initialValues: {
      OrderBookingEquipmentScheduleID: "",
      OrderBookingID: "",
      OrderBookingNo: "",
      PatientNo: "",
      PatientName: "",
      MobileNo: "",
      ClientName: "",
      EquipmentName: "",
      ReferenceTypeName: "",
      ActivityStatusName: "",
      EquipmentInstallationStaffID: "",
      RequiredDate: "",
      ReturnDate: "",
    },
    validateOnBlur: false,
  });

  const {
    values: {
      OrderBookingNo,
      PatientNo,
      PatientName,
      MobileNo,
      ClientName,
      EquipmentName,
      RequiredDate,
    },
  } = formikEquipmentInstallationSchedule;

  const handleValidSubmitEquipmentInstallationSchedule = (values) => {
    setIsLoading(true);

    const data = {
      OrderBookingEquipmentScheduleIDEncrypt:
        values.OrderBookingEquipmentScheduleID,
      StaffIDEncrypt: values.EquipmentInstallationStaffID,
      EquipmentScheduleType: 1,
      AssignByEncrypt: objLogin?.LoginIDEncrypt,
    };

    //  console.log("Data", data);

    post(POST_AdminEquipmentInstallationAssignStaff, data)
      .then((response) => {
        if (response?.IsSuccess) {
          swal({
            title: "Success",
            text: t(response?.MessageCode),
            icon: "success",
            button: "OK",
          });

          formikEquipmentInstallationSchedule.resetForm();
          setEquipmentInstallationScheduleModal(false);
          getData(1, currentSizePerPage);
        } else {
          swal({
            title: "Error",
            text: t(response?.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            motherMenu="Transaction"
            activeMenu=" Assign Staff for Equipment Installation"
          />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Assign Staff for Equipment Installation List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          {objLogin?.CompanyType === 1 &&
                          objLogin?.UserType == 1 ? (
                            <Row>
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col lg={3}>
                              <Field
                                label={"Status"}
                                name="ActivityStatus"
                                placeholder={"Select Status"}
                                options={activityStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="EquipmentInstallation_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped EquipmentInstallation_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Order No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "18%" }}
                        >
                          Patient Name
                        </th>
                        {/* 
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Client Name: activate to sort column ascending"
                          style={{ width: "18%" }}
                        >
                          Client Name
                        </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Equipment Name: activate to sort column ascending"
                          style={{ width: "18%" }}
                        >
                          Equipment Name
                        </th>
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient No: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Patient No
                        </th> */}

                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile No: activate to sort column ascending"
                          style={{ width: "18%" }}
                        >
                          Mobile No
                        </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Installation Date: activate to sort column ascending"
                          style={{ width: "17%" }}
                        >
                          Installation Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Reference Type: activate to sort column ascending"
                          style={{ width: "17%" }}
                        >
                          Type
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Equipment Installation Staff Name: activate to sort column ascending"
                          style={{ width: "18%" }}
                        >
                          Assign Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Activity Status: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        equipmentInstallationScheduleList?.map((element, i) => (
                          <tr
                            role="row"
                            className="odd"
                            style={{
                              backgroundColor:
                                element.ActivityStatusName === "Assign Pending"
                                  ? "#cecdd036"
                                  : element.ActivityStatusName ===
                                    "Installation Pending"
                                  ? "#ebf6e4"
                                  : element.ActivityStatusName === ""
                                  ? "#fde5e5"
                                  : "",
                            }}
                          >
                            <td>{element.OrderBookingNo}</td>
                            <td>{element.PatientName}</td>
                            {/* <td>{element.ClientName}</td> */}
                            <td>{element.EquipmentName}</td>
                            {/* <td>{element.PatientNo}</td>
                            <td>{element.MobileNo}</td> */}
                            <td>{element.RequiredDate}</td>
                            <td>{element.ReferenceTypeName}</td>
                            <td>{element.EquipmentInstallationStaffName}</td>
                            <td>{element.ActivityStatusName}</td>
                            <td style={{ textAlign: "center" }}>
                              {element.ActivityStatus == 0 ? (
                                <div className="d-flex">
                                  <div
                                    className="btn btn-light shadow btn-xs sharp mr-1"
                                    title="Edit"
                                    onClick={() => {
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "OrderBookingEquipmentScheduleID",
                                        element.OrderBookingEquipmentScheduleIDEncrypt
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "EquipmentInstallationStaffID",
                                        element.EquipmentInstallationStaffIDEncrypt
                                      );

                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "OrderBookingID",
                                        element.OrderBookingIDEncrypt
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "OrderBookingNo",
                                        element.OrderBookingNo
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "PatientName",
                                        element.PatientName
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "ClientName",
                                        element.ClientName
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "PatientNo",
                                        element.PatientNo
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "EquipmentName",
                                        element.EquipmentName
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "RequiredDate",
                                        element.RequiredDate
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "ReturnDate",
                                        element.ReturnDate
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "ReferenceTypeName",
                                        element.ReferenceTypeName
                                      );

                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "ActivityStatusName",
                                        element.ActivityStatusName
                                      );
                                      formikEquipmentInstallationSchedule.setFieldValue(
                                        "MobileNo",
                                        element.MobileNo
                                      );

                                      setEquipmentInstallationScheduleModal(
                                        true
                                      );
                                    }}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <span
              class="badge"
              style={{ backgroundColor: "#cecdd036", color: "#3e4954" }}
            >
              Assign Pending
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#ebf6e4", color: "#3e4954" }}
            >
              Installation Pending
            </span>
            &nbsp;
          </div>

          <Modal
            className="modal fade"
            show={equipmentInstallationScheduleModal}
            onHide={setEquipmentInstallationScheduleModal}
            size="lg"
          >
            <FormikProvider value={formikEquipmentInstallationSchedule}>
              <Form onSubmit={formikEquipmentInstallationSchedule.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">
                    Assign Staff for Equipment Installation
                  </h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setEquipmentInstallationScheduleModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Patient Name </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}> {PatientName}</Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Client Name </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={6}> {ClientName}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Patient No </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{PatientNo}</Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Mobile No </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{MobileNo}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Order Booking No </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{OrderBookingNo}</Col>
                      </Row>
                    </Col>

                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Installation Date </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{RequiredDate}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Equipment Name </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{EquipmentName}</Col>
                      </Row>
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row className="mt-3">
                    <Col lg={6}>
                      <Field
                        label={"Staff Name"}
                        name="EquipmentInstallationStaffID"
                        options={staffOptions}
                        placeholder={"Select Staff Name"}
                        component={CustomSelect}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-xs btn-primary">
                    Save
                  </button>

                  <button
                    type="button"
                    onClick={() => setEquipmentInstallationScheduleModal(false)}
                    className="btn btn-xs btn-light"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default EquipmentInstallationScheduleList;
