import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import PaginationButtons from "../../../Custom/Pagination";
import {
  POST_StaffReviewRatingList,
  POST_ClientStaffReviewRatingPublished,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";

import swal from "sweetalert";
import { Form } from "reactstrap";

import * as Yup from "yup";
import { Row, Col, Accordion } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Field, FormikProvider, useFormik } from "formik";

import {
  starOne,
  starTwo,
  starThree,
  starFour,
  starFive,
  starZero,
} from "../../../Custom/CustomStar";
const Rating = () => {
  const [reviewList, setReviewList] = useState([]);
  const [recordCount, setRecordCount] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
      IsPublishedStatus: -1,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { CompanyID, IsPublishedStatus },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_StaffReviewRatingList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      StaffIDEncrypted: "",
      CompanyIDEncrypted: CompanyID,
      IsPublished:
        IsPublishedStatus == 0
          ? -1
          : IsPublishedStatus == 1
          ? 0
          : IsPublishedStatus == 2
          ? 1
          : -1,
    });
    if (response.IsSuccess) {
      setRecordCount(response.TotalRecordCount);
      setReviewList(response.StaffReviewRatingList);
      setCurrentSizePerPage(sizePerPage);
      if (page > 1 && response?.StaffReviewRatingList?.length === 0) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    loadCompanyDDL();
  }, []);

  const isPublishedStatusOptions = [
    { label: "All", value: 0 },
    { label: "Un-Published", value: 1 },
    { label: "Published", value: 2 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const publishReviews = async (reviewID, reviewStatus) => {
    setIsLoading(true);
    let response = await post(POST_ClientStaffReviewRatingPublished, {
      StaffReviewRatingIDEncrypt: reviewID,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
      IsPublished: !reviewStatus,
    });
    if (response.IsSuccess) {
      swal({
        title: "Success",
        text: `Record has been ${reviewStatus ? "unpublished" : "published"}.`,
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            motherMenu="Transaction"
            activeMenu="Ratings and Reviews"
          />
          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Ratings and Reviews List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          <Row>
                            {objLogin?.CompanyType === 1 &&
                            objLogin?.UserType == 1 ? (
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            <Col lg={3}>
                              <Field
                                label={"Status"}
                                name="IsPublishedStatus"
                                placeholder={"Select Status"}
                                options={isPublishedStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>
          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="SchedulePackageAndHealthCheckup_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped SchedulePackageAndHealthCheckup_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Order No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Client Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Client Name
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Review Message: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Review Message
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Rating: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Rating
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        reviewList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.OrderBookingNo}</td>
                            <td>{element.ClientName}</td>
                            <td>{element.StaffName}</td>
                            <td>{element.ReviewMessage}</td>
                            <td>
                              {element.StaffRating === "1"
                                ? starOne
                                : element.StaffRating === "2"
                                ? starTwo
                                : element.StaffRating === "3"
                                ? starThree
                                : element.StaffRating === "4"
                                ? starFour
                                : element.StaffRating === "5"
                                ? starFive
                                : starZero}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {objLogin?.UserType == 1 ||
                              (objLogin?.UserType == 2 &&
                                UserForms?.filter(
                                  (item) => item.FormID == 38 && item.EditAction
                                ).length > 0) ? (
                                <Link
                                  className="btn btn-light shadow btn-xs sharp"
                                  title={
                                    element.IsPublished
                                      ? "Published"
                                      : "Unpublished"
                                  }
                                  onClick={() =>
                                    swal({
                                      title: ` ${
                                        element.IsPublished
                                          ? "Unpublish"
                                          : "Publish"
                                      }`,
                                      text: `Are you sure you want to ${
                                        element.IsPublished
                                          ? "unpublish"
                                          : "publish"
                                      } the feedback?`,
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((confirmDelete) => {
                                      if (confirmDelete) {
                                        publishReviews(
                                          element.StaffReviewRatingIDEncrypt,
                                          element.IsPublished
                                        );
                                      }
                                    })
                                  }
                                >
                                  <i
                                    className={
                                      element.IsPublished
                                        ? "fa fa-toggle-on"
                                        : "fa fa-bullhorn"
                                    }
                                    style={{
                                      color: element.IsPublished
                                        ? "green"
                                        : "black",
                                    }}
                                  ></i>
                                </Link>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default Rating;
