import { POST_GetNotificationsList } from "../../jsx/Helper/url_helper";

// **  Initial State
const initialState = {
  NotificationList: null,
  TotalUnreadCount: 0,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_GetNotificationsList:
      return {
        ...state,
        NotificationList: action.NotificationList,
        TotalUnreadCount: action.TotalUnreadCount,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
