export const isAuthenticated = (state) => {
  if (state.auth.auth.idToken) return true;
  return false;
};

//Added by Hitesh Vaghela
export const isAuthenticatedUser = (state) => {
  if (state.auth.auth.Token) return true;
  return false;
};
