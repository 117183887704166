import { ErrorMessage } from "formik";
import {
  Input,
  Label,
  InputGroup,
  // InputGroupAddon,
  // InputGroupText,
} from "reactstrap";

const CustomInput = ({
  field,
  form: { touched, errors },
  label,
  placeholder,
  required,
  icon,
  iconClass,
  ...props
}) => {
  // const CustomInput = ({ field, form: { errors }, label, placeholder, ...props }) => {

  const numberInputOnWheelPreventChange = (e) => {
    let isFocused = false;
    if (document.activeElement == e.target) {
      isFocused = true;
    }

    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    if (isFocused) {
      // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.target.focus();
      }, 0);
    }
  };

  const numberInputKeyDownPreventChange = (e) => {
    const key = e.charCode || e.keyCode;

    // Disable Up and Down Arrows on Keyboard
    if (key === 38 || key === 40) {
      e.preventDefault();
    } else {
      return;
    }
  };

  return (
    <div className="mb-3">
      {label && (
        <Label>
          {label} {required && <span className="text-danger">*</span>}
        </Label>
      )}
      <InputGroup>
        {icon ? (
          // <InputGroupAddon addonType="prepend">
          //   <InputGroupText>
          //     <i class={iconClass}></i>
          //   </InputGroupText>
          // </InputGroupAddon>
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className={iconClass}></i>
            </span>
          </div>
        ) : (
          ""
        )}
        <Input
          {...field}
          {...props}
          placeholder={placeholder}
          // invalid={touched[field.name] && !!errors[field.name]}
          // valid={touched[field.name] && !errors[field.name]}
          autoComplete={"off"}
          className={`${props.passwordEye ? "passwordInput" : ""}`}
          onWheel={numberInputOnWheelPreventChange} // to Prevent the input value change on mouse scroll
          onKeyDown={numberInputKeyDownPreventChange} // to Disable Up and Down Arrows on Keyboard
        />
        {props.passwordEye ? (
          <div className="input-group-append">
            <span
              className="input-group-text passwordInputEye"
              style={{
                background: "transparent",
                borderColor:
                  // touched[field.name] && !errors[field.name]
                  //   ? "#dc3545"
                  //   : touched[field.name] && !!errors[field.name]
                  //   ? "#dc3545"
                  //   :
                  "#ced4da",
              }}
            >
              <i
                onClick={(e) => {
                  props.setIsPassword(props.type === "password" ? false : true);
                }}
                className={`fa ${
                  props.type === "password" ? "fa-eye-slash" : "fa-eye"
                }`}
              ></i>
            </span>
          </div>
        ) : (
          ""
        )}
      </InputGroup>
      {/* <Input {...field} {...props} placeholder={placeholder} invalid={!!errors[field.name]} valid={touched[field.name] && !errors[field.name]} /> */}
      {/* <Input {...field} {...props} invalid={!!errors[field.name]} placeholder={placeholder} /> */}
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};

export default CustomInput;
