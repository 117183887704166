import { ErrorMessage } from "formik";
import { Label } from "reactstrap";
import Select from "react-select";
import { reactSelectCustomStyles } from "../Custom/reactCustomSelect";

const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    ></div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  // color: state.isFocused ? "#36C95F" : "black",
  control: (base, state) => ({
    ...base,
    // borderColor: "#84d8e2",
    boxShadow: "#c93636",
    borderRadius: "10px",
    height: "56px",
    // "&:hover": {
    //   borderColor: "#84d8e2",
    // },
    "&:focus-within": {
      borderColor: "#36C95F",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#36C95F" : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected ? "#36C95F" : "rgb(222, 235, 255)",
    },
  }),
});

const CustomSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  isMulti,
  required,
  placeholder,
  onChange,
  options,
  ...props
}) => {
  let values = [];
  if (isMulti === true && Array.isArray(field?.value)) {
    values =
      options?.filter(({ value }) =>
        field?.value?.some((option) => option.value === value)
      ) ?? [];
  } else {
    values = options.find((option) => option.value === field.value) ?? [];
  }
  return (
    <div className="mb-3">
      <Label>
        {label} {required && <span className="text-danger">*</span>}
      </Label>
      <Select
        {...field}
        {...props}
        closeMenuOnSelect={false}
        options={options}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        value={values}
        placeholder={placeholder}
        isClearable={true}
        classNamePrefix={`select-${field.name}`}
        onChange={(event) => {
          if (onChange) {
            onChange(event);
          } else {
            if (isMulti === true) {
              // setFieldValue(field.name, event ?? [])
              setFieldValue(field.name, event ?? []);
            } else {
              // setFieldValue(field.name, event?.value ?? null)
              setFieldValue(field.name, event?.value ?? "");
            }
          }
        }}
        className={`${
          !!touched[field.name] && !!errors[field.name] && "border-danger"
        }`}
        isMulti={isMulti === true}
        // styles={reactSelectCustomStyles}
      />
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};

export default CustomSelect;
