import { post } from "../../jsx/Helper/api_helper";
import { POST_GetCategoryListAdminByCategoryTypeWithPagination } from "../../jsx/Helper/url_helper";

export const CATEGORY_LIST = (payload) => async (dispatch) => {
  //console.log("CategoryList ~ payload", payload);
  try {
    const response = await post(
      POST_GetCategoryListAdminByCategoryTypeWithPagination,
      payload
    );
    if (!response?.IsSuccess) {
      console.log("Error!", response.MessageCode, "error");
      return false;
    } else {
      dispatch({
        type: POST_GetCategoryListAdminByCategoryTypeWithPagination,
        CategoryList: response,
        // CategoryList: response.List,
      });
      return response;
    }
  } catch (err) {
    console.log("Error!", err?.message, "error");
  }
};

// export default CATEGORY_LIST;
