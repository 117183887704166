import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, post, postFormData } from "../../../Helper/api_helper";
import Cropper from "react-cropper";
import { dataUrlToFileUsingFetch } from "../../../Custom/Utils";
import "cropperjs/dist/cropper.css";
import RecommendationsImage from "../../../../images/avatar/500x500.png";

import swal from "sweetalert";
import { t } from "i18next";

import {
  GET_CompanyDropDownList,
  POST_RecommendationsDetailByID,
  POST_RecommendationsInsert,
  POST_RecommendationsUpdate,
  POST_RecommendationsPhotoUpdate,
} from "../../../Helper/url_helper";

const AddRecommendations = () => {
  const { RecommendationIDEncryptParam } = useParams();
  const history = useHistory();
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);

  const validationSchema = Yup.object().shape({
    CompanyID: Yup.string().required("Company is required field"),
    PersonName: Yup.string().required("Person Name is required field"),
    Designation: Yup.string().required("Designation is required field"),
    ExperienceLine: Yup.string().required("Experience Line is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      RecommendationIDEncrypt: RecommendationIDEncryptParam
        ? window.atob(RecommendationIDEncryptParam)
        : "",
      CompanyID: AuthUser?.CompanyIDEncrypt,
      PersonName: "",
      Designation: "",
      ExperienceLine: "",
      MessageText: "",
      Status: true,
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: { RecommendationIDEncrypt, CompanyID },
  } = formik;

  useEffect(() => {
    setIsLoading(true);
    loadCompanyDDL();
    if (RecommendationIDEncryptParam) {
      // setIsLoading(true);
      post(POST_RecommendationsDetailByID, {
        RecommendationIDEncrypt: window.atob(RecommendationIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            ...res,
            RecommendationIDEncrypt: res.RecommendationIDEncrypt,
            CompanyID: res.CompanyIDEncrypt,
            // PersonName: res.PersonName,
            // Designation: res.Designation,
            // ExperienceLine: res.ExperienceLine,
            // MessageText: res.MessageText,
            Status: res.IsActive,
          });
          if (res.PhotoUrl) {
            setRecommendationsPhoto(res.PhotoUrl);
          }
        }
      });

      setIsLoading(false);
    }
  }, []);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (recommendationsPhotoFile === null && recommendationsPhoto === null) {
      setValidationRecommendationsPhoto("Recommendations Photo is required");
      return false;
    }

    setIsLoading(true);

    const data = {
      ...values,
      IsActive: values.Status,
      ModifiedByEncrypt: AuthUser.LoginIDEncrypt,
      CreatedByEncrypt: AuthUser.LoginIDEncrypt,
      CompanyIDEncrypt: CompanyID,
    };

    post(
      RecommendationIDEncrypt
        ? POST_RecommendationsUpdate
        : POST_RecommendationsInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          // if (!RecommendationIDEncrypt) {
          //   setFieldValue(
          //     "RecommendationIDEncrypt",
          //     response.EncryptID
          //   );
          // }
          if (recommendationsPhotoFile) {
            setIsLoading(true);
            let formData = new FormData();
            formData.append(
              "RecommendationIDEncrypted",
              !RecommendationIDEncrypt
                ? response.EncryptID
                : RecommendationIDEncrypt
            );

            formData.append("PhotoUrl", recommendationsPhotoFile);

            postFormData(POST_RecommendationsPhotoUpdate, formData)
              .then((res) => {
                if (res.IsSuccess) {
                  setIsLoading(false);
                } else {
                  console.log(res);
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
              });
          }

          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/recommendations");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const [validationRecommendationsPhoto, setValidationRecommendationsPhoto] =
    useState("");
  const [recommendationsPhoto, setRecommendationsPhoto] = useState(null);
  const [recommendationsPhotoFile, setRecommendationsPhotoFile] =
    useState(null);
  const [fileKeyRecommendationsPhoto, setFileKeyRecommendationsPhoto] =
    useState();
  const [zoomRecommendationsPhoto, setZoomRecommendationsPhoto] = useState(0.5);
  const [
    openCropperModalRecommendationsPhoto,
    setOpenCropperModalRecommendationsPhoto,
  ] = useState(false);
  const [imageRecommendationsPhoto, setImageRecommendationsPhoto] = useState();
  const [cropperRecommendationsPhoto, setCropperRecommendationsPhoto] =
    useState();

  const getCropDataRecommendationsPhoto = () => {
    if (typeof cropperRecommendationsPhoto !== "undefined") {
      handleUploadRecommendationsPhoto(
        cropperRecommendationsPhoto.getCroppedCanvas().toDataURL()
      );
      setRecommendationsPhoto(
        cropperRecommendationsPhoto.getCroppedCanvas().toDataURL()
      );
      // setStaffPhotox("")
    }
  };

  const handleUploadRecommendationsPhoto = async (url) => {
    const outputFileRecommendationsPhoto = await dataUrlToFileUsingFetch(
      url,
      "output.png",
      "image/png"
    );
    //const file = dataUrlToFile(url, "output.png");
    setRecommendationsPhotoFile(outputFileRecommendationsPhoto);
    setValidationRecommendationsPhoto("");
  };

  const uploadRecommendationsPhoto = (event) => {
    if (event.target.files.length === 1) {
      var pattern = /image-*/;
      if (event.target.files[0].type.match(pattern)) {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
          files = event.dataTransfer.files;
        } else if (event.target) {
          files = event.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImageRecommendationsPhoto(reader.result);
        };
        reader.readAsDataURL(files[0]);
        setOpenCropperModalRecommendationsPhoto(true);
      }
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${
          RecommendationIDEncryptParam ? "Edit" : "Add"
        } Recommendations`}
        motherMenu={<Link to="/recommendations">Recommendations</Link>}
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <Field
                          label={"Company"}
                          name="CompanyID"
                          placeholder={"Select Company"}
                          options={companyOptions}
                          component={CustomSelect}
                          required
                          isClearable={false}
                          isDisabled={RecommendationIDEncrypt ? true : false}
                        />
                      </Col>
                    </Row>
                    <hr></hr>

                    <Row className="mb-2">
                      <Col lg={4}>
                        <Field
                          type="text"
                          label={"Person Name"}
                          name="PersonName"
                          id="PersonName"
                          placeholder={"Enter Person Name"}
                          component={CustomInput}
                          onChange={(e) => {
                            setFieldValue(
                              "PersonName",
                              e.target.value.replace(/[^A-Za-z - . () ]/g, "")
                            );
                          }}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          label={"Designation"}
                          name="Designation"
                          id="Designation"
                          placeholder={"Enter Designation"}
                          component={CustomInput}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          label={"Experience Line"}
                          name="ExperienceLine"
                          id="ExperienceLine"
                          placeholder={"Enter Experience Line"}
                          component={CustomInput}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={8}>
                        <Field
                          type="textarea"
                          label={"Message Text"}
                          name="MessageText"
                          placeholder={"Enter Message Text"}
                          rows={6}
                          component={CustomInput}
                          style={{ resize: "none" }}
                          maxLength={500}
                        />
                      </Col>

                      <Col lg={4}>
                        <Label>
                          Upload Photo <span className="text-danger">*</span>{" "}
                          <span style={{ color: "red", fontSize: "10px" }}>
                            (500 x 500)
                          </span>
                        </Label>

                        <div className="image-placeholder staffPhoto-placeholder">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={uploadRecommendationsPhoto}
                              key={fileKeyRecommendationsPhoto || ""}
                            />
                            <label htmlFor="imageUpload" name=""></label>
                          </div>
                          <div className="avatar-preview">
                            <div id="imagePreview">
                              <img
                                id="saveImageFile"
                                src={
                                  recommendationsPhoto
                                    ? recommendationsPhoto
                                    : RecommendationsImage
                                }
                                alt={"Recommendations Photo"}
                              />
                            </div>
                          </div>
                        </div>
                        <span
                          style={{
                            color: "#ec4561",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                          }}
                        >
                          {validationRecommendationsPhoto}
                        </span>
                      </Col>
                    </Row>

                    <button type="submit" className="btn mr-2 btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={(e) => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </button>
                  </Form>
                  <Modal
                    className="fade text-center"
                    show={openCropperModalRecommendationsPhoto}
                    onHide={setOpenCropperModalRecommendationsPhoto}
                  >
                    <Modal.Header>
                      <Modal.Title>Recommendations Photo</Modal.Title>
                      <button
                        type="button"
                        className="btn close"
                        onClick={() =>
                          setOpenCropperModalRecommendationsPhoto(false)
                        }
                      >
                        <span>×</span>
                      </button>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12">
                          <Cropper
                            //style={{ height: 400, width: 400 }}
                            // zoomTo={0.5}
                            zoomTo={zoomRecommendationsPhoto}
                            initialAspectRatio={1 / 1}
                            aspectRatio={1 / 1}
                            src={imageRecommendationsPhoto}
                            viewMode={1}
                            minCropBoxHeight={500}
                            minCropBoxWidth={500}
                            maxCropBoxHeight={500}
                            maxCropBoxWidth={500}
                            background={false}
                            responsive={true}
                            // autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              setCropperRecommendationsPhoto(instance);
                            }}
                            //guides={false}
                            checkCrossOrigin={false}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Row style={{ width: "100%" }}>
                        <Col md="4">
                          <Button
                            className="btn btn-success btn-block btn-xs mt-2"
                            onClick={() => {
                              setOpenCropperModalRecommendationsPhoto(false);
                              setFileKeyRecommendationsPhoto(
                                Math.random().toString(9).substring(2, 9)
                              );
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col md="2">
                          <Button
                            className="btn btn-success btn-block btn-xs mt-2"
                            onClick={() => {
                              setZoomRecommendationsPhoto(
                                zoomRecommendationsPhoto + 0.5
                              );
                            }}
                          >
                            <i className="fa fa-search-plus"></i>
                          </Button>
                        </Col>
                        <Col md="2">
                          <Button
                            className="btn btn-success btn-block btn-xs mt-2"
                            onClick={() => {
                              setZoomRecommendationsPhoto(
                                zoomRecommendationsPhoto > 0
                                  ? zoomRecommendationsPhoto - 0.5
                                  : zoomRecommendationsPhoto
                              );
                            }}
                          >
                            <i className="fa fa-search-minus"></i>
                          </Button>
                        </Col>
                        <Col md="4">
                          <Button
                            className="btn btn-success btn-block btn-xs mt-2 "
                            onClick={() => {
                              getCropDataRecommendationsPhoto();
                              setOpenCropperModalRecommendationsPhoto(false);
                            }}
                          >
                            Crop Image
                          </Button>
                        </Col>
                      </Row>
                    </Modal.Footer>
                  </Modal>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddRecommendations;
