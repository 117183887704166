import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik, FieldArray } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import CustomMultiSelect from "../../../Custom/CustomMultiSelect";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  POST_GetPathologyReportsDDLList,
  POST_GetRadiologyReportsDDLList,
  POST_GetServiceDDLList,
  POST_GetHealthCheckupsDetailByID,
  POST_HealthCheckupsCreate,
  POST_HealthCheckupsUpdate,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

const AddHealthCheckUp = () => {
  const { HealthCheckupIDEncryptParam } = useParams();
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);
  const [optionService, setOptionService] = useState([]);
  const [optionPathologyReports, setOptionPathologyReports] = useState([]);
  const [optionRadiologyReports, setOptionRadiologyReports] = useState([]);

  const [mode, setMode] = useState(1);

  const validationSchema = Yup.object().shape({
    HealthCheckupName: Yup.string().required(
      "Health Checkup Name is required field"
    ),
    AdvancePercentage: Yup.number()
      .min(1, "Health Checkup % must be greater than 0.")
      .required("Health Checkup Advance % is required field"),
    // Duration: Yup.string().required("Duration is required field"),
    // Quantity: Yup.string().required("Quantity is required field"),
    // PathologyReports: Yup.array()
    //   .required("Pathology Reports is a required field")
    //   .nullable(),
    // RadiologyReports: Yup.array()
    //   .required("Radiology Reports is a required field")
    //   .nullable(),
    // Services: Yup.array().required("Services is a required field").nullable(),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      HealthCheckupID: HealthCheckupIDEncryptParam
        ? window.atob(HealthCheckupIDEncryptParam)
        : "",
      HealthCheckupName: "",
      AdvancePercentage: 50,
      Duration: 0,
      Quantity: 0,
      PathologyReports: [],
      RadiologyReports: [],
      IsMultiCheckUp: false,
      Services: [],
      Status: true,
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: { HealthCheckupID },
  } = formik;

  useEffect(() => {
    getPathologyReportList();
    getRadiologyReportList();
    getServiceList();
    if (HealthCheckupIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetHealthCheckupsDetailByID, {
        HealthCheckupIDEncrypt: window.atob(HealthCheckupIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          let PathologyReportsList =
            res.HealthCheckupInclusionPathologyReportsList;
          let RadiologyReportsList =
            res.HealthCheckupInclusionRadiologyReportsList;
          let ServicesList = res.HealthCheckupInclusionServicesList;
          setValues({
            HealthCheckupID: window.atob(HealthCheckupIDEncryptParam),
            HealthCheckupName: res.HealthCheckupName,
            AdvancePercentage: res.AdvancePercentage,
            Duration: res.HealthCheckupDuration,
            Quantity: res.HealthCheckupQty,
            PathologyReports:
              PathologyReportsList.length > 0
                ? PathologyReportsList.map((x) => ({
                    label: x.PathologyReportName,
                    value: x.PathologyReportID,
                  }))
                : "",
            RadiologyReports:
              RadiologyReportsList.length > 0
                ? RadiologyReportsList.map((x) => ({
                    label: x.RadiologyReportName,
                    value: x.RadiologyReportID,
                  }))
                : "",
            IsMultiCheckUp: res.IsMultipleCheckup,
            Services:
              ServicesList.length > 0
                ? ServicesList.map((x) => ({
                    label: x.ServiceName,
                    value: x.ServiceID,
                  }))
                : "",
            Status: res.IsActive,
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  const getRadiologyReportList = () => {
    setIsLoading(true);
    get(POST_GetRadiologyReportsDDLList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionRadiologyReports(
            res.RadiologyReportsDDLList.map((item) => ({
              label: item.RadiologyReportName,
              value: item.RadiologyReportID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };
  const getServiceList = () => {
    setIsLoading(true);
    get(POST_GetServiceDDLList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionService(
            res.ServiceDDLList.map((item) => ({
              label: item.ServiceName,
              value: item.ServiceID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };
  const getPathologyReportList = () => {
    setIsLoading(true);
    get(POST_GetPathologyReportsDDLList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionPathologyReports(
            res.PathologyReportsDDLList.map((item) => ({
              label: item.PathologyReportName,
              value: item.PathologyReportID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (
      values.PathologyReports?.length === 0 &&
      values.RadiologyReports?.length === 0 &&
      values.Services?.length === 0
    ) {
      swal({
        //title: "warning",
        text: "Pathology Reports OR Radiology Reports OR Services is required.",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setIsLoading(true);

    const data = {
      HealthCheckupIDEncrypt: HealthCheckupID,
      HealthCheckupName: values.HealthCheckupName.trim(),
      AdvancePercentage: values.AdvancePercentage.toString(),
      IsMultipleCheckup: values.IsMultiCheckUp,
      HealthCheckupDuration: values.Duration,
      HealthCheckupQty: values.Quantity,
      PathologyReportsIDs: Array.from(
        values.PathologyReports,
        (x) => x.value
      ).join(","),
      RadiologyReportsIDs: Array.from(
        values.RadiologyReports,
        (x) => x.value
      ).join(","),
      InclusionServicesIDs: Array.from(values.Services, (x) => x.value).join(
        ","
      ),
      IsActive: values.Status,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
    };
    post(
      HealthCheckupID ? POST_HealthCheckupsUpdate : POST_HealthCheckupsCreate,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/health-checkup-list");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  // const HealthCheckupDurationOption = [
  //   { label: "3 Months", value: 1 },
  //   { label: "6 Months", value: 2 },
  //   { label: "12 Months", value: 3 },
  // ];

  // const HealthCheckupQuantityOption = [
  //   { label: "1", value: 1 },
  //   { label: "2", value: 2 },
  //   { label: "3", value: 3 },
  // ];

  const handleChangeIsMultiCheckup = (event) => {
    if (event.target.checked) {
      setMode(1);
    } else {
      setMode(0);
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            HealthCheckupIDEncryptParam
              ? "Edit Health Checkup"
              : "Add Health Checkup"
          }
          motherMenu={<Link to="/health-checkup-list">Health Checkup</Link>}
        />

        <div className="card">
          <div className="card-body">
            <div className="basic-form">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <Field
                        label={"Health Checkup Name"}
                        name="HealthCheckupName"
                        placeholder={"Enter Health Checkup Name"}
                        component={CustomInput}
                        // onChange={(e) => {
                        //   setFieldValue(
                        //     "HealthCheckupName",
                        //     e.target.value.replace(/[^A-Za-z - + () ]/g, "")
                        //   );
                        // }}
                        required
                        maxLength={200}
                      />
                    </Col>
                    {/* <Col lg={3}>
                    <div
                      className="custom-control custom-checkbox mb-3 checkbox-success"
                      style={{ marginTop: "35px", zIndex: 0 }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheckBox3"
                        onChange={handleChangeIsMultiCheckup}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckBox3"
                      >
                        Is Multiple Checkup
                      </label>
                    </div>
                  </Col>
                  {mode == 1 ? (
                    <>
                      <Col lg={3}>
                        <Field
                          label={"Duration"}
                          name="Duration"
                          placeholder={"Select Duration"}
                          options={HealthCheckupDurationOption}
                          component={CustomSelect}
                        />
                      </Col>
                      <Col lg={3}>
                        <Field
                          label={"Quantity"}
                          name="Quantity"
                          placeholder={"Select Quantity"}
                          options={HealthCheckupQuantityOption}
                          component={CustomSelect}
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )} */}
                  </Row>
                  <hr></hr>

                  <Row>
                    <Col lg={12}>
                      <Field
                        label={"Pathology Reports"}
                        name="PathologyReports"
                        options={optionPathologyReports}
                        component={CustomMultiSelect}
                        isMulti={true}
                        // required
                      />
                    </Col>
                  </Row>
                  <hr></hr>

                  <Row>
                    <Col lg={12}>
                      <Field
                        label={"Radiology Reports"}
                        name="RadiologyReports"
                        options={optionRadiologyReports}
                        component={CustomMultiSelect}
                        isMulti={true}
                        // required
                      />
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row>
                    <Col lg={12}>
                      <Field
                        label={"Services"}
                        name="Services"
                        options={optionService}
                        component={CustomMultiSelect}
                        isMulti={true}
                        // required
                      />
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="mb-3">
                    <Col lg={3}>
                      <Field
                        type="number"
                        label={"Health Checkup Advance %"}
                        name="AdvancePercentage"
                        placeholder={"Enter Health Checkup Advance %"}
                        component={CustomInput}
                        required
                        min={0}
                        maxLength={5}
                        onChange={(e) => {
                          if (e.target.value <= 100) {
                            setFieldValue(
                              "AdvancePercentage",
                              e.target.value.slice(0, 5)
                            );
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <button type="submit" className="btn mr-2 btn-primary btn-sm">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-light"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </button>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddHealthCheckUp;
