import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik, FieldArray } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Row, Col, Card, Tab, Nav, Button } from "react-bootstrap";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";

import {
  POST_GetStaffDutySlotDateWiseDetailByID,
  POST_StaffDutySlotDateWiseInsert,
  POST_StaffDutySlotDateWiseUpdate,
  GET_CompanyDropDownList,
  POST_AdminStaffsListDDLByCompanyID,
} from "../../../Helper/url_helper";

const AddStaffDutySlotDateWise = () => {
  const { StaffDutySlotIDEncryptParam } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);

  // const [time, setTime] = useState(new Date());
  // const [endTime, setEndTime] = useState(new Date());

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchema = Yup.object().shape({
    StaffID: Yup.string().required("Staff Name is required field"),
    CompanyID: Yup.string().required("Company is required field"),
    FromDate: Yup.string().required("From Date is required field"),
    ToDate: Yup.string().required("To Date is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      StaffDutySlotIDEncrypt: StaffDutySlotIDEncryptParam
        ? window.atob(StaffDutySlotIDEncryptParam)
        : "",
      CompanyID: objLogin?.CompanyIDEncrypt,
      StaffID: "",
      FromDate: "",
      ToDate: "",
      DutySlotStartTime: new Date(),
      DutySlotEndTime: new Date(),
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: {
      StaffDutySlotIDEncrypt,
      CompanyID,
      DutySlotStartTime,
      DutySlotEndTime,
    },
  } = formik;

  useEffect(() => {
    loadCompanyDDL();
    if (StaffDutySlotIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetStaffDutySlotDateWiseDetailByID, {
        StaffDutySlotIDEncrypt: window.atob(StaffDutySlotIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            ...res,
            StaffDutySlotIDEncrypt: res.StaffDutySlotIDEncrypt,
            CompanyID: res.CompanyIDEncrypt,
            StaffID: res.StaffIDEncrypt,
            FromDate: new Date(res.DutyDate),
            ToDate: new Date(res.DutyDate),

            DutySlotStartTime: moment(
              `2023-01-01 ${res.DutySlotStartTime.trim()}`,
              "YYYY-MM-DD hh:mm A"
            ).format("YYYY-MM-DD HH:mm"),
            DutySlotEndTime: moment(
              `2023-01-01 ${res.DutySlotEndTime.trim()}`,
              "YYYY-MM-DD hh:mm A"
            ).format("YYYY-MM-DD HH:mm"),
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (CompanyID) {
      getStaffList(CompanyID);
    } else {
      formik.setFieldValue("CompanyID", "");
      setStaffOptions([]);
    }
  }, [CompanyID]);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getStaffList = async (companyID) => {
    await post(POST_AdminStaffsListDDLByCompanyID, {
      CompanyIDEncrypt: companyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setStaffOptions(
            res.StaffsList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  //   const optionWeekDays = [
  //     { label: "Monday", value: 2 },
  //     { label: "Tuesday", value: 3 },
  //     { label: "Wednesday", value: 4 },
  //     { label: "Thursday", value: 5 },
  //     { label: "Friday", value: 6 },
  //     { label: "Saturday", value: 7 },
  //     { label: "Sunday", value: 1 },
  //   ];

  //   const optionHours = [
  //     { label: "12 Hours", value: 1 },
  //     { label: "24 Hours", value: 2 },
  //   ];

  const handleValidSubmit = (values, { setSubmitting }) => {
    /* 
    //CODE COMMENTED B'COZ of NIGHT DUTY TIME Ex. 8:00 PM To 8:00 AM
    // if (
    //   parseInt(moment(values.DutySlotStartTime).format("H")) >
    //   parseInt(moment(values.DutySlotEndTime).format("H"))
    // ) {
    //   swal({
    //     title: "warning",
    //     text: "End Time must be greater then Start Time!",
    //     icon: "warning",
    //     button: "OK",
    //   });
    //   return;
    // }
    */
    setIsLoading(true);
    const data = {
      ...values,
      StaffIDEncrypt: values.StaffID,
      DutyFromDate: `${moment(values.FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      DutyToDate: `${moment(values.ToDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      DutySlotStartTime: moment(values.DutySlotStartTime).format("h:00 A"),
      DutySlotEndTime: moment(values.DutySlotEndTime).format("h:00 A"),
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
    };
    post(
      StaffDutySlotIDEncrypt
        ? POST_StaffDutySlotDateWiseUpdate
        : POST_StaffDutySlotDateWiseInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          formik.resetForm();
          history.push("/staff-duty-slots-date-wise");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={`${
            StaffDutySlotIDEncryptParam ? "Edit" : "Add"
          } Staff Duty Slots Date Wise`}
          motherMenu={
            <Link to="/staff-duty-slots-date-wise">
              Staff Duty Slots Date Wise
            </Link>
          }
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Company"}
                            name="CompanyID"
                            placeholder={"Select Company"}
                            options={companyOptions}
                            component={CustomSelect}
                            required
                            isClearable={false}
                            isDisabled={
                              StaffDutySlotIDEncryptParam ? true : false
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Staff Name"}
                            name="StaffID"
                            options={staffOptions}
                            placeholder={"Select Staff Name"}
                            component={CustomSelect}
                            isDisabled={
                              StaffDutySlotIDEncryptParam ? true : false
                            }
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>

                      <Row>
                        <Col lg={3}>
                          <Field
                            type="text"
                            label={"From Date"}
                            name="FromDate"
                            id="FromDate"
                            placeholder={"From Date"}
                            component={CustomFlatpickr}
                            options={{
                              minDate: moment(new Date()).format("YYYY-MM-DD"),
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            disabled={
                              StaffDutySlotIDEncryptParam ? true : false
                            }
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="text"
                            label={"To Date"}
                            name="ToDate"
                            placeholder={"To Date"}
                            component={CustomFlatpickr}
                            options={{
                              minDate: moment(new Date()).format("YYYY-MM-DD"),
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            disabled={
                              StaffDutySlotIDEncryptParam ? true : false
                            }
                            required
                          />
                        </Col>
                        <Col lg={2}>
                          <Label>
                            Start Time
                            <span className="text-danger">*</span>
                          </Label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              autoOk
                              name={`DutySlotStartTime`}
                              placeholder={"Select Start Time"}
                              value={DutySlotStartTime}
                              ampmInClock={true}
                              onChange={(newValue) => {
                                setFieldValue(
                                  `DutySlotStartTime`,
                                  new Date(newValue)
                                );
                              }}
                              minutesStep={0}
                              format={"h:00 a"}
                              // onChange={(newValue) => {
                              //   setTime(new Date(newValue));
                              // }}
                              // minutesStep={0}
                              // format={"h:00 a"}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col lg={2}>
                          <Label>
                            End Time
                            <span className="text-danger">*</span>
                          </Label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              name={`DutySlotEndTime`}
                              autoOk
                              placeholder={"Select End Time"}
                              ampm={true}
                              value={DutySlotEndTime}
                              onChange={(newValue) => {
                                setFieldValue(
                                  `DutySlotEndTime`,
                                  new Date(newValue)
                                );
                              }}
                              minutesStep={0}
                              format={"h:00 a"}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn mr-2 btn-primary btn-sm"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light"
                            onClick={(e) => {
                              history.goBack();
                            }}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddStaffDutySlotDateWise;
