import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Accordion, Row, Col, Modal } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomInput from "../../../Custom/CustomInput";
import PageTitle from "../../../layouts/PageTitle";
import { Form } from "reactstrap";
import PaginationButtons from "../../../Custom/Pagination";
import {
  POST_GetOrderBookingEquipmentPDCDetailsList,
  POST_GetClientOrderBookingEquipmentDepositReceipt,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import * as Yup from "yup";
import swal from "sweetalert";
import moment from "moment";
import ModalPatientDetails from "../../../Custom/ModalPatientDetails";
import { Field, FormikProvider, useFormik } from "formik";

const EquipmentPDCList = () => {
  const [patientDetailModal, setPatientDetailModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [equipmentPDCList, setEquipmentPDCList] = useState([]);
  const [patientID, setPatientID] = useState("");
  const [reprintEquipmentPDCDetailModal, setReprintEquipmentPDCDetailModal] =
    useState(false);
  const [equipmentPDCDetailID, setEquipmentPDCDetailID] = useState("");

  const [companyOptions, setCompanyOptions] = useState([]);
  const [activeDefault, setActiveDefault] = useState(0);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      IsPDCReturned: -1,
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate, IsPDCReturned },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setIsLoading(true);
    let response = await post(POST_GetOrderBookingEquipmentPDCDetailsList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyID: CompanyID,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",
      IsPDCReturned:
        IsPDCReturned == 0
          ? -1
          : IsPDCReturned == 1
          ? 1
          : IsPDCReturned == 2
          ? 0
          : -1,
    });
    if (response.IsSuccess) {
      setRecordCount(response.TotalRecordCount);
      setEquipmentPDCList(response.OrderBookingEquipmentPDCDetailsList);
      setCurrentSizePerPage(sizePerPage);
      if (
        page > 1 &&
        response?.OrderBookingEquipmentPDCDetailsList?.length === 0
      ) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  const depositReturnedStatusOptions = [
    { label: "All", value: 0 },
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ];
  useEffect(() => {
    getData();
    loadCompanyDDL();
  }, []);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getEquipmentAdvanceReceipt = async (IsOriginal) => {
    setIsLoading(true);

    await post(POST_GetClientOrderBookingEquipmentDepositReceipt, {
      OrderBookingEquipmentPDCDetailIDEncrypt: equipmentPDCDetailID,
      IsOriginal: IsOriginal,
      WithoutLetterHead: true,
    })
      .then((response) => {
        window.open(response.FilePath);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Transaction" activeMenu="Equipment Deposit" />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Equipment Deposit List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          {objLogin?.CompanyType === 1 &&
                          objLogin?.UserType === 1 ? (
                            <Row>
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col lg={3}>
                              <Field
                                label={"Deposit Returned Status"}
                                name="IsPDCReturned"
                                placeholder={"Select  Status"}
                                options={depositReturnedStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>
          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 26 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-lg-block">
                <Link
                  to="/add-equipment-deposit"
                  className="btn btn-primary btn-xs btn-rounded"
                >
                  + Add Equipment Deposit
                </Link>
              </div>
            ) : (
              ""
            )}

            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="order_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Order No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "35%" }}
                        >
                          Patient Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Deposit Received Date: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Deposit Received Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Is Deposit Returned: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Is Deposit Returned
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Deposite Amount: activate to sort column ascending"
                          style={{ width: "10%", textAlign: "right" }}
                        >
                          Deposite Amount
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        equipmentPDCList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.OrderBookingNo}</td>
                            <td>{element.PatientName}</td>
                            <td>{element.PDCReceivedDate}</td>
                            <td>{element.PDCReturned}</td>
                            <td style={{ textAlign: "right" }}>
                              <i className="fa fa-rupee"></i>{" "}
                              {element.EquipmentDepositAmount}
                            </td>
                            <td>
                              <div className="d-flex">
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Patient Detail"
                                  onClick={() => {
                                    setPatientID(element.PatientIDEncrypt);
                                    setPatientDetailModal(true);
                                  }}
                                >
                                  <i class="fa fa-wheelchair"></i>
                                </Link>
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 26 && item.EditAction
                                  ).length > 0) ? (
                                  <Link
                                    to={`/edit-equipment-deposit/${window.btoa(
                                      element.OrderBookingEquipmentPDCDetailIDEncrypt
                                    )}`}
                                    className="btn btn-light shadow btn-xs sharp mr-1"
                                    title="Edit"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                                <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Reprint Receipt"
                                  onClick={() => {
                                    setEquipmentPDCDetailID(
                                      element.OrderBookingEquipmentPDCDetailIDEncrypt
                                    );
                                    setReprintEquipmentPDCDetailModal(true);
                                  }}
                                >
                                  <i class="fa fa-print" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            show={patientDetailModal}
            onHide={setPatientDetailModal}
            size="lg"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Patient Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setPatientDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalPatientDetails
                patientID={patientID}
                setPatientDetailModal={setPatientDetailModal}
              ></ModalPatientDetails>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={reprintEquipmentPDCDetailModal}
            onHide={setReprintEquipmentPDCDetailModal}
            backdrop={"static"}
            centered
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Reprint</h4>
                <button
                  type="button"
                  className="btn close"
                  onClick={() => setReprintEquipmentPDCDetailModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div class="text-center">
                  <button
                    type="button"
                    className="btn btn-primary m-1"
                    onClick={() => getEquipmentAdvanceReceipt(true)}
                  >
                    Origional Receipt
                  </button>
                  <button
                    type="button"
                    className="btn btn-light m-1"
                    onClick={() => getEquipmentAdvanceReceipt(false)}
                  >
                    Duplicate Receipt
                  </button>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light m-1"
                  onClick={() => setReprintEquipmentPDCDetailModal(false)}
                >
                  Close
                </button>
              </div>
            </Spin>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default EquipmentPDCList;
