import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { Link, useHistory, useParams } from "react-router-dom";
import { Modal, Nav, Tab } from "react-bootstrap";
import {
  POST_GetClientDetailByID,
  POST_GetCategoryListByCategoryType,
  POST_GetPatientOrderHistoryList,
  POST_AdminOrderBookingDelete,
  POST_AdminOrderBookingStatusUpdate,
  POST_ClientPatientsSetActiveInActive,
  POST_ClientPatientsDelete,
  POST_GetClientPatientsList,
  POST_ClientPatientAddressInsert,
  POST_GetPatientAddressList,
  POST_GetStateList,
  POST_GetCityList,
  POST_GetAreaList,
  POST_ClientUpdate,
  POST_ClientPatientAddressUpdate,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { List } from "antd";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

const AddClients = () => {
  const { ClientIDEncryptParam } = useParams();

  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);

  const [addressModal, setAddressModal] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);

  const [optionHowDidYouHear, setOptionHowDidYouHear] = useState([]);
  const [familyList, setFamilyList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [orderHistoryList, setOrderHistoryList] = useState([]);

  const [country, setCountry] = useState("");
  const [tab, setTab] = useState(1);

  const [optionState, setOptionState] = useState([]);
  const [optionCity, setOptionCity] = useState([]);
  const [optionArea, setOptionArea] = useState([]);

  const [orderBookingID, setOrderBookingID] = useState("");

  const [addressSuggesionDivStatus, setAddressSuggesionDivStatus] =
    useState(false);

  const { placePredictions, getPlacePredictions, isPlacePredictionLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required field"),
    LastName: Yup.string().required("Last Name is required field"),
    Gender: Yup.string().required("Gender is required field"),
    AboutUs: Yup.string().required(
      "How did you hear know about us ? is required field"
    ),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      FirstName: "",
      LastName: "",
      Gender: "",
      EmailID: "",
      MobileNo: "",
      AboutUs: "",
      CountryCode: "+91",
      Status: true,
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: { CountryCode },
  } = formik;

  const validationSchemaAddress = Yup.object().shape({
    AreaID: Yup.string().required("Area is required field"),
    StateID: Yup.string().required("State is required field"),
    CityID: Yup.string().required("City is required field"),
    Location: Yup.string().required("Location is required field"),
    Pincode: Yup.string().required("Pincode is required field"),
    Address: Yup.string().required("Address is required field"),
  });

  const formikAddress = useFormik({
    validationSchema: validationSchemaAddress,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitAddress(values, formikHelpers),
    initialValues: {
      PatientAddressIDEncrypt: "",
      CityID: "",
      StateID: "",
      AreaID: "",
      Location: "",
      Pincode: "",
      Address: "",
      Latitude: 0,
      Longitude: 0,
    },
    validateOnBlur: false,
  });

  const {
    setFieldValueAddress,
    values: { StateID, CityID, PatientAddressIDEncrypt },
  } = formikAddress;

  useEffect(() => {
    get_Howdidyouhearknowaboutus();
    getStateList();
    if (ClientIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetClientDetailByID, {
        ClientIDEncrypt: window.atob(ClientIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            ClientIDEncrypt: window.atob(ClientIDEncryptParam),
            FirstName: res.FirstName,
            LastName: res.LastName,
            Gender: res.Gender == 0 ? "" : res.Gender,
            EmailID: res.EmailID,
            MobileNo: res.MobileNo,
            AboutUs:
              res.HowDidYouHearKnowAboutUsID == 0
                ? ""
                : res.HowDidYouHearKnowAboutUsID,
            CountryCode: res.CountryCode,
            Status: res.IsActive,
          });
        }
      });
      getFamilyDetail(window.atob(ClientIDEncryptParam));
      getAddressList(window.atob(ClientIDEncryptParam));
      setIsLoading(false);
    }
  }, []);

  const getFamilyDetail = async (cID) => {
    setIsLoading(true);
    let response = await post(POST_GetClientPatientsList, {
      CurrentPage: 1,
      PageSize: 100,
      Search: "",
      Sorting: "",
      ClientID: cID,
    });
    setIsLoading(false);
    setFamilyList(response.ClientPatientsList);
  };

  const getAddressList = async (cID) => {
    setIsLoading(true);
    let response = await post(POST_GetPatientAddressList, {
      ClientIDEncrypt: cID,
    });
    setIsLoading(false);
    setAddressList(response.ClientPatientAddressesList);
  };

  const get_Howdidyouhearknowaboutus = () => {
    setIsLoading(true);
    post(POST_GetCategoryListByCategoryType + "?CategoryType=16")
      .then((res) => {
        if (res.IsSuccess) {
          setOptionHowDidYouHear(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const delete_Patient = (pID) => {
    swal({
      title: "Are you sure you want to delete the patient?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_PatientConfirm(pID);
      }
    });
  };

  const delete_PatientConfirm = async (pID) => {
    setIsLoading(true);
    let response = await post(POST_ClientPatientsDelete, {
      EncryptedPatientID: pID,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getFamilyDetail(window.atob(ClientIDEncryptParam));
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_Patient = async (pID, pStatus) => {
    swal({
      title: `Are you sure you want to ${
        pStatus ? "inactive" : "active"
      } the patient?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_PatientConfirm(pID, pStatus);
      }
    });
  };

  const ActiveInactive_PatientConfirm = async (pID, pStatus) => {
    setIsLoading(true);
    let response = await post(POST_ClientPatientsSetActiveInActive, {
      EncryptedPatientID: pID,
      IsActive: !pStatus,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: pStatus ? "Inactivated" : "Activated",
        text: pStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getFamilyDetail(window.atob(ClientIDEncryptParam));
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const getOrderHistory = async (oID) => {
    setIsLoading(true);
    let response = await post(POST_GetPatientOrderHistoryList, {
      PatientIDEncrypted: oID,
    });
    setOrderHistoryModal(true);
    setIsLoading(false);
    setOrderHistoryList(response.OrderHistoryList);
  };

  const delete_OrderHistory = (oID, pID) => {
    swal({
      title: "Are you sure you want to delete the order?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_OrderHistoryConfirm(oID, pID);
      }
    });
  };

  const delete_OrderHistoryConfirm = async (oID, pID) => {
    setIsLoading(true);
    let response = await post(POST_AdminOrderBookingDelete, {
      OrderBookingIDEncrypted: oID,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getOrderHistory(pID);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_OrderHistory = async (oID, oStatus, pID) => {
    swal({
      title: `Are you sure you want to ${
        oStatus ? "inactive" : "active"
      } the order?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_OrderHistoryConfirm(oID, oStatus, pID);
      }
    });
  };

  const ActiveInactive_OrderHistoryConfirm = async (oID, oStatus, pID) => {
    setIsLoading(true);
    let response = await post(POST_AdminOrderBookingStatusUpdate, {
      OrderBookingIDEncrypted: oID,
      IsActive: !oStatus,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: oStatus ? "Inactivated" : "Activated",
        text: oStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getOrderHistory(pID);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (StateID) {
      getCityList(StateID);
    } else {
      formik.setFieldValue("StateID", "");
      setOptionCity([]);
    }
  }, [StateID]);

  useEffect(() => {
    if (CityID) {
      getAreaList(CityID);
    } else {
      formik.setFieldValue("CityID", "");
      setOptionArea([]);
    }
  }, [CityID]);

  const getStateList = () => {
    setIsLoading(true);
    post(POST_GetStateList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionState(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const getCityList = (stateId) => {
    setIsLoading(true);
    post(POST_GetCityList + stateId)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionCity(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const getAreaList = (cityID) => {
    setIsLoading(true);
    post(POST_GetAreaList + cityID)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionArea(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const handleValidSubmitAddress = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      ...values,
      PatientAddressIDEncrypt: PatientAddressIDEncrypt,
      ClientIDEncrypt: ClientIDEncryptParam
        ? window.atob(ClientIDEncryptParam)
        : "",
      Address: values.Address,
      PinCode: values.Pincode,
      Location: values.Location,
      Latitude: values.Latitude,
      Longitude: values.Longitude,
    };
    post(
      PatientAddressIDEncrypt === ""
        ? POST_ClientPatientAddressInsert
        : POST_ClientPatientAddressUpdate,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          setAddressModal(false);
          formikAddress.resetForm();
          getAddressList(window.atob(ClientIDEncryptParam));
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      ClientIDEncrypt: ClientIDEncryptParam
        ? window.atob(ClientIDEncryptParam)
        : "",
      FirstName: values.FirstName,
      LastName: values.LastName,
      Gender: values.Gender ? values.Gender : 0,
      HowDidYouHearKnowAboutUsID: values.AboutUs ? values.AboutUs : 0,
      IsActive: values.Status,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
    };
    post(POST_ClientUpdate, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          // formik.resetForm();
          setTab(2);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const optionGender = [
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
  ];

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={ClientIDEncryptParam ? "Edit Client" : "Add Client"}
          motherMenu={<Link to="/client-list">Clients</Link>}
        />

        <div className="custom-tab-1">
          <Tab.Container activeKey={tab}>
            <Nav as="ul" className="nav-tabs">
              <Nav.Item as="li" key={1}>
                <Nav.Link eventKey={1} onClick={() => setTab(1)}>
                  {"Client Details"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={2}>
                <Nav.Link eventKey={2} onClick={() => setTab(2)}>
                  {"Family Members"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={3}>
                <Nav.Link eventKey={3} onClick={() => setTab(3)}>
                  {"Addresses"}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey={1} key={1}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="basic-form">
                          <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                              <Row className="mb-2">
                                <Col lg={4}>
                                  <Field
                                    type="text"
                                    label={"First Name"}
                                    name="FirstName"
                                    id="FirstName"
                                    placeholder={"Enter First Name"}
                                    component={CustomInput}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "FirstName",
                                        e.target.value.replace(
                                          /[^A-Za-z ]/g,
                                          ""
                                        )
                                      );
                                    }}
                                    required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="text"
                                    label={"Last Name"}
                                    name="LastName"
                                    id="LastName"
                                    placeholder={"Enter Last Name"}
                                    component={CustomInput}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "LastName",
                                        e.target.value.replace(
                                          /[^A-Za-z ]/g,
                                          ""
                                        )
                                      );
                                    }}
                                    required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="text"
                                    label={"Gender"}
                                    name="Gender"
                                    id="Gender"
                                    options={optionGender}
                                    placeholder={"Select Gender"}
                                    component={CustomSelect}
                                    required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="text"
                                    label={"EmailID"}
                                    name="EmailID"
                                    id="EmailID"
                                    placeholder={"Enter EmailID"}
                                    component={CustomInput}
                                    disabled
                                    //required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="text"
                                    label={"Mobile No."}
                                    name="MobileNo"
                                    id="MobileNo"
                                    placeholder={"Enter Mobile No."}
                                    country={country}
                                    countryCode={CountryCode}
                                    component={CustomPhoneInput}
                                    onChange={(
                                      value,
                                      { countryCode, dialCode }
                                    ) => {
                                      setFieldValue("CountryCode", dialCode);
                                      setFieldValue(
                                        "MobileNo",
                                        value.substring(
                                          dialCode.toString().length
                                        )
                                      );
                                    }}
                                    disabled
                                    required
                                  />
                                </Col>

                                <Col lg={4}>
                                  <Field
                                    label={"How did you hear know about us?"}
                                    name="AboutUs"
                                    id="AboutUs"
                                    component={CustomSelect}
                                    options={optionHowDidYouHear}
                                    required
                                  />
                                </Col>
                              </Row>
                              <br></br>
                              <button
                                type="submit"
                                className="btn mr-2 btn-primary"
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => setTab(2)}
                              >
                                Next
                              </button>
                            </Form>
                          </FormikProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={2} key={2}>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="table-responsive">
                          <div
                            id="patient_list"
                            className="dataTables_wrapper no-footer"
                          >
                            <table
                              id="example5"
                              className="table patient-list mb-4 dataTablesCard fs-14 dataTable no-footer SubDataTable"
                              role="grid"
                              aria-describedby="example5_info"
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Patient No.: activate to sort column ascending"
                                    style={{
                                      width: "15%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Patient No.
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Patient Name: activate to sort column ascending"
                                    style={{
                                      width: "20%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Patient Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Relation: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Relation
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Mobile No. activate to sort column ascending"
                                    style={{
                                      width: "15%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Mobile No.
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Date of Birth: activate to sort column ascending"
                                    style={{
                                      width: "20%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Date of Birth
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Gender: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Gender
                                  </th>

                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              {familyList.length > 0 ? (
                                <tbody>
                                  {familyList?.map((element) => (
                                    <tr role="row" className="odd">
                                      <td>{element.PatientNo}</td>
                                      <td>{element.PatientName}</td>
                                      <td>{element.RelationWithClient}</td>
                                      <td>{element.MobileNo}</td>
                                      <td>{element.DateOfBirth}</td>
                                      <td>{element.Gender}</td>

                                      <td>
                                        <div className="d-flex">
                                          <Link
                                            to="#"
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="Order History"
                                            onClick={() =>
                                              getOrderHistory(
                                                element.PatientIDEncrypt
                                              )
                                            }
                                          >
                                            <i className="fa fa-history"></i>
                                          </Link>
                                          <Link
                                            to={`/edit-patient/${window.btoa(
                                              element.PatientIDEncrypt
                                            )}`}
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="Edit"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </Link>
                                          <Link
                                            to="#"
                                            className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                            title={
                                              element.IsActive
                                                ? "Active"
                                                : "Inactive"
                                            }
                                            onClick={() => {
                                              ActiveInactive_Patient(
                                                element.PatientIDEncrypt,
                                                element.IsActive
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-circle mt-1"
                                              style={{
                                                color: element.IsActive
                                                  ? "green"
                                                  : "red",
                                              }}
                                            ></i>
                                          </Link>
                                          <Link
                                            to="#"
                                            className="btn btn-light shadow btn-xs sharp"
                                            title="Delete"
                                            onClick={() => {
                                              delete_Patient(
                                                element.PatientIDEncrypt
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                            ></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <div className="mt-3 ml-3">
                                  {"No Record Found"}
                                </div>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary"
                      onClick={() => setTab(1)}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setTab(3)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={3} key={3}>
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <Row>
                        <Col lg={12} style={{ textAlign: "right" }}>
                          <button
                            className="btn btn-primary btn-xs btn-rounded mb-2"
                            onClick={() => {
                              formikAddress.resetForm();
                              setAddressModal(true);
                            }}
                          >
                            + Add New
                          </button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        {addressList.length > 0 ? (
                          addressList?.map((element) => (
                            <Col lg={4}>
                              <div className="card">
                                <div
                                  className="card-body"
                                  style={{
                                    border: "1px solid lightgray",
                                    borderRadius: "5%",
                                    color: "#3e4954",
                                  }}
                                >
                                  <Row>
                                    <Col lg={10}>
                                      {element.Address},<br></br>
                                      {element.AreaName},<br></br>
                                      {element.CityName} - {element.PinCode},
                                      <br></br>
                                      {element.StateName}
                                    </Col>
                                    <Col lg={2}>
                                      <button
                                        onClick={() => {
                                          formikAddress.setFieldValue(
                                            "PatientAddressIDEncrypt",
                                            element.PatientAddressIDEncrypt
                                          );
                                          formikAddress.setFieldValue(
                                            "CityID",
                                            element.CityID
                                          );
                                          formikAddress.setFieldValue(
                                            "StateID",
                                            element.StateID
                                          );
                                          formikAddress.setFieldValue(
                                            "AreaID",
                                            element.AreaID
                                          );
                                          formikAddress.setFieldValue(
                                            "Location",
                                            element.Location
                                          );
                                          formikAddress.setFieldValue(
                                            "Pincode",
                                            element.PinCode
                                          );
                                          formikAddress.setFieldValue(
                                            "Address",
                                            element.Address
                                          );
                                          formikAddress.setFieldValue(
                                            "Latitude",
                                            element.Latitude
                                          );
                                          formikAddress.setFieldValue(
                                            "Longitude",
                                            element.Longitude
                                          );
                                          setAddressModal(true);
                                        }}
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Edit"
                                        style={{ marginTop: "-31px" }}
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : (
                          <div className="mt-3 ml-3">{"No Address Found"}</div>
                        )}
                        {/* <Col lg={4}>
                          <div className="card">
                            <div
                              className="card-body"
                              style={{
                                border: "1px solid lightgray",
                                borderRadius: "5%",
                                color: "#3e4954",
                              }}
                            >
                              C-506, Titanium City Center,
                              <br></br>
                              Anand Nagar,
                              <br></br>
                              Ahmedabad - 380050,
                              <br></br>
                              Gujarat
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      <button
                        type="button"
                        className="btn mr-2 btn-primary"
                        onClick={() => setTab(2)}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        className="btn mr-2 btn-primary"
                        onClick={(e) => {
                          history.push("/client-list");
                        }}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <Modal
            className="modal fade"
            show={addressModal}
            onHide={setAddressModal}
          >
            <div className="">
              <div className="">
                <FormikProvider value={formikAddress}>
                  <Form onSubmit={formikAddress.handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">
                        {PatientAddressIDEncrypt === ""
                          ? "Add Address"
                          : "Edit Address"}
                      </h4>
                      <button
                        type="button"
                        className="btn close"
                        onClick={() => setAddressModal(false)}
                      >
                        <span>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <Row>
                            <Col lg={12}>
                              <Field
                                type="textarea"
                                label={"Address"}
                                name="Address"
                                placeholder={"Enter Address"}
                                rows={2}
                                component={CustomInput}
                                required
                                style={{ resize: "none" }}
                                maxLength={500}
                                onChange={(e) => {
                                  formikAddress.setFieldValue(
                                    "Address",
                                    e.target.value.replace(
                                      /[^A-Za-z 0-9 , -]/g,
                                      ""
                                    )
                                  );
                                }}
                              />
                            </Col>
                            <Col lg={12}>
                              <Field
                                type="text"
                                label={"Location"}
                                name="Location"
                                placeholder={"Select Location"}
                                component={CustomInput}
                                onChange={(e) => {
                                  getPlacePredictions({
                                    input: e.target.value,
                                  });
                                  formikAddress.setFieldValue(
                                    "Location",
                                    e.target.value
                                  );
                                  setAddressSuggesionDivStatus(true);
                                }}
                                loading={isPlacePredictionLoading}
                                required
                              />
                              {addressSuggesionDivStatus &&
                              placePredictions.length > 0 ? (
                                <div
                                  style={{
                                    background: "white",
                                    borderRadius: "0.3rem",
                                    position: "absolute",
                                    // left: "40px",
                                    overflow: "auto",
                                    border: "1px solid rgb(207, 207, 207)",
                                    boxShadow: "rgb(28 28 28 / 8%) 0px 2px 8px",
                                    visibility: "visible",
                                    opacity: "1",
                                    transition:
                                      "opacity 0.25s ease 0s, top 0.25s ease 0s",
                                    zIndex: "10",
                                    transform: "translateY(-0.6rem)",
                                    width: "94%",
                                  }}
                                >
                                  <List
                                    style={{
                                      padding: "0px 10px",
                                      textAlign: "left",
                                    }}
                                    dataSource={placePredictions}
                                    renderItem={(item) => (
                                      <List.Item
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          formikAddress.setFieldValue(
                                            "Location",
                                            item.description
                                          );
                                          setAddressSuggesionDivStatus(false);
                                          geocodeByPlaceId(item.place_id)
                                            .then((results) => {
                                              formikAddress.setFieldValue(
                                                "Latitude",
                                                results[0].geometry.location.lat()
                                              );
                                              formikAddress.setFieldValue(
                                                "Longitude",
                                                results[0].geometry.location.lng()
                                              );
                                            })
                                            .catch((error) =>
                                              console.error(error)
                                            );
                                        }}
                                      >
                                        <List.Item.Meta
                                          title={item.description}
                                        />
                                      </List.Item>
                                    )}
                                  ></List>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>

                            {/* <Col lg={6}>
                            <Field
                              type="text"
                              label={"Latitude"}
                              name="Latitude"
                              id="Latitude"
                              placeholder={"Enter Latitude"}
                              component={CustomInput}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text"
                              label={"Longitude"}
                              name="Longitude"
                              id="Longitude"
                              placeholder={"Enter Longitude"}
                              component={CustomInput}
                            />
                          </Col> */}

                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"State"}
                                name="StateID"
                                placeholder={"Select State"}
                                options={optionState}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"City"}
                                name="CityID"
                                placeholder={"Select City"}
                                options={optionCity}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"Area"}
                                name="AreaID"
                                placeholder={"Select Area"}
                                options={optionArea}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                type="number"
                                label={"Pincode"}
                                name="Pincode"
                                placeholder={"Enter Pincode"}
                                component={CustomInput}
                                required
                                maxLength={6}
                                onChange={(e) => {
                                  formikAddress.setFieldValue(
                                    "Pincode",
                                    e.target.value.slice(0, 6)
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setAddressModal(false)}
                        className="btn btn-light"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={orderHistoryModal}
            onHide={setOrderHistoryModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order History</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderHistoryModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row mt-3">
                    <div className="col-xl-12">
                      <div className="table-responsive">
                        <div
                          id="order_list"
                          className="dataTables_wrapper no-footer"
                        >
                          <table
                            id="example5"
                            className="table patient-list mb-2 dataTablesCard fs-14 dataTable no-footer"
                            role="grid"
                            aria-describedby="example5_info"
                          >
                            <thead className="thead-light">
                              <tr role="row">
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Order No: activate to sort column ascending"
                                  style={{
                                    width: "10%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Order No.
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Company Name: activate to sort column ascending"
                                  style={{
                                    width: "25%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Company Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Patient Name: activate to sort column ascending"
                                  style={{
                                    width: "25%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Patient Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Order Booking Date: activate to sort column ascending"
                                  style={{
                                    width: "20%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Order Booking Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Status: activate to sort column ascending"
                                  style={{
                                    width: "10%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Status
                                </th>

                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Actions: activate to sort column ascending"
                                  style={{
                                    width: "10%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            {orderHistoryList.length > 0 ? (
                              <tbody>
                                {orderHistoryList?.map((element) => (
                                  <tr role="row" className="odd">
                                    <td>{element.OrderBookingNo}</td>
                                    <td>{element.CompanyName}</td>
                                    <td>{element.PatientName}</td>
                                    <td>{element.OrderBookingDate}</td>
                                    <td>{element.OrderBookingStatusValue}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          to={`/add-order-booking/${window.btoa(
                                            element.PatientIDEncrypted
                                          )}`}
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Order Booking"
                                        >
                                          <i className="fa fa-plus"></i>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Order Detail"
                                          onClick={() => {
                                            setOrderBookingID(
                                              element.OrderBookingIDEncrypted
                                            );
                                            setOrderDetailModal(true);
                                          }}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>

                                        <Link
                                          to={`/edit-order-booking/${window.btoa(
                                            element.PatientIDEncrypted
                                          )}/${window.btoa(
                                            element.OrderBookingIDEncrypted
                                          )}`}
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                          title={
                                            element.IsActive
                                              ? "Active"
                                              : "Inactive"
                                          }
                                          onClick={() => {
                                            ActiveInactive_OrderHistory(
                                              element.OrderBookingIDEncrypted,
                                              element.IsActive,
                                              element.PatientIDEncrypted
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-circle mt-1"
                                            style={{
                                              color: element.IsActive
                                                ? "green"
                                                : "red",
                                            }}
                                          ></i>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                          onClick={() => {
                                            delete_OrderHistory(
                                              element.OrderBookingIDEncrypted,
                                              element.PatientIDEncrypted
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <div className="mt-3 ml-3">
                                {"No Record Found"}
                              </div>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setOrderHistoryModal(false)}
                className="btn btn-light"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Close
              </button>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={orderDetailModal}
            onHide={setOrderDetailModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>

            <div className="modal-body">
              <ModalOrderBookingDetail
                orderBookingID={orderBookingID}
                setOrderDetailModal={setOrderDetailModal}
              ></ModalOrderBookingDetail>
            </div>
          </Modal>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddClients;
