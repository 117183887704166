import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import { AvForm } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import {
  POST_GetEquipmentsDetailByID,
  POST_EquipmentsInsert,
  POST_EquipmentsUpdate,
  POST_EquipmentsPhotoInsert,
  POST_GetCategoryListByCategoryType,
  GET_GetGSTPlansList,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

const AddEquipments = () => {
  const { EquipmentIDEncryptParam } = useParams();
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([]);
  const [specificationTypeOptions, setSpecificationTypeOptions] = useState([]);
  const [GSTPlanListOptions, setGSTPlanListOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const [equipmentPhotoDetail, setEquipmentPhotoDetail] = useState([]);

  const [selectedFiles, setselectedFiles] = useState([]);
  const [deletePhotoIDs, setDeletePhotoIDs] = useState([]);

  const validationSchema = Yup.object().shape({
    EquipmentType: Yup.string().required("Equipment Type is required field"),
    EquipmentName: Yup.string().required("Equipment Name is required field"),
    EquipmentDeposit: Yup.string().required(
      "Equipment Deposit is required field"
    ),
    TotalQty: Yup.string().nullable().required("Total Qty is required field"),
    AvailableQty: Yup.string().required("Available Qty is required field"),
    // Feature: Yup.string().required("Feature is a required field"),
    GSTPlanforSale: Yup.string().when("ISSale", {
      is: (ISSale) => ISSale === true,
      then: Yup.string()
        .nullable()
        .required("GST Plan for Sale is a required field"),
      otherwise: Yup.string().nullable(),
    }),

    // .required(
    //   "GST Plan for Sale is a required field"
    // ),
    GSTPlanforRent: Yup.string().when("ISRent", {
      is: (ISRent) => ISRent === true,
      then: Yup.string()
        .nullable()
        .required("GST Plan for Rent is a required field"),
      otherwise: Yup.string().nullable(),
    }),
    // .required(
    //   "GST Plan for Rent is a required field"
    // ),
    TransportationCharges: Yup.string().required(
      "Transportation Charges is required field"
    ),
    MinimumDaysRent: Yup.number()
      .min(0, "Minimum Days is required field")
      .required("Minimum Days is required field"),
  });

  const initTechnicalSpecification = {
    SpecificationType: "",
    Specification: "",
  };

  const initListOfFeatures = {
    Feature: "",
  };

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      EncryptedEquipmentID: EquipmentIDEncryptParam
        ? window.atob(EquipmentIDEncryptParam)
        : "",
      EquipmentType: "",
      EquipmentName: "",
      EquipmentDeposit: "",
      ListOfTechnicalSpecification: [initTechnicalSpecification],
      ListOfFeatures: [initListOfFeatures],
      // Feature: "",
      GSTPlanforSale: "",
      GSTPlanforRent: "",
      ISRent: true,
      ISSale: true,
      ISAllowMulti: true,
      Status: true,
      TotalQty: "",
      AvailableQty: "",
      MinimumDaysRent: "",
      TransportationCharges: "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      ListOfTechnicalSpecification,
      ListOfFeatures,
      ISSale,
      ISRent,
      ISAllowMulti,
      EncryptedEquipmentID,
    },
  } = formik;

  useEffect(() => {
    get_EquipmentTypeOptions();
    get_SpecificationType();
    getGSTPlanList();
    if (EquipmentIDEncryptParam) {
      post(POST_GetEquipmentsDetailByID, {
        EquipmentIDEncrypt: window.atob(EquipmentIDEncryptParam),
      }).then((res) => {
        setIsLoading(true);
        if (res.IsSuccess) {
          setValues({
            EncryptedEquipmentID: res.EquipmentIDEncrypt,
            EquipmentType: res.EquipmentType,
            EquipmentName: res.EquipmentName,
            EquipmentDeposit: res.EquipmentDeposit,
            GSTPlanforSale: res.GSTPlanID_SaleEncrypt,
            GSTPlanforRent: res.GSTPlanID_RentEncrypt,
            ListOfTechnicalSpecification:
              res.EquipmentTechnicalSpecificationsList,
            ListOfFeatures: res.EquipmentFeaturesList,
            ISRent: res.IsRent,
            ISSale: res.IsSale,
            ISAllowMulti: res.IsAllowOrderMultipleQty,
            Status: res.IsActive,
            TotalQty: res.TotalQty,
            AvailableQty: res.AvailableQty,
            MinimumDaysRent: res.MinimumDaysRent,
            TransportationCharges: res.TransportationCharges,
          });
          setEquipmentPhotoDetail(res.EquipmentPhotosList);
        }
        setIsLoading(false);
      });
    }
  }, []);

  const getGSTPlanList = () => {
    setIsLoading(true);
    get(GET_GetGSTPlansList)
      .then((res) => {
        if (res.IsSuccess) {
          setGSTPlanListOptions(
            res.GSTPlansDDLList.map((item) => ({
              label: item.GSTPlan,
              value: item.EncryptGSTPlanID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const get_EquipmentTypeOptions = () => {
    setIsLoading(true);
    post(POST_GetCategoryListByCategoryType + "?CategoryType=10")
      .then((res) => {
        if (res.IsSuccess) {
          setEquipmentTypeOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const get_SpecificationType = () => {
    setIsLoading(true);
    post(POST_GetCategoryListByCategoryType + "?CategoryType=11")
      .then((res) => {
        if (res.IsSuccess) {
          setSpecificationTypeOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  function handleAcceptedFiles(files) {
    var pattern = /(\.|\/)(jpe?g|png|webp|svg|gif|ico)$/;
    var patternValid = true;
    files.map((file) => {
      if (!file.type.match(pattern)) {
        swal("", "Only image files are allowed!", "warning");
        patternValid = false;
      }
    });

    if (patternValid) {
      var InvalidFile = false;
      var msgInvalidShow = true;
      files.map((file, i) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== 500 || width !== 500) {
              InvalidFile = true;
            } else {
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
              });
              setselectedFiles((selectedFiles) => [...selectedFiles, file]);
            }
            if (InvalidFile && msgInvalidShow) {
              msgInvalidShow = false;
              swal({
                title: "Error",
                text: "Image size must be of 500x500",
                icon: "error",
                button: "OK",
              });
            }
          };
        };
      });
    }
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const SavePhotos = async () => {
    if (selectedFiles?.length > 0 || deletePhotoIDs.length > 0) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("EncryptedEquipmentID", EncryptedEquipmentID);
      if (selectedFiles?.length > 0) {
        Array.prototype.forEach.call(selectedFiles, (child) =>
          formData.append("Photos", child)
        );
      }
      if (deletePhotoIDs?.length > 0) {
        formData.append("EquipmentPhotoIDs", deletePhotoIDs);
      }
      postFormData(POST_EquipmentsPhotoInsert, formData)
        .then((response) => {
          if (response.IsSuccess) {
            swal({
              title: "Success",
              text: t(response.MessageCode),
              icon: "success",
              button: "OK",
            });
            setIsLoading(false);
            history.push("/equipment-list");
          } else {
            swal({
              title: "Error",
              text: t(response.MessageCode),
              icon: "error",
              button: "OK",
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      swal({
        text: "Photos are required",
        icon: "warning",
        button: "OK",
      });
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (!ISRent && !ISSale) {
      swal({
        //title: "warning",
        text: "Please check Rent OR Sale.",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    if (
      ListOfTechnicalSpecification.filter(
        (x) => x.SpecificationType == "" || x.Specification?.trim() == ""
      ).length > 0
    ) {
      swal({
        //title: "warning",
        text: "Fill the details of Technical Specifications or remove it.",
        icon: "warning",
        button: "OK",
      });
      return;
    } else if (
      ListOfFeatures.filter((x) => x.Feature?.trim() == "").length > 0
    ) {
      swal({
        //title: "warning",
        text: "Fill the details of Features or remove it.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if (values.TotalQty < values.AvailableQty) {
      swal({
        //title: "warning",
        text: "Available Qty must be less then Total Qty!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    const data = {
      // EquipmentIDEncrypt: EquipmentIDEncrypt
      //   ? window.atob(EquipmentIDEncryptParam)
      //   : "",
      EquipmentIDEncrypt: EncryptedEquipmentID,
      EquipmentType: values.EquipmentType,
      EquipmentName: values.EquipmentName,
      EquipmentDeposit: values.EquipmentDeposit,
      IsRent: ISRent,
      IsSale: ISSale,
      IsAllowOrderMultipleQty: ISAllowMulti,
      GSTPlanID_RentEncrypt: ISRent ? values.GSTPlanforRent : "",
      GSTPlanID_SaleEncrypt: ISRent ? values.GSTPlanforSale : "",
      ListOfEquipmentTechnicalSpecificationsDetail:
        ListOfTechnicalSpecification,
      ListOfEquipmentFeaturesDetail: ListOfFeatures,
      IsActive: values.Status,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
      TotalQty: values.TotalQty,
      AvailableQty: values.AvailableQty,
      MinimumDaysRent: values.MinimumDaysRent,
      TransportationCharges: values.TransportationCharges,
    };

    post(
      EncryptedEquipmentID ? POST_EquipmentsUpdate : POST_EquipmentsInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          if (!EncryptedEquipmentID) {
            setFieldValue("EncryptedEquipmentID", response.EncryptID);
          }
          setTab(2);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            EquipmentIDEncryptParam ? "Edit Equipment" : "Add Equipment"
          }
          motherMenu={<Link to="/equipment-list">Equipments</Link>}
        />

        <div className="custom-tab-1">
          <Tab.Container activeKey={tab}>
            <Nav as="ul" className="nav-tabs">
              <Nav.Item as="li" key={1}>
                <Nav.Link eventKey={1} onClick={() => setTab(1)}>
                  {"Equipment"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={2}>
                <Nav.Link
                  eventKey={2}
                  disabled={EncryptedEquipmentID ? false : true}
                  onClick={() => setTab(2)}
                >
                  {"Photos"}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey={1} key={1}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="basic-form">
                          <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                              <Row>
                                <Col lg={4}>
                                  <Field
                                    label={"Equipment Type"}
                                    name="EquipmentType"
                                    options={equipmentTypeOptions}
                                    placeholder={"Select Equipment Type"}
                                    component={CustomSelect}
                                    required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="text"
                                    label={"Equipment Name"}
                                    name="EquipmentName"
                                    placeholder={"Enter Equipment Name"}
                                    component={CustomInput}
                                    // onChange={(e) => {
                                    //   setFieldValue(
                                    //     "EquipmentName",
                                    //     e.target.value.replace(
                                    //       /[^A-Za-z - () ]/g,
                                    //       ""
                                    //     )
                                    //   );
                                    // }}
                                    required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <div className="d-flex">
                                    <div
                                      className="custom-control custom-checkbox mb-3 checkbox-success ml-2 mr-3"
                                      style={{ marginTop: "35px", zIndex: 0 }}
                                    >
                                      <input
                                        type="checkbox"
                                        defaultChecked
                                        className="custom-control-input"
                                        id="ISRent"
                                        name="ISRent"
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            "ISRent",
                                            e.target.checked
                                          );
                                          if (e.target.checked === false) {
                                            setFieldValue("GSTPlanforRent", "");
                                          }
                                        }}
                                        checked={ISRent}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="ISRent"
                                      >
                                        Rent
                                      </label>
                                    </div>

                                    <div
                                      className="custom-control custom-checkbox mb-3 checkbox-success ml-3"
                                      style={{ marginTop: "35px", zIndex: 0 }}
                                    >
                                      <input
                                        type="checkbox"
                                        defaultChecked
                                        className="custom-control-input"
                                        id="ISSale"
                                        name="ISSale"
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            "ISSale",
                                            e.target.checked
                                          );
                                          if (e.target.checked === false) {
                                            setFieldValue("GSTPlanforSale", "");
                                          }
                                        }}
                                        checked={ISSale}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="ISSale"
                                      >
                                        Sale
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="number"
                                    label="Equipment Deposit"
                                    name="EquipmentDeposit"
                                    component={CustomInput}
                                    placeholder="Enter Deposit Amount"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "EquipmentDeposit",
                                        e.target.value.slice(0, 7)
                                      );
                                    }}
                                    min={0}
                                    required
                                  />
                                </Col>

                                <Col lg={4}>
                                  <Field
                                    type="number"
                                    label="Transportation Charges"
                                    name="TransportationCharges"
                                    component={CustomInput}
                                    placeholder="Enter Transportation Charges"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "TransportationCharges",
                                        e.target.value.slice(0, 7)
                                      );
                                    }}
                                    min={0}
                                    required
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="number"
                                    label="Minimum Rent Days"
                                    name="MinimumDaysRent"
                                    component={CustomInput}
                                    placeholder="Enter Minimum Rent Days"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "MinimumDaysRent",
                                        e.target.value.slice(0, 2)
                                      );
                                    }}
                                    min={0}
                                    required
                                  />
                                </Col>
                                <Col lg={2}>
                                  <Field
                                    type="number"
                                    label={"Total Qty"}
                                    name="TotalQty"
                                    placeholder={"Enter Total Qty"}
                                    component={CustomInput}
                                    min={0}
                                    required
                                  />
                                </Col>
                                <Col lg={2}>
                                  <Field
                                    type="number"
                                    label={"Available Qty"}
                                    name="AvailableQty"
                                    placeholder={"Enter Available Qty"}
                                    component={CustomInput}
                                    min={0}
                                    required
                                  />
                                </Col>
                                <Col lg={4}>
                                  <div
                                    className="custom-control custom-checkbox mb-3 checkbox-success ml-2 mr-3"
                                    style={{ marginTop: "35px", zIndex: 0 }}
                                  >
                                    <input
                                      type="checkbox"
                                      defaultChecked
                                      className="custom-control-input"
                                      id="ISAllowMulti"
                                      name="ISAllowMulti"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "ISAllowMulti",
                                          e.target.checked
                                        );
                                      }}
                                      checked={ISAllowMulti}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="ISAllowMulti"
                                    >
                                      Allow Multiple Quantity
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                              <hr></hr>
                              <h6 className="mb-3">Technical Specification</h6>
                              <FieldArray
                                name="ListOfTechnicalSpecification"
                                render={(arrayHelpers) => {
                                  return (
                                    <Fragment>
                                      {ListOfTechnicalSpecification?.map(
                                        (TechnicalSpecification, index) => (
                                          <div
                                            data-repeater-list="group-a"
                                            key={index}
                                          >
                                            <Row>
                                              <Col lg={4}>
                                                <Field
                                                  label={"Specification Type"}
                                                  name={`ListOfTechnicalSpecification.[${index}].SpecificationType`}
                                                  options={
                                                    specificationTypeOptions
                                                  }
                                                  placeholder={
                                                    "Select Specification Type"
                                                  }
                                                  component={CustomSelect}
                                                  required
                                                />
                                              </Col>
                                              <Col lg={6}>
                                                <Field
                                                  label={"Specification"}
                                                  name={`ListOfTechnicalSpecification.[${index}].Specification`}
                                                  placeholder={
                                                    "Enter Specification"
                                                  }
                                                  component={CustomInput}
                                                  // onChange={(e) => {
                                                  //   setFieldValue(
                                                  //     `ListOfTechnicalSpecification.[${index}].Specification`,
                                                  //     e.target.value.replace(
                                                  //       /[^A-Za-z - + () ]/g,
                                                  //       ""
                                                  //     )
                                                  //   );
                                                  // }}
                                                  required
                                                />
                                              </Col>
                                              {/* {EquipmentIDEncrypt ? ( */}
                                              <Col
                                                lg={2}
                                                className="align-self-center mb-3"
                                              >
                                                <div className="d-grid">
                                                  <label className="form-label">
                                                    <span
                                                      style={{
                                                        color: "white",
                                                      }}
                                                    >
                                                      .
                                                    </span>
                                                  </label>
                                                  <input
                                                    data-repeater-delete
                                                    type="button"
                                                    className="btn btn-primary btn-xs mt-4"
                                                    value="Delete"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              {/* ) : (
                                                index !== 0 && (
                                                  <Col
                                                    lg={2}
                                                    className="align-self-center mb-3"
                                                  >
                                                    <div className="d-grid">
                                                      <label className="form-label">
                                                        <span
                                                          style={{
                                                            color: "white",
                                                          }}
                                                        >
                                                          .
                                                        </span>
                                                      </label>
                                                      <input
                                                        data-repeater-delete
                                                        type="button"
                                                        className="btn btn-primary btn-xs mt-4"
                                                        value="Delete"
                                                        onClick={() =>
                                                          arrayHelpers.remove(
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                )
                                              )} */}
                                            </Row>
                                          </div>
                                        )
                                      )}
                                      <Row>
                                        <Col
                                          lg={12}
                                          style={{ textAlign: "right" }}
                                        >
                                          <Button
                                            color="success"
                                            className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                            onClick={() =>
                                              arrayHelpers.push(
                                                initTechnicalSpecification
                                              )
                                            }
                                          >
                                            <i className="fa fa-plus"></i>{" "}
                                            &nbsp; Add
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Fragment>
                                  );
                                }}
                              />
                              <hr></hr>
                              {/* <Row>
                              <Col lg={10}>
                                <Field
                                  type="textarea"
                                  label={"Feature"}
                                  name="Feature"
                                  placeholder={"Enter Feature"}
                                  component={CustomInput}
                                  rows={3}
                                  style={{ resize: "none" }}
                                  required
                                  maxLength={200}
                                />
                              </Col>
                            </Row> */}
                              <h6 className="mb-3">Features</h6>
                              <FieldArray
                                name="ListOfFeatures"
                                render={(arrayHelpers) => {
                                  return (
                                    <Fragment>
                                      {ListOfFeatures?.map(
                                        (FeaturesList, index) => (
                                          <div
                                            data-repeater-list="group-a"
                                            key={index}
                                          >
                                            <Row>
                                              <Col lg={10}>
                                                <Field
                                                  label={"Feature"}
                                                  name={`ListOfFeatures.[${index}].Feature`}
                                                  placeholder={"Enter Feature"}
                                                  component={CustomInput}
                                                  // onChange={(e) => {
                                                  //   setFieldValue(
                                                  //     `ListOfFeatures.[${index}].Feature`,
                                                  //     e.target.value.replace(
                                                  //       /[^A-Za-z - + () ]/g,
                                                  //       ""
                                                  //     )
                                                  //   );
                                                  // }}
                                                  required
                                                  maxLength={200}
                                                />
                                              </Col>
                                              {/* {EquipmentIDEncrypt ? ( */}
                                              <Col
                                                lg={2}
                                                className="align-self-center mb-3"
                                              >
                                                <div className="d-grid">
                                                  <label className="form-label">
                                                    <span
                                                      style={{
                                                        color: "white",
                                                      }}
                                                    >
                                                      .
                                                    </span>
                                                  </label>
                                                  <input
                                                    data-repeater-delete
                                                    type="button"
                                                    className="btn btn-primary btn-xs mt-4"
                                                    value="Delete"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              {/* ) : (
                                                index !== 0 && (
                                                  <Col
                                                    lg={2}
                                                    className="align-self-center mb-3"
                                                  >
                                                    <div className="d-grid">
                                                      <label className="form-label">
                                                        <span
                                                          style={{
                                                            color: "white",
                                                          }}
                                                        >
                                                          .
                                                        </span>
                                                      </label>
                                                      <input
                                                        data-repeater-delete
                                                        type="button"
                                                        className="btn btn-primary btn-xs mt-4"
                                                        value="Delete"
                                                        onClick={() =>
                                                          arrayHelpers.remove(
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                )
                                              )} */}
                                            </Row>
                                          </div>
                                        )
                                      )}
                                      <Row>
                                        <Col
                                          lg={12}
                                          style={{ textAlign: "right" }}
                                        >
                                          <Button
                                            color="success"
                                            className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                            onClick={() =>
                                              arrayHelpers.push(
                                                initListOfFeatures
                                              )
                                            }
                                          >
                                            <i className="fa fa-plus"></i>{" "}
                                            &nbsp; Add
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Fragment>
                                  );
                                }}
                              />
                              <hr></hr>
                              <Row>
                                {ISRent ? (
                                  <Col lg={4}>
                                    <Field
                                      label={"GST for Rent"}
                                      name="GSTPlanforRent"
                                      placeholder={"Select GST Plan for Rent"}
                                      options={GSTPlanListOptions}
                                      component={CustomSelect}
                                      required
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {ISSale ? (
                                  <Col lg={4}>
                                    <Field
                                      label={"GST for Sale"}
                                      name="GSTPlanforSale"
                                      placeholder={"Select GST Plan for Rent"}
                                      options={GSTPlanListOptions}
                                      component={CustomSelect}
                                      required
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                              <Row className="mt-3">
                                <Col lg={12}>
                                  <button
                                    type="submit"
                                    className="btn mr-2 btn-primary btn-sm"
                                  >
                                    Submit
                                  </button>
                                  {EncryptedEquipmentID ? (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        setTab(2);
                                      }}
                                    >
                                      Next
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            </Form>
                          </FormikProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey={2} key={2}>
                <div className="card">
                  <div className="card-body">
                    <h6>
                      Upload Equipment Photos{" "}
                      <span className="text-danger">*</span>{" "}
                      <span style={{ color: "red", fontSize: "10px" }}>
                        (500 x 500)
                      </span>
                    </h6>

                    {/* &nbsp;
                    <span
                      style={{
                        color: "red",
                        marginTop: "-2px",
                        fontSize: "13px",
                      }}
                    >
                      (510 * 320 px)
                    </span> */}
                    <Row className="mt-3">
                      <Col md={12} lg={12} xl={12}>
                        <AvForm className="needs-validation">
                          {/* <Row> */}
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className="dropzone col-12"
                                style={{
                                  minHeight: "150px",
                                  textAlign: "center",
                                }}
                              >
                                <div
                                  className="dz-message needsclick m-0"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-1">
                                    <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                                  </div>
                                  <h4>Drop here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3 mb-3 row"
                            id="file-previews"
                            style={{ minWidth: "100%" }}
                          >
                            <Row style={{ marginLeft: "20px" }}>
                              {/* <Col md={12} lg={12} xl={12}> */}
                              {selectedFiles.map((f, i) => {
                                return (
                                  <div
                                    className="
                                          mt-0 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            width="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                          <Link
                                            style={{
                                              float: "right",
                                              marginTop: "-9px",
                                            }}
                                            title="Delete"
                                            onClick={() => {
                                              setselectedFiles(
                                                selectedFiles.filter(
                                                  (item, index) => index !== i
                                                )
                                              );
                                            }}
                                          >
                                            <i
                                              className="mdi mdi-delete"
                                              style={{
                                                fontSize: "20px",
                                                color: "red",
                                              }}
                                            ></i>
                                          </Link>
                                        </Col>
                                        {/* <Col className="pl-1">
                                          <Link
                                            style={{
                                              float: "right",
                                            }}
                                            onClick={() => {
                                              setselectedFiles(
                                                selectedFiles.filter(
                                                  (item, index) => index !== i
                                                )
                                              );
                                            }}
                                          >
                                            <i
                                              className="mdi mdi-delete"
                                              style={{
                                                fontSize: "22px",
                                                color: "red",
                                              }}
                                            ></i>
                                          </Link>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col> */}
                                      </Row>
                                    </div>
                                  </div>
                                );
                              })}
                              {/* </Col> */}
                            </Row>
                          </div>
                          {equipmentPhotoDetail.length > 0 ? (
                            <>
                              <hr
                                style={{
                                  marginTop: "2rem",
                                  backgroundColor: "black",
                                }}
                              ></hr>
                              <h5>Uploaded Images</h5>
                            </>
                          ) : (
                            ""
                          )}
                          <Row>
                            {equipmentPhotoDetail?.map((item, index) => {
                              if (item) {
                                return (
                                  <Col md={2} className="mt-3">
                                    <div className="mb-3">
                                      <img
                                        src={item.PhotoFilePath}
                                        width="90%"
                                        alt="upload"
                                      />

                                      <a
                                        className="link"
                                        title="Delete"
                                        onClick={() => {
                                          setDeletePhotoIDs([
                                            ...deletePhotoIDs,
                                            item.EquipmentPhotoID,
                                          ]);
                                          setEquipmentPhotoDetail(
                                            equipmentPhotoDetail?.filter(
                                              (x, i) => i !== index
                                            )
                                          );
                                        }}
                                        style={{
                                          position: "absolute",
                                        }}
                                      >
                                        <i
                                          className="fa fa-window-close"
                                          style={{
                                            fontSize: "20px",
                                            transform: "translateX(5px)",
                                            color: "red",
                                          }}
                                        ></i>
                                      </a>
                                    </div>
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                          {/* </Row> */}
                          <Row className="mt-3">
                            <button
                              type="button"
                              className="btn mt-2 btn-primary btn-sm"
                              onClick={() => {
                                setTab(1);
                              }}
                            >
                              Previous
                            </button>{" "}
                            &nbsp;
                            <button
                              className="btn mt-2 btn-primary btn-sm"
                              type="submit"
                              onClick={() => {
                                SavePhotos();
                              }}
                            >
                              Submit
                            </button>{" "}
                            &nbsp;
                            {EquipmentIDEncryptParam ? (
                              <button
                                type="button"
                                className="btn mt-2 btn-light btn-sm"
                                onClick={(e) => {
                                  history.goBack();
                                }}
                              >
                                Close
                              </button>
                            ) : (
                              ""
                            )}
                          </Row>
                        </AvForm>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddEquipments;
