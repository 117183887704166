import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  login_authenticationAction,
} from "../../store/actions/AuthActions";

//
import logo from "../../images/logo.png";
import logotext from "../../images/logo-text.png";

import { Spin } from "antd";

function Login(props) {
  // const [email, setEmail] = useState("demo@example.com");
  // const [password, setPassword] = useState("123456");
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email or Mobile No. is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setIsLoading(true);
    dispatch(loadingToggleAction(true));
    //dispatch(loginAction(email, password, props.history));
    dispatch(
      login_authenticationAction(email, password, props.history, setIsLoading)
    );
  }

  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <Spin size="large" spinning={isLoading} tip={"Loading..."}>
                <Link to="#" className="login-logo">
                  <img src={logo} alt="" className="logo-icon mr-2" />
                  <img src={logotext} alt="" className="logo-text ml-1" />
                </Link>
                <div className="mb-4">
                  {/* <h3 className="mb-1 font-w600">Welcome to Eres</h3> */}
                  <h3 className="mb-1 font-w600">Welcome to Shrahav</h3>

                  {/* <p className="">Sign in by entering information below</p> */}
                </div>
                {/* {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )} */}
                <form onSubmit={onLogin}>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Email or Mobile No.</strong>
                    </label>
                    <input
                      // type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="text-danger fs-12">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Password</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <div className="text-danger fs-12">{errors.password}</div>
                    )}
                  </div>
                  {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox ml-1 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="basic_checkbox_1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="basic_checkbox_1"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div>
                </div> */}
                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </button>
                  </div>
                </form>

                {/* <div className="new-account mt-2">
                <p className="mb-0">
                  Don't have an account?{" "}
                  <Link className="text-black" to="./page-register">
                    Sign up
                  </Link>
                </p>
              </div> */}
                <Link to="/page-forgot-password">
                  <div
                    className="mt-2"
                    style={{ color: "#36C95F", float: "right" }}
                  >
                    <b>Forgot Password?</b>
                  </div>
                </Link>
              </Spin>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1">
            {/* <div className="inner-content align-self-center">
							<Link to="/dashboard" className="login-logo">
								<img src={logo} alt="" className="logo-icon mr-2"/>
								<img src={logotext} alt="" className="logo-text ml-1"/>
							</Link>
							<h2 className="m-b10 text-white">Login To You Now</h2>
							<p className="m-b40 text-white">User Experience & Interface Design Strategy SaaS Solutions</p>
							<ul className="social-icons mt-4">
								<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
							</ul>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
