import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Tab, Nav, Modal, Form, Accordion } from "react-bootstrap";
import { POST_GetOrderBookingAdvanceDetailByOrderBookingID } from "../Helper/url_helper";
import { post } from "../Helper/api_helper";
import { Spin } from "antd";
import CustomInput from "../Custom/CustomInput";
import CustomSelect from "../Custom/CustomSelect";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

const ModalOrderBookingAdvancesDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  //const [patientDetails, setPatientDetails] = useState("");
  const [advanceDetail, setAdvanceDetail] = useState(false);
  const [sendNotificationlModal, setSendNotificationModal] = useState(false);

  useEffect(() => {
    if (props.orderBookingID) {
      getOrderBookingAdvanceDetailByOrderBookingID();
    }
  }, []);

  const getOrderBookingAdvanceDetailByOrderBookingID = async () => {
    setIsLoading(true);
    let response = await post(
      POST_GetOrderBookingAdvanceDetailByOrderBookingID,
      {
        OrderBookingID: props.orderBookingID,
      }
    );
    setIsLoading(false);
    setAdvanceDetail(response);
  };

  const validationSchemaNotificationMessage = Yup.object().shape({
    NotificationMessage: Yup.string().required(
      "Notification Message is required field"
    ),
  });

  const formikNotificationMessage = useFormik({
    validationSchema: validationSchemaNotificationMessage,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitNotificationMessage(values, formikHelpers),
    initialValues: {
      NotificationMessage:
        "Your Advance Amount is insufficiant Kindly pay the advance amount to get continue service.",
    },
    validateOnBlur: false,
  });
  const { handleValidSubmitNotificationMessage, setFieldValue } =
    formikNotificationMessage;

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="Advance_Detail_list"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead className="thead-light">
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Date: activate to sort column ascending"
                          style={{ width: "40%", padding: "10px 20px" }}
                        >
                          Advance Recieved Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Advance Amount: activate to sort column ascending"
                          style={{
                            width: "60%",
                            textAlign: "right",
                            padding: "10px 20px",
                          }}
                        >
                          Advance Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {advanceDetail.AdminOrderBookingAdvancesDetails?.length >
                      0 ? (
                        advanceDetail.AdminOrderBookingAdvancesDetails?.map(
                          (advance) => (
                            <>
                              <tr role="row" className="odd">
                                <td>{advance.AdvanceReceivedDate}</td>
                                <td style={{ textAlign: "right" }}>
                                  <i className="fa fa-rupee"></i>{" "}
                                  {advance.AdvanceAmount}
                                </td>
                              </tr>
                            </>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={2}>
                            <div className="mt-1 ml-1 mb-3">
                              {"No Record Found..."}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <Row>
            <Col lg={6}></Col>
            <Col lg={6}>
              <Row>
                <Col lg={1}></Col>
                <Col style={{ color: "black" }} lg={6}>
                  Total Order Amount
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={4}>
                  <i className="fa fa-rupee"></i>{" "}
                  {advanceDetail.TotalOrderAmount}
                </Col>
              </Row>
              <Row>
                <Col lg={1}></Col>
                <Col style={{ color: "black" }} lg={6}>
                  Total Advance
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={4}>
                  <i className="fa fa-rupee"></i>{" "}
                  {advanceDetail.TotalAdvanceAmount}
                </Col>
              </Row>
              <Row>
                <Col lg={1}></Col>
                <Col style={{ color: "black" }} lg={6}>
                  Advance Balance
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={4}>
                  <i className="fa fa-rupee"></i>{" "}
                  {advanceDetail.TotalAdvanceBalance}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4 mb-4 mr-2" style={{ float: "right" }}>
            {/* <div
              onClick={() => {
                setSendNotificationModal(true);
              }}
              className="btn btn-primary btn-xs btn-rounded"
            >
              Send Notification
            </div>{" "}
            &nbsp; */}
            <div
              onClick={() => {
                //setAdvanceDetailModal(false);
                props.setAdvanceDetailModal(false);
              }}
              className="btn btn-light btn-xs btn-rounded"
            >
              Ok
            </div>
          </Row>

          <Modal
            className="modal fade"
            show={sendNotificationlModal}
            onHide={setSendNotificationModal}
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Send Notification</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setSendNotificationModal(false)}
              >
                <span>×</span>
              </button>
            </div>

            <FormikProvider value={formikNotificationMessage}>
              <Form onSubmit={formikNotificationMessage.handleSubmit}>
                <div className="modal-body">
                  <Row>
                    <Col lg={12}>
                      <Field
                        type="textarea"
                        // label={"Pathology Report Name"}
                        name="NotificationMessage"
                        placeholder={"Enter Notification Message"}
                        component={CustomInput}
                        onChange={(e) => {
                          setFieldValue(
                            "NotificationMessage",
                            e.target.value.replace(/[^A-Za-z 0-9 - + () ]/g, "")
                          );
                        }}
                        rows={4}
                        style={{ resize: "none" }}
                        required
                      />
                    </Col>
                  </Row>
                  {/* <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Send
                </button>
                <button
                  type="button"
                  onClick={() => setSendNotificationModal(false)}
                  className="btn btn-light"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div> */}
                  <button
                    type="button"
                    onClick={() => setSendNotificationModal(false)}
                    className="btn btn-xs btn-light"
                    style={{ float: "right", marginLeft: "4px" }}
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Cancel
                  </button>
                  &nbsp; &nbsp;{" "}
                  <button
                    type="submit"
                    className="btn btn-xs btn-primary"
                    style={{ float: "right" }}
                  >
                    Send
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default ModalOrderBookingAdvancesDetails;
