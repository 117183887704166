import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import loadable from "@loadable/component";
import { Spin } from "antd";
import { POST_GetEquipmentTypeDDL } from "../../../Helper/url_helper";
import { post } from "../../../Helper/api_helper";

const EquipmentTypeWiseMonthlyBookingChart = loadable(() =>
  import("./EquipmentTypeWiseMonthlyBookingChart")
);
const EquipmentTypeWiseWeeklyBookingChart = loadable(() =>
  import("./EquipmentTypeWiseWeeklyBookingChart")
);
const EquipmentTypeWiseTodaysBookingChart = loadable(() =>
  import("./EquipmentTypeWiseTodaysBookingChart")
);

export default function EquipmentTypeWiseBookingChart(props) {
  const [tabKey, setTabKey] = useState("monthly");

  const [isLoading, setIsLoading] = useState(false);

  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([]);

  const loadEquipmentTypeDDL = () => {
    setIsLoading(true);
    post(POST_GetEquipmentTypeDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setEquipmentTypeOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    loadEquipmentTypeDDL();
  }, []);

  return (
    <Spin size="large" spinning={isLoading} tip={"Loading..."}>
      <Tab.Container activeKey={tabKey}>
        <div className="card">
          <div className="card-header d-sm-flex d-block border-0 p-0">
            <div></div>
            <div className="card-action card-tabs mt-0 mt-sm-0">
              <Nav className="nav nav-tabs" role="tablist">
                <Nav.Item as="li" className="nav-item" key="monthly">
                  <Nav.Link
                    className="nav-link"
                    data-toggle="tab"
                    eventKey="monthly"
                    role="tab"
                    onClick={() => setTabKey("monthly")}
                  >
                    Monthly
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item" key="weekly">
                  <Nav.Link
                    className="nav-link"
                    data-toggle="tab"
                    eventKey="weekly"
                    role="tab"
                    onClick={() => setTabKey("weekly")}
                  >
                    Weekly
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item" key="today">
                  <Nav.Link
                    className="nav-link"
                    data-toggle="tab"
                    eventKey="today"
                    role="tab"
                    onClick={() => setTabKey("today")}
                  >
                    Today
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="card-body pb-0">
            <Tab.Content>
              <Tab.Pane className="pb-0" eventKey="monthly" key="monthly">
                <div className="chart-warp" style={{ overflow: "hidden" }}>
                  {tabKey == "monthly" && (
                    <EquipmentTypeWiseMonthlyBookingChart
                      CompanyID={props.CompanyID}
                      optionsYear={props.optionsYear}
                      optionsEquipmentType={equipmentTypeOptions}
                    />
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane className="pb-0" eventKey="weekly" key="weekly">
                <div className="chart-warp" style={{ overflow: "hidden" }}>
                  {tabKey == "weekly" && (
                    <EquipmentTypeWiseWeeklyBookingChart
                      CompanyID={props.CompanyID}
                      optionsYear={props.optionsYear}
                      optionsEquipmentType={equipmentTypeOptions}
                    />
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane className="pb-0" eventKey="today" key="today">
                <div className="chart-warp" style={{ overflow: "hidden" }}>
                  {tabKey == "today" && (
                    <EquipmentTypeWiseTodaysBookingChart
                      CompanyID={props.CompanyID}
                    />
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </Spin>
  );
}
