import React, { Fragment, useEffect, useState } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import { Row, Col } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import { Spin } from "antd";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_StaffPerformanceReportInExcel,
  POST_GetDepartmentList,
  POST_GetDesignationList,
} from "../../../Helper/url_helper";

const StaffPerformanceReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchemaReports = Yup.object().shape({
    EmploymentType: Yup.string().required("Employment Type  is required field"),
    StaffCategory: Yup.string().required("Staff Category is required field"),
    SalaryType: Yup.string().required("Salary Type is required field"),
  });

  const formik = useFormik({
    validationSchema: validationSchemaReports,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: props.companyID,
      Report: props.reportID,
      DepartmentID: 0,
      DesignationID: 0,
      EmploymentType: 0,
      StaffCategory: 0,
      SalaryType: 0,
      FromDate: "",
      ToDate: "",
    },
    validateOnBlur: false,
  });

  const {
    values: { CompanyID },
  } = formik;

  useEffect(() => {
    loadDepartmentDDL();
    loadDesignationDDL();
  }, []);

  const employmentTypeOptions = [
    { label: "All", value: 0 },
    { label: "Permanent", value: 1 },
    { label: "Freelancer", value: 2 },
  ];

  const staffCategoryOptions = [
    { label: "All", value: 0 },
    { label: "L1", value: 1 },
    { label: "L2", value: 2 },
    { label: "L3", value: 3 },
    { label: "Not Applicable", value: 4 },
  ];

  const salaryTypeOptions = [
    { label: "All", value: 0 },
    { label: "Fix", value: 1 },
    { label: "Hourly", value: 2 },
    { label: "Per Visit", value: 3 },
  ];

  const loadDepartmentDDL = async () => {
    await post(POST_GetDepartmentList)
      .then((res) => {
        if (res.IsSuccess) {
          setDepartmentOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadDesignationDDL = async () => {
    await post(POST_GetDesignationList)
      .then((res) => {
        if (res.IsSuccess) {
          setDesignationOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      DepartmentID: values.DepartmentID,
      DesignationID: values.DesignationID,
      EmploymentType: values.EmploymentType,
      StaffCategory: values.StaffCategory,
      SalaryType: values.SalaryType,
      CompanyIDEncrypt: CompanyID,
      FromDate: values.FromDate,
      ToDate: values.ToDate,
    };

    // console.log("Data", Data);

    let response = await post(POST_StaffPerformanceReportInExcel, Data);
    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} style={{ textAlign: "left", fontSize: "15px" }}>
                <div style={{ color: "black" }}>Filters</div> <br></br>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Field
                  label={"Department"}
                  name="DepartmentID"
                  options={departmentOptions}
                  placeholder={"Select Department"}
                  component={CustomSelect}
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Designation"}
                  name="DesignationID"
                  options={designationOptions}
                  placeholder={"Select Designation"}
                  component={CustomSelect}
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Employment Type"}
                  name="EmploymentType"
                  options={employmentTypeOptions}
                  placeholder={"Select Employment Type"}
                  component={CustomSelect}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Field
                  label={"Staff Category"}
                  name="StaffCategory"
                  options={staffCategoryOptions}
                  placeholder={"Select Staff Category"}
                  component={CustomSelect}
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Salary Type"}
                  name="SalaryType"
                  options={salaryTypeOptions}
                  placeholder={"Select Salary Type"}
                  component={CustomSelect}
                />
              </Col>
            </Row>

            <hr></hr>
            <Row className="mt-3">
              <Col lg={12}>
                <button type="submit" className="btn mr-2 btn-primary btn-sm">
                  View Report
                </button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Spin>
    </Fragment>
  );
};

export default StaffPerformanceReport;
