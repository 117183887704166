import React, { Fragment, useEffect, useState } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import { Row, Col } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import { Spin } from "antd";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_GetRevenueSummary,
  POST_GetRevenueServiceWiseData,
  POST_GetRevenueEquipmentWiseData,
  POST_GetRevenuePackageWiseData,
  POST_GetRevenueHealthCheckupWiseData,
  POST_GetRevenuePathologyWiseData,
  POST_GetRevenueRadiologyWiseData,
  POST_GetYearlyAnalysisDataWiseYearDDLList,
  POST_GetAreaListOfExstingPatientAndStaff,
} from "../../../Helper/url_helper";

const RevenueReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [yearReportAnalysisOptions, setYearAnalysisReportOptions] = useState(
    []
  );
  const [areaReportOptions, setAreaReportOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchemaReports = Yup.object().shape({
    RevenueReportTypeID: Yup.string().required(
      "Revenue Report Type is required field"
    ),
    Year: Yup.string().required("Year is required field"),
    // Year: Yup.string().when("RevenueReportTypeID", {
    //   is: (RevenueReportTypeID) => RevenueReportTypeID == 1,
    //   then: Yup.string().required("Year is required field"),
    //   otherwise: Yup.string(),
    // }),
  });

  const formik = useFormik({
    validationSchema: validationSchemaReports,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: props.companyID,
      Report: props.reportID,

      RevenueReportTypeID: "",
      Year: "",
      Month: 0,
      AreaID: 0,
      SubReportType: 1,
    },
    validateOnBlur: false,
  });

  const {
    values: { CompanyID, RevenueReportTypeID, SubReportType },
  } = formik;

  useEffect(() => {
    loadYearlyAnalysisDataDDL();
    loadAreaDDL();
  }, []);

  useEffect(() => {
    formik.setFieldValue("Year", "");
    formik.setFieldValue("RevenueReportTypeID", "");
    loadYearlyAnalysisDataDDL();
  }, [CompanyID]);

  const revenueReportOptions = [
    { label: "Revenue Summary Report", value: 1 },
    { label: "Revenue Summary Service Wise Report", value: 2 },
    { label: "Revenue Summary Equipment Wise Report", value: 3 },
    { label: "Revenue Summary Package Wise Report", value: 4 },
    { label: "Revenue Summary Health Chekup Wise Report", value: 5 },
    { label: "Revenue Summary Pathology Report Wise Report", value: 6 },
    { label: "Revenue Summary Radiology Report Wise Report", value: 7 },
  ];

  const monthReportOptions = [
    { label: "All", value: 0 },
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const loadAreaDDL = async () => {
    //await post(POST_GetAreaListOfExstingPatientAndStaff)
    await post(POST_GetAreaListOfExstingPatientAndStaff, {
      CompanyIDEncrypted: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setAreaReportOptions(
            res.AreaList.map((item) => ({
              label: item.AreaName,
              value: item.AreaID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadYearlyAnalysisDataDDL = () => {
    setIsLoading(true);
    post(POST_GetYearlyAnalysisDataWiseYearDDLList, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setYearAnalysisReportOptions(
            res.AdminGetYearDDLList.map((item) => ({
              label: item.YearName,
              value: item.YearName,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      Year: values.Year,
      Month: values.Month,
      ReportType: values.SubReportType,
      AreaID: values.AreaID,
      CompanyIDEncrypt: CompanyID,
    };

    // console.log("Data", Data);

    let response = "";

    if (RevenueReportTypeID == 1) {
      response = await post(POST_GetRevenueSummary, Data);
    } else if (RevenueReportTypeID == 2) {
      response = await post(POST_GetRevenueServiceWiseData, Data);
    } else if (RevenueReportTypeID == 3) {
      response = await post(POST_GetRevenueEquipmentWiseData, Data);
    } else if (RevenueReportTypeID == 4) {
      response = await post(POST_GetRevenuePackageWiseData, Data);
    } else if (RevenueReportTypeID == 5) {
      response = await post(POST_GetRevenueHealthCheckupWiseData, Data);
    } else if (RevenueReportTypeID == 6) {
      response = await post(POST_GetRevenuePathologyWiseData, Data);
    } else if (RevenueReportTypeID == 7) {
      response = await post(POST_GetRevenueRadiologyWiseData, Data);
    }

    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} style={{ textAlign: "left", fontSize: "15px" }}>
                <div style={{ color: "black" }}>Filters</div> <br></br>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <Field
                  label={"Revenue Report Type"}
                  name="RevenueReportTypeID"
                  options={revenueReportOptions}
                  placeholder={"Select Revenue Report Type"}
                  component={CustomSelect}
                  isClearable={false}
                  required
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Year"}
                  name="Year"
                  options={yearReportAnalysisOptions}
                  placeholder={"Select Year"}
                  component={CustomSelect}
                  isClearable={false}
                  required
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Month"}
                  name="Month"
                  options={monthReportOptions}
                  placeholder={"Select Month"}
                  component={CustomSelect}
                />
              </Col>
              {/* {SubReportType == 2 ? (
                <Col lg={3}>
                  <Field
                    label={"Area"}
                    name="AreaID"
                    options={areaReportOptions}
                    placeholder={"Select Area"}
                    component={CustomSelect}
                  />
                </Col>
              ) : (
                ""
              )} */}
              <Col lg={4}>
                <label
                  className="radio-inline mr-3"
                  style={{ marginTop: "13%", fontSize: "15px" }}
                >
                  <input
                    type="radio"
                    name="SubReportType"
                    value={1}
                    checked={SubReportType === 1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formik.setFieldValue("SubReportType", 1);
                        formik.setFieldValue("AreaID", "");
                      }
                    }}
                  />{" "}
                  Summary Report
                </label>
                <label
                  className="radio-inline mr-3"
                  style={{ marginTop: "13%", fontSize: "15px" }}
                >
                  <input
                    type="radio"
                    name="SubReportType"
                    value={2}
                    checked={SubReportType === 2 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formik.setFieldValue("SubReportType", 2);
                      }
                    }}
                  />{" "}
                  Area Wise
                </label>
              </Col>
            </Row>

            <hr></hr>
            <Row className="mt-3">
              <Col lg={12}>
                <button type="submit" className="btn mr-2 btn-primary btn-sm">
                  View Report
                </button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Spin>
    </Fragment>
  );
};

export default RevenueReport;
