import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { Button, Form } from "reactstrap";
import { Row, Col, Modal } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { Link, useHistory } from "react-router-dom";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import {
  POST_EnquiryCreate,
  GET_GetEnquiryTypeList,
  POST_GetEnquiryTypeWiseDetail,
} from "../../../Helper/url_helper";
import { post, get } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import moment from "moment";

const AddEnquiry = () => {
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [optionEnquiryType, setOptionEnquiryType] = useState([]);
  const [optionEnquiryTypeWiseDetail, setOptionEnquiryTypeWiseDetail] =
    useState([]);

  const [addEnquiryTypeModal, setAddEnquiryTypeModal] = useState(false);

  const validationSchema = Yup.object().shape({
    ContactPersonName: Yup.string().required(
      "Contact Person Name is required field"
    ),
    EnquiryDate: Yup.string().required("Enquiry Date is required field"),
    ContactPersonMobileNo: Yup.string().required(
      "Contact No is required field"
    ),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      ContactPersonName: "",
      ContactPersonMobileNo: "",
      EnquiryDate: "",
      ListOfEnquiry: [],
      CountryCode: "+91",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    values: { ListOfEnquiry, CountryCode },
  } = formik;

  const validationSchemaEnquiryType = Yup.object().shape({
    EnquiryType: Yup.string().required("Enquiry Type is required field"),
    // ReferenceID: Yup.string().required("Enquiry For is required field"),
    ReferenceID: Yup.string().when("EnquiryType", {
      is: (EnquiryType) => EnquiryType != 7,
      then: Yup.string().nullable().required("Enquiry For is required field"),
      otherwise: Yup.string().nullable(),
    }),
    EnquiryRemarks: Yup.string().required("Enquiry Remark is required field"),
  });

  const formikEnquiryType = useFormik({
    validationSchema: validationSchemaEnquiryType,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitEnquiryType(values, formikHelpers),
    initialValues: {
      EnquiryType: "",
      EnquiryTypeName: "",
      ReferenceID: "",
      EnquiryForName: "",
      EnquiryRemarks: "",
    },
    validateOnBlur: false,
  });

  const {
    values: { EnquiryType },
  } = formikEnquiryType;

  const loadDDlEnquiryType = () => {
    setIsLoading(true);
    get(GET_GetEnquiryTypeList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionEnquiryType(
            res.List.map((item) => ({
              label: item.Name,
              value: item.EnquiryTypeID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    formik.resetForm();
    loadDDlEnquiryType();
  }, []);

  const loadDDlEnquiryTypeWisedetail = (enquiryTypeID) => {
    setIsLoading(true);
    post(POST_GetEnquiryTypeWiseDetail, {
      EnquiryType: enquiryTypeID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionEnquiryTypeWiseDetail(
            res.EnquiryEnquiryTypeWiseList.map((item) => ({
              label: item.Name,
              value: item.IDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (ListOfEnquiry.length === 0) {
      swal({
        text: "Please Add Enquiry detail...",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setIsLoading(true);

    var data = {
      ...values,
      CompanyID: objLogin.CompanyIDEncrypt,
      ClientID: "",
      CountryCode: "+" + CountryCode.replace(/\+/g, ""),
      EnquiryDate: `${moment(values.EnquiryDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      CityName: "",
      ListOfEnquiryDetail: ListOfEnquiry,
    };

    post(POST_EnquiryCreate, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          history.push("/enquiry-list");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const handleValidSubmitEnquiryType = (values, { setSubmitting }) => {
    setIsLoading(true);
    setFieldValue("ListOfEnquiry", [
      ...ListOfEnquiry,
      {
        ...values,
        EnquiryDetailID: "",
        EnquiryID: "",
      },
    ]);
    setAddEnquiryTypeModal(false);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu="Add Enquiry"
          motherMenu={<Link to="/enquiry-list">Enquiries</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row className="mb-2">
                        <Col lg={4}>
                          <Field
                            label={"Contact Person Name"}
                            name="ContactPersonName"
                            placeholder={"Select Contact Person Name"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "ContactPersonName",
                                e.target.value.replace(/[^A-Za-z ]/g, "")
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Contact No."}
                            name="ContactPersonMobileNo"
                            placeholder={"Select Contact No"}
                            country={country}
                            countryCode={CountryCode}
                            component={CustomPhoneInput}
                            onChange={(value, { countryCode, dialCode }) => {
                              setFieldValue("CountryCode", dialCode);
                              setFieldValue(
                                "ContactPersonMobileNo",
                                value.substring(dialCode.toString().length)
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Enquiry Date"}
                            name="EnquiryDate"
                            placeholder={"Select Enquiry Date"}
                            component={CustomFlatpickr}
                            options={{
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      {/* <FieldArray
                        name="ListOfEnquiry"
                        render={(arrayHelpers) => {
                          return (
                            <Fragment>
                              {ListOfEnquiry?.map((Enquiry, index) => (
                                <div data-repeater-list="group-a" key={index}>
                                  <Row>
                                    <Col lg={3}>
                                      <Field
                                        label={"Enquiry Type"}
                                        name={`ListOfEnquiry.[${index}].EnquiryType`}
                                        placeholder={"Select Enquiry Type"}
                                        options={optionEnquiryType}
                                        component={CustomSelect}
                                        //onChange={handleChangeEnquiryType}
                                        onChange={(event) => {
                                          setFieldValue(
                                            `ListOfEnquiry.[${index}].EnquiryType`,
                                            event == null ? 0 : event.value
                                          );
                                          setMode(
                                            event == null ? 0 : event.value
                                          );
                                          loadDDlEnquiryTypeWisedetail(
                                            event == null ? 0 : event.value
                                          );
                                        }}
                                        required
                                      />
                                    </Col>
                                    {mode > 0 && mode < 7 ? (
                                      <Col lg={3}>
                                        <Field
                                          label={
                                            mode == 1
                                              ? "Service"
                                              : mode == 2
                                              ? "Equipments"
                                              : mode == 3
                                              ? "Pathology"
                                              : mode == 4
                                              ? "Radiology"
                                              : mode == 5
                                              ? "Package"
                                              : mode == 6
                                              ? "Health Checkup"
                                              : ""
                                          }
                                          name={`ListOfEnquiry.[${index}].ReferenceID`}
                                          onChange={(event) => {
                                            setFieldValue(
                                              `ListOfEnquiry.[${index}].ReferenceID`,
                                              event == null ? 0 : event.value
                                            );
                                          }}
                                          options={optionEnquiryTypeWiseDetail}
                                          component={CustomSelect}
                                          required
                                        />
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    <Col lg={4}>
                                      <Field
                                        label={"Enquiry Remark"}
                                        name={`ListOfEnquiry.[${index}].EnquiryRemarks`}
                                        placeholder={"Enter Enquiry Remark"}
                                        component={CustomInput}
                                        required
                                      />
                                    </Col>
                                    {index !== 0 && (
                                      <Col
                                        lg={2}
                                        className="align-self-center mt-3 mb-3"
                                      >
                                        <div className="d-grid">
                                          <label className="form-label">
                                            <span
                                              style={{
                                                color: "white",
                                              }}
                                            >
                                              .
                                            </span>
                                          </label>
                                          <input
                                            data-repeater-delete
                                            type="button"
                                            className="btn btn-primary btn-xs mt-3"
                                            value="Delete"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              ))}
                              <Row>
                                <Col lg={12} style={{ textAlign: "right" }}>
                                  <Button
                                    color="success"
                                    className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                    onClick={() =>
                                      arrayHelpers.push(initEnquiry)
                                    }
                                  >
                                    <i className="fa fa-plus"></i> &nbsp; Add
                                  </Button>
                                </Col>
                              </Row>
                            </Fragment>
                          );
                        }}
                      /> */}
                      <Row>
                        <Col lg={12} style={{ textAlign: "right" }}>
                          <button
                            type="button"
                            className="btn mr-2 btn-primary btn-xs"
                            onClick={() => {
                              setAddEnquiryTypeModal(true);
                              formikEnquiryType.resetForm();
                            }}
                          >
                            + Add Enquiry Detail
                          </button>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={12}>
                          <div className="table-responsive ">
                            <div className="dataTables_wrapper no-footer">
                              <table
                                className="table verticle-middle table-responsive-md mb-3"
                                style={{ width: "98%" }}
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Enquiry Type: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 15px",
                                      }}
                                    >
                                      Enquiry Type
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Enquiry For: activate to sort column ascending"
                                      style={{
                                        width: "40%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Enquiry For
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Enquiry Remark: activate to sort column ascending"
                                      style={{
                                        width: "40%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Enquiry Remark
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListOfEnquiry?.length > 0 ? (
                                    ListOfEnquiry?.map((item, index) => (
                                      <tr role="row">
                                        <td>{item.EnquiryTypeName}</td>
                                        <td>
                                          {item.EnquiryType == 7
                                            ? "-"
                                            : item.EnquiryForName}
                                        </td>
                                        <td>{item.EnquiryRemarks}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={3}>
                                        <div className="mt-2 mb-3">
                                          {"No Record Found"}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn mr-2 btn-primary"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={(e) => {
                              history.goBack();
                            }}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="modal fade"
          show={addEnquiryTypeModal}
          onHide={setAddEnquiryTypeModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Enquiry Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setAddEnquiryTypeModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikEnquiryType}>
              <Form onSubmit={formikEnquiryType.handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <Field
                      label={"Enquiry Type"}
                      name="EnquiryType"
                      placeholder={"Select Enquiry Type"}
                      component={CustomSelect}
                      options={optionEnquiryType}
                      onChange={(event) => {
                        formikEnquiryType.setFieldValue(
                          "EnquiryType",
                          event == null ? "" : event.value
                        );

                        loadDDlEnquiryTypeWisedetail(
                          event == null ? 0 : event.value
                        );
                        formikEnquiryType.setFieldValue(
                          "EnquiryTypeName",
                          event == null ? "" : event.label
                        );
                      }}
                      isClearable={false}
                      required
                    />
                  </Col>
                  {EnquiryType != 7 ? (
                    <Col lg={6}>
                      <Field
                        label={"Enquiry For"}
                        name="ReferenceID"
                        placeholder={"Select Enquiry For"}
                        component={CustomSelect}
                        options={optionEnquiryTypeWiseDetail}
                        onChange={(event) => {
                          formikEnquiryType.setFieldValue(
                            "ReferenceID",
                            event == null ? "" : event.value
                          );
                          formikEnquiryType.setFieldValue(
                            "EnquiryForName",
                            event == null ? "" : event.label
                          );
                        }}
                        required
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col lg={12}>
                    <Field
                      label={"Enquiry Remark"}
                      name="EnquiryRemarks"
                      placeholder={"Enter Enquiry Remark"}
                      component={CustomInput}
                      options={{
                        optionEnquiryType,
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <button type="submit" className="btn mr-2 btn-primary">
                      Add
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={(e) => {
                        setAddEnquiryTypeModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default AddEnquiry;
