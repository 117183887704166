import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label, InputGroup } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Tab, Nav, Modal, Button } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { Link, useHistory, useParams } from "react-router-dom";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import { get, post, postFormData } from "../../../Helper/api_helper";
import {
  //GET_GetAdvanceSlabConfiguration,
  GET_GetConfiguration,
  GET_GetServiceTypeDDL,
  POST_AdminStaffsListDDLByCompanyID,
  POST_ClientPatientAddressInsert,
  POST_ClientPatientAddressUpdate,
  POST_GetAreaList,
  POST_GetCityList,
  POST_GetClientStaffListByServiceID,
  POST_GetDocumentsDDL,
  POST_GetEquipmentTypeDDL,
  POST_GetEquipmentsDDLByEquipmentTypeID,
  POST_GetHealthCheckupsListDDLByCompanyID,
  POST_GetModeOfPaymentDDL,
  POST_GetOrderBookingDetailByID,
  POST_GetPackagesDetailListDDLByCompanyID,
  POST_GetPathologyReportsListDDLByCompanyID,
  POST_GetPatientAddressList,
  POST_GetPatientDetailByID,
  POST_GetPatientOrderHistoryList,
  POST_GetRadiologyReportsListDDLByCompanyID,
  POST_GetSLAbyOrderBookingID,
  POST_GetServiceDropDownListByTypeSubTypeAndCompany,
  POST_GetServiceHoursDDLByServiceID,
  POST_GetServiceSubTypeDDL,
  POST_GetStateList,
  POST_OrderBookingEquipmentPDCFileInsert,
  POST_OrderBookingInsert,
  POST_OrderBookingPatientDocumentDelete,
  POST_OrderBookingPatientDocumentInsert,
  POST_OrderBookingPatientDocumentsList,
  POST_OrderBookingUpdate,
} from "../../../Helper/url_helper";
import ModalPatientDetails from "../../../Custom/ModalPatientDetails";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import moment from "moment";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { List } from "antd";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

const AddOrder = () => {
  const { SelectedPatientIDParam, OrderBookingIDParam } = useParams();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [tab, setTab] = useState(1);
  const [country, setCountry] = useState("in");

  const [patientAddresssOptions, setPatientAddresssOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [optionServiceType, setOptionServiceType] = useState([]);
  const [optionServiceSubType, setOptionServiceSubType] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceHoursOptions, setServiceHoursOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [healthCheckupOptions, setHealthCheckupOptions] = useState([]);
  const [pathologyReportsOptions, setPathologyReportsOptions] = useState([]);
  const [radiologyReportsOptions, setRadiologyReportsOptions] = useState([]);
  const [modeOfPaymentOptions, setModeOfPaymentOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [allStaffOptions, setAllStaffOptions] = useState([]);

  const [documentModal, setDocumentModal] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [pathologyReportModal, setPathologyReportModal] = useState(false);
  const [radiologyReportModal, setRadiologyReportModal] = useState(false);
  const [healthCheckupModal, setHealthCheckupModal] = useState(false);
  const [patientPastHistoryModel, setPatientPastHistoryModal] = useState(false);
  const [patientAddressModel, setPatientAddressModal] = useState(false);
  const [patientDetailModel, setPatientDetailModal] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [orderSuccessModal, setOrderSuccessModal] = useState(false);
  const [serviceForceCloseModal, setServiceForceCloseModal] = useState(false);
  const [serviceExtendModal, setServiceExtendModal] = useState(false);
  const [equipmentForceCloseModal, setEquipmentForceCloseModal] =
    useState(false);
  const [equipmentExtendModal, setEquipmentExtendModal] = useState(false);

  const [OrderHistoryDataList, setOrderHistoryDataList] = useState([]);
  const [orderBookingID_Detail, setOrderBookingID_Detail] = useState("");
  const [advanceBookingMaxDate, setAdvanceBookingMaxDate] = useState(
    new Date()
  );
  // const [advanceSlabDetail, setAdvanceSlabDetail] = useState("");
  const [advanceCutOffDays, setAdvanceCutOffDays] = useState(3);
  const [advanceMinimumDays, setAdvanceMinimumDays] = useState(1);
  const [advanceMaximumDays, setAdvanceMaximumDays] = useState(3);
  const [diagnosticsAdvancePercentage, setDiagnosticsAdvancePercentage] =
    useState(100);
  const [patientDocumentList, setPatientDocumentList] = useState("");

  const [pdc, setPDC] = useState(null);
  const [pdcFile, setPDCFile] = useState(null);
  const [fileKeyPDC, setFileKeyPDC] = useState();
  const [validationPDCFile, setValidationPDCFile] = useState("");

  const [documentFile, setDocumentFile] = useState(null);
  const [fileKeyDocument, setFileKeyDocument] = useState();

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const [addressSuggesionDivStatus, setAddressSuggesionDivStatus] =
    useState(false);

  const [patientAddressLatitude, setPatientAddressLatitude] = useState("");
  const [patientAddressLongitude, setPatientAddressLongitude] = useState("");

  const { placePredictions, getPlacePredictions, isPlacePredictionLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const BloodPressureOption = [
    { label: "Normal", value: 1 },
    { label: "High", value: 2 },
    { label: "Low", value: 3 },
  ];

  const loadStateDDL = async () => {
    await post(POST_GetStateList)
      .then((res) => {
        if (res.IsSuccess) {
          setStateOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadCityDDL = async (selectedStateID) => {
    await post(POST_GetCityList + selectedStateID)
      .then((res) => {
        if (res.IsSuccess) {
          setCityOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            CityID &&
            res.List?.filter((x) => x.CategoryID == CityID).length == 0
          ) {
            formikAddress.setFieldValue("CityID", "");
            formikAddress.setFieldValue("AreaID", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadAreaDDL = async (selectedCityID) => {
    await post(POST_GetAreaList + selectedCityID)
      .then((res) => {
        if (res.IsSuccess) {
          setAreaOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            AreaID &&
            res.List?.filter((x) => x.CategoryID == AreaID).length == 0
          ) {
            formikAddress.setFieldValue("AreaID", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const get_Configuration = async () => {
    let response = await get(GET_GetConfiguration);
    if (response.IsSuccess) {
      if (parseInt(response.AdvanceBookingDays) > 0) {
        let maxDate = new Date();
        maxDate = new Date(
          maxDate.setDate(
            maxDate.getDate() + parseInt(response.AdvanceBookingDays)
          )
        );
        setAdvanceBookingMaxDate(maxDate);
      }
      setAdvanceCutOffDays(response.ServiceEquipmentAdvanceCutOffDays);
      setAdvanceMinimumDays(response.ServiceEquipmentAdvanceMinimumDays);
      setAdvanceMaximumDays(response.ServiceEquipmentAdvanceMaximumDays);
      setDiagnosticsAdvancePercentage(response.DiagnosticsAdvancePercentage);
    }
  };

  const validationSchema = Yup.object().shape({
    PatientNo: Yup.string(),
    PatientName: Yup.string(),
    PatientAddressIDEncrypt: Yup.string().required(
      "Patient Address is required field"
    ),
    IsOnGoingTreatment: Yup.boolean(),
    OnGoingTreatmentInformation: Yup.string()
      .trim()
      .when("IsOnGoingTreatment", {
        is: (IsOnGoingTreatment) => IsOnGoingTreatment == true,
        then: Yup.string()
          .trim()
          .required("On Going Treatment Information is required field"),
      }),
    HospitalName: Yup.string()
      .trim()
      .when("IsOnGoingTreatment", {
        is: (IsOnGoingTreatment) => IsOnGoingTreatment == true,
        then: Yup.string().trim().required("Hospital Name is required field"),
      }),
    DoctorName: Yup.string()
      .trim()
      .when("IsOnGoingTreatment", {
        is: (IsOnGoingTreatment) => IsOnGoingTreatment == true,
        then: Yup.string().trim().required("Doctor Name is required field"),
      }),
    GrossAmount: Yup.string(),
    DiscountAmount: Yup.string(),
    TaxAmount: Yup.string(),
    NetAmount: Yup.string(),
    DepositAmount: Yup.string(),
    AdvanceAmount: Yup.string(),
    ModeOfPaymentID: Yup.string().when("AdvanceAmount", {
      is: (AdvanceAmount) => parseFloat(AdvanceAmount) > 0,
      then: Yup.string().required("Mode of Payment is required field"),
    }),
    AdvanceRemarks: Yup.string(),
    BloodPressure: Yup.string().required("Blood Pressure is required field"),
    IsPDCReceived: Yup.boolean(),
    PDCRemarks: Yup.string(),
    //   .trim()
    //   .when("IsPDCReceived", {
    //     is: (IsPDCReceived) => IsPDCReceived == true,
    //     then: Yup.string().trim().required("Deposit Remarks is required field"),
    //   }),
    PDCRecievedByStaffIDEncrypt: Yup.string()
      .trim()
      .when("IsPDCReceived", {
        is: (IsPDCReceived) => IsPDCReceived == true,
        then: Yup.string().trim().required("Staff is required field"),
      }),
    PDCGivenBy: Yup.string()
      .trim()
      .when("IsPDCReceived", {
        is: (IsPDCReceived) => IsPDCReceived == true,
        then: Yup.string()
          .trim()
          .required("Deposit Given By is required field"),
      }),
    PDCGivenByMobileNo: Yup.string()
      .trim()
      .when("IsPDCReceived", {
        is: (IsPDCReceived) => IsPDCReceived == true,
        then: Yup.string().trim().required("Mobile No. is required field"),
      }),
    DepositModeOfPaymentID: Yup.string()
      .trim()
      .when("IsPDCReceived", {
        is: (IsPDCReceived) => IsPDCReceived == true,
        then: Yup.string().trim().required("Mode Of Payment is required field"),
      }),
    IsCoMorbitCondition: Yup.boolean(),
    CoMorbitCondition: Yup.string().when("IsCoMorbitCondition", {
      is: true,
      then: Yup.string().required("Co Morbit Condition Field is required"),
    }),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      OrderBookingIDEncrypt: OrderBookingIDParam
        ? window.atob(OrderBookingIDParam)
        : "",
      PatientIDEncrypt: SelectedPatientIDParam
        ? window.atob(SelectedPatientIDParam)
        : "",
      ClientIDEncrypt: "",
      CompanyIDEncrypt: AuthUser?.CompanyIDEncrypt,
      OrderBookingNo: "",
      OrderBookingDate: "",
      PatientAddressIDEncrypt: "",
      PatientNo: "",
      PatientName: "",
      MobileNo: "",
      IsDiabetese: false,
      BloodPressure: "",
      PatientPastHistoryList: [],
      ServicesList: [],
      EquipmentsList: [],
      PackagesList: [],
      HealthCheckupsList: [],
      PathologyReportsList: [],
      RadiologyReportsList: [],
      IsOnGoingTreatment: false,
      OnGoingTreatmentInformation: "",
      HospitalName: "",
      DoctorName: "",
      PastHistory: "",
      ModeOfPaymentID: "",
      AdvanceRemarks: "",
      IsPDCReceived: false,
      GrossAmount: "",
      DiscountAmount: "0.00",
      TaxAmount: "",
      NetAmount: "",
      AdvanceAmount: "",
      DepositAmount: "0.00",
      //ActualTotalAdvanceAmount: "",
      PDCRemarks: "",
      PDCRecievedByStaffIDEncrypt: "",
      PDCGivenBy: "",
      PDCGivenByCountryCode: "+91",
      PDCGivenByMobileNo: "",
      OrderBookingEquipmentPDCDetailIDEncrypted: "",
      PaymentTransactionID: "",
      DepositModeOfPaymentID: "0",
      DepositPaymentTransactionID: "",
      IsDepositInPDC: false,
      ReceivedAdvanceAmount: "",
      IsFullPay: false,
      IsCoMorbitCondition: false,
      CoMorbitCondition: "",
      TransportationCharges: "0.00",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      PatientIDEncrypt,
      ClientIDEncrypt,
      OrderBookingNo,
      OrderBookingDate,
      PatientNo,
      PatientName,
      MobileNo,
      PatientPastHistoryList,
      OrderBookingIDEncrypt,
      IsOnGoingTreatment,
      IsDiabetese,
      IsPDCReceived,
      CompanyIDEncrypt,
      ServicesList,
      EquipmentsList,
      PackagesList,
      HealthCheckupsList,
      PathologyReportsList,
      RadiologyReportsList,
      GrossAmount,
      DiscountAmount,
      TaxAmount,
      NetAmount,
      AdvanceAmount,
      DepositAmount,
      //ActualTotalAdvanceAmount,
      PDCGivenByCountryCode,
      OrderBookingEquipmentPDCDetailIDEncrypted,
      IsDepositInPDC,
      IsFullPay,
      IsCoMorbitCondition,
      TransportationCharges,
    },
  } = formik;

  const handleValidSubmit = async (values, { setSubmitting }) => {
    if (
      ServicesList?.filter((x) => x.IsDeleted == false)?.length == 0 &&
      EquipmentsList?.filter((x) => x.IsDeleted == false)?.length == 0 &&
      PackagesList?.filter((x) => x.IsDeleted == false)?.length == 0 &&
      HealthCheckupsList?.filter((x) => x.IsDeleted == false)?.length == 0 &&
      PathologyReportsList?.filter((x) => x.IsDeleted == false)?.length == 0 &&
      RadiologyReportsList?.filter((x) => x.IsDeleted == false)?.length == 0
    ) {
      swal("", "There is no data to save!", "warning");
      return false;
    }

    if (IsPDCReceived && pdcFile === null && pdc === null && IsDepositInPDC) {
      setValidationPDCFile("PDC File is required");
      return false;
    }

    // if (OrderBookingIDEncrypt) {
    //   swal(
    //     "Coming Soon...",
    //     "Order Booking Update is not available!",
    //     "warning"
    //   );
    //   return false;
    // }
    setIsLoading(true);

    var paylod = {
      ...values,
      PaymentTransactionID: values.PaymentTransactionID
        ? values.PaymentTransactionID
        : "0",
      ModeOfPaymentID: values.ModeOfPaymentID ? values.ModeOfPaymentID : 0,
      AdvanceAmount: values.AdvanceAmount.toString(),
      PastHistory: values.PastHistory.trim(),
      PDCGivenByCountryCode:
        "+" + values.PDCGivenByCountryCode.replace(/\+/g, ""),
      EquipmentDepositAmount: values.DepositAmount,
      DepositPaymentTransactionID: values.DepositPaymentTransactionID
        ? values.DepositPaymentTransactionID
        : "0",
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
      ModifiedByEncrypt: AuthUser?.LoginIDEncrypt,
    };

    delete paylod["ClientOrderBookingServicesDetails"];
    delete paylod["ClientOrderBookingEquipmentsDetails"];
    delete paylod["ClientOrderBookingPackagesDetails"];
    delete paylod["ClientOrderBookingHealthCheckupsDetails"];
    delete paylod["ClientOrderBookingPathologyReportsDetails"];
    delete paylod["ClientOrderBookingRadiologyReportsDetails"];
    delete paylod["ClientOrderBookingPackageInclusionServicesDetails"];
    delete paylod["ClientOrderBookingPackageInclusionEquipmentsDetails"];
    delete paylod["ClientOrderBookingHealthCheckupInclusionServicesDetails"];
    delete paylod[
      "ClientOrderBookingHealthCheckupInclusionPathologyReportsDetails"
    ];
    delete paylod[
      "ClientOrderBookingHealthCheckupInclusionRadiologyReportsDetails"
    ];
    delete paylod["ClientOrderBookingPatientDocumentsList"];
    //console.log("payload: ", paylod);

    post(
      OrderBookingIDEncrypt ? POST_OrderBookingUpdate : POST_OrderBookingInsert,
      paylod
    )
      .then((response) => {
        if (response.IsSuccess) {
          if (!OrderBookingIDEncrypt) {
            setFieldValue(
              "OrderBookingIDEncrypt",
              response.OrderBookingIDEncrypted
            );
            setOrderBookingID_Detail(response.OrderBookingIDEncrypted); //to open Order detail after Save
          } else {
            setOrderBookingID_Detail(OrderBookingIDEncrypt); //to open Order detail after Save
          }
          if (IsPDCReceived) {
            if (!OrderBookingEquipmentPDCDetailIDEncrypted) {
              setFieldValue(
                "OrderBookingEquipmentPDCDetailIDEncrypted",
                response.OrderBookingEquipmentPDCDetailIDEncrypted
              );
            }
            SavePDFFile(
              !OrderBookingEquipmentPDCDetailIDEncrypted
                ? response.OrderBookingEquipmentPDCDetailIDEncrypted
                : OrderBookingEquipmentPDCDetailIDEncrypted
            );
          }
          //swal("Success!", t(response.MessageCode), "success");
          setOrderSuccessModal(true);
          setTimeout(() => {
            setTab(2);
          }, 1000);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const SavePDFFile = (OrderBookingEquipmentPDCDetailID) => {
    if (pdcFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append(
        "EncryptOrderBookingEquipmentPDCDetailID",
        OrderBookingEquipmentPDCDetailID
      );
      formData.append("PDCFilePath", pdcFile);

      postFormData(POST_OrderBookingEquipmentPDCFileInsert, formData)
        .then((res) => {
          if (res.IsSuccess) {
            setIsLoading(false);
          } else {
            console.log(res);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (PatientIDEncrypt) {
      loadStateDDL();
      loadServiceTypeDDL();
      loadEquipmentTypeDDL();
      loadPackageDDL();
      loadHealthCheckupDDL();
      loadPathologyReportDDL();
      loadRadiologyReportDDL();
      get_Configuration();
      //loadAdvanceSlabConfig();
      loadModeOfPaymentOptionsDDL();
      loadDocuumentsDDL();
      loadAllStaffDDL();
      setIsLoading(true);
      if (OrderBookingIDParam) {
        post(POST_GetOrderBookingDetailByID, {
          OrderBookingID: window.atob(OrderBookingIDParam),
        })
          .then((response) => {
            if (response?.IsSuccess) {
              if (response?.IsOpen == false) {
                swal(
                  "Can't edit order booking!",
                  "Order has been closed.",
                  "warning"
                );
                setTimeout(() => {
                  history.push("/order-booking-list");
                }, 2000);
              } else {
                setValues({
                  ...response,
                  AdvanceAmount: response.TotalAdvanceAmount,
                  ReceivedAdvanceAmount: response.TotalAdvanceAmount,
                  IsFullPay: response.NetAmount == response.TotalAdvanceAmount,
                  ServicesList: response.ClientOrderBookingServicesDetails?.map(
                    (x) => ({
                      ...x,
                      FromDate: x.ServiceScheduleStartDate,
                      ServiceHours: x.Hours ? x.Hours + " Hours" : "",
                      IsDeleted: false,
                      StaffActualAdvanceAmount: x.StaffAdvanceAmount,
                      RotationalActualAdvanceAmount: x.RotationalAdvanceAmount,
                      ServiceScheduleActualEndDate: x.ServiceScheduleEndDate,
                      ServiceScheduleStartDate: `${moment(
                        new Date(x.ServiceScheduleStartDate)
                      )
                        .format("YYYY-MM-DD")
                        .replace(/^\-/, "")} 00:00:00.000`,
                      ServiceScheduleEndDate: `${moment(
                        new Date(x.ServiceScheduleEndDate)
                      )
                        .format("YYYY-MM-DD")
                        .replace(/^\-/, "")} 00:00:00.000`,
                    })
                  ),
                  EquipmentsList:
                    response.ClientOrderBookingEquipmentsDetails?.map((x) => ({
                      ...x,
                      EquipmentTypeName: x.EquipmentType,
                      EquipmentAdvanceAmount: x.AdvanceAmount,
                      EquipmentDepositAmount: x.DepositAmount,
                      EquipmentTotalDepositAmount: x.TotalDepositAmount,
                      IsDeleted: false,
                      EquipmentActualAdvanceAmount: x.AdvanceAmount,
                      EquipmentRequiredActualEndDate:
                        x.EquipmentRequiredEndDate,
                      EquipmentRequiredOnDate: `${moment(
                        new Date(x.EquipmentRequiredOnDate)
                      )
                        .format("YYYY-MM-DD")
                        .replace(/^\-/, "")} 00:00:00.000`,
                      EquipmentRequiredEndDate: `${moment(
                        new Date(x.EquipmentRequiredEndDate)
                      )
                        .format("YYYY-MM-DD")
                        .replace(/^\-/, "")} 00:00:00.000`,
                    })),
                  PackagesList: response.ClientOrderBookingPackagesDetails?.map(
                    (x) => ({
                      ...x,
                      PackageDuration: x.PackageDurationInDays,
                      IsDeleted: false,
                      ActualAdvanceAmount: x.AdvanceAmount,
                    })
                  ),
                  HealthCheckupsList:
                    response.ClientOrderBookingHealthCheckupsDetails?.map(
                      (x) => ({
                        ...x,
                        IsDeleted: false,
                        ActualAdvanceAmount: x.AdvanceAmount,
                      })
                    ),
                  PathologyReportsList:
                    response.ClientOrderBookingPathologyReportsDetails?.map(
                      (x) => ({
                        ...x,
                        DateOfPathologyReport: x.ScheduleDate,
                        TimeOfPathologyReport: x.ScheduleTime,
                        IsDeleted: false,
                        ActualAdvanceAmount: x.AdvanceAmount,
                      })
                    ),
                  RadiologyReportsList:
                    response.ClientOrderBookingRadiologyReportsDetails?.map(
                      (x) => ({
                        ...x,
                        DateOfRadiologyReport: x.ScheduleDate,
                        TimeOfRadiologyReport: x.ScheduleTime,
                        IsDeleted: false,
                        ActualAdvanceAmount: x.AdvanceAmount,
                      })
                    ),
                  DepositAmount: response.EquipmentDepositAmount,
                  PDCRecievedByStaffIDEncrypt: response.PDCReceivedByEncrypt,
                });
                if (response.PDCFilePath) {
                  setPDC(response.PDCFilePath);
                  setFieldValue("IsDepositInPDC", true);
                }
                setPatientDocumentList([
                  ...response.ClientOrderBookingPatientDocumentsList,
                ]);
              }
            } else {
              console.log(response);
              swal("Error!", t(response?.MessageCode), "error");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      post(POST_GetPatientDetailByID, {
        PatientIDEncrypted: PatientIDEncrypt,
      })
        .then((response) => {
          if (response?.IsSuccess) {
            setFieldValue("ClientIDEncrypt", response.ClientIDEncrypt);
            setFieldValue("PatientNo", response.PatientNo);
            setFieldValue(
              "PatientName",
              response.FirstName +
                " " +
                response.MiddleName +
                " " +
                response.LastName
            );
            setFieldValue("CountryCode", response.CountryCode);
            setFieldValue(
              "MobileNo",
              response.CountryCode + " " + response.MobileNo
            );
            setFieldValue("IsCoMorbitCondition", response.IsCoMorbitCondition);
            setFieldValue("CoMorbitCondition", response.CoMorbitCondition);
            console.log("response.AddressList", response.AddressList);
            setPatientAddresssOptions(
              response.AddressList?.map((item) => ({
                label:
                  item.Address +
                  ", " +
                  item.Location +
                  ", " +
                  item.AreaName +
                  ", " +
                  item.CityName +
                  ", " +
                  item.StateName,
                value: item.PatientAddressIDEncrypt,
                patientAddressLatitude: item.Latitude,
                patientAddressLongitude: item.Longitude,
              }))
            );
            setFieldValue("PatientPastHistoryList", response.PastHistoryList);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, []);

  const getAddressList = async () => {
    setIsLoading(true);
    await post(POST_GetPatientAddressList, {
      ClientIDEncrypt: ClientIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setPatientAddresssOptions(
            res.ClientPatientAddressesList?.map((item) => ({
              label:
                item.Address +
                ", " +
                item.Location +
                ", " +
                item.AreaName +
                ", " +
                item.CityName +
                ", " +
                item.StateName,
              value: item.PatientAddressIDEncrypt,
              patientAddressLatitude: item.Latitude,
              patientAddressLongitude: item.Longitude,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaAddress = Yup.object().shape({
    Address: Yup.string().required("Address is required field"),
    StateID: Yup.string().required("State is required field"),
    CityID: Yup.string().required("City is required field"),
    AreaID: Yup.string().required("Area is required field"),
    Location: Yup.string().required("Location is required field"),
    Pincode: Yup.string().required("Pincode is required field"),
  });

  const formikAddress = useFormik({
    validationSchema: validationSchemaAddress,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitAddress(values, formikHelpers),
    initialValues: {
      PatientAddressIDEncrypt: "",
      Address: "",
      StateID: "",
      CityID: "",
      AreaID: "",
      Location: "",
      Pincode: "",
      Latitude: 0,
      Longitude: 0,
    },
    validateOnBlur: false,
  });

  const {
    values: { StateID, CityID, AreaID, PatientAddressIDEncrypt },
  } = formikAddress;

  useEffect(() => {
    if (StateID) {
      loadCityDDL(StateID ? StateID : 0);
    } else {
      setCityOptions([]);
      setAreaOptions([]);
      formikAddress.setFieldValue("CityID", "");
      formikAddress.setFieldValue("AreaID", "");
    }
  }, [StateID]);

  useEffect(() => {
    if (CityID) {
      loadAreaDDL(CityID ? CityID : 0);
    } else {
      setAreaOptions([]);
      formikAddress.setFieldValue("AreaID", "");
    }
  }, [CityID]);

  const handleValidSubmitAddress = (values) => {
    setIsLoading(true);
    var paylod = {
      ...values,
      ClientIDEncrypt: ClientIDEncrypt,
      Latitude: values.Latitude,
      Longitude: values.Longitude,
      PatientAddressIDEncrypt: PatientAddressIDEncrypt,
    };
    //console.log("payload: ", paylod);
    post(
      PatientAddressIDEncrypt === ""
        ? POST_ClientPatientAddressInsert
        : POST_ClientPatientAddressUpdate,
      paylod
    )
      .then((response) => {
        if (response.IsSuccess) {
          //swal("Success!", t(response.MessageCode), "success");
          setPatientAddressModal(false);
          formikAddress.resetForm();
          getAddressList();
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getOrderHistory = async (patientID) => {
    setIsLoading(true);
    await post(POST_GetPatientOrderHistoryList, {
      PatientIDEncrypted: patientID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setOrderHistoryModal(true);
          setOrderHistoryDataList(response.OrderHistoryList);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log(response.MessageCode);
          console.log(response.Message);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadServiceTypeDDL = async () => {
    await get(GET_GetServiceTypeDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionServiceType(
            res.ServiceTypesList?.map((item) => ({
              label: item.ServiceTypeEnglish,
              value: item.ServiceTypeIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadServiceSubTypeDDL = async (selectedServiceType) => {
    await post(POST_GetServiceSubTypeDDL, {
      ServiceTypeIDEncrypt: selectedServiceType,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionServiceSubType(
            res.ClientServiceSubTypesList?.map((item) => ({
              label: item.ServiceSubTypeEnglish,
              value: item.ServiceSubTypeIDEncrypt,
            }))
          );
          if (res.ClientServiceSubTypesList?.length === 0) {
            loadServiceDDL(selectedServiceType, "");
          } else {
            setServiceOptions([]);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadServiceDDL = async (
    selectedServiceType,
    selectedServiceSubType
  ) => {
    await post(POST_GetServiceDropDownListByTypeSubTypeAndCompany, {
      ServiceTypeIDEncrypted: selectedServiceType,
      ServiceSubTypeIDEncrypted: selectedServiceSubType,
      CompanyIDEncrypted: CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setServiceOptions(
            res.ServiceList?.map((item) => ({
              label: item.ServiceName,
              value: item.ServiceIDEncrypted,
              chargeType: item.ChargeType,
              visitDuration: item.VisitDuration,
            }))
          );
          if (
            ServiceIDEncrypt &&
            res.ServiceList?.filter(
              (x) => x.ServiceIDEncrypted == ServiceIDEncrypt
            )?.length == 0
          ) {
            formikService.setFieldValue("ServiceIDEncrypt", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadServiceHoursDDL = async (selectedServiceID) => {
    await post(POST_GetServiceHoursDDLByServiceID, {
      CompanyID: CompanyIDEncrypt,
      ServiceID: selectedServiceID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setServiceHoursOptions(
            res.AdminServiceHoursDDLList?.map((item) => ({
              label: item.HoursCaption,
              value: item.ServiceHourIDEncrypt,
              isRotationalService: item.HourlyServiceType === 2 ? true : false,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadStaffDDL = async () => {
    setIsLoading(true);
    await post(POST_GetClientStaffListByServiceID, {
      CompanyIDEncrypt: CompanyIDEncrypt,
      CurrentPage: 1,
      PageSize: 1000,
      Search: "",
      Sorting: "",
      ServiceIDEncrypt: ServiceIDEncrypt,
      CityName: "",
      FromDate: `${moment(ServiceScheduleStartDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      ToDate: `${moment(ServiceScheduleEndDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      ServiceHourIDEncrypt: ChargeType === 1 ? ServiceHourIDEncrypt : "",
      FromTime: moment(ServiceScheduleTime).format("h:mm A"),
      Latitude: patientAddressLatitude,
      Longitude: patientAddressLongitude,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setStaffOptions(
            res.ClientServiceWiseStaffList.map((item) => ({
              label:
                item.StaffName +
                "  -  (₹ " +
                item.SpecialRate +
                ")" +
                (item.IsBooked ? " - (Booked)" : ""),
              value: item.StaffIDEncrypt,
              staffName: item.StaffName,
              staffRate: item.SpecialRate,
              startTime: item.StartTime,
              finishTime: item.FinishTime,
              isBooked: item.IsBooked,
            }))
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaService = Yup.object().shape({
    ServiceTypeIDEncrypt: Yup.string().required(
      "Service Type is required field"
    ),
    ServiceSubTypeIDEncrypt: Yup.string().when("ServiceTypeIDEncrypt", {
      is: (ServiceTypeIDEncrypt) => optionServiceSubType?.length > 0,
      then: Yup.string()
        .nullable()
        .required("Service Sub Type is required field"),
      otherwise: Yup.string().nullable(),
    }),
    ServiceIDEncrypt: Yup.string().required("Service is required field"),
    ChargeType: Yup.number(),
    ServiceHourIDEncrypt: Yup.string().when("ChargeType", {
      is: (ChargeType) => ChargeType === 1,
      then: Yup.string().nullable().required("Service Hours is required field"),
      otherwise: Yup.string().nullable(),
    }),
    StaffIDEncrypt: Yup.string().required("Staff is required field"),
    RotationalStaffIDEncrypt: Yup.string().when("IsRotationalService", {
      is: (IsRotationalService) => IsRotationalService === true,
      then: Yup.string()
        .nullable()
        .required("Rotational Staff is required field"),
      otherwise: Yup.string().nullable(),
    }),
    IsRotationalService: Yup.boolean(),
    ServiceScheduleStartDate: Yup.string().required(
      "Schedule From Date is required field"
    ),
    ServiceScheduleEndDate: Yup.string().required(
      "Schedule To Date is required field"
    ),
    ServiceScheduleTime: Yup.string().required("Time is required field"),
    ServiceRate: Yup.string(),
    ServiceBookingDays: Yup.string(),
    TotalAmount: Yup.string(),
  });

  const formikService = useFormik({
    validationSchema: validationSchemaService,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitService(values, formikHelpers),
    initialValues: {
      OrderBookingServiceIDEncrypt: "",
      ServiceTypeIDEncrypt: "",
      ServiceSubTypeIDEncrypt: "",
      ServiceIDEncrypt: "",
      ServiceName: "",
      ChargeType: 1,
      VisitDuration: "",
      ServiceHourIDEncrypt: "",
      ServiceHours: "",
      IsRotationalService: false,
      StaffIDEncrypt: "",
      StaffName: "",
      StaffServiceRate: 0,
      RotationalStaffIDEncrypt: "",
      RotationalStaffName: "",
      RotationalStaffServiceRate: 0,
      ServiceScheduleStartDate: "",
      ServiceScheduleEndDate: "",
      ServiceScheduleTime: new Date(),
      ServiceRate: "",
      ServiceBookingDays: "",
      ServiceTotalAmount: "",
      ServiceScheduleStartTime: "",
      ServiceScheduleEndTime: "",
      IsDeleted: false,
      StaffAdvanceAmount: "",
      StaffActualAdvanceAmount: "",
      RotationalAdvanceAmount: "",
      RotationalActualAdvanceAmount: "",
      IsOngoing: false,
      IsClosed: false,
    },
    validateOnBlur: false,
  });
  const {
    values: {
      OrderBookingServiceIDEncrypt,
      ServiceTypeIDEncrypt,
      ServiceSubTypeIDEncrypt,
      ServiceIDEncrypt,
      ServiceHourIDEncrypt,
      ChargeType,
      //VisitDuration,
      IsRotationalService,
      ServiceScheduleStartDate,
      ServiceScheduleEndDate,
      ServiceScheduleTime,
      StaffServiceRate,
      RotationalStaffServiceRate,
      ServiceRate,
      ServiceBookingDays,
      StaffIDEncrypt,
      RotationalStaffIDEncrypt,
    },
  } = formikService;

  const handleValidSubmitService = (values) => {
    // if (
    //   ServicesList?.filter((x) => x.IsDeleted == false)?.filter(
    //     (x) => x.ServiceIDEncrypt === values.ServiceIDEncrypt
    //   )?.length > 0
    // ) {
    //   swal("", "Service already exists!", "warning");
    //   return;
    // }
    /*To allow Same Service With Different Staff Or Time*/
    if (
      ServicesList?.filter((x) => x.IsDeleted == false)?.filter(
        (x) =>
          x.ServiceIDEncrypt === values.ServiceIDEncrypt &&
          x.StaffIDEncrypt === values.StaffIDEncrypt &&
          x.RotationalStaffIDEncrypt === values.RotationalStaffIDEncrypt &&
          ((moment(x?.ServiceScheduleStartTime, "hh:mma").toDate() >=
            moment(values?.ServiceScheduleStartTime, "hh:mma").toDate() &&
            moment(x?.ServiceScheduleStartTime, "hh:mma").toDate() <=
              moment(values?.ServiceScheduleEndTime, "hh:mma").toDate()) ||
            (moment(x?.ServiceScheduleEndTime, "hh:mma").toDate() >=
              moment(values?.ServiceScheduleStartTime, "hh:mma").toDate() &&
              moment(x?.ServiceScheduleEndTime, "hh:mma").toDate() <=
                moment(values?.ServiceScheduleEndTime, "hh:mma").toDate())) &&
          ((new Date(x?.ServiceScheduleStartDate) >=
            new Date(values?.ServiceScheduleStartDate) &&
            new Date(x?.ServiceScheduleStartDate) <=
              new Date(values?.ServiceScheduleEndDate)) ||
            (new Date(x?.ServiceScheduleEndDate) >=
              new Date(values?.ServiceScheduleStartDate) &&
              new Date(x?.ServiceScheduleEndDate) <=
                new Date(values?.ServiceScheduleEndDate)))
      )?.length > 0
    ) {
      swal("", "Service already exists!", "warning");
      return;
    }
    let newService = {
      ...values,
      BookingDays: values.ServiceBookingDays.toString(),
      GrossAmount: values.ServiceTotalAmount,
      DiscountAmount: "0.00",
      NetAmount: values.ServiceTotalAmount,
      FromDate: `${moment(values.ServiceScheduleStartDate)
        .format("DD-MMM-YYYY")
        .replace(/^\-/, "")}`,
      ServiceScheduleStartDate: `${moment(values.ServiceScheduleStartDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      ServiceScheduleEndDate: `${moment(values.ServiceScheduleEndDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      StaffAdvanceAmount: values.StaffAdvanceAmount.toString(),
      RotationalAdvanceAmount: values.RotationalAdvanceAmount.toString(),
      RotationalStaffServiceRate: values.RotationalStaffServiceRate.toString(),
      ServiceScheduleActualEndDate: `${moment(values.ServiceScheduleEndDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
    };
    //console.log("newService: ", newService);
    setFieldValue("ServicesList", [...ServicesList, newService]);
    setServiceModal(false);
  };

  const delete_Service = (orderBookingServiceID, index, isOngoing) => {
    if (isOngoing) {
      swal(
        "",
        "Can't delete because service is on going or completed!",
        "warning"
      );
    } else {
      swal({
        title: "Are you sure you want to delete the service?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (orderBookingServiceID) {
            setFieldValue(`ServicesList.[${index}].IsDeleted`, true);
          } else {
            setFieldValue(
              "ServicesList",
              ServicesList?.filter((x, i) => i !== index)
            );
          }
        }
      });
    }
  };

  const formikServiceForceClose = useFormik({
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitServiceForceClose(values, formikHelpers),
    initialValues: {
      OrderBookingServiceIDEncrypt: "",
      ForceCloseServiceName: "",
    },
    validateOnBlur: false,
  });

  const {
    values: { ForceCloseServiceName },
  } = formikServiceForceClose;

  const handleValidSubmitServiceForceClose = (values) => {
    formik.setFieldValue(
      "ServicesList",
      ServicesList?.map((x) => {
        if (
          x.OrderBookingServiceIDEncrypt == values.OrderBookingServiceIDEncrypt
        ) {
          return { ...x, IsClosed: true };
        } else {
          return { ...x };
        }
      })
    );
    setServiceForceCloseModal(false);
  };

  const validationSchemaServiceExtend = Yup.object().shape({
    ServiceScheduleExtendFromDate: Yup.string().required(
      "Schedule From Date is required"
    ),
    ServiceScheduleExtendToDate: Yup.string().required(
      "Schedule To Date is required"
    ),
  });

  const formikServiceExtend = useFormik({
    validationSchema: validationSchemaServiceExtend,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitServiceExtend(values, formikHelpers),
    initialValues: {
      OrderBookingServiceIDEncrypt: "",
      ExtendServiceName: "",
      ServiceScheduleExtendFromDate: new Date(),
      ServiceScheduleActualToDate: new Date(),
      ServiceScheduleExtendToDate: new Date(),
    },
    validateOnBlur: false,
  });

  const {
    values: {
      ExtendServiceName,
      ServiceScheduleActualToDate,
      ServiceScheduleExtendToDate,
    },
  } = formikServiceExtend;

  useEffect(() => {
    if (!ServiceScheduleExtendToDate) {
      formikServiceExtend.setFieldValue("ServiceScheduleExtendToDate", "");
    }
  }, [ServiceScheduleExtendToDate]);

  const handleValidSubmitServiceExtend = (values) => {
    formik.setFieldValue(
      "ServicesList",
      ServicesList?.map((x) => {
        if (
          x.OrderBookingServiceIDEncrypt == values.OrderBookingServiceIDEncrypt
        ) {
          let bookingDays =
            (new Date(values.ServiceScheduleExtendToDate) -
              new Date(x.ServiceScheduleStartDate)) /
              (1000 * 60 * 60 * 24) +
            1;
          let totalAmount = (
            (x.ServiceRate ? parseFloat(x.ServiceRate) : 0) *
            (bookingDays ? parseInt(bookingDays) : 0)
          ).toFixed(2);
          return {
            ...x,
            BookingDays: bookingDays.toString(),
            ServiceScheduleEndDate: `${moment(
              values.ServiceScheduleExtendToDate
            )
              .format("YYYY-MM-DD")
              .replace(/^\-/, "")} 00:00:00.000`,
            GrossAmount: totalAmount.toString(),
            NetAmount: totalAmount.toString(),
          };
        } else {
          return { ...x };
        }
      })
    );
    setServiceExtendModal(false);
  };

  useEffect(() => {
    if (ServiceTypeIDEncrypt) {
      loadServiceSubTypeDDL(ServiceTypeIDEncrypt);
    } else {
      setOptionServiceSubType([]);
      formikService.setFieldValue("ServiceSubTypeIDEncrypt", "");
    }
  }, [ServiceTypeIDEncrypt]);

  useEffect(() => {
    if (ServiceTypeIDEncrypt && ServiceSubTypeIDEncrypt) {
      loadServiceDDL(ServiceTypeIDEncrypt, ServiceSubTypeIDEncrypt);
    } else {
      setServiceOptions([]);
      formikService.setFieldValue("ServiceIDEncrypt", "");
    }
  }, [ServiceSubTypeIDEncrypt]);

  useEffect(() => {
    if (ServiceIDEncrypt) {
      let service = serviceOptions?.filter(
        (x) => x.value === ServiceIDEncrypt
      )[0];
      formikService.setFieldValue("ServiceName", service?.label);
      formikService.setFieldValue("ChargeType", service?.chargeType);
      if (service?.chargeType === 1) {
        loadServiceHoursDDL(ServiceIDEncrypt);
      } else {
        formikService.setFieldValue("VisitDuration", service?.visitDuration);
        setServiceHoursOptions([]);
        formikService.setFieldValue("ServiceHourIDEncrypt", "");
      }
    } else {
      setServiceHoursOptions([]);
      formikService.setFieldValue("ServiceHourIDEncrypt", "");
      formikService.setFieldValue("ChargeType", 1);
      formikService.setFieldValue("VisitDuration", "");
    }
  }, [ServiceIDEncrypt]);

  useEffect(() => {
    if (ServiceHourIDEncrypt) {
      let serviceHour = serviceHoursOptions?.filter(
        (x) => x.value === ServiceHourIDEncrypt
      )[0];
      formikService.setFieldValue("ServiceHours", serviceHour?.label);
      formikService.setFieldValue(
        "IsRotationalService",
        serviceHour?.isRotationalService
      );
      if (serviceHour?.isRotationalService === false) {
        formikService.setFieldValue("RotationalStaffIDEncrypt", "");
        formikService.setFieldValue("RotationalStaffName", "");
        formikService.setFieldValue("RotationalStaffServiceRate", 0);
        formikService.setFieldValue("RotationalAdvanceAmount", 0);
        formikService.setFieldValue("RotationalActualAdvanceAmount", 0);
      }
    } else {
      formikService.setFieldValue("IsRotationalService", false);
      formikService.setFieldValue("RotationalStaffIDEncrypt", "");
      formikService.setFieldValue("RotationalStaffName", "");
      formikService.setFieldValue("RotationalStaffServiceRate", 0);
      formikService.setFieldValue("RotationalAdvanceAmount", 0);
      formikService.setFieldValue("RotationalActualAdvanceAmount", 0);
    }
  }, [ServiceHourIDEncrypt]);

  useEffect(() => {
    if (
      ServiceIDEncrypt &&
      ServiceScheduleStartDate &&
      ServiceScheduleEndDate &&
      ServiceScheduleTime
    ) {
      if (ChargeType === 1) {
        if (ServiceHourIDEncrypt) {
          loadStaffDDL();
        } else {
          setStaffOptions([]);
          // formikService.setFieldValue("StaffIDEncrypt", "");
          // formikService.setFieldValue("RotationalStaffIDEncrypt", "");
          // formikService.setFieldValue("RotationalStaffName", "");
          // formikService.setFieldValue("RotationalStaffServiceRate", 0);
          // formikService.setFieldValue("RotationalAdvanceAmount", 0);
          // formikService.setFieldValue("RotationalActualAdvanceAmount", 0);
        }
      } else {
        loadStaffDDL();
      }
      formikService.setFieldValue("StaffIDEncrypt", "");
      formikService.setFieldValue("RotationalStaffIDEncrypt", "");
      formikService.setFieldValue("RotationalStaffName", "");
      formikService.setFieldValue("RotationalStaffServiceRate", 0);
      formikService.setFieldValue("RotationalAdvanceAmount", 0);
      formikService.setFieldValue("RotationalActualAdvanceAmount", 0);
      calcServiceRate(0, 0);
    } else {
      setStaffOptions([]);
      formikService.setFieldValue("StaffIDEncrypt", "");
      formikService.setFieldValue("RotationalStaffIDEncrypt", "");
      formikService.setFieldValue("RotationalStaffName", "");
      formikService.setFieldValue("RotationalStaffServiceRate", 0);
      formikService.setFieldValue("RotationalAdvanceAmount", 0);
      formikService.setFieldValue("RotationalActualAdvanceAmount", 0);
      calcServiceRate(0, 0);
    }
  }, [
    ServiceIDEncrypt,
    ServiceHourIDEncrypt,
    ServiceScheduleStartDate,
    ServiceScheduleEndDate,
    ServiceScheduleTime,
  ]);

  useEffect(() => {
    if (ServiceScheduleStartDate) {
      calcServiceBookingDays();
    } else {
      formikService.setFieldValue("ServiceScheduleStartDate", "");
      formikService.setFieldValue("ServiceBookingDays", "");
    }
  }, [ServiceScheduleStartDate]);

  useEffect(() => {
    if (ServiceScheduleEndDate) {
      calcServiceBookingDays();
    } else {
      formikService.setFieldValue("ServiceScheduleEndDate", "");
      formikService.setFieldValue("ServiceBookingDays", "");
    }
  }, [ServiceScheduleEndDate]);

  const calcServiceBookingDays = () => {
    if (ServiceScheduleStartDate && ServiceScheduleEndDate) {
      if (
        new Date(ServiceScheduleEndDate) < new Date(ServiceScheduleStartDate)
      ) {
        formikService.setFieldValue("ServiceScheduleEndDate", "");
      } else {
        let serviceBookingDays =
          (new Date(ServiceScheduleEndDate) -
            new Date(ServiceScheduleStartDate)) /
            (1000 * 60 * 60 * 24) +
          1;
        formikService.setFieldValue("ServiceBookingDays", serviceBookingDays);
        calcServiceTotalAmount(ServiceRate, serviceBookingDays);
      }
    }
  };

  const calcServiceRate = (staffServiceRate, rotationalStaffServiceRate) => {
    let serviceRate = (
      parseFloat(staffServiceRate) + parseFloat(rotationalStaffServiceRate)
    ).toFixed(2);
    formikService.setFieldValue("ServiceRate", serviceRate);

    let staffAdvance =
      parseInt(ServiceBookingDays) > advanceCutOffDays
        ? parseFloat(staffServiceRate) * advanceMaximumDays
        : parseFloat(staffServiceRate) * advanceMinimumDays;
    formikService.setFieldValue("StaffAdvanceAmount", staffAdvance);
    formikService.setFieldValue("StaffActualAdvanceAmount", staffAdvance);

    let rotationalStaffAdvance =
      parseInt(ServiceBookingDays) > advanceCutOffDays
        ? parseFloat(rotationalStaffServiceRate) * advanceMaximumDays
        : parseFloat(rotationalStaffServiceRate) * advanceMinimumDays;
    formikService.setFieldValue(
      "RotationalAdvanceAmount",
      rotationalStaffAdvance
    );
    formikService.setFieldValue(
      "RotationalActualAdvanceAmount",
      rotationalStaffAdvance
    );

    calcServiceTotalAmount(serviceRate, ServiceBookingDays);
  };

  const calcServiceTotalAmount = (serviceRate, bookingDays) => {
    let serviceTotalAmount = (
      (serviceRate ? parseFloat(serviceRate) : 0) *
      (bookingDays ? parseInt(bookingDays) : 0)
    ).toFixed(2);
    formikService.setFieldValue("ServiceTotalAmount", serviceTotalAmount);
  };

  const loadEquipmentTypeDDL = () => {
    setIsLoading(true);
    post(POST_GetEquipmentTypeDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setEquipmentTypeOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const loadEquipmentDDL = async (equipmentType) => {
    setIsLoading(true);
    await post(POST_GetEquipmentsDDLByEquipmentTypeID, {
      CompanyIDEncrypt: CompanyIDEncrypt,
      EquipmentTypeID: equipmentType,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setEquipmentOptions(
            res.AdminEquipmentsListDDLByEquipmentsTypeID?.filter(
              (x) => x.IsRent === true
            )?.map((item) => ({
              label:
                item.EquipmentName +
                " - (Available Qty: " +
                item.AvailableQty +
                ")",
              value: item.EquipmentIDEncrypt,
              equipmentName: item.EquipmentName,
              equipmentTypeName: item.EquipmentTypeName,
              isAllowOrderMultipleQty: item.IsAllowOrderMultipleQty,
              equipmentPrice: item.EquipmentPrice,
              equipmentDeposit: item.EquipmentDeposit,
              availableQty: item.AvailableQty,
              gstPlanIDEncrypt: item.GSTPlanID_RentEncrypt,
              gstPlanName: item.GSTPlanID_RentValue,
              gstPercentage: item.GSTPercentage_Rent,
              minimumDaysRent: item.MinimumDaysRent,
              transportationCharges: item.TransportationCharges,
            }))
          );
          if (
            EquipmentIDEncrypt &&
            res.AdminEquipmentsListDDLByEquipmentsTypeID?.filter(
              (x) =>
                x.IsRent === true && x.EquipmentIDEncrypt === EquipmentIDEncrypt
            )?.length == 0
          ) {
            resetSelectedEquipmentDetail();
          }
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  // const loadAdvanceSlabConfig = () => {
  //   get(GET_GetAdvanceSlabConfiguration).then((res) => {
  //     if (res.IsSuccess) {
  //       setAdvanceSlabDetail({
  //         ServiceEquipmentDiagnostics:
  //           res.ServiceEquipmentDiagnostics_AdvanceSlabList,
  //         Package: res.Package_AdvanceSlabList,
  //         HealthCheckup: res.HealthCheckup_AdvanceSlabList,
  //       });
  //     }
  //   });
  // };

  const schemaEquipments = Yup.object().shape({
    EquipmentTypeID: Yup.string().required(
      "Equipment Type is a required field"
    ),
    EquipmentIDEncrypt: Yup.string().required("Equipment is a required field"),
    EquipmentRentRate: Yup.string(),
    EquipmentQty: Yup.number()
      .min(1, "Quantity is a required field")
      .required("Quantity is a required field"),
    EquipmentRequiredOnDate: Yup.string().required(
      "Required On Date is a required field"
    ),
    EquipmentBookingDays: Yup.number()
      .min(1, "Booking Days is a required field")
      .required("Booking Days is a required field"),
  });

  const formikEquipments = useFormik({
    validationSchema: schemaEquipments,
    onSubmit: (values, formikHelpers) =>
      handleSubmitEquipments(values, formikHelpers),
    initialValues: {
      OrderBookingEquipmentIDEncrypt: "",
      EquipmentTypeID: "",
      EquipmentTypeName: "",
      EquipmentIDEncrypt: "",
      EquipmentName: "",
      EquipmentRentRate: "",
      EquipmentQty: "",
      EquipmentAvailableQty: "",
      EquipmentRequiredOnDate: "",
      EquipmentRequiredEndDate: "",
      EquipmentBookingDays: "",
      EquipmentGSTPlanIDEncrypt: "",
      EquipmentTaxPercentage: "0.00",
      EquipmentGrossAmount: "0.00",
      EquipmentDiscountAmount: "0.00",
      EquipmentTaxAmount: "0.00",
      EquipmentNetAmount: "0.00",
      EquipmentDepositAmount: "0.00",
      EquipmentTotalDepositAmount: "0.00",
      EquipmentAdvanceAmount: "0.00",
      EquipmentActualAdvanceAmount: "0.00",
      IsAllowOrderEquipmentMultipleQty: false,
      IsDeleted: false,
      IsOngoing: false,
      IsClosed: false,
      EquipmentMinimumDaysRent: 0,
      EquipmentTransportationCharges: "0.00",
    },
    validateOnBlur: false,
  });

  const {
    values: {
      EquipmentTypeID,
      EquipmentIDEncrypt,
      EquipmentRentRate,
      EquipmentQty,
      EquipmentAvailableQty,
      EquipmentRequiredOnDate,
      EquipmentRequiredEndDate,
      EquipmentBookingDays,
      EquipmentTaxPercentage,
      EquipmentGrossAmount,
      EquipmentDiscountAmount,
      EquipmentTaxAmount,
      EquipmentNetAmount,
      EquipmentDepositAmount,
      EquipmentAdvanceAmount,
      EquipmentTotalDepositAmount,
      IsAllowOrderEquipmentMultipleQty,
      EquipmentMinimumDaysRent,
      EquipmentTransportationCharges,
    },
  } = formikEquipments;

  const handleSubmitEquipments = (values) => {
    if (
      EquipmentsList?.filter((x) => x.IsDeleted == false)?.filter(
        (x) => x.EquipmentIDEncrypt === values.EquipmentIDEncrypt
      )?.length > 0
    ) {
      swal("", "Equipment already exists!", "warning");
      return;
    }

    let newEquipment = {
      ...values,
      BookingDays: values.EquipmentBookingDays.toString(),
      GSTPlanIDEncrypt: values.EquipmentGSTPlanIDEncrypt,
      TaxPercentage: values.EquipmentTaxPercentage.toString(),
      GrossAmount: values.EquipmentGrossAmount,
      DiscountAmount: "0.00",
      TaxAmount: values.EquipmentTaxAmount,
      NetAmount: values.EquipmentNetAmount,
      EquipmentRequiredOnDate: `${moment(values.EquipmentRequiredOnDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      EquipmentRequiredEndDate: `${moment(values.EquipmentRequiredEndDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      AdvanceAmount: values.EquipmentAdvanceAmount.toString(),
      DepositAmount: values.EquipmentDepositAmount.toString(),
      TotalDepositAmount: values.EquipmentTotalDepositAmount.toString(),
      EquipmentRequiredActualEndDate: `${moment(values.EquipmentRequiredEndDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      MinimumDaysRent: values.EquipmentMinimumDaysRent,
      TransportationCharges: values.EquipmentTransportationCharges,
    };
    //console.log("newEquipment: ", newEquipment);
    setFieldValue("EquipmentsList", [...EquipmentsList, newEquipment]);
    setEquipmentModal(false);
  };

  const delete_Equipment = (orderBookingEquipmentID, index, isOngoing) => {
    if (isOngoing) {
      swal(
        "",
        "Can't delete because equipment is on going or completed!",
        "warning"
      );
    } else {
      swal({
        title: "Are you sure you want to delete the equipment?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (orderBookingEquipmentID) {
            setFieldValue(`EquipmentsList.[${index}].IsDeleted`, true);
          } else {
            setFieldValue(
              "EquipmentsList",
              EquipmentsList?.filter((x, i) => i !== index)
            );
          }
        }
      });
    }
  };

  const validationSchemaEquipmentForceClose = Yup.object().shape({
    EquipmentCloseDate: Yup.string().required(
      "Equipment Close Date is required"
    ),
  });

  const formikEquipmentForceClose = useFormik({
    validationSchema: validationSchemaEquipmentForceClose,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitEquipmentForceClose(values, formikHelpers),
    initialValues: {
      OrderBookingEquipmentIDEncrypt: "",
      EquipmentCloseDate: "",
      ForceCloseEquipmentName: "",
      EquipmentStartDate: new Date(),
      EquipmentActualEndDate: new Date(),
    },
    validateOnBlur: false,
  });

  const {
    values: {
      ForceCloseEquipmentName,
      EquipmentCloseDate,
      EquipmentStartDate,
      EquipmentActualEndDate,
    },
  } = formikEquipmentForceClose;

  useEffect(() => {
    if (!EquipmentCloseDate) {
      formikEquipmentForceClose.setFieldValue("EquipmentCloseDate", "");
    }
  }, [EquipmentCloseDate]);

  const handleValidSubmitEquipmentForceClose = (values) => {
    formik.setFieldValue(
      "EquipmentsList",
      EquipmentsList?.map((x) => {
        if (
          x.OrderBookingEquipmentIDEncrypt ==
          values.OrderBookingEquipmentIDEncrypt
        ) {
          return {
            ...x,
            IsClosed: true,
            ClosedDate: `${moment(values.EquipmentCloseDate)
              .format("YYYY-MM-DD")
              .replace(/^\-/, "")} 00:00:00.000`,
          };
        } else {
          return { ...x };
        }
      })
    );
    setEquipmentForceCloseModal(false);
  };

  const validationSchemaEquipmentExtend = Yup.object().shape({
    EquipmentExtendRequiredFromDate: Yup.string().required(
      "Equipment From Date is required"
    ),
    EquipmentExtendRequiredToDate: Yup.string().required(
      "Equipment To Date is required"
    ),
  });

  const formikEquipmentExtend = useFormik({
    validationSchema: validationSchemaEquipmentExtend,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitEquipmentExtend(values, formikHelpers),
    initialValues: {
      OrderBookingEquipmentIDEncrypt: "",
      ExtendEquipmentName: "",
      EquipmentExtendRequiredFromDate: new Date(),
      EquipmentActualRequiredToDate: new Date(),
      EquipmentExtendRequiredToDate: new Date(),
    },
    validateOnBlur: false,
  });

  const {
    values: {
      ExtendEquipmentName,
      EquipmentActualRequiredToDate,
      EquipmentExtendRequiredToDate,
    },
  } = formikEquipmentExtend;

  useEffect(() => {
    if (!EquipmentExtendRequiredToDate) {
      formikEquipmentExtend.setFieldValue("EquipmentExtendRequiredToDate", "");
    }
  }, [EquipmentExtendRequiredToDate]);

  const handleValidSubmitEquipmentExtend = (values) => {
    formik.setFieldValue(
      "EquipmentsList",
      EquipmentsList?.map((x) => {
        if (
          x.OrderBookingEquipmentIDEncrypt ==
          values.OrderBookingEquipmentIDEncrypt
        ) {
          let bookingDays =
            (new Date(values.EquipmentExtendRequiredToDate) -
              new Date(x.EquipmentRequiredOnDate)) /
              (1000 * 60 * 60 * 24) +
            1;
          let totalGrossAmount = (
            parseInt(x.MinimumDaysRent ? x.MinimumDaysRent : 0) >
              parseInt(bookingDays) && parseInt(bookingDays) > 0
              ? (x.EquipmentRentRate ? parseFloat(x.EquipmentRentRate) : 0) *
                (x.EquipmentQty ? parseFloat(x.EquipmentQty) : 1) *
                parseInt(x.MinimumDaysRent)
              : (x.EquipmentRentRate ? parseFloat(x.EquipmentRentRate) : 0) *
                (x.EquipmentQty ? parseFloat(x.EquipmentQty) : 1) *
                (bookingDays ? parseInt(bookingDays) : 0)
          ).toFixed(2);
          let totalTaxAmount =
            (x.TaxPercentage ? parseFloat(x.TaxPercentage) : 0) > 0
              ? (parseFloat(totalGrossAmount) * parseFloat(x.TaxPercentage)) /
                100
              : 0;
          let totalNetAmount = (
            parseFloat(totalGrossAmount) + parseFloat(totalTaxAmount)
          ).toFixed(2);
          return {
            ...x,
            BookingDays: bookingDays.toString(),
            EquipmentRequiredEndDate: `${moment(
              values.EquipmentExtendRequiredToDate
            )
              .format("YYYY-MM-DD")
              .replace(/^\-/, "")} 00:00:00.000`,
            GrossAmount: totalGrossAmount.toString(),
            TaxAmount: totalTaxAmount.toString(),
            NetAmount: totalNetAmount.toString(),
          };
        } else {
          return { ...x };
        }
      })
    );
    setEquipmentExtendModal(false);
  };

  useEffect(() => {
    if (EquipmentTypeID) {
      loadEquipmentDDL(EquipmentTypeID);
    } else {
      setEquipmentOptions([]);
      resetSelectedEquipmentDetail();
    }
  }, [EquipmentTypeID]);

  useEffect(() => {
    if (EquipmentRequiredOnDate) {
      if (EquipmentBookingDays) {
        let endDate = new Date(EquipmentRequiredOnDate);
        endDate = new Date(
          endDate.setDate(endDate.getDate() + parseInt(EquipmentBookingDays))
        );
        formikEquipments.setFieldValue("EquipmentRequiredEndDate", endDate);
      } else {
        formikEquipments.setFieldValue("EquipmentRequiredEndDate", "");
      }
    } else {
      formikEquipments.setFieldValue("EquipmentRequiredOnDate", "");
      formikEquipments.setFieldValue("EquipmentRequiredEndDate", "");
    }
  }, [EquipmentRequiredOnDate]);

  useEffect(() => {
    // if (EquipmentBookingDays && EquipmentRequiredOnDate) {
    if (EquipmentBookingDays) {
      if (EquipmentRequiredOnDate) {
        let endDate = new Date(EquipmentRequiredOnDate);
        endDate = new Date(
          endDate.setDate(
            endDate.getDate() + parseInt(EquipmentBookingDays) - 1
          )
        );
        formikEquipments.setFieldValue("EquipmentRequiredEndDate", endDate);
      }
      calcEquipmentAmounts(
        EquipmentRentRate,
        EquipmentQty,
        EquipmentBookingDays,
        EquipmentTaxPercentage,
        EquipmentDepositAmount,
        EquipmentMinimumDaysRent
      );
    } else {
      formikEquipments.setFieldValue("EquipmentRequiredEndDate", "");
      calcEquipmentAmounts();
    }
  }, [EquipmentBookingDays]);

  useEffect(() => {
    calcEquipmentAmounts(
      EquipmentRentRate,
      EquipmentQty,
      EquipmentBookingDays,
      EquipmentTaxPercentage,
      EquipmentDepositAmount,
      EquipmentMinimumDaysRent
    );
  }, [EquipmentQty]);

  function resetSelectedEquipmentDetail() {
    formikEquipments.setFieldValue("EquipmentIDEncrypt", "");
    formikEquipments.setFieldValue("EquipmentName", "");
    formikEquipments.setFieldValue("EquipmentTypeName", "");
    formikEquipments.setFieldValue("IsAllowOrderEquipmentMultipleQty", false);
    formikEquipments.setFieldValue("EquipmentRentRate", "");
    formikEquipments.setFieldValue("EquipmentAvailableQty", 0);
    formikEquipments.setFieldValue("EquipmentTaxPercentage", "0.00");
    formikEquipments.setFieldValue("EquipmentGSTPlanIDEncrypt", "");
    formikEquipments.setFieldValue("EquipmentDepositAmount", "0.00");
    formikEquipments.setFieldValue("EquipmentQty", "");
    formikEquipments.setFieldValue("EquipmentMinimumDaysRent", "0");
    formikEquipments.setFieldValue("EquipmentTransportationCharges", "0.00");
    calcEquipmentAmounts();
  }

  const calcEquipmentAmounts = (
    price = 0,
    qty = 0,
    days = 0,
    gstPer = 0,
    deposit = 0,
    minimumRentDays = 0
  ) => {
    price = price ? price : 0;
    qty = qty ? qty : 0;
    days = days ? days : 0;
    gstPer = gstPer ? gstPer : 0;
    deposit = deposit ? deposit : 0;
    minimumRentDays = minimumRentDays ? minimumRentDays : 0;

    let grossAmount = 0,
      taxAmount = 0,
      netAmount = 0,
      totalDepositAmount = 0,
      totalAdvanceAmount = 0;

    if (parseInt(minimumRentDays) > parseInt(days) && parseInt(days) > 0) {
      grossAmount =
        parseFloat(price) * parseInt(qty) * parseInt(minimumRentDays);
    } else {
      grossAmount = parseFloat(price) * parseInt(qty) * parseInt(days);
    }
    taxAmount =
      gstPer > 0 ? (parseFloat(grossAmount) * parseFloat(gstPer)) / 100 : 0;
    netAmount = parseFloat(grossAmount) + parseFloat(taxAmount);
    totalDepositAmount = deposit > 0 ? parseFloat(deposit) * parseInt(qty) : 0;

    // if (
    //   netAmount > 0 &&
    //   advanceSlabDetail?.ServiceEquipmentDiagnostics?.length > 0
    // ) {
    //   let advancePercentage =
    //     advanceSlabDetail?.ServiceEquipmentDiagnostics?.filter(
    //       (x) =>
    //         netAmount >= x.AdvanceSlabStartAmount &&
    //         netAmount <= x.AdvanceSlabEndAmount
    //     )[0]?.AdvancePercentage;
    //   if (advancePercentage) {
    //     advancePercentage = parseFloat(advancePercentage);
    //   } else {
    //     advancePercentage =
    //       advanceSlabDetail?.ServiceEquipmentDiagnostics?.sort((a, b) =>
    //         a.AdvanceSlabEndAmount < b.AdvanceSlabEndAmount ? 1 : -1
    //       )[0].AdvancePercentage;
    //     advancePercentage = parseFloat(advancePercentage);
    //   }
    //   totalAdvanceAmount =
    //     (parseFloat(netAmount) * parseFloat(advancePercentage)) / 100;
    // }

    formikEquipments.setFieldValue(
      "EquipmentGrossAmount",
      grossAmount.toFixed(2)
    );
    formikEquipments.setFieldValue("EquipmentTaxAmount", taxAmount.toFixed(2));
    formikEquipments.setFieldValue("EquipmentNetAmount", netAmount.toFixed(2));
    formikEquipments.setFieldValue(
      "EquipmentTotalDepositAmount",
      totalDepositAmount.toFixed(2)
    );
    // formikEquipments.setFieldValue(
    //   "EquipmentAdvanceAmount",
    //   totalAdvanceAmount.toFixed(2)
    // );
    let rentWithGST =
      parseFloat(price) +
      (gstPer > 0 ? (parseFloat(price) * parseFloat(gstPer)) / 100 : 0);

    let equipmentAdvance = 0;
    if (parseInt(minimumRentDays) > 1) {
      if (
        parseInt(days) > parseInt(minimumRentDays) &&
        parseInt(days) > advanceCutOffDays &&
        advanceCutOffDays > parseInt(minimumRentDays) &&
        advanceMaximumDays >= parseInt(minimumRentDays)
      ) {
        equipmentAdvance = parseFloat(rentWithGST * qty) * advanceMaximumDays;
      } else {
        equipmentAdvance =
          parseFloat(rentWithGST * qty) * parseInt(minimumRentDays);
      }
    } else {
      equipmentAdvance =
        parseInt(days) > advanceCutOffDays
          ? parseFloat(rentWithGST * qty) * advanceMaximumDays
          : parseFloat(rentWithGST * qty) * advanceMinimumDays;
    }
    formikEquipments.setFieldValue("EquipmentAdvanceAmount", equipmentAdvance);
    formikEquipments.setFieldValue(
      "EquipmentActualAdvanceAmount",
      equipmentAdvance
    );
  };

  const loadPackageDDL = () => {
    setIsLoading(true);
    post(POST_GetPackagesDetailListDDLByCompanyID, {
      CompanyID: CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setPackageOptions(
            res.PackagesList?.map((item) => ({
              label: item.PackageName,
              value: item.PackageIDEncrypt,
              duration: item.PackageDurationInDays,
              packagePrice: item.PackagePrice,
              advancePercentage: item.AdvancePercentage,
              description: item.ListOfPackageDescriptions?.map(
                (x) => x.Description
              )?.join(", "),
              services: item.ListOfPackageInclusionServices?.map(
                (x) => x.ServiceName
              )?.join(", "),
              equipments: item.ListOfPackageInclusionEquipments?.map(
                (x) => x.EquipmentName
              )?.join(", "),
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const schemaPackages = Yup.object().shape({
    PackageIDEncrypt: Yup.string().required("Package is a required field"),
  });

  const formikPackages = useFormik({
    initialValues: {
      OrderBookingPackageIDEncrypt: "",
      PackageIDEncrypt: "",
      PackageName: "",
      PackageRate: "",
      //GrossAmount: "",
      //DiscountAmount: "",
      NetAmount: "",
      PackageDescription: "",
      PackageInclusionServices: "",
      PackageInclusionEquipments: "",
      PackageDuration: "",
      IsDeleted: false,
      ActualAdvanceAmount: "0.00",
      AdvanceAmount: "0.00",
      IsOngoing: false,
    },
    validationSchema: schemaPackages,
    onSubmit: (values, formikHelpers) =>
      handleSubmitPackages(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: {
      PackageDescription,
      PackageInclusionServices,
      PackageInclusionEquipments,
      PackageDuration,
    },
  } = formikPackages;

  const handleSubmitPackages = (values) => {
    if (
      PackagesList?.filter((x) => x.IsDeleted == false)?.filter(
        (x) => x.PackageIDEncrypt === values.PackageIDEncrypt
      )?.length > 0
    ) {
      swal("", "Package already exists!", "warning");
      return;
    }

    let newPackage = {
      ...values,
      GrossAmount: values.NetAmount,
      DiscountAmount: "0.00",
      AdvanceAmount: values.AdvanceAmount.toString(),
    };
    //console.log("newPackage: ", newPackage);
    setFieldValue("PackagesList", [...PackagesList, newPackage]);

    setPackageModal(false);
  };

  const delete_Package = (orderBookingPackageID, index, isOngoing) => {
    if (isOngoing) {
      swal(
        "",
        "Can't delete because package is on going or completed!",
        "warning"
      );
    } else {
      swal({
        title: "Are you sure you want to delete the package?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (orderBookingPackageID) {
            setFieldValue(`PackagesList.[${index}].IsDeleted`, true);
          } else {
            setFieldValue(
              "PackagesList",
              PackagesList?.filter((x, i) => i !== index)
            );
          }
        }
      });
    }
  };

  const loadHealthCheckupDDL = () => {
    setIsLoading(true);
    post(POST_GetHealthCheckupsListDDLByCompanyID, {
      CompanyID: CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setHealthCheckupOptions(
            res.HealthCheckupsList?.map((item) => ({
              label: item.HealthCheckupName,
              value: item.HealthCheckupIDEncrypt,
              healthCheckupPrice: item.HealthCheckupPrice,
              advancePercentage: item.AdvancePercentage,
              pathologyReports: item.ListOfPathologyReport?.map(
                (x) => x.PathologyReportName
              )?.join(", "),
              radiologyReports: item.ListOfRadiologyReports?.map(
                (x) => x.RadiologyReportName
              )?.join(", "),
              services: item.ListOfServices?.map((x) => x.ServiceName)?.join(
                ", "
              ),
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const schemaHealthCheckup = Yup.object().shape({
    HealthCheckupIDEncrypt: Yup.string().required(
      "Health Checkup is a required field"
    ),
  });

  const formikHealthCheckup = useFormik({
    initialValues: {
      OrderBookingHealthCheckupIDEncrypt: "",
      HealthCheckupIDEncrypt: "",
      HealthCheckupName: "",
      HealthCheckupRate: "",
      // GrossAmount: "",
      // DiscountAmount: "",
      NetAmount: "",
      HealthCheckupInclusionServices: "",
      HealthCheckupInclusionPathologyReports: "",
      HealthCheckupInclusionRadiologyReports: "",
      IsDeleted: false,
      ActualAdvanceAmount: "0.00",
      AdvanceAmount: "0.00",
      IsOngoing: false,
    },
    validationSchema: schemaHealthCheckup,
    onSubmit: (values, formikHelpers) =>
      handleSubmitHealthCheckup(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: {
      HealthCheckupInclusionServices,
      HealthCheckupInclusionPathologyReports,
      HealthCheckupInclusionRadiologyReports,
    },
  } = formikHealthCheckup;

  const handleSubmitHealthCheckup = (values) => {
    if (
      HealthCheckupsList?.filter((x) => x.IsDeleted == false)?.filter(
        (x) => x.HealthCheckupIDEncrypt === values.HealthCheckupIDEncrypt
      )?.length > 0
    ) {
      swal("", "Health Checkup already exists!", "warning");
      return;
    }

    let newHealthCheckup = {
      ...values,
      GrossAmount: values.NetAmount,
      DiscountAmount: "0.00",
      AdvanceAmount: values.AdvanceAmount.toString(),
    };
    //console.log("newHealthCheckup: ", newHealthCheckup);
    setFieldValue("HealthCheckupsList", [
      ...HealthCheckupsList,
      newHealthCheckup,
    ]);

    setHealthCheckupModal(false);
  };

  const delete_HealthCheckup = (
    orderBookingHealthCheckupID,
    index,
    isOngoing
  ) => {
    if (isOngoing) {
      swal(
        "",
        "Can't delete because health checkup is on going or completed!",
        "warning"
      );
    } else {
      swal({
        title: "Are you sure you want to delete the health checkup?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (orderBookingHealthCheckupID) {
            setFieldValue(`HealthCheckupsList.[${index}].IsDeleted`, true);
          } else {
            setFieldValue(
              "HealthCheckupsList",
              HealthCheckupsList?.filter((x, i) => i !== index)
            );
          }
        }
      });
    }
  };

  const loadPathologyReportDDL = () => {
    setIsLoading(true);
    post(POST_GetPathologyReportsListDDLByCompanyID, {
      CompanyID: CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setPathologyReportsOptions(
            res.PathologyReportsList?.map((item) => ({
              label: item.PathologyReportName,
              value: item.PathologyReportIDEncrypt,
              pathologyReportPrice: item.PathologyReportPrice,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const schemaPathologyReport = Yup.object().shape({
    PathologyReportIDEncrypt: Yup.string().required(
      "Pathology Report is a required field"
    ),
    PathologyReportPrice: Yup.string(),
    // GrossAmount: Yup.string(),
    // DiscountAmount: Yup.string(),
    DateOfPathologyReport: Yup.string().required("Date is a required field"),
    TimeOfPathologyReport: Yup.string().required("Time is a required field"),
  });

  const formikPathologyReport = useFormik({
    initialValues: {
      OrderBookingPathologyReportIDEncrypt: "",
      PathologyReportIDEncrypt: "",
      PathologyReportName: "",
      PathologyReportPrice: "",
      // GrossAmount: "",
      // DiscountAmount: "",
      NetAmount: "",
      DateOfPathologyReport: "",
      TimeOfPathologyReport: new Date(),
      IsDeleted: false,
      ActualAdvanceAmount: "0.00",
      AdvanceAmount: "0.00",
      IsOngoing: false,
    },
    validationSchema: schemaPathologyReport,
    onSubmit: (values, formikHelpers) =>
      handleSubmitPathologyReport(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: { TimeOfPathologyReport },
  } = formikPathologyReport;

  const handleSubmitPathologyReport = (values) => {
    if (
      PathologyReportsList?.filter((x) => x.IsDeleted == false)?.filter(
        (x) => x.PathologyReportIDEncrypt === values.PathologyReportIDEncrypt
      )?.length > 0
    ) {
      swal("", "Pathology Report already exists!", "warning");
      return;
    }
    let newPathologyReport = {
      ...values,
      ScheduleDateTime:
        moment(values.DateOfPathologyReport)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "") +
        " " +
        moment(values.TimeOfPathologyReport).format("h:mm A"),
      GrossAmount: values.NetAmount,
      DiscountAmount: "0.00",
      AdvanceAmount: values.AdvanceAmount.toString(),
      DateOfPathologyReport: moment(values.DateOfPathologyReport)
        .format("DD-MMM-YYYY")
        .replace(/^\-/, ""),
      TimeOfPathologyReport: moment(values.TimeOfPathologyReport).format(
        "h:mm A"
      ),
    };
    //console.log("newPathologyReport: ", newPathologyReport);
    setFieldValue("PathologyReportsList", [
      ...PathologyReportsList,
      newPathologyReport,
    ]);
    setPathologyReportModal(false);
  };

  const delete_PathologyReport = (
    orderBookingPathologyReportID,
    index,
    isOngoing
  ) => {
    if (isOngoing) {
      swal(
        "",
        "Can't delete because pathology report is on going or completed!",
        "warning"
      );
    } else {
      swal({
        title: "Are you sure you want to delete the pathology report?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (orderBookingPathologyReportID) {
            setFieldValue(`PathologyReportsList.[${index}].IsDeleted`, true);
          } else {
            setFieldValue(
              "PathologyReportsList",
              PathologyReportsList?.filter((x, i) => i !== index)
            );
          }
        }
      });
    }
  };

  const loadRadiologyReportDDL = () => {
    setIsLoading(true);
    post(POST_GetRadiologyReportsListDDLByCompanyID, {
      CompanyID: CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setRadiologyReportsOptions(
            res.RadiologyReportsList?.map((item) => ({
              label: item.RadiologyReportName,
              value: item.RadiologyReportIDEncrypt,
              radiologyReportPrice: item.RadiologyReportPrice,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const schemaRadiologyReport = Yup.object().shape({
    RadiologyReportIDEncrypt: Yup.string().required(
      "Radiology Report is a required field"
    ),
    RadiologyReportPrice: Yup.string(),
    // GrossAmount: Yup.string(),
    // DiscountAmount: Yup.string(),
    // NetAmount: Yup.string(),
    DateOfRadiologyReport: Yup.string().required("Date is a required field"),
    TimeOfRadiologyReport: Yup.string().required("Time is a required field"),
  });

  const formikRadiologyReport = useFormik({
    initialValues: {
      OrderBookingRadiologyReportIDEncrypt: "",
      RadiologyReportIDEncrypt: "",
      RadiologyReportName: "",
      RadiologyReportPrice: "",
      // GrossAmount: "",
      // DiscountAmount: "",
      NetAmount: "",
      DateOfRadiologyReport: "",
      TimeOfRadiologyReport: new Date(),
      IsDeleted: false,
      ActualAdvanceAmount: "0.00",
      AdvanceAmount: "0.00",
      IsOngoing: false,
    },
    validationSchema: schemaRadiologyReport,
    onSubmit: (values, formikHelpers) =>
      handleSubmitRadiologyReport(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: { TimeOfRadiologyReport },
  } = formikRadiologyReport;

  const handleSubmitRadiologyReport = (values) => {
    if (
      RadiologyReportsList?.filter((x) => x.IsDeleted == false)?.filter(
        (x) => x.RadiologyReportIDEncrypt === values.RadiologyReportIDEncrypt
      )?.length > 0
    ) {
      swal("", "Radiology Report already exists!", "warning");
      return;
    }
    let newRadiologyReport = {
      ...values,
      ScheduleDateTime:
        moment(values.DateOfRadiologyReport)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "") +
        " " +
        moment(values.TimeOfRadiologyReport).format("h:mm A"),
      GrossAmount: values.NetAmount,
      DiscountAmount: "0.00",
      AdvanceAmount: values.AdvanceAmount.toString(),
      DateOfRadiologyReport: moment(values.DateOfRadiologyReport)
        .format("DD-MMM-YYYY")
        .replace(/^\-/, ""),
      TimeOfRadiologyReport: moment(values.TimeOfRadiologyReport).format(
        "h:mm A"
      ),
    };
    //console.log("newRadiologyReport: ", newRadiologyReport);
    setFieldValue("RadiologyReportsList", [
      ...RadiologyReportsList,
      newRadiologyReport,
    ]);
    setRadiologyReportModal(false);
  };

  const delete_RadiologyReport = (
    orderBookingRadiologyReportID,
    index,
    isOngoing
  ) => {
    if (isOngoing) {
      swal(
        "",
        "Can't delete because radiology report is on going or completed!",
        "warning"
      );
    } else {
      swal({
        title: "Are you sure you want to delete the radiology report?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (orderBookingRadiologyReportID) {
            setFieldValue(`RadiologyReportsList.[${index}].IsDeleted`, true);
          } else {
            setFieldValue(
              "RadiologyReportsList",
              RadiologyReportsList?.filter((x, i) => i !== index)
            );
          }
        }
      });
    }
  };

  const calcTotalGrossAmount = () => {
    let grossAmount = 0;
    ServicesList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      grossAmount = grossAmount + parseFloat(x.GrossAmount);
    });
    EquipmentsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      grossAmount = grossAmount + parseFloat(x.GrossAmount);
    });
    PackagesList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      grossAmount = grossAmount + parseFloat(x.GrossAmount);
    });
    HealthCheckupsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      grossAmount = grossAmount + parseFloat(x.GrossAmount);
    });
    PathologyReportsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      grossAmount = grossAmount + parseFloat(x.GrossAmount);
    });
    RadiologyReportsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      grossAmount = grossAmount + parseFloat(x.GrossAmount);
    });
    setFieldValue("GrossAmount", grossAmount.toFixed(2));
  };

  const calcTotalTaxAmount = () => {
    let taxAmount = 0;
    EquipmentsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      taxAmount = taxAmount + parseFloat(x.TaxAmount);
    });
    setFieldValue("TaxAmount", taxAmount.toFixed(2));
  };

  const calcTotalNetAmount = () => {
    let netAmount = 0;
    let transportCharge = 0;
    ServicesList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      netAmount = netAmount + parseFloat(x.NetAmount);
    });
    EquipmentsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      netAmount = netAmount + parseFloat(x.NetAmount);
      if (parseFloat(x.TransportationCharges) > parseFloat(transportCharge)) {
        transportCharge = parseFloat(x.TransportationCharges);
      }
    });
    PackagesList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      netAmount = netAmount + parseFloat(x.NetAmount);
    });
    HealthCheckupsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      netAmount = netAmount + parseFloat(x.NetAmount);
    });
    PathologyReportsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      netAmount = netAmount + parseFloat(x.NetAmount);
    });
    RadiologyReportsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      netAmount = netAmount + parseFloat(x.NetAmount);
    });
    // if (DiscountAmount && parseFloat(DiscountAmount) > 0) {
    //   netAmount = netAmount - parseFloat(DiscountAmount);
    // }
    // if (AdvanceAmount && parseFloat(AdvanceAmount) > netAmount) {
    //   setFieldValue("AdvanceAmount", netAmount.toFixed(2));
    // }
    netAmount = netAmount + parseFloat(transportCharge);
    setFieldValue("TransportationCharges", transportCharge.toFixed(2));
    setFieldValue("NetAmount", netAmount.toFixed(2));
  };

  /* //Advance Calculation Based on Advance Slab Configuration

  const calcTotalAdvanceAmount = () => {
    let advanceAmount = 0,
      serviceEquipmentDiagnosticsNetAmount = 0,
      packageNetAmount = 0,
      healthCheckupNetAmount = 0;

    ServicesList?.map((x) => {
      serviceEquipmentDiagnosticsNetAmount =
        serviceEquipmentDiagnosticsNetAmount + parseFloat(x.NetAmount);
    });
    EquipmentsList?.map((x) => {
      serviceEquipmentDiagnosticsNetAmount =
        serviceEquipmentDiagnosticsNetAmount + parseFloat(x.NetAmount);
    });
    PathologyReportsList?.map((x) => {
      serviceEquipmentDiagnosticsNetAmount =
        serviceEquipmentDiagnosticsNetAmount + parseFloat(x.NetAmount);
    });
    RadiologyReportsList?.map((x) => {
      serviceEquipmentDiagnosticsNetAmount =
        serviceEquipmentDiagnosticsNetAmount + parseFloat(x.NetAmount);
    });
    PackagesList?.map((x) => {
      packageNetAmount = packageNetAmount + parseFloat(x.NetAmount);
    });
    HealthCheckupsList?.map((x) => {
      healthCheckupNetAmount = healthCheckupNetAmount + parseFloat(x.NetAmount);
    });

    
    if (
      serviceEquipmentDiagnosticsNetAmount > 0 &&
      advanceSlabDetail?.ServiceEquipmentDiagnostics?.length > 0
    ) {
      let advancePercentage =
        advanceSlabDetail?.ServiceEquipmentDiagnostics?.filter(
          (x) =>
            serviceEquipmentDiagnosticsNetAmount >= x.AdvanceSlabStartAmount &&
            serviceEquipmentDiagnosticsNetAmount <= x.AdvanceSlabEndAmount
        )[0]?.AdvancePercentage;
      if (advancePercentage) {
        advancePercentage = parseFloat(advancePercentage);
      } else {
        advancePercentage =
          advanceSlabDetail?.ServiceEquipmentDiagnostics?.sort((a, b) =>
            a.AdvanceSlabEndAmount < b.AdvanceSlabEndAmount ? 1 : -1
          )[0].AdvancePercentage;
        advancePercentage = parseFloat(advancePercentage);
      }
      advanceAmount =
        advanceAmount +
        (parseFloat(serviceEquipmentDiagnosticsNetAmount) *
          parseFloat(advancePercentage)) /
          100;
    }

    if (packageNetAmount > 0 && advanceSlabDetail?.Package?.length > 0) {
      let advancePercentage = advanceSlabDetail?.Package?.filter(
        (x) =>
          packageNetAmount >= x.AdvanceSlabStartAmount &&
          packageNetAmount <= x.AdvanceSlabEndAmount
      )[0]?.AdvancePercentage;
      if (advancePercentage) {
        advancePercentage = parseFloat(advancePercentage);
      } else {
        advancePercentage = advanceSlabDetail?.Package?.sort((a, b) =>
          a.AdvanceSlabEndAmount < b.AdvanceSlabEndAmount ? 1 : -1
        )[0].AdvancePercentage;
        advancePercentage = parseFloat(advancePercentage);
      }
      advanceAmount =
        advanceAmount +
        (parseFloat(packageNetAmount) * parseFloat(advancePercentage)) / 100;
    }

    if (
      healthCheckupNetAmount > 0 &&
      advanceSlabDetail?.HealthCheckup?.length > 0
    ) {
      let advancePercentage = advanceSlabDetail?.HealthCheckup?.filter(
        (x) =>
          healthCheckupNetAmount >= x.AdvanceSlabStartAmount &&
          healthCheckupNetAmount <= x.AdvanceSlabEndAmount
      )[0]?.AdvancePercentage;
      if (advancePercentage) {
        advancePercentage = parseFloat(advancePercentage);
      } else {
        advancePercentage = advanceSlabDetail?.HealthCheckup?.sort((a, b) =>
          a.AdvanceSlabEndAmount < b.AdvanceSlabEndAmount ? 1 : -1
        )[0].AdvancePercentage;
        advancePercentage = parseFloat(advancePercentage);
      }
      advanceAmount =
        advanceAmount +
        (parseFloat(healthCheckupNetAmount) * parseFloat(advancePercentage)) /
          100;
    }
    
    // if (
    //   advanceAmount >
    //   serviceEquipmentDiagnosticsNetAmount +
    //     packageNetAmount +
    //     healthCheckupNetAmount -
    //     (DiscountAmount && parseFloat(DiscountAmount) > 0
    //       ? parseFloat(DiscountAmount)
    //       : 0)
    // ) {
    //   advanceAmount =
    //     serviceEquipmentDiagnosticsNetAmount +
    //     packageNetAmount +
    //     healthCheckupNetAmount -
    //     (DiscountAmount && parseFloat(DiscountAmount) > 0
    //       ? parseFloat(DiscountAmount)
    //       : 0);
    // }

    setFieldValue("ActualTotalAdvanceAmount", advanceAmount.toFixed(2));
    setFieldValue("AdvanceAmount", advanceAmount.toFixed(2));
  };
  */

  const calcTotalAdvanceAmount = () => {
    let advanceAmount = 0;
    let transportCharge = 0;
    ServicesList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      advanceAmount =
        advanceAmount +
        parseFloat(x.StaffAdvanceAmount) +
        parseFloat(x.RotationalAdvanceAmount);
    });
    EquipmentsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      advanceAmount = advanceAmount + parseFloat(x.AdvanceAmount);
      if (parseFloat(x.TransportationCharges) > parseFloat(transportCharge)) {
        transportCharge = parseFloat(x.TransportationCharges);
      }
    });
    PackagesList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      advanceAmount = advanceAmount + parseFloat(x.AdvanceAmount);
    });
    HealthCheckupsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      advanceAmount = advanceAmount + parseFloat(x.AdvanceAmount);
    });
    PathologyReportsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      advanceAmount = advanceAmount + parseFloat(x.AdvanceAmount);
    });
    RadiologyReportsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
      advanceAmount = advanceAmount + parseFloat(x.AdvanceAmount);
    });
    advanceAmount = parseFloat(advanceAmount) + parseFloat(transportCharge);
    //setFieldValue("ActualTotalAdvanceAmount", advanceAmount.toFixed(2));
    setFieldValue("AdvanceAmount", advanceAmount.toFixed(2));
  };

  const calcTotalDepositAmount = () => {
    let depositAmount = 0;
    if (!OrderBookingIDEncrypt) {
      EquipmentsList?.filter((x) => x.IsDeleted == false)?.map((x) => {
        depositAmount =
          depositAmount + parseFloat(x.EquipmentTotalDepositAmount);
      });
    } else {
      EquipmentsList?.map((x) => {
        depositAmount =
          depositAmount + parseFloat(x.EquipmentTotalDepositAmount);
      });
    }
    setFieldValue("DepositAmount", depositAmount.toFixed(2));
  };

  const CalcAllTotalAmounts = () => {
    calcTotalGrossAmount();
    calcTotalTaxAmount();
    calcTotalNetAmount();
    calcTotalDepositAmount();
    if (!OrderBookingIDEncrypt) {
      calcTotalAdvanceAmount();
    }
  };

  useEffect(() => {
    CalcAllTotalAmounts();
  }, [
    ServicesList,
    EquipmentsList,
    PackagesList,
    HealthCheckupsList,
    PathologyReportsList,
    RadiologyReportsList,
  ]);

  // useEffect(() => {
  //   calcTotalNetAmount();
  //   calcTotalAdvanceAmount();
  // }, [DiscountAmount]);

  const handleChangeFullPay = (e) => {
    setFieldValue("IsFullPay", e.target.checked);
    if (e.target.checked) {
      setFieldValue(
        "ServicesList",
        ServicesList?.map((x) => {
          return {
            ...x,
            StaffAdvanceAmount: (
              parseFloat(x.StaffServiceRate) * parseInt(x.BookingDays)
            ).toString(),
            RotationalAdvanceAmount: (
              parseFloat(x.RotationalStaffServiceRate) * parseInt(x.BookingDays)
            ).toString(),
          };
        })
      );
      setFieldValue(
        "EquipmentsList",
        EquipmentsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.NetAmount.toString(),
          };
        })
      );
      setFieldValue(
        "PackagesList",
        PackagesList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.NetAmount.toString(),
          };
        })
      );
      setFieldValue(
        "HealthCheckupsList",
        HealthCheckupsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.NetAmount.toString(),
          };
        })
      );
      setFieldValue(
        "PathologyReportsList",
        PathologyReportsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.NetAmount.toString(),
          };
        })
      );
      setFieldValue(
        "RadiologyReportsList",
        RadiologyReportsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.NetAmount.toString(),
          };
        })
      );
    } else {
      setFieldValue(
        "ServicesList",
        ServicesList?.map((x) => {
          return {
            ...x,
            StaffAdvanceAmount: x.StaffActualAdvanceAmount.toString(),
            RotationalAdvanceAmount: x.RotationalActualAdvanceAmount.toString(),
          };
        })
      );
      setFieldValue(
        "EquipmentsList",
        EquipmentsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.EquipmentActualAdvanceAmount.toString(),
          };
        })
      );
      setFieldValue(
        "PackagesList",
        PackagesList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.ActualAdvanceAmount.toString(),
          };
        })
      );
      setFieldValue(
        "HealthCheckupsList",
        HealthCheckupsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.ActualAdvanceAmount.toString(),
          };
        })
      );
      setFieldValue(
        "PathologyReportsList",
        PathologyReportsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.ActualAdvanceAmount.toString(),
          };
        })
      );
      setFieldValue(
        "RadiologyReportsList",
        RadiologyReportsList?.map((x) => {
          return {
            ...x,
            AdvanceAmount: x.ActualAdvanceAmount.toString(),
          };
        })
      );
    }
  };

  const loadModeOfPaymentOptionsDDL = async () => {
    await post(POST_GetModeOfPaymentDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setModeOfPaymentOptions(
            res.ModeOfPaymentList.map((item) => ({
              label: item.ModeOfPayment,
              value: item.ModeOfPaymentID,
              isCheque: item.IsCheque,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadAllStaffDDL = async () => {
    await post(POST_AdminStaffsListDDLByCompanyID, {
      CompanyIDEncrypt: CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setAllStaffOptions(
            res.StaffsList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const uploadPDCFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /(\.|\/)(jpe?g|png|webp|pdf|svg|gif|ico)$/;
      if (!file.type.match(pattern)) {
        setPDCFile(null);
        setFileKeyPDC(Math.random().toString(9).substring(2, 9));
        swal("", "Select valid file format (PDF/Image)", "warning");
      } else {
        setPDCFile(file);
        setFileKeyPDC(Math.random().toString(9).substring(2, 9));
        setValidationPDCFile("");
      }
    } else {
      setPDCFile(null);
      setFileKeyPDC(Math.random().toString(9).substring(2, 9));
    }
  };

  const getSLAByOrderBookingID = async (OrderBookingID) => {
    setIsLoading(true);
    await post(POST_GetSLAbyOrderBookingID, {
      OrderBookingID: OrderBookingID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setIsLoading(false);
          window.open(response.FilePath, "_blank");
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadDocuumentsDDL = async () => {
    await post(POST_GetDocumentsDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setDocumentOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const schemaPatientDocuments = Yup.object().shape({
    DocumentID: Yup.string().required("Document is a required field"),
    DocumentRemarks: Yup.string()
      .trim()
      .required("Remarks is a required field"),
  });

  const formikPatientDocuments = useFormik({
    initialValues: {
      DocumentID: "",
      DocumentRemarks: "",
    },
    validationSchema: schemaPatientDocuments,
    onSubmit: (values, formikHelpers) =>
      handleSubmitPatientDocuments(values, formikHelpers),
    validateOnBlur: false,
  });

  // const {  } = formikPatientDocuments;

  const uploadDocument = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /(\.|\/)(jpe?g|png|webp|pdf|svg|ico)$/;

      var validPdfSize = true;
      var validFileFormat = true;

      if (!file.type.match(pattern)) {
        validFileFormat = false;
        setDocumentFile(null);
        setFileKeyDocument(Math.random().toString(9).substring(2, 9));
        swal({
          title: "Error",
          text: "Invalid file formate",
          icon: "error",
          button: "OK",
        });
      } else {
        if (file.type.match(/(\.|\/)(pdf)$/)) {
          if (Math.round(file.size / 1024 / 1024) > 1) {
            validPdfSize = false;
          }
        }
      }
      if (validFileFormat && validPdfSize) {
        setDocumentFile(file);
        setFileKeyDocument(Math.random().toString(9).substring(2, 9));
      } else {
        e.target.value = [];
        if (!validPdfSize) {
          swal({
            title: "Error",
            text: "Pdf file should not be more than 1 MB",
            icon: "error",
            button: "OK",
          });
          setDocumentFile(null);
          setFileKeyDocument(Math.random().toString(9).substring(2, 9));
        }
      }
    } else {
      setDocumentFile(null);
      setFileKeyDocument(Math.random().toString(9).substring(2, 9));
    }
  };

  const resetDocumentUploadForm = () => {
    formikPatientDocuments.resetForm();
    setDocumentFile(null);
    setFileKeyDocument(Math.random().toString(9).substring(2, 9));
  };

  const handleSubmitPatientDocuments = async (values) => {
    if (documentFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("EncryptOrderBookingID", OrderBookingIDEncrypt);
      formData.append("DocumentID", values.DocumentID);
      formData.append("Remarks", values.DocumentRemarks);
      formData.append("DocumentFilePath", documentFile);

      await postFormData(POST_OrderBookingPatientDocumentInsert, formData)
        .then((response) => {
          if (response.IsSuccess) {
            setPatientDocumentList([...response.PatientDocumentsList]);
            setDocumentModal(false);
            swal("Success!", t(response.MessageCode), "success");
            setIsLoading(false);
            resetDocumentUploadForm();
          } else {
            swal("Error!", t(response.MessageCode), "error");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  // const getPatientDocumentList = async () => {
  //   setIsLoading(true);
  //   await post(POST_OrderBookingPatientDocumentsList, {
  //     OrderBookingID: OrderBookingIDEncrypt,
  //   })
  //     .then((response) => {
  //       if (response.IsSuccess) {
  //         setPatientDocumentList([...response.PatientDocumentsList]);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // };

  const delete_PatientDocument = (DocumentID, OrderBookingID) => {
    swal({
      title: "Are you sure you want to delete the patient document?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_PatientDocumentConfirm(DocumentID, OrderBookingID);
      }
    });
  };

  const delete_PatientDocumentConfirm = async (DocumentID, OrderBookingID) => {
    setIsLoading(true);
    await post(POST_OrderBookingPatientDocumentDelete, {
      OrderBookingPatientDocumentIDEncrypted: DocumentID,
      OrderBookingIDEncrypted: OrderBookingID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setPatientDocumentList([...response.PatientDocumentsList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={OrderBookingIDParam ? "Edit Order" : "Add Order"}
          motherMenu={<Link to="/order-booking-list">Order Booking</Link>}
        />

        <div className="custom-tab-1">
          <Tab.Container activeKey={tab}>
            <Nav as="ul" className="nav-tabs">
              <Nav.Item as="li" key={1}>
                <Nav.Link eventKey={1} onClick={() => setTab(1)}>
                  {"Order Details"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={2}>
                <Nav.Link
                  eventKey={2}
                  disabled={OrderBookingIDEncrypt ? false : true}
                  onClick={() => setTab(2)}
                >
                  {"Patient Documents"}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey={1} key={1}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="basic-form">
                          <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                              <Row className="mb-4">
                                {OrderBookingIDParam ? (
                                  <Col lg={12}>
                                    <InputGroup>
                                      <h6>Order No:</h6> &nbsp;&nbsp;{" "}
                                      {OrderBookingNo}
                                      <span className="mr-5"></span>
                                      <h6>Order Date:</h6> &nbsp;&nbsp;
                                      {OrderBookingDate}
                                    </InputGroup>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                <Col lg={12}>
                                  <InputGroup>
                                    <h6>Patient No:</h6> &nbsp;&nbsp;{" "}
                                    {PatientNo}
                                    <span className="mr-5"></span>
                                    <h6>Patient Name:</h6> &nbsp;&nbsp;
                                    {/* <Link
                                     to={`/edit-patient/${window.btoa(
                                      PatientIDEncrypted
                                    )}`}
                                    style={{ color: "#36C95F" }}
                                  > */}
                                    {PatientName}
                                    {/* </Link> */}
                                    <span className="mr-5"></span>
                                    <h6>Mobile No. :</h6> &nbsp;&nbsp;{" "}
                                    {MobileNo}
                                  </InputGroup>
                                </Col>
                              </Row>
                              <Row>
                                {/* <Col lg={3}>
                                <Field
                                  label={"Patient No."}
                                  name="PatientNo"
                                  placeholder={"Enter Patient No."}
                                  component={CustomInput}
                                  readOnly
                                />
                              </Col> */}
                                {/* <Col lg={3}>
                                <Field
                                  label={"Patient Name"}
                                  name="PatientName"
                                  placeholder={"Enter Patient Name"}
                                  component={CustomInput}
                                  readOnly
                                />
                              </Col> */}
                                <Col lg={6}>
                                  <Field
                                    label={"Patient Address"}
                                    name="PatientAddressIDEncrypt"
                                    options={patientAddresssOptions}
                                    component={CustomSelect}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "PatientAddressIDEncrypt",
                                        e?.value
                                      );
                                      setPatientAddressLatitude(
                                        e?.patientAddressLatitude
                                      );
                                      setPatientAddressLongitude(
                                        e?.patientAddressLongitude
                                      );
                                    }}
                                    required
                                    isDisabled={
                                      OrderBookingIDEncrypt
                                        ? true
                                        : ServicesList?.filter(
                                            (x) => x.IsDeleted == false
                                          )?.length == 0
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                                {/* <Col lg={3}>
                                <Field
                                  label={"Order Booking Date"}
                                  name="OrderBookingDate"
                                  placeholder={"Select Order Booking Date"}
                                  component={CustomFlatpickr}
                                  required
                                />
                              </Col> */}
                                {/* <Col lg={3}>
                                <Field
                                  label={"Patient Mobile No."}
                                  name="PatientMobile"
                                  placeholder={"Enter Patient Mobile No."}
                                  component={CustomPhoneInput}
                                  readOnly
                                />
                              </Col> */}
                                <Col
                                  style={{
                                    textAlign: "end",
                                  }}
                                  lg={6}
                                >
                                  {OrderBookingIDEncrypt ? (
                                    ""
                                  ) : (
                                    <>
                                      <div
                                        onClick={() => {
                                          setPatientAddressModal(true);
                                        }}
                                        className="btn btn-primary btn-xxs btn-rounded mt-4"
                                      >
                                        + Add Address
                                      </div>
                                      &nbsp;&nbsp;
                                    </>
                                  )}
                                  <div
                                    onClick={() => {
                                      //setOrderHistoryModal(true);
                                      getOrderHistory(PatientIDEncrypt);
                                    }}
                                    className="btn btn-light btn-xxs btn-rounded mt-4"
                                  >
                                    Patient Order History
                                  </div>
                                  &nbsp;&nbsp;
                                  <div
                                    onClick={() => {
                                      setPatientDetailModal(true);
                                    }}
                                    className="btn btn-light btn-xxs btn-rounded mt-4"
                                  >
                                    Patient Detail
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    label={"Blood Pressure"}
                                    name="BloodPressure"
                                    component={CustomSelect}
                                    options={BloodPressureOption}
                                    required
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div
                                    className="custom-control custom-checkbox mb-3 checkbox-success"
                                    style={{ marginTop: "40px", zIndex: 0 }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="IsDiabetese"
                                      checked={IsDiabetese}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "IsDiabetese",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="IsDiabetese"
                                    >
                                      Is Diabetic
                                    </label>
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div
                                    className="custom-control custom-checkbox mb-3 checkbox-success"
                                    style={{ marginTop: "40px", zIndex: 0 }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="OnGoingTreatment"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "IsOnGoingTreatment",
                                          e.target.checked
                                        );
                                      }}
                                      checked={IsOnGoingTreatment}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="OnGoingTreatment"
                                    >
                                      On Going Treatment
                                    </label>
                                  </div>
                                </Col>
                              </Row>

                              {IsOnGoingTreatment ? (
                                <>
                                  <hr></hr>
                                  <h6 className="mb-3">On Going Treatment</h6>
                                  <Row>
                                    <Col lg={6}>
                                      <Row>
                                        <Col lg={12}>
                                          <Field
                                            label={"Hospital Name"}
                                            name="HospitalName"
                                            placeholder={"Enter Hospital Name"}
                                            component={CustomInput}
                                            maxLength={250}
                                            required={IsOnGoingTreatment}
                                          />
                                        </Col>
                                        <Col lg={12}>
                                          <Field
                                            label={"Doctor Name"}
                                            name="DoctorName"
                                            placeholder={"Enter Doctor Name"}
                                            component={CustomInput}
                                            maxLength={100}
                                            required={IsOnGoingTreatment}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col lg={6}>
                                      <Field
                                        type="textarea"
                                        label={"On Going Treatment Information"}
                                        name="OnGoingTreatmentInformation"
                                        placeholder="Enter On Going Treatment Information"
                                        rows={5}
                                        maxLength={500}
                                        style={{ resize: "none" }}
                                        component={CustomInput}
                                        required={IsOnGoingTreatment}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ""
                              )}
                              <hr></hr>
                              <Row>
                                <Col lg={3}>
                                  <div
                                    className="custom-control custom-checkbox mb-3 checkbox-success"
                                    style={{ marginTop: "40px", zIndex: 0 }}
                                  >
                                    <input
                                      type="checkbox"
                                      defaultChecked
                                      className="custom-control-input"
                                      id="chkCoMorbitCondition"
                                      checked={IsCoMorbitCondition}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "IsCoMorbitCondition",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="chkCoMorbitCondition"
                                    >
                                      Is Co Morbit Condition
                                    </label>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <Field
                                    type="text"
                                    label={"Co Morbit Condition"}
                                    name="CoMorbitCondition"
                                    placeholder={"Enter Co Morbit Condition"}
                                    component={CustomInput}
                                    required={IsCoMorbitCondition}
                                    disabled={!IsCoMorbitCondition}
                                  />
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row className="mb-3">
                                <Col lg={6}>
                                  <Field
                                    type="textarea"
                                    label={"Patient Past History"}
                                    name="PastHistory"
                                    placeholder={"Enter Patient Past History"}
                                    component={CustomInput}
                                    rows={5}
                                    style={{ resize: "none" }}
                                    maxLength={500}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <div
                                    onClick={() => {
                                      setPatientPastHistoryModal(true);
                                    }}
                                    className="btn btn-light btn-xxs btn-rounded mt-4"
                                  >
                                    Show Patient Other Past History
                                  </div>
                                </Col>
                              </Row>
                              <hr></hr>
                              {OrderBookingIDEncrypt ? (
                                ""
                              ) : (
                                <Row className="mb-4">
                                  <Col>
                                    <div
                                      onClick={() => {
                                        formikService.resetForm();
                                        setServiceModal(true);
                                      }}
                                      className="btn btn-primary btn-xxs btn-rounded"
                                    >
                                      + Add Service
                                    </div>{" "}
                                    &nbsp;&nbsp;
                                    <div
                                      onClick={() => {
                                        formikEquipments.resetForm();
                                        setEquipmentModal(true);
                                      }}
                                      className="btn btn-primary btn-xxs btn-rounded"
                                    >
                                      + Add Equipment
                                    </div>{" "}
                                    &nbsp;&nbsp;
                                    <div
                                      onClick={() => {
                                        formikPackages.resetForm();
                                        setPackageModal(true);
                                      }}
                                      className="btn btn-primary btn-xxs btn-rounded"
                                    >
                                      + Add Package
                                    </div>{" "}
                                    &nbsp;&nbsp;
                                    <div
                                      onClick={() => {
                                        formikHealthCheckup.resetForm();
                                        setHealthCheckupModal(true);
                                      }}
                                      className="btn btn-primary btn-xxs btn-rounded"
                                    >
                                      + Add Health Checkup
                                    </div>{" "}
                                    &nbsp;&nbsp;
                                    <div
                                      onClick={() => {
                                        formikPathologyReport.resetForm();
                                        setPathologyReportModal(true);
                                      }}
                                      className="btn btn-primary btn-xxs btn-rounded"
                                    >
                                      + Add Pathology Report
                                    </div>{" "}
                                    &nbsp;&nbsp;
                                    <div
                                      onClick={() => {
                                        formikRadiologyReport.resetForm();
                                        setRadiologyReportModal(true);
                                      }}
                                      className="btn btn-primary btn-xxs btn-rounded"
                                    >
                                      + Add Radiology Report
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              {ServicesList?.filter((x) => x.IsDeleted == false)
                                ?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <div className="table-responsive ">
                                      <div className="dataTables_wrapper no-footer">
                                        <table className="table verticle-middle table-responsive-md mb-3">
                                          <thead className="thead-light">
                                            <tr role="row">
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Service Name: activate to sort column ascending"
                                                style={{
                                                  width: "21%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Service Name
                                              </th>
                                              {/* <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Quantity: activate to sort column ascending"
                                            style={{
                                              width: "10%",
                                              padding: "10px 10px",
                                            }}
                                          >
                                            Quantity
                                          </th>{" "} */}
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Quantity: activate to sort column ascending"
                                                style={{
                                                  width: "20%",
                                                  padding: "10px 30px",
                                                }}
                                              >
                                                Staff Name
                                              </th>{" "}
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="From Date: activate to sort column ascending"
                                                style={{
                                                  width: "19%",
                                                  padding: "10px 30px",
                                                }}
                                              >
                                                From Date
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Slots: activate to sort column ascending"
                                                style={{
                                                  width: "17%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Slots
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Booking Days: activate to sort column ascending"
                                                style={{
                                                  width: "5%",
                                                  padding: "10px 20px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                Days
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Amount: activate to sort column ascending"
                                                style={{
                                                  width: "10%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Actions: activate to sort column ascending"
                                                style={{
                                                  width: "8%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {ServicesList?.filter(
                                              (x) => x.IsDeleted == false
                                            )?.length > 0 ? (
                                              ServicesList?.map(
                                                (service, index) => (
                                                  <tr
                                                    role="row"
                                                    className="odd"
                                                    style={{
                                                      display: service.IsDeleted
                                                        ? "none"
                                                        : "",
                                                      backgroundColor:
                                                        service.IsClosed
                                                          ? "#fff1f1"
                                                          : "",
                                                    }}
                                                  >
                                                    <td>
                                                      {service.ServiceName}
                                                    </td>
                                                    <td>
                                                      {/* <Link
                                                          to="/staff-details"
                                                          style={{ color: "#36C95F" }}
                                                        >
                                                        {service.StaffName}
                                                        </Link> */}
                                                      {service.StaffName}
                                                      {service.RotationalStaffName
                                                        ? " - " +
                                                          service.RotationalStaffName
                                                        : ""}
                                                    </td>
                                                    <td>{service.FromDate}</td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      className="pl-0 pr-0"
                                                    >
                                                      {service.ServiceHours}
                                                      {service.ServiceHours ? (
                                                        <br></br>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <span
                                                        style={{
                                                          fontSize: "9px",
                                                        }}
                                                      >
                                                        {"(" +
                                                          service.ServiceScheduleStartTime +
                                                          " - " +
                                                          service.ServiceScheduleEndTime +
                                                          ")"}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {service.BookingDays}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {service.NetAmount}
                                                    </td>
                                                    <td>
                                                      {service.IsClosed ? (
                                                        "Closed"
                                                      ) : (
                                                        <div className="d-flex">
                                                          {OrderBookingIDEncrypt ? (
                                                            <Link
                                                              to="#"
                                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                                              title="Extend"
                                                              onClick={(e) => {
                                                                formikServiceExtend.setFieldValue(
                                                                  "OrderBookingServiceIDEncrypt",
                                                                  service.OrderBookingServiceIDEncrypt
                                                                );
                                                                formikServiceExtend.setValues(
                                                                  {
                                                                    OrderBookingServiceIDEncrypt:
                                                                      service.OrderBookingServiceIDEncrypt,
                                                                    ExtendServiceName:
                                                                      service.ServiceName,
                                                                    ServiceScheduleExtendFromDate:
                                                                      new Date(
                                                                        service.ServiceScheduleStartDate
                                                                      ),
                                                                    ServiceScheduleActualToDate:
                                                                      new Date(
                                                                        service.ServiceScheduleActualEndDate
                                                                      ),
                                                                    ServiceScheduleExtendToDate:
                                                                      new Date(
                                                                        service.ServiceScheduleEndDate
                                                                      ),
                                                                  }
                                                                );
                                                                setServiceExtendModal(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <i
                                                                className="fa fa-expand"
                                                                style={{
                                                                  color:
                                                                    "green",
                                                                }}
                                                              ></i>
                                                            </Link>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {service?.IsOngoing ? (
                                                            <Link
                                                              to="#"
                                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                                              title="Close"
                                                              style={{
                                                                display:
                                                                  moment(
                                                                    service?.ServiceScheduleActualEndDate
                                                                  ).format(
                                                                    "DD-MMM-YYYY"
                                                                  ) ==
                                                                  moment(
                                                                    service?.ServiceScheduleEndDate
                                                                  ).format(
                                                                    "DD-MMM-YYYY"
                                                                  )
                                                                    ? ""
                                                                    : "none",
                                                              }}
                                                              onClick={(e) => {
                                                                formikServiceForceClose.setFieldValue(
                                                                  "OrderBookingServiceIDEncrypt",
                                                                  service.OrderBookingServiceIDEncrypt
                                                                );
                                                                formikServiceForceClose.setFieldValue(
                                                                  "ForceCloseServiceName",
                                                                  service.ServiceName
                                                                );
                                                                setServiceForceCloseModal(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <i
                                                                className="fa fa-ban"
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              ></i>
                                                            </Link>
                                                          ) : (
                                                            <Link
                                                              to="#"
                                                              className="btn btn-light shadow btn-xs sharp"
                                                              title="Delete"
                                                              onClick={(e) => {
                                                                delete_Service(
                                                                  service.OrderBookingServiceIDEncrypt,
                                                                  index,
                                                                  service.IsOngoing
                                                                );
                                                              }}
                                                            >
                                                              <i
                                                                className="fa fa-trash"
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              ></i>
                                                            </Link>
                                                          )}
                                                        </div>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={7}>
                                                  <div className="mt-1 ml-1 mb-3">
                                                    {"No Record Found"}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {EquipmentsList?.filter(
                                (x) => x.IsDeleted == false
                              )?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <div className="table-responsive ">
                                      <div className="dataTables_wrapper no-footer">
                                        <table className="table verticle-middle table-responsive-md mb-3">
                                          <thead className="thead-light">
                                            <tr role="row">
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Equipment Name: activate to sort column ascending"
                                                style={{
                                                  width: "20%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Equipment Name
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Equipment Type: activate to sort column ascending"
                                                style={{
                                                  width: "20%",
                                                  padding: "10px 10px",
                                                }}
                                              >
                                                Equipment Type
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="FromDate: activate to sort column ascending"
                                                style={{
                                                  width: "16%",
                                                  padding: "10px 30px",
                                                }}
                                              >
                                                From Date
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Qty: activate to sort column ascending"
                                                style={{
                                                  width: "9%",
                                                  padding: "10px 20px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                Qty
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Booking Days: activate to sort column ascending"
                                                style={{
                                                  width: "10%",
                                                  padding: "10px 20px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {/* Booking */} Days
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Amount: activate to sort column ascending"
                                                style={{
                                                  width: "15%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Actions: activate to sort column ascending"
                                                style={{
                                                  width: "10%",
                                                  padding: "10px 10px",
                                                }}
                                              >
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {EquipmentsList?.filter(
                                              (x) => x.IsDeleted == false
                                            )?.length > 0 ? (
                                              EquipmentsList?.map(
                                                (equipment, index) => (
                                                  <tr
                                                    role="row"
                                                    className="odd"
                                                    style={{
                                                      display:
                                                        equipment.IsDeleted
                                                          ? "none"
                                                          : "",
                                                      backgroundColor:
                                                        equipment.IsClosed
                                                          ? "#fff1f1"
                                                          : "",
                                                    }}
                                                  >
                                                    <td>
                                                      {equipment.EquipmentName}
                                                    </td>
                                                    <td>
                                                      {
                                                        equipment.EquipmentTypeName
                                                      }
                                                    </td>
                                                    <td>
                                                      {moment(
                                                        equipment.EquipmentRequiredOnDate
                                                      ).format("DD-MMM-YYYY")}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {equipment.EquipmentQty}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {equipment.BookingDays}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {equipment.NetAmount}
                                                    </td>
                                                    <td>
                                                      {equipment.IsClosed ? (
                                                        "Closed"
                                                      ) : (
                                                        <div className="d-flex">
                                                          {OrderBookingIDEncrypt ? (
                                                            <Link
                                                              to="#"
                                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                                              title="Extend"
                                                              onClick={(e) => {
                                                                formikEquipmentExtend.setValues(
                                                                  {
                                                                    OrderBookingEquipmentIDEncrypt:
                                                                      equipment.OrderBookingEquipmentIDEncrypt,
                                                                    ExtendEquipmentName:
                                                                      equipment.EquipmentName,
                                                                    EquipmentExtendRequiredFromDate:
                                                                      new Date(
                                                                        equipment.EquipmentRequiredOnDate
                                                                      ),
                                                                    EquipmentExtendRequiredToDate:
                                                                      new Date(
                                                                        equipment.EquipmentRequiredEndDate
                                                                      ),
                                                                    EquipmentActualRequiredToDate:
                                                                      new Date(
                                                                        equipment.EquipmentRequiredActualEndDate
                                                                      ),
                                                                  }
                                                                );
                                                                setEquipmentExtendModal(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <i
                                                                className="fa fa-expand"
                                                                style={{
                                                                  color:
                                                                    "green",
                                                                }}
                                                              ></i>
                                                            </Link>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {equipment?.IsOngoing ? (
                                                            <Link
                                                              to="#"
                                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                                              title="Close"
                                                              style={{
                                                                display:
                                                                  moment(
                                                                    equipment?.EquipmentRequiredActualEndDate
                                                                  ).format(
                                                                    "DD-MMM-YYYY"
                                                                  ) ==
                                                                  moment(
                                                                    equipment?.EquipmentRequiredEndDate
                                                                  ).format(
                                                                    "DD-MMM-YYYY"
                                                                  )
                                                                    ? ""
                                                                    : "none",
                                                              }}
                                                              onClick={(e) => {
                                                                formikEquipmentForceClose.resetForm();
                                                                formikEquipmentForceClose.setFieldValue(
                                                                  "OrderBookingEquipmentIDEncrypt",
                                                                  equipment.OrderBookingEquipmentIDEncrypt
                                                                );
                                                                formikEquipmentForceClose.setFieldValue(
                                                                  "ForceCloseEquipmentName",
                                                                  equipment.EquipmentName
                                                                );
                                                                formikEquipmentForceClose.setFieldValue(
                                                                  "EquipmentActualEndDate",
                                                                  new Date(
                                                                    equipment.EquipmentRequiredActualEndDate
                                                                  )
                                                                );
                                                                formikEquipmentForceClose.setFieldValue(
                                                                  "EquipmentStartDate",
                                                                  new Date(
                                                                    equipment.EquipmentRequiredOnDate
                                                                  )
                                                                );
                                                                setEquipmentForceCloseModal(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <i
                                                                className="fa fa-ban"
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              ></i>
                                                            </Link>
                                                          ) : (
                                                            <Link
                                                              to="#"
                                                              className="btn btn-light shadow btn-xs sharp"
                                                              title="Delete"
                                                              onClick={(e) => {
                                                                delete_Equipment(
                                                                  equipment.OrderBookingEquipmentIDEncrypt,
                                                                  index,
                                                                  equipment.IsOngoing
                                                                );
                                                              }}
                                                            >
                                                              <i
                                                                className="fa fa-trash"
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              ></i>
                                                            </Link>
                                                          )}
                                                        </div>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="mt-1 ml-1 mb-3">
                                                    {"No Record Found"}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {PackagesList?.filter((x) => x.IsDeleted == false)
                                ?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <div className="table-responsive ">
                                      <div className="dataTables_wrapper no-footer">
                                        <table className="table verticle-middle table-responsive-md mb-3">
                                          <thead className="thead-light">
                                            <tr role="row">
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Package Name: activate to sort column ascending"
                                                style={{
                                                  width: "60%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Package Name
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Duration: activate to sort column ascending"
                                                style={{
                                                  width: "15%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Duration
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Amount: activate to sort column ascending"
                                                style={{
                                                  width: "15%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Actions: activate to sort column ascending"
                                                style={{
                                                  width: "10%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {PackagesList?.filter(
                                              (x) => x.IsDeleted == false
                                            )?.length > 0 ? (
                                              PackagesList?.map(
                                                (pkg, index) => (
                                                  <tr
                                                    role="row"
                                                    className="odd"
                                                    style={{
                                                      display: pkg.IsDeleted
                                                        ? "none"
                                                        : "",
                                                    }}
                                                  >
                                                    <td>{pkg.PackageName}</td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {pkg.PackageDuration} days
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {pkg.NetAmount}
                                                    </td>
                                                    <td>
                                                      <div className="d-flex">
                                                        {/* <Link
                                                            to="#"
                                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                                            title="Edit"
                                                          >
                                                            <i className="fa fa-pencil"></i>
                                                          </Link> */}
                                                        <Link
                                                          to="#"
                                                          className="btn btn-light shadow btn-xs sharp"
                                                          title="Delete"
                                                          onClick={(e) => {
                                                            delete_Package(
                                                              pkg.OrderBookingPackageIDEncrypt,
                                                              index,
                                                              pkg.IsOngoing
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-trash"
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          ></i>
                                                        </Link>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={4}>
                                                  <div className="mt-1 ml-1 mb-3">
                                                    {"No Record Found"}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {HealthCheckupsList?.filter(
                                (x) => x.IsDeleted == false
                              )?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <div className="table-responsive ">
                                      <div className="dataTables_wrapper no-footer">
                                        <table className="table verticle-middle table-responsive-md mb-3">
                                          <thead className="thead-light">
                                            <tr role="row">
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Health Checkup: activate to sort column ascending"
                                                style={{
                                                  width: "75%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Health Checkup
                                              </th>

                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Amount: activate to sort column ascending"
                                                style={{
                                                  width: "15%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Actions: activate to sort column ascending"
                                                style={{
                                                  width: "10%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {HealthCheckupsList?.filter(
                                              (x) => x.IsDeleted == false
                                            )?.length > 0 ? (
                                              HealthCheckupsList?.map(
                                                (hc, index) => (
                                                  <tr
                                                    role="row"
                                                    className="odd"
                                                    style={{
                                                      display: hc.IsDeleted
                                                        ? "none"
                                                        : "",
                                                    }}
                                                  >
                                                    <td>
                                                      {hc.HealthCheckupName}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {hc.NetAmount}
                                                    </td>
                                                    <td>
                                                      <div className="d-flex">
                                                        {/* <Link
                                                            to="#"
                                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                                            title="Edit"
                                                          >
                                                            <i className="fa fa-pencil"></i>
                                                          </Link> */}
                                                        <Link
                                                          to="#"
                                                          className="btn btn-light shadow btn-xs sharp"
                                                          title="Delete"
                                                          onClick={(e) => {
                                                            delete_HealthCheckup(
                                                              hc.OrderBookingHealthCheckupIDEncrypt,
                                                              index,
                                                              hc.IsOngoing
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-trash"
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          ></i>
                                                        </Link>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={3}>
                                                  <div className="mt-1 ml-1 mb-3">
                                                    {"No Record Found"}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {PathologyReportsList?.filter(
                                (x) => x.IsDeleted == false
                              )?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <div className="table-responsive ">
                                      <div className="dataTables_wrapper no-footer">
                                        <table className="table verticle-middle table-responsive-md mb-3">
                                          <thead className="thead-light">
                                            <tr role="row">
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Health Checkup: activate to sort column ascending"
                                                style={{
                                                  width: "50%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Pathology Report
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="ScheduledOn: activate to sort column ascending"
                                                style={{
                                                  width: "25%",
                                                  padding: "10px 30px",
                                                }}
                                              >
                                                Scheduled On
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Amount: activate to sort column ascending"
                                                style={{
                                                  width: "15%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Actions: activate to sort column ascending"
                                                style={{
                                                  width: "10%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {PathologyReportsList?.filter(
                                              (x) => x.IsDeleted == false
                                            )?.length > 0 ? (
                                              PathologyReportsList?.map(
                                                (report, index) => (
                                                  <tr
                                                    role="row"
                                                    className="odd"
                                                    style={{
                                                      display: report.IsDeleted
                                                        ? "none"
                                                        : "",
                                                    }}
                                                  >
                                                    <td>
                                                      {
                                                        report.PathologyReportName
                                                      }
                                                    </td>
                                                    <td>
                                                      {report.DateOfPathologyReport +
                                                        " | " +
                                                        report.TimeOfPathologyReport}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {
                                                        report.PathologyReportPrice
                                                      }
                                                    </td>
                                                    <td>
                                                      <div className="d-flex">
                                                        {/* <Link
                                                            to="#"
                                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                                            title="Edit"
                                                          >
                                                            <i className="fa fa-pencil"></i>
                                                          </Link> */}
                                                        <Link
                                                          to="#"
                                                          className="btn btn-light shadow btn-xs sharp"
                                                          title="Delete"
                                                          onClick={(e) => {
                                                            delete_PathologyReport(
                                                              report.OrderBookingPathologyReportIDEncrypt,
                                                              index,
                                                              report.IsOngoing
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-trash"
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          ></i>
                                                        </Link>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={3}>
                                                  <div className="mt-1 ml-1 mb-3">
                                                    {"No Record Found"}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {RadiologyReportsList?.filter(
                                (x) => x.IsDeleted == false
                              )?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <div className="table-responsive ">
                                      <div className="dataTables_wrapper no-footer">
                                        <table className="table verticle-middle table-responsive-md mb-3">
                                          <thead className="thead-light">
                                            <tr role="row">
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Health Checkup: activate to sort column ascending"
                                                style={{
                                                  width: "50%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Radiology Report
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="ScheduledOn: activate to sort column ascending"
                                                style={{
                                                  width: "25%",
                                                  padding: "10px 30px",
                                                }}
                                              >
                                                Scheduled On
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Amount: activate to sort column ascending"
                                                style={{
                                                  width: "15%",
                                                  padding: "10px 40px",
                                                }}
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Actions: activate to sort column ascending"
                                                style={{
                                                  width: "10%",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {RadiologyReportsList?.filter(
                                              (x) => x.IsDeleted == false
                                            )?.length > 0 ? (
                                              RadiologyReportsList?.map(
                                                (report, index) => (
                                                  <tr
                                                    role="row"
                                                    className="odd"
                                                    style={{
                                                      display: report.IsDeleted
                                                        ? "none"
                                                        : "",
                                                    }}
                                                  >
                                                    <td>
                                                      {
                                                        report.RadiologyReportName
                                                      }
                                                    </td>
                                                    <td>
                                                      {report.DateOfRadiologyReport +
                                                        " | " +
                                                        report.TimeOfRadiologyReport}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {
                                                        report.RadiologyReportPrice
                                                      }
                                                    </td>
                                                    <td>
                                                      <div className="d-flex">
                                                        {/* <Link
                                                            to="#"
                                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                                            title="Edit"
                                                          >
                                                            <i className="fa fa-pencil"></i>
                                                          </Link> */}
                                                        <Link
                                                          to="#"
                                                          className="btn btn-light shadow btn-xs sharp"
                                                          title="Delete"
                                                          onClick={(e) => {
                                                            delete_RadiologyReport(
                                                              report.OrderBookingRadiologyReportIDEncrypt,
                                                              index,
                                                              report.IsOngoing
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-trash"
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          ></i>
                                                        </Link>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={3}>
                                                  <div className="mt-1 ml-1 mb-3">
                                                    {"No Record Found"}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              <hr></hr>
                              <Row>
                                <Col lg={3}>
                                  <Field
                                    type="number"
                                    label={"Gross Amount"}
                                    name="GrossAmount"
                                    placeholder={"Gross Amount"}
                                    component={CustomInput}
                                    min={0}
                                    readOnly
                                  />
                                </Col>
                                {/* <Col lg={3}>
                                  <Field
                                    type="number"
                                    label={"Discount Amount"}
                                    name="DiscountAmount"
                                    placeholder={"Discount Amount"}
                                    component={CustomInput}
                                    min={0}
                                  />
                                </Col> */}
                                <Col lg={3}>
                                  <Field
                                    type="number"
                                    label={"Tax Amount"}
                                    name="TaxAmount"
                                    placeholder={"Tax Amount"}
                                    component={CustomInput}
                                    min={0}
                                    readOnly
                                  />
                                </Col>
                                {EquipmentsList?.filter(
                                  (x) => x.IsDeleted == false
                                )?.length > 0 ? (
                                  <Col lg={3}>
                                    <Field
                                      type="number"
                                      label={"Transportation Charges"}
                                      name="TransportationCharges"
                                      placeholder={"Transportation Charges"}
                                      component={CustomInput}
                                      min={0}
                                      readOnly
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                <Col lg={3}>
                                  <Field
                                    type="number"
                                    label={"Net Amount"}
                                    name="NetAmount"
                                    placeholder={"Net Amount"}
                                    min={0}
                                    component={CustomInput}
                                    readOnly
                                  />
                                </Col>
                              </Row>

                              <hr></hr>
                              <Row>
                                <Col lg={3}>
                                  {/* {ActualTotalAdvanceAmount &&
                                  parseFloat(ActualTotalAdvanceAmount) !==
                                    parseFloat(
                                      AdvanceAmount ? AdvanceAmount : 0
                                    ) ? (
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        marginTop: "5px",
                                        float: "right",
                                      }}
                                    >
                                      (Actual: {ActualTotalAdvanceAmount})
                                    </span>
                                  ) : (
                                    ""
                                  )} */}
                                  <span
                                    style={{
                                      float: "right",
                                    }}
                                  >
                                    <div
                                      className="custom-control custom-checkbox checkbox-success"
                                      style={{ zIndex: 0 }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="IsFullPay"
                                        checked={IsFullPay}
                                        onChange={(e) => {
                                          handleChangeFullPay(e);
                                        }}
                                        disabled={
                                          OrderBookingIDEncrypt ? true : false
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="IsFullPay"
                                      >
                                        Full Pay
                                      </label>
                                    </div>
                                  </span>
                                  <Field
                                    type="number"
                                    label={"Advance Amount"}
                                    name="AdvanceAmount"
                                    placeholder={"Enter Advance Amount"}
                                    min={0}
                                    component={CustomInput}
                                    readOnly
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    label={"Mode of Payment"}
                                    name="ModeOfPaymentID"
                                    placeholder={"Select Mode of Payment"}
                                    component={CustomSelect}
                                    options={modeOfPaymentOptions}
                                    required={
                                      parseFloat(AdvanceAmount) > 0
                                        ? true
                                        : false
                                    }
                                  />
                                </Col>
                                {/* <Col lg={3}>
                                  <Field
                                    label={"Payment Transaction ID"}
                                    name="PaymentTransactionID"
                                    placeholder={"Enter Payment Transaction ID"}
                                    component={CustomInput}
                                    maxLength={20}
                                  />
                                </Col> */}
                                <Col lg={6}>
                                  <Field
                                    label={"Advance Remarks"}
                                    name="AdvanceRemarks"
                                    placeholder={"Enter Advance Remarks"}
                                    component={CustomInput}
                                    maxLength={200}
                                  />
                                </Col>
                              </Row>
                              <hr></hr>
                              {EquipmentsList?.filter(
                                (x) => x.IsDeleted == false
                              )?.length > 0 ? (
                                <>
                                  <Row>
                                    <Col lg={3} className="mb-2">
                                      <Label>&nbsp;</Label>
                                      <div
                                        className="custom-control custom-checkbox mb-3 checkbox-success"
                                        style={{ zIndex: 0 }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="IsPDCReceived"
                                          checked={IsPDCReceived}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "IsPDCReceived",
                                              e.target.checked
                                            );
                                          }}
                                          disabled={
                                            OrderBookingIDEncrypt ? true : false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="IsPDCReceived"
                                        >
                                          Is Deposit Received
                                        </label>
                                      </div>
                                    </Col>
                                    <Col lg={3}>
                                      <Field
                                        type="number"
                                        label={"Equipment Deposit Amount"}
                                        name="DepositAmount"
                                        placeholder={"Deposite Amount"}
                                        min={0}
                                        component={CustomInput}
                                        readOnly
                                      />
                                    </Col>
                                    {IsPDCReceived ? (
                                      <>
                                        <Col lg={3}>
                                          <Field
                                            label={"Deposit Mode of Payment"}
                                            name="DepositModeOfPaymentID"
                                            placeholder={
                                              "Select Mode of Payment"
                                            }
                                            component={CustomSelect}
                                            options={modeOfPaymentOptions}
                                            required={
                                              parseFloat(DepositAmount) > 0
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              if (e) {
                                                setFieldValue(
                                                  "DepositModeOfPaymentID",
                                                  e?.value
                                                );
                                                setFieldValue(
                                                  "IsDepositInPDC",
                                                  e?.isCheque
                                                );
                                              } else {
                                                setFieldValue(
                                                  "DepositModeOfPaymentID",
                                                  ""
                                                );
                                                setFieldValue(
                                                  "IsDepositInPDC",
                                                  false
                                                );
                                              }
                                            }}
                                          />
                                        </Col>
                                        {/* <Col lg={3}>
                                          <Field
                                            label={
                                              "Deposit Payment Transaction ID"
                                            }
                                            name="DepositPaymentTransactionID"
                                            placeholder={
                                              "Enter Payment Transaction ID"
                                            }
                                            component={CustomInput}
                                            maxLength={20}
                                          />
                                        </Col> */}

                                        <Col lg={3}>
                                          <Field
                                            label={"Deposit Recieved By Staff"}
                                            name="PDCRecievedByStaffIDEncrypt"
                                            placeholder={
                                              "Deposit Recieved By Staff"
                                            }
                                            component={CustomSelect}
                                            options={allStaffOptions}
                                            required={
                                              IsPDCReceived ? true : false
                                            }
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <Field
                                            label={"Deposit Given By"}
                                            name="PDCGivenBy"
                                            placeholder={
                                              "Enter Deposit Given By Name"
                                            }
                                            component={CustomInput}
                                            required={
                                              IsPDCReceived ? true : false
                                            }
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <Field
                                            type="text"
                                            label={
                                              "Deposit Given By Mobile No."
                                            }
                                            name="PDCGivenByMobileNo"
                                            placeholder={"Enter Mobile No."}
                                            country={country}
                                            countryCode={PDCGivenByCountryCode}
                                            component={CustomPhoneInput}
                                            onChange={(
                                              value,
                                              { countryCode, dialCode }
                                            ) => {
                                              setFieldValue(
                                                "PDCGivenByCountryCode",
                                                dialCode
                                              );
                                              setFieldValue(
                                                "PDCGivenByMobileNo",
                                                value.substring(
                                                  dialCode.toString().length
                                                )
                                              );
                                            }}
                                            required={
                                              IsPDCReceived ? true : false
                                            }
                                          />
                                        </Col>
                                        <Col lg={6}>
                                          <Field
                                            label={"Deposit Remarks"}
                                            name="PDCRemarks"
                                            placeholder={
                                              "Enter Deposit Remarks"
                                            }
                                            component={CustomInput}
                                            maxLength={200}
                                          />
                                        </Col>
                                        {IsDepositInPDC ? (
                                          <Col lg={3}>
                                            {pdc ? (
                                              <span
                                                style={{
                                                  float: "right",
                                                  fontSize: "12px",
                                                  marginRight: "10px",
                                                  marginTop: "2px",
                                                  color: "#36C95F",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  window.open(pdc, "_blank");
                                                }}
                                              >
                                                <b>View PDC</b>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            <Label>
                                              Upload PDC{" "}
                                              {IsDepositInPDC ? (
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </Label>
                                            <div
                                              className="input-group mb-3"
                                              style={{ zIndex: "0" }}
                                            >
                                              <div className="custom-file">
                                                <input
                                                  type="file"
                                                  className="custom-file-input"
                                                  name="fileControlPDC"
                                                  onChange={(e) => {
                                                    uploadPDCFile(e);
                                                  }}
                                                  key={fileKeyPDC || ""}
                                                  required={
                                                    pdcFile ||
                                                    pdc ||
                                                    IsPDCReceived === false ||
                                                    IsDepositInPDC === false
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                <label
                                                  className="custom-file-label"
                                                  htmlFor="fileControlPDC"
                                                >
                                                  {pdcFile
                                                    ? pdcFile?.name
                                                    : "Choose file"}
                                                </label>
                                              </div>
                                            </div>
                                            <span
                                              style={{
                                                color: "#ec4561",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                              }}
                                            >
                                              {validationPDCFile}
                                            </span>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        {/* {pdc ? (
                                      <Col lg={3}>
                                        <Label>&nbsp;</Label>
                                        <div className="input-group mb-3">
                                          <Link
                                            className="btn btn-light shadow sharp mr-1"
                                            onClick={() => {
                                              window.open(pdc, "_blank");
                                            }}
                                          >
                                            View PDC
                                          </Link>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )} */}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Row>
                                  <hr></hr>
                                </>
                              ) : (
                                ""
                              )}
                              <button
                                type="submit"
                                className="btn mr-2 mt-2 btn-primary"
                              >
                                Submit
                              </button>
                              {OrderBookingIDEncrypt ? (
                                <button
                                  type="button"
                                  className="btn mr-2 mt-2 btn-primary"
                                  onClick={() => {
                                    setTab(2);
                                  }}
                                >
                                  Next
                                </button>
                              ) : (
                                ""
                              )}
                            </Form>
                          </FormikProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={2} key={2}>
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <div className="table-responsive ">
                        <div className="dataTables_wrapper no-footer">
                          <table
                            className="table verticle-middle table-responsive-md mb-3"
                            style={{ width: "85%" }}
                          >
                            <thead className="thead-light">
                              <tr role="row">
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Document Name: activate to sort column ascending"
                                  style={{ width: "40%", padding: "10px 30px" }}
                                >
                                  Document Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Remarks: activate to sort column ascending"
                                  style={{ width: "40%", padding: "10px 30px" }}
                                >
                                  Remarks
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Actions: activate to sort column ascending"
                                  style={{ width: "10%", padding: "10px 30px" }}
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {patientDocumentList?.length > 0 ? (
                                patientDocumentList?.map((item, index) => (
                                  <tr role="row">
                                    <td>{item.DocumentName}</td>
                                    <td>{item.Remarks}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="View"
                                          onClick={() => {
                                            window.open(
                                              item.DocumentFilePath,
                                              "_blank"
                                            );
                                          }}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                          onClick={() => {
                                            delete_PatientDocument(
                                              item.OrderBookingPatientDocumentIDEncrypt,
                                              item.OrderBookingIDEncrypt
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={3}>
                                    <div className="mt-2 mb-3">
                                      {"No Record Found"}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <Col lg={12} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-xs btn-rounded mb-2"
                          onClick={() => {
                            resetDocumentUploadForm();
                            setDocumentModal(true);
                          }}
                        >
                          + Add Document
                        </button>
                      </Col>
                    </Row>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary"
                      onClick={() => {
                        setTab(1);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="btn mr-2 btn-light"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <Modal
          className="modal fade"
          show={documentModal}
          onHide={setDocumentModal}
        >
          <div className="">
            <div className="">
              <FormikProvider value={formikPatientDocuments}>
                <Form onSubmit={formikPatientDocuments.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Patient Document</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setDocumentModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          <Col lg={12}>
                            <Field
                              name={"DocumentID"}
                              label={"Document"}
                              placeholder="Select Document"
                              component={CustomSelect}
                              options={documentOptions}
                              required
                            />
                          </Col>
                          <Col lg={12}>
                            <Field
                              type="textarea"
                              name={"DocumentRemarks"}
                              label={"Remarks"}
                              placeholder="Enter Document Remarks"
                              component={CustomInput}
                              rows={4}
                              maxLength={500}
                              style={{ resize: "none" }}
                              required
                            />
                          </Col>
                          <Col lg={12}>
                            <Label>
                              Upload Document
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="input-group mb-3">
                              <div
                                className="custom-file"
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type="file"
                                  id="fileControlDocument"
                                  name="fileControlDocument"
                                  className="custom-file-input"
                                  onChange={(e) => {
                                    uploadDocument(e);
                                  }}
                                  key={fileKeyDocument || ""}
                                  required={documentFile ? false : true}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="fileControlDocument"
                                >
                                  {documentFile
                                    ? documentFile?.name
                                    : "Choose file"}
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Upload
                    </button>
                    <button
                      type="button"
                      onClick={() => setDocumentModal(false)}
                      className="btn btn-sm btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal fade"
          show={serviceModal}
          onHide={setServiceModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Service</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setServiceModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <FormikProvider value={formikService}>
              <Form onSubmit={formikService.handleSubmit}>
                <div className="modal-body">
                  <div className="basic-form">
                    <Row>
                      <Col lg={4}>
                        <Field
                          label={"Service Type"}
                          name="ServiceTypeIDEncrypt"
                          options={optionServiceType}
                          placeholder={"Select Service Type"}
                          component={CustomSelect}
                          required
                          isDisabled={
                            OrderBookingServiceIDEncrypt ? true : false
                          }
                        />
                      </Col>
                      {ServiceTypeIDEncrypt &&
                      optionServiceSubType?.length === 0 ? (
                        ""
                      ) : (
                        <Col lg={4}>
                          <Field
                            label={"Service Sub Type"}
                            name="ServiceSubTypeIDEncrypt"
                            placeholder={"Select Sub Type"}
                            options={optionServiceSubType}
                            component={CustomSelect}
                            required
                            isDisabled={
                              OrderBookingServiceIDEncrypt ? true : false
                            }
                          />
                        </Col>
                      )}
                      <Col lg={4}>
                        <Field
                          label={"Service"}
                          name="ServiceIDEncrypt"
                          placeholder={"Select Service"}
                          component={CustomSelect}
                          options={serviceOptions}
                          required
                          isDisabled={
                            OrderBookingServiceIDEncrypt ? true : false
                          }
                        />
                      </Col>
                      {ChargeType === 1 ? (
                        <Col lg={4}>
                          <Field
                            label={"Service Hours"}
                            name="ServiceHourIDEncrypt"
                            placeholder={"Select Service Hours"}
                            options={serviceHoursOptions}
                            component={CustomSelect}
                            isDisabled={
                              OrderBookingServiceIDEncrypt ? true : false
                            }
                          />
                        </Col>
                      ) : (
                        <Col lg={4}>
                          <Field
                            label={"Visit Duration (Minutes)"}
                            name="VisitDuration"
                            component={CustomInput}
                            disabled={true}
                          />
                        </Col>
                      )}
                      {/* <Col lg={4}>
                      <Field
                        type="number"
                        label={"Service Rate"}
                        name="ServiceRate"
                        placeholder={"Service Rate"}
                        min={0}
                        component={CustomInput}
                        // required
                      />
                    </Col> */}
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={4}>
                        <Field
                          label={"Service Schedule From Date"}
                          name="ServiceScheduleStartDate"
                          placeholder={"Select Schedule From Date"}
                          component={CustomFlatpickr}
                          options={{
                            maxDate: moment(advanceBookingMaxDate).format(
                              "YYYY-MM-DD"
                            ),
                            altInput: true,
                            altFormat: "j-M-Y",
                            dateFormat: "d-m-Y",
                          }}
                          required
                          disabled={OrderBookingServiceIDEncrypt ? true : false}
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          label={"Service Schedule To Date"}
                          name="ServiceScheduleEndDate"
                          placeholder={"Select Schedule To Date"}
                          component={CustomFlatpickr}
                          options={{
                            minDate: moment(
                              new Date(ServiceScheduleStartDate)
                            ).format("DD-MM-YYYY"),
                            altInput: true,
                            altFormat: "j-M-Y",
                            dateFormat: "d-m-Y",
                          }}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Label>
                          Time
                          <span className="text-danger">*</span>
                        </Label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <TimePicker
                            name={"ServiceScheduleTime"}
                            autoOk
                            value={ServiceScheduleTime}
                            placeholder={"Select Time"}
                            onChange={(newValue) => {
                              formikService.setFieldValue(
                                "ServiceScheduleTime",
                                new Date(newValue)
                              );
                            }}
                            //minutesStep={0}
                            disabled={
                              OrderBookingServiceIDEncrypt ? true : false
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Col>

                      {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Booking Days"}
                      name="BookingDays"
                      placeholder={"Enter Booking Days"}
                      component={CustomInput}
                      required
                    />
                  </Col> */}
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={8}>
                        <Field
                          //label={"Staff"}
                          label={`Staff${
                            IsRotationalService ? " (Morning)" : ""
                          }`}
                          name="StaffIDEncrypt"
                          placeholder={"Select Staff"}
                          component={CustomSelect}
                          options={staffOptions}
                          required
                          isDisabled={
                            OrderBookingServiceIDEncrypt ? true : false
                          }
                          onChange={(e) => {
                            if (e) {
                              if (e?.isBooked === false) {
                                formikService.setFieldValue(
                                  "StaffIDEncrypt",
                                  e?.value
                                );
                                formikService.setFieldValue(
                                  "StaffName",
                                  e?.staffName
                                );
                                formikService.setFieldValue(
                                  "StaffServiceRate",
                                  e?.staffRate
                                );
                                formikService.setFieldValue(
                                  "ServiceScheduleStartTime",
                                  e?.startTime
                                );
                                formikService.setFieldValue(
                                  "ServiceScheduleEndTime",
                                  e?.finishTime
                                );
                                calcServiceRate(
                                  e?.staffRate,
                                  RotationalStaffServiceRate
                                );
                                if (
                                  RotationalStaffIDEncrypt &&
                                  RotationalStaffIDEncrypt === e?.value
                                ) {
                                  formikService.setFieldValue(
                                    "RotationalStaffIDEncrypt",
                                    ""
                                  );
                                  formikService.setFieldValue(
                                    "RotationalStaffName",
                                    ""
                                  );
                                  formikService.setFieldValue(
                                    "RotationalStaffServiceRate",
                                    0
                                  );
                                  formikService.setFieldValue(
                                    "RotationalAdvanceAmount",
                                    0
                                  );
                                  formikService.setFieldValue(
                                    "RotationalActualAdvanceAmount",
                                    0
                                  );
                                }
                              } else {
                                swal(
                                  e?.staffName,
                                  "This staff is already booked! Please select other staff.",
                                  "warning"
                                );
                              }
                            } else {
                              formikService.setFieldValue("StaffIDEncrypt", "");
                              formikService.setFieldValue("StaffName", "");
                              formikService.setFieldValue(
                                "StaffServiceRate",
                                0
                              );
                              formikService.setFieldValue(
                                "ServiceScheduleStartTime",
                                ""
                              );
                              formikService.setFieldValue(
                                "ServiceScheduleEndTime",
                                ""
                              );
                              formikService.setFieldValue(
                                "StaffAdvanceAmount",
                                0
                              );
                              formikService.setFieldValue(
                                "StaffActualAdvanceAmount",
                                0
                              );
                              calcServiceRate(0, RotationalStaffServiceRate);
                            }
                          }}
                        />
                      </Col>
                      {IsRotationalService ? (
                        <Col lg={8}>
                          <Field
                            label={"Rotational Staff (Evening)"}
                            name="RotationalStaffIDEncrypt"
                            placeholder={"Select Rotational Staff"}
                            component={CustomSelect}
                            options={
                              StaffIDEncrypt
                                ? staffOptions?.filter(
                                    (x) => x.value !== StaffIDEncrypt
                                  )
                                : staffOptions
                            }
                            required
                            isDisabled={
                              OrderBookingServiceIDEncrypt ? true : false
                            }
                            onChange={(e) => {
                              if (e) {
                                if (e?.isBooked === false) {
                                  formikService.setFieldValue(
                                    "RotationalStaffIDEncrypt",
                                    e?.value
                                  );
                                  formikService.setFieldValue(
                                    "RotationalStaffName",
                                    e?.staffName
                                  );
                                  formikService.setFieldValue(
                                    "RotationalStaffServiceRate",
                                    e?.staffRate
                                  );
                                  calcServiceRate(
                                    StaffServiceRate,
                                    e?.staffRate
                                  );
                                } else {
                                  swal(
                                    e?.staffName,
                                    "This staff is already booked! Please select other staff.",
                                    "warning"
                                  );
                                }
                              } else {
                                formikService.setFieldValue(
                                  "RotationalStaffIDEncrypt",
                                  ""
                                );
                                formikService.setFieldValue(
                                  "RotationalStaffName",
                                  ""
                                );
                                formikService.setFieldValue(
                                  "RotationalStaffServiceRate",
                                  0
                                );
                                formikService.setFieldValue(
                                  "RotationalAdvanceAmount",
                                  0
                                );
                                formikService.setFieldValue(
                                  "RotationalActualAdvanceAmount",
                                  0
                                );
                                calcServiceRate(StaffServiceRate, 0);
                              }
                            }}
                          />
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                    <hr></hr>
                    <Row>
                      {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Gross Amount"}
                      name="GrossAmount"
                      placeholder={"Gross Amount"}
                      component={CustomInput}
                      readOnly
                    />
                  </Col> */}
                      {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Discount Amount"}
                      name="DiscountAmount"
                      placeholder={"Enter Discount Amount"}
                      component={CustomInput}
                    />
                  </Col> */}
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Service Rate"}
                          name="ServiceRate"
                          placeholder={"Service Rate"}
                          component={CustomInput}
                          min={0}
                          readOnly
                          disabled={OrderBookingServiceIDEncrypt ? true : false}
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Days"}
                          name="ServiceBookingDays"
                          placeholder={"Days"}
                          component={CustomInput}
                          min={0}
                          readOnly
                          disabled={OrderBookingServiceIDEncrypt ? true : false}
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Total Amount"}
                          name="ServiceTotalAmount"
                          placeholder={"Total Amount"}
                          component={CustomInput}
                          min={0}
                          readOnly
                          disabled={OrderBookingServiceIDEncrypt ? true : false}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-sm btn-primary">
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => setServiceModal(false)}
                    className="btn btn-sm btn-light"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>

        <Modal
          className="modal fade"
          show={equipmentModal}
          onHide={setEquipmentModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Equipment</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setEquipmentModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <FormikProvider value={formikEquipments}>
              <Form onSubmit={formikEquipments.handleSubmit}>
                <div className="modal-body">
                  <div className="basic-form">
                    <Row>
                      <Col lg={4}>
                        <Field
                          label={"Equipment Type"}
                          name="EquipmentTypeID"
                          placeholder={"Select Equipment Type"}
                          component={CustomSelect}
                          options={equipmentTypeOptions}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          label={"Equipment"}
                          name="EquipmentIDEncrypt"
                          placeholder={"Select Equipment"}
                          component={CustomSelect}
                          options={equipmentOptions}
                          required
                          onChange={(e) => {
                            if (e) {
                              if (e?.availableQty > 0) {
                                formikEquipments.setFieldValue(
                                  "EquipmentIDEncrypt",
                                  e?.value
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentName",
                                  e?.equipmentName
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentTypeName",
                                  e?.equipmentTypeName
                                );
                                formikEquipments.setFieldValue(
                                  "IsAllowOrderEquipmentMultipleQty",
                                  e?.isAllowOrderMultipleQty
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentRentRate",
                                  e?.equipmentPrice
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentAvailableQty",
                                  e?.availableQty
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentTaxPercentage",
                                  e?.gstPercentage ? e?.gstPercentage : 0
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentGSTPlanIDEncrypt",
                                  e?.gstPlanIDEncrypt
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentDepositAmount",
                                  e?.equipmentDeposit ? e?.equipmentDeposit : 0
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentQty",
                                  e?.isAllowOrderMultipleQty === true ? "" : 1
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentMinimumDaysRent",
                                  e?.minimumDaysRent
                                );
                                formikEquipments.setFieldValue(
                                  "EquipmentTransportationCharges",
                                  e?.transportationCharges
                                );
                                calcEquipmentAmounts(
                                  e?.equipmentPrice,
                                  e?.isAllowOrderMultipleQty === true ? 0 : 1,
                                  EquipmentBookingDays
                                    ? EquipmentBookingDays
                                    : 0,
                                  e?.gstPercentage ? e?.gstPercentage : 0,
                                  e?.equipmentDeposit ? e?.equipmentDeposit : 0,
                                  e?.minimumDaysRent
                                );
                              } else {
                                swal(
                                  e?.equipmentName,
                                  "Stock is not available! You can't book this equipment.",
                                  "warning"
                                );
                                resetSelectedEquipmentDetail();
                              }
                            } else {
                              resetSelectedEquipmentDetail();
                            }
                          }}
                        />
                        {parseInt(EquipmentMinimumDaysRent) > 0 ? (
                          <span
                            style={{
                              float: "right",
                              marginTop: "-82px",
                              fontSize: "11px",
                              color: "maroon",
                            }}
                          >
                            Minimum Rent : {EquipmentMinimumDaysRent} days
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Equipment Rate"}
                          name="EquipmentRentRate"
                          placeholder={"Equipment Rate"}
                          component={CustomInput}
                          min={0}
                          readOnly
                        />
                      </Col>
                      {IsAllowOrderEquipmentMultipleQty ? (
                        <Col lg={4}>
                          <Field
                            type="number"
                            label={"Quantity"}
                            name="EquipmentQty"
                            placeholder={"Enter Quantity"}
                            component={CustomInput}
                            min={1}
                            max={EquipmentAvailableQty}
                            required
                            onChange={(e) => {
                              formikEquipments.setFieldValue(
                                "EquipmentQty",
                                e.target.value.slice(0, 3)
                              );
                            }}
                          />
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col lg={4}>
                        <Field
                          label={"Required On Date"}
                          name="EquipmentRequiredOnDate"
                          placeholder={"Enter Required On Date"}
                          component={CustomFlatpickr}
                          options={{
                            maxDate: moment(advanceBookingMaxDate).format(
                              "YYYY-MM-DD"
                            ),
                            altInput: true,
                            altFormat: "j-M-Y",
                            dateFormat: "d-m-Y",
                          }}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Booking Days"}
                          name="EquipmentBookingDays"
                          placeholder={"Enter Booking Days"}
                          component={CustomInput}
                          min={1}
                          required
                          onChange={(e) => {
                            formikEquipments.setFieldValue(
                              "EquipmentBookingDays",
                              e.target.value.slice(0, 3)
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <hr></hr>

                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={10}>
                        <Row style={{ textAlign: "right" }}>
                          <Col lg={9}>
                            <b>Gross Amount</b>
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={2}>
                            <i class="fa fa-rupee"></i> {EquipmentGrossAmount}
                          </Col>
                          <br></br>
                        </Row>
                        <Row style={{ textAlign: "right" }}>
                          <Col lg={9}>
                            <b>GST {EquipmentTaxPercentage} %</b>
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={2}>
                            {" "}
                            <i class="fa fa-rupee"></i> {EquipmentTaxAmount}{" "}
                          </Col>
                          <br></br>
                        </Row>
                        {/* <Row style={{ textAlign: "right" }}>
                          <Col lg={9}>
                            <b>Discount Amount</b>
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={2}>
                            {" "}
                            <i class="fa fa-rupee"></i> 500.00
                          </Col>
                          <br></br>
                        </Row> */}
                        <Row style={{ textAlign: "right" }}>
                          <Col lg={9}>
                            <b>Net Amount</b>
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={2}>
                            <i class="fa fa-rupee"></i> {EquipmentNetAmount}
                          </Col>
                          <br></br>
                        </Row>

                        <hr style={{ marginLeft: "50%" }}></hr>
                        <Row style={{ textAlign: "right" }}>
                          <Col lg={9}>
                            <b>Transportation Charges</b>
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={2}>
                            <i class="fa fa-rupee"></i>{" "}
                            {EquipmentTransportationCharges}
                          </Col>
                          <Col lg={9}>
                            <b>Deposite(PDC) Amount</b>
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={2}>
                            <i class="fa fa-rupee"></i>{" "}
                            {EquipmentTotalDepositAmount}
                          </Col>
                          <br></br>
                        </Row>
                        {/* <Row style={{ textAlign: "right" }}>
                          <Col lg={9}>
                            <b>Advance Amount</b>
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={2}>
                            <i class="fa fa-rupee"></i> {EquipmentAdvanceAmount}
                          </Col>
                          <br></br>
                        </Row> */}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-sm btn-primary">
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => setEquipmentModal(false)}
                    className="btn btn-sm btn-light"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>

        <Modal
          className="modal fade"
          show={packageModal}
          onHide={setPackageModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Package</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setPackageModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <FormikProvider value={formikPackages}>
            <Form onSubmit={formikPackages.handleSubmit}>
              <div className="modal-body">
                <div className="basic-form">
                  <Row>
                    <Col lg={8}>
                      <Field
                        label={"Packages"}
                        name="PackageIDEncrypt"
                        placeholder={"Select Packages"}
                        component={CustomSelect}
                        options={packageOptions}
                        required
                        onChange={(e) => {
                          if (e) {
                            formikPackages.setFieldValue(
                              "PackageIDEncrypt",
                              e?.value
                            );
                            formikPackages.setFieldValue(
                              "PackageName",
                              e?.label
                            );
                            formikPackages.setFieldValue(
                              "PackageRate",
                              e?.packagePrice
                            );
                            formikPackages.setFieldValue(
                              "NetAmount",
                              e?.packagePrice
                            );
                            formikPackages.setFieldValue(
                              "PackageDescription",
                              e?.description
                            );
                            formikPackages.setFieldValue(
                              "PackageInclusionServices",
                              e?.services
                            );
                            formikPackages.setFieldValue(
                              "PackageInclusionEquipments",
                              e?.equipments
                            );
                            formikPackages.setFieldValue(
                              "PackageDuration",
                              e?.duration
                            );
                            let packageAdvance =
                              parseFloat(e?.advancePercentage) > 0
                                ? (parseFloat(e?.packagePrice) *
                                    parseFloat(e?.advancePercentage)) /
                                  100
                                : 0;
                            formikPackages.setFieldValue(
                              "AdvanceAmount",
                              packageAdvance
                            );
                            formikPackages.setFieldValue(
                              "ActualAdvanceAmount",
                              packageAdvance
                            );
                          } else {
                            formikPackages.setFieldValue(
                              "PackageIDEncrypt",
                              ""
                            );
                            formikPackages.setFieldValue("PackageName", "");
                            formikPackages.setFieldValue("PackageRate", "");
                            formikPackages.setFieldValue("NetAmount", "");
                            formikPackages.setFieldValue(
                              "PackageDescription",
                              ""
                            );
                            formikPackages.setFieldValue(
                              "PackageInclusionServices",
                              ""
                            );
                            formikPackages.setFieldValue(
                              "PackageInclusionEquipments",
                              ""
                            );
                            formikPackages.setFieldValue("PackageDuration", "");
                            formikPackages.setFieldValue("AdvanceAmount", 0);
                            formikPackages.setFieldValue(
                              "ActualAdvanceAmount",
                              0
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Package Rate"}
                        name="PackageRate"
                        placeholder={"Package Rate"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} style={{ color: "black" }}>
                      Duration
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={8}>
                      {PackageDuration + (PackageDuration ? " days" : "")}
                    </Col>
                    <Col lg={3} style={{ color: "black" }}>
                      Description
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={8}>{PackageDescription}</Col>
                    <Col lg={3} style={{ color: "black" }}>
                      Inclusion Services
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={8}>{PackageInclusionServices}</Col>

                    <Col lg={3} style={{ color: "black" }}>
                      Inclusion Equipment
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={8}>{PackageInclusionEquipments}</Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Gross Amount"}
                      name="GrossAmount"
                      placeholder={"Enter Gross Amount"}
                      component={CustomInput}
                      readOnly
                    />
                  </Col> */}
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Discount Amount"}
                      name="DiscountAmount"
                      placeholder={"Enter Discount Amount"}
                      component={CustomInput}
                    />
                  </Col> */}
                    <Col lg={8}></Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Total Amount"}
                        name="NetAmount"
                        placeholder={"Total Amount"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-sm btn-primary">
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setPackageModal(false)}
                  className="btn btn-sm btn-light"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </FormikProvider>
        </Modal>

        <Modal
          className="modal fade"
          show={healthCheckupModal}
          onHide={setHealthCheckupModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Health Checkup</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setHealthCheckupModal(false)}
            >
              <span>×</span>
            </button>
          </div>

          <FormikProvider value={formikHealthCheckup}>
            <Form onSubmit={formikHealthCheckup.handleSubmit}>
              <div className="modal-body">
                <div className="basic-form">
                  <Row>
                    <Col lg={8}>
                      <Field
                        label={"Health Checkup"}
                        name="HealthCheckupIDEncrypt"
                        placeholder={"Select Health Checkup"}
                        component={CustomSelect}
                        options={healthCheckupOptions}
                        required
                        onChange={(e) => {
                          if (e) {
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupIDEncrypt",
                              e?.value
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupName",
                              e?.label
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupRate",
                              e?.healthCheckupPrice
                            );
                            formikHealthCheckup.setFieldValue(
                              "NetAmount",
                              e?.healthCheckupPrice
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupInclusionServices",
                              e?.services
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupInclusionPathologyReports",
                              e?.pathologyReports
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupInclusionRadiologyReports",
                              e?.radiologyReports
                            );
                            let hcAdvance =
                              parseFloat(e?.advancePercentage) > 0
                                ? (parseFloat(e?.healthCheckupPrice) *
                                    parseFloat(e?.advancePercentage)) /
                                  100
                                : 0;
                            formikHealthCheckup.setFieldValue(
                              "AdvanceAmount",
                              hcAdvance
                            );
                            formikHealthCheckup.setFieldValue(
                              "ActualAdvanceAmount",
                              hcAdvance
                            );
                          } else {
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupIDEncrypt",
                              ""
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupName",
                              ""
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupRate",
                              ""
                            );
                            formikHealthCheckup.setFieldValue("NetAmount", "");
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupInclusionServices",
                              ""
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupInclusionPathologyReports",
                              ""
                            );
                            formikHealthCheckup.setFieldValue(
                              "HealthCheckupInclusionRadiologyReports",
                              ""
                            );
                            formikHealthCheckup.setFieldValue(
                              "AdvanceAmount",
                              0
                            );
                            formikHealthCheckup.setFieldValue(
                              "ActualAdvanceAmount",
                              0
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Health Checkup Rate"}
                        name="HealthCheckupRate"
                        placeholder={"Health Checkup Rate"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4} style={{ color: "black" }}>
                      Inclusion Pathology Report
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={7}>{HealthCheckupInclusionPathologyReports}</Col>
                    <Col lg={4} style={{ color: "black" }}>
                      Inclusion Radiology Report
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={7}>{HealthCheckupInclusionRadiologyReports}</Col>
                    <Col lg={4} style={{ color: "black" }}>
                      Inclusion Services
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={7}>{HealthCheckupInclusionServices}</Col>
                  </Row>
                  <hr></hr>

                  <Row>
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Gross Amount"}
                      name="GrossAmount"
                      placeholder={"Enter Gross Amount"}
                      component={CustomInput}
                      readOnly
                    />
                  </Col> */}
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Discount Amount"}
                      name="DiscountAmount"
                      placeholder={"Enter Discount Amount"}
                      component={CustomInput}
                    />
                  </Col> */}
                    <Col lg={8}></Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Total Amount"}
                        name="NetAmount"
                        placeholder={"Total Amount"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-sm btn-primary">
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setHealthCheckupModal(false)}
                  className="btn btn-sm btn-light"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </FormikProvider>
        </Modal>

        <Modal
          className="modal fade"
          show={patientPastHistoryModel}
          onHide={setPatientPastHistoryModal}
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Patient Past History</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setPatientPastHistoryModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <Row>
                  <Col lg={12}>
                    <ul>
                      {PatientPastHistoryList?.length > 0
                        ? PatientPastHistoryList?.map((item, index) => (
                            <>
                              <li>{item.PastHistory}</li>
                              {index !== PatientPastHistoryList.length - 1 ? (
                                <hr></hr>
                              ) : (
                                ""
                              )}
                            </>
                          ))
                        : "No record found..."}
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => setPatientPastHistoryModal(false)}
              className="btn btn-sm btn-light"
            >
              Close
            </button>
          </div>
        </Modal>

        <Modal
          className="modal fade"
          show={pathologyReportModal}
          onHide={setPathologyReportModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Pathology Report</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setPathologyReportModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <FormikProvider value={formikPathologyReport}>
            <Form onSubmit={formikPathologyReport.handleSubmit}>
              <div className="modal-body">
                <div className="basic-form">
                  <Row>
                    <Col lg={8}>
                      <Field
                        label={"Pathology Report"}
                        name="PathologyReportIDEncrypt"
                        placeholder={"Select PathologyReport"}
                        component={CustomSelect}
                        options={pathologyReportsOptions}
                        required
                        onChange={(e) => {
                          if (e) {
                            formikPathologyReport.setFieldValue(
                              "PathologyReportIDEncrypt",
                              e?.value
                            );
                            formikPathologyReport.setFieldValue(
                              "PathologyReportName",
                              e?.label
                            );
                            formikPathologyReport.setFieldValue(
                              "PathologyReportPrice",
                              e?.pathologyReportPrice
                            );
                            formikPathologyReport.setFieldValue(
                              "NetAmount",
                              e?.pathologyReportPrice
                            );
                            let pathoAdvance =
                              parseFloat(diagnosticsAdvancePercentage) > 0
                                ? (parseFloat(e?.pathologyReportPrice) *
                                    parseFloat(diagnosticsAdvancePercentage)) /
                                  100
                                : 0;
                            formikPathologyReport.setFieldValue(
                              "AdvanceAmount",
                              pathoAdvance
                            );
                            formikPathologyReport.setFieldValue(
                              "ActualAdvanceAmount",
                              pathoAdvance
                            );
                          } else {
                            formikPathologyReport.setFieldValue(
                              "PathologyReportIDEncrypt",
                              ""
                            );
                            formikPathologyReport.setFieldValue(
                              "PathologyReportName",
                              ""
                            );
                            formikPathologyReport.setFieldValue(
                              "PathologyReportPrice",
                              ""
                            );
                            formikPathologyReport.setFieldValue(
                              "NetAmount",
                              ""
                            );
                            formikPathologyReport.setFieldValue(
                              "AdvanceAmount",
                              0
                            );
                            formikPathologyReport.setFieldValue(
                              "ActualAdvanceAmount",
                              0
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Pathology Report Rate"}
                        name="PathologyReportPrice"
                        placeholder={"Pathology Report Rate"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        label={"Date"}
                        name="DateOfPathologyReport"
                        placeholder={"Select Date of Report"}
                        component={CustomFlatpickr}
                        options={{
                          maxDate: moment(advanceBookingMaxDate).format(
                            "YYYY-MM-DD"
                          ),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </Col>
                    <Col lg={4}>
                      <Label>
                        Time
                        <span className="text-danger">*</span>
                      </Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          name={"TimeOfPathologyReport"}
                          autoOk
                          value={TimeOfPathologyReport}
                          placeholder={"Select Time"}
                          onChange={(newValue) => {
                            formikPathologyReport.setFieldValue(
                              "TimeOfPathologyReport",
                              new Date(newValue)
                            );
                          }}
                          //minutesStep={0}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Gross Amount"}
                      name="GrossAmount"
                      placeholder={"Enter Gross Amount"}
                      component={CustomInput}
                      readOnly
                    />
                  </Col> */}
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Discount Amount"}
                      name="DiscountAmount"
                      placeholder={"Enter Discount Amount"}
                      component={CustomInput}
                    />
                  </Col> */}
                    <Col lg={8}></Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Total Amount"}
                        name="NetAmount"
                        placeholder={"Total Amount"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-sm btn-primary">
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setPathologyReportModal(false)}
                  className="btn btn-sm btn-light"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </FormikProvider>
        </Modal>

        <Modal
          className="modal fade"
          show={radiologyReportModal}
          onHide={setRadiologyReportModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Radiology Report</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setRadiologyReportModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <FormikProvider value={formikRadiologyReport}>
            <Form onSubmit={formikRadiologyReport.handleSubmit}>
              <div className="modal-body">
                <div className="basic-form">
                  <Row>
                    <Col lg={8}>
                      <Field
                        label={"Radiology Report"}
                        name="RadiologyReportIDEncrypt"
                        placeholder={"Select Radiology Report"}
                        component={CustomSelect}
                        options={radiologyReportsOptions}
                        required
                        onChange={(e) => {
                          if (e) {
                            formikRadiologyReport.setFieldValue(
                              "RadiologyReportIDEncrypt",
                              e?.value
                            );
                            formikRadiologyReport.setFieldValue(
                              "RadiologyReportName",
                              e?.label
                            );
                            formikRadiologyReport.setFieldValue(
                              "RadiologyReportPrice",
                              e?.radiologyReportPrice
                            );
                            formikRadiologyReport.setFieldValue(
                              "NetAmount",
                              e?.radiologyReportPrice
                            );
                            let radioAdvance =
                              parseFloat(diagnosticsAdvancePercentage) > 0
                                ? (parseFloat(e?.radiologyReportPrice) *
                                    parseFloat(diagnosticsAdvancePercentage)) /
                                  100
                                : 0;
                            formikRadiologyReport.setFieldValue(
                              "AdvanceAmount",
                              radioAdvance
                            );
                            formikRadiologyReport.setFieldValue(
                              "ActualAdvanceAmount",
                              radioAdvance
                            );
                          } else {
                            formikRadiologyReport.setFieldValue(
                              "RadiologyReportIDEncrypt",
                              ""
                            );
                            formikRadiologyReport.setFieldValue(
                              "RadiologyReportName",
                              ""
                            );
                            formikRadiologyReport.setFieldValue(
                              "RadiologyReportPrice",
                              ""
                            );
                            formikRadiologyReport.setFieldValue(
                              "NetAmount",
                              ""
                            );
                            formikRadiologyReport.setFieldValue(
                              "AdvanceAmount",
                              0
                            );
                            formikRadiologyReport.setFieldValue(
                              "ActualAdvanceAmount",
                              0
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Radiology Report Rate"}
                        name="RadiologyReportPrice"
                        placeholder={"Radiology Report Rate"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        label={"Date"}
                        name="DateOfRadiologyReport"
                        placeholder={"Select Date of Report"}
                        component={CustomFlatpickr}
                        options={{
                          maxDate: moment(advanceBookingMaxDate).format(
                            "YYYY-MM-DD"
                          ),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </Col>
                    <Col lg={4}>
                      <Label>
                        Time
                        <span className="text-danger">*</span>
                      </Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          name={"TimeOfRadiologyReport"}
                          autoOk
                          value={TimeOfRadiologyReport}
                          placeholder={"Select Time"}
                          onChange={(newValue) => {
                            formikRadiologyReport.setFieldValue(
                              "TimeOfRadiologyReport",
                              new Date(newValue)
                            );
                          }}
                          //minutesStep={0}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Gross Amount"}
                      name="GrossAmount"
                      placeholder={"Enter Gross Amount"}
                      component={CustomInput}
                      readOnly
                    />
                  </Col> */}
                    {/* <Col lg={4}>
                    <Field
                      type="number"
                      label={"Discount Amount"}
                      name="DiscountAmount"
                      placeholder={"Enter Discount Amount"}
                      component={CustomInput}
                    />
                  </Col> */}

                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Total Amount"}
                        name="NetAmount"
                        placeholder={"Total Amount"}
                        component={CustomInput}
                        min={0}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-sm btn-primary">
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setRadiologyReportModal(false)}
                  className="btn btn-sm btn-light"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </FormikProvider>
        </Modal>

        <Modal
          className="modal fade"
          show={patientDetailModel}
          onHide={setPatientDetailModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Patient Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setPatientDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalPatientDetails
              patientID={PatientIDEncrypt}
              setPatientDetailModal={setPatientDetailModal}
            ></ModalPatientDetails>
          </div>
        </Modal>

        <Modal
          className="modal fade"
          show={patientAddressModel}
          onHide={setPatientAddressModal}
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <FormikProvider value={formikAddress}>
              <Form onSubmit={formikAddress.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Address</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setPatientAddressModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <Row>
                        <Col lg={12}>
                          <Field
                            type="textarea"
                            label={"Address"}
                            name="Address"
                            placeholder={"Enter Address"}
                            rows={2}
                            component={CustomInput}
                            required
                            style={{ resize: "none" }}
                            maxLength={500}
                            // onChange={(e) => {
                            //   formikAddress.setFieldValue(
                            //     "Address",
                            //     e.target.value.replace(
                            //       /[^A-Za-z 0-9 , -]/g,
                            //       ""
                            //     )
                            //   );
                            // }}
                          />
                        </Col>
                        <Col lg={12}>
                          <Field
                            type="text"
                            label={"Location"}
                            name="Location"
                            placeholder={"Select Location"}
                            component={CustomInput}
                            onChange={(e) => {
                              getPlacePredictions({
                                input: e.target.value,
                              });
                              formikAddress.setFieldValue(
                                "Location",
                                e.target.value
                              );
                              setAddressSuggesionDivStatus(true);
                            }}
                            loading={isPlacePredictionLoading}
                            required
                          />
                          {addressSuggesionDivStatus &&
                          placePredictions.length > 0 ? (
                            <div
                              style={{
                                background: "white",
                                borderRadius: "0.3rem",
                                position: "absolute",
                                // left: "40px",
                                overflow: "auto",
                                border: "1px solid rgb(207, 207, 207)",
                                boxShadow: "rgb(28 28 28 / 8%) 0px 2px 8px",
                                visibility: "visible",
                                opacity: "1",
                                transition:
                                  "opacity 0.25s ease 0s, top 0.25s ease 0s",
                                zIndex: "10",
                                transform: "translateY(-0.6rem)",
                                width: "94%",
                              }}
                            >
                              <List
                                style={{
                                  padding: "0px 10px",
                                  textAlign: "left",
                                }}
                                dataSource={placePredictions}
                                renderItem={(item) => (
                                  <List.Item
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      formikAddress.setFieldValue(
                                        "Location",
                                        item.description
                                      );
                                      setAddressSuggesionDivStatus(false);
                                      geocodeByPlaceId(item.place_id)
                                        .then((results) => {
                                          formikAddress.setFieldValue(
                                            "Latitude",
                                            results[0].geometry.location.lat()
                                          );
                                          formikAddress.setFieldValue(
                                            "Longitude",
                                            results[0].geometry.location.lng()
                                          );
                                        })
                                        .catch((error) => console.error(error));
                                    }}
                                  >
                                    <List.Item.Meta title={item.description} />
                                  </List.Item>
                                )}
                              ></List>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                        {/* <Col lg={6}>
                            <Field
                              type="text"
                              label={"Latitude"}
                              name="Latitude"
                              id="Latitude"
                              placeholder={"Enter Latitude"}
                              component={CustomInput}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text"
                              label={"Longitude"}
                              name="Longitude"
                              id="Longitude"
                              placeholder={"Enter Longitude"}
                              component={CustomInput}
                            />
                          </Col> */}
                        <Col lg={6}>
                          <Field
                            type="text"
                            label={"State"}
                            name="StateID"
                            placeholder={"Select State"}
                            options={stateOptions}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        <Col lg={6}>
                          <Field
                            type="text"
                            label={"City"}
                            name="CityID"
                            placeholder={"Select City"}
                            options={cityOptions}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        <Col lg={6}>
                          <Field
                            type="text"
                            label={"Area"}
                            name="AreaID"
                            placeholder={"Select Area"}
                            options={areaOptions}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        <Col lg={6}>
                          <Field
                            type="number"
                            label={"Pincode"}
                            name="Pincode"
                            placeholder={"Enter Pincode"}
                            component={CustomInput}
                            required
                            maxLength={6}
                            min={0}
                            onChange={(e) => {
                              formikAddress.setFieldValue(
                                "Pincode",
                                e.target.value.slice(0, 6)
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setPatientAddressModal(false)}
                    className="btn btn-light"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>

        <Modal
          className="modal fade"
          show={orderHistoryModal}
          onHide={setOrderHistoryModal}
          size="xl"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Order History</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setOrderHistoryModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="order_list"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="example5"
                          className="table patient-list mb-2 dataTablesCard fs-14 dataTable no-footer"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead className="thead-light">
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Order No: activate to sort column ascending"
                                style={{
                                  width: "10%",
                                  padding: "10px 15px",
                                }}
                              >
                                Order No.
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company Name: activate to sort column ascending"
                                style={{
                                  width: "25%",
                                  padding: "10px 15px",
                                }}
                              >
                                Company Name
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Patient Name: activate to sort column ascending"
                                style={{
                                  width: "25%",
                                  padding: "10px 15px",
                                }}
                              >
                                Patient Name
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Order Booking Date: activate to sort column ascending"
                                style={{
                                  width: "20%",
                                  padding: "10px 15px",
                                }}
                              >
                                Order Booking Date
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                                style={{
                                  width: "10%",
                                  padding: "10px 15px",
                                }}
                              >
                                Status
                              </th>

                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Actions: activate to sort column ascending"
                                style={{
                                  width: "10%",
                                  padding: "10px 15px",
                                }}
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {OrderHistoryDataList?.length > 0 ? (
                              OrderHistoryDataList?.map((element) => (
                                <tr role="row" className="odd">
                                  <td>{element.OrderBookingNo}</td>
                                  <td>{element.CompanyName}</td>
                                  <td>{element.PatientName}</td>
                                  <td>{element.OrderBookingDate}</td>
                                  <td>{element.OrderBookingStatusValue}</td>
                                  <td>
                                    <div className="d-flex">
                                      <Link
                                        to="#"
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Order Detail"
                                        onClick={() => {
                                          setOrderBookingID_Detail(
                                            element.OrderBookingIDEncrypted
                                          );
                                          setOrderDetailModal(true);
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={6}>
                                  <div className="mt-2 ml-1 mb-3">
                                    {"No Record Found"}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => setOrderHistoryModal(false)}
              className="btn btn-light"
            >
              {" "}
              <i className="flaticon-delete-1"></i> Close
            </button>
          </div>
        </Modal>

        <Modal
          className="modal fade"
          show={orderDetailModal}
          onHide={setOrderDetailModal}
          size="xl"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Order Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setOrderDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalOrderBookingDetail
              orderBookingID={orderBookingID_Detail}
              setOrderDetailModal={setOrderDetailModal}
            ></ModalOrderBookingDetail>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          show={orderSuccessModal}
          onHide={setOrderSuccessModal}
          backdrop={"static"}
          centered
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <div className="modal-body">
              <div className="swal-icon swal-icon--success">
                <span className="swal-icon--success__line swal-icon--success__line--long"></span>
                <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

                <div className="swal-icon--success__ring"></div>
                <div className="swal-icon--success__hide-corners"></div>
              </div>
              <div className="swal-title">Success</div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary m-1"
                  onClick={() => getSLAByOrderBookingID(OrderBookingIDEncrypt)}
                >
                  SLA Agreement
                </button>
                <button
                  type="button"
                  className="btn btn-light m-1"
                  onClick={() => setOrderDetailModal(true)}
                >
                  Order Booking Details
                </button>
                <button
                  type="button"
                  className="btn btn-info m-1"
                  onClick={() => setOrderSuccessModal(false)}
                >
                  Ok
                </button>
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          className="modal fade"
          show={serviceForceCloseModal}
          onHide={setServiceForceCloseModal}
          backdrop={"static"}
          centered
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <FormikProvider value={formikServiceForceClose}>
              <Form onSubmit={formikServiceForceClose.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Service Force Close</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setServiceForceCloseModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="swal-icon swal-icon--warning mt-0">
                    <span className="swal-icon--warning__body">
                      <span className="swal-icon--warning__dot"></span>
                    </span>
                  </div>
                  <div className="swal-title">
                    Are you sure you want to force close the service?
                  </div>
                  <div className="text-center">{ForceCloseServiceName}</div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-danger m-1">
                    Service Force Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-light m-1"
                    onClick={() => setServiceForceCloseModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>
        <Modal
          className="modal fade"
          show={serviceExtendModal}
          onHide={setServiceExtendModal}
          backdrop={"static"}
          centered
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <FormikProvider value={formikServiceExtend}>
              <Form onSubmit={formikServiceExtend.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Service Extend</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setServiceExtendModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col md={12}>
                      <h5>{ExtendServiceName}</h5>
                    </Col>
                    <Col md={6}>
                      <Field
                        label={"Service Schedule From Date"}
                        name="ServiceScheduleExtendFromDate"
                        placeholder={"Select Schedule From Date"}
                        component={CustomFlatpickr}
                        options={{
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                        disabled
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        label={"Service Schedule To Date"}
                        name="ServiceScheduleExtendToDate"
                        placeholder={"Select Schedule To Date"}
                        component={CustomFlatpickr}
                        options={{
                          // minDate: moment(
                          //   new Date(ServiceScheduleActualToDate)
                          // ).format("DD-MM-YYYY"),
                          minDate: new Date(ServiceScheduleActualToDate),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary m-1">
                    Extend
                  </button>
                  <button
                    type="button"
                    className="btn btn-light m-1"
                    onClick={() => setServiceExtendModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>
        <Modal
          className="modal fade"
          show={equipmentForceCloseModal}
          onHide={setEquipmentForceCloseModal}
          backdrop={"static"}
          centered
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <FormikProvider value={formikEquipmentForceClose}>
              <Form onSubmit={formikEquipmentForceClose.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Equipment Force Close</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setEquipmentForceCloseModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="swal-icon swal-icon--warning mt-0">
                    <span className="swal-icon--warning__body">
                      <span className="swal-icon--warning__dot"></span>
                    </span>
                  </div>
                  <div className="swal-title">
                    Are you sure you want to force close the equipment?
                  </div>
                  <div className="text-center">{ForceCloseEquipmentName}</div>
                </div>
                <div className="modal-footer">
                  <div style={{ marginTop: "-5px" }}>
                    <Field
                      label={"Equipment Close Date"}
                      name="EquipmentCloseDate"
                      placeholder={"Select Equipment Close Date"}
                      component={CustomFlatpickr}
                      options={{
                        minDate: new Date(EquipmentStartDate),
                        maxDate: new Date(EquipmentActualEndDate),
                        altInput: true,
                        altFormat: "j-M-Y",
                        dateFormat: "d-m-Y",
                      }}
                      required
                    />
                  </div>
                  <div>
                    <button type="submit" className="btn btn-danger m-1">
                      Force Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-light m-1"
                      onClick={() => setEquipmentForceCloseModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>
        <Modal
          className="modal fade"
          show={equipmentExtendModal}
          onHide={setEquipmentExtendModal}
          backdrop={"static"}
          centered
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <FormikProvider value={formikEquipmentExtend}>
              <Form onSubmit={formikEquipmentExtend.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Equipment Extend</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setEquipmentExtendModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col md={12}>
                      <h5>{ExtendEquipmentName}</h5>
                    </Col>
                    <Col md={6}>
                      <Field
                        label={"Required From Date"}
                        name="EquipmentExtendRequiredFromDate"
                        placeholder={"Select Required From Date"}
                        component={CustomFlatpickr}
                        options={{
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                        disabled
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        label={"Required To Date"}
                        name="EquipmentExtendRequiredToDate"
                        placeholder={"Select Required To Date"}
                        component={CustomFlatpickr}
                        options={{
                          // minDate: moment(
                          //   new Date(EquipmentActualRequiredToDate)
                          // ).format("DD-MM-YYYY"),
                          minDate: new Date(EquipmentActualRequiredToDate),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary m-1">
                    Extend
                  </button>
                  <button
                    type="button"
                    className="btn btn-light m-1"
                    onClick={() => setEquipmentExtendModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default AddOrder;
