import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { Modal, Nav, Tab, Col, Row } from "react-bootstrap";
import { get, post, postFormData } from "../../../Helper/api_helper";
import {
  GET_CompanyDropDownList,
  POST_GetCityList,
  POST_GetDocumentDDLListByCheckListType,
  POST_GetFormRightsList,
  POST_GetQualificationDDL,
  POST_GetReligionDDL,
  POST_GetStateList,
  POST_GetUserDetailByID,
  POST_GetUserDropDownListByCompanyID,
  POST_GetUserRightsByLoginID,
  POST_UserCreate,
  POST_UserDocumentDelete,
  POST_UserDocumentInsert,
  POST_UserFormRightsSave,
  POST_UserQualificationDocumentDelete,
  POST_UserQualificationDocumentInsert,
  POST_UserUpdate,
} from "../../../Helper/url_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import moment from "moment";

const AddUser = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(0);

  const { UserIDEncryptParam } = useParams();
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const [tab, setTab] = useState(1);
  const [qualificationModal, setQualificationModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [copyUserRightsModal, setCopyUserRightsModal] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const [qualificationFile, setQualificationFile] = useState(null);
  const [fileKeyQualification, setFileKeyQualification] = useState();
  const [documentFile, setDocumentFile] = useState(null);
  const [fileKeyDocument, setFileKeyDocument] = useState();

  const [userQualificationList, setUserQualificationList] = useState([]);
  const [userDocumentList, setUserDocumentList] = useState([]);

  const [countryPrimary, setCountryPrimary] = useState("");
  const [countrySecondary, setCountrySecondary] = useState("");
  const [countryEmergency, setCountryEmergency] = useState("");

  const optionUserType = [
    { label: "Admin", value: 1 },
    { label: "User", value: 2 },
  ];

  const optionGender = [
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
  ];

  const optionMaritalStatus = [
    { label: "Unmarried", value: 1 },
    { label: "Married", value: 2 },
    { label: "Divorced", value: 3 },
    { label: "Widow", value: 4 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadReligionDDL = async () => {
    await post(POST_GetReligionDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setReligionOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadQualificationDDL = async () => {
    await post(POST_GetQualificationDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setQualificationOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadDocumentDDL = async () => {
    await post(POST_GetDocumentDDLListByCheckListType, { CheckListType: 1 })
      .then((res) => {
        if (res.IsSuccess) {
          setDocumentOptions(
            res.DocumentList.map((item) => ({
              label: item.DocumentName,
              value: item.DocumentID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadStateDDL = async () => {
    await post(POST_GetStateList)
      .then((res) => {
        if (res.IsSuccess) {
          setStateOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadCityDDL = async (selectedStateID) => {
    await post(POST_GetCityList + selectedStateID)
      .then((res) => {
        if (res.IsSuccess) {
          setCityOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            CityID &&
            res.List?.filter((x) => x.CategoryID == CityID).length == 0
          ) {
            formik.setFieldValue("CityID", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadFormRightsList = async () => {
    await post(POST_GetFormRightsList, { CompanyType: AuthUser?.CompanyType })
      .then((res) => {
        if (res.IsSuccess) {
          formikUserRights.setFieldValue(
            "ListUserFormRights",
            res.UserRightsList
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const validationSchema = Yup.object().shape({
    CompanyID: Yup.string().required("Company is required field"),
    UserType: Yup.string().required("User Type is required field"),
    FirstName: Yup.string().required("First Name is required field"),
    LastName: Yup.string().required("Last Name is required field"),
    DateOfBirth: Yup.string().required("Date of Birth is required field"),
    StateID: Yup.string().required("State is required field"),
    CityID: Yup.string().required("City is required field"),
    Gender: Yup.string().required("Gender is required field"),
    MaritalStatus: Yup.string().required("Marital Status is required field"),
    Religion: Yup.string().required("Religion is required field"),
    EmailID: Yup.string(), //.required("EmailID is required field"),
    PrimaryContactNumber: Yup.string().required(
      "Primary Mobile is required field"
    ),
    FatherName: Yup.string().required(" Father Name is required field"),
    MotherName: Yup.string().required(" Mother Name is required field"),
    DateOfJoining: Yup.string().required(" Date Of Joining is required field"),
    Address: Yup.string().required("Address is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      UserIDEncrypt: UserIDEncryptParam ? window.atob(UserIDEncryptParam) : "",
      CompanyID: AuthUser?.CompanyIDEncrypt,
      UserType: AuthUser?.UserType === 1 ? "" : 2,
      UserNo: "",
      FirstName: "",
      LastName: "",
      DateOfBirth: "",
      Gender: "",
      MaritalStatus: "",
      FatherName: "",
      MotherName: "",
      SpouseName: "",
      Religion: "",
      EmailID: "",
      PrimaryContactNumber: "",
      SecondaryContactNumber: "",
      EmergencyContactNumber: "",
      DateOfJoining: "",
      DateOfLeaving: "",
      IsEquipmentRights: false,
      Address: "",
      StateID: "",
      CityID: "",
      IsActive: true,
      CountryCodePrimary: "+91",
      CountryCodeSecondary: "+91",
      CountryCodeEmergency: "+91",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      UserType,
      MaritalStatus,
      UserIDEncrypt,
      CompanyID,
      StateID,
      IsEquipmentRights,
      CountryCodePrimary,
      CountryCodeSecondary,
      CountryCodeEmergency,
      DateOfBirth,
      DateOfJoining,
      CityID,
    },
  } = formik;

  useEffect(() => {
    if (!DateOfBirth) {
      setFieldValue("DateOfBirth", "");
    }
  }, [DateOfBirth]);

  useEffect(() => {
    if (!DateOfJoining) {
      setFieldValue("DateOfJoining", "");
    }
  }, [DateOfJoining]);

  const handleValidSubmit = (values) => {
    setIsLoading(true);
    var paylod = {
      ...values,
      DateOfBirth: `${moment(values.DateOfBirth)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      DateOfJoining: `${moment(values.DateOfJoining)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      DateOfLeaving: values.DateOfLeaving
        ? `${moment(values.DateOfLeaving)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`
        : "",
      CompanyIDEncrypt: CompanyID,
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
      ModifiedByEncrypt: AuthUser?.LoginIDEncrypt,
      CountryCodePrimary: "+" + CountryCodePrimary.replace(/\+/g, ""),
      CountryCodeSecondary: "+" + CountryCodeSecondary.replace(/\+/g, ""),
      CountryCodeEmergency: "+" + CountryCodeEmergency.replace(/\+/g, ""),
    };
    post(UserIDEncrypt ? POST_UserUpdate : POST_UserCreate, paylod)
      .then((response) => {
        if (response.IsSuccess) {
          if (!UserIDEncrypt) {
            setFieldValue("UserIDEncrypt", response.EncryptID);
          }
          swal("Success!", t(response.MessageCode), "success");
          setTab(2);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (StateID) {
      loadCityDDL(StateID ? StateID : 0);
    } else {
      formik.setFieldValue("StateID", "");
      formik.setFieldValue("CityID", "");
      setCityOptions([]);
    }
  }, [StateID]);

  const schemaUserQualification = Yup.object().shape({
    QualificationID: Yup.string()
      .trim()
      .required("Qualification is a required field"),
  });

  const formikUserQualification = useFormik({
    initialValues: {
      QualificationID: "",
    },
    validationSchema: schemaUserQualification,
    onSubmit: (values, formikHelpers) =>
      handleSubmitUserQualification(values, formikHelpers),
    validateOnBlur: false,
  });

  //const { handleSubmitUserQualification } = formikUserQualification;

  const schemaUserDocuments = Yup.object().shape({
    DocumentID: Yup.string().trim().required("Document is a required field"),
  });

  const formikUserDocuments = useFormik({
    initialValues: {
      DocumentID: "",
    },
    validationSchema: schemaUserDocuments,
    onSubmit: (values, formikHelpers) =>
      handleSubmitUserDocuments(values, formikHelpers),
    validateOnBlur: false,
  });

  //const { handleSubmitUserDocuments } = formikUserDocuments;

  const schemaUserRights = Yup.object().shape({});

  const formikUserRights = useFormik({
    initialValues: {
      ListUserFormRights: [],
    },
    validationSchema: schemaUserRights,
    onSubmit: (values, formikHelpers) =>
      handleSubmitUserRights(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: { ListUserFormRights },
  } = formikUserRights;

  const handleSubmitUserRights = (values) => {
    setIsLoading(true);
    post(POST_UserFormRightsSave, {
      UserIDEncrypted: UserIDEncrypt,
      CreatedByEncrypted: AuthUser?.LoginIDEncrypt,
      UserFormRightsList: ListUserFormRights,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const schemaCopyUserRights = Yup.object().shape({
    CopyUserLoginID: Yup.string().trim().required("User is a required field"),
  });

  const formikCopyUserRights = useFormik({
    initialValues: {
      CopyFromCompanyID: CompanyID,
      CopyUserLoginID: "",
      ListCopyUserFormRights: [],
    },
    validationSchema: schemaCopyUserRights,
    onSubmit: (values, formikHelpers) =>
      handleSubmitCopyUserRights(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: { CopyFromCompanyID, CopyUserLoginID, ListCopyUserFormRights },
  } = formikCopyUserRights;

  const handleSubmitCopyUserRights = (values) => {
    formikUserRights.setFieldValue(
      "ListUserFormRights",
      ListCopyUserFormRights
    );
    setCopyUserRightsModal(false);
  };

  const loadUsersDDL = async (CompanyID) => {
    await post(POST_GetUserDropDownListByCompanyID, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setUserOptions(
            res.UserList.map((item) => ({
              label: item.UserName,
              value: item.LoginIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadUserRightByLoginID = async (LoginID) => {
    await post(POST_GetUserRightsByLoginID, {
      LoginIDEncrypted: LoginID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          formikCopyUserRights.setFieldValue(
            "ListCopyUserFormRights",
            res.UserRightsList
          );
        } else {
          formikCopyUserRights.setFieldValue("ListCopyUserFormRights", []);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadUsersDDL(CopyFromCompanyID);
  }, [CopyFromCompanyID]);

  useEffect(() => {
    if (CopyUserLoginID) {
      loadUserRightByLoginID(CopyUserLoginID);
    } else {
      formikCopyUserRights.setFieldValue("ListCopyUserFormRights", []);
    }
  }, [CopyUserLoginID]);

  useEffect(() => {
    setIsLoading(true);
    loadCompanyDDL();
    loadReligionDDL();
    loadStateDDL();
    loadQualificationDDL();
    loadDocumentDDL();
    if (UserIDEncryptParam) {
      post(POST_GetUserDetailByID, {
        UserIDEncrypt: window.atob(UserIDEncryptParam),
      })
        .then((response) => {
          if (response?.IsSuccess) {
            setValues({
              ...response,
              CompanyID: response.CompanyIDEncrypt,
              DateOfBirth: new Date(response.DateOfBirth),
              DateOfJoining: new Date(response.DateOfJoining),
              DateOfLeaving:
                response.DateOfLeaving === ""
                  ? ""
                  : new Date(response.DateOfLeaving),
            });
            setUserQualificationList([...response.UserQualificationsList]);
            setUserDocumentList([...response.UserDocumentList]);
            formikUserRights.setFieldValue(
              "ListUserFormRights",
              response.UserFormRightsList
            );
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      loadFormRightsList();
      setIsLoading(false);
    }
  }, []);

  const uploadQualificationFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /(\.|\/)(jpe?g|png|webp|pdf|svg|gif|ico)$/;
      if (!file.type.match(pattern)) {
        setQualificationFile(null);
        setFileKeyQualification(Math.random().toString(9).substring(2, 9));
        swal("", "Select valid file format (PDF/Image)", "warning");
      } else {
        setQualificationFile(file);
        setFileKeyQualification(Math.random().toString(9).substring(2, 9));
      }
    } else {
      setQualificationFile(null);
      setFileKeyQualification(Math.random().toString(9).substring(2, 9));
    }
  };

  const handleSubmitUserQualification = async (values) => {
    if (qualificationFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("QualificationID", values.QualificationID);
      formData.append("UserIDEncrypt", UserIDEncrypt);
      formData.append("Files", qualificationFile);

      await postFormData(POST_UserQualificationDocumentInsert, formData)
        .then((response) => {
          if (response.IsSuccess) {
            setUserQualificationList([...response.UserQualificationsList]);
            setQualificationModal(false);
            swal("Success!", t(response.MessageCode), "success");
            setIsLoading(false);
            resetQualificationUploadForm();
          } else {
            swal("Error!", t(response.MessageCode), "error");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const resetQualificationUploadForm = () => {
    formikUserQualification.resetForm();
    setQualificationFile(null);
    setFileKeyQualification(Math.random().toString(9).substring(2, 9));
  };

  const delete_UserQualification = (UserQualificationID, UserID) => {
    swal({
      title: "Are you sure you want to delete the user qualification?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_UserQualificationConfirm(UserQualificationID, UserID);
      }
    });
  };

  const delete_UserQualificationConfirm = async (
    UserQualificationID,
    UserID
  ) => {
    setIsLoading(true);
    await post(POST_UserQualificationDocumentDelete, {
      UserIDEncrypt: UserID,
      UserQualificationIDEncrypt: UserQualificationID,
      EncryptModifiedBy: AuthUser.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setUserQualificationList([...response.UserQualificationsList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const uploadDocumentFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /(\.|\/)(jpe?g|png|webp|pdf|svg|gif|ico)$/;
      if (!file.type.match(pattern)) {
        setQualificationFile(null);
        setFileKeyQualification(Math.random().toString(9).substring(2, 9));
        swal("", "Select valid file format (PDF/Image)", "warning");
      } else {
        setDocumentFile(file);
        setFileKeyDocument(Math.random().toString(9).substring(2, 9));
      }
    } else {
      setDocumentFile(null);
      setFileKeyDocument(Math.random().toString(9).substring(2, 9));
    }
  };

  const handleSubmitUserDocuments = async (values) => {
    if (documentFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("DocumentID", values.DocumentID);
      formData.append("UserIDEncrypt", UserIDEncrypt);
      formData.append("Files", documentFile);

      await postFormData(POST_UserDocumentInsert, formData)
        .then((response) => {
          if (response.IsSuccess) {
            setUserDocumentList([...response.UserDocumentList]);
            setDocumentModal(false);
            swal("Success!", t(response.MessageCode), "success");
            setIsLoading(false);
            resetDocumentUploadForm();
          } else {
            swal("Error!", t(response.MessageCode), "error");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const resetDocumentUploadForm = () => {
    formikUserDocuments.resetForm();
    setDocumentFile(null);
    setFileKeyDocument(Math.random().toString(9).substring(2, 9));
  };

  const delete_UserDocument = (UserDocumentID, UserID) => {
    swal({
      title: "Are you sure you want to delete the user document?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_UserDocumentConfirm(UserDocumentID, UserID);
      }
    });
  };

  const delete_UserDocumentConfirm = async (UserDocumentID, UserID) => {
    setIsLoading(true);
    await post(POST_UserDocumentDelete, {
      UserIDEncrypt: UserID,
      UserCheckListDocumentIDEncrypted: UserDocumentID,
      EncryptModifiedBy: AuthUser.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setUserDocumentList([...response.UserDocumentList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={`${UserIDEncryptParam ? "Edit" : "Add"} User`}
          motherMenu={<Link to="/user-list">Users</Link>}
        />

        <div className="custom-tab-1">
          <Tab.Container activeKey={tab}>
            <Nav as="ul" className="nav-tabs">
              <Nav.Item as="li" key={1}>
                <Nav.Link eventKey={1} onClick={() => setTab(1)}>
                  {"User Information"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={2}>
                <Nav.Link
                  eventKey={2}
                  disabled={UserIDEncrypt ? false : true}
                  onClick={() => setTab(2)}
                >
                  {"User Qualification"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={3}>
                <Nav.Link
                  eventKey={3}
                  disabled={UserIDEncrypt ? false : true}
                  onClick={() => setTab(3)}
                >
                  {"User Documents"}
                </Nav.Link>
              </Nav.Item>
              {UserType !== 1 ? (
                <Nav.Item as="li" key={4}>
                  <Nav.Link
                    eventKey={4}
                    disabled={UserIDEncrypt ? false : true}
                    onClick={() => setTab(4)}
                  >
                    {"User Form Rights"}
                  </Nav.Link>
                </Nav.Item>
              ) : (
                ""
              )}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={1} key={1} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                          {AuthUser?.UserType === 1 ? (
                            <>
                              <Row>
                                <Col lg={3}>
                                  <Field
                                    label={"User Type"}
                                    name="UserType"
                                    placeholder={"Select User Type"}
                                    options={optionUserType}
                                    component={CustomSelect}
                                    // onChange={(event) => {
                                    //   setFieldValue(
                                    //     "UserType",
                                    //     event == null ? 0 : event?.value
                                    //   );
                                    //   setMode(event?.value);
                                    // }}
                                    isDisabled={UserIDEncrypt ? true : false}
                                    required
                                  />
                                </Col>
                                {AuthUser?.CompanyType === 1 &&
                                AuthUser?.UserType == 1 ? (
                                  <Col lg={6}>
                                    <Field
                                      label={"Company"}
                                      name="CompanyID"
                                      placeholder={"Select Company"}
                                      options={companyOptions}
                                      component={CustomSelect}
                                      required
                                      isDisabled={UserIDEncrypt ? true : false}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                              <hr></hr>
                            </>
                          ) : (
                            ""
                          )}
                          <Row>
                            {UserIDEncryptParam ? (
                              <Col lg={3}>
                                <Field
                                  label={"User No."}
                                  name="UserNo"
                                  component={CustomInput}
                                  disabled={true}
                                  readOnly={true}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"First Name"}
                                name="FirstName"
                                placeholder={"Enter First Name"}
                                component={CustomInput}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Last Name"}
                                name="LastName"
                                placeholder={"Enter Last Name"}
                                component={CustomInput}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Date of Birth"}
                                name="DateOfBirth"
                                placeholder={"Select Date of Birth"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                  maxDate: new Date(),
                                }}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Gender"}
                                name="Gender"
                                options={optionGender}
                                placeholder={"Select Gender"}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Marital Status"}
                                name="MaritalStatus"
                                options={optionMaritalStatus}
                                placeholder={"Select Marital Status"}
                                component={CustomSelect}
                                required
                              />
                            </Col>

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Father Name"}
                                name="FatherName"
                                placeholder={"Enter Father Name"}
                                component={CustomInput}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Mother Name"}
                                name="MotherName"
                                placeholder={"Enter Mother Name"}
                                component={CustomInput}
                                required
                              />
                            </Col>
                            {MaritalStatus === 2 ? (
                              <Col lg={3}>
                                <Field
                                  type="text"
                                  label={"Spouse Name"}
                                  name="SpouseName"
                                  placeholder={"Enter Spouse Name"}
                                  component={CustomInput}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Religion"}
                                name="Religion"
                                options={religionOptions}
                                placeholder={"Select Religion"}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row>
                            <Col lg={3}>
                              <Field
                                type="email"
                                label={"EmailID"}
                                name="EmailID"
                                placeholder={"Enter EmailID"}
                                component={CustomInput}
                                //required
                                disabled={UserIDEncrypt ? true : false}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Primary Mobile No."}
                                name="PrimaryContactNumber"
                                placeholder={"Enter Primary Mobile No."}
                                country={countryPrimary}
                                countryCode={CountryCodePrimary}
                                component={CustomPhoneInput}
                                onChange={(
                                  value,
                                  { countryCode, dialCode }
                                ) => {
                                  setFieldValue("CountryCodePrimary", dialCode);
                                  setFieldValue(
                                    "PrimaryContactNumber",
                                    value.substring(dialCode.toString().length)
                                  );
                                }}
                                required
                                disabled={UserIDEncrypt ? true : false}
                              />
                            </Col>
                            {/* <Col lg={3}>
                              <Field
                                type="text"
                                label={"Secondary Mobile No."}
                                name="SecondaryContactNumber"
                                placeholder={"Enter Secondary Mobile No."}
                                country={countrySecondary}
                                countryCode={CountryCodeSecondary}
                                component={CustomPhoneInput}
                                onChange={(
                                  value,
                                  { countryCode, dialCode }
                                ) => {
                                  setFieldValue(
                                    "CountryCodeSecondary",
                                    dialCode
                                  );
                                  setFieldValue(
                                    "SecondaryContactNumber",
                                    value.substring(dialCode.toString().length)
                                  );
                                }}
                              />
                            </Col> */}
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Emergency Mobile No."}
                                name="EmergencyContactNumber"
                                placeholder={"Enter Emergency Mobile No."}
                                country={countryEmergency}
                                countryCode={CountryCodeEmergency}
                                component={CustomPhoneInput}
                                onChange={(
                                  value,
                                  { countryCode, dialCode }
                                ) => {
                                  setFieldValue(
                                    "CountryCodeEmergency",
                                    dialCode
                                  );
                                  setFieldValue(
                                    "EmergencyContactNumber",
                                    value.substring(dialCode.toString().length)
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"Date of Joining"}
                                name="DateOfJoining"
                                placeholder={"Select Date of Joining"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                                required
                              />
                            </Col>
                            {UserIDEncryptParam ? (
                              <Col lg={3}>
                                <Field
                                  type="text"
                                  label={"Date of Leaving"}
                                  name="DateOfLeaving"
                                  placeholder={"Select Date of Leaving"}
                                  component={CustomFlatpickr}
                                  options={{
                                    altInput: true,
                                    altFormat: "j-M-Y",
                                    dateFormat: "d-m-Y",
                                  }}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={3}>
                              <div
                                className="custom-control custom-checkbox mb-3 checkbox-success"
                                style={{ marginTop: "35px", zIndex: 0 }}
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheckBox3"
                                  checked={IsEquipmentRights}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "IsEquipmentRights",
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckBox3"
                                >
                                  Equipment Booking Rights
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row className="mb-3">
                            <Col lg={6}>
                              <Field
                                type="textarea"
                                label={"Address"}
                                name="Address"
                                placeholder={"Enter Address"}
                                rows={3}
                                component={CustomInput}
                                required
                                style={{ resize: "none" }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"State"}
                                name="StateID"
                                options={stateOptions}
                                placeholder={"Select State"}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"City"}
                                name="CityID"
                                options={cityOptions}
                                placeholder={"Select City"}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                          </Row>

                          <button
                            type="submit"
                            className="btn mr-2 btn-primary btn-sm"
                          >
                            Submit
                          </button>
                          {UserIDEncrypt ? (
                            <button
                              type="button"
                              className="btn mr-2 btn-primary btn-sm"
                              onClick={() => {
                                setTab(2);
                              }}
                            >
                              Next
                            </button>
                          ) : (
                            ""
                          )}
                        </Form>
                      </FormikProvider>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={2} key={2} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive ">
                          <div className="dataTables_wrapper no-footer">
                            <table
                              className="table verticle-middle table-responsive-md mb-3"
                              style={{ width: "60%" }}
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Qualification: activate to sort column ascending"
                                    style={{
                                      width: "80%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Qualification
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "20%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {userQualificationList?.length > 0 ? (
                                  userQualificationList?.map((item, index) => (
                                    <tr role="row">
                                      <td>{item.QualificationName}</td>
                                      <td>
                                        <div className="d-flex">
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="View"
                                            onClick={() => {
                                              window.open(
                                                item.FilePath,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp"
                                            title="Delete"
                                            onClick={() => {
                                              delete_UserQualification(
                                                item.UserQualificationIDEncrypt,
                                                item.UserIDEncrypt
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                            ></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <div className="mt-3 ml-3 mb-3">
                                    {"No Record Found"}
                                  </div>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-sm btn-rounded mb-2"
                          onClick={() => {
                            resetQualificationUploadForm();
                            setQualificationModal(true);
                          }}
                        >
                          + Add Qualification
                        </button>
                      </Col>
                    </Row>
                    <br></br>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(1);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(3);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={3} key={3} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive ">
                          <div className="dataTables_wrapper no-footer">
                            <table
                              className="table verticle-middle table-responsive-md mb-3"
                              style={{ width: "60%" }}
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Document Name: activate to sort column ascending"
                                    style={{
                                      width: "80%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Document Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "20%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDocumentList?.length > 0 ? (
                                  userDocumentList?.map((item, index) => (
                                    <tr role="row">
                                      <td>{item.DocumentName}</td>
                                      <td>
                                        <div className="d-flex">
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="View"
                                            onClick={() => {
                                              window.open(item.Path, "_blank");
                                            }}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp"
                                            title="Delete"
                                            onClick={() => {
                                              delete_UserDocument(
                                                item.UserCheckListDocumentIDEncrypted,
                                                UserIDEncrypt
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                            ></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <div className="mt-3 ml-3 mb-3">
                                    {"No Record Found"}
                                  </div>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-sm btn-rounded mb-2"
                          onClick={() => {
                            resetDocumentUploadForm();
                            setDocumentModal(true);
                          }}
                        >
                          + Add Document
                        </button>
                      </Col>
                    </Row>
                    <br></br>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(2);
                      }}
                    >
                      Previous
                    </button>
                    {UserType !== 1 ? (
                      <button
                        type="submit"
                        className="btn mr-2 btn-primary btn-sm"
                        onClick={() => {
                          setTab(4);
                        }}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn mr-2 btn-light btn-sm"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Close
                      </button>
                    )}
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={4} key={4} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <Row className="mb-2">
                      <Col lg={12} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-xs btn-rounded"
                          onClick={() => setCopyUserRightsModal(true)}
                        >
                          <i className="fa fa-copy"></i>&nbsp; Copy Rights From
                          Another User
                        </button>
                      </Col>
                    </Row>
                    <FormikProvider value={formikUserRights}>
                      <Form onSubmit={formikUserRights.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <div className="table-responsive ">
                              <div className="dataTables_wrapper no-footer">
                                <table
                                  className="table verticle-middle table-responsive-md mb-3"
                                  style={{ width: "80%" }}
                                >
                                  <thead className="thead-light">
                                    <tr role="row">
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Forms: activate to sort column ascending"
                                        style={{
                                          width: "50%",
                                          padding: "10px 20px",
                                        }}
                                      >
                                        Forms
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Actions: activate to sort column ascending"
                                        style={{
                                          width: "50%",
                                          padding: "10px 30px",
                                        }}
                                      >
                                        Assign Role
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ListUserFormRights?.map((item, index) => (
                                      <>
                                        <tr>
                                          <td>{item.FormName}</td>
                                          <td>
                                            <div className="d-flex">
                                              {item.IsDisplayAddAction ==
                                              true ? (
                                                <>
                                                  <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                    <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      id={`Add_${index}`}
                                                      checked={item.AddAction}
                                                      onChange={(e) => {
                                                        formikUserRights.setFieldValue(
                                                          `ListUserFormRights.[${index}].AddAction`,
                                                          e.target.checked
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`Add_${index}`}
                                                    >
                                                      Add
                                                    </label>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {item.IsDisplayEditAction ==
                                              true ? (
                                                <>
                                                  <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                    <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      id={`Edit_${index}`}
                                                      checked={item.EditAction}
                                                      onChange={(e) => {
                                                        formikUserRights.setFieldValue(
                                                          `ListUserFormRights.[${index}].EditAction`,
                                                          e.target.checked
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`Edit_${index}`}
                                                    >
                                                      Edit
                                                    </label>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {item.IsDisplayDeleteAction ==
                                              true ? (
                                                <>
                                                  <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                    <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      id={`Delete_${index}`}
                                                      checked={
                                                        item.DeleteAction
                                                      }
                                                      onChange={(e) => {
                                                        formikUserRights.setFieldValue(
                                                          `ListUserFormRights.[${index}].DeleteAction`,
                                                          e.target.checked
                                                        );
                                                      }}
                                                    />

                                                    <Label
                                                      className="custom-control-label"
                                                      htmlFor={`Delete_${index}`}
                                                    >
                                                      Delete
                                                    </Label>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {item.IsDisplayViewAction ==
                                              true ? (
                                                <>
                                                  <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                    <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      id={`View_${index}`}
                                                      checked={item.ViewAction}
                                                      onChange={(e) => {
                                                        formikUserRights.setFieldValue(
                                                          `ListUserFormRights.[${index}].ViewAction`,
                                                          e.target.checked
                                                        );
                                                      }}
                                                    />

                                                    <Label
                                                      className="custom-control-label"
                                                      htmlFor={`View_${index}`}
                                                    >
                                                      View
                                                    </Label>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {item.IsInvoiceDiscount ==
                                              true ? (
                                                <>
                                                  <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                    <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      id={`Discount_${index}`}
                                                      checked={
                                                        item.InvoiceDiscountAction
                                                      }
                                                      onChange={(e) => {
                                                        formikUserRights.setFieldValue(
                                                          `ListUserFormRights.[${index}].InvoiceDiscountAction`,
                                                          e.target.checked
                                                        );
                                                      }}
                                                    />

                                                    <Label
                                                      className="custom-control-label"
                                                      htmlFor={`Discount_${index}`}
                                                    >
                                                      Discount
                                                    </Label>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <br></br>
                        <button
                          type="submit"
                          className="btn mr-2 btn-primary btn-sm"
                        >
                          Submit
                        </button>
                        {UserIDEncryptParam ? (
                          <button
                            type="button"
                            className="btn mr-2 btn-light btn-sm"
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            Close
                          </button>
                        ) : (
                          ""
                        )}
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <Modal
            className="modal fade"
            show={qualificationModal}
            onHide={setQualificationModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikUserQualification}>
                <Form onSubmit={formikUserQualification.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">User Qualification</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setQualificationModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          <Col lg={12}>
                            <Field
                              name={"QualificationID"}
                              label={"Qualification"}
                              placeholder="Select Qualification"
                              component={CustomSelect}
                              options={qualificationOptions}
                            />
                          </Col>
                          <Col lg={12}>
                            <Label>Upload Document</Label>
                            <div className="input-group mb-3">
                              <div
                                className="custom-file"
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type="file"
                                  id="fileControlQualification"
                                  name="fileControlQualification"
                                  className="custom-file-input"
                                  onChange={(e) => {
                                    uploadQualificationFile(e);
                                  }}
                                  key={fileKeyQualification || ""}
                                  required={qualificationFile ? false : true}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="fileControlQualification"
                                >
                                  {qualificationFile
                                    ? qualificationFile?.name
                                    : "Choose file"}
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary btn-sm">
                      Upload
                    </button>
                    <button
                      type="button"
                      onClick={() => setQualificationModal(false)}
                      className="btn btn-light btn-sm"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={documentModal}
            onHide={setDocumentModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikUserDocuments}>
                <Form onSubmit={formikUserDocuments.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">User Document</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setDocumentModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          <Col lg={12}>
                            <Field
                              name={"DocumentID"}
                              label={"Document"}
                              placeholder="Select Document"
                              component={CustomSelect}
                              options={documentOptions}
                            />
                          </Col>
                          <Col lg={12}>
                            <Label>Upload Document</Label>
                            <div className="input-group mb-3">
                              <div
                                className="custom-file"
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type="file"
                                  id="fileControlDocument"
                                  name="fileControlDocument"
                                  className="custom-file-input"
                                  onChange={(e) => {
                                    uploadDocumentFile(e);
                                  }}
                                  key={fileKeyDocument || ""}
                                  required={documentFile ? false : true}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="fileControlDocument"
                                >
                                  {documentFile
                                    ? documentFile?.name
                                    : "Choose file"}
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary btn-sm">
                      Upload
                    </button>
                    <button
                      type="button"
                      onClick={() => setDocumentModal(false)}
                      className="btn btn-light btn-sm"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={copyUserRightsModal}
            onHide={setCopyUserRightsModal}
            size="lg"
          >
            <div className="">
              <div className="">
                <FormikProvider value={formikCopyUserRights}>
                  <Form onSubmit={formikCopyUserRights.handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Copy User Rights</h4>
                      <button
                        type="button"
                        className="btn close"
                        onClick={() => setCopyUserRightsModal(false)}
                      >
                        <span>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <Row>
                            {AuthUser?.CompanyType === 1 &&
                            AuthUser?.UserType == 1 ? (
                              <Col lg={12}>
                                <Field
                                  label={"Company"}
                                  name="CopyFromCompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  required
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={12}>
                              <Field
                                name={"CopyUserLoginID"}
                                label={"User"}
                                placeholder="Select User"
                                component={CustomSelect}
                                options={userOptions}
                                required
                              />
                            </Col>
                          </Row>
                          {ListCopyUserFormRights?.length > 0 ? (
                            <>
                              <hr></hr>
                              <Row>
                                <Col lg={12}>
                                  <div className="table-responsive">
                                    <div className="dataTables_wrapper no-footer">
                                      <table
                                        className="table verticle-middle table-responsive-md mb-3"
                                        // style={{ width: "80%" }}
                                      >
                                        <thead className="thead-light">
                                          <tr role="row">
                                            <th
                                              className="sorting"
                                              tabIndex={0}
                                              aria-controls="example5"
                                              rowSpan={1}
                                              colSpan={1}
                                              aria-label="Forms: activate to sort column ascending"
                                              style={{
                                                width: "50%",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Forms
                                            </th>
                                            <th
                                              className="sorting"
                                              tabIndex={0}
                                              aria-controls="example5"
                                              rowSpan={1}
                                              colSpan={1}
                                              aria-label="Actions: activate to sort column ascending"
                                              style={{
                                                width: "50%",
                                                padding: "10px 30px",
                                              }}
                                            >
                                              Assign Role
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {ListCopyUserFormRights?.map(
                                            (item, index) => (
                                              <>
                                                <tr>
                                                  <td>{item.FormName}</td>
                                                  <td>
                                                    <div className="d-flex">
                                                      {item.IsDisplayAddAction ==
                                                      true ? (
                                                        <>
                                                          <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                            <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              id={`cAdd_${index}`}
                                                              checked={
                                                                item.AddAction
                                                              }
                                                              disabled
                                                            />
                                                            <label
                                                              className="custom-control-label"
                                                              htmlFor={`cAdd_${index}`}
                                                            >
                                                              Add
                                                            </label>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {item.IsDisplayEditAction ==
                                                      true ? (
                                                        <>
                                                          <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                            <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              id={`cEdit_${index}`}
                                                              checked={
                                                                item.EditAction
                                                              }
                                                              disabled
                                                            />
                                                            <label
                                                              className="custom-control-label"
                                                              htmlFor={`cEdit_${index}`}
                                                            >
                                                              Edit
                                                            </label>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {item.IsDisplayDeleteAction ==
                                                      true ? (
                                                        <>
                                                          <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                            <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              id={`cDelete_${index}`}
                                                              checked={
                                                                item.DeleteAction
                                                              }
                                                              disabled
                                                            />

                                                            <Label
                                                              className="custom-control-label"
                                                              htmlFor={`cDelete_${index}`}
                                                            >
                                                              Delete
                                                            </Label>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {item.IsDisplayViewAction ==
                                                      true ? (
                                                        <>
                                                          <div className="custom-control custom-checkbox mb-3 checkbox-success mr-3">
                                                            <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              id={`cView_${index}`}
                                                              checked={
                                                                item.ViewAction
                                                              }
                                                              disabled
                                                            />

                                                            <Label
                                                              className="custom-control-label"
                                                              htmlFor={`cView_${index}`}
                                                            >
                                                              View
                                                            </Label>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary btn-sm">
                        Copy
                      </button>
                      <button
                        type="button"
                        onClick={() => setCopyUserRightsModal(false)}
                        className="btn btn-light btn-sm"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddUser;
