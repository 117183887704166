import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, post } from "../../../Helper/api_helper";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import swal from "sweetalert";
import { t } from "i18next";
import moment from "moment";
import {
  GET_CompanyDropDownList,
  POST_AdminVoucherSeriesDetailByID,
  POST_AdminVoucherSeriesInsert,
  POST_AdminVoucherSeriesUpdate,
} from "../../../Helper/url_helper";
import { Spin } from "antd";

const AddVoucherSeries = () => {
  const { VoucherSeriesIDEncryptParam } = useParams();
  const history = useHistory();
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);

  const validationSchema = Yup.object().shape({
    CompanyID: Yup.string().nullable().required("Company is required field"),
    TranType: Yup.string()
      .nullable()
      .required("Transaction Type is required field"),
    Prefix: Yup.string().nullable().required("Prefix is required field"),
    StartFrom: Yup.string().nullable().required("Start From is required field"),
    EffectiveDate: Yup.string()
      .nullable()
      .required("Effective Date is required field"),
    NosLength: Yup.number().when("ZeroPadding", {
      is: (ZeroPadding) => ZeroPadding === true,
      then: Yup.number()
        .nullable()
        .min(1, "Nos Length is required Field")
        .required("Nos Length is required Field"),
      otherwise: Yup.number().nullable(),
      ZeroPadding: Yup.boolean(),
    }),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      VoucherSeriesIDEncrypt: VoucherSeriesIDEncryptParam
        ? window.atob(VoucherSeriesIDEncryptParam)
        : "",
      CompanyID: AuthUser?.CompanyIDEncrypt,
      TranType: "",
      Prefix: "",
      StartFrom: "",
      Suffix: "",
      NosLength: "",
      EffectiveDate: "",
      ZeroPadding: true,
      Status: true,
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: { ZeroPadding, VoucherSeriesIDEncrypt, CompanyID, EffectiveDate },
  } = formik;

  useEffect(() => {
    loadCompanyDDL();
    if (VoucherSeriesIDEncryptParam) {
      setIsLoading(true);
      post(POST_AdminVoucherSeriesDetailByID, {
        VoucherSeriesIDEncrypted: window.atob(VoucherSeriesIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            ...res,
            VoucherSeriesIDEncrypt: res.VoucherSeriesIDEncrypt,
            ZeroPadding: res.ZeroPadding,
            CompanyID: res.CompanyIDEncrypt,
            Status: res.IsActive,
            TranType: res.TranType,
            EffectiveDate: res.EffectiveDate ? new Date(res.EffectiveDate) : "",
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!EffectiveDate) {
      setFieldValue("EffectiveDate", "");
    }
  }, [EffectiveDate]);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const TranTypeOption = [
    { label: "Order", value: 1 },
    { label: "Enquiry", value: 2 },
    { label: "Complaints", value: 3 },
    { label: "Invoice", value: 4 },
    { label: "Advance", value: 5 },
    { label: "Deposit", value: 6 },
    { label: "Invoice Receipt", value: 7 },
  ];

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      ...values,
      IsActive: values.Status,
      ModifiedByEncrypt: AuthUser?.LoginIDEncrypt,
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
      CompanyIDEncrypt: CompanyID,
      EffectiveDate: `${moment(values.EffectiveDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      NosLength: ZeroPadding === true ? values.NosLength : "0",
    };

    post(
      VoucherSeriesIDEncryptParam
        ? POST_AdminVoucherSeriesUpdate
        : POST_AdminVoucherSeriesInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/voucher-series-list");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            VoucherSeriesIDEncryptParam
              ? "Edit Voucher Series"
              : "Add Voucher Series"
          }
          motherMenu={<Link to="/voucher-series-list">Voucher Series</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      {AuthUser?.CompanyType === 1 &&
                      AuthUser?.UserType == 1 ? (
                        <>
                          <Row>
                            <Col lg={6}>
                              <Field
                                label={"Company"}
                                name="CompanyID"
                                placeholder={"Select Company"}
                                options={companyOptions}
                                component={CustomSelect}
                                required
                                isClearable={false}
                                isDisabled={
                                  VoucherSeriesIDEncrypt ? true : false
                                }
                              />
                            </Col>
                          </Row>
                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}

                      <Row className="mb-2">
                        <Col lg={4}>
                          <Field
                            label={"Transaction Type"}
                            name="TranType"
                            placeholder={"Select Transaction Type"}
                            options={TranTypeOption}
                            component={CustomSelect}
                            required
                            isClearable={false}
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"Prefix"}
                            name="Prefix"
                            id="Prefix"
                            placeholder={"Enter Prefix"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "Prefix",
                                e.target.value
                                  .replace(/[^A-Za-z]/g, "")
                                  ?.toUpperCase()
                              );
                            }}
                            style={{ textTransform: "Uppercase" }}
                            maxLength={3}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Start From"}
                            name="StartFrom"
                            id="StartFrom"
                            placeholder={"Enter Start From"}
                            component={CustomInput}
                            min={0}
                            onChange={(e) => {
                              setFieldValue(
                                "StartFrom",
                                e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 9)
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"Suffix"}
                            name="Suffix"
                            id="Suffix"
                            placeholder={"Enter Suffix"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "Suffix",
                                e.target.value
                                  .replace(/[^A-Za-z]/g, "")
                                  ?.toUpperCase()
                              );
                            }}
                            style={{ textTransform: "Uppercase" }}
                            maxLength={3}
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Nos Length"}
                            name="NosLength"
                            id="NosLength"
                            placeholder={"Enter Nos Length"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "NosLength",
                                e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 1)
                              );
                            }}
                            required={ZeroPadding === true ? true : false}
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Effective Date"}
                            name="EffectiveDate"
                            placeholder={"Select Effective Date"}
                            component={CustomFlatpickr}
                            options={{
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <div
                            className="custom-control custom-checkbox mb-3 checkbox-success"
                            style={{ zIndex: 0 }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked
                              className="custom-control-input"
                              id="ZeroPadding"
                              name="ZeroPadding"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "ZeroPadding",
                                  e.target.checked
                                );
                              }}
                              checked={ZeroPadding}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="ZeroPadding"
                            >
                              Is Zero Padding
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddVoucherSeries;
