import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik, FieldArray } from "formik";
import { Form, InputGroup, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Tab, Nav, Button, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { Spin } from "antd";
import CustomInput from "../../../Custom/CustomInput";
import {
  GET_ExpenseDDLList,
  //POST_AdminGetYearDDLList,
  POST_AdminInvoiceInsert,
  POST_AdminOrderBookingInvoiceReceiptInsert,
  POST_GenerateOTPForUserChangeEmailOrMobile,
  POST_GetModeOfPaymentDDL,
  POST_GetPatientListForInvoice,
  POST_GetPendingOrderListForInvoiceGenerate,
  POST_GetPreGenerateInvoiceDetail,
  POST_InvoiceDownload,
  POST_InvoiceReceiptDownload,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import moment from "moment";
import { t } from "i18next";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const AddInvoice = () => {
  const history = useHistory();
  const [advanceDetailModal, setAdvanceDetailModal] = useState(false);
  const [confirmGenerateModal, setConfirmGenerateModal] = useState(false);
  const [advancePayModal, setAdvancePayModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const [showDetailsButton, setShowDetailsButton] = useState(false);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [patientOptions, setPatientOptions] = useState([]);
  //const [yearOptions, setYearOptions] = useState([]);
  const [expenseOptions, setExpenseOptions] = useState([]);
  const [modeOfPaymentOptions, setModeOfPaymentOptions] = useState([]);
  //const [selectedOrderBookingIDs, setSelectedOrderBookingIDs] = useState("");

  const loadPatientDDL = async () => {
    await post(POST_GetPatientListForInvoice, {
      CompanyIDEncrypted: AuthUser?.CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setPatientOptions(
            res.ListOfPatient.map((item) => ({
              label: item.PatientName + " - " + item.PatientNo,
              value: item.PatientIDEncrypted,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  // const monthOptions = [
  //   { label: "January", value: 1 },
  //   { label: "February", value: 2 },
  //   { label: "March", value: 3 },
  //   { label: "April", value: 4 },
  //   { label: "May", value: 5 },
  //   { label: "June", value: 6 },
  //   { label: "July", value: 7 },
  //   { label: "August", value: 8 },
  //   { label: "September", value: 9 },
  //   { label: "October", value: 10 },
  //   { label: "November", value: 11 },
  //   { label: "December", value: 12 },
  // ];

  // const getYearDDLList = () => {
  //   setIsLoading(true);
  //   post(POST_AdminGetYearDDLList, {
  //     CompanyIDEncrypt: AuthUser?.CompanyIDEncrypt,
  //   })
  //     .then((res) => {
  //       if (res.IsSuccess) {
  //         setYearOptions(
  //           res.AdminGetYearDDLList.map((item) => ({
  //             label: item.YearName,
  //             value: item.YearName,
  //           }))
  //         );
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => console.log(error), setIsLoading(false));
  // };

  const getExpenseDDLList = () => {
    setIsLoading(true);
    get(GET_ExpenseDDLList)
      .then((res) => {
        if (res.IsSuccess) {
          setExpenseOptions(
            res.ExpensesDDLList.map((item) => ({
              label: item.ExpenseName,
              value: item.ExpenseIDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const loadModeOfPaymentOptionsDDL = () => {
    post(POST_GetModeOfPaymentDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setModeOfPaymentOptions(
            res.ModeOfPaymentList.map((item) => ({
              label: item.ModeOfPayment,
              value: item.ModeOfPaymentID,
              isCheque: item.IsCheque,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadPatientDDL();
    //getYearDDLList();
    getExpenseDDLList();
    loadModeOfPaymentOptionsDDL();
  }, []);

  const validationSchemaGetOrders = Yup.object().shape({
    PatientIDEncrypted: Yup.string().required("Patient is required field"),
    // Month: Yup.string().required("Month is required field"),
    // Year: Yup.string().required("Year is required field"),
  });
  const validationSchema = Yup.object().shape({});

  const formikGetOrders = useFormik({
    validationSchema: validationSchemaGetOrders,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitGetOrders(values, formikHelpers),
    initialValues: {
      PatientIDEncrypted: "",
      Month: "0",
      Year: "0",
    },
    validateOnBlur: false,
  });

  const {
    values: { PatientIDEncrypted, Month, Year },
  } = formikGetOrders;

  useEffect(() => {
    formikPendingOrders.resetForm();
    formikGenerateInvoice.resetForm();
    //formikPayBill.resetForm();
  }, [PatientIDEncrypted, Month, Year]);

  const GetPendingOrderListForInvoiceGenerate = () => {
    setIsLoading(true);
    setTimeout(() => {
      post(POST_GetPendingOrderListForInvoiceGenerate, {
        Month: Month,
        Year: Year,
        PatientIDEncrypted: PatientIDEncrypted,
        CompanyIDEncrypted: AuthUser?.CompanyIDEncrypt,
      })
        .then((response) => {
          if (response?.IsSuccess) {
            formikPendingOrders.setFieldValue(
              "ListOfPendingOrders",
              response?.ListOfOrders?.map((x) => {
                return { ...x, IsSelected: false };
              })
            );
            formikGenerateInvoice.resetForm();
          }
          setIsLoading(false);
        })
        .catch((error) => console.log(error), setIsLoading(false));
    }, 100);
  };

  const handleValidSubmitGetOrders = () => {
    GetPendingOrderListForInvoiceGenerate();
  };

  const formikPendingOrders = useFormik({
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitGetSelectedOrdersData(values, formikHelpers),
    initialValues: {
      ListOfPendingOrders: [],
    },
    validateOnBlur: false,
  });

  const {
    values: { ListOfPendingOrders },
  } = formikPendingOrders;

  const handleValidSubmitGetSelectedOrdersData = () => {
    if (ListOfPendingOrders?.filter((x) => x.IsSelected)?.length > 0) {
      setIsLoading(true);
      setTimeout(() => {
        let selectedOrdersID = [];
        ListOfPendingOrders?.filter((x) => x.IsSelected)?.map((x) => {
          selectedOrdersID.push(x.OrderBookingIDEncrypted);
        });

        post(POST_GetPreGenerateInvoiceDetail, {
          Month: Month,
          Year: Year,
          OrderBookingIDEncrypted: selectedOrdersID.join(","),
        })
          .then((response) => {
            if (response?.IsSuccess) {
              formikGenerateInvoice.setFieldValue(
                "OrderBookingInvoiceDetailsList",
                response.ListOfService?.map((x) => {
                  return {
                    ...x,
                    NoOfDays: x.TotalHours,
                    ReferenceType: x.ReferenceTypeID,
                    DiscountAmount: "0.00",
                  };
                })
              );
              formikGenerateInvoice.setFieldValue(
                "OrderBookingInvoiceDetailsGSTList",
                response.ListOfGSTPlanDetails?.map((x) => {
                  return { ...x, GSTPercentage: parseFloat(x.GSTPercentage) };
                })
              );
              formikGenerateInvoice.setFieldValue(
                "ListOfAdvanceBalanceDetail",
                response.ListOfAdvanceBalanceDetail
              );
              formikGenerateInvoice.setFieldValue(
                "AdvanceAmount",
                response?.AmountDetail?.AdvanceAmt
              );
              formikGenerateInvoice.setFieldValue(
                "GrossAmount",
                response?.AmountDetail?.GrossAmount
              );
              formikGenerateInvoice.setFieldValue(
                "TaxAmount",
                response?.AmountDetail?.TaxAmount
              );
              formikGenerateInvoice.setFieldValue(
                "NetAmount",
                response?.AmountDetail?.NetAmount
              );
              formikGenerateInvoice.setFieldValue("DiscountAmount", "0.00");
              formikGenerateInvoice.setFieldValue(
                "TransportationCharges",
                response?.AmountDetail?.TransportationCharges
              );
              formikGenerateInvoice.setFieldValue(
                "OrderBookingInvoiceServiceExportList",
                response?.ListOfServiceExport?.map((x) => {
                  return {
                    "Order Booking No": x?.OrderBookingNo,
                    Type: x?.ReferenceType,
                    "Service Name": x?.ServiceName,
                    Date: x?.ServiceDate,
                    Hours: x?.TotalHours,
                    Amount: x?.Rate,
                    "Staff Name": x?.StaffName,
                    "Staff Punch In": x?.StaffPunchIn,
                    "Staff Punch Out": x?.StaffPunchOut,
                  };
                })
              );
            }
            setIsLoading(false);
          })
          .catch((error) => console.log(error), setIsLoading(false));
      }, 100);
    } else {
      swal("", "Please select orders...", "warning");
    }
  };

  const initOrderBookingInvoiceAddtionalsList = {
    ExpenseIDEncrypt: "",
    Remarks: "",
    TotalAmount: "",
    Qty: 1, //not in use
    Rate: "0.00", //not in use
  };

  const formikGenerateInvoice = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      InvoiceFromDate: "",
      InvoiceToDate: "",
      GrossAmount: "0.00",
      DiscountAmount: "0.00",
      NetAmount: "0.00",
      TaxAmount: "0.00",
      TotalPayableAmount: "0.00",
      AdvanceAmount: "0.00",
      Narration: "",
      CompanyIDEncrypt: AuthUser?.CompanyIDEncrypt,
      PatientIDEncrypt: PatientIDEncrypted,
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
      OrderBookingInvoiceDetailsList: [],
      OrderBookingInvoiceDetailsGSTList: [],
      OrderBookingInvoiceAddtionalsList: [
        //initOrderBookingInvoiceAddtionalsList,
      ],
      ListOfAdvanceBalanceDetail: [],
      TotalAdditionalAmount: 0,
      OrderBookingInvoiceIDEncrypted: "",
      FilePath: "",
      // AdvanceBalance: "",
      // Patient: "",
      // TotalPayable: "",
      // ModeOfPayment: "",
      // OrderRecordCheckBoxTwo: false,
      // OrderRecordCheckBoxOne: false,
      TransportationCharges: "0.00",
      disableSubmit: false,
      OrderBookingInvoiceServiceExportList: [],
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: {
      OrderBookingInvoiceDetailsList,
      OrderBookingInvoiceAddtionalsList,
      GrossAmount,
      TaxAmount,
      DiscountAmount,
      NetAmount,
      TotalAdditionalAmount,
      OrderBookingInvoiceIDEncrypted,
      AdvanceAmount,
      ListOfAdvanceBalanceDetail,
      // OrderRecordCheckBoxTwo,
      // OrderRecordCheckBoxOne,
      TransportationCharges,
      disableSubmit,
      OrderBookingInvoiceServiceExportList,
    },
  } = formikGenerateInvoice;

  useEffect(() => {
    let TotalAdditionalAmt = 0;
    let previousAdditionAmount = TotalAdditionalAmount;
    OrderBookingInvoiceAddtionalsList?.map((x) => {
      TotalAdditionalAmt =
        TotalAdditionalAmt + parseFloat(x.TotalAmount ? x.TotalAmount : 0);
    });
    formikGenerateInvoice.setFieldValue(
      "TotalAdditionalAmount",
      TotalAdditionalAmt
    );
    formikGenerateInvoice.setFieldValue(
      "GrossAmount",
      (
        parseFloat(GrossAmount) -
        parseFloat(previousAdditionAmount) +
        TotalAdditionalAmt
      ).toFixed(2)
    );
    formikGenerateInvoice.setFieldValue(
      "NetAmount",
      (
        parseFloat(NetAmount) -
        parseFloat(previousAdditionAmount) +
        TotalAdditionalAmt
      ).toFixed(2)
    );
  }, [OrderBookingInvoiceAddtionalsList]);

  useEffect(() => {
    let netAmt =
      parseFloat(GrossAmount) +
      parseFloat(TaxAmount) +
      parseFloat(TransportationCharges) -
      parseFloat(DiscountAmount ? DiscountAmount : 0);

    formikGenerateInvoice.setFieldValue(
      "NetAmount",
      (netAmt > 0 ? netAmt : 0).toFixed(2)
    );
  }, [DiscountAmount]);

  const handleValidSubmit = async (values, { setSubmitting }) => {
    if (!OrderBookingInvoiceIDEncrypted && disableSubmit == false) {
      formikGenerateInvoice.setFieldValue("disableSubmit", true);
      //after generate invoice prevent re-generate it
      if (
        OrderBookingInvoiceAddtionalsList?.filter(
          (x) => x.ExpenseIDEncrypt == "" || x.TotalAmount == ""
        ).length > 0
      ) {
        swal("Additional Expenses", "Please fill the details", "warning");
        formikGenerateInvoice.setFieldValue("disableSubmit", false);
        return;
      }

      let netAmt = parseFloat(GrossAmount) + parseFloat(TaxAmount);
      if (parseFloat(DiscountAmount) > parseFloat(netAmt)) {
        swal(
          "",
          "Discount Amount must be less then or equal to Net Amount",
          "warning"
        );
        formikGenerateInvoice.setFieldValue("disableSubmit", false);
        return;
      }

      setIsLoading(true);

      // setTimeout(() => {
      let payload = {
        ...values,
        InvoiceFromDate: moment(new Date(Year, Month - 1, 1))
          .format("YYYY-MM-DD")
          .replace(/^\-/, ""),
        InvoiceToDate: moment(new Date(Year, Month, 0))
          .format("YYYY-MM-DD")
          .replace(/^\-/, ""),
        PatientIDEncrypt: PatientIDEncrypted,
        OrderBookingInvoiceAddtionalsList:
          OrderBookingInvoiceAddtionalsList?.map((x) => {
            return { ...x, TotalAmount: x.TotalAmount.toString() };
          }),
      };

      delete payload["ListOfAdvanceBalanceDetail"];
      delete payload["OrderBookingInvoiceIDEncrypted"];
      delete payload["FilePath"];
      delete payload["disableSubmit"];
      // console.log("Payload: ", payload);

      post(POST_AdminInvoiceInsert, payload)
        .then((response) => {
          if (response?.IsSuccess) {
            formikGenerateInvoice.setFieldValue(
              "OrderBookingInvoiceIDEncrypted",
              response?.OrderBookingInvoiceIDEncrypted
            );
            formikGenerateInvoice.setFieldValue("FilePath", response?.FilePath);

            if (parseFloat(TotalPayable) == 0) {
              let payloadPayBill = {
                PaidAmount: "0",
                SettleAmount: SettleAmount,
                ModeOfPaymentID: 0,
                Remark: "",
                IsSettleAgaisntAdvance: 1,
                OrderBookingInvoiceIDEncrypt:
                  response.OrderBookingInvoiceIDEncrypted,
                PaymentTransactionIDEncrypt: "",
                IsSettleAgaisntAdvance: 1,
                CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
                CompanyIDEncrypt: AuthUser?.CompanyIDEncrypt,
              };
              //console.log("payloadPayBill 0:", payloadPayBill);
              OrderBookingInvoiceReceiptInsert(payloadPayBill);
            } else {
              setIsLoading(false);
              setConfirmGenerateModal(true);
            }
          } else {
            swal("Error!", t(response.MessageCode), "error");
            setIsLoading(false);
          }
        })
        .catch((error) => console.log(error), setIsLoading(false));
      // }, 100);
    }
  };

  const downloadInvoice = async (OrderBookingInvoiceID) => {
    setIsLoading(true);

    await post(POST_InvoiceDownload, {
      OrderBookingInvoiceIDEncrypt: OrderBookingInvoiceID,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          window.open(response.FilePath);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let TotalPayableAmount = 0,
      SettledAmount = 0;
    if (parseFloat(NetAmount) > parseFloat(AdvanceAmount)) {
      SettledAmount = parseFloat(AdvanceAmount);
      TotalPayableAmount = parseFloat(NetAmount) - parseFloat(AdvanceAmount);
    } else {
      SettledAmount = parseFloat(NetAmount);
      TotalPayableAmount = 0;
    }
    formikPayBill.setFieldValue("InvoiceNetAmount", NetAmount);
    formikPayBill.setFieldValue("AdvanceBalance", AdvanceAmount);
    formikPayBill.setFieldValue("TotalPayable", TotalPayableAmount.toFixed(2));
    formikPayBill.setFieldValue("SettleAmount", SettledAmount.toFixed(2));
  }, [NetAmount]);

  const validationSchemaPayBill = Yup.object().shape({
    ModeOfPaymentID: Yup.string().required("Mode of Payment is required field"),
    PaidAmount: Yup.number()
      .min(1, "Paying Amount is required field")
      .required("Paying Amount is required field"),
  });

  const formikPayBill = useFormik({
    validationSchema: validationSchemaPayBill,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitPayBill(values, formikHelpers),
    initialValues: {
      AdvanceBalance: "0.00",
      InvoiceNetAmount: "0.00",
      TotalPayable: "",
      ModeOfPaymentID: "",
      SettleAmount: "",
      PaidAmount: "",
      Remarks: "",
      OrderBookingInvoiceReceiptIDEncrypted: "",
    },
    validateOnBlur: false,
  });

  const {
    values: {
      TotalPayable,
      SettleAmount,
      OrderBookingInvoiceReceiptIDEncrypted,
    },
  } = formikPayBill;

  const handleValidSubmitPayBill = (values, { setSubmitting }) => {
    let payload = {
      ...values,
      PaidAmount: values?.PaidAmount.toString(),
      OrderBookingInvoiceIDEncrypt: OrderBookingInvoiceIDEncrypted,
      PaymentTransactionIDEncrypt: "",
      IsSettleAgaisntAdvance: 1,
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
      CompanyIDEncrypt: AuthUser?.CompanyIDEncrypt,
    };
    delete payload["OrderBookingInvoiceReceiptIDEncrypted"];
    //console.log("PayBill Payload:", payload);
    OrderBookingInvoiceReceiptInsert(payload);
  };

  const OrderBookingInvoiceReceiptInsert = async (data) => {
    setIsLoading(true);
    await post(POST_AdminOrderBookingInvoiceReceiptInsert, data)
      .then((response) => {
        if (response?.IsSuccess) {
          formikPayBill.setFieldValue(
            "OrderBookingInvoiceReceiptIDEncrypted",
            response?.OrderBookingInvoiceReceiptIDEncrypted
          );
          if (data?.PaidAmount > 0) {
            formikPayBill.setFieldValue("TotalPayable", 0);
            setAdvancePayModal(false);
            swal("Success!", t(response.MessageCode), "success");
          } else {
            setConfirmGenerateModal(true);
          }
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const downloadInvoicePaymentReceipt = async (
    OrderBookingInvoiceReceiptID
  ) => {
    setIsLoading(true);

    await post(POST_InvoiceReceiptDownload, {
      OrderBookingInvoiceReceiptIDEncrypt: OrderBookingInvoiceReceiptID,
      IsOriginal: true,
      WithoutLetterHead: true,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          window.open(response.FilePath);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const exportToExcelServiceData = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(OrderBookingInvoiceServiceExportList);
    const headerColumnCount = 9; //No. of column header OrderBookingInvoiceServiceExportList

    // Define a style for the header row
    const headerStyle = {
      font: { bold: true, color: { rgb: "ffffff" } },
      fill: { fgColor: { rgb: "4f81bd" } },
    };

    // Apply the style to each cell in the header row
    for (let col = 0; col < headerColumnCount; col++) {
      const cellRef = XLSX.utils.encode_cell({ c: col, r: 0 });
      ws[cellRef].s = headerStyle;
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      "Invoice_OrderServices_" +
        moment(new Date()).format("YYYYMMDDhhmmss").replace(/^\-/, "") +
        fileExtension
    );
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={"Generate Invoice"}
          motherMenu={<Link to="/invoices">Invoices</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formikGetOrders}>
                    <Form onSubmit={formikGetOrders.handleSubmit}>
                      <Row>
                        <Col lg={5}>
                          <Field
                            label={"Patient"}
                            name="PatientIDEncrypted"
                            placeholder={"Select Patient"}
                            component={CustomSelect}
                            options={patientOptions}
                            required
                          />
                        </Col>

                        {/* <Col lg={3}>
                          <Field
                            label={"Month"}
                            name="Month"
                            placeholder={"Select Month"}
                            component={CustomSelect}
                            options={monthOptions}
                            required
                          />
                        </Col>
                        <Col lg={2}>
                          <Field
                            label={"Year"}
                            name="Year"
                            placeholder={"Select Year"}
                            component={CustomSelect}
                            options={yearOptions}
                            required
                          />
                        </Col> */}
                        <Col lg={2}>
                          <div className="mb-3">
                            <Label>&nbsp;</Label>
                            <InputGroup>
                              <button
                                type="submit"
                                className="btn mt-1 mr-2 btn-xs btn-primary"
                              >
                                Get Orders
                              </button>
                            </InputGroup>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                  <hr></hr>
                  <FormikProvider value={formikPendingOrders}>
                    <Form onSubmit={formikPendingOrders.handleSubmit}>
                      <Row>
                        <Col>
                          <div className="table-responsive ">
                            <div className="dataTables_wrapper no-footer">
                              <table className="table verticle-middle table-responsive-md mb-3">
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="select: activate to sort column ascending"
                                      style={{
                                        padding: "10px 20px",
                                      }}
                                    ></th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Order Booking No: activate to sort column ascending"
                                      style={{
                                        padding: "10px 30px",
                                      }}
                                    >
                                      Order Booking No
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Order Date: activate to sort column ascending"
                                      style={{
                                        padding: "10px 30px",
                                      }}
                                    >
                                      Order Date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Order Amount: activate to sort column ascending"
                                      style={{
                                        textAlign: "right",
                                        padding: "10px 30px",
                                      }}
                                    >
                                      Order Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListOfPendingOrders?.length > 0 ? (
                                    ListOfPendingOrders?.map((order, index) => (
                                      <tr>
                                        <td>
                                          <div
                                            className="custom-control custom-checkbox  checkbox-success"
                                            style={{
                                              zIndex: "0",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id={`select_${index}`}
                                              checked={order.IsSelected}
                                              // onChange={(e) => {
                                              //   setFieldValue(
                                              //     "OrderRecordCheckBoxOne",
                                              //     e.target.checked
                                              //   );
                                              //   if (e.target.checked) {
                                              //     setShowDetailsButton(true);
                                              //   } else if (
                                              //     OrderRecordCheckBoxTwo
                                              //   ) {
                                              //     setShowDetailsButton(true);
                                              //   } else {
                                              //     setShowDetailsButton(false);
                                              //   }
                                              // }}
                                              onChange={(e) => {
                                                formikPendingOrders.setFieldValue(
                                                  `ListOfPendingOrders.[${index}].IsSelected`,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={`select_${index}`}
                                            ></label>
                                          </div>
                                        </td>
                                        <td>{order.OrderBookingNo}</td>
                                        <td>{order.OrderBookingDate}</td>
                                        <td style={{ textAlign: "right" }}>
                                          <i className="fa fa-rupee"></i>{" "}
                                          {order.NetAmount}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="mt-3 ml-3 mb-3">
                                          {"No Record Found"}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* {showDetailsButton ? ( */}
                          <div style={{ textAlign: "right" }}>
                            <button
                              type="submit"
                              className="btn mt-1 mr-2 btn-xs btn-primary"
                            >
                              Get Order Details
                            </button>
                          </div>
                          {/* ) : (
                            ""
                          )} */}
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>

                  <FormikProvider value={formikGenerateInvoice}>
                    <Form onSubmit={formikGenerateInvoice.handleSubmit}>
                      {OrderBookingInvoiceDetailsList?.length > 0 ? (
                        <>
                          <hr></hr>
                          <Row>
                            <Col>
                              <Label>
                                <b>Order Details</b>
                              </Label>
                            </Col>
                          </Row>
                          {OrderBookingInvoiceDetailsList?.filter(
                            (x) => x.ReferenceType == 1
                          ).length > 0 ? (
                            <Row>
                              <Col>
                                <div className="table-responsive ">
                                  <div className="dataTables_wrapper no-footer">
                                    <table className="table verticle-middle table-responsive-md mb-3">
                                      <thead className="thead-light">
                                        <tr role="row">
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Order Booking No: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Order Booking No{" "}
                                            <Link
                                              to="#"
                                              title="Export Service Data"
                                              className="ml-2 text-success"
                                              onClick={() => {
                                                exportToExcelServiceData();
                                              }}
                                            >
                                              <i className="fa fa-file-excel-o"></i>
                                            </Link>
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Service Name: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Service Name
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Date: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                            }}
                                          >
                                            Date
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Hours: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                            }}
                                          >
                                            Hours
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount: activate to sort column ascending"
                                            style={{
                                              textAlign: "right",
                                              padding: "10px 30px",
                                            }}
                                          >
                                            Amount
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {OrderBookingInvoiceDetailsList?.filter(
                                          (x) => x.ReferenceType == 1
                                        )?.map((item) => (
                                          <tr>
                                            <td>{item.OrderBookingNo}</td>
                                            <td>
                                              {item.ServiceName}
                                              {item?.StaffName ? (
                                                <>
                                                  <br></br>
                                                  <span
                                                    style={{ fontSize: "9px" }}
                                                  >
                                                    ({item?.StaffName})
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td>{item.StaffPunchInDate}</td>
                                            <td>
                                              {parseInt(item.TotalHours) > 0
                                                ? item.TotalHours + " Hours"
                                                : "Per Visit"}{" "}
                                              {item?.TimeSlot ? (
                                                <>
                                                  <br></br>
                                                  <span
                                                    style={{ fontSize: "9px" }}
                                                  >
                                                    ({item?.TimeSlot})
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </td>

                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.NetAmount}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {OrderBookingInvoiceDetailsList?.filter(
                            (x) => x.ReferenceType == 2
                          ).length > 0 ? (
                            <Row>
                              <Col>
                                <div className="table-responsive ">
                                  <div className="dataTables_wrapper no-footer">
                                    <table className="table verticle-middle table-responsive-md mb-3">
                                      <thead className="thead-light">
                                        <tr role="row">
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Order Booking No: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Order Booking No
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Equipment Name: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Equipment Name
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Qty: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                            }}
                                          >
                                            Qty
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Gross Amount: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                              textAlign: "right",
                                            }}
                                          >
                                            Gross Amt
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Tax Amount: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                              textAlign: "right",
                                            }}
                                          >
                                            Tax Amt
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Total Amount: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                              textAlign: "right",
                                            }}
                                          >
                                            Total Amt
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {OrderBookingInvoiceDetailsList?.filter(
                                          (x) => x.ReferenceType == 2
                                        )?.map((item) => (
                                          <tr>
                                            <td>{item.OrderBookingNo}</td>
                                            <td>{item.ServiceName}</td>
                                            <td style={{ textAlign: "center" }}>
                                              {item.Qty}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.GrossAmount}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.TaxAmount}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.NetAmount}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {OrderBookingInvoiceDetailsList?.filter(
                            (x) => x.ReferenceType == 6
                          ).length > 0 ? (
                            <Row>
                              <Col>
                                <div className="table-responsive ">
                                  <div className="dataTables_wrapper no-footer">
                                    <table className="table verticle-middle table-responsive-md mb-3">
                                      <thead className="thead-light">
                                        <tr role="row">
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Order Booking No: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Order Booking No
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Health CheckUp Name: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Health CheckUp Name
                                          </th>

                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                              textAlign: "right",
                                            }}
                                          >
                                            Amount
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {OrderBookingInvoiceDetailsList?.filter(
                                          (x) => x.ReferenceType == 6
                                        )?.map((item) => (
                                          <tr>
                                            <td>{item.OrderBookingNo}</td>
                                            <td>{item.ServiceName}</td>
                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.NetAmount}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {OrderBookingInvoiceDetailsList?.filter(
                            (x) => x.ReferenceType == 5
                          ).length > 0 ? (
                            <Row>
                              <Col>
                                <div className="table-responsive ">
                                  <div className="dataTables_wrapper no-footer">
                                    <table className="table verticle-middle table-responsive-md mb-3">
                                      <thead className="thead-light">
                                        <tr role="row">
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Order Booking No: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Order Booking No
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Package Name: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Package Name
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Days: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                            }}
                                          >
                                            Days
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                              textAlign: "right",
                                            }}
                                          >
                                            Amount
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {OrderBookingInvoiceDetailsList?.filter(
                                          (x) => x.ReferenceType == 5
                                        )?.map((item) => (
                                          <tr>
                                            <td>{item.OrderBookingNo}</td>
                                            <td>{item.ServiceName}</td>
                                            <td>{item.NoOfDays}</td>
                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.NetAmount}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {OrderBookingInvoiceDetailsList?.filter(
                            (x) => x.ReferenceType == 3
                          ).length > 0 ? (
                            <Row>
                              <Col>
                                <div className="table-responsive ">
                                  <div className="dataTables_wrapper no-footer">
                                    <table className="table verticle-middle table-responsive-md mb-3">
                                      <thead className="thead-light">
                                        <tr role="row">
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Order Booking No: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Order Booking No
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Pathology Report Name: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Pathology Report Name
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Days: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                            }}
                                          >
                                            Date
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                              textAlign: "right",
                                            }}
                                          >
                                            Amount
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {OrderBookingInvoiceDetailsList?.filter(
                                          (x) => x.ReferenceType == 3
                                        )?.map((item) => (
                                          <tr>
                                            <td>{item.OrderBookingNo}</td>
                                            <td>{item.ServiceName}</td>
                                            <td>{item.StaffPunchInDate}</td>
                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.NetAmount}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {OrderBookingInvoiceDetailsList?.filter(
                            (x) => x.ReferenceType == 4
                          ).length > 0 ? (
                            <Row>
                              <Col>
                                <div className="table-responsive ">
                                  <div className="dataTables_wrapper no-footer">
                                    <table className="table verticle-middle table-responsive-md mb-3">
                                      <thead className="thead-light">
                                        <tr role="row">
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Order Booking No: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Order Booking No
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Radiology Report Name: activate to sort column ascending"
                                            style={{
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Radiology Report Name
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Days: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                            }}
                                          >
                                            Date
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount: activate to sort column ascending"
                                            style={{
                                              padding: "10px 30px",
                                              textAlign: "right",
                                            }}
                                          >
                                            Amount
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {OrderBookingInvoiceDetailsList?.filter(
                                          (x) => x.ReferenceType == 4
                                        )?.map((item) => (
                                          <tr>
                                            <td>{item.OrderBookingNo}</td>
                                            <td>{item.ServiceName}</td>
                                            <td>{item.StaffPunchInDate}</td>
                                            <td style={{ textAlign: "right" }}>
                                              <i className="fa fa-rupee"></i>{" "}
                                              {item.NetAmount}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <hr></hr>
                          <Row>
                            <Col>
                              <Label>
                                <b>Additional Expenses</b>
                              </Label>
                            </Col>
                          </Row>
                          <FieldArray
                            name="OrderBookingInvoiceAddtionalsList"
                            render={(arrayHelpers) => {
                              return (
                                <Fragment>
                                  {OrderBookingInvoiceAddtionalsList?.map(
                                    (additionalExpense, index) => (
                                      <div
                                        data-repeater-list="group-a"
                                        key={index}
                                      >
                                        <Row>
                                          <Col lg={3}>
                                            <Field
                                              label={"Expense"}
                                              name={`OrderBookingInvoiceAddtionalsList.[${index}].ExpenseIDEncrypt`}
                                              options={expenseOptions}
                                              placeholder={"Select Expense"}
                                              component={CustomSelect}
                                              onChange={(e) => {
                                                if (e) {
                                                  if (
                                                    OrderBookingInvoiceAddtionalsList?.filter(
                                                      (x) =>
                                                        x.ExpenseIDEncrypt ==
                                                        e.value
                                                    ).length > 0
                                                  ) {
                                                    swal(
                                                      "Additional Expenses",
                                                      e.label +
                                                        " expense already exists!",
                                                      "warning"
                                                    );
                                                  } else {
                                                    formikGenerateInvoice.setFieldValue(
                                                      `OrderBookingInvoiceAddtionalsList.[${index}].ExpenseIDEncrypt`,
                                                      e.value
                                                    );
                                                  }
                                                } else {
                                                  formikGenerateInvoice.setFieldValue(
                                                    `OrderBookingInvoiceAddtionalsList.[${index}].ExpenseIDEncrypt`,
                                                    []
                                                  );
                                                }
                                              }}
                                            />
                                          </Col>

                                          <Col lg={2}>
                                            <Field
                                              type="number"
                                              label={"Amount"}
                                              name={`OrderBookingInvoiceAddtionalsList.[${index}].TotalAmount`}
                                              placeholder={"Enter Amount"}
                                              min={0}
                                              component={CustomInput}
                                            />
                                          </Col>
                                          <Col lg={5}>
                                            <Field
                                              type="text"
                                              label={"Remark"}
                                              name={`OrderBookingInvoiceAddtionalsList.[${index}].Remarks`}
                                              placeholder={"Enter Remark"}
                                              component={CustomInput}
                                              maxLength={500}
                                            />
                                          </Col>
                                          <Col
                                            lg={2}
                                            className="align-self-center mb-3"
                                          >
                                            <div className="d-grid">
                                              {/* <label className="form-label"></label> */}
                                              <input
                                                data-repeater-delete
                                                type="button"
                                                className="btn btn-primary btn-xs mt-4"
                                                value="Delete"
                                                onClick={() =>
                                                  swal({
                                                    title:
                                                      "Are you sure you want to delete additional expense?",
                                                    icon: "warning",
                                                    buttons: true,
                                                    dangerMode: true,
                                                  }).then((confirmResult) => {
                                                    if (confirmResult) {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }
                                                  })
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  )}
                                  <Row>
                                    <Col lg={12} style={{ textAlign: "right" }}>
                                      <Button
                                        color="success"
                                        className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                        onClick={() =>
                                          arrayHelpers.push(
                                            initOrderBookingInvoiceAddtionalsList
                                          )
                                        }
                                      >
                                        <i className="fa fa-plus"></i> &nbsp;
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                </Fragment>
                              );
                            }}
                          />
                          <hr></hr>

                          <Row>
                            <Col lg={3}>
                              <Field
                                type="number"
                                label={"Gross Amount"}
                                name="GrossAmount"
                                placeholder={"Gross Amount"}
                                component={CustomInput}
                                min={0}
                                readOnly
                              />
                            </Col>
                            {OrderBookingInvoiceDetailsList?.filter(
                              (x) => x.ReferenceType == 2
                            ).length > 0 ? (
                              <Col lg={3}>
                                <Field
                                  type="number"
                                  label={"Transportation Charges"}
                                  name="TransportationCharges"
                                  placeholder={"Transportation Charges"}
                                  component={CustomInput}
                                  min={0}
                                  readOnly
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={3}>
                              <Field
                                type="number"
                                label={"Tax Amount"}
                                name="TaxAmount"
                                placeholder={"Tax Amount"}
                                component={CustomInput}
                                min={0}
                                readOnly
                              />
                            </Col>
                            {AuthUser?.UserType == 1 ||
                            (AuthUser?.UserType == 2 &&
                              UserForms?.filter(
                                (item) =>
                                  item.FormID == 41 &&
                                  item.InvoiceDiscountAction
                              ).length > 0) ? (
                              <Col lg={3}>
                                <Field
                                  type="number"
                                  label={"Discount/Refund Amount"}
                                  name="DiscountAmount"
                                  placeholder={"Discount/Refund Amount"}
                                  min={0}
                                  component={CustomInput}
                                  onChange={(e) => {
                                    if (
                                      parseFloat(
                                        e.target.value ? e.target.value : 0
                                      ) <=
                                      parseFloat(GrossAmount) +
                                        parseFloat(TaxAmount)
                                    ) {
                                      formikGenerateInvoice.setFieldValue(
                                        "DiscountAmount",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={3}>
                              <Field
                                type="number"
                                label={"Net Amount"}
                                name="NetAmount"
                                placeholder={"Net Amount"}
                                min={0}
                                component={CustomInput}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <Field
                                type="textarea"
                                label={"Narration"}
                                name="Narration"
                                placeholder={"Narration"}
                                component={CustomInput}
                                maxLength={500}
                                rows={4}
                                style={{ resize: "none" }}
                              />
                            </Col>
                          </Row>
                          {/* <hr></hr>
                      <Row>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Advance Balance"}
                            name="AdvanceBalance"
                            placeholder={"Advance Balance"}
                            min={0}
                            component={CustomInput}
                            readOnly
                          />
                        </Col>
                        <Col lg={6}>
                          <button
                            type="button"
                            className="btn mr-2 btn-xs btn-light"
                            style={{ marginTop: "35px" }}
                            onClick={() => {
                              setAdvanceDetailModal(true);
                            }}
                          >
                            Advance Detail
                          </button>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={3}>
                          <div
                            style={{ marginTop: "40px" }}
                            className="custom-control custom-checkbox  checkbox-success"
                          >
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              className="custom-control-input"
                              id="SettleAgainstAdvance"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="SettleAgainstAdvance"
                            >
                              Settle Agaisnt Advance
                            </label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Total Payable"}
                            name="TotalPayable"
                            placeholder={"Total Payable"}
                            min={0}
                            component={CustomInput}
                            readOnly
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Mode of Payment"}
                            name="ModeOfPayment"
                            placeholder={"Mode Of Payment"}
                            component={CustomSelect}
                            options={OptionModeOfPayment}
                          />
                        </Col>
                      </Row> */}
                          <hr></hr>
                          <button
                            type="submit"
                            className="btn mr-2 btn-primary"
                            // onClick={() => {
                            //   setConfirmGenerateModal(true);
                            // }}
                            disabled={disableSubmit}
                          >
                            Generate Invoice
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
                {/* </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal fade"
          show={advanceDetailModal}
          onHide={setAdvanceDetailModal}
          size="lg"
          centered
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Advance Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setAdvanceDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <div className="table-responsive">
                <div id="advance_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <div className="table-responsive ">
                      <div className="dataTables_wrapper no-footer">
                        <table className="table verticle-middle table-responsive-md mb-3">
                          <thead className="thead-light">
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date: activate to sort column ascending"
                                style={{
                                  padding: "10px 20px",
                                }}
                              >
                                Date
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Amount: activate to sort column ascending"
                                style={{
                                  textAlign: "right",
                                  padding: "10px 20px",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Settle: activate to sort column ascending"
                                style={{
                                  textAlign: "right",
                                  padding: "10px 20px",
                                }}
                              >
                                Settle
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Return: activate to sort column ascending"
                                style={{
                                  textAlign: "right",
                                  padding: "10px 20px",
                                }}
                              >
                                Return
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Balance: activate to sort column ascending"
                                style={{
                                  textAlign: "right",
                                  padding: "10px 20px",
                                }}
                              >
                                Balance
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ListOfAdvanceBalanceDetail?.length > 0 ? (
                              ListOfAdvanceBalanceDetail?.map((x) => (
                                <tr>
                                  <td>{x.AdvanceReceivedDate}</td>
                                  <td style={{ textAlign: "right" }}>
                                    <i className="fa fa-rupee"></i>{" "}
                                    {x.AdvanceAmount}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <i className="fa fa-rupee"></i>{" "}
                                    {x.SettledAmount}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <i className="fa fa-rupee"></i>{" "}
                                    {x.ReturnAmount}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <i className="fa fa-rupee"></i>{" "}
                                    {x.BalanceAmount}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No record found...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </Row>
            <Row style={{ float: "right" }}>
              <button
                className="btn btn-sm btn-light"
                onClick={() => setAdvanceDetailModal(false)}
              >
                Close
              </button>
            </Row>
          </div>
        </Modal>

        <Modal
          className="modal fade"
          show={confirmGenerateModal}
          onHide={setConfirmGenerateModal}
          backdrop={"static"}
          centered
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            {/* <div className="modal-header">
              <h4 className="modal-title fs-20">Invoice</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setConfirmGenerateModal(false)}
              >
                <span>×</span>
              </button>
            </div> */}
            <div className="modal-body">
              <div className="swal-icon swal-icon--success">
                <span className="swal-icon--success__line swal-icon--success__line--long"></span>
                <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

                <div className="swal-icon--success__ring"></div>
                <div className="swal-icon--success__hide-corners"></div>
              </div>
              <div className="swal-title">Success</div>
              <div class="text-center">
                <button
                  type="button"
                  className="btn btn-primary m-1"
                  onClick={() =>
                    downloadInvoice(OrderBookingInvoiceIDEncrypted)
                  }
                >
                  Invoice PDF
                </button>
                {TotalPayable && parseFloat(TotalPayable) > 0 ? (
                  <button
                    type="button"
                    className="btn btn-light m-1"
                    onClick={() => setAdvancePayModal(true)}
                  >
                    Pay Bill
                  </button>
                ) : OrderBookingInvoiceReceiptIDEncrypted ? (
                  <button
                    type="button"
                    className="btn btn-primary m-1"
                    onClick={() =>
                      downloadInvoicePaymentReceipt(
                        OrderBookingInvoiceReceiptIDEncrypted
                      )
                    }
                  >
                    Invoice Payment Receipt
                  </button>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className="btn btn-info m-1"
                  onClick={() => history.goBack()}
                >
                  Ok
                </button>
              </div>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light m-1"
                onClick={() => setConfirmGenerateModal(false)}
              >
                Close
              </button>
            </div> */}
          </Spin>
        </Modal>
        <Modal
          className="modal fade"
          show={advancePayModal}
          onHide={setAdvancePayModal}
          backdrop={"static"}
          centered
          size="lg"
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Invoice Payment</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setAdvancePayModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <FormikProvider value={formikPayBill}>
                <Form onSubmit={formikPayBill.handleSubmit}>
                  <Row>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Net Amount"}
                        name="InvoiceNetAmount"
                        placeholder={"Net Amount"}
                        component={CustomInput}
                        readOnly
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Advance Balance"}
                        name="AdvanceBalance"
                        placeholder={"Advance Balance"}
                        component={CustomInput}
                        readOnly
                      />
                    </Col>
                    <Col lg={4}>
                      <button
                        type="button"
                        className="btn mr-2 btn-xs btn-light"
                        style={{ marginTop: "35px" }}
                        onClick={() => {
                          setAdvanceDetailModal(true);
                        }}
                      >
                        Advance Detail
                      </button>
                    </Col>
                  </Row>
                  {/* <hr></hr>
                  <Row>
                    <Col lg={4}>
                      <div className="custom-control custom-checkbox  checkbox-success">
                        <input
                          type="checkbox"
                          defaultChecked={false}
                          className="custom-control-input"
                          id="SettleAgainstAdvance"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="SettleAgainstAdvance"
                        >
                          Settle Agaisnt Advance
                        </label>
                      </div>
                    </Col>
                  </Row> */}
                  <hr></hr>
                  <Row className="mt-3">
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Pending Amount"}
                        name="TotalPayable"
                        placeholder={"Total Payable"}
                        min={0}
                        component={CustomInput}
                        readOnly
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        label={"Mode of Payment"}
                        name="ModeOfPaymentID"
                        placeholder={"Mode Of Payment"}
                        component={CustomSelect}
                        options={modeOfPaymentOptions}
                        required
                      />
                    </Col>
                    <Col lg={4}>
                      <Field
                        type="number"
                        label={"Paying Amount"}
                        name="PaidAmount"
                        placeholder={"Paying Amount"}
                        min={0}
                        component={CustomInput}
                        required
                      />
                    </Col>
                    <Col lg={8}>
                      <Field
                        type="text"
                        label={"Remarks"}
                        name="Remarks"
                        placeholder={"Remarks"}
                        component={CustomInput}
                        maxLength={500}
                      />
                    </Col>
                  </Row>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => setAdvancePayModal(false)}
                      className="btn btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </Spin>
        </Modal>
      </Spin>
    </Fragment>
  );
};
export default AddInvoice;
