import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Input, Label, Row } from "reactstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import CustomSelect from "../../../Custom/CustomSelect";
import {
  POST_GSTPlanInsert,
  POST_GSTPlanUpdate,
  POST_GetGSTPlanDetailByID,
  POST_GSTTypeList,
} from "../../../Helper/url_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import { post } from "../../../Helper/api_helper";
import moment from "moment";

const AddGSTPlan = () => {
  const { GSTPlanIDEncryptParam } = useParams();
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    GSTPlanName: Yup.string().required("GST Plan Name is required field"),
    GSTPercentage: Yup.string().required("GST Percentage is required field"),
    EffectiveDate: Yup.string().required("Effective Date is required field"),
    // GSTCaption: Yup.string().required("GST Caption is required field"),
    // GSTDetailPercentage: Yup.string().required(
    //   "GST Detail Percentage is required field"
    // ),
    // GSTName: Yup.string().required("GST Name is required field"),
    // GSTType: Yup.string().required("GST Type is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      GSTPlanID: GSTPlanIDEncryptParam
        ? window.atob(GSTPlanIDEncryptParam)
        : "",
      GSTPlanName: "",
      GSTPercentage: "",
      EffectiveDate: "",
      // GSTCaption: "",
      // GSTDetailPercentage: "",
      // GSTName: "",
      // GSTType: "",
      Status: true,
      ListOfGSTPlanDetail: [],
      GSTPercentageDisable: false,
    },
    validateOnBlur: false,
  });

  //   const validationSchema2 = Yup.object().shape({
  //     GSTCaption: Yup.string().required("GST Caption is required field"),
  //     GSTName: Yup.string().required("GST Name is required field"),
  //     GSTDetailPercentage: Yup.string().required(
  //       "GST Detail Percentage is required field"
  //     ),
  //     GSTType: Yup.string().required(
  //         "GST Type is required field"
  //       ),
  //   });

  // const option = [
  //   { label: "CGST", value: 1 },
  //   { label: "UGST", value: 2 },
  //   { label: "SGST", value: 3 },
  //   { label: "IGST", value: 4 },
  // ];

  const {
    setFieldValue,
    setValues,
    values: {
      GSTPercentage,
      ListOfGSTPlanDetail,
      GSTPlanID,
      GSTPercentageDisable,
      EffectiveDate,
    },
  } = formik;

  useEffect(() => {
    if (!EffectiveDate) {
      setFieldValue("EffectiveDate", "");
    }
  }, [EffectiveDate]);

  useEffect(() => {
    if (GSTPlanIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetGSTPlanDetailByID, {
        EncryptedGSTPlanID: window.atob(GSTPlanIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            GSTPlanID: window.atob(GSTPlanIDEncryptParam),
            GSTPlanName: res.GSTPlan,
            GSTPercentage: res.GSTPercentage,
            // EffectiveDate: moment(res.EffectiveDate).format("YYYY-MM-DD"),
            EffectiveDate: new Date(res.EffectiveDate),
            //ListOfGSTPlanDetail: res.GstPlanDetailList,
            Status: res.IsActive,
            GSTPercentageDisable: res.GSTPercentageDisable,
          });
          setFieldValue("ListOfGSTPlanDetail", res.GstPlanDetailList);
        }
      });
      setIsLoading(false);
    }
  }, []);

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      EncryptGSTPlanID: GSTPlanID,
      GSTPlan: values.GSTPlanName,
      GSTPercentage: values.GSTPercentage.toString(),
      EffectiveDate: `${moment(values.EffectiveDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      IsActive: values.Status,
      EncryptCreatedBy: objLogin.LoginIDEncrypt,
      EncryptModifiedBy: objLogin.LoginIDEncrypt,
      ListOfGSTPlanDetail: ListOfGSTPlanDetail,
    };
    post(GSTPlanID ? POST_GSTPlanUpdate : POST_GSTPlanInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/GST-plan");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (GSTPercentage) {
      let GSTDetailPer = (GSTPercentage / 2).toFixed(2);
      setFieldValue("ListOfGSTPlanDetail", [
        {
          GSTCaption: GSTDetailPer + " % SGST",
          GSTPercentage: GSTDetailPer,
          GSTType: 1,
          GSTTypeName: "SGST",
          GSTSubType: 1,
          GSTSubTypeName: "SGST",
        },
        {
          GSTCaption: GSTDetailPer + " % CGST",
          GSTPercentage: GSTDetailPer,
          GSTType: 1,
          GSTTypeName: "SGST",
          GSTSubType: 4,
          GSTSubTypeName: "CGST",
        },
        {
          GSTCaption: parseFloat(GSTPercentage).toFixed(2) + " % IGST",
          GSTPercentage: parseFloat(GSTPercentage).toFixed(2),
          GSTType: 2,
          GSTTypeName: "IGST",
          GSTSubType: 2,
          GSTSubTypeName: "IGST",
        },
        {
          GSTCaption: GSTDetailPer + " % UGST",
          GSTPercentage: GSTDetailPer,
          GSTType: 3,
          GSTTypeName: "UGST",
          GSTSubType: 3,
          GSTSubTypeName: "UGST",
        },
        {
          GSTCaption: GSTDetailPer + " % CGST",
          GSTPercentage: GSTDetailPer,
          GSTType: 3,
          GSTTypeName: "UGST",
          GSTSubType: 4,
          GSTSubTypeName: "CGST",
        },
      ]);
    } else {
      setFieldValue("ListOfGSTPlanDetail", []);
    }
  }, [GSTPercentage]);

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={GSTPlanIDEncryptParam ? "Edit GST Plan" : "Add GST Plan"}
          motherMenu={<Link to="/GST-plan">GST Plans</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"GST Plan Name"}
                            name="GSTPlanName"
                            id="GSTPlanName"
                            placeholder={"Enter GST Plan Name"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="number"
                            label={"GST Percentage"}
                            name="GSTPercentage"
                            id="GSTPercentage"
                            placeholder={"Enter GST Percentage"}
                            component={CustomInput}
                            min={0}
                            required
                            onChange={(e) => {
                              if (e.target.value <= 100) {
                                setFieldValue(
                                  "GSTPercentage",
                                  e.target.value.slice(0, 5)
                                );
                              }
                            }}
                            disabled={GSTPercentageDisable}
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"Effective Date"}
                            name="EffectiveDate"
                            id="EffectiveDate"
                            placeholder={"Select Effective Date"}
                            component={CustomFlatpickr}
                            options={{
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            required
                          />
                        </Col>{" "}
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={12}>
                          <h6>GST Plan Detail</h6>
                        </Col>
                      </Row>
                      {/* <Row className="mt-3">
                      <Col lg={4}>
                        <Field
                          type="text"
                          label={"GST Caption"}
                          name="GSTCaption"
                          id="GSTCaption"
                          placeholder={"Enter GST Caption"}
                          component={CustomInput}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          label={"GST Detail Percentage"}
                          name="GSTDetailPercentage"
                          id="GSTDetailPercentage"
                          placeholder={"Enter GST Detail Percentage"}
                          component={CustomInput}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          label={"GST Name"}
                          name="GSTName"
                          id="GSTName"
                          placeholder={"Enter GST Name"}
                          component={CustomInput}
                          required
                        />
                      </Col>

                      <Col lg={4}>
                        <Field
                          label={"GST Type"}
                          name="GSTType"
                          id="GSTType"
                          options={option}
                          placeholder={"Select GST Type"}
                          component={CustomSelect}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <div
                          className="custom-control custom-checkbox mb-3 checkbox-success"
                          style={{ marginTop: "45px", zIndex: 0 }}
                        >
                          <input
                            type="checkbox"
                            defaultChecked
                            className="custom-control-input"
                            id="customCheckBox3"
                            required
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckBox3"
                          >
                            Main GST
                          </label>
                        </div>
                      </Col>
                      <Col lg={4} style={{ textAlign: "right" }}>
                        <button
                          type="submit"
                          className="btn btn-xxs mr-2 btn-primary"
                          style={{ marginTop: "45px" }}
                        >
                          <i className="fa fa-plus mt-1"></i> Add
                        </button>
                      </Col>
                    </Row> */}
                      <Row className="mt-3">
                        <Col lg={12}>
                          <div className="table-responsive">
                            <div
                              id="patient_list"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="example5"
                                className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                                role="grid"
                                aria-describedby="example5_info"
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="GST Caption: activate to sort column ascending"
                                      style={{
                                        width: "30%",
                                        padding: "10px 15px",
                                      }}
                                    >
                                      GST Caption
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="GST Detail Percentage: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 15px",
                                      }}
                                    >
                                      GST Percentage
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="GST Name: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 15px",
                                      }}
                                    >
                                      GST Type
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="GST Type: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 15px",
                                      }}
                                    >
                                      Sub Type
                                    </th>
                                    {/* <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Actions: activate to sort column ascending"
                                  style={{ width: "10%" }}
                                >
                                  Actions
                                </th> */}
                                  </tr>
                                </thead>
                                {/* <tbody>
                              <tr role="row" className="odd">
                                <td>9% of SGST</td>
                                <td>9%</td>
                                <td>SGST</td>
                                <td>SGST</td>
                                <td>
                                  <div className="d-flex">
                                    <Link
                                      to="/add-GST-plan"
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp"
                                      title="Delete"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{ color: "red" }}
                                      ></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr role="row" className="even">
                                <td>9% of CGST</td>
                                <td>9%</td>
                                <td>SGST</td>
                                <td>CGST</td>
                                <td>
                                  <div className="d-flex">
                                    <Link
                                      to="/add-GST-plan"
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>

                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp"
                                      title="Delete"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{ color: "red" }}
                                      ></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>18% of IGST</td>
                                <td>18%</td>
                                <td>IGST</td>
                                <td>IGST</td>
                                <td>
                                  <div className="d-flex">
                                    <Link
                                      to="/add-GST-plan"
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp"
                                      title="Delete"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{ color: "red" }}
                                      ></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr role="row" className="even">
                                <td>9% of UGST</td>
                                <td>9%</td>
                                <td>UGST</td>
                                <td>UGST</td>
                                <td>
                                  <div className="d-flex">
                                    <Link
                                      to="/add-GST-plan"
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>

                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp"
                                      title="Delete"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{ color: "red" }}
                                      ></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>9% of CGST</td>
                                <td>9%</td>
                                <td>UGST</td>
                                <td>CGST</td>
                                <td>
                                  <div className="d-flex">
                                    <Link
                                      to="/add-GST-plan"
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp"
                                      title="Delete"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{ color: "red" }}
                                      ></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            </tbody> */}
                                <tbody>
                                  {ListOfGSTPlanDetail &&
                                    ListOfGSTPlanDetail.map(
                                      (planDetail, index) => (
                                        <tr role="row">
                                          <td>
                                            <Field
                                              name={`ListOfGSTPlanDetail[${index}].GSTCaption`}
                                              placeholder={"Enter GST Caption"}
                                              component={CustomInput}
                                              value={planDetail.GSTCaption}
                                            />
                                          </td>
                                          <td>{planDetail.GSTPercentage} %</td>
                                          <td>{planDetail.GSTTypeName}</td>
                                          <td>{planDetail.GSTSubTypeName}</td>
                                        </tr>
                                      )
                                    )}
                                  {/* <tr role="row" className="odd">
                                <td>9% of SGST</td>
                                <td>9%</td>
                                <td>SGST</td>
                                <td>SGST</td>
                              </tr>
                              <tr role="row" className="even">
                                <td>9% of CGST</td>
                                <td>9%</td>
                                <td>SGST</td>
                                <td>CGST</td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>18% of IGST</td>
                                <td>18%</td>
                                <td>IGST</td>
                                <td>IGST</td>
                              </tr>
                              <tr role="row" className="even">
                                <td>9% of UGST</td>
                                <td>9%</td>
                                <td>UGST</td>
                                <td>UGST</td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>9% of CGST</td>
                                <td>9%</td>
                                <td>UGST</td>
                                <td>CGST</td>
                              </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddGSTPlan;
