import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
//import Home from "./components/Dashboard/Home";
// import PatientList from "./components/Dashboard/PatientList";
import PatientDetails from "./components/Dashboard/PatientDetails";
import DoctorList from "./components/Dashboard/DoctorList";
import Doctordetail from "./components/Dashboard/Doctordetail";
import PageReview from "./components/Dashboard/PageReview";
import Task from "./components/Dashboard/Task";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import EcomCustomers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import RechartJs from "./components/charts/rechart";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import SparklineChart from "./components/charts/Sparkline";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

/// Widget
import Widget from "./pages/Widget";

/// Table
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";

/// Form
import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
import WizardForm from "./components/Forms/ReduxWizard/Index";
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Todo from "./pages/Todo";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import ChangePassword from "./pages/Shrahav Pages/ChangePassword";
import Companies from "./pages/Shrahav Pages/Companies/Companies";
import AddCompanies from "./pages/Shrahav Pages/Companies/AddCompanies";
import AddUser from "./pages/Shrahav Pages/User/AddUser";
import UserList from "./pages/Shrahav Pages/User/UserList";
import GSTPlanList from "./pages/Shrahav Pages/GSTPlan/gstPlanList";
import AddGSTPlan from "./pages/Shrahav Pages/GSTPlan/AddGSTPlan";
import ClientList from "./pages/Shrahav Pages/Clients/ClientList";
import AddClients from "./pages/Shrahav Pages/Clients/AddClients";
import PatientList from "./pages/Shrahav Pages/Patient/PatientList";
import AddPatient from "./pages/Shrahav Pages/Patient/AddPatient";
import StaffList from "./pages/Shrahav Pages/Staff/StaffList";
import StaffDetails from "./pages/Shrahav Pages/Staff/StaffDetails";
import StaffDutySlotList from "./pages/Shrahav Pages/StaffDutySlots/StaffDutySlotList";
import AddStaffDutySlot from "./pages/Shrahav Pages/StaffDutySlots/StaffDutySlotDetail";
import ServiceList from "./pages/Shrahav Pages/Services/ServicesList";
import AddService from "./pages/Shrahav Pages/Services/AddService";
import EquipmentsList from "./pages/Shrahav Pages/Equipments/EquipmentsList";
import AddEquipments from "./pages/Shrahav Pages/Equipments/AddEquipments";
import PackageList from "./pages/Shrahav Pages/Packages/PackageList";
import AddPackage from "./pages/Shrahav Pages/Packages/AddPackage";
import PathologyReportList from "./pages/Shrahav Pages/Pathology Reports/PathologyReport";
import RadiologyReportList from "./pages/Shrahav Pages/Radiology Repots/RadiologyReport";
import HealthCheckUpList from "./pages/Shrahav Pages/HealthCheckUp/HealthCheckupList";
import AddHealthCheckUp from "./pages/Shrahav Pages/HealthCheckUp/AddHealthCheckup";
import EnquiryList from "./pages/Shrahav Pages/Enquires/EnquiryList";
import AddEnquiry from "./pages/Shrahav Pages/Enquires/AddEnquiry";
import Expense from "./pages/Shrahav Pages/Expense/Expense";
// import EnquiryFollowup from "./pages/Shrahav Pages/EnquiryFollowup/EnquiryFollowupList";
// import AddEnquiryFollowup from "./pages/Shrahav Pages/EnquiryFollowup/AddEnquiryFollow";
import CategoryList from "./pages/Shrahav Pages/Categories/CategoryList";
import ServiceMappingPriceList from "./pages/Shrahav Pages/Mapping&PriceList/ServiceMapping&PriceList";
import EquipmentMappingPriceList from "./pages/Shrahav Pages/Mapping&PriceList/Equipmentsmapping&PriceList";
import PackageMappingPriceList from "./pages/Shrahav Pages/Mapping&PriceList/PackageMapping&PriceList";
import PathologyReportMappingPriceList from "./pages/Shrahav Pages/Mapping&PriceList/PathologyReportMapping&Pricelist";
import RadiologyReportMappingPriceList from "./pages/Shrahav Pages/Mapping&PriceList/RadiologyReportMapping&PriceList";
import HealthCheckupMappingPriceList from "./pages/Shrahav Pages/Mapping&PriceList/HealthCheckupMapping&PriceList";
import Configuration from "./pages/Shrahav Pages/Configruation/Configruation";
import AdvanceSlabConfiguration from "./pages/Shrahav Pages/Configruation/AdvanceSlabConfigruation";
import DocumentCheckList from "./pages/Shrahav Pages/Configruation/DocumentCheckList";
import OrderBooking from "./pages/Shrahav Pages/OrderBooking/OrderBooking";
import AddOrder from "./pages/Shrahav Pages/OrderBooking/AddOrder";
import EquipmentPDCList from "./pages/Shrahav Pages/EquipmentPDC/EquipmentPDCDList";
import AddEquipmentPDC from "./pages/Shrahav Pages/EquipmentPDC/EquipmentPDCDetail";
import OrderBookingAdvanceList from "./pages/Shrahav Pages/OrderBookingAdvance/OrderBookingAdvanceList";
import OrderBookingAdvanceReturnList from "./pages/Shrahav Pages/OrderBookingAdvanceReturn/OrderBookingAdvanceReturnList";
import AddOrderBookingAdvance from "./pages/Shrahav Pages/OrderBookingAdvance/AddOrderBookingAdvance";
import AddOrderBookingAdvanceReturn from "./pages/Shrahav Pages/OrderBookingAdvanceReturn/AddOrderBookingAdvanceReturn";
import EmailSetting from "./pages/Shrahav Pages/EmailSetting/EmailSetting";
import ActivityMonitoring from "./pages/Shrahav Pages/ActivityMonitoring/ActivityMonitoring";
import SchecdulePackageAndHealthCheckup from "./pages/Shrahav Pages/SchedulePackage&HealthCheckup/SchedulePackageAndHealthCheckup";
import StaffLeaveManagment from "./pages/Shrahav Pages/StaffLeaveManagment/StaffLeaveManagment";
import ComplaintManagment from "./pages/Shrahav Pages/ComplaintManagment/ComplaintManagment";
import EquipmentReturn from "./pages/Shrahav Pages/EquipmentPDC/EquipmentReturn";
import EquipmentInstallation from "./pages/Shrahav Pages/EquipmentPDC/EquipmentSettingUp";
import AddEquipmentInstallation from "./pages/Shrahav Pages/EquipmentPDC/AddEquipmentInstallation";
import AddEquipmentsReturn from "./pages/Shrahav Pages/EquipmentPDC/AddEquipmentReturn";
import ServicePriceList from "./pages/Shrahav Pages/Mapping&PriceList/ServicePriceList";
import StaffDutySlotDatewiseList from "./pages/Shrahav Pages/StaffDutySlots/DaywiseStaffDutySlotList";
import AddStaffDutySlotDateWise from "./pages/Shrahav Pages/StaffDutySlots/AddDaywiseStaffDutySlot";
import RecommendationsList from "./pages/Shrahav Pages/Recommendations/RecommendationsList";
import AddRecommendations from "./pages/Shrahav Pages/Recommendations/AddRecommendations";
import AddVoucherSeries from "./pages/Shrahav Pages/VoucherSeries/AddVoucherSeries";
import VoucherSeriesList from "./pages/Shrahav Pages/VoucherSeries/VoucherSeriesList";
import AddPackageSchedule from "./pages/Shrahav Pages/SchedulePackage&HealthCheckup/AddPackageSchedule";
import AddHealthCheckupSchedule from "./pages/Shrahav Pages/SchedulePackage&HealthCheckup/AddHealthCheckUpSchedule";
import StaffPayout from "./pages/Shrahav Pages/StaffPayout/StaffPayout";
import StaffAwardsList from "./pages/Shrahav Pages/StaffAwards/StaffAwardsList";
import AddStaffAwards from "./pages/Shrahav Pages/StaffAwards/AddStaffAwards";
import StaffOverTime from "./pages/Shrahav Pages/StaffOverTime/StaffOverTimeList";
import AddStaffOverTime from "./pages/Shrahav Pages/StaffOverTime/AddStaffOverTime";
import Feedback from "./pages/Shrahav Pages/Feedback/Feedback";
import Rating from "./pages/Shrahav Pages/Rating/Rating";
import InvoiceList from "./pages/Shrahav Pages/Invoice/Invoicelist";
import AddInvoice from "./pages/Shrahav Pages/Invoice/AddInvoive";
import Authmiddleware from "./AuthMiddleware";
import TermsAndCondition from "./pages/Shrahav Pages/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./pages/Shrahav Pages/PrivacyPolicy/PrivacyPolicy";
import ReportsMain from "./pages/Shrahav Pages/Reports/ReportsMain";
import ServiceCancel from "./pages/Shrahav Pages/ServiceCancel/ServiceCancelApprovalRejectList";
import EquipmentInstallationSchedule from "./pages/Shrahav Pages/EquipmentInstallationSchedule/EquipmentInstallationScheduleList";
import EquipmentReturnSchedule from "./pages/Shrahav Pages/EquipmentReturnSchedule/EquipmentReturnScheduleList";
import Dashboard from "./pages/Shrahav Pages/Dashboard/Dashboard";
import NotificationsList from "./pages/Shrahav Pages/Notifications/NotificationsList";
import TutorialVideoList from "./pages/Shrahav Pages/TutorialVideos/TutorialVideoList";
import AddTutorialVideo from "./pages/Shrahav Pages/TutorialVideos/AddTutorialVideo";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    // { url: "", component: Home },
    // { url: "dashboard", component: Home },
    { url: "", component: Dashboard },
    { url: "dashboard", component: Dashboard },
    // { url: "patient-list", component: PatientList },
    { url: "patient-details", component: PatientDetails },
    { url: "doctor-list", component: DoctorList },
    { url: "doctor-details", component: Doctordetail },
    { url: "reviews", component: PageReview },
    { url: "task", component: Task },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "post-details", component: PostDetails },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    { url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: EcomCustomers },

    /// Form

    { url: "form-redux", component: ReduxForm },
    { url: "form-redux-wizard", component: WizardForm },
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "todo", component: Todo },

    /// Shrahav Pages

    { url: "change-password", component: ChangePassword },
    { url: "companies", component: Companies },
    { url: "add-company", component: AddCompanies },
    { url: "edit-company/:CompanyIDEncryptParam", component: AddCompanies },
    { url: "add-user", component: AddUser },
    { url: "edit-user/:UserIDEncryptParam", component: AddUser },
    { url: "user-list", component: UserList },
    { url: "GST-plan", component: GSTPlanList },
    { url: "edit-GST-plan/:GSTPlanIDEncryptParam", component: AddGSTPlan },
    { url: "add-GST-plan", component: AddGSTPlan },
    { url: "client-list", component: ClientList },
    { url: "add-clients", component: AddClients },
    { url: "edit-clients/:ClientIDEncryptParam", component: AddClients },
    { url: "patient-list", component: PatientList },
    { url: "add-patient", component: AddPatient },
    { url: "edit-patient/:PatientIDEncryptParam", component: AddPatient },
    { url: "staff-list", component: StaffList },
    //{ url: "staff-details", component: StaffDetails },
    { url: "add-staff", component: StaffDetails },
    { url: "edit-staff/:StaffIDEncryptParam", component: StaffDetails },
    { url: "staff-duty-slots", component: StaffDutySlotList },
    { url: "add-staff-duty-slot", component: AddStaffDutySlot },
    {
      url: "edit-staff-duty-slot/:StaffIDEncryptParam",
      component: AddStaffDutySlot,
    },
    { url: "service-list", component: ServiceList },
    { url: "add-service", component: AddService },
    { url: "edit-service/:ServiceIDEncryptParam", component: AddService },
    { url: "equipment-list", component: EquipmentsList },
    { url: "add-equipments", component: AddEquipments },
    {
      url: "edit-equipments/:EquipmentIDEncryptParam",
      component: AddEquipments,
    },
    { url: "packages-list", component: PackageList },
    { url: "add-package", component: AddPackage },
    { url: "edit-package/:PackageIDEncryptedParam", component: AddPackage },
    { url: "pathology-reports-list", component: PathologyReportList },
    { url: "radiology-reports-list", component: RadiologyReportList },
    { url: "health-checkup-list", component: HealthCheckUpList },
    { url: "add-health-checkup", component: AddHealthCheckUp },
    {
      url: "edit-health-checkup/:HealthCheckupIDEncryptParam",
      component: AddHealthCheckUp,
    },
    { url: "enquiry-list", component: EnquiryList },
    { url: "add-enquiry", component: AddEnquiry },
    // { url: "enquiry-followup", component: EnquiryFollowup },
    // { url: "add-enquiry-followup", component: AddEnquiryFollowup },
    { url: "expense", component: Expense },
    { url: "category-list", component: CategoryList },
    {
      url: "service-mapping-and-price-list",
      component: ServiceMappingPriceList,
    },
    {
      url: "equipment-mapping-and-price-list",
      component: EquipmentMappingPriceList,
    },
    {
      url: "package-mapping-and-price-list",
      component: PackageMappingPriceList,
    },
    {
      url: "pathology-report-mapping-and-price-list",
      component: PathologyReportMappingPriceList,
    },
    {
      url: "radiology-report-mapping-and-price-list",
      component: RadiologyReportMappingPriceList,
    },
    {
      url: "health-checkup-mapping-and-price-list",
      component: HealthCheckupMappingPriceList,
    },
    { url: "configuration", component: Configuration },
    { url: "advance-slab-configuration", component: AdvanceSlabConfiguration },
    { url: "document-check-list", component: DocumentCheckList },
    { url: "order-booking-list", component: OrderBooking },
    // { url: "add-order-booking", component: AddOrder },
    { url: "add-order-booking/:SelectedPatientIDParam", component: AddOrder },
    {
      url: "edit-order-booking/:SelectedPatientIDParam/:OrderBookingIDParam",
      component: AddOrder,
    },
    { url: "equipment-deposit-list", component: EquipmentPDCList },
    { url: "add-equipment-deposit", component: AddEquipmentPDC },
    {
      url: "edit-equipment-deposit/:OrderBookingEquipmentPDCDetailIDEncryptParam",
      component: AddEquipmentPDC,
    },
    { url: "order-booking-advance", component: OrderBookingAdvanceList },
    {
      url: "order-booking-advance-return",
      component: OrderBookingAdvanceReturnList,
    },
    { url: "add-order-booking-advance", component: AddOrderBookingAdvance },
    {
      url: "add-order-booking-advance-return",
      component: AddOrderBookingAdvanceReturn,
    },
    { url: "email-SMTP-setting", component: EmailSetting },
    { url: "activity-monitoring", component: ActivityMonitoring },
    {
      url: "schedule-package-and-healthcheckup",
      component: SchecdulePackageAndHealthCheckup,
    },
    { url: "staff-leave-managment", component: StaffLeaveManagment },
    { url: "complaint-managment", component: ComplaintManagment },
    { url: "equipment-installation", component: EquipmentInstallation },
    { url: "equipment-return", component: EquipmentReturn },
    { url: "add-equipment-installation", component: AddEquipmentInstallation },
    {
      url: "edit-equipment-installation/:EquipmentInstallationIDEncryptParam",
      component: AddEquipmentInstallation,
    },
    { url: "add-equipment-return", component: AddEquipmentsReturn },
    {
      url: "edit-equipment-return/:EquipmentReturnIDEncryptParam",
      component: AddEquipmentsReturn,
    },
    { url: "service-price-list", component: ServicePriceList },

    { url: "recommendations", component: RecommendationsList },
    { url: "add-recommendations", component: AddRecommendations },
    {
      url: "edit-recommendations/:RecommendationIDEncryptParam",
      component: AddRecommendations,
    },

    { url: "staff-awards", component: StaffAwardsList },
    { url: "add-staff-awards", component: AddStaffAwards },
    {
      url: "edit-staff-awards/:StaffAwardIDEncryptParam",
      component: AddStaffAwards,
    },

    { url: "voucher-series-list", component: VoucherSeriesList },
    { url: "add-voucher-series", component: AddVoucherSeries },
    {
      url: "edit-voucher-series/:VoucherSeriesIDEncryptParam",
      component: AddVoucherSeries,
    },

    {
      url: "staff-duty-slots-date-wise",
      component: StaffDutySlotDatewiseList,
    },
    {
      url: "add-staff-duty-slots-date-wise",
      component: AddStaffDutySlotDateWise,
    },
    {
      url: "edit-staff-duty-slots-date-wise/:StaffDutySlotIDEncryptParam",
      component: AddStaffDutySlotDateWise,
    },
    {
      url: "edit-package-schedule/:HealthCheckupOrPackageIDEncryptParam/:OrderBookingIDEncryptParam",
      component: AddPackageSchedule,
    },
    {
      url: "edit-healthcheckup-schedule/:HealthCheckupOrPackageIDEncryptParam/:OrderBookingIDEncryptParam",
      component: AddHealthCheckupSchedule,
    },
    {
      url: "add-healthcheckup-schedule",
      component: AddHealthCheckupSchedule,
    },
    { url: "staff-payout", component: StaffPayout },
    { url: "staff-over-time", component: StaffOverTime },
    { url: "add-staff-over-time", component: AddStaffOverTime },
    {
      url: "edit-staff-over-time/:StaffOverTimeIDEncryptParam",
      component: AddStaffOverTime,
    },
    { url: "feedbacks", component: Feedback },
    { url: "ratings", component: Rating },
    { url: "invoices", component: InvoiceList },
    { url: "generate-invoice", component: AddInvoice },
    { url: "terms-and-condition", component: TermsAndCondition },
    { url: "privacy-policy", component: PrivacyPolicy },
    { url: "reports", component: ReportsMain },
    { url: "service-cancel-approval-reject", component: ServiceCancel },
    {
      url: "assign-staff-for-equipment-installation",
      component: EquipmentInstallationSchedule,
    },
    {
      url: "assign-staff-for-equipment-return",
      component: EquipmentReturnSchedule,
    },
    {
      url: "notifications",
      component: NotificationsList,
    },
    { url: "tutorial-videos", component: TutorialVideoList },
    { url: "add-tutorial-video", component: AddTutorialVideo },
    {
      url: "edit-tutorial-video/:TutorialVideoIDParam",
      component: AddTutorialVideo,
    },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            // style={{ minHeight: window.screen.height - 60 }}
            style={{ minHeight: window.screen.height - 15 }}
          >
            <Switch>
              {routes.map((data, i) => (
                // <Route
                //   key={i}
                //   exact
                //   path={`/${data.url}`}
                //   component={data.component}
                // />
                <Authmiddleware
                  path={`/${data.url}`}
                  component={data.component}
                  key={i}
                  exact
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
