import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Accordion, Row, Col, Modal } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import PaginationButtons from "../../../Custom/Pagination";
import CustomInput from "../../../Custom/CustomInput";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { get, post } from "../../../Helper/api_helper";
import moment from "moment";

import {
  GET_CompanyDropDownList,
  POST_GetStaffLeaveRequestsList,
  POST_StaffLeaveRequestsActiveInActive,
  POST_StaffLeaveRequestsDelete,
  POST_StaffLeaveRequestsDetailByID,
  POST_StaffLeaveRequestsInsert,
  POST_StaffLeaveRequestsUpdate,
  POST_StaffLeaveRequestsOnGoingBookingsList,
  POST_AdminStaffsListDDLByCompanyID,
} from "../../../Helper/url_helper";

const StaffLeaveManagment = () => {
  const [leaveApprovalModal, setLeaveApprovalModal] = useState(false);
  const [addStaffLeaveModal, setAddStaffLeaveModal] = useState(false);

  const [activeDefault, setActiveDefault] = useState(0);

  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [getStaffLeaveList, setStaffLeaveList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);

  const [companyOptions, setCompanyOptions] = useState([]);

  var AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [searchValue, setSearchValue] = useState("");
  let updatedSearchValue = searchValue;

  //const [companyOptions, setCompanyOptions] = useState([]);

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      LeaveStatusFilter: -1,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate, LeaveStatusFilter },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      // Search: searchValue,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyIDEncrypted: CompanyID,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",

      LeaveStatus:
        LeaveStatusFilter == 0
          ? -1
          : LeaveStatusFilter == 1
          ? 0
          : LeaveStatusFilter == 2
          ? 1
          : LeaveStatusFilter == 3
          ? 2
          : LeaveStatusFilter == 4
          ? 3
          : -1,
    };

    await post(POST_GetStaffLeaveRequestsList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setStaffLeaveList(response?.AdminStaffLeaveRequestsList);
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (page > 1 && response?.AdminStaffLeaveRequestsList?.length === 0) {
          setCurrentPage(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const leaveStatusOptions = [
    { label: "All", value: 0 },
    { label: "Pending", value: 1 },
    { label: "Approved", value: 2 },
    { label: "Rejected", value: 3 },
    { label: "Cancel", value: 4 },
  ];

  useEffect(() => {
    loadCompanyDDL();
    getData();
  }, []);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const delete_StaffLeaveRequests = (cID) => {
    swal({
      title: "Are you sure you want to delete the Staff Leave Request?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_StaffLeaveRequestsConfirm(cID);
      }
    });
  };

  const delete_StaffLeaveRequestsConfirm = async (cID) => {
    setIsLoading(true);
    let response = await post(POST_StaffLeaveRequestsDelete, {
      StaffRequestIDEncrypted: cID,
      ModifiedByEncrypted: AuthUser.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_StaffLeaveRequests = async (cID, cStatus) => {
    swal({
      title: `Are you sure you want to ${
        cStatus ? "inactive" : "active"
      } the Staff Leave Requests?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_StaffLeaveRequestsConfirm(cID, cStatus);
      }
    });
  };

  const ActiveInactive_StaffLeaveRequestsConfirm = async (cID, cStatus) => {
    setIsLoading(true);
    let response = await post(POST_StaffLeaveRequestsActiveInActive, {
      StaffRequestIDEncrypted: cID,
      IsActive: !cStatus,
      ModifiedByEncrypted: AuthUser.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: cStatus ? "Inactivated" : "Activated",
        text: cStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const validationSchemaLeaveApproval = Yup.object().shape({
    ApprovedFrom: Yup.string()
      .nullable()
      .required("Approved Date From is required field"),
    ApprovedTo: Yup.string()
      .nullable()
      .required("Approved Date To is required field"),
  });

  const get_StaffLeaveRequests = async (rID, sID) => {
    setIsLoading(true);
    let response = await post(POST_StaffLeaveRequestsDetailByID, {
      StaffRequestIDEncrypted: rID,
      StaffIDEncrypted: sID,
    });
    if (response.IsSuccess) {
      formikLeaveApproval.setValues({
        StaffRequestID: response.StaffRequestIDEncrypt,
        StaffID: response.StaffIDEncrypt,
        StaffNo: response.StaffNo,
        StaffName: response.StaffName,
        MobileNo: response.PrimaryContactNumber,
        AprLeaveFrom: response.LeaveFromDate,
        AprLeaveTo: response.LeaveToDate,
        AprLeaveRemark: response.LeaveRemarks,
        CompanyID: response.CompanyIDEncrypt,
        ApprovedFrom:
          response.LeaveApprovedFromDate === ""
            ? new Date(response.LeaveFromDate)
            : new Date(response.LeaveApprovedFromDate),
        ApprovedTo:
          response.LeaveApprovedToDate === ""
            ? new Date(response.LeaveToDate)
            : new Date(response.LeaveApprovedToDate),
        Status: response.IsActive,
        LeaveStatus: response.LeaveStatus,
        AddLeaveStatus: response.LeaveStatusName,
        StaffLeaveRequestsOnGoingBookingsList:
          response.AdminStaffLeaveRequestsOnGoingBookingsList,
      });
      setLeaveApprovalModal(true);
      // console.log(response);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const formikLeaveApproval = useFormik({
    validationSchema: validationSchemaLeaveApproval,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitLeaveApproval(values, formikHelpers),
    initialValues: {
      StaffRequestID: "",
      StaffID: "",
      LeaveStatus: "",
      StaffNo: "",
      StaffName: "",
      AprLeaveFrom: "",
      AprLeaveTo: "",
      AprLeaveRemark: "",
      MobileNo: "",
      ApprovedFrom: "",
      ApprovedTo: "",
      AddLeaveStatus: "",
      StaffLeaveRequestsOnGoingBookingsList: [],
      submitType: "",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    //handleValidSubmitLeaveApproval,
    values: {
      StaffID,
      StaffRequestID,
      LeaveStatus,
      StaffNo,
      StaffName,
      AprLeaveFrom,
      AprLeaveTo,
      AprLeaveRemark,
      MobileNo,
      ApprovedFrom,
      ApprovedTo,
      AddLeaveStatus,
      StaffLeaveRequestsOnGoingBookingsList,
      submitType,
    },
  } = formikLeaveApproval;

  useEffect(() => {
    if (!ApprovedFrom) {
      formikLeaveApproval.setFieldValue("ApprovedFrom", "");
    }
    if (!ApprovedTo) {
      formikLeaveApproval.setFieldValue("ApprovedTo", "");
    }
  }, [ApprovedFrom, ApprovedTo]);

  const validationSchemaAddLeave = Yup.object().shape({
    AddStaffID: Yup.string()
      .nullable()
      .required("Staff Name is required field"),
    LeaveFrom: Yup.string()
      .nullable()
      .required("Leave From Date is required field"),
    LeaveTo: Yup.string()
      .nullable()
      .required("Leave To Date is required field"),
    LeaveRemark: Yup.string().required("Leave Remark is required field"),
  });

  const formikAddLeave = useFormik({
    validationSchema: validationSchemaAddLeave,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitAddLeave(values, formikHelpers),
    initialValues: {
      AddStaffRequestID: "",
      AddStaffID: "",
      LeaveFrom: "",
      LeaveTo: "",
      LeaveRemark: "",
      AddStaffNo: "",
      AddStaffName: "",
      Gender: "",
      AddMobileNo: "",
      EmergencyContactNumber: "",
      EmailID: "",
      Speciality: "",
      ServiceName: "",
      ListStaffLeaveRequestsOnGoingBookings: [],
    },
    validateOnBlur: false,
  });

  const {
    //handleValidSubmitAddLeave,
    values: {
      AddStaffID,
      AddStaffNo,
      AddStaffName,
      Gender,
      AddMobileNo,
      EmergencyContactNumber,
      EmailID,
      Speciality,
      ServiceName,
      LeaveFrom,
      LeaveTo,
      ListStaffLeaveRequestsOnGoingBookings,
    },
  } = formikAddLeave;

  useEffect(() => {
    if (!LeaveFrom) {
      formikAddLeave.setFieldValue("LeaveFrom", "");
    }
    if (!LeaveTo) {
      formikAddLeave.setFieldValue("LeaveTo", "");
    }
  }, [LeaveFrom, LeaveTo]);

  useEffect(() => {
    if (AddStaffID) {
      setIsLoading(true);

      post(POST_StaffLeaveRequestsOnGoingBookingsList, {
        StaffIDEncrypt: AddStaffID,
      }).then((res) => {
        if (res.IsSuccess) {
          formikAddLeave.setValues({
            AddStaffID: res.StaffIDEncrypt,
            AddStaffNo: res.StaffNo,
            AddStaffName: res.StaffName,
            AddMobileNo: res.PrimaryContactNumber,
            Gender: res.Gender,
            EmergencyContactNumber: res.EmergencyContactNumber,
            EmailID: res.EmailID,
            Speciality: res.Speciality,
            ServiceName: res.ServiceName,
            ListStaffLeaveRequestsOnGoingBookings:
              res.AdminStaffLeaveRequestsOnGoingBookingsList,
          });

          formikAddLeave.setFieldValue("LeaveRemark", "");
          formikAddLeave.setFieldValue("LeaveTo", "");
          formikAddLeave.setFieldValue("LeaveFrom", "");
        }
      });
      setIsLoading(false);
    }
  }, [AddStaffID]);

  const handleValidSubmitAddLeave = (values, { setSubmitting }) => {
    if (
      `${moment(values.LeaveFrom)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(values.LeaveTo)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "Leave ToDate must be greater then Leave FromDate!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    const data = {
      StaffIDEncrypt: values.AddStaffID,
      LeaveFromDate: `${moment(values.LeaveFrom)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      LeaveToDate: `${moment(values.LeaveTo)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      LeaveRemarks: values.LeaveRemark,
      Type: 1,
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
    };
    post(POST_StaffLeaveRequestsInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          formikAddLeave.resetForm();
          setAddStaffLeaveModal(false);
          getData(1, currentSizePerPage);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const handleValidSubmitLeaveApproval = (values, { setSubmitting }) => {
    if (StaffRequestID) {
      if (
        `${moment(values.ApprovedFrom)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000` >
        `${moment(values.ApprovedTo)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`
      ) {
        swal({
          title: "warning",
          text: "Leave ToDate must be greater then Leave FromDate!",
          icon: "warning",
          button: "OK",
        });
        return;
      }

      setIsLoading(true);

      let LeaveStatus = 0;
      if (submitType === "Approve") {
        LeaveStatus = 1;
      } else if (submitType === "Reject") {
        LeaveStatus = 2;
      } else if (submitType === "Cancel Leave") {
        LeaveStatus = 3;
      }

      const data = {
        StaffRequestIDEncrypt: StaffRequestID,
        StaffIDEncrypt: StaffID,
        LeaveApprovedFromDate: `${moment(values.ApprovedFrom)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        LeaveApprovedToDate: `${moment(values.ApprovedTo)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        LeaveStatus: LeaveStatus,
        ApprovedByEncrypt: AuthUser?.LoginIDEncrypt,
        ModifiedByEncrypt: AuthUser?.LoginIDEncrypt,
        LeaveFromDate: `${moment(AprLeaveFrom)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        LeaveToDate: `${moment(AprLeaveTo)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        Type: 1,
      };
      post(POST_StaffLeaveRequestsUpdate, data)
        .then((response) => {
          if (response.IsSuccess) {
            swal({
              title: "Success",
              text: t(response.MessageCode),
              icon: "success",
              button: "OK",
            });
            formikLeaveApproval.resetForm();
            setLeaveApprovalModal(false);
            getData(1, currentSizePerPage);
          } else {
            swal({
              title: "Error",
              text: t(response.MessageCode),
              icon: "error",
              button: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    } else {
      swal({
        title: "Error",
        text: t(0),
        icon: "error",
        button: "OK",
      });
    }
  };

  useEffect(() => {
    if (CompanyID) {
      getStaffList(CompanyID);
    } else {
      formikLeaveApproval.setFieldValue("CompanyID", "");
      setStaffOptions([]);
    }
  }, [CompanyID]);

  const getStaffList = async (companyID) => {
    await post(POST_AdminStaffsListDDLByCompanyID, {
      CompanyIDEncrypt: companyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setStaffOptions(
            res.StaffsList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            motherMenu="Transaction"
            activeMenu="Staff Leave Managment"
          />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Staff Leave Managment List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          {AuthUser?.CompanyType === 1 &&
                          AuthUser?.UserType === 1 ? (
                            <Row>
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col lg={3}>
                              <Field
                                label={"Leave Status"}
                                name="LeaveStatusFilter"
                                placeholder={"Select Leave Status"}
                                options={leaveStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 33 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-lg-block">
                <div
                  onClick={() => {
                    formikAddLeave.resetForm();
                    setAddStaffLeaveModal(true);
                  }}
                  className="btn btn-primary btn-xs btn-rounded"
                >
                  + Add Staff Leave
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  //setSearchValue(e.target.value);
                  updatedSearchValue = e.target.value;
                  setSearchValue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="StaffLeaveManagment_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped StaffLeaveManagment_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Staff No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile No.: activate to sort column ascending"
                          style={{ width: "16%" }}
                        >
                          Mobile No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="From Date: activate to sort column ascending"
                          style={{ width: "17%" }}
                        >
                          From Date
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="To Date: activate to sort column ascending"
                          style={{ width: "17%" }}
                        >
                          To Date
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Approval Status: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Approval Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        getStaffLeaveList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.StaffNo}</td>
                            <td>{element.StaffName}</td>
                            <td>{element.PrimaryContactNumber}</td>
                            <td>{element.LeaveFromDate}</td>
                            <td>{element.LeaveToDate}</td>
                            <td>{element.LeaveStatus}</td>
                            <td>
                              {AuthUser?.UserType == 1 ||
                              (AuthUser?.UserType == 2 &&
                                UserForms?.filter(
                                  (item) => item.FormID == 33 && item.EditAction
                                ).length > 0) ? (
                                element.LeaveStatusID === 2 ||
                                element.LeaveStatusID === 3 ? (
                                  <div className="d-flex">
                                    <div
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="View"
                                      onClick={() => {
                                        get_StaffLeaveRequests(
                                          element.StaffRequestIDEncrypt,
                                          element.StaffIDEncrypt
                                        );
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <div
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                      onClick={() => {
                                        get_StaffLeaveRequests(
                                          element.StaffRequestIDEncrypt,
                                          element.StaffIDEncrypt
                                        );
                                      }}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </div>
                                    {/* <Link
                                to="#"
                                className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                title={
                                  element.IsActive ? "Active" : "Inactive"
                                }
                                onClick={() => {
                                  ActiveInactive_StaffLeaveRequests(
                                    element.StaffRequestIDEncrypt,
                                    element.IsActive
                                  );
                                }}
                              >
                                <i
                                  className="fa fa-circle mt-1"
                                  style={{
                                    color: element.IsActive ? "green" : "red",
                                  }}
                                ></i>
                              </Link> */}
                                    {/* <Link
                                    to="#"
                                    className="btn btn-light shadow btn-xs sharp"
                                    title="Delete"
                                    onClick={() => {
                                      delete_StaffLeaveRequests(
                                        element.StaffRequestIDEncrypt
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </Link> */}
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-1 ml-1">
                              {"No record found..."}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            show={leaveApprovalModal}
            onHide={setLeaveApprovalModal}
            size="lg"
          >
            <FormikProvider value={formikLeaveApproval}>
              <Form onSubmit={formikLeaveApproval.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">
                    Leave Approval ({AddLeaveStatus})
                  </h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setLeaveApprovalModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={4}>
                          <b>Staff No.</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={7} style={{ marginLeft: "-20px" }}>
                          {StaffNo}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={4}>
                          <b>Staff Name</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={7} style={{ marginLeft: "-20px" }}>
                          {StaffName}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={4}>
                          <b>Mobile No.</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={7} style={{ marginLeft: "-20px" }}>
                          {MobileNo}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Leave From Date</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={5} style={{ marginLeft: "-20px" }}>
                          {AprLeaveFrom}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Leave To Date</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={5} style={{ marginLeft: "-20px" }}>
                          {AprLeaveTo}
                        </Col>
                      </Row>
                    </Col>

                    {/* <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Leave Status</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={5} style={{ marginLeft: "-20px" }}>
                          {AddLeaveStatus}
                        </Col>
                      </Row>
                    </Col> */}
                    <Col lg={12}>
                      <Row>
                        <Col lg={3}>
                          <b>Leave Remark</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={8} style={{ marginLeft: "-53px" }}>
                          {AprLeaveRemark}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col>
                      <text>
                        <b>On Going Bookings</b>
                      </text>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="table-responsive">
                        <div
                          id="Advance_Detail_list"
                          className="dataTables_wrapper no-footer"
                        >
                          <table
                            id="example5"
                            className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                            role="grid"
                            aria-describedby="example5_info"
                          >
                            <thead className="thead-light">
                              <tr role="row">
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Order No.: activate to sort column ascending"
                                  style={{
                                    width: "10%",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Order No.
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Patient Name: activate to sort column ascending"
                                  style={{
                                    width: "20%",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Patient Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Date: activate to sort column ascending"
                                  style={{
                                    width: "20%",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Date: activate to sort column ascending"
                                  style={{
                                    width: "30%",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Advance Amount: activate to sort column ascending"
                                  style={{
                                    width: "20%",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Service
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {StaffLeaveRequestsOnGoingBookingsList?.length >
                              0 ? (
                                StaffLeaveRequestsOnGoingBookingsList?.map(
                                  (element, i) => (
                                    <tr role="row" className="odd">
                                      <td key={i}>{element.OrderBookingNo}</td>
                                      <td key={i}>{element.PatientName}</td>
                                      <td key={i}>
                                        {element.OrderBookingDate}
                                      </td>
                                      <td key={i}>
                                        {element.ServiceScheduleStartTime} -
                                        {element.ServiceScheduleEndTime}
                                      </td>
                                      <td key={i}>{element.ServiceName}</td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan={5}>
                                    <div className="mt-1 ml-1">
                                      {"No record found..."}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>

                  <Row className="mt-3">
                    <Col lg={6}>
                      <Field
                        label={"Approved From"}
                        name="ApprovedFrom"
                        placeholder={"Select Approved From Date"}
                        component={CustomFlatpickr}
                        options={{
                          //  minDate: moment(new Date()).format("YYYY-MM-DD"),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        disabled={LeaveStatus > 0 ? true : false}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <Field
                        label={"Approved Leave To"}
                        name="ApprovedTo"
                        placeholder={"Select Approved To Date"}
                        component={CustomFlatpickr}
                        options={{
                          //minDate: moment(new Date()).format("YYYY-MM-DD"),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        disabled={LeaveStatus > 0 ? true : false}
                        required
                      />
                    </Col>
                  </Row>
                  <hr></hr>
                  <Col lg={6}>
                    <Row>
                      <Col lg={6}>
                        <b>Leave Status</b>
                      </Col>
                      <Col lg={1} style={{ marginLeft: "-60px" }}>
                        :
                      </Col>
                      <Col lg={5} style={{ marginLeft: "-20px" }}>
                        {AddLeaveStatus}
                      </Col>
                    </Row>
                  </Col>
                </div>
                <div className="modal-footer">
                  {LeaveStatus === 0 ? (
                    <>
                      <button
                        type="submit"
                        className="btn btn-xs btn-primary"
                        onClick={(e) => {
                          setFieldValue("submitType", "Approve");
                        }}
                      >
                        Approve
                      </button>

                      <button
                        type="submit"
                        className="btn btn-xs btn-danger"
                        onClick={(e) => {
                          setFieldValue("submitType", "Reject");
                        }}
                      >
                        Reject
                      </button>
                      <button
                        type="submit"
                        className="btn btn-xs btn-warning"
                        onClick={(e) => {
                          setFieldValue("submitType", "Cancel Leave");
                        }}
                      >
                        Cancel Leave
                      </button>
                    </>
                  ) : LeaveStatus === 1 ? (
                    <button
                      type="submit"
                      className="btn btn-xs btn-warning"
                      onClick={(e) => {
                        setFieldValue("submitType", "Cancel Leave");
                      }}
                    >
                      Cancel Leave
                    </button>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    onClick={() => setLeaveApprovalModal(false)}
                    className="btn btn-xs btn-light"
                  >
                    {LeaveStatus === 0 ? "Cancel" : "Close"}
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Modal>
          <Modal
            className="modal fade"
            show={addStaffLeaveModal}
            onHide={setAddStaffLeaveModal}
            size="lg"
          >
            <FormikProvider value={formikAddLeave}>
              <Form onSubmit={formikAddLeave.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Staff Leave</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setAddStaffLeaveModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg={6}>
                      <Field
                        label={"Staff Name"}
                        name="AddStaffID"
                        options={staffOptions}
                        placeholder={"Select Staff Name"}
                        component={CustomSelect}
                        isClearable={false}
                        //isDisabled={StaffID ? true : false}
                        required
                      />
                    </Col>
                  </Row>
                  {AddStaffID ? (
                    <>
                      <hr></hr>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Staff No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{AddStaffNo}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Staff Name</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{AddStaffName}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Mobile No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{AddMobileNo}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Email</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{EmailID}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Gender</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{Gender}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Services</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{ServiceName}</Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col>
                          <text>
                            <b>On Going Bookings</b>
                          </text>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <div className="table-responsive">
                            <div
                              id="Advance_Detail_list"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="example5"
                                className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                                role="grid"
                                aria-describedby="example5_info"
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Order No.: activate to sort column ascending"
                                      style={{
                                        width: "10%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Order No.
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Patient Name: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Patient Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Date: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Date: activate to sort column ascending"
                                      style={{
                                        width: "30%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Time
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Advance Amount: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Service
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListStaffLeaveRequestsOnGoingBookings?.length >
                                  0 ? (
                                    ListStaffLeaveRequestsOnGoingBookings?.map(
                                      (element, i) => (
                                        <tr role="row" className="odd">
                                          <td key={i}>
                                            {element.OrderBookingNo}
                                          </td>
                                          <td key={i}>{element.PatientName}</td>
                                          <td key={i}>
                                            {element.OrderBookingDate}
                                          </td>
                                          <td key={i}>
                                            {element.ServiceScheduleStartTime} -
                                            {element.ServiceScheduleEndTime}
                                          </td>
                                          <td key={i}>{element.ServiceName}</td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={5}>
                                        <div className="mt-1 ml-1">
                                          {"No record found..."}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  <hr></hr>
                  <Row>
                    <Col lg={6}>
                      <Field
                        label={"Leave From"}
                        name="LeaveFrom"
                        placeholder={"Select Leave From Date"}
                        component={CustomFlatpickr}
                        options={{
                          minDate: moment(new Date()).format("YYYY-MM-DD"),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <Field
                        label={"Leave To"}
                        name="LeaveTo"
                        placeholder={"Select Leave From Date"}
                        component={CustomFlatpickr}
                        options={{
                          minDate: moment(new Date()).format("YYYY-MM-DD"),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <Field
                        label={"Leave Remark"}
                        name="LeaveRemark"
                        placeholder={"Enter Leave Remark"}
                        component={CustomInput}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-xs btn-primary">
                    Approve
                  </button>
                  <button
                    type="button"
                    onClick={() => setAddStaffLeaveModal(false)}
                    className="btn btn-xs btn-light"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default StaffLeaveManagment;
