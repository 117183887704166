import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik, FieldArray } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Row, Col, Card, Tab, Nav, Button } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import defaultIcon from "../../../../images/avatar/500x500.png";
import CustomClearIndicator from "../../../components/PluginsMenu/Select2/MultiSelect";
import CustomMultiSelect from "../../../Custom/CustomMultiSelect";
import {
  POST_ServiceCreate,
  POST_ServiceUpdate,
  POST_GetServiceDetailByID,
  POST_ServiceIconUpdate,
  GET_GetServiceTypesList,
  GET_GetServiceSubTypesList,
  POST_GetCategoryListByCategoryType,
  POST_GetHoursMasterDDL,
  POST_GetServiceSubTypeDDL,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { is } from "date-fns/locale";

const AddService = () => {
  const { ServiceIDEncryptParam } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [iconPath, setIconPath] = useState("");

  const [optionServiceType, setOptionServiceType] = useState([]);
  const [optionServiceSubType, setOptionServiceSubType] = useState([]);
  const [optionServiceDescription, setOptionServiceDescription] = useState([]);
  const [optionServiceHours, setOptionServiceHours] = useState([]);

  const [icon, setIcon] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [fileKeyIcon, setFileKeyIcon] = useState();
  const [iconPathx, setIconPathx] = useState("");
  const [validationIcon, setValidationIcon] = useState("");

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  // const initListOfServiceHours = {
  //   ServiceHourIDEncrypt: "",
  //   ServiceIDEncrypt: "",
  //   HoursCaption: "",
  //   Hours: 0,
  //   HourlyServiceType: 0,
  // };

  const validationSchema = Yup.object().shape({
    ServiceType: Yup.string()
      .nullable()
      .required("Service Type is required field"),
    // ServiceSubType: Yup.string()
    //   .nullable()
    //   .required("Service Sub Type is required field"),

    ServiceSubType: Yup.string().when("ServiceType", {
      is: (ServiceType) => optionServiceSubType?.length > 0,
      then: Yup.string()
        .nullable()
        .required("Service Sub Type is required field"),
      otherwise: Yup.string().nullable(),
    }),
    ServiceName: Yup.string()
      .nullable()
      .required("Service Name is required field"),
    ChargeType: Yup.string()
      .nullable()
      .required("Charge Type is required field"),
    ServiceToBeUsedIn: Yup.string().required(
      "Service To Be UsedIn is required field"
    ),
    ServiceDescription: Yup.array()
      .nullable()
      .required("Service Description is a required field"),
    VisitDuration: Yup.string().when("ChargeType", {
      is: (ChargeType) => ChargeType === "2",
      then: Yup.string()
        .nullable()
        .required("Visit Duration (Minutes) is a required field"),
      otherwise: Yup.string().nullable(),
    }),
    ServiceHours: Yup.array().when("ChargeType", {
      is: (ChargeType) => ChargeType === "1",
      then: Yup.array()
        .nullable()
        .required("Service Hours is a required field"),
      otherwise: Yup.array().nullable(),
    }),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      ServiceID: ServiceIDEncryptParam
        ? window.atob(ServiceIDEncryptParam)
        : "",
      ServiceType: "",
      ServiceSubType: "",
      ServiceName: "",
      ChargeType: "",
      VisitDuration: "",
      ServiceToBeUsedIn: "",
      ServiceDescription: "",
      IsCashOnHand: false,
      IsQualificationCompulsory: false,
      Status: true,
      //ListOfServiceHours: [initListOfServiceHours],
      ServiceHours: "",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: {
      ChargeType,
      //ListOfServiceHours,
      IsCashOnHand,
      IsQualificationCompulsory,
      IsServiceHoursDisable,
      ServiceID,
      ServiceType,
      ServiceSubType,
    },
  } = formik;

  useEffect(() => {
    // getServiceSubTypeList();
    getServiceTypeList();
    getHoursMasterDDL();
    get_ServiceDescription();
    if (ServiceIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetServiceDetailByID, {
        ServiceIDEncrypt: window.atob(ServiceIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          let ServiceDescriptionsList = res.ServiceDescriptionsList;
          let ServiceHoursList = res.ListOfServiceHoursDetail;
          setValues({
            ServiceID: window.atob(ServiceIDEncryptParam),
            ServiceType: res.ServiceTypeIDEncrypt,
            ServiceSubType: res.ServiceSubTypeIDEncrypt,
            ServiceName: res.ServiceName,
            ChargeType: res.ChargeType,
            VisitDuration: res.VisitDuration,
            ServiceToBeUsedIn: res.ServiceToBeUsedIn,
            ServiceDescription:
              ServiceDescriptionsList.length > 0
                ? ServiceDescriptionsList.map((x) => ({
                    label: x.DescriptionName,
                    value: x.DescriptionID,
                  }))
                : "",
            ServiceHours:
              ServiceHoursList.length > 0
                ? ServiceHoursList.map((x) => ({
                    label: x.HoursCaption,
                    value: x.HoursID,
                  }))
                : "",
            //ListOfServiceHours: [...res.ListOfServiceHoursDetail],
            IsCashOnHand: res.IsCashOnHand,
            IsQualificationCompulsory: res.IsQualificationCompulsory,
            Status: res.IsActive,
            IsServiceHoursDisable: res.IsServiceHoursDisable,
          });
          setIcon(res.ServiceIcon);
        }
      });
      setIsLoading(false);
    }
  }, []);

  const getServiceTypeList = () => {
    setIsLoading(true);
    get(GET_GetServiceTypesList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionServiceType(
            res.ServiceTypesList.map((item) => ({
              label: item.ServiceTypeEnglish,
              value: item.ServiceTypeIDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    getServiceSubTypeList(ServiceType);
  }, [ServiceType]);

  const getServiceSubTypeList = (ServiceTypeID) => {
    setIsLoading(true);
    post(POST_GetServiceSubTypeDDL, {
      ServiceTypeIDEncrypt: ServiceTypeID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionServiceSubType(
            res.ClientServiceSubTypesList.map((item) => ({
              label: item.ServiceSubTypeEnglish,
              value: item.ServiceSubTypeIDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const get_ServiceDescription = () => {
    setIsLoading(true);
    post(POST_GetCategoryListByCategoryType + "?CategoryType=6")
      .then((res) => {
        if (res.IsSuccess) {
          setOptionServiceDescription(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const getHoursMasterDDL = () => {
    setIsLoading(true);
    post(POST_GetHoursMasterDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionServiceHours(
            res.HoursMasterList.map((item) => ({
              label: item.HoursCaption,
              value: item.HoursID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const ChargeTypeOptions = [
    { label: "Hourly", value: 1 },
    { label: "Per Visit", value: 2 },
  ];

  const ServiceToBeUsedInOptions = [
    { label: "Both", value: 1 },
    { label: "Order Booking", value: 2 },
    { label: "Enquiry", value: 3 },
  ];

  // const HourlyServiceOptions = [
  //   { label: "24 Hour Resident", value: 1 },
  //   { label: "24 Hour Rotational", value: 2 },
  //   { label: "Hourly", value: 3 },
  // ];

  const uploadIcon = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        swal("", "Invalid File", "warning");
        setIconFile(null);
        setIcon(null);
        setFileKeyIcon(Math.random().toString(9).substring(2, 9));
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== 500 || width !== 500) {
              swal({
                title: "Error",
                text: "Image size must be of 500x500",
                icon: "error",
                button: "OK",
              });
              setIconFile(null);
              setIcon(null);
            } else {
              setIconFile(file);
              setIconPathx("");
              setIcon(URL.createObjectURL(file));
              setValidationIcon("");
            }
          };
        };
      }
    } else {
      setIconFile(null);
      setIcon(null);
    }
  };

  const SaveUploadImages = (sID) => {
    if (iconFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("EncryptServiceID", sID);
      formData.append("EncryptCreatedBy", objLogin.LoginIDEncrypt);
      formData.append("Logo", iconFile);

      postFormData(POST_ServiceIconUpdate, formData)
        .then((response) => {
          if (response.IsSuccess) {
            setIsLoading(false);
          } else {
            console.log(response);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    if (iconFile === null && icon === null) {
      setValidationIcon("Service Icon required");
      setIsLoading(false);
      return false;
    }

    const data = {
      ServiceSubTypeID: values.ServiceSubType,
      ServiceName: values.ServiceName,
      ChargeType: values.ChargeType,
      // ListOfServiceHoursDetail:
      //   values.ChargeType == 1 ? ListOfServiceHours : [],
      ServiceToBeUsedIn: values.ServiceToBeUsedIn,
      ServiceDescriptionIDs: Array.from(
        values.ServiceDescription,
        (x) => x.value
      ).join(","),
      ServiceHourIDs: Array.from(values.ServiceHours, (x) => x.value).join(","),
      IsCashOnHand: values.IsCashOnHand,
      IsQualificationCompulsory: values.IsQualificationCompulsory,
      IsActive: values.Status,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
      VisitDuration: values.ChargeType == 2 ? values.VisitDuration : "",
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
      ServiceID: ServiceID,
      ServiceTypeID: values.ServiceType,
    };

    post(ServiceIDEncryptParam ? POST_ServiceUpdate : POST_ServiceCreate, data)
      .then((response) => {
        if (response.IsSuccess) {
          SaveUploadImages(
            ServiceIDEncryptParam
              ? window.atob(ServiceIDEncryptParam)
              : response.EncryptID
          );
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/service-list");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={ServiceIDEncryptParam ? "Edit Service" : "Add Service"}
          motherMenu={<Link to="/service-list">Services</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Service Type"}
                            name="ServiceType"
                            options={optionServiceType}
                            placeholder={"Select Service Type"}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        {ServiceType && optionServiceSubType?.length === 0 ? (
                          ""
                        ) : (
                          <Col lg={4}>
                            <Field
                              label={"Service Sub Type"}
                              name="ServiceSubType"
                              options={optionServiceSubType}
                              component={CustomSelect}
                              placeholder={"Select Service Sub Type"}
                              required
                            />
                          </Col>
                        )}
                        <Col lg={4}>
                          <Field
                            label={"Service Name"}
                            name="ServiceName"
                            placeholder={"Enter Service Name"}
                            component={CustomInput}
                            style={{ resize: "none" }}
                            required
                            maxLength={200}
                          />
                        </Col>

                        {/* <Col lg={8}>
                          <Row> */}
                        <Col lg={4}>
                          <Field
                            label={"Charge Type"}
                            name="ChargeType"
                            options={ChargeTypeOptions}
                            placeholder={"Select Charge Type"}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        {ChargeType === 2 ? (
                          <Col lg={4}>
                            <Field
                              type="number"
                              label={"Visit Duration (Minutes)"}
                              name="VisitDuration"
                              placeholder={"Enter Visit Duration (Minutes)"}
                              component={CustomInput}
                              min={0}
                              required
                              onChange={(e) => {
                                setFieldValue(
                                  "VisitDuration",
                                  e.target.value.slice(0, 3)
                                );
                              }}
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col lg={4}>
                          <Field
                            label={"Service To Be Used In"}
                            name="ServiceToBeUsedIn"
                            options={ServiceToBeUsedInOptions}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        {/* </Row>
                        </Col> */}
                      </Row>

                      {ChargeType === 1 ? (
                        <>
                          <hr></hr>
                          {/* <h6 className="mb-3">Service Hours</h6>  <FieldArray
                            name="ListOfServiceHours"
                            render={(arrayHelpers) => {
                              return (
                                <Fragment>
                                  {ListOfServiceHours?.map(
                                    (ServiceHoursList, index) => (
                                      <div
                                        data-repeater-list="group-a"
                                        key={index}
                                      >
                                        <div data-repeater-item className="row">
                                          <div className="mb-3 col-lg-4">
                                            <Field
                                              label={"Hourly Service Type"}
                                              name={`ListOfServiceHours.[${index}].HourlyServiceType`}
                                              options={HourlyServiceOptions}
                                              placeholder={
                                                "Select Hourly Service Type"
                                              }
                                              component={CustomSelect}
                                              required
                                            />
                                          </div>
                                          <div className="mb-3 col-lg-3">
                                            <Field
                                              type="text"
                                              label={"Hours Caption"}
                                              name={`ListOfServiceHours.[${index}].HoursCaption`}
                                              placeholder={
                                                "Enter Hours Caption"
                                              }
                                              component={CustomInput}
                                              required
                                              maxLength={50}
                                            />
                                          </div>
                                          <div className="mb-3 col-lg-3">
                                            <Field
                                              type="number"
                                              label={"Hours"}
                                              name={`ListOfServiceHours.[${index}].Hours`}
                                              placeholder={"Enter Hours"}
                                              component={CustomInput}
                                              required
                                              maxLength={3}
                                            />
                                          </div>
                                          {ServiceIDEncrypt ? (
                                            <Col
                                              lg={2}
                                              className="align-self-center mb-3"
                                            >
                                              <div className="d-grid">
                                                <label className="form-label">
                                                  <span
                                                    style={{
                                                      color: "white",
                                                    }}
                                                  >
                                                    .
                                                  </span>
                                                </label>
                                                <input
                                                  data-repeater-delete
                                                  type="button"
                                                  className="btn btn-primary btn-xs mt-3"
                                                  value="Delete"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                />
                                              </div>
                                            </Col>
                                          ) : (
                                            index !== 0 && (
                                              <Col
                                                lg={2}
                                                className="align-self-center mb-3"
                                              >
                                                <div className="d-grid">
                                                  <label className="form-label">
                                                    <span
                                                      style={{
                                                        color: "white",
                                                      }}
                                                    >
                                                      .
                                                    </span>
                                                  </label>
                                                  <input
                                                    data-repeater-delete
                                                    type="button"
                                                    className="btn btn-primary btn-xs mt-3"
                                                    value="Delete"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <Row>
                                    <Col lg={12} style={{ textAlign: "right" }}>
                                      <Button
                                        color="success"
                                        className="btn btn-success mt-2 mb-2 mt-lg-0 btn-xs"
                                        onClick={() =>
                                          arrayHelpers.push(
                                            initListOfServiceHours
                                          )
                                        }
                                      >
                                        <i className="fa fa-plus"></i> &nbsp;
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                </Fragment>
                              );
                            }}
                          /> */}
                          <Row>
                            <Col lg={12}>
                              <Field
                                label={"Service Hours"}
                                name="ServiceHours"
                                options={optionServiceHours}
                                component={CustomMultiSelect}
                                isMulti={true}
                                isDisabled={IsServiceHoursDisable}
                                required
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                      <hr></hr>
                      <Row>
                        <Col lg={12}>
                          <Field
                            label={"Service Description"}
                            name="ServiceDescription"
                            options={optionServiceDescription}
                            component={CustomMultiSelect}
                            isMulti={true}
                            required
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={4}>
                          <Label>
                            Service Icon <span className="text-danger">*</span>{" "}
                            <span style={{ color: "red", fontSize: "10px" }}>
                              (500 x 500)
                            </span>
                          </Label>

                          <div className="image-placeholder serviceIcon-placeholder">
                            <div className="avatar-edit">
                              <input
                                type="file"
                                id="imageUpload"
                                onChange={uploadIcon}
                                key={fileKeyIcon || ""}
                              />
                              <label htmlFor="imageUpload" name=""></label>
                            </div>
                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile"
                                  src={
                                    iconFile || icon
                                      ? iconFile
                                        ? URL.createObjectURL(iconFile)
                                        : icon
                                      : defaultIcon
                                  }
                                  alt={iconFile ? iconFile.name : null}
                                />
                              </div>
                            </div>
                          </div>
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationIcon}
                          </span>
                        </Col>

                        <Col lg={3} className="mt-3">
                          <div
                            className="custom-control custom-checkbox mb-3 checkbox-success"
                            style={{ zIndex: 0 }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked
                              className="custom-control-input"
                              id="IsCashOnHand"
                              name="IsCashOnHand"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "IsCashOnHand",
                                  e.target.checked
                                );
                              }}
                              checked={IsCashOnHand}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="IsCashOnHand"
                            >
                              Cash on hand Applicable
                            </label>
                          </div>
                        </Col>

                        <Col lg={3} className="mt-3">
                          <div
                            className="custom-control custom-checkbox mb-3 checkbox-success"
                            style={{ zIndex: 0 }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked
                              className="custom-control-input"
                              id="IsQualificationCompulsory"
                              name="IsQualificationCompulsory"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "IsQualificationCompulsory",
                                  e.target.checked
                                );
                              }}
                              checked={IsQualificationCompulsory}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="IsQualificationCompulsory"
                            >
                              Is Qualification Compulsory
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn mr-2 btn-primary btn-sm"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light"
                            onClick={(e) => {
                              history.goBack();
                            }}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddService;
