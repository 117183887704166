import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import CustomSelect from "../../../Custom/CustomSelect";
import { Spin } from "antd";
import { post } from "../../../Helper/api_helper";
import { POST_GetAdminDashboardRevenueMonthlyYearWise } from "../../../Helper/url_helper";

const RevenueMonthlyYearWiseChart = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      Year: new Date().getFullYear().toString(),
      ServiceData: [],
      EquipmentData: [],
      MonthLabel: [],
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { Year, ServiceData, EquipmentData, MonthLabel },
  } = formik;

  useEffect(() => {
    getRevenueMonthlyChartData();
  }, [Year]);

  const getRevenueMonthlyChartData = () => {
    setIsLoading(true);
    post(POST_GetAdminDashboardRevenueMonthlyYearWise, {
      CompanyIDEncrypt: props.CompanyID,
      Year: Year,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          bindChartData(response?.MonthlyYearWiseRevenueList);
        } else {
          bindChartData([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const bindChartData = (list) => {
    let serviceArray = [],
      equipmentArray = [],
      monthLableArray = [];
    Promise.all(
      list?.map((x) => {
        serviceArray.push(x.TotalServiceRevenue);
        equipmentArray.push(x.TotalEquipmentRevenue);
        monthLableArray.push(x.YearMonthCaption);
      })
    );
    formik.setFieldValue("ServiceData", serviceArray);
    formik.setFieldValue("EquipmentData", equipmentArray);
    formik.setFieldValue("MonthLabel", monthLableArray);
  };

  const series = [
    {
      name: "Service",
      //data: [420, 550, 850, 220, 650],
      data: ServiceData,
    },
    {
      name: "Equipment",
      //data: [170, 850, 101, 90, 250],
      data: EquipmentData,
    },
  ];
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: true,
      fontSize: "12px",
      fontWeight: 300,

      labels: {
        colors: "black",
      },
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        width: 19,
        height: 19,
        strokeWidth: 0,
        radius: 19,
        strokeColor: "#fff",
        fillColors: ["#369DC9", "#D45BFF"],
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#3e4954",
          fontSize: "14px",
          fontFamily: "Poppins",
          fontWeight: 100,
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      //categories: ["06", "07", "08", "09", "10"],
      categories: MonthLabel,
    },
    fill: {
      colors: ["#369DC9", "#D45BFF"],
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "₹ " + parseFloat(val).toFixed(2);
        },
      },
    },
  };

  return (
    <div>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-9"></div>
              <div className="col-lg-3">
                <Field
                  label={"Year"}
                  name="Year"
                  options={props.optionsYear}
                  component={CustomSelect}
                  isClearable={false}
                />
              </div>
            </div>
          </form>
        </FormikProvider>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </Spin>
    </div>
  );
};

export default RevenueMonthlyYearWiseChart;
