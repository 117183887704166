import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Accordion, Modal } from "react-bootstrap";
import { Col, Form, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Field, FormikProvider, useFormik } from "formik";
import { post } from "../../../Helper/api_helper";
import * as Yup from "yup";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import {
  POST_AdminOrderBookingDelete,
  POST_AdminOrderBookingStatusUpdate,
  POST_GetCityListBasedOnCompanies,
  POST_GetPatientList,
  POST_GetPatientOrderHistoryList,
  POST_PatientActiveInactive,
  POST_PatientDelete,
} from "../../../Helper/url_helper";
import PaginationButtons from "../../../Custom/Pagination";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
const PatientList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [PatientDataList, setPatientDataList] = useState([]);
  const [OrderHistoryDataList, setOrderHistoryDataList] = useState([]);
  const [orderBookingID, setOrderBookingID] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [searchValue, setSearchValue] = useState("");
  const [cityOptions, setCityOptions] = useState([]);

  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [activeDefault, setActiveDefault] = useState(0);

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CityID: "",
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CityID },
  } = formik;

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);

    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: searchValue,
      Sorting: "",
      CityID: CityID ? CityID : 0,
    };

    await post(POST_GetPatientList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setPatientDataList(response?.PatientsList);
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (page > 1 && response?.PatientsList?.length === 0) {
          setCurrentPage(page - 1);
          getData(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadCityDDL = async () => {
    await post(POST_GetCityListBasedOnCompanies)
      .then((res) => {
        if (res.IsSuccess) {
          setCityOptions(
            res.CityList.map((item) => ({
              label: item.CityName,
              value: item.CityID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getData();
    loadCityDDL();
  }, []);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const ActiveInactive_Patient = async (ID, Status) => {
    swal({
      title: `Are you sure you want to ${
        Status ? "inactive" : "active"
      } the patient?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmResult) => {
      if (confirmResult) {
        ActiveInactive_PatientConfirm(ID, Status);
      }
    });
  };

  const ActiveInactive_PatientConfirm = async (ID, Status) => {
    setIsLoading(true);
    await post(POST_PatientActiveInactive, {
      EncryptedPatientID: ID,
      IsActive: !Status,
      EncryptedModifiedBy: AuthUser?.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: Status ? "Inactivated" : "Activated",
            text: Status
              ? "Record has been Inactivated."
              : "Record has been Activated.",
            icon: "success",
            button: "OK",
          });
          getData(currentPage, currentSizePerPage);
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const delete_Patient = (ID) => {
    swal({
      title: "Are you sure you want to delete the patient?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_PatientConfirm(ID);
      }
    });
  };

  const delete_PatientConfirm = async (ID) => {
    setIsLoading(true);
    await post(POST_PatientDelete, {
      EncryptedPatientID: ID,
      EncryptedModifiedBy: AuthUser?.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Deleted",
            text: "Record has been deleted.",
            icon: "success",
            button: "OK",
          });
          getData(currentPage, currentSizePerPage);
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getOrderHistory = async (patientID) => {
    setIsLoading(true);
    await post(POST_GetPatientOrderHistoryList, {
      PatientIDEncrypted: patientID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setOrderHistoryModal(true);
          setOrderHistoryDataList(response.OrderHistoryList);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log(response.MessageCode);
          console.log(response.Message);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const delete_OrderHistory = (orderID, patientID) => {
    swal({
      title: "Are you sure you want to delete the order?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_OrderHistoryConfirm(orderID, patientID);
      }
    });
  };

  const delete_OrderHistoryConfirm = async (orderID, patientID) => {
    setIsLoading(true);
    let response = await post(POST_AdminOrderBookingDelete, {
      OrderBookingIDEncrypted: orderID,
      LoginIDEncrypted: AuthUser.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getOrderHistory(patientID);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_OrderHistory = async (
    orderID,
    orderStatus,
    patientID
  ) => {
    swal({
      title: `Are you sure you want to ${
        orderStatus ? "inactive" : "active"
      } the order?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_OrderHistoryConfirm(orderID, orderStatus, patientID);
      }
    });
  };

  const ActiveInactive_OrderHistoryConfirm = async (
    orderID,
    orderStatus,
    patientID
  ) => {
    setIsLoading(true);
    let response = await post(POST_AdminOrderBookingStatusUpdate, {
      OrderBookingIDEncrypted: orderID,
      IsActive: !orderStatus,
      LoginIDEncrypted: AuthUser.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: orderStatus ? "Inactivated" : "Activated",
        text: orderStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getOrderHistory(patientID);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Patients" />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Patient List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                          <Row>
                            <Col lg={4}>
                              <Field
                                name="CityID"
                                placeholder={"Select City"}
                                options={cityOptions}
                                component={CustomSelect}
                              />
                            </Col>
                            <Col lg={4}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                                {/* &nbsp;
                              <div className="d-lg-block">
                                <Link
                                  to="#"
                                  className="btn btn-light btn-xs btn-rounded"
                                >
                                  Clear
                                </Link>
                              </div> */}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 4 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-lg-block">
                <Link
                  to="/add-patient"
                  className="btn btn-primary btn-xs btn-rounded"
                  onClick={() => {
                    localStorage.removeItem("AddPatientFromOrder");
                  }}
                >
                  + Add Patient
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="patient_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient No.: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Patient No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Patient Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Client Name: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Client Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile Number activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Mobile Number
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Date of Birth: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Date of Birth
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Gender: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Gender
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        PatientDataList?.map((element, index) => (
                          <tr role="row" className="odd">
                            <td>{element.PatientNo}</td>
                            <td>{element.PatientName}</td>
                            <td>{element.ClientName}</td>
                            <td>{element.MobileNo}</td>
                            <td>{element.DateOfBirth}</td>
                            <td>{element.Gender}</td>
                            <td>
                              <div className="d-flex">
                                <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Order History"
                                  onClick={() =>
                                    getOrderHistory(element.PatientIDEncrypted)
                                  }
                                >
                                  <i className="fa fa-history"></i>
                                </Link>
                                {AuthUser?.UserType == 1 ||
                                (AuthUser?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 25 && item.AddAction
                                  ).length > 0) ? (
                                  <Link
                                    to={`/add-order-booking/${window.btoa(
                                      element.PatientIDEncrypted
                                    )}`}
                                    className="btn btn-light shadow btn-xs sharp mr-1"
                                    title="Order Booking"
                                  >
                                    <i className="fa fa-plus"></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {AuthUser?.UserType == 1 ||
                                (AuthUser?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 4 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <Link
                                      to={`/edit-patient/${window.btoa(
                                        element.PatientIDEncrypted
                                      )}`}
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                      onClick={() => {
                                        localStorage.removeItem(
                                          "AddPatientFromOrder"
                                        );
                                      }}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                    <Link
                                      className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                      title={
                                        element.IsActive ? "Active" : "Inactive"
                                      }
                                      onClick={() => {
                                        ActiveInactive_Patient(
                                          element.PatientIDEncrypted,
                                          element.IsActive
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle mt-1"
                                        style={{
                                          color: element.IsActive
                                            ? "green"
                                            : "red",
                                        }}
                                      ></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {AuthUser?.UserType == 1 ||
                                (AuthUser?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 4 && item.DeleteAction
                                  ).length > 0) ? (
                                  <Link
                                    className="btn btn-light shadow btn-xs sharp"
                                    title="Delete"
                                    onClick={() => {
                                      delete_Patient(
                                        element.PatientIDEncrypted
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3 mb-3">
                              {"No Record Found"}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers d-flex  justify-content-center align-items-center pb-3">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            show={orderHistoryModal}
            onHide={setOrderHistoryModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order History</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderHistoryModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row mt-3">
                    <div className="col-xl-12">
                      <div className="table-responsive">
                        <div
                          id="order_list"
                          className="dataTables_wrapper no-footer"
                        >
                          <table
                            id="example5"
                            className="table patient-list mb-2 dataTablesCard fs-14 dataTable no-footer"
                            role="grid"
                            aria-describedby="example5_info"
                          >
                            <thead className="thead-light">
                              <tr role="row">
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Order No: activate to sort column ascending"
                                  style={{
                                    width: "10%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Order No.
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Company Name: activate to sort column ascending"
                                  style={{
                                    width: "25%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Company Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Patient Name: activate to sort column ascending"
                                  style={{
                                    width: "25%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Patient Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Order Booking Date: activate to sort column ascending"
                                  style={{
                                    width: "20%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Order Booking Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Status: activate to sort column ascending"
                                  style={{
                                    width: "10%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Status
                                </th>

                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Actions: activate to sort column ascending"
                                  style={{
                                    width: "10%",
                                    padding: "10px 15px",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {OrderHistoryDataList?.length > 0 ? (
                                OrderHistoryDataList?.map((element) => (
                                  <tr role="row" className="odd">
                                    <td>{element.OrderBookingNo}</td>
                                    <td>{element.CompanyName}</td>
                                    <td>{element.PatientName}</td>
                                    <td>{element.OrderBookingDate}</td>
                                    <td>{element.OrderBookingStatusValue}</td>
                                    <td>
                                      <div className="d-flex">
                                        {/* {AuthUser?.UserType == 1 ||
                                        (AuthUser?.UserType == 2 &&
                                          UserForms?.filter(
                                            (item) =>
                                              item.FormID == 25 &&
                                              item.AddAction
                                          ).length > 0) ? (
                                          <Link
                                            to={`/add-order-booking/${window.btoa(
                                              element.PatientIDEncrypted
                                            )}`}
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="Order Booking"
                                          >
                                            <i className="fa fa-plus"></i>
                                          </Link>
                                        ) : (
                                          ""
                                        )} */}
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Order Detail"
                                          onClick={() => {
                                            setOrderBookingID(
                                              element.OrderBookingIDEncrypted
                                            );
                                            setOrderDetailModal(true);
                                          }}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                        {/* {AuthUser?.UserType == 1 ||
                                        (AuthUser?.UserType == 2 &&
                                          UserForms?.filter(
                                            (item) =>
                                              item.FormID == 25 &&
                                              item.EditAction
                                          ).length > 0) ? (
                                          <>
                                            {element.OrderBookingStatusValue ===
                                            "Open" ? (
                                              <Link
                                                to={`/edit-order-booking/${window.btoa(
                                                  element.PatientIDEncrypted
                                                )}/${window.btoa(
                                                  element.OrderBookingIDEncrypted
                                                )}`}
                                                className="btn btn-light shadow btn-xs sharp mr-1"
                                                title="Edit"
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </Link>
                                            ) : (
                                              <Link
                                                to="#"
                                                className="btn btn-light shadow btn-xs sharp mr-1"
                                                title="Edit"
                                                onClick={(e) => {
                                                  swal(
                                                    "Can't edit order booking!",
                                                    "Order has been closed.",
                                                    "warning"
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </Link>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )} */}
                                        {/* <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                          title={
                                            element.IsActive
                                              ? "Active"
                                              : "Inactive"
                                          }
                                          onClick={() => {
                                            ActiveInactive_OrderHistory(
                                              element.OrderBookingIDEncrypted,
                                              element.IsActive,
                                              element.PatientIDEncrypted
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-circle mt-1"
                                            style={{
                                              color: element.IsActive
                                                ? "green"
                                                : "red",
                                            }}
                                          ></i>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                          onClick={() => {
                                            delete_OrderHistory(
                                              element.OrderBookingIDEncrypted,
                                              element.PatientIDEncrypted
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link> */}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    <div className="mt-2 ml-1 mb-3">
                                      {"No Record Found"}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setOrderHistoryModal(false)}
                className="btn btn-light"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Close
              </button>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={orderDetailModal}
            onHide={setOrderDetailModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalOrderBookingDetail
                orderBookingID={orderBookingID}
                setOrderDetailModal={setOrderDetailModal}
              ></ModalOrderBookingDetail>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default PatientList;
