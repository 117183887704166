import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, Accordion } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { useHistory } from "react-router-dom";
import { get, post } from "../../../Helper/api_helper";
import {
  GET_CompanyDropDownList,
  //POST_GetServiceMappingAndPriceListDetailByCompanyID,
  POST_GetServiceMappingDetailByCompanyID,
  POST_ServiceMappingDetailSave,
  //POST_ServiceMappingAndPriceListDetailSave,
} from "../../../Helper/url_helper";
import { Spin } from "antd";
import { t } from "i18next";
import swal from "sweetalert";
import moment from "moment";

const ServiceMappingPriceList = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  // const [activeDefault, setActiveDefault] = useState(0);

  const [companyOptions, setCompanyOptions] = useState([]);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadCompanyDDL();
  }, []);

  const validationSchema = Yup.object().shape({
    CompanyID: Yup.string().required("Company is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: "",
      // CurrentEffectiveDate: new Date(),
      // NextEffectiveDate: "",
      // CurrentEffectivePriceList: [],
      // NextEffectivePriceList: [],
      ServiceMappingList: [],
      Filter: "",
      Search: "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      CompanyID,
      //CurrentEffectivePriceList,
      //NextEffectivePriceList,
      ServiceMappingList,
      Filter,
      Search,
    },
  } = formik;

  // const GetServiceMappingAndPriceListByCompanyID = async (companyID) => {
  //   setIsLoading(true);
  //   await post(POST_GetServiceMappingAndPriceListDetailByCompanyID, {
  //     CompanyIDEncrypted: companyID,
  //   })
  //     .then((response) => {
  //       if (response.IsSuccess) {
  //         setValues({
  //           CompanyID: companyID,
  //           CurrentEffectiveDate: new Date(response.CurrentEffectiveDate),
  //           CurrentEffectivePriceList: [...response.CurrentEffectivePriceList],
  //           NextEffectiveDate: response.NextEffectiveDate
  //             ? new Date(response.NextEffectiveDate)
  //             : "",
  //           NextEffectivePriceList: [...response.NextEffectivePriceList],
  //         });
  //         setIsLoading(false);
  //       } else {
  //         swal("Error!", t(response.MessageCode), "error");
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // };

  const GetServiceMappingDetailByCompanyID = async (companyID) => {
    setIsLoading(true);
    await post(POST_GetServiceMappingDetailByCompanyID, {
      CompanyIDEncrypted: companyID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setValues({
            CompanyID: companyID,
            ServiceMappingList: response.ServiceMappingList?.map((x, i) => {
              return {
                ...x,
                show: true,
                old: x.IsSelected,
                new: false,
              };
            }),
          });
          setIsLoading(false);
          formik.setFieldValue("Filter", 1);
          formik.setFieldValue("Search", "");
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (CompanyID) {
      //GetServiceMappingAndPriceListByCompanyID(CompanyID);
      GetServiceMappingDetailByCompanyID(CompanyID);
    } else {
      formik.resetForm();
    }
  }, [CompanyID]);

  // const handleValidSubmit = (values) => {
  //   if (
  //     CurrentEffectivePriceList.filter((x) => x.IsSelected).filter(
  //       (x) => x.ServicePrice === ""
  //     ).length > 0
  //   ) {
  //     swal(
  //       "",
  //       "Current Effective: Selected Service Price is required!",
  //       "warning"
  //     );
  //     return;
  //   } else if (values.NextEffectiveDate) {
  //     if (
  //       NextEffectivePriceList.filter((x) => x.IsSelected).filter(
  //         (x) => x.ServicePrice === ""
  //       ).length > 0
  //     ) {
  //       swal(
  //         "",
  //         " Next Effective: Selected Service Price is required!",
  //         "warning"
  //       );
  //       return;
  //     }
  //   }
  //   setIsLoading(true);
  //   var Payload = {
  //     CompanyIDEncrypted: values.CompanyID,
  //     CurrentEffectiveDate: `${moment(values.CurrentEffectiveDate)
  //       .format("YYYY-MM-DD")
  //       .replace(/^\-/, "")} 00:00:00.000`,
  //     NextEffectiveDate: values.NextEffectiveDate
  //       ? `${moment(values.NextEffectiveDate)
  //           .format("YYYY-MM-DD")
  //           .replace(/^\-/, "")} 00:00:00.000`
  //       : "",
  //     CurrentEffectivePriceList: CurrentEffectivePriceList.filter(
  //       (x) => x.IsSelected
  //     ),
  //     NextEffectivePriceList: NextEffectivePriceList.filter(
  //       (x) => x.IsSelected
  //     ),
  //     LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
  //   };
  //   post(POST_ServiceMappingAndPriceListDetailSave, Payload)
  //     .then((response) => {
  //       if (response.IsSuccess) {
  //         swal("Success!", t(response.MessageCode), "success");
  //       } else {
  //         swal("Error!", t(response.MessageCode), "error");
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // };

  const handleValidSubmit = (values) => {
    setIsLoading(true);
    var Payload = {
      CompanyIDEncrypted: values.CompanyID,
      ServiceMappingList: ServiceMappingList.filter((x) => x.IsSelected),
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    };
    post(POST_ServiceMappingDetailSave, Payload)
      .then((response) => {
        if (response.IsSuccess) {
          swal("Success!", t(response.MessageCode), "success");
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  // const optionsPercentage = [
  //   { label: "Increase", value: 1 },
  //   { label: "Decrease", value: 2 },
  // ];

  const optionsMappedUnmapped = [
    { label: "All", value: 1 },
    { label: "Mapped", value: 2 },
    { label: "Unmapped", value: 3 },
  ];

  useEffect(() => {
    formik.setFieldValue(
      "ServiceMappingList",
      ServiceMappingList?.map((x, i) => {
        if (x.IsSelected) {
          return {
            ...x,
            show:
              Filter == 2 &&
              x.ServiceName?.toLowerCase().includes(Search?.toLowerCase())
                ? true
                : Filter == 3 &&
                  x.ServiceName?.toLowerCase().includes(Search?.toLowerCase())
                ? false
                : x.ServiceName?.toLowerCase().includes(Search?.toLowerCase())
                ? true
                : !Search
                ? true
                : false,
          };
        } else {
          return {
            ...x,
            show:
              Filter == 2 &&
              x.ServiceName?.toLowerCase().includes(Search?.toLowerCase())
                ? false
                : Filter == 3 &&
                  x.ServiceName?.toLowerCase().includes(Search?.toLowerCase())
                ? true
                : x.ServiceName?.toLowerCase().includes(Search?.toLowerCase())
                ? true
                : !Search
                ? true
                : false,
          };
        }
      })
    );
  }, [Filter, Search]);

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle activeMenu="Service Mapping" motherMenu="Mapping" />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg={3}>
                        <Field
                          label={"Company"}
                          name="CompanyID"
                          placeholder={"Select Company"}
                          options={companyOptions}
                          component={CustomSelect}
                          required
                        />
                      </Col>
                    </Row>

                    <hr></hr>
                    <Row>
                      <Col lg={12}>
                        {/* <Accordion
                          className="accordion accordion-no-gutter accordion-header-bg"
                          defaultActiveKey={1}
                        >
                          <div className="accordion__item" key={1}>
                            <Accordion.Toggle
                              // as={Card.Text}
                              eventKey={1}
                              className={`accordion__header  ${
                                activeDefault === 1 ? "" : "collapsed"
                              }`}
                              style={{
                                minWidth: "100%",
                                // backgroundColor: "#d1d1d1",
                                // borderColor: "#d1d1d1",
                              }}
                              onClick={() =>
                                setActiveDefault(activeDefault === 1 ? -1 : 1)
                              }
                            >
                              <span
                                className="accordion__header--text"
                                style={{ float: "left" }}
                              >
                                {"Current Effective"}
                              </span>
                              <span className="accordion__header--indicator"></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={1}>
                              <div className="accordion__body--text">
                                <Row>
                                  <Col lg={3}>
                                    <Field
                                      label={"Current Effective Date"}
                                      name="CurrentEffectiveDate"
                                      placeholder={
                                        "Select Current Effective Date"
                                      }
                                      component={CustomFlatpickr}
                                      options={{
                                        maxDate: new Date(),
                                        altInput: true,
                                        altFormat: "j-M-Y",
                                        dateFormat: "d-m-Y",
                                      }}
                                      disabled
                                    />
                                  </Col>
                                </Row> */}
                        <Row>
                          <Col lg={2}>
                            <Field
                              label="Filter"
                              name={"Filter"}
                              component={CustomSelect}
                              options={optionsMappedUnmapped}
                              isClearable={false}
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label="Search"
                              type="text"
                              name={"Search"}
                              component={CustomInput}
                              placeholder="Search here..."
                            />
                          </Col>
                          {/* <Col lg={3}>
                                    <Field
                                      label="Increase/Decrease"
                                      name={"IncreaseDecreaseByPercentage"}
                                      component={CustomSelect}
                                      options={optionsPercentage}
                                      isClearable={false}
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <Field
                                      label="Percentage"
                                      type="number"
                                      name={"DecreaseByPercentage"}
                                      component={CustomInput}
                                      placeholder="Enter %"
                                      min={0}
                                    />
                                  </Col>
                                  <Col lg={1}>
                                    <button
                                      type="button"
                                      className="btn mr-2 btn-xs btn-primary"
                                      style={{ marginTop: "28px" }}
                                    >
                                      OK
                                    </button>
                                  </Col> */}
                        </Row>
                        <Row className="mt-2">
                          <Col lg={12}>
                            <div className="table-responsive ">
                              <div className="dataTables_wrapper no-footer">
                                <table
                                  className="table verticle-middle table-responsive-md mb-3"
                                  style={{ width: "95%" }}
                                >
                                  <thead className="thead-light">
                                    <tr role="row">
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="CheckBox: activate to sort column ascending"
                                        style={{
                                          width: "10%",
                                          padding: "10px",
                                        }}
                                      ></th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="ServiceName: activate to sort column ascending"
                                        style={{
                                          width: "30%",
                                          padding: "10px 30px",
                                        }}
                                      >
                                        Service Name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="ServiceType: activate to sort column ascending"
                                        style={{
                                          width: "25%",
                                          padding: "10px 30px",
                                        }}
                                      >
                                        Service Type
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="ServiceSubType: activate to sort column ascending"
                                        style={{
                                          width: "35%",
                                          padding: "10px 30px",
                                        }}
                                      >
                                        Service Sub Type
                                      </th>
                                      {/* <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="ServiceName: activate to sort column ascending"
                                        style={{
                                          width: "30%",
                                          padding: "10px 30px",
                                        }}
                                      >
                                        Charge Type
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Price: activate to sort column ascending"
                                        style={{
                                          width: "20%",
                                          padding: "10px 40px",
                                        }}
                                      >
                                        Price
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* {CurrentEffectivePriceList?.length > 0 ? (
                                      CurrentEffectivePriceList?.map(
                                        (currentList, index) => (
                                          <tr role="row">
                                            <td className="currentPriceList_checkbox">
                                              <div className="checkbox text-right align-self-center">
                                                <div
                                                  className="custom-control custom-checkbox"
                                                  style={{
                                                    zIndex: "0 !important",
                                                  }}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`customCheckBoxCurrent_${index}`}
                                                    checked={
                                                      currentList.IsSelected
                                                    }
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `CurrentEffectivePriceList.[${index}].IsSelected`,
                                                        e.target.checked
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={`customCheckBoxCurrent_${index}`}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                            <td>{currentList.ServiceName}</td>
                                             <td>{currentList.ChargeType}</td>
                                            <td>
                                              <Field
                                                type="number"
                                                name={`CurrentEffectivePriceList.[${index}].ServicePrice`}
                                                component={CustomInput}
                                                placeholder="Enter Price"
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `CurrentEffectivePriceList.[${index}].ServicePrice`,
                                                    e.target.value.slice(0, 6)
                                                  );
                                                  setFieldValue(
                                                    `CurrentEffectivePriceList.[${index}].IsSelected`,
                                                    e.target.value
                                                      ? true
                                                      : false
                                                  );
                                                }}
                                                min={0}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={4}>
                                          <div className="mt-2 ml-3 mb-3">
                                            {"No Record Found"}
                                          </div>
                                        </td>
                                      </tr>
                                    )} */}
                                    {ServiceMappingList?.length > 0 ? (
                                      ServiceMappingList?.map(
                                        (currentList, index) => (
                                          <tr
                                            role="row"
                                            style={{
                                              display: currentList.show
                                                ? ""
                                                : "none",
                                              backgroundColor:
                                                currentList.old &&
                                                currentList.new === false
                                                  ? "#f5fcf7"
                                                  : currentList.new &&
                                                    currentList.old === false
                                                  ? "#e5f6f9"
                                                  : "",
                                            }}
                                          >
                                            <td className="currentPriceList_checkbox">
                                              <div className="checkbox text-right align-self-center">
                                                <div
                                                  className="custom-control custom-checkbox"
                                                  style={{
                                                    zIndex: "0",
                                                  }}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`customCheckBoxCurrent_${index}`}
                                                    checked={
                                                      currentList.IsSelected
                                                    }
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `ServiceMappingList.[${index}].IsSelected`,
                                                        e.target.checked
                                                      );
                                                      setFieldValue(
                                                        `ServiceMappingList.[${index}].new`,
                                                        currentList.old ===
                                                          false &&
                                                          e.target.checked ===
                                                            true
                                                          ? true
                                                          : false
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={`customCheckBoxCurrent_${index}`}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                            <td>{currentList.ServiceName}</td>
                                            <td>
                                              {currentList.ServiceTypeEnglish}
                                            </td>
                                            <td>
                                              {
                                                currentList.ServiceSubTypeEnglish
                                              }
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>
                                          <div className="mt-2 ml-3 mb-3">
                                            {"No Record Found"}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* </div>
                            </Accordion.Collapse>
                          </div> 
                        <div className="accordion__item" key={2}>
                          <Accordion.Toggle
                            
                            eventKey={2}
                            className={`accordion__header ${
                              activeDefault === 2 ? "" : "collapsed"
                            }`}
                            style={{
                              minWidth: "100%",
                              backgroundColor: "#d1d1d1",
                            }}
                            onClick={() =>
                              setActiveDefault(activeDefault === 2 ? -1 : 2)
                            }
                          >
                            <span
                              className="accordion__header--text"
                              style={{
                                float: "left",
                              }}
                            >
                              {"Next Effective"}
                            </span>
                            <span className="accordion__header--indicator"></span>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={2}>
                            <div className="accordion__body--text">
                              <Row>
                                <Col lg={3}>
                                  <Field
                                    label={"Next Effective Date"}
                                    name="NextEffectiveDate"
                                    placeholder={"Select Next Effective Date"}
                                    component={CustomFlatpickr}
                                    options={{
                                      minDate: new Date(),
                                      altInput: true,
                                      altFormat: "j-M-Y",
                                      dateFormat: "d-m-Y",
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={2}>
                                  <Field
                                    label="Filter"
                                    name={"Filter"}
                                    component={CustomSelect}
                                    options={optionsMappedUnmapped}
                                    isClearable={false}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    label="Search"
                                    type="text"
                                    name={"Search"}
                                    component={CustomInput}
                                    placeholder="Search here..."
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    label="Increase/Decrease"
                                    name={"IncreaseDecreaseByPercentage"}
                                    component={CustomSelect}
                                    options={optionsPercentage}
                                    isClearable={false}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <Field
                                    label="Percentage"
                                    type="number"
                                    name={"DecreaseByPercentage"}
                                    component={CustomInput}
                                    placeholder="Enter %"
                                    min={0}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <button
                                    type="button"
                                    className="btn mr-2 btn-xs btn-primary"
                                    style={{ marginTop: "28px" }}
                                  >
                                    OK
                                  </button>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col lg={12}>
                                  <div className="table-responsive ">
                                    <div className="dataTables_wrapper no-footer">
                                      <table
                                        className="table verticle-middle table-responsive-md mb-3"
                                        style={{ width: "95%" }}
                                      >
                                        <thead className="thead-light">
                                          <tr role="row">
                                            <th
                                              className="sorting"
                                              tabIndex={0}
                                              aria-controls="example5"
                                              rowSpan={1}
                                              colSpan={1}
                                              aria-label="CheckBox: activate to sort column ascending"
                                              style={{
                                                width: "10%",
                                                padding: "10px",
                                              }}
                                            ></th>
                                            <th
                                              className="sorting"
                                              tabIndex={0}
                                              aria-controls="example5"
                                              rowSpan={1}
                                              colSpan={1}
                                              aria-label="ServiceName: activate to sort column ascending"
                                              style={{
                                                width: "40%",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Service Name
                                            </th>
                                            <th
                                              className="sorting"
                                              tabIndex={0}
                                              aria-controls="example5"
                                              rowSpan={1}
                                              colSpan={1}
                                              aria-label="Charge Type: activate to sort column ascending"
                                              style={{
                                                width: "30%",
                                                padding: "10px 30px",
                                              }}
                                            >
                                              Charge Type
                                            </th>
                                            <th
                                              className="sorting"
                                              tabIndex={0}
                                              aria-controls="example5"
                                              rowSpan={1}
                                              colSpan={1}
                                              aria-label="Price: activate to sort column ascending"
                                              style={{
                                                width: "20%",
                                                padding: "10px 40px",
                                              }}
                                            >
                                              Price
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {NextEffectivePriceList?.length >
                                          0 ? (
                                            NextEffectivePriceList?.map(
                                              (nextList, index) => (
                                                <tr role="row">
                                                  <td className="nextPriceList_checkbox">
                                                    <div className="checkbox text-right align-self-center">
                                                      <div className="custom-control custom-checkbox"   style={{
                                                            zIndex:
                                                              "0 !important",
                                                          }}>
                                                        <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={`customCheckBoxNext_${index}`}
                                                          checked={
                                                            nextList.IsSelected
                                                          }
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              `NextEffectivePriceList.[${index}].IsSelected`,
                                                              e.target.checked
                                                            );
                                                          }}
                                                        />
                                                        <label
                                                          className="custom-control-label"
                                                          htmlFor={`customCheckBoxNext_${index}`}
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {nextList.ServiceName}
                                                  </td>
                                                  <td>{nextList.ChargeType}</td>
                                                  <td>
                                                    <Field
                                                      type="number"
                                                      name={`NextEffectivePriceList.[${index}].ServicePrice`}
                                                      component={CustomInput}
                                                      placeholder="Enter Price"
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          `NextEffectivePriceList.[${index}].ServicePrice`,
                                                          e.target.value.slice(
                                                            0,
                                                            6
                                                          )
                                                        );
                                                        setFieldValue(
                                                          `NextEffectivePriceList.[${index}].IsSelected`,
                                                          e.target.value
                                                            ? true
                                                            : false
                                                        );
                                                      }}
                                                      min={0}
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td colSpan={4}>
                                                <div className="mt-2 ml-3 mb-3">
                                                  {"No Record Found"}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Col>
                              </Row>{" "}
                            </div>
                          </Accordion.Collapse>
                        </div>*/}
                        {/* </Accordion> */}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg={12}>
                        <button type="submit" className="btn mr-2 btn-primary">
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn mr-2 btn-light"
                          onClick={(e) => {
                            history.goBack();
                          }}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default ServiceMappingPriceList;
