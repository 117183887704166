import React, { useState, Fragment, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import PaginationButtons from "../../../Custom/Pagination";
import {
  POST_GetNotificationsList,
  POST_NotificationReadUpdate,
} from "../../../Helper/url_helper";
import { post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { NOTIFICATION_LIST } from "../../../../store/actions/NotificationAction";

const NotificationsList = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [recordCount, setRecordCount] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const dispatch = useDispatch();

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      LoginIDEncrypted: objLogin?.LoginIDEncrypt,
    };
    try {
      const response = await dispatch(NOTIFICATION_LIST(payload));
      if (response?.IsSuccess) {
        setRecordCount(response?.TotalRecordCount);
        setNotificationList(response?.NotificationList);
        setCurrentSizePerPage(sizePerPage);
        if (page > 1 && response?.NotificationList?.length === 0) {
          setCurrentPage(page - 1);
          getData(page - 1);
        } else {
          setCurrentPage(page);
        }
        setIsLoading(false);
      } else {
        swal("Error!", t(response.MessageCode), "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const notificationRead = async (notificationID) => {
    setIsLoading(true);
    await post(POST_NotificationReadUpdate, {
      NotificationIDEncrypted: notificationID,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          getData(currentPage, currentSizePerPage);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Dashboard" activeMenu="Notifications" />

          {/* <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="Notifications_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped Notifications_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Date Time: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Date Time
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Title: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Notification Title
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Notification Message: activate to sort column ascending"
                          style={{ width: "60%" }}
                        >
                          Notification Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        notificationList?.map((element, i) => (
                          <tr
                            role="row"
                            className="odd"
                            style={{
                              backgroundColor: element.IsRead
                                ? "#f6f6f6"
                                : "#e4f6ee",
                              cursor: element.IsRead ? "" : "pointer",
                            }}
                            onClick={() => {
                              if (element.IsRead == false) {
                                notificationRead(
                                  element.NotificationIDEncrypted
                                );
                              }
                            }}
                          >
                            <td>{element.CreatedDate}</td>
                            <td>{element.NotificationTitle}</td>
                            <td>{element.NotificationMessage}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <span
                      class="badge"
                      style={{ backgroundColor: "#f6f6f6", color: "#3e4954" }}
                    >
                      Read
                    </span>
                    &nbsp;
                    <span
                      class="badge"
                      style={{ backgroundColor: "#e4f6ee", color: "#3e4954" }}
                    >
                      Unread
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default NotificationsList;
