import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import swal from "sweetalert";
import PaginationButtons from "../../../Custom/Pagination";
import { get, post } from "../../../Helper/api_helper";
import * as Yup from "yup";
import { t } from "i18next";
import { Spin } from "antd";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field, FormikProvider, useFormik } from "formik";
import CustomSelect from "../../../Custom/CustomSelect";
import {
  GET_CompanyDropDownList,
  POST_AdminVoucherSeriesList,
  POST_AdminVoucherSeriesRequestsActiveInActive,
} from "../../../Helper/url_helper";

const VoucherSeriesList = () => {
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);

  var AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [isLoading, setIsLoading] = useState(false);

  const [voucherList, setVoucherList] = useState([]);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { CompanyID },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_AdminVoucherSeriesList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyIDEncrypted: CompanyID,
    });
    if (response.IsSuccess) {
      setRecordCount(response.TotalRecordCount);
      setVoucherList(response.AdminVoucherSeriesList);
      setCurrentSizePerPage(sizePerPage);
      if (page > 1 && response?.AdminVoucherSeriesList?.length === 0) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    loadCompanyDDL();
  }, []);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const ActiveInactive_VoucherSeries = async (vID, vStatus) => {
    swal({
      title: `Are you sure you want to ${
        vStatus ? "inactive" : "active"
      } the Voucher Series?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_VoucherSeriesConfirm(vID, vStatus);
      }
    });
  };

  const ActiveInactive_VoucherSeriesConfirm = async (vID, vStatus) => {
    setIsLoading(true);
    let response = await post(POST_AdminVoucherSeriesRequestsActiveInActive, {
      VoucherSeriesIDEncrypted: vID,
      IsActive: !vStatus,
      ModifiedByEncrypted: AuthUser.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: vStatus ? "Inactivated" : "Activated",
        text: vStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Configuration" activeMenu="Voucher Series" />

          {AuthUser?.CompanyType === 1 && AuthUser?.UserType == 1 ? (
            <Row className="mb-3">
              <Col lg={12}>
                <Accordion
                  className="accordion accordion-no-gutter accordion-header-bg"
                  defaultActiveKey={0}
                >
                  <div className="accordion__item" key={1}>
                    <Accordion.Toggle
                      // as={Card.Text}
                      eventKey={1}
                      className={`accordion__header  ${
                        activeDefault === 1 ? "" : "collapsed"
                      }`}
                      style={{
                        minWidth: "100%",
                        // backgroundColor: "#36C95F",
                        // borderColor: "#d1d1d1",
                      }}
                      onClick={() =>
                        setActiveDefault(activeDefault === 1 ? -1 : 1)
                      }
                    >
                      <span
                        className="accordion__header--text"
                        style={{ float: "left" }}
                      >
                        <i
                          class="fa fa-filter"
                          aria-hidden="true"
                          style={{ fontSize: "20px" }}
                        ></i>
                        &nbsp;
                        {"Filter Voucher Series List"}
                      </span>
                      <span className="accordion__header--indicator"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={1}>
                      <div className="accordion__body--text">
                        <FormikProvider value={formikFilter}>
                          <Form onSubmit={formikFilter.handleSubmit}>
                            <Row>
                              <Col lg={4}>
                                <Field
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                              <Col lg={4}>
                                <label></label>
                                <div className="d-flex">
                                  <div className="d-lg-block">
                                    <Link
                                      className="btn btn-primary btn-xs btn-rounded"
                                      onClick={(e) => {
                                        getData();
                                      }}
                                    >
                                      Search
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </FormikProvider>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 39 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <Link
                  to="/add-voucher-series"
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add Voucher Series
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="voucherSeries_list"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Prefix : activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Prefix
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Type : activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Type
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Start From: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Start From
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Suffix: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Suffix
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="NosLength : activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          NosLength
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Zero Padding: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Zero Padding
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Effective Date: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Effective Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        voucherList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.Prefix}</td>
                            <td>{element.TranType}</td>
                            <td>{element.StartFrom}</td>
                            <td>{element.Suffix}</td>
                            <td>{element.NosLength}</td>
                            <td>{element.ZeroPadding}</td>
                            <td>{element.EffectiveDate}</td>
                            <td>
                              <div className="d-flex">
                                {AuthUser?.UserType == 1 ||
                                (AuthUser?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 39 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <Link
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                      to={`/edit-voucher-series/${window.btoa(
                                        element.VoucherSeriesIDEncrypt
                                      )}`}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                      title={
                                        element.IsActive ? "Active" : "Inactive"
                                      }
                                      onClick={() => {
                                        ActiveInactive_VoucherSeries(
                                          element.VoucherSeriesIDEncrypt,
                                          element.IsActive
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle mt-1"
                                        style={{
                                          color: element.IsActive
                                            ? "green"
                                            : "red",
                                        }}
                                      ></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default VoucherSeriesList;
