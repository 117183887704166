import { Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { post, postFormData } from "../../../Helper/api_helper";
import {
  POST_GetTutorialVideoDetailByID,
  POST_TutorialVideoInsert,
  POST_TutorialVideoUpdate,
} from "../../../Helper/url_helper";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import CustomInput from "../../../Custom/CustomInput";
import CustomSelect from "../../../Custom/CustomSelect";

const AddTutorialVideo = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { TutorialVideoIDParam } = useParams();
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  // const [videoFile, setVideoFile] = useState(null);
  // const [fileKeyVideo, setFileKeyVideo] = useState();

  const validationSchemaTutorialVideo = Yup.object().shape({
    TopicName: Yup.string().trim().required("Topic Name is required field"),
    AppType: Yup.string().required("App Type is required field"),
    VideoFilePath: Yup.string().required("Video Url is required field"),
  });

  const formik = useFormik({
    validationSchema: validationSchemaTutorialVideo,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      TutorialVideoIDEncrypt: TutorialVideoIDParam
        ? window.atob(TutorialVideoIDParam)
        : "",
      TopicName: "",
      Description: "",
      VideoFilePath: "",
      AppType: "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: { TutorialVideoIDEncrypt },
  } = formik;

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);
    //let formData = new FormData();
    // if (TutorialVideoIDParam) {
    //   formData.append("TutorialVideoIDEncrypt", TutorialVideoIDEncrypt);
    //   if (values.VideoFilePath) {
    //     formData.append("VideoFilePath", values.VideoFilePath);
    //   }
    // }
    // formData.append("LoginIDEncrypted", AuthUser?.LoginIDEncrypt);
    // formData.append("TopicName", values.TopicName.trim());
    // if (values.Description) {
    //   formData.append("Description", values.Description);
    // }
    // formData.append("AppType", values.AppType);
    // formData.append("VideoFile", videoFile);
    var paylod = {
      TutorialVideoIDEncrypt: TutorialVideoIDEncrypt,
      VideoFilePath: values.VideoFilePath ? values.VideoFilePath : "",
      TopicName: values.TopicName ? values.TopicName : "",
      Description: values.Description ? values.Description : "",
      AppType: values.AppType,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    };

    post(
      TutorialVideoIDParam
        ? POST_TutorialVideoUpdate
        : POST_TutorialVideoInsert,
      paylod
    )
      .then((res) => {
        if (res.IsSuccess) {
          setIsLoading(false);
          swal("Success!", t(res.MessageCode), "success");
          history.push("/tutorial-videos");
        } else {
          console.log(res);
          setIsLoading(false);
          swal("Error!", t(res.MessageCode), "error");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (TutorialVideoIDParam) {
      setIsLoading(true);
      post(POST_GetTutorialVideoDetailByID, {
        TutorialVideoIDEncrypt: window.atob(TutorialVideoIDParam),
      }).then((res) => {
        if (res?.IsSuccess) {
          setValues({
            TutorialVideoIDEncrypt: window.atob(TutorialVideoIDParam),
            TopicName: res?.TopicName,
            Description: res?.Description,
            VideoFilePath: res?.VideoFilePath,
            AppType: res?.AppType ? (res?.AppType > 0 ? res?.AppType : "") : "",
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  // const uploadVideoFile = (e) => {
  //   if (e.target.files[0]) {
  //     const file = e.target.files[0];

  //     setVideoFile(file);
  //     setFileKeyVideo(Math.random().toString(9).substring(2, 9));
  //   } else {
  //     setVideoFile(null);
  //     setFileKeyVideo(Math.random().toString(9).substring(2, 9));
  //   }
  // };

  const appTypeOptions = [
    { label: "Client", value: 1 },
    { label: "Staff", value: 2 },
  ];

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            activeMenu={`${
              TutorialVideoIDParam ? "Edit" : "Add"
            } Tutorial Video`}
            motherMenu={<Link to="/tutorial-videos">Tutorial Videos</Link>}
          />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="col-lg-4">
                            <Field
                              label={"Topic Name"}
                              name="TopicName"
                              placeholder={"Enter Topic Name"}
                              component={CustomInput}
                              required
                              maxLength={100}
                            />
                          </div>
                          <div className="col-lg-3">
                            <Field
                              label={"App Type"}
                              name="AppType"
                              placeholder={"Select App Type"}
                              options={appTypeOptions}
                              component={CustomSelect}
                              required
                            />
                          </div>
                          <div className="col-lg-5">
                            <Field
                              label={"Video Url"}
                              name="VideoFilePath"
                              placeholder={"Enter Video Url"}
                              component={CustomInput}
                              required
                            />
                          </div>
                          {/* <div className="col-lg-4">
                            <label>Video File</label>
                            <div
                              className="input-group mb-3"
                              style={{ zIndex: "0" }}
                            >
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  name="fileControlVideo"
                                  onChange={(e) => {
                                    uploadVideoFile(e);
                                  }}
                                  key={fileKeyVideo || ""}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="fileControlVideo"
                                >
                                  {videoFile ? videoFile?.name : "Choose file"}
                                </label>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <button
                              type="submit"
                              className="btn mr-2 btn-primary btn-sm"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-light"
                              onClick={(e) => {
                                history.goBack();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default AddTutorialVideo;
