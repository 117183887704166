import { Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PaginationButtons from "../../../Custom/Pagination";
import {
  POST_GetTutorialVideoList,
  POST_TutorialVideoActiveInactive,
  POST_TutorialVideoDelete,
} from "../../../Helper/url_helper";
import { post } from "../../../Helper/api_helper";
import PageTitle from "../../../layouts/PageTitle";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { t } from "i18next";

const TutorialVideoList = () => {
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [TutorialVideoList, setTutorialVideoList] = useState([]);

  const [videoModal, setVideoModal] = useState(false);
  const [videoTopicName, setVideoTopicName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const [searchValue, setSearchValue] = useState("");
  let updatedSearchValue = searchValue;

  const [isLoading, setIsLoading] = useState(false);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);

    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: searchValue,
      Sorting: "",
      AppType: 0,
    };

    await post(POST_GetTutorialVideoList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setTutorialVideoList(response?.VideoList);
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (page > 1 && response?.VideoList?.length === 0) {
          setCurrentPage(page - 1);
          getData(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [searchValue]);

  const ActiveInactive_video = async (ID, Status) => {
    swal({
      title: `Are you sure you want to ${
        Status ? "inactive" : "active"
      } the tutorial video?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmResult) => {
      if (confirmResult) {
        ActiveInactive_videoConfirm(ID, Status);
      }
    });
  };

  const ActiveInactive_videoConfirm = async (ID, Status) => {
    setIsLoading(true);
    await post(POST_TutorialVideoActiveInactive, {
      TutorialVideoIDEncrypt: ID,
      IsActive: !Status,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: Status ? "Inactivated" : "Activated",
            text: Status
              ? "Record has been Inactivated."
              : "Record has been Activated.",
            icon: "success",
            button: "OK",
          });
          getData(currentPage, currentSizePerPage);
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const delete_video = (ID) => {
    swal({
      title: "Are you sure you want to delete the tutorial video?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_videoConfirm(ID);
      }
    });
  };

  const delete_videoConfirm = async (ID) => {
    setIsLoading(true);
    await post(POST_TutorialVideoDelete, {
      TutorialVideoIDEncrypt: ID,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Deleted",
            text: "Record has been deleted.",
            icon: "success",
            button: "OK",
          });
          getData(currentPage, currentSizePerPage);
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const viewTotorialVideo = async (VideoFilePath) => {
    setIsLoading(true);

    if (VideoFilePath) {
      window.open(VideoFilePath);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Tutorial Videos" />
          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="mr-auto d-lg-block">
              <Link
                to="/add-tutorial-video"
                className="btn btn-primary btn-xs btn-rounded"
              >
                + Add Tutorial Video
              </Link>
            </div>
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  // setSearchValue(e.target.value);
                  updatedSearchValue = e.target.value;
                  setSearchValue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="order_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Topic Name: activate to sort column ascending"
                          style={{ width: "70%" }}
                        >
                          Topic Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Topic Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          App Type
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        TutorialVideoList?.map((element, index) => (
                          <tr role="row" className="odd" key={index}>
                            <td>{element.TopicName}</td>
                            <td>{element.AppType}</td>
                            <td>
                              <div className="d-flex">
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="View"
                                  onClick={() => {
                                    // setVideoUrl(element?.VideoFilePath);
                                    // setVideoTopicName(element?.TopicName);
                                    // setVideoModal(true);
                                    viewTotorialVideo(element.VideoFilePath);
                                  }}
                                >
                                  <i className="fa fa-eye"></i>
                                </Link>

                                <Link
                                  to={`/edit-tutorial-video/${window.btoa(
                                    element.TutorialVideoIDEncrypt
                                  )}`}
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Edit"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>

                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                  title={
                                    element.IsActive ? "Active" : "Inactive"
                                  }
                                  onClick={() => {
                                    ActiveInactive_video(
                                      element.TutorialVideoIDEncrypt,
                                      element.IsActive
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-circle mt-1"
                                    style={{
                                      color: element.IsActive ? "green" : "red",
                                    }}
                                  ></i>
                                </Link>
                                <Link
                                  className="btn btn-light shadow btn-xs sharp"
                                  title="Delete"
                                  onClick={() => {
                                    delete_video(
                                      element.TutorialVideoIDEncrypt
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "red" }}
                                  ></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="mt-1 ml-1 mb-3">
                              {"No Record Found..."}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Modal
            className="modal fade"
            show={videoModal}
            onHide={setVideoModal}
          >
            <div className="">
              <div className="">
                <div className="modal-header">
                  <h4 className="modal-title fs-20">{videoTopicName}</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => {
                      setVideoModal(false);
                    }}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="row">
                        <div className="col-xl-12">
                          <video
                            src={videoUrl}
                            controls
                            width={"100%"}
                            controlsList="nodownload"
                          ></video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}
        </Spin>
      </Fragment>
    </>
  );
};

export default TutorialVideoList;
