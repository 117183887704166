/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        // "patient-list",
        "patient-details",
        "doctor-list",
        "doctor-details",
        "reviews",
        "task",
      ],
      Masters = [
        // "app-profile",
        // "app-calender",
        // "email-compose",
        // "email-inbox",
        // "email-read",
        // "ecom-product-grid",
        // "ecom-product-list",
        // "ecom-product-list",
        // "ecom-product-order",
        // "ecom-checkout",
        // "ecom-invoice",
        // "ecom-customers",
        // "post-details",
        // "ecom-product-detail",
        "category-list",
        "companies",
        "add-company",
        "user-list",
        "add-user",
        "GST-plan",
        "add-GST-Plan",
        "client-list",
        "add-clients",
        "patient-list",
        "add-patient",
        "staff-list",
        "staff-details",
        "staff-duty-slots",
        "add-staff-duty-slot",
        "service-list",
        "add-service",
        "equipment-list",
        "add-equipments",
        "packages-list",
        "add-package",
        "pathology-reports-list",
        "radiology-reports-list",
        "health-checkup-list",
        "add-health-checkup",

        //"enquiry-followup",
        "expense",
        "staff-duty-slots-date-wise",
        "recommendations",
        "staff-awards",
        "add-staff-awards",
        "tutorial-videos",
        "add-tutorial-videos",
      ],
      MappingMasters = [
        "service-mapping-and-price-list",
        "equipment-mapping-and-price-list",
        "package-mapping-and-price-list",
        "pathology-report-mapping-and-price-list",
        "radiology-report-mapping-and-price-list",
        "health-checkup-mapping-and-price-list",
        "service-price-list",
      ],
      email = ["email-compose", "email-inbox", "email-read"],
      shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
      ],
      charts = [
        // "chart-rechart",
        // "chart-flot",
        // "chart-chartjs",
        // "chart-chartist",
        // "chart-sparkline",
        // "chart-apexchart",
        "configuration",
        "advance-slab-configuration",
        "email-SMTP-setting",
        "terms-and-condition",
        "privacy-policy",
        "document-check-list",
        "voucher-series-list",
      ],
      reportstab = ["reports"],
      bootstrap = [
        // "ui-accordion",
        // "ui-badge",
        // "ui-alert",
        // "ui-button",
        // "ui-modal",
        // "ui-button-group",
        // "ui-list-group",
        // "ui-media-object",
        // "ui-card",
        // "ui-carousel",
        // "ui-dropdown",
        // "ui-popover",
        // "ui-progressbar",
        // "ui-tab",
        // "ui-typography",
        // "ui-pagination",
        // "ui-grid",
        "order-booking-list",
        "equipment-deposit-list",
        "order-booking-advance",
        "order-booking-advance-return",
        "enquiry-list",
        "activity-monitoring",
        "schedule-package-and-healthcheckup",
        "staff-leave-managment",
        "complaint-managment",
        "equipment-installation",
        "equipment-return",
        "staff-payout",
        "staff-over-time",
        "ratings",
        "feedbacks",
        "invoices",
        "service-cancel-approval-reject",
        "assign-staff-for-equipment-installation",
        "assign-staff-for-equipment-return",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
        //"post",
      ],
      redux = ["todo", "form-redux", "form-redux-wizard"],
      widget = ["widget-basic"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = [
        "table-bootstrap-basic",
        "table-datatable-basic",
        "table-sorting",
        "table-filtering",
      ],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];

    const objLogin = JSON.parse(localStorage.getItem("userDetails"));
    const UserForms = JSON.parse(localStorage.getItem("userRights"));

    return (
      <div className="deznav">
        <PerfectScrollbar
          className="deznav-scroll"
          containerRef={(ref) => (this._scrollRef = ref)}
        >
          <MM className="metismenu" id="menu">
            {/* <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "" ? "mm-active" : ""}`}
                    to="/"
                    onClick={() => this.props.onClick3()}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "patient-list" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/patient-list"
                  >
                    Patient
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "patient-details" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/patient-details"
                  >
                    Patient Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "doctor-list" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/doctor-list"
                  >
                    Doctor
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "doctor-details" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/doctor-details"
                  >
                    Doctor Detail
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "reviews" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/reviews"
                  >
                    Review
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "task" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/task"
                  >
                    Task
                  </Link>
                </li>
              </ul>
            </li> */}
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter(
                (item) =>
                  (item.FormID == 1 ||
                    item.FormID == 2 ||
                    item.FormID == 3 ||
                    item.FormID == 4 ||
                    item.FormID == 5 ||
                    item.FormID == 6 ||
                    item.FormID == 7 ||
                    item.FormID == 8 ||
                    item.FormID == 9 ||
                    item.FormID == 10 ||
                    item.FormID == 11 ||
                    item.FormID == 12 ||
                    item.FormID == 13 ||
                    item.FormID == 14 ||
                    item.FormID == 15 ||
                    item.FormID == 16) &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length > 0) ? (
              <li className={`${Masters.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  <i className="flaticon-381-app"></i>
                  <span className="nav-text">Masters</span>
                </Link>
                <ul>
                  {objLogin.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 1 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "category-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/category-list"
                      >
                        Categories
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 && objLogin.CompanyType == 1 ? ( //only for HO Admin
                    <>
                      <li>
                        <Link
                          className={`${
                            path === "GST-plan" ? "mm-active" : ""
                          }`}
                          onClick={() => this.props.onClick()}
                          to="/GST-plan"
                        >
                          GST Plans
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${
                            path === "companies" ? "mm-active" : ""
                          }`}
                          onClick={() => this.props.onClick()}
                          to="/companies"
                        >
                          Companies
                        </Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 2 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${path === "user-list" ? "mm-active" : ""}`}
                        onClick={() => this.props.onClick()}
                        to="/user-list"
                      >
                        Users
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 3 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "client-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/client-list"
                      >
                        Clients
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 4 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "patient-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/patient-list"
                      >
                        Patients
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 5 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "staff-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/staff-list"
                      >
                        Staffs
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 6 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "staff-duty-slots" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/staff-duty-slots"
                      >
                        Staff Duty Slots
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 7 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "staff-duty-slots-date-wise"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/staff-duty-slots-date-wise"
                      >
                        Staff Duty Slots Date Wise
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 8 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "service-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/service-list"
                      >
                        Services
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 9 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "equipment-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/equipment-list"
                      >
                        Equipments
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 10 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "packages-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/packages-list"
                      >
                        Packages
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 11 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "pathology-reports-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/pathology-reports-list"
                      >
                        Pathology Reports
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 12 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "radiology-reports-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/radiology-reports-list"
                      >
                        Radiology Reports
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 13 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "health-checkup-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/health-checkup-list"
                      >
                        Health Checkup
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* <li>
                  <Link
                    className={`${
                      path === "enquiry-followup" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/enquiry-followup"
                  >
                    Enquiry Followup
                  </Link>
                </li> */}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 14 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${path === "expense" ? "mm-active" : ""}`}
                        onClick={() => this.props.onClick()}
                        to="/expense"
                      >
                        Expenses
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 15 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "recommendations" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/recommendations"
                      >
                        Recommendations
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 16 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "staff-awards" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/staff-awards"
                      >
                        Staff Awards
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 && objLogin.CompanyType == 1 ? ( //only for HO Admin
                    <li>
                      <Link
                        className={`${
                          path === "tutorial-videos" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/tutorial-videos"
                      >
                        Tutorial Videos
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* <li>
                  <Link
                    className={`${path === "app-profile" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/app-profile"
                  >
                    Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "post-details" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/post-details"
                  >
                    Post Details
                  </Link>
                </li>
                <li className={`${email.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#">
                    Email
                  </Link>
                  <ul className={`${email.includes(path) ? "mm-show" : ""}`}>
                    <li>
                      <Link
                        className={`${
                          path === "email-compose" ? "mm-active" : ""
                        }`}
                        to="/email-compose"
                        onClick={() => this.props.onClick()}
                      >
                        Compose
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "email-inbox" ? "mm-active" : ""
                        }`}
                        to="/email-inbox"
                        onClick={() => this.props.onClick()}
                      >
                        Inbox
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "email-read" ? "mm-active" : ""
                        }`}
                        to="/email-read"
                        onClick={() => this.props.onClick()}
                      >
                        Read
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={`${path === "app-calender" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/app-calender"
                  >
                    Calendar
                  </Link>
                </li>
                <li className={`${shop.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#">
                    Shop
                  </Link>
                  <ul className={`${shop.includes(path) ? "mm-show" : ""}`}>
                    <li>
                      <Link
                        className={`${
                          path === "ecom-product-grid" ? "mm-active" : ""
                        }`}
                        to="/ecom-product-grid"
                        onClick={() => this.props.onClick()}
                      >
                        Product Grid
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "ecom-product-list" ? "mm-active" : ""
                        }`}
                        to="/ecom-product-list"
                        onClick={() => this.props.onClick()}
                      >
                        Product List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "ecom-product-detail" ? "mm-active" : ""
                        }`}
                        to="/ecom-product-detail"
                        onClick={() => this.props.onClick()}
                      >
                        Product Details
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "ecom-product-order" ? "mm-active" : ""
                        }`}
                        to="/ecom-product-order"
                        onClick={() => this.props.onClick()}
                      >
                        Order
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "ecom-checkout" ? "mm-active" : ""
                        }`}
                        to="/ecom-checkout"
                        onClick={() => this.props.onClick()}
                      >
                        Checkout
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "ecom-invoice" ? "mm-active" : ""
                        }`}
                        to="/ecom-invoice"
                        onClick={() => this.props.onClick()}
                      >
                        Invoice
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "ecom-customers" ? "mm-active" : ""
                        }`}
                        to="/ecom-customers"
                        onClick={() => this.props.onClick()}
                      >
                        Customers
                      </Link>
                    </li>
                  </ul>
                </li> */}
                </ul>
              </li>
            ) : (
              ""
            )}

            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter(
                (item) =>
                  (item.FormID == 17 ||
                    item.FormID == 18 ||
                    item.FormID == 19 ||
                    item.FormID == 20 ||
                    item.FormID == 21 ||
                    item.FormID == 22 ||
                    item.FormID == 23) &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length > 0) ? (
              <li
                className={`${
                  MappingMasters.includes(path) ? "mm-active" : ""
                }`}
              >
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  onClick={() => {
                    this._scrollRef.scrollTop = 0;
                  }}
                >
                  <i className="flaticon-381-networking-1"></i>
                  <span className="nav-text">Mapping</span>
                </Link>
                <ul>
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 17 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${
                          path === "service-mapping-and-price-list"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/service-mapping-and-price-list"
                      >
                        Services Mapping
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 18 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "service-price-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/service-price-list"
                      >
                        Service Price List
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 19 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "equipment-mapping-and-price-list"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/equipment-mapping-and-price-list"
                      >
                        Equipments
                        {/* Mapping & Price List */}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 20 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "package-mapping-and-price-list"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/package-mapping-and-price-list"
                      >
                        Packages
                        {/* Mapping & Price List */}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 21 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "pathology-report-mapping-and-price-list"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/pathology-report-mapping-and-price-list"
                      >
                        Pathology Report
                        {/* Mapping & Price List */}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 22 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "radiology-report-mapping-and-price-list"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/radiology-report-mapping-and-price-list"
                      >
                        Radiology Report
                        {/* Mapping & Price List */}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 23 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "health-checkup-mapping-and-price-list"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/health-checkup-mapping-and-price-list"
                      >
                        Health Checkup
                        {/* Mapping & Price List */}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter(
                (item) =>
                  (item.FormID == 24 ||
                    item.FormID == 25 ||
                    item.FormID == 26 ||
                    item.FormID == 27 ||
                    item.FormID == 28 ||
                    item.FormID == 29 ||
                    item.FormID == 30 ||
                    item.FormID == 31 ||
                    item.FormID == 32 ||
                    item.FormID == 33 ||
                    item.FormID == 34 ||
                    item.FormID == 35 ||
                    item.FormID == 36 ||
                    item.FormID == 37 ||
                    item.FormID == 38 ||
                    item.FormID == 41 ||
                    item.FormID == 42 ||
                    item.FormID == 43 ||
                    item.FormID == 44) &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length > 0) ? (
              <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  onClick={() => {
                    this._scrollRef.scrollTop = 0;
                  }}
                >
                  <i className="flaticon-381-windows"></i>
                  <span className="nav-text">Transactions</span>
                </Link>

                <ul>
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 24 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "enquiry-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/enquiry-list"
                      >
                        Enquiries
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 25 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "order-booking-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/order-booking-list"
                      >
                        Order Booking
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 26 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "equipment-deposit-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/equipment-deposit-list"
                      >
                        Equipment Deposit
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 27 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "equipment-installation" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/equipment-installation"
                      >
                        Equipment Installation
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 28 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "equipment-return" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/equipment-return"
                      >
                        Equipment Return
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 29 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "order-booking-advance" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/order-booking-advance"
                      >
                        Advance Receipt
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 30 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "order-booking-advance-return"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/order-booking-advance-return"
                      >
                        Advance Return
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 31 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "activity-monitoring" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/activity-monitoring"
                      >
                        Activity Monitoring
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 32 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "schedule-package-and-healthcheckup"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/schedule-package-and-healthcheckup"
                      >
                        Package & Health Checkup Schedule
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 33 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "staff-leave-managment" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/staff-leave-managment"
                      >
                        Staff Leave Managment
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 34 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "complaint-managment" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/complaint-managment"
                      >
                        Complaint Managment
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 35 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "staff-payout" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/staff-payout"
                      >
                        Staff Payout
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 36 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "staff-over-time" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/staff-over-time"
                      >
                        Staff Over Time
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.CompanyType == 1 && //Only for HO User
                  (objLogin?.UserType == 1 ||
                    (objLogin?.UserType == 2 &&
                      UserForms?.filter(
                        (item) =>
                          item.FormID == 37 &&
                          (item.ViewAction ||
                            item.EditAction ||
                            item.DeleteAction ||
                            item.AddAction)
                      ).length > 0)) ? (
                    <li>
                      <Link
                        className={`${path === "feedbacks" ? "mm-active" : ""}`}
                        onClick={() => this.props.onClick()}
                        to="/feedbacks"
                      >
                        Feedbacks
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 38 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${path === "ratings" ? "mm-active" : ""}`}
                        onClick={() => this.props.onClick()}
                        to="/ratings"
                      >
                        Ratings and Reviews
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 41 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${path === "invoices" ? "mm-active" : ""}`}
                        onClick={() => this.props.onClick()}
                        to="/invoices"
                      >
                        Invoices
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 42 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "service-cancel-approval-reject"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/service-cancel-approval-reject"
                      >
                        Staff Service Cancel Request
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 43 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "assign-staff-for-equipment-installation"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/assign-staff-for-equipment-installation"
                      >
                        Assign Staff for Equipment Installation
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 44 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "assign-staff-for-equipment-return"
                            ? "mm-active"
                            : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/assign-staff-for-equipment-return"
                      >
                        Assign Staff for Equipment Return
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                {/* <ul>
                <li>
                  <Link
                    className={`${path === "ui-accordion" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-accordion"
                  >
                    Accordion
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-alert" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-alert"
                  >
                    Alert
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-badge" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-badge"
                  >
                    Badge
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-button" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-button"
                  >
                    Button
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-modal" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-modal"
                  >
                    Modal
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "ui-button-group" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/ui-button-group"
                  >
                    Button Group
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-list-group" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-list-group"
                  >
                    List Group
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "ui-media-object" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/ui-media-object"
                  >
                    Media Object
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-card" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-card"
                  >
                    Cards
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-carousel" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-carousel"
                  >
                    Carousel
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-dropdown" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-dropdown"
                  >
                    Dropdown
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-popover" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-popover"
                  >
                    Popover
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "ui-progressbar" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/ui-progressbar"
                  >
                    Progressbar
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-tab" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-tab"
                  >
                    Tab
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-typography" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-typography"
                  >
                    Typography
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-pagination" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-pagination"
                  >
                    Pagination
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "ui-grid" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/ui-grid"
                  >
                    Grid
                  </Link>
                </li>
              </ul> */}
              </li>
            ) : (
              ""
            )}

            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter(
                (item) =>
                  item.FormID == 39 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length > 0) ? (
              <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  onClick={() => {
                    this._scrollRef.scrollTop = 0;
                  }}
                >
                  <i className="flaticon-381-settings-2"></i>
                  <span className="nav-text">Configurations</span>
                </Link>
                <ul>
                  {objLogin?.UserType == 1 && objLogin.CompanyType == 1 ? ( //only for HO Admin
                    <>
                      <li>
                        <Link
                          className={`${
                            path === "configuration" ? "mm-active" : ""
                          }`}
                          onClick={() => this.props.onClick()}
                          to="/configuration"
                        >
                          Configuration
                        </Link>
                      </li>
                      {/* <li>
                          <Link
                            className={`${
                              path === "advance-slab-configuration" ? "mm-active" : ""
                            }`}
                            onClick={() => this.props.onClick()}
                            to="/advance-slab-configuration"
                          >
                            Advance Slab
                          </Link>
                    </li> */}
                      <li>
                        <Link
                          className={`${
                            path === "document-check-list" ? "mm-active" : ""
                          }`}
                          onClick={() => this.props.onClick()}
                          to="/document-check-list"
                        >
                          Document Check List
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={`${
                            path === "email-SMTP-setting" ? "mm-active" : ""
                          }`}
                          onClick={() => this.props.onClick()}
                          to="/email-SMTP-setting"
                        >
                          Email SMTP Setting
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={`${
                            path === "terms-and-condition" ? "mm-active" : ""
                          }`}
                          onClick={() => this.props.onClick()}
                          to="/terms-and-condition"
                        >
                          Terms & Condition
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${
                            path === "privacy-policy" ? "mm-active" : ""
                          }`}
                          onClick={() => this.props.onClick()}
                          to="/privacy-policy"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {objLogin?.UserType == 1 ||
                  (objLogin?.UserType == 2 &&
                    UserForms?.filter(
                      (item) =>
                        item.FormID == 39 &&
                        (item.ViewAction ||
                          item.EditAction ||
                          item.DeleteAction ||
                          item.AddAction)
                    ).length > 0) ? (
                    <li>
                      <Link
                        className={`${
                          path === "voucher-series-list" ? "mm-active" : ""
                        }`}
                        onClick={() => this.props.onClick()}
                        to="/voucher-series-list"
                      >
                        Voucher Series
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter(
                (item) =>
                  item.FormID == 40 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length > 0) ? (
              <li className={`${reportstab.includes(path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  onClick={() => {
                    this._scrollRef.scrollTop = 0;
                  }}
                >
                  <i className="flaticon-381-note"></i>
                  <span className="nav-text">Reports</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "reports" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to="/reports"
                    >
                      Reports
                    </Link>
                  </li>
                </ul>
                {/* <ul>
                <li>
                  <Link
                    className={`${path === "chart-rechart" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/chart-rechart"
                  >
                    RechartJs
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "chart-chartjs" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/chart-chartjs"
                  >
                    Chartjs
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "chart-chartist" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/chart-chartist"
                  >
                    Chartist
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "chart-sparkline" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/chart-sparkline"
                  >
                    Sparkline
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "chart-apexchart" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/chart-apexchart"
                  >
                    Apexchart
                  </Link>
                </li>
              </ul> */}
              </li>
            ) : (
              ""
            )}
            {/* <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-heart"></i>
                <span className="nav-text">Plugins</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "uc-select2" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/uc-select2"
                  >
                    Select 2
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "uc-nestable" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/uc-nestable"
                  >
                    Nestedable
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "uc-noui-slider" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/uc-noui-slider"
                  >
                    Noui Slider
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "uc-sweetalert" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/uc-sweetalert"
                  >
                    Sweet Alert
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "uc-toastr" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/uc-toastr"
                  >
                    Toastr
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "map-jqvmap" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/map-jqvmap"
                  >
                    Jqv Map
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "uc-lightgallery" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/uc-lightgallery"
                  >
                    Light Gallery
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${redux.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-book"></i>
                <span className="nav-text">Redux</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "todo" ? "mm-active" : ""}`}
                    to="/todo"
                  >
                    Todo
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "form-redux" ? "mm-active" : ""}`}
                    to="/form-redux"
                  >
                    Redux Form
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "form-redux-wizard" ? "mm-active" : ""
                    }`}
                    to="/form-redux-wizard"
                  >
                    Wizard Form
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="widget-basic" className="ai-icon">
                <i className="flaticon-381-settings-2"></i>
                <span className="nav-text">Widget</span>
              </Link>
            </li>
            <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-notepad"></i>
                <span className="nav-text forms">Forms</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "form-element" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/form-element"
                  >
                    Form Elements
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "form-wizard" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/form-wizard"
                  >
                    Wizard
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "form-editor-summernote" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/form-editor-summernote"
                  >
                    Summernote
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "form-pickers" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/form-pickers"
                  >
                    Pickers
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "form-validation-jquery" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/form-validation-jquery"
                  >
                    Jquery Validate
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${table.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-network"></i>
                <span className="nav-text">Table</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "table-bootstrap-basic" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/table-bootstrap-basic"
                  >
                    Bootstrap
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "table-datatable-basic" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/table-datatable-basic"
                  >
                    Datatable
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "table-filtering" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/table-filtering"
                  >
                    Table Filtering
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "table-sorting" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/table-sorting"
                  >
                    Table Sorting
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-layer-1"></i>
                <span className="nav-text">Pages</span>
              </Link>
              <ul>
                <li className={`${error.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#">
                    Error
                  </Link>
                  <ul>
                    <li>
                      <Link
                        className={`${
                          path === "page-error-400" ? "mm-active" : ""
                        }`}
                        to="/page-error-400"
                        onClick={() => this.props.onClick3()}
                      >
                        Error 400
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "page-error-403" ? "mm-active" : ""
                        }`}
                        to="/page-error-403"
                        onClick={() => this.props.onClick3()}
                      >
                        Error 403
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "page-error-404" ? "mm-active" : ""
                        }`}
                        to="/page-error-404"
                        onClick={() => this.props.onClick3()}
                      >
                        Error 404
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "page-error-500" ? "mm-active" : ""
                        }`}
                        to="/page-error-500"
                        onClick={() => this.props.onClick3()}
                      >
                        Error 500
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "page-error-503" ? "mm-active" : ""
                        }`}
                        to="/page-error-503"
                        onClick={() => this.props.onClick3()}
                      >
                        Error 503
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "page-lock-screen" ? "mm-active" : ""
                    }`}
                    to="/page-lock-screen"
                    onClick={() => this.props.onClick3()}
                  >
                    Lock Screen
                  </Link>
                </li>
              </ul>
            </li> */}
          </MM>
          {/* <div className="plus-box">
            <p className="fs-16 font-w500 mb-1">Check your job schedule</p>
            <Link to={"#"} className="text-white fs-26">
              <i className="las la-long-arrow-alt-right" />
            </Link>
          </div> */}
          {/* <div className="copyright">
            <p>
              <strong>Shrahav </strong> © 2023 All Rights Reserved
            </p>
            <p>
              Made with{" "}
              <span
                className={`${
                  this.state.loveEmoji ? "heart heart-blast" : "heart"
                }`}
                onClick={() =>
                  this.setState({ loveEmoji: !this.state.loveEmoji })
                }
              ></span>{" "}
              by Appegic
            </p>
          </div> */}
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
