import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Tab, Nav, Modal, Form, Accordion } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { get, post } from "../../../Helper/api_helper";
import * as Yup from "yup";
import { t } from "i18next";
import { Spin } from "antd";
import ModalPatientlist from "../../../Custom/ModalPatientList";
import { Field, FormikProvider, useFormik } from "formik";
import CustomInput from "../../../Custom/CustomInput";
import CustomSelect from "../../../Custom/CustomSelect";
import PaginationButtons from "../../../Custom/Pagination";
import {
  GET_CompanyDropDownList,
  POST_GetOrderBookingAdvanceDetailByOrderBookingID,
  POST_GetOrderBookingList,
  POST_GetSLAbyOrderBookingID,
  POST_OrderBookingDelete,
  POST_GetClientOrderBookingAdvancesReceipt,
  POST_OrderBookingClose,
  POST_OrderBookingAdvanceNotificationSend,
} from "../../../Helper/url_helper";
import swal from "sweetalert";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import moment from "moment";

const OrderBooking = () => {
  const [orderBookingModal, setOrderBookingModal] = useState(false);
  const [activeDefault, setActiveDefault] = useState(0);
  const [advanceDetailModal, setAdvanceDetailModal] = useState(false);
  const [sendNotificationlModal, setSendNotificationModal] = useState(false);
  const [reprintModal, setReprintModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [orderForceCloseModal, setOrderForceCloseModal] = useState(false);

  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [OrderBookingDataList, setOrderBookingDataList] = useState([]);
  const [selectedOrderBookingID, setSelectedOrderBookingID] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  let updatedSearchValue = searchValue;

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      OrderBookingStatus: 0,
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate, OrderBookingStatus },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setIsLoading(true);

    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyID: CompanyID,
      OrderBookingStatus: OrderBookingStatus,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",
    };

    await post(POST_GetOrderBookingList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setOrderBookingDataList(response?.AdminOrderBookingsList);
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (page > 1 && response?.AdminOrderBookingsList?.length === 0) {
          setCurrentPage(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const orderBookingStatusOptions = [
    { label: "All", value: 0 },
    { label: "Open", value: 1 },
    { label: "Close", value: 2 },
    { label: "Cancel", value: 3 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getData();
    loadCompanyDDL();
  }, []);

  // const delete_OrderBooking = (ID) => {
  //   swal({
  //     title: "Are you sure you want to delete the order booking?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       delete_OrderBookingConfirm(ID);
  //     }
  //   });
  // };

  // const delete_OrderBookingConfirm = async (ID) => {
  //   setIsLoading(true);
  //   await post(POST_OrderBookingDelete, {
  //     OrderBookingIDEncrypted: ID,
  //     LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
  //   })
  //     .then((response) => {
  //       if (response.IsSuccess) {
  //         swal({
  //           title: "Deleted",
  //           text: "Record has been deleted.",
  //           icon: "success",
  //           button: "OK",
  //         });
  //         getData(currentPage, currentSizePerPage);
  //         setIsLoading(false);
  //       } else {
  //         swal("Error!", t(response.MessageCode), "error");
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // };

  const validationSchemaForceClose = Yup.object().shape({
    OrderBookingCloseDate: Yup.string().required(
      "Order Close Date is required"
    ),
  });

  const formikForceClose = useFormik({
    validationSchema: validationSchemaForceClose,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitForceClose(values, formikHelpers),
    initialValues: {
      OrderBookingIDEncrypted: "",
      OrderBookingNo: "",
      OrderBookingDate: new Date(),
      OrderBookingCloseDate: new Date(),
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    },
    validateOnBlur: false,
  });

  const {
    values: { OrderBookingNo, OrderBookingCloseDate, OrderBookingDate },
  } = formikForceClose;

  useEffect(() => {
    if (!OrderBookingCloseDate) {
      formikForceClose.setFieldValue("OrderBookingCloseDate", "");
    }
  }, [OrderBookingCloseDate]);

  const handleValidSubmitForceClose = async (values) => {
    setIsLoading(true);
    await post(POST_OrderBookingClose, {
      ...values,
      OrderBookingCloseDate: `${moment(values.OrderBookingCloseDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setOrderForceCloseModal(false);
          swal({
            title: "Closed",
            text: "Order has been closed.",
            icon: "success",
            button: "OK",
          });
          getData(currentPage, currentSizePerPage);
          setIsLoading(false);
        } else {
          setOrderForceCloseModal(false);
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const formikAdvanceDetail = useFormik({
    initialValues: {
      AdvanceDetailList: [],
      TotalOrderAmount: "",
      TotalAdvanceAmount: "",
      TotalAdvanceBalance: "",
    },
  });

  const {
    values: {
      AdvanceDetailList,
      TotalOrderAmount,
      TotalAdvanceAmount,
      TotalAdvanceBalance,
    },
  } = formikAdvanceDetail;

  const GetAdvanceDetail = async (OrderID) => {
    setIsLoading(true);
    await post(POST_GetOrderBookingAdvanceDetailByOrderBookingID, {
      OrderBookingID: OrderID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          formikAdvanceDetail.setValues({
            AdvanceDetailList: response.AdminOrderBookingAdvancesDetails,
            TotalOrderAmount: response.TotalOrderAmount,
            TotalAdvanceAmount: response.TotalAdvanceAmount,
            TotalAdvanceBalance: response.TotalAdvanceBalance,
          });
          setIsLoading(false);
          formikNotificationMessage.resetForm();
          formikNotificationMessage.setFieldValue(
            "selectedOrderBookingID",
            OrderID
          );
          setAdvanceDetailModal(true);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaNotificationMessage = Yup.object().shape({
    NotificationMessage: Yup.string().required(
      "Notification Message is required field"
    ),
  });

  const formikNotificationMessage = useFormik({
    validationSchema: validationSchemaNotificationMessage,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitNotificationMessage(values, formikHelpers),
    initialValues: {
      selectedOrderBookingID: "",
      NotificationMessage:
        "Your Advance Amount is insufficient Kindly pay the advance amount to get continue service.",
    },
    validateOnBlur: false,
  });
  const {} = formikNotificationMessage;

  const handleValidSubmitNotificationMessage = async (values) => {
    setIsLoading(true);
    await post(POST_OrderBookingAdvanceNotificationSend, {
      OrderBookingIDEncrypted: values?.selectedOrderBookingID,
      ModifiedByEncrypted: AuthUser?.LoginIDEncrypt,
      AdvanceRemarks: values?.NotificationMessage,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal("Success!", t("Notification Send Successfully!"), "success");
          setSendNotificationModal(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getSLAByOrderBookingID = async (OrderBookingID) => {
    setIsLoading(true);
    await post(POST_GetSLAbyOrderBookingID, {
      OrderBookingID: OrderBookingID,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setIsLoading(false);
          window.open(response.FilePath, "_blank");
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getAdvanceReceipt = async (OrderBookingAdvanceID, isOriginal) => {
    setIsLoading(true);

    await post(POST_GetClientOrderBookingAdvancesReceipt, {
      OrderBookingAdvanceIDEncrypted: OrderBookingAdvanceID,
      IsOriginal: isOriginal,
      WithoutLetterHead: true,
    })
      .then((response) => {
        window.open(response.FilePath);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Transaction" activeMenu="Order Booking" />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Order Booking"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          {AuthUser?.CompanyType === 1 &&
                          AuthUser?.UserType == 1 ? (
                            <Row>
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col lg={3}>
                              <Field
                                label={"Order Status"}
                                name="OrderBookingStatus"
                                placeholder={"Select Order Status"}
                                options={orderBookingStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 25 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-lg-block">
                <div
                  onClick={() => {
                    setOrderBookingModal(true);
                  }}
                  className="btn btn-primary btn-xs btn-rounded"
                >
                  + Add Order
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  // setSearchValue(e.target.value);
                  updatedSearchValue = e.target.value;
                  setSearchValue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="order_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No: activate to sort column ascending"
                          style={{ width: "5%" }}
                        >
                          Order No.
                        </th>
                        {/* <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Company Name: activate to sort column ascending"
                        style={{ width: "25%" }}
                      >
                        Company Name
                      </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "25%" }}
                        >
                          Patient
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile No.: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Mobile No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Area: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Area
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Booking Date: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Booking Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Total Adv: activate to sort column ascending"
                          style={{ width: "10%", textAlign: "right" }}
                        >
                          Total Adv
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Bill Amt: activate to sort column ascending"
                          style={{ width: "10%", textAlign: "right" }}
                        >
                          Bill Amount
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Status
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        OrderBookingDataList?.map((element, index) => (
                          <tr role="row" className="odd" key={index}>
                            <td>{element.OrderBookingNo}</td>
                            <td>{element.PatientName}</td>
                            <td>{element.MobileNo}</td>
                            <td>{element.AreaName}</td>
                            <td>{element.OrderBookingDate}</td>
                            <td style={{ textAlign: "right" }}>
                              {element.AdvanceAmount}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {element.NetAmount}
                            </td>
                            <td>{element.OrderBookingStatus}</td>
                            <td>
                              <div className="d-flex">
                                <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Advance Detail"
                                  onClick={() => {
                                    GetAdvanceDetail(
                                      element.OrderBookingIDEncrypt
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-info"
                                    style={{ color: "#333b3d" }}
                                  ></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Reprint Receipt"
                                  onClick={() => {
                                    // window.open(
                                    //   "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                                    // );
                                    setSelectedOrderBookingID(
                                      element.OrderBookingIDEncrypt
                                    );
                                    setReprintModal(true);
                                  }}
                                >
                                  <i class="fa fa-print" aria-hidden="true"></i>
                                </Link>
                                {AuthUser?.UserType == 1 ||
                                (AuthUser?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 25 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    {element.OrderBookingStatus === "Open" ? (
                                      <Link
                                        to={`/edit-order-booking/${window.btoa(
                                          element.PatientIDEncrypt
                                        )}/${window.btoa(
                                          element.OrderBookingIDEncrypt
                                        )}`}
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Edit"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Link>
                                    ) : (
                                      <Link
                                        to="#"
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Edit"
                                        onClick={(e) => {
                                          swal(
                                            "Can't edit order booking!",
                                            "Order has been closed.",
                                            "warning"
                                          );
                                        }}
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Link>
                                    )}
                                    {/* <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Edit"
                                  onClick={(e) => {
                                    swal(
                                      "Coming Soon...",
                                      "Order Booking Edit",
                                      "info"
                                    );
                                  }}
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link> */}
                                    {/* <Link
                                    to="#"
                                    className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                    title="Active / Inactive"
                                  >
                                    <i
                                      className="fa fa-circle mt-1"
                                      style={{ color: "green" }}
                                    ></i>
                                  </Link> */}
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp"
                                      //title="Delete"
                                      title={element.OrderBookingStatus}
                                      onClick={() => {
                                        // delete_OrderBooking(
                                        //   element.OrderBookingIDEncrypt
                                        // );
                                        if (
                                          element.OrderBookingStatus === "Open"
                                        ) {
                                          formikForceClose.resetForm();
                                          formikForceClose.setFieldValue(
                                            "OrderBookingIDEncrypted",
                                            element.OrderBookingIDEncrypt
                                          );
                                          formikForceClose.setFieldValue(
                                            "OrderBookingNo",
                                            element.OrderBookingNo
                                          );
                                          formikForceClose.setFieldValue(
                                            "OrderBookingDate",
                                            element.OrderBookingDate
                                          );
                                          setOrderForceCloseModal(true);
                                        } else {
                                          swal(
                                            "",
                                            "Order has been closed.",
                                            "warning"
                                          );
                                        }
                                      }}
                                    >
                                      {element.OrderBookingStatus === "Open" ? (
                                        <i
                                          className="fa fa-unlock"
                                          style={{ color: "black" }}
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-lock"
                                          style={{ color: "red" }}
                                        ></i>
                                      )}
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9}>
                            <div className="mt-1 ml-1 mb-3">
                              {"No Record Found..."}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            show={orderBookingModal}
            onHide={setOrderBookingModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add Order</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderBookingModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalPatientlist></ModalPatientlist>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={advanceDetailModal}
            onHide={setAdvanceDetailModal}
            size="lg"
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Advance Detail</h4>
                <button
                  type="button"
                  className="btn close"
                  onClick={() => setAdvanceDetailModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <FormikProvider value={formikAdvanceDetail}>
                <Form onSubmit={formikAdvanceDetail.handleSubmit}>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="table-responsive">
                          <div
                            id="Advance_Detail_list"
                            className="dataTables_wrapper no-footer"
                          >
                            <table
                              id="example5"
                              className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                              role="grid"
                              aria-describedby="example5_info"
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Date: activate to sort column ascending"
                                    style={{
                                      width: "40%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Advance Recieved Date
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Advance Amount: activate to sort column ascending"
                                    style={{
                                      width: "50%",
                                      textAlign: "right",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Advance Amount
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Origional
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Duplicate
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {AdvanceDetailList?.length > 0 ? (
                                  AdvanceDetailList?.map((advance) => (
                                    <>
                                      <tr role="row" className="odd">
                                        <td>{advance.AdvanceReceivedDate}</td>
                                        <td style={{ textAlign: "right" }}>
                                          <i className="fa fa-rupee"></i>{" "}
                                          {advance.AdvanceAmount}
                                        </td>
                                        <td>
                                          <center>
                                            <Link
                                              to="#"
                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                              title="Reprint Origional Receipt"
                                              onClick={() => {
                                                getAdvanceReceipt(
                                                  advance.OrderBookingAdvanceIDEncrypt,
                                                  true
                                                );
                                              }}
                                            >
                                              <i
                                                class="fa fa-print"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Link
                                              to="#"
                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                              title="Reprint Duplicate Receipt"
                                              onClick={() => {
                                                getAdvanceReceipt(
                                                  advance.OrderBookingAdvanceIDEncrypt,
                                                  false
                                                );
                                              }}
                                            >
                                              <i
                                                class="fa fa-print"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </center>
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="mt-1 ml-1 mb-3">
                                        {"No Record Found..."}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <Row>
                      <Col lg={6}></Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={1}></Col>
                          <Col style={{ color: "black" }} lg={6}>
                            Total Order Amount
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={4}>
                            <i className="fa fa-rupee"></i> {TotalOrderAmount}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={1}></Col>
                          <Col style={{ color: "black" }} lg={6}>
                            Total Advance
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={4}>
                            <i className="fa fa-rupee"></i> {TotalAdvanceAmount}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={1}></Col>
                          <Col style={{ color: "black" }} lg={6}>
                            Advance Balance
                          </Col>
                          <Col lg={1}>:</Col>
                          <Col lg={4}>
                            <i className="fa fa-rupee"></i>{" "}
                            {TotalAdvanceBalance}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-4 mr-2" style={{ float: "right" }}>
                      <div
                        onClick={() => {
                          setSendNotificationModal(true);
                        }}
                        className="btn btn-primary btn-xs btn-rounded"
                      >
                        Send Notification
                      </div>{" "}
                      &nbsp;
                      <div
                        onClick={() => {
                          setAdvanceDetailModal(false);
                        }}
                        className="btn btn-light btn-xs btn-rounded"
                      >
                        Ok
                      </div>
                    </Row>
                    {sendNotificationlModal == true && (
                      <div class="fade modal-backdrop show"></div>
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>

          <Modal
            className="modal fade"
            show={sendNotificationlModal}
            onHide={setSendNotificationModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Send Notification</h4>
                <button
                  type="button"
                  className="btn close"
                  onClick={() => setSendNotificationModal(false)}
                >
                  <span>×</span>
                </button>
              </div>

              <FormikProvider value={formikNotificationMessage}>
                <Form onSubmit={formikNotificationMessage.handleSubmit}>
                  <div className="modal-body">
                    <Row>
                      <Col lg={12}>
                        <Field
                          type="textarea"
                          // label={"Pathology Report Name"}
                          name="NotificationMessage"
                          placeholder={"Enter Notification Message"}
                          component={CustomInput}
                          onChange={(e) => {
                            formikNotificationMessage.setFieldValue(
                              "NotificationMessage",
                              e.target.value.replace(
                                /[^A-Za-z 0-9 - + () ]/g,
                                ""
                              )
                            );
                          }}
                          rows={4}
                          style={{ resize: "none" }}
                          required
                        />
                      </Col>
                    </Row>
                    {/* <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Send
                </button>
                <button
                  type="button"
                  onClick={() => setSendNotificationModal(false)}
                  className="btn btn-light"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div> */}
                    <button
                      type="button"
                      onClick={() => setSendNotificationModal(false)}
                      className="btn btn-xs btn-light"
                      style={{ float: "right", marginLeft: "4px" }}
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                    &nbsp; &nbsp;{" "}
                    <button
                      type="submit"
                      className="btn btn-xs btn-primary"
                      style={{ float: "right" }}
                    >
                      Send
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={orderDetailModal}
            onHide={setOrderDetailModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalOrderBookingDetail
                orderBookingID={selectedOrderBookingID}
                setOrderDetailModal={setOrderDetailModal}
              ></ModalOrderBookingDetail>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={reprintModal}
            onHide={setReprintModal}
            backdrop={"static"}
            centered
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Reprint</h4>
                <button
                  type="button"
                  className="btn close"
                  onClick={() => setReprintModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div class="text-center">
                  <button
                    type="button"
                    className="btn btn-primary m-1"
                    onClick={() =>
                      getSLAByOrderBookingID(selectedOrderBookingID)
                    }
                  >
                    SLA Agreement
                  </button>
                  <button
                    type="button"
                    className="btn btn-light m-1"
                    onClick={() => setOrderDetailModal(true)}
                  >
                    Order Booking Details
                  </button>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light m-1"
                  onClick={() => setReprintModal(false)}
                >
                  Close
                </button>
              </div>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={orderForceCloseModal}
            onHide={setOrderForceCloseModal}
            backdrop={"static"}
            centered
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikForceClose}>
                <Form onSubmit={formikForceClose.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">{OrderBookingNo}</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setOrderForceCloseModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="swal-icon swal-icon--warning mt-0">
                      <span className="swal-icon--warning__body">
                        <span className="swal-icon--warning__dot"></span>
                      </span>
                    </div>
                    <div className="swal-title">
                      Are you sure you want to force close the order booking?
                    </div>
                    <div className="text-center">
                      You won't be able to revert this!
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div style={{ marginTop: "-5px" }}>
                      <Field
                        label={"Order Close Date"}
                        name="OrderBookingCloseDate"
                        placeholder={"Select Order Close Date"}
                        component={CustomFlatpickr}
                        options={{
                          minDate: new Date(OrderBookingDate),
                          altInput: true,
                          altFormat: "j-M-Y",
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </div>
                    <div>
                      <button type="submit" className="btn btn-danger m-1">
                        Force Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-light m-1"
                        onClick={() => setOrderForceCloseModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default OrderBooking;
