import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "reactstrap";
import CustomMultiSelect from "../../../Custom/CustomMultiSelect";
import { useHistory } from "react-router-dom";
import {
  POST_DocumentCheckListConfigurationSave,
  GET_GetDocumentCheckListConfiguration,
  POST_GetCategoryListByCategoryType,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

const DocumentCheckList = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [documentCheckListOptions, setDocumentCheckListOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchema = Yup.object().shape({
    StaffDocuments: Yup.array()
      .required("Staff Document Check List is required field")
      .min(1, "Staff Document Check List is required field")
      .nullable(),
    UserDocuments: Yup.array()
      .required("User Document Check List is required field")
      .min(1, "User Document Check List is required field")
      .nullable(),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      StaffDocuments: "",
      UserDocuments: "",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: { StaffDocuments, UserDocuments },
  } = formik;

  useEffect(() => {
    get_GetDocumentListOptions();
    get(GET_GetDocumentCheckListConfiguration).then((res) => {
      setIsLoading(true);
      if (res.IsSuccess) {
        let UserDocumentList = res.UserDocumentCheckList;
        let StaffDocumentList = res.StaffDocumentCheckList;
        setValues({
          UserDocuments:
            UserDocumentList.length > 0
              ? UserDocumentList.map((x) => ({
                  label: x.DocumentName,
                  value: x.DocumentID,
                }))
              : "",
          StaffDocuments:
            StaffDocumentList.length > 0
              ? StaffDocumentList.map((x) => ({
                  label: x.DocumentName,
                  value: x.DocumentID,
                }))
              : "",
        });
      }
      setIsLoading(false);
    });
  }, []);

  const get_GetDocumentListOptions = () => {
    setIsLoading(true);
    post(POST_GetCategoryListByCategoryType + "?CategoryType=5")
      .then((res) => {
        if (res.IsSuccess) {
          setDocumentCheckListOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      UserDocumentsIDs: Array.from(values.UserDocuments, (x) => x.value).join(
        ","
      ),
      StaffDocumentsIDs: Array.from(values.StaffDocuments, (x) => x.value).join(
        ","
      ),
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    };
    post(POST_DocumentCheckListConfigurationSave, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu="Document Check List"
          motherMenu="Configuration"
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <Field
                            label={"User Document Check List"}
                            name="UserDocuments"
                            placeholder="Select Documents"
                            options={documentCheckListOptions}
                            component={CustomMultiSelect}
                            isMulti={true}
                            required
                          />
                        </Col>
                        <Col lg={12}>
                          <Field
                            label={"Staff Document Check List"}
                            name="StaffDocuments"
                            placeholder="Select Documents"
                            options={documentCheckListOptions}
                            component={CustomMultiSelect}
                            isMulti={true}
                            required
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.push("/");
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default DocumentCheckList;
