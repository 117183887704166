import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Row, Col, ListGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import CustomSelect from "../../../Custom/CustomSelect";
import { post } from "../../../Helper/api_helper";
import PaginationButtons from "../../../Custom/Pagination";
import { CATEGORY_LIST } from "../../../../store/actions/CategoryAction";
import { CATEGORY_TYPE_LIST } from "../../../../store/actions/CategoryTypeAction";
import { useDispatch } from "react-redux";
import * as url from "../../../Helper/url_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import { getDate } from "date-fns";
// import { useSelector } from "react-redux";

const CategoryList = () => {
  const dispatch = useDispatch();
  const [tableColumnName, setTableColoumnName] = useState("State");
  // const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [categoryTypeList, setCategoryTypeList] = useState([]);
  const [categoryDataList, setCategoryDataList] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [categoryType, setCategoryType] = useState(1);
  const [categoryID, setCategoryID] = useState("");
  const [categoryStatus, setCategoryStatus] = useState(true);
  const [active, setActive] = useState(false);
  const [parentCategoryOptions, setParentCategoryOptions] = useState([]);
  const [ConfirmMode, setConfirmMode] = useState("");

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validationSchemaCategory = Yup.object().shape({
    CategoryType: Yup.number(),
    // CategoryName: Yup.string().required("Category Name is required field"),
    CategoryName: Yup.string().required(`${tableColumnName} is required field`),
    ParentCategoryID: Yup.string(),
  });

  const formikCategory = useFormik({
    validationSchema: validationSchemaCategory,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitCategory(values, formikHelpers),
    initialValues: {
      CategoryName: "",
      ParentCategoryID: 0,
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: { ParentCategoryID, CategoryType },
  } = formikCategory;

  const categoryTable = {
    columns: [tableColumnName],
  };

  ////Below code for API call

  // let categoryListSelector = useSelector(
  //   (state) => state.rootReducers.CategoryListReducer.CategoryList
  // );

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  useEffect(() => {
    formikCategory.resetForm();
    get_CategoryTypes();
  }, []);

  useEffect(() => {
    getData();
  }, [search]);

  const get_CategoryTypes = async () => {
    setIsLoading(true);
    let response = await dispatch(CATEGORY_TYPE_LIST());
    setCategoryTypeList(response.List);
    setIsLoading(false);
  };

  const handleOnChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    formikCategory.resetForm();
    getData();
  }, [categoryType]);

  useEffect(() => {
    if (categoryType == 2) {
      loadParentCategoryDDL(1);
    } else if (categoryType == 9) {
      //loadParentCategoryDDL(2);
      loadCityWithOutStateDDL();
    }
  }, [categoryType]);

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: search,
      Sorting: "",
      CategoryType: categoryType,
    };

    let response = await dispatch(CATEGORY_LIST(Payload));
    setRecordCount(response.TotalRecordCount);
    setCategoryDataList(response.List);
    //setCurrentPage(page);
    setCurrentSizePerPage(sizePerPage);
    setIsLoading(false);
    if (page > 1 && response?.List?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  const delete_Category = (cID) => {
    setConfirmMode("Delete");
    setCategoryID(cID);
    swal({
      title: `Are you sure you want to delete the ${tableColumnName}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setActive(true);
      }
    });
  };

  const delete_CategoryConfirm = async () => {
    setIsLoading(true);
    let response = await post(url.POST_CategoryDelete, {
      CategoryID: categoryID,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_Category = async (cID, cStatus) => {
    setConfirmMode("ActiveInactive");
    setCategoryID(cID);
    setCategoryStatus(cStatus);
    if (cStatus) {
      swal({
        title: `Are you sure you want to inactive the ${tableColumnName}?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setActive(true);
        }
      });
    } else {
      swal({
        title: `Are you sure you want to active the ${tableColumnName}?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setActive(true);
        }
      });
    }
  };

  useEffect(() => {
    if (active) {
      onConfirmClick();
      setActive(false);
    }
  }, [ConfirmMode, active]);

  const onConfirmClick = () => {
    if (ConfirmMode === "Delete") {
      delete_CategoryConfirm();
    } else if (ConfirmMode === "ActiveInactive") {
      ActiveInactive_CategoryConfirm();
    }
  };

  const ActiveInactive_CategoryConfirm = async () => {
    setIsLoading(true);
    let response = await post(url.POST_CategoryStatusUpdate, {
      CategoryID: categoryID,
      IsActive: !categoryStatus,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: categoryStatus ? "Inactivated" : "Activated",
        text: categoryStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const get_Category = async (cID, cType) => {
    setIsLoading(true);
    setCategoryID(cID);
    let response = await post(url.POST_GetCategoryByID + "?CategoryID=" + cID);
    if (response.IsSuccess) {
      // loadParentCategoryDDL(response.ParentCategoryType);
      setValues({
        CategoryName: response.CategoryName,
        ParentCategoryID: response.ParentCategoryID,
      });
      setCategoryStatus(response.IsActive);
      setEditCategoryModal(true);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const loadParentCategoryDDL = async (cType) => {
    setIsLoading(true);
    post(url.POST_GetCategoryListByCategoryType + "?CategoryType=" + cType)
      .then((res) => {
        if (res.IsSuccess) {
          setParentCategoryOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
    setIsLoading(false);
  };

  const loadCityWithOutStateDDL = async () => {
    setIsLoading(true);
    const Payload = {
      CurrentPage: 1,
      PageSize: 5000,
      Search: "",
      Sorting: "",
      CategoryType: 2,
    };

    post(url.POST_GetCategoryListAdminByCategoryTypeWithPagination, Payload)
      .then((res) => {
        if (res.IsSuccess) {
          setParentCategoryOptions(
            res.List.map((item) => ({
              label: item.CategoryName + " (" + item.ParentCategoryName + ")",
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
    setIsLoading(false);
  };

  const handleValidSubmitCategory = (values, { setSubmitting }) => {
    if (categoryType === 2 || categoryType === 9) {
      if (values.ParentCategoryID === "" || values.ParentCategoryID === 0) {
        formikCategory.setErrors({
          ParentCategoryID: `${
            tableColumnName == "City"
              ? "State"
              : tableColumnName == "Area"
              ? "City"
              : ""
          } is required field`,
        });
        return false;
      }
    }
    setIsLoading(true);
    const data = {
      CategoryType: categoryType,
      CategoryName: values.CategoryName,
      ParentCategoryID: values.ParentCategoryID ? values.ParentCategoryID : 0,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
      IsActive: categoryStatus ? categoryStatus : "",
      CategoryID: categoryID ? categoryID : 0,
    };
    post(categoryID ? url.POST_CategoryUpdate : url.POST_CategoryInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          formikCategory.resetForm();
          getData(1, currentSizePerPage);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
          formikCategory.resetForm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    if (categoryID) {
      setEditCategoryModal(false);
    }

    setIsLoading(false);
  };

  function add_category() {
    formikCategory.resetForm();
    setCategoryID("");
    setValues({
      ParentCategoryID: "",
      CategoryName: "",
    });
    setEditCategoryModal(!editCategoryModal);
  }

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle activeMenu="Categories" motherMenu="Master" />
        <Row>
          <div className="col-lg-12">
            <div className="form-head d-flex  align-items-start">
              {objLogin?.UserType == 1 ||
              (objLogin?.UserType == 2 &&
                UserForms?.filter((item) => item.FormID == 1 && item.AddAction)
                  .length > 0) ? (
                <div className="mr-auto d-none d-lg-block">
                  <div
                    onClick={() => add_category()}
                    className="btn-xs btn btn-primary btn-rounded"
                  >
                    + Add{" "}
                    {tableColumnName !== "" ? tableColumnName : "Category"}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="input-group search-area ml-auto d-inline-flex mr-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  value={search}
                  onChange={handleOnChangeSearch}
                />
                <div className="input-group-append">
                  <button type="button" className="input-group-text">
                    <i className="flaticon-381-search-2" />
                  </button>
                </div>
              </div>
            </div>
            <div className="basic-list-group">
              <Row>
                <Col lg="3" xl="3">
                  <ListGroup className="mb-4" id="list-tab">
                    {categoryTypeList != null &&
                      categoryTypeList.map((element) => {
                        return (
                          <>
                            <ListGroup.Item
                              action={element.CategoryType}
                              className={
                                element.Name === tableColumnName
                                  ? "categoryButton activeCategory"
                                  : "categoryButton"
                              }
                              onClick={() => {
                                setTableColoumnName(element.Name);
                                setCategoryType(element.CategoryType);
                                setFieldValue(
                                  "CategoryType",
                                  element.CategoryType
                                );
                              }}
                            >
                              {element.Name}
                            </ListGroup.Item>
                          </>
                        );
                      })}
                  </ListGroup>
                </Col>

                <Col lg="9" xl="9">
                  <Row className="mt-3">
                    <div className="table-responsive">
                      <div
                        id="category_list"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="example5"
                          className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            {categoryTable.columns.map((d, i) => (
                              <th
                                key={i}
                                style={
                                  categoryType == 2 || categoryType == 9
                                    ? { width: "40%" }
                                    : { width: "80%" }
                                }
                              >
                                {d}
                              </th>
                            ))}
                            {categoryType == 2 ? (
                              <th style={{ width: "40%" }}>State</th>
                            ) : (
                              ""
                            )}
                            {categoryType == 9 ? (
                              <th style={{ width: "40%" }}>City</th>
                            ) : (
                              ""
                            )}
                            <th style={{ width: "20%" }}>Actions</th>
                          </thead>
                          {recordCount > 0 ? (
                            <tbody>
                              {categoryDataList?.map((element, i) => (
                                <tr>
                                  <td key={i}>{element.CategoryName}</td>

                                  {element.ParentCategoryID != 0 ? (
                                    <td>{element.ParentCategoryName}</td>
                                  ) : (
                                    ""
                                  )}

                                  <td>
                                    <div className="d-flex">
                                      {objLogin?.UserType == 1 ||
                                      (objLogin?.UserType == 2 &&
                                        UserForms?.filter(
                                          (item) =>
                                            item.FormID == 1 && item.EditAction
                                        ).length > 0) ? (
                                        <>
                                          <div
                                            onClick={() => {
                                              get_Category(
                                                element.CategoryID,
                                                element.ParentCategoryID
                                              );
                                            }}
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="Edit"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </div>
                                          <Link
                                            // to="#"
                                            className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                            title={
                                              element.IsActive
                                                ? "Active"
                                                : "Inactive"
                                            }
                                            onClick={() => {
                                              ActiveInactive_Category(
                                                element.CategoryID,
                                                element.IsActive
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-circle mt-1"
                                              style={{
                                                color: element.IsActive
                                                  ? "green"
                                                  : "red",
                                              }}
                                            ></i>
                                          </Link>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {objLogin?.UserType == 1 ||
                                      (objLogin?.UserType == 2 &&
                                        UserForms?.filter(
                                          (item) =>
                                            item.FormID == 1 &&
                                            item.DeleteAction
                                        ).length > 0) ? (
                                        <Link
                                          // to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                          onClick={() => {
                                            delete_Category(element.CategoryID);
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          )}
                        </table>
                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing{" "}
                            {recordCount > 0
                              ? (currentPage - 1) * currentSizePerPage + 1
                              : 0}{" "}
                            to{" "}
                            {recordCount > currentPage * currentSizePerPage
                              ? currentPage * currentSizePerPage
                              : recordCount}{" "}
                            of {recordCount} entries
                          </div>
                          <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                            <PaginationButtons
                              totalPages={recordCount / currentSizePerPage}
                              currentPage={currentPage}
                              getData={getData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
        <Modal
          className="modal fade"
          show={editCategoryModal}
          onHide={setEditCategoryModal}
        >
          <div className="">
            <div className="">
              <FormikProvider value={formikCategory}>
                <Form onSubmit={formikCategory.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">{tableColumnName}</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setEditCategoryModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          {tableColumnName == "City" ||
                          tableColumnName == "Area" ? (
                            <Col lg={12}>
                              <Field
                                type="text"
                                label={
                                  tableColumnName == "City"
                                    ? "State"
                                    : tableColumnName == "Area"
                                    ? "City"
                                    : ""
                                }
                                name="ParentCategoryID"
                                placeholder={`Select ${
                                  tableColumnName == "City"
                                    ? "State"
                                    : tableColumnName == "Area"
                                    ? "City"
                                    : ""
                                }`}
                                options={
                                  // tableColumnName == "City"
                                  //   ? StateOptions
                                  //   : tableColumnName == "Area"
                                  //   ? CityOptions
                                  //   : ""
                                  parentCategoryOptions
                                }
                                component={CustomSelect}
                                required
                              />
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col lg={12}>
                            <Field
                              type="text"
                              label={tableColumnName}
                              name="CategoryName"
                              placeholder={`Enter ${tableColumnName}`}
                              // onChange={(e) => {
                              //   setFieldValue(
                              //     "CategoryName",
                              //     e.target.value.replace(/[^A-Za-z + -]/g, "")
                              //   );
                              // }}
                              component={CustomInput}
                              maxLength={150}
                              required
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => setEditCategoryModal(false)}
                      className="btn btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default CategoryList;
