import axios from "axios";
import { POST_RefreshToken } from "./url_helper";
import { user_logout } from "../../services/AuthService";
//import accessToken from "./accessToken";

//pass new generated access token here
var accessToken =
  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFkbWluIiwiYWRtaW4iOnRydWUsImp0aSI6ImQ2MTEwYzAxLWMwYjUtNDUzNy1iNDZhLTI0NTk5Mjc2YjY1NiIsImlhdCI6MTU5MjU2MDk2MCwiZXhwIjoxNTkyNTY0NjE5fQ.QgFSQtFaK_Ktauadttq1Is7f9w0SUtKcL8xCmkAvGLw";
const token = accessToken;

//apply base url for axios
const API_URL = process.env.REACT_APP_DATABASEURL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

var obj = JSON.parse(localStorage.getItem("userDetails"));
if (obj && obj.Token) {
  axiosApi.defaults.headers.common["Authorization"] = obj.Token;
} else {
  axiosApi.defaults.headers.common["Authorization"] = token;
}

// axiosApi.defaults.headers.common["Authorization"] = token

// axiosApi.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    //status 401 = Unauthorized then get new Authorization token using refresh token
    const originalRequest = error.config; //original requested api
    if (error?.response?.status == 401 && !originalRequest._retry) {
      originalRequest._retry = true; //handle infinite
      if (obj) {
        return axiosApi
          .post(POST_RefreshToken, {
            RefreshToken: obj?.RefreshToken,
          })
          .then((res) => {
            //console.log("new token response: ", res?.data);
            if (res?.data?.IsSuccess) {
              //set new token
              obj.Token = res?.data?.Token;
              obj.TokenExpiryDate = res?.data?.TokenExpiryDate;
              obj.RefreshToken = res?.data?.RefreshToken;
              obj.RefreshTokenExpiryDate = res?.data?.RefreshTokenExpiryDate;
              localStorage.setItem("userDetails", JSON.stringify(obj));

              axiosApi.defaults.headers.common["Authorization"] =
                res?.data?.Token;

              originalRequest.headers.Authorization = res?.data?.Token;
              return axiosApi(originalRequest); //to call original requested api after new token set
            } else {
              //logout & redirect to login screen
              user_logout(obj?.LoginIDEncrypt);
              setTimeout(() => {
                localStorage.removeItem("userDetails");
                localStorage.removeItem("userRights");
                //localStorage.removeItem("FCMToken");
                window.location = "/login";
              }, 1000);
            }
          })
          .catch((er) => {
            //logout & redirect to login screen
            user_logout(obj?.LoginIDEncrypt);
            setTimeout(() => {
              localStorage.removeItem("userDetails");
              localStorage.removeItem("userRights");
              //localStorage.removeItem("FCMToken");
              window.location = "/login";
            }, 1000);
          });
      } else {
        //user auth details not found then redirect to login screen
        localStorage.removeItem("userDetails");
        localStorage.removeItem("userRights");
        //localStorage.removeItem("FCMToken");
        window.location = "/login";
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
export async function postFormData(url, data) {
  return axiosApi
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
}
