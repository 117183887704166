import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { Row, Col, Tab, Nav, Modal } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import moment from "moment";
import {
  GET_GetHeightDDL,
  GET_GetWeightDDL,
  POST_ClientPatientAddressInsert,
  POST_GetAreaList,
  POST_GetBloodGroupList,
  POST_GetCityList,
  POST_GetPatientAddressList,
  POST_GetPatientDetailByID,
  POST_GetPatientListByMobileOrEmail,
  POST_GetRelationList,
  POST_GetStateList,
  POST_PatientAadharCardUpload,
  POST_PatientCreate,
  POST_PatientPastHistoryInsert,
  POST_PatientPastHistoryUpdate,
  POST_PatientUpdate,
  POST_ClientPatientAddressUpdate,
} from "../../../Helper/url_helper";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { List } from "antd";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

const AddPatient = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { PatientIDEncryptParam } = useParams();
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const [tab, setTab] = useState(1);
  const [addressModal, setAddressModal] = useState(false);
  const [patientPastHistoryModel, setPatientPastHistoryModal] = useState(false);
  const [patientListModel, setPatientListModal] = useState(false);

  const [country, setCountry] = useState("in");
  const [countryAlertnative, setCountryAlertnative] = useState("in");

  const [heightOptions, setHeightOptions] = useState([]);
  const [weightOptions, setWeightOptions] = useState([]);
  const [bloodGroupOptions, setBloodGroupOptions] = useState([]);
  const [relationOptions, setRelationOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);

  const [aadharCard, setAadharCard] = useState(null);
  const [aadharCardFile, setAadharCardFile] = useState(null);
  const [fileKeyAadharCard, setFileKeyAadharCard] = useState();

  const [pastHistoryList, setPastHistoryList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [PatientDataList, setPatientDataList] = useState([]);

  const [addressSuggesionDivStatus, setAddressSuggesionDivStatus] =
    useState(false);

  const { placePredictions, getPlacePredictions, isPlacePredictionLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const optionGender = [
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
  ];

  const optionMaritalStatus = [
    { label: "Unmarried", value: 1 },
    { label: "Married", value: 2 },
    { label: "Divorced", value: 3 },
    { label: "Widow", value: 4 },
  ];

  const loadHeightDDL = async () => {
    await get(GET_GetHeightDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setHeightOptions(
            res.HeightDetailDDLList.map((item) => ({
              label: item.Height + " - " + item.HeightCms + " cms",
              value: item.HeightDetailID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadWeightDDL = async () => {
    await get(GET_GetWeightDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setWeightOptions(
            res.WeightDetailDDLList.map((item) => ({
              label: item.Weight,
              value: item.WeightDetailID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadBloodGroupDDL = async () => {
    await post(POST_GetBloodGroupList)
      .then((res) => {
        if (res.IsSuccess) {
          setBloodGroupOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadRelationDDL = async () => {
    await post(POST_GetRelationList)
      .then((res) => {
        if (res.IsSuccess) {
          setRelationOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadStateDDL = async () => {
    await post(POST_GetStateList)
      .then((res) => {
        if (res.IsSuccess) {
          setStateOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadCityDDL = async (selectedStateID) => {
    await post(POST_GetCityList + selectedStateID)
      .then((res) => {
        if (res.IsSuccess) {
          setCityOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            CityID &&
            res.List?.filter((x) => x.CategoryID == CityID).length == 0
          ) {
            formikAddress.setFieldValue("CityID", "");
            formikAddress.setFieldValue("AreaID", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadAreaDDL = async (selectedCityID) => {
    await post(POST_GetAreaList + selectedCityID)
      .then((res) => {
        if (res.IsSuccess) {
          setAreaOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            AreaID &&
            res.List?.filter((x) => x.CategoryID == AreaID).length == 0
          ) {
            formikAddress.setFieldValue("AreaID", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const getAddressList = async () => {
    setIsLoading(true);
    await post(POST_GetPatientAddressList, {
      ClientIDEncrypt: ClientIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          //console.log("sub", res.ClientPatientAddressesList);
          setAddressList(res.ClientPatientAddressesList);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required field"),
    LastName: Yup.string().required("Last Name is required field"),
    Gender: Yup.string().required("Gender is required field"),
    EmailID: Yup.string(), //.required("EmailID is required field"),
    MobileNo: Yup.string().required("Mobile No. is required field"),
    MiddleName: Yup.string().required("Middle Name is required field"),
    DateOfBirth: Yup.string().required("Date Of Birth is required field"),
    MaritalStatus: Yup.string(), //.required("Marital Status is required field"),
    HeightID: Yup.string(), //.required("Height is required field"),
    WeightID: Yup.string(), //.required("Weight is required field"),
    BloodGroupID: Yup.string(),
    // .required("Blood Group is required field")
    RelationWithClientID: Yup.string().required(
      "Relation With Client is required field"
    ),
    IsCoMorbitCondition: Yup.boolean(),
    CoMorbitCondition: Yup.string().when("IsCoMorbitCondition", {
      is: true,
      then: Yup.string().required("Co Morbit Condition Field is required"),
    }),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      PatientIDEncrypt: PatientIDEncryptParam
        ? window.atob(PatientIDEncryptParam)
        : "",
      ClientIDEncrypt: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Gender: "",
      EmailID: "",
      MobileNo: "",
      DateOfBirth: "",
      MaritalStatus: "",
      HeightID: "",
      WeightID: "",
      AlertnativeMobile: "",
      BloodGroupID: "",
      RelationWithClientID: "",
      CountryCode: "+91",
      CountryCodeAlternative: "+91",
      IsDiabetic: false,
      IsCoMorbitCondition: false,
      CoMorbitCondition: "",
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: {
      CountryCode,
      CountryCodeAlternative,
      PatientIDEncrypt,
      IsDiabetic,
      IsCoMorbitCondition,
      ClientIDEncrypt,
      MobileNo,
      EmailID,
      DateOfBirth,
    },
  } = formik;

  const uploadAadharCardFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /(\.|\/)(jpe?g|png|webp|pdf|svg|gif|ico)$/;
      if (!file.type.match(pattern)) {
        setAadharCardFile(null);
        setFileKeyAadharCard(Math.random().toString(9).substring(2, 9));
        swal("", "Select valid file format (PDF/Image)", "warning");
      } else {
        setAadharCardFile(file);
        setFileKeyAadharCard(Math.random().toString(9).substring(2, 9));
      }
    } else {
      setAadharCardFile(null);
      setFileKeyAadharCard(Math.random().toString(9).substring(2, 9));
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    var paylod = {
      ...values,
      HeightID: values.HeightID ? values.HeightID : 0,
      WeightID: values.WeightID ? values.WeightID : 0,
      MaritalStatus: values.MaritalStatus ? values.MaritalStatus : 0,
      BloodGroupID: values.BloodGroupID ? values.BloodGroupID : 0,
      DateOfBirth: `${moment(values.DateOfBirth)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      CountryCode: "+" + CountryCode.replace(/\+/g, ""),
      CountryCodeAlternative: "+" + CountryCodeAlternative.replace(/\+/g, ""),
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
    };
    //console.log("payload: ", paylod);

    post(PatientIDEncrypt ? POST_PatientUpdate : POST_PatientCreate, paylod)
      .then((response) => {
        if (response.IsSuccess) {
          if (!PatientIDEncrypt) {
            setFieldValue("PatientIDEncrypt", response.PatientIDEncrypt);
          }
          if (!ClientIDEncrypt) {
            setFieldValue("ClientIDEncrypt", response.ClientIDEncrypt);
          }
          if (aadharCardFile) {
            setIsLoading(true);
            let formData = new FormData();
            formData.append(
              "EncryptPatientID",
              !PatientIDEncrypt ? response.PatientIDEncrypt : PatientIDEncrypt
            );
            formData.append("AadharCardFile", aadharCardFile);

            postFormData(POST_PatientAadharCardUpload, formData)
              .then((res) => {
                if (res.IsSuccess) {
                  setAadharCard(res.AadharCardFile);
                  setIsLoading(false);
                } else {
                  console.log(res);
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
              });
          }
          if (localStorage.getItem("AddPatientFromOrder")) {
            localStorage.removeItem("AddPatientFromOrder");
            history.push(
              `/add-order-booking/${window.btoa(response.PatientIDEncrypt)}`
            );
          } else {
            swal("Success!", t(response.MessageCode), "success");
            setTab(2);
          }
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaAddress = Yup.object().shape({
    Address: Yup.string().required("Address is required field"),
    StateID: Yup.string().required("State is required field"),
    CityID: Yup.string().required("City is required field"),
    AreaID: Yup.string().required("Area is required field"),
    Location: Yup.string().required("Location is required field"),
    Pincode: Yup.string().required("Pincode is required field"),
  });

  const formikAddress = useFormik({
    validationSchema: validationSchemaAddress,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitAddress(values, formikHelpers),
    initialValues: {
      PatientAddressIDEncrypt: "",
      Address: "",
      StateID: "",
      CityID: "",
      AreaID: "",
      Location: "",
      Pincode: "",
      Latitude: 0,
      Longitude: 0,
    },
    validateOnBlur: false,
  });
  const {
    values: { StateID, CityID, AreaID, PatientAddressIDEncrypt },
  } = formikAddress;

  const handleValidSubmitAddress = (values) => {
    setIsLoading(true);
    var paylod = {
      ...values,
      PatientAddressIDEncrypt: PatientAddressIDEncrypt,
      ClientIDEncrypt: ClientIDEncrypt,
      Latitude: values.Latitude,
      Longitude: values.Longitude,
    };
    //console.log("payload: ", paylod);
    post(
      PatientAddressIDEncrypt === ""
        ? POST_ClientPatientAddressInsert
        : POST_ClientPatientAddressUpdate,
      paylod
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal("Success!", t(response.MessageCode), "success");
          setAddressModal(false);
          formikAddress.resetForm();
          getAddressList();
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaPastHistory = Yup.object().shape({
    PastHistory: Yup.string().required(
      "Patient Past History is required field"
    ),
  });

  const formikPastHistory = useFormik({
    validationSchema: validationSchemaPastHistory,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitPastHistory(values, formikHelpers),
    initialValues: {
      PatientPastHistoryIDEncrypted: "",
      PastHistory: "",
    },
    validateOnBlur: false,
  });
  const {
    values: { PatientPastHistoryIDEncrypted },
  } = formikPastHistory;

  const handleValidSubmitPastHistory = (values) => {
    setIsLoading(true);

    var paylod = {
      ...values,
      PatientIDEncrypted: PatientIDEncrypt,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
    };
    //console.log("payload: ", paylod);

    post(
      PatientPastHistoryIDEncrypted
        ? POST_PatientPastHistoryUpdate
        : POST_PatientPastHistoryInsert,
      paylod
    )
      .then((response) => {
        if (response.IsSuccess) {
          if (!PatientPastHistoryIDEncrypted) {
            formikPastHistory.setFieldValue(
              "PatientPastHistoryIDEncrypted",
              response.EncryptID
            );
          }
          swal("Success!", t(response.MessageCode), "success");
          setTab(3);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const updatePastHistory = (PastHistoryID, PastHistory, index) => {
    if (PastHistoryID && PastHistory) {
      setIsLoading(true);

      var paylod = {
        PatientPastHistoryIDEncrypted: PastHistoryID,
        PastHistory: PastHistory,
        LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
      };
      //console.log("payload: ", paylod);

      post(POST_PatientPastHistoryUpdate, paylod)
        .then((response) => {
          if (response.IsSuccess) {
            setPastHistoryList(
              pastHistoryList?.map((x, i) => {
                if (index == i) {
                  return {
                    ...x,
                    PastHistory: PastHistory,
                    editMode: false,
                  };
                }
                return x;
              })
            );
            swal("Success!", t(response.MessageCode), "success");
          } else {
            swal("Error!", t(response.MessageCode), "error");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    loadHeightDDL();
    loadWeightDDL();
    loadBloodGroupDDL();
    loadRelationDDL();
    loadStateDDL();
    if (PatientIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetPatientDetailByID, {
        PatientIDEncrypted: window.atob(PatientIDEncryptParam),
      })
        .then((response) => {
          if (response?.IsSuccess) {
            setValues({
              ...response,
              DateOfBirth: new Date(response.DateOfBirth),
            });
            if (response.AadharCardFilePath) {
              setAadharCard(response.AadharCardFilePath);
            }
            //setPastHistoryList([...response.PastHistoryList]);
            setPastHistoryList(
              response.PastHistoryList?.map((x) => {
                return {
                  ...x,
                  PastHistoryUpdated: x.PastHistory,
                  editMode: false,
                };
              })
            );
            setAddressList([...response.AddressList]);
            //console.log("response", response);
            // console.log("Address", [...response.AddressList]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (StateID) {
      loadCityDDL(StateID ? StateID : 0);
    } else {
      setCityOptions([]);
      setAreaOptions([]);
      formikAddress.setFieldValue("CityID", "");
      formikAddress.setFieldValue("AreaID", "");
    }
  }, [StateID]);

  useEffect(() => {
    if (CityID) {
      loadAreaDDL(CityID ? CityID : 0);
    } else {
      setAreaOptions([]);
      formikAddress.setFieldValue("AreaID", "");
    }
  }, [CityID]);

  const getPatientListByMobileOrEmail = () => {
    if ((MobileNo || EmailID) && !PatientIDEncrypt) {
      post(POST_GetPatientListByMobileOrEmail, {
        MobileNo: MobileNo,
        EmailID: EmailID,
      })
        .then((response) => {
          setPatientDataList(response?.PatientsList);
          if (response?.PatientsList.length > 0) {
            setPatientListModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (!DateOfBirth) {
      setFieldValue("DateOfBirth", "");
    }
  }, [DateOfBirth]);

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={`${PatientIDEncryptParam ? "Edit" : "Add"} Patient`}
          motherMenu={<Link to="/patient-list">Patients</Link>}
        />

        <div className="custom-tab-1">
          <Tab.Container activeKey={tab}>
            {!localStorage.getItem("AddPatientFromOrder") ? (
              <Nav as="ul" className="nav-tabs">
                <Nav.Item as="li" key={1}>
                  <Nav.Link eventKey={1} onClick={() => setTab(1)}>
                    {"Patient Details"}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key={2}>
                  <Nav.Link
                    eventKey={2}
                    disabled={PatientIDEncrypt ? false : true}
                    onClick={() => setTab(2)}
                  >
                    {"Patient Past History"}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key={3}>
                  <Nav.Link
                    eventKey={3}
                    disabled={PatientIDEncrypt ? false : true}
                    onClick={() => setTab(3)}
                  >
                    {"Patient Address"}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            ) : (
              ""
            )}
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey={1} key={1}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="basic-form">
                          <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                              <Row>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"First Name"}
                                    name="FirstName"
                                    id="FirstName"
                                    placeholder={"Enter First Name"}
                                    component={CustomInput}
                                    required
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Middle Name"}
                                    name="MiddleName"
                                    id="MiddleName"
                                    placeholder={"Enter Middle Name"}
                                    component={CustomInput}
                                    required
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Last Name"}
                                    name="LastName"
                                    id="LastName"
                                    placeholder={"Enter Last Name"}
                                    component={CustomInput}
                                    required
                                  />
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Mobile No."}
                                    name="MobileNo"
                                    id="MobileNo"
                                    placeholder={"Enter Mobile No."}
                                    country={country}
                                    countryCode={CountryCode}
                                    component={CustomPhoneInput}
                                    onChange={(
                                      value,
                                      { countryCode, dialCode }
                                    ) => {
                                      setFieldValue("CountryCode", dialCode);
                                      setFieldValue(
                                        "MobileNo",
                                        value.substring(
                                          dialCode.toString().length
                                        )
                                      );
                                    }}
                                    required
                                    onBlur={() => {
                                      getPatientListByMobileOrEmail();
                                    }}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Alertnative Mobile No."}
                                    name="AlertnativeMobile"
                                    id="AlertnativeMobile"
                                    placeholder={"Enter Alertnative Mobile No."}
                                    country={countryAlertnative}
                                    countryCode={CountryCodeAlternative}
                                    component={CustomPhoneInput}
                                    onChange={(
                                      value,
                                      { countryCode, dialCode }
                                    ) => {
                                      setFieldValue(
                                        "CountryCodeAlternative",
                                        dialCode
                                      );
                                      setFieldValue(
                                        "AlertnativeMobile",
                                        value.substring(
                                          dialCode.toString().length
                                        )
                                      );
                                    }}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Field
                                    type="text"
                                    label={"EmailID"}
                                    name="EmailID"
                                    placeholder={"Enter EmailID"}
                                    component={CustomInput}
                                    //required
                                    onBlur={() => {
                                      getPatientListByMobileOrEmail();
                                    }}
                                  />
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Date of Birth"}
                                    name="DateOfBirth"
                                    id="DateOfBirth"
                                    placeholder={"Select Date of Birth"}
                                    component={CustomFlatpickr}
                                    options={{
                                      altInput: true,
                                      altFormat: "j-M-Y",
                                      dateFormat: "d-m-Y",
                                      maxDate: new Date(),
                                    }}
                                    required
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Gender"}
                                    name="Gender"
                                    id="Gender"
                                    options={optionGender}
                                    placeholder={"Select Gender"}
                                    component={CustomSelect}
                                    required
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Marital Status"}
                                    name="MaritalStatus"
                                    id="MaritalStatus"
                                    options={optionMaritalStatus}
                                    placeholder={"Select Marital Status"}
                                    component={CustomSelect}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Height"}
                                    name="HeightID"
                                    placeholder={"Select Height"}
                                    options={heightOptions}
                                    component={CustomSelect}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Weight"}
                                    name="WeightID"
                                    placeholder={"Select Weight"}
                                    options={weightOptions}
                                    component={CustomSelect}
                                  />
                                </Col>

                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Blood Group"}
                                    name="BloodGroupID"
                                    options={bloodGroupOptions}
                                    placeholder={"Select Blood Group"}
                                    component={CustomSelect}
                                    // required
                                  />
                                </Col>

                                <Col lg={3}>
                                  <Field
                                    type="text"
                                    label={"Relation With Client"}
                                    name="RelationWithClientID"
                                    options={relationOptions}
                                    // placeholder={"Select Relation With Client"}
                                    component={CustomSelect}
                                    required
                                  />
                                </Col>
                                <Col lg={3}>
                                  <div
                                    className="custom-control custom-checkbox mb-3 checkbox-success"
                                    style={{ marginTop: "40px", zIndex: 0 }}
                                  >
                                    <input
                                      type="checkbox"
                                      defaultChecked
                                      className="custom-control-input"
                                      id="chkDiabetic"
                                      checked={IsDiabetic}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "IsDiabetic",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="chkDiabetic"
                                    >
                                      Is Diabetic
                                    </label>
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div
                                    className="custom-control custom-checkbox mb-3 checkbox-success"
                                    style={{ marginTop: "40px", zIndex: 0 }}
                                  >
                                    <input
                                      type="checkbox"
                                      defaultChecked
                                      className="custom-control-input"
                                      id="chkCoMorbitCondition"
                                      checked={IsCoMorbitCondition}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "IsCoMorbitCondition",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="chkCoMorbitCondition"
                                    >
                                      Is Co Morbit Condition
                                    </label>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <Field
                                    type="text"
                                    label={"Co Morbit Condition"}
                                    name="CoMorbitCondition"
                                    placeholder={"Enter Co Morbit Condition"}
                                    component={CustomInput}
                                    required={IsCoMorbitCondition}
                                    disabled={!IsCoMorbitCondition}
                                  />
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row className="mb-3">
                                <Col lg={4}>
                                  <Label>
                                    Aadhar Card / Photo Identify Proof
                                  </Label>
                                  <div
                                    className="input-group mb-3"
                                    style={{ zIndex: "0" }}
                                  >
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        name="fileControlAadharCard"
                                        onChange={(e) => {
                                          uploadAadharCardFile(e);
                                        }}
                                        key={fileKeyAadharCard || ""}
                                        //required={aadharCardFile ? false : true}
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="fileControlAadharCard"
                                      >
                                        {aadharCardFile
                                          ? aadharCardFile?.name
                                          : "Choose file"}
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                                {aadharCard ? (
                                  <Col lg={8}>
                                    <Label>&nbsp;</Label>
                                    <div className="input-group mb-3">
                                      <Link
                                        className="btn btn-light shadow sharp mr-1"
                                        onClick={() => {
                                          window.open(aadharCard, "_blank");
                                        }}
                                      >
                                        View Identify Proof
                                      </Link>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                              <button
                                type="submit"
                                className="btn mr-2 mt-2 btn-primary"
                              >
                                Submit
                              </button>
                              {PatientIDEncrypt ? (
                                <button
                                  type="button"
                                  className="btn mr-2 mt-2 btn-primary"
                                  onClick={() => {
                                    setTab(2);
                                  }}
                                >
                                  Next
                                </button>
                              ) : (
                                ""
                              )}
                            </Form>
                          </FormikProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={2} key={2}>
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <FormikProvider value={formikPastHistory}>
                        <Form onSubmit={formikPastHistory.handleSubmit}>
                          <Row className="mb-3">
                            <Col lg={9}>
                              <Field
                                type="textarea"
                                label={"Patient Past History"}
                                name="PastHistory"
                                placeholder={"Enter Patient Past History"}
                                component={CustomInput}
                                rows={6}
                                required
                                style={{ resize: "none" }}
                                maxLength={500}
                              />
                            </Col>
                            {pastHistoryList?.length > 0 ? (
                              <Col lg={3}>
                                <div
                                  onClick={() => {
                                    setPatientPastHistoryModal(true);
                                  }}
                                  className="btn btn-light btn-xxs btn-rounded mt-4"
                                >
                                  Show Patient Past History
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                          <button
                            type="submit"
                            className="btn mr-2 btn-primary"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn mr-2 btn-primary"
                            onClick={() => {
                              setTab(1);
                            }}
                          >
                            Previous
                          </button>
                          <button
                            type="button"
                            className="btn mr-2 btn-primary"
                            onClick={() => {
                              setTab(3);
                            }}
                          >
                            Next
                          </button>
                        </Form>
                      </FormikProvider>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={3} key={3}>
                <div className="card">
                  <div className="card-body">
                    <div className="basic-form">
                      <Row>
                        <Col lg={12} style={{ textAlign: "right" }}>
                          <button
                            className="btn btn-primary btn-xs btn-rounded mb-2"
                            onClick={() => {
                              formikAddress.resetForm();
                              setAddressModal(true);
                            }}
                          >
                            + Add New
                          </button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        {addressList?.map((element) => (
                          <Col lg={4}>
                            <div className="card">
                              <div
                                className="card-body"
                                style={{
                                  border: "1px solid lightgray",
                                  borderRadius: "5%",
                                  color: "#3e4954",
                                }}
                              >
                                <div className="media">
                                  <span className="p-3 border border-primary-light rounded-circle mr-3">
                                    <svg
                                      width={22}
                                      height={22}
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0)">
                                        <path
                                          d="M27.5716 13.4285C27.5716 22.4285 16.0001 30.1428 16.0001 30.1428C16.0001 30.1428 4.42871 22.4285 4.42871 13.4285C4.42871 10.3596 5.64784 7.41637 7.8179 5.24631C9.98797 3.07625 12.9312 1.85712 16.0001 1.85712C19.0691 1.85712 22.0123 3.07625 24.1824 5.24631C26.3524 7.41637 27.5716 10.3596 27.5716 13.4285Z"
                                          stroke="#2BC155"
                                          strokeWidth={3}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M16.0002 17.2857C18.1305 17.2857 19.8574 15.5588 19.8574 13.4286C19.8574 11.2983 18.1305 9.57141 16.0002 9.57141C13.87 9.57141 12.1431 11.2983 12.1431 13.4286C12.1431 15.5588 13.87 17.2857 16.0002 17.2857Z"
                                          stroke="#2BC155"
                                          strokeWidth={3}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect
                                            width="30.8571"
                                            height="30.8571"
                                            fill="white"
                                            transform="translate(0.571533 0.571411)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                  <div className="media-body">
                                    <Row>
                                      <Col lg={9}>
                                        <span className="d-block text-light mb-2">
                                          Address
                                        </span>
                                        {element.Address},<br></br>
                                        {element.AreaName},<br></br>
                                        {element.CityName} - {element.PinCode},
                                        <br></br>
                                        {element.StateName}
                                      </Col>
                                      <Col lg={2}>
                                        <button
                                          onClick={() => {
                                            formikAddress.setFieldValue(
                                              "PatientAddressIDEncrypt",
                                              element.PatientAddressIDEncrypt
                                            );
                                            formikAddress.setFieldValue(
                                              "CityID",
                                              element.CityID
                                            );
                                            formikAddress.setFieldValue(
                                              "StateID",
                                              element.StateID
                                            );
                                            formikAddress.setFieldValue(
                                              "AreaID",
                                              element.AreaID
                                            );
                                            formikAddress.setFieldValue(
                                              "Location",
                                              element.Location
                                            );
                                            formikAddress.setFieldValue(
                                              "Pincode",
                                              element.PinCode
                                            );
                                            formikAddress.setFieldValue(
                                              "Address",
                                              element.Address
                                            );
                                            formikAddress.setFieldValue(
                                              "Latitude",
                                              element.Latitude
                                            );
                                            formikAddress.setFieldValue(
                                              "Longitude",
                                              element.Longitude
                                            );
                                            setAddressModal(true);
                                          }}
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                          style={{ marginTop: "-31px" }}
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                      <button
                        type="button"
                        className="btn mr-2 btn-primary"
                        onClick={() => {
                          setTab(2);
                        }}
                      >
                        Previous
                      </button>
                      {PatientIDEncrypt ? (
                        <button
                          type="button"
                          className="btn mr-2 btn-light"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Close
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <Modal
            className="modal fade"
            show={patientPastHistoryModel}
            onHide={setPatientPastHistoryModal}
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Patient Past History</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setPatientPastHistoryModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  {pastHistoryList?.map((past, index) => (
                    <>
                      {past.editMode == false ? (
                        <Row>
                          <Col lg={10}>{past.PastHistory}</Col>
                          <Col lg={2}>
                            <Link
                              className="btn btn-light shadow btn-xs sharp mr-1"
                              title="Edit"
                              onClick={(e) => {
                                setPastHistoryList(
                                  pastHistoryList?.map((x, i) => {
                                    if (index == i) {
                                      return {
                                        ...x,
                                        editMode: true,
                                      };
                                    }
                                    return x;
                                  })
                                );
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </Link>
                          </Col>
                        </Row>
                      ) : (
                        <FormikProvider value={formikPastHistory}>
                          <Form>
                            <Row>
                              <Col lg={12}>
                                <Field
                                  type="textarea"
                                  name={`pastHistoryList.[${index}].PastHistoryUpdated`}
                                  placeholder={"Enter Patient Past History"}
                                  component={CustomInput}
                                  value={past.PastHistoryUpdated}
                                  rows={4}
                                  required
                                  style={{ resize: "none" }}
                                  maxLength={500}
                                  onChange={(e) =>
                                    setPastHistoryList(
                                      pastHistoryList?.map((x, i) => {
                                        if (index == i) {
                                          return {
                                            ...x,
                                            PastHistoryUpdated: e.target.value,
                                          };
                                        }
                                        return x;
                                      })
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={12}>
                                <Link
                                  className="btn btn-primary btn-xxs mr-1"
                                  title="Update"
                                  onClick={(e) => {
                                    if (
                                      past.PastHistoryUpdated.trim().length ===
                                      0
                                    ) {
                                      swal(
                                        "",
                                        "Please Enter Patient Past History",
                                        "warning"
                                      );
                                    } else {
                                      updatePastHistory(
                                        past.PatientPastHistoryIDEncrypted,
                                        past.PastHistoryUpdated.trim(),
                                        index
                                      );
                                    }
                                  }}
                                >
                                  Update
                                </Link>
                                <Link
                                  className="btn btn-light btn-xxs mr-1"
                                  title="Cancel"
                                  onClick={(e) => {
                                    setPastHistoryList(
                                      pastHistoryList?.map((x, i) => {
                                        if (index == i) {
                                          return {
                                            ...x,
                                            editMode: false,
                                          };
                                        }
                                        return x;
                                      })
                                    );
                                  }}
                                >
                                  Cancel
                                </Link>
                              </Col>
                            </Row>
                          </Form>
                        </FormikProvider>
                      )}

                      {index !== pastHistoryList.length - 1 ? <hr></hr> : ""}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setPatientPastHistoryModal(false)}
                className="btn btn-sm btn-light"
              >
                <i className="flaticon-delete-1"></i> Close
              </button>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={addressModal}
            onHide={setAddressModal}
          >
            <div className="">
              <div className="">
                <FormikProvider value={formikAddress}>
                  <Form onSubmit={formikAddress.handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">
                        {PatientAddressIDEncrypt === ""
                          ? "Add Address"
                          : "Edit Address"}
                      </h4>
                      <button
                        type="button"
                        className="btn close"
                        onClick={() => setAddressModal(false)}
                      >
                        <span>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <Row>
                            <Col lg={12}>
                              <Field
                                type="textarea"
                                label={"Address"}
                                name="Address"
                                placeholder={"Enter Address"}
                                rows={2}
                                component={CustomInput}
                                required
                                style={{ resize: "none" }}
                                maxLength={500}
                                // onChange={(e) => {
                                //   formikAddress.setFieldValue(
                                //     "Address",
                                //     e.target.value.replace(
                                //       /[^A-Za-z 0-9 , -]/g,
                                //       ""
                                //     )
                                //   );
                                // }}
                              />
                            </Col>
                            <Col lg={12}>
                              <Field
                                type="text"
                                label={"Location"}
                                name="Location"
                                placeholder={"Select Location"}
                                component={CustomInput}
                                onChange={(e) => {
                                  getPlacePredictions({
                                    input: e.target.value,
                                  });
                                  formikAddress.setFieldValue(
                                    "Location",
                                    e.target.value
                                  );
                                  setAddressSuggesionDivStatus(true);
                                }}
                                loading={isPlacePredictionLoading}
                                required
                              />
                              {addressSuggesionDivStatus &&
                              placePredictions.length > 0 ? (
                                <div
                                  style={{
                                    background: "white",
                                    borderRadius: "0.3rem",
                                    position: "absolute",
                                    // left: "40px",
                                    overflow: "auto",
                                    border: "1px solid rgb(207, 207, 207)",
                                    boxShadow: "rgb(28 28 28 / 8%) 0px 2px 8px",
                                    visibility: "visible",
                                    opacity: "1",
                                    transition:
                                      "opacity 0.25s ease 0s, top 0.25s ease 0s",
                                    zIndex: "10",
                                    transform: "translateY(-0.6rem)",
                                    width: "94%",
                                  }}
                                >
                                  <List
                                    style={{
                                      padding: "0px 10px",
                                      textAlign: "left",
                                    }}
                                    dataSource={placePredictions}
                                    renderItem={(item) => (
                                      <List.Item
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          formikAddress.setFieldValue(
                                            "Location",
                                            item.description
                                          );
                                          setAddressSuggesionDivStatus(false);
                                          geocodeByPlaceId(item.place_id)
                                            .then((results) => {
                                              formikAddress.setFieldValue(
                                                "Latitude",
                                                results[0].geometry.location.lat()
                                              );
                                              formikAddress.setFieldValue(
                                                "Longitude",
                                                results[0].geometry.location.lng()
                                              );
                                            })
                                            .catch((error) =>
                                              console.error(error)
                                            );
                                        }}
                                      >
                                        <List.Item.Meta
                                          title={item.description}
                                        />
                                      </List.Item>
                                    )}
                                  ></List>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                            {/* <Col lg={6}>
                            <Field
                              type="text"
                              label={"Latitude"}
                              name="Latitude"
                              id="Latitude"
                              placeholder={"Enter Latitude"}
                              component={CustomInput}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text"
                              label={"Longitude"}
                              name="Longitude"
                              id="Longitude"
                              placeholder={"Enter Longitude"}
                              component={CustomInput}
                            />
                          </Col> */}
                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"State"}
                                name="StateID"
                                placeholder={"Select State"}
                                options={stateOptions}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"City"}
                                name="CityID"
                                placeholder={"Select City"}
                                options={cityOptions}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"Area"}
                                name="AreaID"
                                placeholder={"Select Area"}
                                options={areaOptions}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                type="number"
                                label={"Pincode"}
                                name="Pincode"
                                placeholder={"Enter Pincode"}
                                component={CustomInput}
                                required
                                maxLength={6}
                                min={0}
                                onChange={(e) => {
                                  formikAddress.setFieldValue(
                                    "Pincode",
                                    e.target.value.slice(0, 6)
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setAddressModal(false)}
                        className="btn btn-light"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={patientListModel}
            onHide={setPatientListModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">
                Patients found which are already registered with same Email or
                Mobile No.
              </h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setPatientListModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="table-responsive">
                        <div
                          id="patient_list"
                          className="dataTables_wrapper no-footer"
                        >
                          <table
                            id="example5"
                            className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                            role="grid"
                            aria-describedby="example5_info"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Patient No.: activate to sort column ascending"
                                  style={{ width: "15%" }}
                                >
                                  Patient No.
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Patient Name: activate to sort column ascending"
                                  style={{ width: "20%" }}
                                >
                                  Patient Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Client Name: activate to sort column ascending"
                                  style={{ width: "20%" }}
                                >
                                  Client Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Mobile Number activate to sort column ascending"
                                  style={{ width: "15%" }}
                                >
                                  Mobile Number
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="EmailID: activate to sort column ascending"
                                  style={{ width: "20%" }}
                                >
                                  EmailID
                                </th>
                                {localStorage.getItem("AddPatientFromOrder") ? (
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Select: activate to sort column ascending"
                                    style={{ width: "5%" }}
                                  >
                                    Select
                                  </th>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {PatientDataList?.length > 0 ? (
                                PatientDataList?.map((element, index) => (
                                  <tr role="row" className="odd">
                                    <td>{element.PatientNo}</td>
                                    <td>{element.PatientName}</td>
                                    <td>{element.ClientName}</td>
                                    <td>{element.MobileNo}</td>
                                    <td>{element.EmailID}</td>
                                    {localStorage.getItem(
                                      "AddPatientFromOrder"
                                    ) ? (
                                      <td>
                                        <div className="mr-auto d-lg-block">
                                          <Link
                                            to={`/add-order-booking/${window.btoa(
                                              element.PatientIDEncrypted
                                            )}`}
                                            style={{ float: "right" }}
                                            className="btn btn-primary btn-xxs btn-rounded"
                                            onClick={() => {
                                              localStorage.removeItem(
                                                "AddPatientFromOrder"
                                              );
                                            }}
                                          >
                                            Select
                                          </Link>
                                        </div>
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={7}>
                                    <div className="mt-3 ml-3 mb-3">
                                      {"No Record Found"}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <h6>Continue to add new patient?</h6>
              <button
                type="button"
                onClick={() => setPatientListModal(false)}
                className="btn btn-sm btn-primary"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => {
                  if (localStorage.getItem("AddPatientFromOrder")) {
                    localStorage.removeItem("AddPatientFromOrder");
                    history.push("/order-booking-list");
                  } else {
                    history.push("/patient-list");
                  }
                }}
                className="btn btn-sm btn-light"
              >
                No
              </button>
            </div>
          </Modal>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddPatient;
