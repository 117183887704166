import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Tab, Nav, Modal, Button } from "react-bootstrap";
import {
  POST_GetClientOrderBookingsDetailByID,
  POST_ClientOrderBookingsSummary,
} from "../Helper/url_helper";
import { post } from "../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
const ModalOrderBookingDetail = (props) => {
  const [orderBookingServiceList, setOrderBookingServiceList] = useState([]);
  const [orderBookingEquipmetList, setOrderBookingEquipmetList] = useState([]);
  const [orderBookingHealthCheckupList, setOrderBookingHealthCheckupList] =
    useState([]);
  const [orderBookingPackageList, setOrderBookingPackageList] = useState([]);
  const [orderBookingPathologyReportList, setOrderBookingPathologyReportList] =
    useState([]);
  const [orderBookingRadiologyReportList, setOrderBookingRadiologyReportList] =
    useState([]);
  const [orderBookingDetailList, setOrderBookingDetailList] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.orderBookingID) {
      getOrderBookingDetailByOrderID();
    }
  }, []);

  const getOrderBookingDetailByOrderID = async () => {
    setIsLoading(true);
    let response = await post(POST_GetClientOrderBookingsDetailByID, {
      OrderBookingID: props.orderBookingID,
    });
    setIsLoading(false);
    setOrderBookingDetailList(response);
    setOrderBookingServiceList(response.ClientOrderBookingServicesDetails);
    setOrderBookingEquipmetList(response.ClientOrderBookingEquipmentsDetails);
    setOrderBookingHealthCheckupList(
      response.ClientOrderBookingHealthCheckupsDetails
    );
    setOrderBookingPackageList(response.ClientOrderBookingPackagesDetails);
    setOrderBookingPathologyReportList(
      response.ClientOrderBookingPathologyReportsDetails
    );
    setOrderBookingRadiologyReportList(
      response.ClientOrderBookingRadiologyReportsDetails
    );
    //console.log("OrderBookingDetailList", response);
  };

  const downloadOrderBookingSummary = async () => {
    setIsLoading(true);
    let response = await post(POST_ClientOrderBookingsSummary, {
      OrderBookingID: props.orderBookingID,
    });
    if (response.IsSuccess) {
      window.open(response.FilePath);
    }
    setIsLoading(false);
  };
  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <Row>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Patient No.</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={5}>{orderBookingDetailList.PatientNo}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Order No.</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={5}>{orderBookingDetailList.OrderBookingNo}</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Patient Name</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={5}>{orderBookingDetailList.PatientName}</Col>
              </Row>
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Booking Order Date</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={5}>{orderBookingDetailList.OrderBookingDate}</Col>
              </Row>
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Patient Address</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={5} style={{ textAlign: "left" }}>
                  {orderBookingDetailList.Address} ,
                  {orderBookingDetailList.AreaName} ,
                  {orderBookingDetailList.CityName} ,{" "}
                  {orderBookingDetailList.StateName} ,
                  {orderBookingDetailList.PinCode}
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <b>Mobile No.</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={5}>{orderBookingDetailList.MobileNo}</Col>
              </Row>
            </Col>
          </Row>
          <hr></hr>
          {orderBookingServiceList.length > 0 ? (
            <Row className="mt-4">
              <Col>
                <div className="table-responsive ">
                  <div className="dataTables_wrapper no-footer">
                    <table className="table verticle-middle table-responsive-md mb-3">
                      <thead className="thead-light">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Service Name: activate to sort column ascending"
                            style={{ width: "25%", padding: "10px 20px" }}
                          >
                            Service Name
                          </th>
                          {/* <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Staff Name: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "center",
                              padding: "10px 40px",
                            }}
                          >
                            Staff Name
                          </th> */}
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Start Date: activate to sort column ascending"
                            style={{
                              width: "15%",
                              textAlign: "center",
                              padding: "10px 20px",
                            }}
                          >
                            Start Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="End Date: activate to sort column ascending"
                            style={{
                              width: "15%",
                              textAlign: "center",
                              padding: "10px 20px",
                            }}
                          >
                            End Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Slots: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "center",
                              padding: "10px 20px",
                            }}
                          >
                            Slots
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Service Book Days: activate to sort column ascending"
                            style={{
                              width: "10%",
                              textAlign: "center",
                              padding: "10px 40px",
                            }}
                          >
                            Days
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{
                              width: "15%",
                              textAlign: "right",
                              padding: "10px 40px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      {orderBookingServiceList.map((element) => (
                        <tbody>
                          <tr role="row" className="odd">
                            <td>
                              {element.ServiceName}
                              {element?.StaffName ? (
                                <>
                                  <br></br>
                                  <span style={{ fontSize: "9px" }}>
                                    (
                                    {element?.RotationalStaffName
                                      ? element?.StaffName +
                                        " - " +
                                        element?.RotationalStaffName
                                      : element?.StaffName}
                                    )
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.ServiceScheduleStartDate}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.ServiceScheduleEndDate}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.ServiceScheduleStartTime} -{" "}
                              {element.ServiceScheduleEndTime}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.BookingDays}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <i class="fa fa-rupee"></i> {element.NetAmount}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {orderBookingEquipmetList.length > 0 ? (
            <Row className="mt-2">
              <Col>
                <div className="table-responsive ">
                  <div className="dataTables_wrapper no-footer">
                    <table className="table verticle-middle table-responsive-md mb-3">
                      <thead className="thead-light">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Equipment Name: activate to sort column ascending"
                            style={{ width: "35%", padding: "10px 20px" }}
                          >
                            Equipment Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Equipment Type: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "center",
                              padding: "10px 20px",
                            }}
                          >
                            Equipment Type
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Start Date: activate to sort column ascending"
                            style={{
                              width: "15%",
                              textAlign: "center",
                              padding: "10px 20px",
                            }}
                          >
                            Start Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="End Date: activate to sort column ascending"
                            style={{
                              width: "15%",
                              textAlign: "center",
                              padding: "10px 20px",
                            }}
                          >
                            End Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Days: activate to sort column ascending"
                            style={{
                              width: "10%",
                              textAlign: "center",
                              padding: "10px 40px",
                            }}
                          >
                            Days
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "right",
                              padding: "10px 40px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      {orderBookingEquipmetList.map((element) => (
                        <tbody>
                          <tr role="row" className="odd">
                            <td>{element.EquipmentName}</td>
                            <td style={{ textAlign: "center" }}>
                              {element.EquipmentType}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.EquipmentRequiredOnDate}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.EquipmentRequiredEndDate}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.BookingDays}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <i class="fa fa-rupee"></i> {element.NetAmount}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {orderBookingPackageList.length > 0 ? (
            <Row className="mt-2">
              <Col>
                <div className="table-responsive ">
                  <div className="dataTables_wrapper no-footer">
                    <table className="table verticle-middle table-responsive-md mb-3">
                      <thead className="thead-light">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Package Name: activate to sort column ascending"
                            style={{ width: "60%", padding: "10px 20px" }}
                          >
                            Package Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Package Duration: activate to sort column ascending"
                            style={{ width: "20%", padding: "10px 20px" }}
                          >
                            Package Duration
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "right",
                              padding: "10px 40px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      {orderBookingPackageList.map((element) => (
                        <tbody>
                          <tr role="row" className="odd">
                            <td>{element.PackageName}</td>
                            <td style={{ textAlign: "center" }}>
                              {element.PackageDurationInDays} Days
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <i class="fa fa-rupee"></i> {element.NetAmount}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {orderBookingHealthCheckupList.length > 0 ? (
            <Row className="mt-2">
              <Col>
                <div className="table-responsive ">
                  <div className="dataTables_wrapper no-footer">
                    <table className="table verticle-middle table-responsive-md mb-3">
                      <thead className="thead-light">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Health Checkup: activate to sort column ascending"
                            style={{ width: "80%", padding: "10px 20px" }}
                          >
                            Health Checkup
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "right",
                              padding: "10px 40px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      {orderBookingHealthCheckupList.map((element) => (
                        <tbody>
                          <tr role="row" className="odd">
                            <td>{element.HealthCheckupName}</td>

                            <td style={{ textAlign: "right" }}>
                              <i class="fa fa-rupee"></i> {element.NetAmount}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {orderBookingPathologyReportList.length > 0 ? (
            <Row className="mt-2">
              <Col>
                <div className="table-responsive ">
                  <div className="dataTables_wrapper no-footer">
                    <table className="table verticle-middle table-responsive-md mb-3">
                      <thead className="thead-light">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Pathology Report: activate to sort column ascending"
                            style={{ width: "60%", padding: "10px 20px" }}
                          >
                            Pathology Report
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Scheduled On: activate to sort column ascending"
                            style={{ width: "20%", padding: "10px 30px" }}
                          >
                            Scheduled On
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "right",
                              padding: "10px 40px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      {orderBookingPathologyReportList.map((element) => (
                        <tbody>
                          <tr role="row" className="odd">
                            <td>{element.PathologyReportName}</td>
                            <td>
                              {element.ScheduleDate} {element.ScheduleTime}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <i class="fa fa-rupee"></i> {element.NetAmount}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {orderBookingRadiologyReportList.length > 0 ? (
            <Row className="mt-2">
              <Col>
                <div className="table-responsive ">
                  <div className="dataTables_wrapper no-footer">
                    <table className="table verticle-middle table-responsive-md mb-3">
                      <thead className="thead-light">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Radiology Report: activate to sort column ascending"
                            style={{ width: "60%", padding: "10px 20px" }}
                          >
                            Radiology Report
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Scheduled On: activate to sort column ascending"
                            style={{ width: "20%", padding: "10px 30px" }}
                          >
                            Scheduled On
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{
                              width: "20%",
                              textAlign: "right",
                              padding: "10px 40px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>

                      {orderBookingRadiologyReportList.map((element) => (
                        <tbody>
                          <tr role="row" className="odd">
                            <td>{element.RadiologyReportName}</td>
                            <td>
                              {element.ScheduleDate} {element.ScheduleTime}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <i class="fa fa-rupee"></i> {element.NetAmount}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <hr></hr>
          <Row>
            <Col lg={4}></Col>
            <Col lg={8}>
              <Row style={{ textAlign: "right" }}>
                <Col lg={9}>
                  <b>Gross Amount</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={2}>
                  <i class="fa fa-rupee"></i>{" "}
                  {orderBookingDetailList.GrossAmount}
                </Col>
                <br></br>
              </Row>
              {orderBookingDetailList.DiscountAmount > 0 ? (
                <Row style={{ textAlign: "right" }}>
                  <Col lg={9}>
                    <b>Discount Amount</b>
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={2}>
                    {" "}
                    <i class="fa fa-rupee"></i>{" "}
                    {orderBookingDetailList.DiscountAmount}
                  </Col>
                  <br></br>
                </Row>
              ) : (
                ""
              )}
              {orderBookingDetailList.TaxAmount > 0 ? (
                <Row style={{ textAlign: "right" }}>
                  <Col lg={9}>
                    <b>Tax Amount</b>
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={2}>
                    {" "}
                    <i class="fa fa-rupee"></i>{" "}
                    {orderBookingDetailList.TaxAmount}
                  </Col>
                  <br></br>
                </Row>
              ) : (
                ""
              )}
              {orderBookingDetailList.TransportationCharges > 0 ? (
                <Row style={{ textAlign: "right" }}>
                  <Col lg={9}>
                    <b>Transportation Charges</b>
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={2}>
                    {" "}
                    <i class="fa fa-rupee"></i>{" "}
                    {orderBookingDetailList.TransportationCharges}
                  </Col>
                  <br></br>
                </Row>
              ) : (
                ""
              )}
              <Row style={{ textAlign: "right" }}>
                <Col lg={9}>
                  <b>Net Amount</b>
                </Col>
                <Col lg={1}>:</Col>
                <Col lg={2}>
                  <i class="fa fa-rupee"></i> {orderBookingDetailList.NetAmount}
                </Col>
                <br></br>
              </Row>
              <hr style={{ marginLeft: "50%" }}></hr>
              {/* <Row style={{ textAlign: "right" }}>
                    <Col lg={9}>
                      <b>Deposit Amount</b>
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={2}>
                      <i class="fa fa-rupee"></i>{" "}
                      {orderBookingDetailList.MobileNo}
                    </Col>
                    <br></br>
                  </Row> */}
              {orderBookingDetailList.TotalAdvanceAmount > 0 ? (
                <Row style={{ textAlign: "right" }}>
                  <Col lg={9}>
                    <b>Advance Amount</b>
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={2}>
                    <i class="fa fa-rupee"></i>{" "}
                    {orderBookingDetailList.TotalAdvanceAmount}
                  </Col>
                  <br></br>
                </Row>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <button
            style={{ float: "right" }}
            className="btn mt-5 mr-2 btn-xm btn-light"
            onClick={() => {
              props.setOrderDetailModal(false);
            }}
          >
            Close
          </button>
          <Button
            style={{ float: "right" }}
            className="btn mt-5 mr-2 btn-xm btn-primary"
            variant="primary btn-rounded"
            onClick={() => {
              downloadOrderBookingSummary();
              // window.open(
              //   "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
              // );
            }}
          >
            <span className="btn-icon-left" style={{ color: "#36C95F" }}>
              <i className="fa fa-download color-light" />
            </span>
            Download PDF
          </Button>
        </Spin>
      </Fragment>
    </>
  );
};

export default ModalOrderBookingDetail;
