import React, { useEffect, useState } from "react";
import { get, post } from "../../../Helper/api_helper";
import {
  GET_CompanyDropDownList,
  POST_AdminGetYearDDLList,
  POST_GetAdminDashboard,
  POST_GetAdminDashboardRevenueData,
  POST_GetAdvanceDueReportInExcel,
  POST_GetInvoiceGeneratePendingReportInExcel,
} from "../../../Helper/url_helper";
import { Spin } from "antd";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { Form, Modal } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Link } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";
import { t } from "i18next";
import RevenueMonthlyYearWiseChart from "./RevenueMonthlyYearWiseChart";
import EquipmentTypeWiseBookingChart from "./EquipmentTypeWiseBookingChart";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [summary, setSummary] = useState(null);
  const [revenueData, setRevenueData] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [revenueModal, setRevenueModal] = useState(false);
  const [revenueChartModal, setRevenueChartModal] = useState(false);
  const [equipmentChartModal, setEquipmentChartModal] = useState(false);

  const [optionYearDDLList, setOptionYearDDLList] = useState([]);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CompanyID },
  } = formikFilter;

  const GetDashboardSummary = async () => {
    setIsLoading(true);
    await post(POST_GetAdminDashboard, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          setSummary(response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const GetDashboardRevenueData = async () => {
    setIsLoading(true);
    await post(POST_GetAdminDashboardRevenueData, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          setRevenueData(response?.ListOfData);
        } else {
          setRevenueData([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getYearDDLList = () => {
    setIsLoading(true);
    post(POST_AdminGetYearDDLList, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionYearDDLList(
            res.AdminGetYearDDLList.map((item) => ({
              label: item.YearName,
              value: item.YearName,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    if (AuthUser?.UserType == 1 && AuthUser?.CompanyType == 1) {
      loadCompanyDDL();
    }
  }, []);

  useEffect(() => {
    GetDashboardSummary();
    if (AuthUser?.UserType == 1) {
      GetDashboardRevenueData();
      getYearDDLList();
    }
  }, [CompanyID]);

  const GetInvoiceGeneratePendingReport = async () => {
    setIsLoading(true);
    await post(POST_GetInvoiceGeneratePendingReportInExcel, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          window.open(response.FilePath);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const formikRevenue = useFormik({
    initialValues: {
      RevenueList: [],
    },
  });

  const {
    values: { RevenueList },
  } = formikRevenue;

  const GetAdvanceDueReport = async () => {
    setIsLoading(true);
    await post(POST_GetAdvanceDueReportInExcel, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          window.open(response.FilePath);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        {AuthUser?.UserType == 1 ? (
          <>
            {AuthUser?.CompanyType == 1 ? (
              <FormikProvider value={formikFilter}>
                <Form onSubmit={formikFilter.handleSubmit}>
                  <div className="row mb-2">
                    <div className="col-xl-6">
                      {/* <div className="d-flex">
                        <span style={{ width: "100%" }}> */}
                      <Field
                        name="CompanyID"
                        placeholder={"Select Company"}
                        options={companyOptions}
                        component={CustomSelect}
                        isClearable={false}
                      />
                      {/* </span>
                        &nbsp;
                        <span>
                          <lable>&nbsp;</lable>
                          <button
                            type="submit"
                            className="btn btn-rounded btn-primary"
                          >
                            Search
                          </button>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-danger">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-money" aria-hidden="true"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          <Link
                            className="text-white"
                            to="#"
                            onClick={() => {
                              setRevenueModal(true);
                            }}
                          >
                            Revenue Segment Wise
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-warning">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-bar-chart-o" aria-hidden="true"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          <Link
                            className="text-white"
                            to="#"
                            onClick={() => {
                              setRevenueChartModal(true);
                            }}
                          >
                            Revenue Monthly Chart
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-secondary">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-line-chart" aria-hidden="true"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          <Link
                            className="text-white"
                            to="#"
                            onClick={() => {
                              setEquipmentChartModal(true);
                            }}
                          >
                            Equipment Type Wise Booking Chart
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-xl-10">
                <div className="card widget-stat" style={{ zIndex: 0 }}>
                  <div className="card-body p-4">
                    <div className="table-responsive ">
                      <div className="dataTables_wrapper no-footer">
                        <table
                          className="table verticle-middle table-responsive-md mb-2 "
                          style={{ width: "100%" }}
                        >
                          <thead className="thead-light ">
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Revenue Segment: activate to sort column ascending"
                                style={{
                                  width: "30%",
                                  padding: "10px 20px",
                                }}
                              >
                                Revenue Segment
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Today: activate to sort column ascending"
                                style={{
                                  width: "20%",
                                  padding: "10px 25px",
                                  textAlign: "right",
                                }}
                              >
                                Today
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Current Month: activate to sort column ascending"
                                style={{
                                  width: "25%",
                                  padding: "10px 20px",
                                  textAlign: "right",
                                }}
                              >
                                Current Month
                              </th>

                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Current Year: activate to sort column ascending"
                                style={{
                                  width: "25%",
                                  padding: "10px 20px",
                                  textAlign: "right",
                                }}
                              >
                                Current Year
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {revenueData?.length > 0 ? (
                              revenueData?.map((item, index) => (
                                <tr role="row">
                                  <td>
                                    <Link to="/reports">
                                      {item.ServiceTypeEnglish}
                                    </Link>
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <i class="fa fa-rupee"></i> {item.Today}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <i class="fa fa-rupee"></i>{" "}
                                    {item.CurrentMonth}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <i class="fa fa-rupee"></i>{" "}
                                    {item.YearToDate}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={4}>
                                  <div className="mt-2 mb-3">
                                    {"No Record Found"}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        ) : (
          ""
        )}
        <div className="row">
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 31 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-4 col-xxl-6 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-info">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-stethoscope"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 31 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link
                            className="text-white"
                            to="/activity-monitoring"
                          >
                            Today's Service Duty / Pending / Completed / On
                            Going
                          </Link>
                        ) : (
                          "Today's Service Duty / Pending / Completed / On Going"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.TodaysServiceDutyCount}
                        {" / "}
                        {summary?.TodaysServiceDutyPendingCount}
                        {" / "}
                        {summary?.TodaysServiceDutyCompletedCount}
                        {" / "}
                        {summary?.TodaysServiceDutyOnGoingCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 29 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-4 col-xxl-6 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-success">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-rupee"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 29 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link
                            className="text-white"
                            to="order-booking-advance"
                          >
                            Today's Advance Deposited
                          </Link>
                        ) : (
                          "Today's Advance Deposited"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.TodaysAdvanceDepositedCount}{" "}
                        <span className="fs-20">
                          (<i class="fa fa-rupee"></i>{" "}
                          {summary?.TodaysAdvanceDepositedAmount})
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 25 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-danger">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-medkit"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 25 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link className="text-white" to="/order-booking-list">
                            Today's Order Booking
                          </Link>
                        ) : (
                          "Today's Order Booking"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.TodaysOrderBookingCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 24 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-warning">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-question"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 24 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link className="text-white" to="/enquiry-list">
                              Today's Enquiry
                            </Link>
                          ) : (
                            "Today's Enquiry"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TodaysEnquiryCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-secondary">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-comment-o"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 24 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link className="text-white" to="/enquiry-list">
                              Today's Enquiry Followup
                            </Link>
                          ) : (
                            "Today's Enquiry Followup"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TodaysEnquiryFollowupCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 31 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-info">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-home"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 31 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link
                              className="text-white"
                              to="/activity-monitoring"
                            >
                              Today's Staff On Leave
                            </Link>
                          ) : (
                            "Today's Staff On Leave"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TodaysStaffOnLeaveCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-success">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-calendar-minus-o"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 31 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link
                              className="text-white"
                              to="/activity-monitoring"
                            >
                              Today's Staff Absent
                            </Link>
                          ) : (
                            "Today's Staff Absent"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TodaysStaffAbsentCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-danger">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-calendar-times-o"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 31 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link
                              className="text-white"
                              to="/activity-monitoring"
                            >
                              Today's Staff Service Cancel
                            </Link>
                          ) : (
                            "Today's Staff Service Cancel"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TodaysStaffServiceCancelCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-warning">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-undo"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 31 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link
                              className="text-white"
                              to="/activity-monitoring"
                            >
                              Today's Equipment Return Pending
                            </Link>
                          ) : (
                            "Today's Equipment Return Pending"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TodaysEquipmentReturnPendingCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 43 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-secondary">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-wheelchair"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 43 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link
                            className="text-white"
                            to="/assign-staff-for-equipment-installation"
                          >
                            Equipment Installation Staff Allocation Pending
                          </Link>
                        ) : (
                          "Equipment Installation Staff Allocation Pending"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.EquipmentInstallationAllocationPendingCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 44 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-info">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-wheelchair-alt"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 44 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link
                            className="text-white"
                            to="/assign-staff-for-equipment-return"
                          >
                            Equipment Return Staff Allocation Pending
                          </Link>
                        ) : (
                          "Equipment Return Staff Allocation Pending"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.EquipmentReturnAllocationPendingCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 32 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-success">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-calendar-o"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 32 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link
                            className="text-white"
                            to="/schedule-package-and-healthcheckup"
                          >
                            Package & Healthcheckup Schedule Pending
                          </Link>
                        ) : (
                          "Package & Healthcheckup Schedule Pending"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.PackageHealthcheckupSchedulePendingCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 41 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-danger">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-print"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 41 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            // <Link className="text-white" to="/invoices">
                            //   Invoice Generate Pending
                            // </Link>
                            <Link
                              className="text-white"
                              to="#"
                              onClick={() => GetInvoiceGeneratePendingReport()}
                            >
                              Invoice Generate Pending
                            </Link>
                          ) : (
                            "Invoice Generate Pending"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.InvoiceGeneratePendingCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-warning">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-credit-card"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 41 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link className="text-white" to="/invoices">
                              Invoice Settlement Pending
                            </Link>
                          ) : (
                            "Invoice Settlement Pending"
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.InvoiceSettlementPendingCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 33 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-secondary">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-ticket"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 33 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link
                            className="text-white"
                            to="/staff-leave-managment"
                          >
                            Leave Request
                          </Link>
                        ) : (
                          "Leave Request"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.LeaveRequestCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 36 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-info">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-clock-o"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 36 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link className="text-white" to="/staff-over-time">
                            Staff Overtime Request
                          </Link>
                        ) : (
                          "Staff Overtime Request"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.StaffOverTimeRequestCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 40 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-success">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-hand-paper-o"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 40 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link className="text-white" to="/reports">
                            Equipment Deposit Collection Pending
                          </Link>
                        ) : (
                          "Equipment Deposit Collection Pending"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.EquipmentDepositCollectionPendingCount}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 34 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <>
              <div className="col-xl-4 col-xxl-6 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-danger">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-comments-o"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 34 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link
                              className="text-white"
                              to="/complaint-managment"
                            >
                              Staff Complaint Total
                              <span style={{ fontSize: "11px" }}>
                                (Today's)
                              </span>{" "}
                              / Open / Close
                            </Link>
                          ) : (
                            <>
                              Staff Complaint Total
                              <span style={{ fontSize: "11px" }}>
                                (Today's)
                              </span>{" "}
                              / Open / Close
                            </>
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TotalStaffComplaintCount}
                          <span className="fs-20">
                            ({summary?.TodaysStaffComplaintCount})
                          </span>
                          {" / "}
                          {summary?.StaffComplaintOpenCount}
                          {" / "}
                          {summary?.StaffComplaintCloseCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xxl-6 col-lg-6 col-sm-6">
                <div className="card widget-stat bg-warning">
                  <div className="card-body p-4">
                    <div className="media">
                      <span className="mr-3">
                        <i class="fa fa-comments-o"></i>
                      </span>
                      <div className="media-body text-white text-right">
                        <p className="mb-1">
                          {AuthUser?.UserType == 1 ||
                          (AuthUser?.UserType == 2 &&
                            UserForms?.filter(
                              (item) =>
                                item.FormID == 34 &&
                                (item.ViewAction ||
                                  item.EditAction ||
                                  item.DeleteAction ||
                                  item.AddAction)
                            ).length > 0) ? (
                            <Link
                              className="text-white"
                              to="/complaint-managment"
                            >
                              Client Complaint Total
                              <span style={{ fontSize: "11px" }}>
                                (Today's)
                              </span>{" "}
                              / Open / Close
                            </Link>
                          ) : (
                            <>
                              Client Complaint Total
                              <span style={{ fontSize: "11px" }}>
                                (Today's)
                              </span>{" "}
                              / Open / Close
                            </>
                          )}
                        </p>
                        <h2 className="fs-40 font-w600 text-white mb-0">
                          {summary?.TotalClientComplaintCount}
                          <span className="fs-20">
                            ({summary?.TodaysClientComplaintCount})
                          </span>
                          {" / "}
                          {summary?.ClientComplaintOpenCount}
                          {" / "}
                          {summary?.ClientComplaintCloseCount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {AuthUser?.UserType == 1 ||
          (AuthUser?.UserType == 2 &&
            UserForms?.filter(
              (item) =>
                item.FormID == 40 &&
                (item.ViewAction ||
                  item.EditAction ||
                  item.DeleteAction ||
                  item.AddAction)
            ).length > 0) ? (
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
              <div className="card widget-stat bg-secondary">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="mr-3">
                      <i class="fa fa-rupee"></i>
                    </span>
                    <div className="media-body text-white text-right">
                      <p className="mb-1">
                        {AuthUser?.UserType == 1 ||
                        (AuthUser?.UserType == 2 &&
                          UserForms?.filter(
                            (item) =>
                              item.FormID == 40 &&
                              (item.ViewAction ||
                                item.EditAction ||
                                item.DeleteAction ||
                                item.AddAction)
                          ).length > 0) ? (
                          <Link
                            className="text-white"
                            to="#"
                            onClick={() => GetAdvanceDueReport()}
                          >
                            Advance Due
                          </Link>
                        ) : (
                          "Advance Due"
                        )}
                      </p>
                      <h2 className="fs-40 font-w600 text-white mb-0">
                        {summary?.TotalAdvanceDue}{" "}
                        <span className="fs-20">
                          (<i class="fa fa-rupee"></i>{" "}
                          {summary?.TotalAdvanceDueAmount})
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <Modal
          className="modal fade"
          show={revenueModal}
          onHide={setRevenueModal}
          size="lg"
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Revenue</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setRevenueModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <FormikProvider value={formikRevenue}>
              <Form onSubmit={formikRevenue.handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card widget-stat" style={{ zIndex: 0 }}>
                        <div className="card-body p-4">
                          <div className="table-responsive ">
                            <div className="dataTables_wrapper no-footer">
                              <table
                                className="table verticle-middle table-responsive-md mb-2 "
                                style={{ width: "100%" }}
                              >
                                <thead className="thead-light ">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Revenue Segment: activate to sort column ascending"
                                      style={{
                                        width: "30%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Revenue Segment
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Today: activate to sort column ascending"
                                      style={{
                                        width: "20%",
                                        padding: "10px 25px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Today
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Current Month: activate to sort column ascending"
                                      style={{
                                        width: "25%",
                                        padding: "10px 20px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Current Month
                                    </th>

                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Current Year: activate to sort column ascending"
                                      style={{
                                        width: "25%",
                                        padding: "10px 20px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Current Year
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {revenueData?.length > 0 ? (
                                    revenueData?.map((item, index) => (
                                      <tr role="row">
                                        <td>
                                          <Link to="/reports">
                                            {item.ServiceTypeEnglish}
                                          </Link>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <i class="fa fa-rupee"></i>{" "}
                                          {item.Today}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <i class="fa fa-rupee"></i>{" "}
                                          {item.CurrentMonth}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <i class="fa fa-rupee"></i>{" "}
                                          {item.YearToDate}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="mt-2 mb-3">
                                          {"No Record Found"}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Spin>
        </Modal>
        <Modal
          className="modal fade"
          show={revenueChartModal}
          onHide={setRevenueChartModal}
          size="lg"
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Revenue Monthly Chart</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setRevenueChartModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <RevenueMonthlyYearWiseChart
                CompanyID={CompanyID}
                optionsYear={optionYearDDLList}
              />
            </div>
          </Spin>
        </Modal>
        <Modal
          className="modal fade"
          show={equipmentChartModal}
          onHide={setEquipmentChartModal}
          size="lg"
        >
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">
                Equipment Type Wise Booking Chart
              </h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setEquipmentChartModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <EquipmentTypeWiseBookingChart
                CompanyID={CompanyID}
                optionsYear={optionYearDDLList}
              />
            </div>
          </Spin>
        </Modal>
      </Spin>
    </div>
  );
};

export default Dashboard;
