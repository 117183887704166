import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Row, Col, Modal } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import PaginationButtons from "../../../Custom/Pagination";
import {
  POST_GetHealthCheckupsList,
  POST_HealthCheckupsSetActiveInActive,
  POST_HealthCheckupsDelete,
} from "../../../Helper/url_helper";
const HealthCheckUpList = () => {
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [healthCheckUpList, setHealthCheckupList] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const handleOnChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getData();
  }, [search]);

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_GetHealthCheckupsList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: search,
      Sorting: "",
      LoginID: objLogin.LoginIDEncrypt,
    });
    setIsLoading(false);
    setRecordCount(response.TotalRecordCount);
    setHealthCheckupList(response.HealthCheckupsList);
    //setCurrentPage(page);
    setCurrentSizePerPage(sizePerPage);
    if (page > 1 && response?.HealthCheckupsList?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  const delete_HealthCheckup = (hID) => {
    swal({
      title: "Are you sure you want to delete the health checkup?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_HealthCheckupConfirm(hID);
      }
    });
  };

  const delete_HealthCheckupConfirm = async (hID) => {
    setIsLoading(true);
    let response = await post(POST_HealthCheckupsDelete, {
      EncryptedHealthCheckupID: hID,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_HealthCheckup = async (hID, hStatus) => {
    swal({
      title: `Are you sure you want to ${
        hStatus ? "inactive" : "active"
      } the health chekup?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_HealthCheckupConfirm(hID, hStatus);
      }
    });
  };

  const ActiveInactive_HealthCheckupConfirm = async (hID, hStatus) => {
    setIsLoading(true);
    let response = await post(POST_HealthCheckupsSetActiveInActive, {
      EncryptedHealthCheckupID: hID,
      IsActive: !hStatus,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: hStatus ? "Inactivated" : "Activated",
        text: hStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Health Checkup" />

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 13 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <Link
                  to="/add-health-checkup"
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add Health Checkup
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                value={search}
                onChange={handleOnChangeSearch}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="patient_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Health Checkup Name: activate to sort column ascending"
                          style={{ width: "90%" }}
                        >
                          Health Checkup Name
                        </th>
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Is Multiple Checkup: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Is Multiple Checkup
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Duration: activate to sort column ascending"
                          style={{ width: "12%" }}
                        >
                          Duration
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Quantity: activate to sort column ascending"
                          style={{ width: "12%" }}
                        >
                          Quantity
                        </th> */}

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {recordCount > 0 ? (
                      <tbody>
                        {healthCheckUpList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.HealthCheckupName}</td>
                            {/* <td style={{ textAlign: "center" }}>
                              {element.IsMultipleCheckup ? "Yes" : "No"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.HealthCheckupDuration}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.HealthCheckupQty}
                            </td> */}
                            <td>
                              <div className="d-flex">
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 13 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <Link
                                      to={`/edit-health-checkup/${window.btoa(
                                        element.HealthCheckupIDEncrypt
                                      )}`}
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                      title={
                                        element.IsActive ? "Active" : "Inactive"
                                      }
                                      onClick={() => {
                                        ActiveInactive_HealthCheckup(
                                          element.HealthCheckupIDEncrypt,
                                          element.IsActive
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle mt-1"
                                        style={{
                                          color: element.IsActive
                                            ? "green"
                                            : "red",
                                        }}
                                      ></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 13 && item.DeleteAction
                                  ).length > 0) ? (
                                  <Link
                                    to="#"
                                    className="btn btn-light shadow btn-xs sharp"
                                    title="Delete"
                                    onClick={() => {
                                      delete_HealthCheckup(
                                        element.HealthCheckupIDEncrypt
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="mt-3 ml-3">{"No Record Found"}</div>
                    )}
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default HealthCheckUpList;
