import { post, get } from "../../jsx/Helper/api_helper";
import { POST_GetCategoryTypes } from "../../jsx/Helper/url_helper";

export const CATEGORY_TYPE_LIST = () => async (dispatch) => {
  try {
    const response = await get(POST_GetCategoryTypes);
    //console.log("CategoryTypeList response", response);
    if (!response.IsSuccess) {
      console.log("Error!", response.MessageCode, "error");
      return false;
    } else {
      dispatch({
        type: POST_GetCategoryTypes,
        CategoryTypeList: response.List,
      });

      return response;
    }
  } catch (err) {
    console.log(err.message);
    console.log("Error!", err.message, "error");
  }
};

// export default CATEGORY_TYPE_LIST;
