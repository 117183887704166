import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";

import { FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { post } from "../../../Helper/api_helper";
import { Editor } from "@tinymce/tinymce-react";

import {
  GET_GetAdminTermsAndConditionDetailByID,
  POST_AdminTermsAndConditionInsert,
} from "../../../Helper/url_helper";

import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";

const TermsAndCondition = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [initialTermsAndCondition, setInitialTermsAndCondition] = useState("");

  const formikTermsAndCondition = useFormik({
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      TermsAndConditionID: "",
      TermsAndConditions: "",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    values: { TermsAndConditionID },
  } = formikTermsAndCondition;

  useEffect(() => {
    get_GetTermsAndCondition();
  }, []);

  const get_GetTermsAndCondition = async () => {
    setIsLoading(true);

    post(GET_GetAdminTermsAndConditionDetailByID, {
      TermsAndConditionIDEncrypt: "",
    }).then((response) => {
      if (response.IsSuccess) {
        setInitialTermsAndCondition(response.TermsAndCondition);
        setValues({
          TermsAndConditionID: response.TermsAndConditionIDEncrypt,
          TermsAndConditions: response.TermsAndCondition,
        });
      }
    });
    setIsLoading(false);
  };

  const handleValidSubmit = async (values) => {
    const Data = {
      TermsAndConditionIDEncrypt: TermsAndConditionID,
      TermsAndCondition: values.TermsAndConditions,
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
      //ModifyByEncrypt: objLogin.LoginIDEncrypt,
    };

    setIsLoading(true);

    let response = await post(POST_AdminTermsAndConditionInsert, Data);
    if (response.IsSuccess) {
      setIsLoading(false);
      swal({
        title: "Success",
        text: t(response.MessageCode),
        icon: "success",
        button: "OK",
      });
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleEditorChange = async (content, editor) => {
    //console.log("Content was updated:", content);
    formikTermsAndCondition.setFieldValue("TermsAndConditions", content);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle activeMenu="Terms & Condition" motherMenu="Configuration" />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formikTermsAndCondition}>
                    <Form onSubmit={formikTermsAndCondition.handleSubmit}>
                      <Row>
                        <Editor
                          initialValue={initialTermsAndCondition}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image code charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
                              "bullist numlist outdent indent | removeformat | help ",
                            content_style: "body { color: #7e7e7e }",
                          }}
                          onEditorChange={handleEditorChange}
                        />
                      </Row>
                      <br></br>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Save
                      </button>

                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.push("/");
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default TermsAndCondition;
