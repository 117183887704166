import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Accordion, Row, Col, Modal } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomInput from "../../../Custom/CustomInput";
import PaginationButtons from "../../../Custom/Pagination";
import {
  POST_GetEnquiryList,
  POST_EnquiryFollowupsInsert,
  POST_GetEnquiryDetailByID,
  POST_GetFollowUpList,
  POST_EnquiryRead,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import moment from "moment";
import { login } from "../../../../services/AuthService";

const EnquiryList = () => {
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const [enquiryStatusModal, setEnquiryStatusModal] = useState(false);
  const [followupModal, setFollowupModal] = useState(false);
  const [enquiryDetailModal, setEnquiryDetailModal] = useState(false);
  const [activeDefault, setActiveDefault] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [enquiryList, setEnquiryList] = useState([]);
  const [enquiryDetail, setEnquiryDetail] = useState("");
  const [enquiryFollowupDetail, setEnquiryFollowupDetail] = useState([]);
  const [optionFollowUp, setOptionFollowUp] = useState([]);

  const [enquiryID, setEnquiryID] = useState("");
  const [nextfollowUpDate, setNextfollowUpDate] = useState("");
  const [nextFollowUpRemark, setNextFollowUpRemark] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchemaEnquiryFollowup = Yup.object().shape({
    FollowupDate: Yup.string().required("Followup Date is required field"),
    CurrentFollowupRemarks: Yup.string().required(
      "Followup Remarks is required field"
    ),
    NextFollowupDate: Yup.string().when("FollowupStatus", {
      is: (FollowupStatus) => FollowupStatus === 1,
      then: Yup.string()
        .nullable()
        .required("Next Followup Date is required field"),
      otherwise: Yup.string().nullable(),
    }),
    // NextFollowupRemarks: Yup.string().when("FollowupStatus", {
    //   is: (FollowupStatus) => FollowupStatus === 1,
    //   then: Yup.string()
    //     .nullable()
    //     .required("Next Followup Remark is required field"),
    //   otherwise: Yup.string().nullable(),
    // }),
  });

  const formikEnquiryFollowup = useFormik({
    validationSchema: validationSchemaEnquiryFollowup,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitEnquiryFollowup(values, formikHelpers),
    initialValues: {
      FollowupDate: "",
      CurrentFollowupRemarks: "",
      FollowupStatus: 1,
      NextFollowupDate: "",
      NextFollowupRemarks: "",
      CurrentFollowupRemarksNotes: "",
      NextFollowupRemarksNotes: "",
    },
    validateOnBlur: false,
  });

  const {
    values: { FollowupStatus },
  } = formikEnquiryFollowup;

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      EnquiryStatus: 0,
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate, EnquiryStatus },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setIsLoading(true);
    let response = await post(POST_GetEnquiryList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyIDEncrypt: CompanyID,
      // LoginID: objLogin.LoginIDEncrypt,
      EnquiryStatus: EnquiryStatus,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",
    });
    if (response.IsSuccess) {
      setRecordCount(response.TotalRecordCount);
      setEnquiryList(response.EnquiryList);
      setCurrentSizePerPage(sizePerPage);
      if (page > 1 && response?.EnquiryList?.length === 0) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      formikEnquiryFollowup.setValues({
        FollowupStatus: response.EnquiryList.EnquiryStatus,
      });
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  const enquiryStatusOptions = [
    { label: "All", value: 0 },
    { label: "Open", value: 1 },
    { label: "Close", value: 2 },
  ];

  useEffect(() => {
    getData();
    loadCompanyDDL();
  }, []);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getFollowUpList = () => {
    setIsLoading(true);
    post(POST_GetFollowUpList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionFollowUp(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const getEnquiryDetail = (enquiryID, isread) => {
    setIsLoading(true);
    //alert(isread);
    //return;

    post(POST_GetEnquiryDetailByID, {
      EnquiryIDEncrypt: enquiryID,
    }).then((res) => {
      if (res.IsSuccess) {
        setEnquiryDetailModal(true);
        setEnquiryDetail(res);

        if (isread === false) {
          post(POST_EnquiryRead, {
            EnquiryIDEncrypt: enquiryID,
            ReadByIDEncrypt: AuthUser?.LoginIDEncrypt,
          }).then((response) => {
            if (response.IsSuccess) {
              setEnquiryList(
                enquiryList?.map((x, i) => {
                  if (enquiryID === x.EnquiryIDEncrypt) {
                    return { ...x, IsRead: true };
                  } else {
                    return { ...x };
                  }
                })
              );
            }
          });
        }
      }
    });

    setIsLoading(false);
  };

  const getEnquiryFollowupDetail = (enquiryID) => {
    setIsLoading(true);
    getFollowUpList();
    post(POST_GetEnquiryDetailByID, {
      EnquiryIDEncrypt: enquiryID,
    }).then((res) => {
      if (res.IsSuccess) {
        setEnquiryID(enquiryID);
        setEnquiryFollowupDetail(res.EnquiryFollowupHistoryList);
        if (res.EnquiryFollowupHistoryList.length > 0) {
          let NextFollowUp =
            res.EnquiryFollowupHistoryList[
              res.EnquiryFollowupHistoryList?.length - 1
            ];
          setNextfollowUpDate(NextFollowUp.NextFollowupDate);
          setNextFollowUpRemark(
            NextFollowUp.NextFollowupRemarks +
              (NextFollowUp?.NextFollowUpRemarksNotes
                ? " - " + NextFollowUp?.NextFollowUpRemarksNotes
                : NextFollowUp.NextFollowupRemarks
                ? ""
                : " - ")
          );
        }
      }
    });
    setIsLoading(false);
  };

  const handleValidSubmitEnquiryFollowup = (values, { setSubmitting }) => {
    setIsLoading(true);

    var data = {
      EnquiryID: enquiryID,
      LoginID: objLogin.LoginIDEncrypt,
      FollowupTakenDate: `${moment(values.FollowupDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      FollowupRemarksID: values.CurrentFollowupRemarks,
      FollowupStatus: values.FollowupStatus,
      NextFollowupDate: values.NextFollowupDate
        ? `${moment(values.NextFollowupDate)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`
        : "",
      NextFollowupRemarksID: values.NextFollowupRemarks
        ? values.NextFollowupRemarks
        : 0,
      FollowupRemarksNotes: values.CurrentFollowupRemarksNotes,
      NextFollowUpRemarksNotes: values.NextFollowupRemarksNotes,
    };
    post(POST_EnquiryFollowupsInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          getData();
          setFollowupModal(false);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };
  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Transaction" activeMenu="Enquiry" />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Enquiry List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          {AuthUser?.CompanyType === 1 &&
                          AuthUser?.UserType === 1 ? (
                            <Row>
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col lg={3}>
                              <Field
                                label={"Enquiry Status"}
                                name="EnquiryStatus"
                                placeholder={"Select Enquiry Status"}
                                options={enquiryStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 24 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <Link
                  to="/add-enquiry"
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add Enquiry
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                // onChange={handleOnChangeSearch}
                onChange={(event) => {
                  //setSearchvalue(event.target.value);
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="patient_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Enquiry No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Enquiry No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Client Name: activate to sort column ascending"
                          style={{ width: "30%" }}
                        >
                          Client Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Enquiry Type: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Contact No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Enquiry Date: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Enquiry Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Next Followup: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Next Followup
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {recordCount > 0 ? (
                      <tbody>
                        {enquiryList?.map((element, i) => (
                          <tr
                            role="row"
                            className="odd"
                            style={{
                              backgroundColor: element.IsRead
                                ? "#f6f6f6"
                                : "#e4f6ee",
                            }}
                          >
                            <td>{element.EnquiryNo}</td>
                            <td>{element.ContactPersonName}</td>
                            <td>{element.ContactPersonMobileNo}</td>
                            <td>{element.EnquiryDate}</td>
                            <td>
                              {element.NextFollowupDate ? (
                                element.NextFollowupDate
                              ) : (
                                <center>-</center>
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <div className="d-flex">
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 24 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <div
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Enquiry Detail"
                                      onClick={() => {
                                        getEnquiryDetail(
                                          element.EnquiryIDEncrypt,
                                          element.IsRead
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    {element.EnquiryStatus === 1 ? (
                                      <div
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Followup"
                                        onClick={() => {
                                          setNextfollowUpDate("");
                                          setNextFollowUpRemark("");
                                          formikEnquiryFollowup.resetForm();
                                          getEnquiryFollowupDetail(
                                            element.EnquiryIDEncrypt
                                          );
                                          setFollowupModal(true);
                                        }}
                                      >
                                        <i
                                          class="fa fa-comments-o"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    ) : (
                                      <div
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Close"
                                        onClick={() => {
                                          getEnquiryFollowupDetail(
                                            element.EnquiryIDEncrypt
                                          );
                                          setEnquiryStatusModal(true);
                                        }}
                                      >
                                        <i
                                          class="fa fa-lock"
                                          aria-hidden="true"
                                          style={{ color: "red" }}
                                        ></i>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="mt-3 ml-3">{"No Record Found"}</div>
                    )}
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <span
              class="badge"
              style={{ backgroundColor: "#f6f6f6", color: "#3e4954" }}
            >
              Read
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#e4f6ee", color: "#3e4954" }}
            >
              Unread
            </span>
          </div>
          <Modal
            className="modal fade"
            show={enquiryStatusModal}
            onHide={setEnquiryStatusModal}
            size="lg"
          >
            {/* {mode == 1 ? (
            <> */}
            <div className="modal-header">
              <h4 className="modal-title fs-20">Enquiry Closed</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setEnquiryStatusModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <Row>
                    <Col lg={12}>
                      <h6>Followup History</h6>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <div
                            id="patient_list"
                            className="dataTables_wrapper no-footer"
                          >
                            <table
                              id="example5"
                              className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                              role="grid"
                              aria-describedby="example5_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Followup Date: activate to sort column ascending"
                                    style={{ width: "10%" }}
                                  >
                                    Followup Date
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Followup Remarks: activate to sort column ascending"
                                    style={{ width: "40%" }}
                                  >
                                    Followup Remarks
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="GST Name: activate to sort column ascending"
                                    style={{ width: "10%" }}
                                  >
                                    Next Followup Date
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="GST Type: activate to sort column ascending"
                                    style={{ width: "40%" }}
                                  >
                                    Next Followup Remarks
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {enquiryFollowupDetail.map((element, i) => (
                                  <tr role="row" className="odd">
                                    <td>{element.FollowupTakenDate}</td>
                                    <td>
                                      {element.FollowupRemarks +
                                        (element?.FollowupRemarksNotes
                                          ? " - " +
                                            element?.FollowupRemarksNotes
                                          : "")}
                                    </td>
                                    <td>
                                      {" "}
                                      {element.NextFollowupDate ? (
                                        element.NextFollowupDate
                                      ) : (
                                        <center>-</center>
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {element.NextFollowupRemarks ? (
                                        element.NextFollowupRemarks +
                                        (element?.NextFollowupRemarksNotes
                                          ? " - " +
                                            element?.NextFollowupRemarksNotes
                                          : "")
                                      ) : (
                                        <center>-</center>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ float: "right" }}>
                    <button
                      className="btn btn-sm btn-light"
                      onClick={() => setEnquiryStatusModal(false)}
                    >
                      Close
                    </button>
                  </Row>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={followupModal}
            onHide={setFollowupModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Enquiry Followup</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setFollowupModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <FormikProvider value={formikEnquiryFollowup}>
                <Form onSubmit={formikEnquiryFollowup.handleSubmit}>
                  <i className="flaticon-cancel-12 close"></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div
                        className="card"
                        style={{
                          border: "1px solid",
                          borderColor: "black",
                        }}
                      >
                        <div className="cardbody">
                          <Row
                            style={{
                              marginLeft: "10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Col lg={3}>
                              <Field
                                label={"Current Followup Date"}
                                name="FollowupDate"
                                placeholder={"Select Followup Date"}
                                component={CustomFlatpickr}
                                options={{
                                  // minDate: new Date(),
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Current Followup Remarks"}
                                name="CurrentFollowupRemarks"
                                placeholder={"Select Followup Remarks"}
                                component={CustomSelect}
                                options={optionFollowUp}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Remarks"}
                                name="CurrentFollowupRemarksNotes"
                                placeholder={"Enter Remarks"}
                                component={CustomInput}
                              />
                            </Col>
                            {/* <Col lg={3}></Col> */}
                            <Col lg={3}>
                              <button
                                type="button"
                                style={{
                                  float: "right",
                                  marginTop: "12px",
                                  marginRight: "12px",
                                }}
                                className="btn btn-xs btn-light"
                                onClick={() => {
                                  getEnquiryDetail(enquiryID);
                                }}
                              >
                                View Detail
                              </button>
                            </Col>
                            <Col lg={12}>
                              <label className="radio-inline mr-3">
                                Status:
                              </label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="radio-inline mr-3">
                                <Field
                                  id="1"
                                  className="form-check-input"
                                  name="FollowupStatus"
                                  value={1}
                                  type="radio"
                                  checked={FollowupStatus == 1 ? true : false}
                                  onChange={(e) => {
                                    formikEnquiryFollowup.setFieldValue(
                                      "FollowupStatus",
                                      1
                                    );
                                  }}
                                />{" "}
                                Open
                              </label>
                              &nbsp;&nbsp;&nbsp;
                              <label className="radio-inline mr-3">
                                <Field
                                  id="1"
                                  className="form-check-input"
                                  name="FollowupStatus"
                                  value={2}
                                  type="radio"
                                  checked={FollowupStatus == 2 ? true : false}
                                  onChange={(e) => {
                                    formikEnquiryFollowup.setFieldValue(
                                      "FollowupStatus",
                                      2
                                    );
                                  }}
                                />{" "}
                                Close
                              </label>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <Row>
                        {nextfollowUpDate ? (
                          <Col lg={6}>
                            <h6>Next Followup Date:</h6> {nextfollowUpDate}
                          </Col>
                        ) : (
                          ""
                        )}
                        {nextFollowUpRemark ? (
                          <Col lg={6}>
                            <h6>Next Followup Remarks:</h6> {nextFollowUpRemark}
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      {FollowupStatus === 1 ? (
                        <>
                          <hr></hr>
                          <Row className="mt-3">
                            <Col lg={3}>
                              <Field
                                label={"Next Followup Date"}
                                name="NextFollowupDate"
                                placeholder={"Select Next Followup Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Next Followup Remarks"}
                                name="NextFollowupRemarks"
                                placeholder={"Select Followup Remarks"}
                                component={CustomSelect}
                                options={optionFollowUp}
                                // required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Remarks"}
                                name="NextFollowupRemarksNotes"
                                placeholder={"Enter Remarks"}
                                component={CustomInput}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        onClick={() => setFollowupModal(false)}
                        className="btn btn-sm btn-light"
                        style={{ float: "right", marginLeft: "4px" }}
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                      &nbsp; &nbsp;{" "}
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        style={{ float: "right" }}
                      >
                        Submit
                      </button>
                      <hr
                        style={{
                          marginTop: "2rem",
                          backgroundColor: "black",
                        }}
                      ></hr>
                      <Row>
                        <Col lg={12}>
                          <h6>Followup History</h6>
                          <Row className="mt-3">
                            <div className="table-responsive">
                              <div
                                id="patient_list"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example5"
                                  className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                                  role="grid"
                                  aria-describedby="example5_info"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="User Name: activate to sort column ascending"
                                        style={{ width: "20%" }}
                                      >
                                        User Name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Followup Date: activate to sort column ascending"
                                        style={{ width: "10%" }}
                                      >
                                        Followup Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Followup Remarks: activate to sort column ascending"
                                        style={{ width: "30%" }}
                                      >
                                        Followup Remarks
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="GST Name: activate to sort column ascending"
                                        style={{ width: "10%" }}
                                      >
                                        Next Followup Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="GST Type: activate to sort column ascending"
                                        style={{ width: "30%" }}
                                      >
                                        Next Followup Remarks
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {enquiryFollowupDetail.map((element, i) => (
                                      <tr role="row" className="odd">
                                        <td>{element.FollowupTaken}</td>
                                        <td>{element.FollowupTakenDate}</td>
                                        <td>
                                          {element.FollowupRemarks ? (
                                            element.FollowupRemarks +
                                            (element?.FollowupRemarksNotes
                                              ? " - " +
                                                element?.FollowupRemarksNotes
                                              : "")
                                          ) : (
                                            <center>-</center>
                                          )}
                                        </td>
                                        <td>
                                          {element.NextFollowupDate ? (
                                            element.NextFollowupDate
                                          ) : (
                                            <center>-</center>
                                          )}
                                        </td>
                                        <td>
                                          {element.NextFollowupRemarks ? (
                                            element.NextFollowupRemarks +
                                            (element?.NextFollowUpRemarksNotes
                                              ? " - " +
                                                element?.NextFollowUpRemarksNotes
                                              : "")
                                          ) : (
                                            <center>-</center>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Row>
                          <hr></hr>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={enquiryDetailModal}
            onHide={setEnquiryDetailModal}
            size="lg"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Enquiry Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setEnquiryDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <b>Enquiry No.</b>
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={5}>{enquiryDetail.EnquiryNo}</Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <b>Client Name</b>
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={5}>{enquiryDetail.ContactPersonName}</Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <b>Enquiry Date</b>
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={5}>{enquiryDetail.EnquiryDate}</Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <b>Contact No.</b>
                    </Col>
                    <Col lg={1}>:</Col>
                    <Col lg={5}>{enquiryDetail.ContactPersonMobileNo}</Col>
                  </Row>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mt-3">
                <div className="table-responsive">
                  <div
                    id="enquiry_list"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="example5"
                      className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead className="thead-light">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Enquiry Type: activate to sort column ascending"
                            style={{ width: "30%" }}
                          >
                            Enquiry Type
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Enquiry For: activate to sort column ascending"
                            style={{ width: "30%" }}
                          >
                            Enquiry For
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label=" Enquiry Remark: activate to sort column ascending"
                            style={{ width: "40%" }}
                          >
                            Enquiry Remark
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {enquiryDetail.EnquiryDetailList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.EnquiryTypeName}</td>
                            <td>{element.EnquiryDetails}</td>
                            <td>{element.EnquiryRemarks}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
              <Row style={{ float: "right" }}>
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => setEnquiryDetailModal(false)}
                >
                  Close
                </button>
              </Row>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default EnquiryList;
