import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Row, Col, Modal } from "react-bootstrap";
import PaginationButtons from "../../../Custom/Pagination";
import { post } from "../../../Helper/api_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import {
  POST_GetPathologyReportList,
  POST_PathologyReportDelete,
  POST_PathologyReportSetActiveInActive,
  POST_GetPathologyReportDetailByID,
  POST_PathologyReportCreate,
  POST_PathologyReportUpdate,
} from "../../../Helper/url_helper";
const PathologyReportList = () => {
  const [pathologyReportModal, setPathologyReportModal] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [pathologyList, setPathologyList] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const handleOnChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const validationSchemaPathologyReport = Yup.object().shape({
    PathologyReportName: Yup.string().required(
      "Pathology Report Name is required field"
    ),
  });

  const formikPathologyReport = useFormik({
    validationSchema: validationSchemaPathologyReport,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitPathologyReport(values, formikHelpers),
    initialValues: {
      PathologyReportID: "",
      PathologyReportName: "",
      Status: true,
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: { PathologyReportID },
  } = formikPathologyReport;

  useEffect(() => {
    getData();
  }, [search]);

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_GetPathologyReportList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: search,
      Sorting: "",
      LoginID: objLogin.LoginIDEncrypt,
    });
    setIsLoading(false);
    setRecordCount(response.TotalRecordCount);
    setPathologyList(response.PathologyReportList);
    //setCurrentPage(page);
    setCurrentSizePerPage(sizePerPage);
    if (page > 1 && response?.PathologyReportList?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  const delete_PathologyReport = (pID) => {
    swal({
      title: "Are you sure you want to delete the pathology report?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_PathologyReportConfirm(pID);
      }
    });
  };

  const delete_PathologyReportConfirm = async (pID) => {
    setIsLoading(true);
    let response = await post(POST_PathologyReportDelete, {
      EncryptedPathologyReportID: pID,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_PathologyReport = async (pID, pStatus) => {
    swal({
      title: `Are you sure you want to ${
        pStatus ? "inactive" : "active"
      } the pathology report?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_PathologyReportConfirm(pID, pStatus);
      }
    });
  };

  const ActiveInactive_PathologyReportConfirm = async (pID, pStatus) => {
    setIsLoading(true);
    let response = await post(POST_PathologyReportSetActiveInActive, {
      EncryptedPathologyReportID: pID,
      IsActive: !pStatus,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: pStatus ? "Inactivated" : "Activated",
        text: pStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const get_PathologyReport = async (pID) => {
    setIsLoading(true);
    let response = await post(POST_GetPathologyReportDetailByID, {
      PathologyReportIDEncrypt: pID,
    });
    if (response.IsSuccess) {
      setValues({
        PathologyReportID: response.PathologyReportIDEncrypt,
        PathologyReportName: response.PathologyReportName,
        Status: response.IsActive,
      });
      setPathologyReportModal(true);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const handleValidSubmitPathologyReport = (values, { setSubmitting }) => {
    setIsLoading(true);
    const data = {
      PathologyReportIDEncrypt: values.PathologyReportID,
      PathologyReportName: values.PathologyReportName.trim(),
      IsActive: values.Status,
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
    };
    post(
      PathologyReportID
        ? POST_PathologyReportUpdate
        : POST_PathologyReportCreate,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          setPathologyReportModal(false);
          getData(1, currentSizePerPage);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
          // setPathologyReportModal(false);
          // formikPathologyReport.resetForm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  function add_PathologyReport() {
    formikPathologyReport.resetForm();
    setValues({
      PathologyReportID: "",
      PathologyReportName: "",
      Status: true,
    });
    setPathologyReportModal(!pathologyReportModal);
  }

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Pathology Reports" />

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 11 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <div
                  onClick={() => add_PathologyReport()}
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add Pathology Reports
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                value={search}
                onChange={handleOnChangeSearch}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="patho_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Pathology Report Name: activate to sort column ascending"
                          style={{ width: "90%" }}
                        >
                          Pathology Report Name
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {recordCount > 0 ? (
                      <tbody>
                        {pathologyList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td key={i}>{element.PathologyReportName}</td>
                            <td>
                              <div className="d-flex">
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 11 && item.EditAction
                                  ).length > 0) ? (
                                  <>
                                    <div
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                      onClick={() => {
                                        get_PathologyReport(
                                          element.PathologyReportIDEncrypt
                                        );
                                      }}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </div>
                                    <Link
                                      // to="#"
                                      className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                      title={
                                        element.IsActive ? "Active" : "Inactive"
                                      }
                                      onClick={() => {
                                        ActiveInactive_PathologyReport(
                                          element.PathologyReportIDEncrypt,
                                          element.IsActive
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle mt-1"
                                        style={{
                                          color: element.IsActive
                                            ? "green"
                                            : "red",
                                        }}
                                      ></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 11 && item.DeleteAction
                                  ).length > 0) ? (
                                  <Link
                                    to="#"
                                    className="btn btn-light shadow btn-xs sharp"
                                    title="Delete"
                                    onClick={() => {
                                      delete_PathologyReport(
                                        element.PathologyReportIDEncrypt
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="mt-3 ml-3">{"No Record Found"}</div>
                    )}
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            className="modal fade"
            show={pathologyReportModal}
            onHide={setPathologyReportModal}
          >
            <div className="">
              <div className="">
                <FormikProvider value={formikPathologyReport}>
                  <Form onSubmit={formikPathologyReport.handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Pathology Report</h4>
                      <button
                        type="button"
                        className="btn close"
                        onClick={() => setPathologyReportModal(false)}
                      >
                        <span>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <Row>
                            <Col lg={12}>
                              <Field
                                type="text"
                                label={"Pathology Report Name"}
                                name="PathologyReportName"
                                placeholder={"Enter Pathology Report Name"}
                                component={CustomInput}
                                // onChange={(e) => {
                                //   setFieldValue(
                                //     "PathologyReportName",
                                //     e.target.value.replace(
                                //       /[^A-Za-z 0-9 - + () ]/g,
                                //       ""
                                //     )
                                //   );
                                // }}
                                required
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setPathologyReportModal(false)}
                        className="btn btn-light"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default PathologyReportList;
