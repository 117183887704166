import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { useHistory, Link, useParams } from "react-router-dom";
import { Spin } from "antd";
import { post } from "../../../Helper/api_helper";
import {
  POST_StaffDropDownForOverTime,
  POST_OrderBookingNoDropdDwnStaffWise,
  POST_StaffServiceScheduleDateDropDownOrderBookingWise,
  POST_StaffOverTimeInsert,
  POST_StaffOverTimeUpdate,
  POST_GetStaffOverTimeDetailByID,
} from "../../../Helper/url_helper";

import swal from "sweetalert";
import { t } from "i18next";

import CustomInput from "../../../Custom/CustomInput";

const AddStaffOverTime = () => {
  const { StaffOverTimeIDEncryptParam } = useParams();

  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);
  const [optionStaff, setOptionStaff] = useState([]);
  const [optionOrderBookingNo, setOrderBookingNo] = useState([]);
  const [optionDate, setOptionDate] = useState([]);

  const validationSchema = Yup.object().shape({
    Staff: Yup.string().required("Staff is required field."),
    OrderBookingNo: Yup.string().required(
      "Order Booking No is required field."
    ),
    Date: Yup.string().required("Date is required field."),
    Remark: Yup.string().required("Remark is required field."),
    StaffOverTimeHours: Yup.string().required(
      "Staff Over Time Hours is required field."
    ),

    Amount: Yup.number().when("IsApprove", {
      is: 1,
      then: Yup.number().required("Amount is requried field."),
    }),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      OrderBookingServiceScheduleIDEncrypt: "",
      Staff: "",
      OrderBookingNo: "",
      Date: "",
      Remark: "",
      StaffOverTimeHours: "",
      IsApprove: 0,
      Amount: "",
      PatientName: "",
      PatientNo: "",
      ServiceName: "",
      ScheduleDate: "",
      PunchInTime: "",
      PunchOutTime: "",
      submitType: "",
      StaffOverTimeIDEncrypt: StaffOverTimeIDEncryptParam
        ? window.atob(StaffOverTimeIDEncryptParam)
        : "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      IsApprove,
      Staff,
      OrderBookingNo,
      Date,
      PatientName,
      PatientNo,
      ServiceName,
      ScheduleDate,
      PunchInTime,
      PunchOutTime,
    },
  } = formik;

  useEffect(() => {
    getStaffOptionList();
    if (StaffOverTimeIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetStaffOverTimeDetailByID, {
        StaffOverTimeIDEncrypted: window.atob(StaffOverTimeIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            StaffOverTimeIDEncrypt: window.atob(StaffOverTimeIDEncryptParam),
            OrderBookingServiceScheduleIDEncrypt:
              res.OrderBookingServiceScheduleIDEncrypted,
            Staff: res.StaffIDEncrypted,
            OrderBookingNo: res.OrderBookingIDEncrypted,
            Date: res.OrderBookingServiceScheduleIDEncrypted,
            Remark: res.ApprovalRemarks,
            StaffOverTimeHours: res.StaffOverTimeHours,
            // IsApprove: res.StaffOverTimeStatus === 1 ? true : false,
            IsApprove: res.StaffOverTimeStatus,
            Amount: res.OverTimeAmount > 0 ? res.OverTimeAmount : "",
            PatientName: res.PatientName,
            PatientNo: res.PatientNo,
            ServiceName: res.ServiceName,
            ScheduleDate: res.ScheduledPunchInDate,
            PunchInTime: res.StaffPunchIn,
            PunchOutTime: res.StaffPunchOut,
            submitType:
              res.StaffOverTimeStatus === 1
                ? "Approve"
                : res.StaffOverTimeStatus === 2
                ? "Reject"
                : 0,
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  const handleChangeIsApprove = (event) => {
    setFieldValue("IsApprove", event.target.checked);
    setFieldValue("submitType", "Approve");
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      OrderBookingServiceScheduleIDEncrypt:
        values.OrderBookingServiceScheduleIDEncrypt,
      OrderBookingIDEncrypt: values.OrderBookingNo,
      StaffIDEncrypt: values.Staff,
      ServiceDate: values.ScheduleDate,
      StaffOverTimeHours: values.StaffOverTimeHours,
      // StaffOverTimeStatus: values.IsApprove
      //   ? 1
      //   : values.submitType === "Reject"
      //   ? 2
      //   : 0,
      StaffOverTimeStatus: values.IsApprove,
      OverTimeAmount: values.IsApprove === 1 ? values.Amount : "0",
      StaffRemarks: "",
      ApprovalRemarks: values.Remark,
      ApprovalByEncrypt: values.IsApprove === 1 ? objLogin.LoginIDEncrypt : "",
      CreatedByEncrypt: objLogin.LoginIDEncrypt,

      StaffOverTimeIDEncrypt: values.StaffOverTimeIDEncrypt,
      ModifyByEncrypt: objLogin.LoginIDEncrypt,
    };
    post(
      StaffOverTimeIDEncryptParam
        ? POST_StaffOverTimeUpdate
        : POST_StaffOverTimeInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/staff-over-time");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const getStaffOptionList = () => {
    setIsLoading(true);
    post(POST_StaffDropDownForOverTime, {
      CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionStaff(
            res.StaffDropDownForOverTimeList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    if (Staff) {
      getOrderBookingList(Staff);
    } else {
      setOptionDate([]);
      setOrderBookingNo([]);
      setFieldValue("OrderBookingNo", "");
      setFieldValue("Date", "");
    }
  }, [Staff]);

  const getOrderBookingList = (StaffID) => {
    setIsLoading(true);
    post(POST_OrderBookingNoDropdDwnStaffWise, {
      StaffIDEncrypt: StaffID,
      CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOrderBookingNo(
            res.OrderBookingNoDropdDwnStaffWiseList.map((item) => ({
              label: item.OrderBookingNo,
              value: item.OrderBookingIDEncrypt,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    if (OrderBookingNo) {
      getDateList(OrderBookingNo);
    } else {
      setOptionDate([]);
      setFieldValue("Date", "");
    }
  }, [OrderBookingNo]);

  const getDateList = (OrderBookingNoID) => {
    setIsLoading(true);
    post(POST_StaffServiceScheduleDateDropDownOrderBookingWise, {
      OrderBookingIDEncrypt: OrderBookingNoID,
      CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionDate(
            res.StaffServiceScheduleDateDropDownList.map((item) => ({
              label: item.ScheduledPunchInDate,
              value: item.OrderBookingServiceScheduleIDEncrypt,
              PatientName: item.PatientName,
              PatientNo: item.PatientNo,
              ServiceName: item.ServiceName,
              ScheduleDate: item.ScheduledPunchInDate,
              PunchInTime: item.ScheduledPunchIn,
              PunchOutTime: item.ScheduledPunchOut,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    if (!Date) {
      setFieldValue("PatientName", "");
      setFieldValue("PatientNo", "");
      setFieldValue("ServiceName", "");
      setFieldValue("ScheduleDate", "");
      setFieldValue("PunchInTime", "");
      setFieldValue("PunchOutTime", "");
    }
  }, [Date]);

  const optionHours = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
  ];

  const OptionStatus = [
    { label: "Pending", value: 0 },
    { label: "Approved", value: 1 },
    { label: "Rejected", value: 2 },
  ];

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            StaffOverTimeIDEncryptParam
              ? "Edit Staff Over Time"
              : "Add Staff Over Time"
          }
          motherMenu={<Link to="/staff-over-time">Staff Over Time</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={3}>
                          <Field
                            label={"Staff"}
                            name="Staff"
                            options={optionStaff}
                            component={CustomSelect}
                            placeholder={"Select Staff"}
                            required
                            isDisabled={
                              StaffOverTimeIDEncryptParam ? true : false
                            }
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Order Booking No"}
                            name="OrderBookingNo"
                            options={optionOrderBookingNo}
                            component={CustomSelect}
                            placeholder={"Select Order Booking No"}
                            isDisabled={
                              StaffOverTimeIDEncryptParam ? true : false
                            }
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Date"}
                            name="Date"
                            options={optionDate}
                            component={CustomSelect}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue(
                                  "OrderBookingServiceScheduleIDEncrypt",
                                  e.value
                                );
                                setFieldValue("Date", e.value);
                                setFieldValue("PatientName", e.PatientName);
                                setFieldValue("PatientNo", e.PatientNo);
                                setFieldValue("ServiceName", e.ServiceName);
                                setFieldValue("ScheduleDate", e.ScheduleDate);
                                setFieldValue("PunchInTime", e.PunchInTime);
                                setFieldValue("PunchOutTime", e.PunchOutTime);
                              } else {
                                setFieldValue("Date", "");
                                setFieldValue("PatientName", "");
                                setFieldValue("PatientNo", "");
                                setFieldValue("ServiceName", "");
                                setFieldValue("ScheduleDate", "");
                                setFieldValue("PunchInTime", "");
                                setFieldValue("PunchOutTime", "");
                              }
                            }}
                            placeholder={"Select Date"}
                            required
                          />
                        </Col>

                        <Col lg={3}>
                          <Field
                            label={"Staff Over Time Hours"}
                            name="StaffOverTimeHours"
                            placeholder={"Staff Over Time Hours"}
                            component={CustomSelect}
                            options={optionHours}
                            required
                          />
                        </Col>

                        <Col lg={4}>
                          <Field
                            label={"Remark"}
                            type="textarea"
                            name="Remark"
                            placeholder={"Enter Remark"}
                            component={CustomInput}
                            rows={3}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      {Date ? (
                        <>
                          <Row>
                            <Col lg={4}>
                              <b>Patient Name</b> : &nbsp;&nbsp; {PatientName}
                            </Col>
                            <Col lg={3}>
                              <b>Patient No</b> : &nbsp;&nbsp; {PatientNo}
                            </Col>
                            <Col lg={3}>
                              <b>Service Name</b> : &nbsp;&nbsp; {ServiceName}
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col lg={4}>
                              <b>Schedule Date</b> : &nbsp;&nbsp; {ScheduleDate}
                            </Col>
                            <Col lg={3}>
                              <b>Punch In Time</b> : &nbsp;&nbsp; {PunchInTime}
                            </Col>
                            <Col lg={3}>
                              <b>Punch Out Time</b> : &nbsp;&nbsp;{" "}
                              {PunchOutTime}
                            </Col>
                          </Row>
                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col lg={4}>
                          {/* <div
                            className="custom-control custom-checkbox mb-3 mt-1 checkbox-success"
                            style={{ zIndex: 0 }}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="IsApprove"
                              onChange={handleChangeIsApprove}
                              checked={IsApprove}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="IsApprove"
                            >
                              Is Approve
                            </label>
                          </div> */}
                          <Field
                            label={"Status"}
                            name="IsApprove"
                            placeholder={"Select Status"}
                            component={CustomSelect}
                            options={OptionStatus}
                            required
                          />
                        </Col>
                        {IsApprove === 1 ? (
                          <>
                            <Col lg={4}>
                              <Field
                                type="number"
                                label={"Overtime Payout"}
                                name="Amount"
                                placeholder={"Enter Amount"}
                                component={CustomInput}
                                min={0}
                                onChange={(e) => {
                                  setFieldValue(
                                    "Amount",
                                    e.target.value.slice(0, 7)
                                  );
                                }}
                                required
                              />
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                      <hr></hr>
                      <br></br>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      {/* {StaffOverTimeIDEncryptParam ? (
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={(e) => {
                            setFieldValue("submitType", "Reject");
                            setFieldValue("IsApprove", false);
                          }}
                        >
                          Reject
                        </button>
                      ) : (
                        ""
                      )}
                      &nbsp;&nbsp; */}
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.push("/staff-over-time");
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddStaffOverTime;
