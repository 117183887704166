import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Label, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Link, useHistory } from "react-router-dom";
import {
  GET_GetAdvanceSlabConfiguration,
  POST_AdvanceSlabConfigurationSave,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

const AdvanceSlabConfigruation = () => {
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);
  const [textButton, setTextButton] = useState("Add");

  const [
    validationMessageStartAmountService,
    setValidationMessageStartAmountService,
  ] = useState("");
  const [
    validationMessageEndAmountService,
    setValidationMessageEndAmountService,
  ] = useState("");
  const [
    validationMessagePercentageService,
    setValidationMessagePercentageService,
  ] = useState("");
  const [
    validationMessageStartAmountPackage,
    setValidationMessageStartAmountPackage,
  ] = useState("");
  const [
    validationMessageEndAmountPackage,
    setValidationMessageEndAmountPackage,
  ] = useState("");
  const [
    validationMessagePercentagePackage,
    setValidationMessagePercentagePackage,
  ] = useState("");
  const [
    validationMessageStartAmountHealthCheckup,
    setValidationMessageStartAmountHealthCheckup,
  ] = useState("");
  const [
    validationMessageEndAmountHealthCheckup,
    setValidationMessageEndAmountHealthCheckup,
  ] = useState("");
  const [
    validationMessagePercentageHealthCheckup,
    setValidationMessagePercentageHealthCheckup,
  ] = useState("");

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      ListofServiceEquipmentDiagnostics: [],
      ListofPackage: [],
      ListofHealthCheckup: [],
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      ListofServiceEquipmentDiagnostics,
      ListofPackage,
      ListofHealthCheckup,
      AdvanceSlabConfigurationIDEncrypted1,
      SlabEndAmountService,
      SlabPercentageService,
      SlabStartAmountService,
      SlabEndAmountPackage,
      SlabPercentagePackage,
      SlabStartAmountPackage,
      SlabEndAmountHealthCheckup,
      SlabPercentageHealthCheckup,
      SlabStartAmountHealthCheckup,
    },
  } = formik;

  useEffect(() => {
    setIsLoading(true);
    get(GET_GetAdvanceSlabConfiguration).then((res) => {
      if (res.IsSuccess) {
        setValues({
          ListofServiceEquipmentDiagnostics:
            res.ServiceEquipmentDiagnostics_AdvanceSlabList,
          ListofPackage: res.Package_AdvanceSlabList,
          ListofHealthCheckup: res.HealthCheckup_AdvanceSlabList,
        });
      }
    });
    setIsLoading(false);
  }, []);

  const handleValidSubmit = (values, { setSubmitting }) => {
    const data = {
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
      ServiceEquipmentDiagnostics_AdvanceSlabList:
        ListofServiceEquipmentDiagnostics,
      Package_AdvanceSlabList: ListofPackage,
      HealthCheckup_AdvanceSlabList: ListofHealthCheckup,
    };
    post(POST_AdvanceSlabConfigurationSave, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const validateEmptySevice = () => {
    var flag = true;
    if (SlabStartAmountService == null) {
      setValidationMessageStartAmountService("Slab Start Amount is required.");
      flag = false;
    }
    if (SlabEndAmountService == null) {
      setValidationMessageEndAmountService("Slab End Amount is required.");
      flag = false;
    }
    if (SlabPercentageService === undefined) {
      setValidationMessagePercentageService("Slab Percentage is required.");
      flag = false;
    }

    return flag;
  };
  const validateEmptyPackage = () => {
    var flag = true;
    if (SlabStartAmountPackage == null) {
      setValidationMessageStartAmountPackage("Slab Start Amount is required.");
      flag = false;
    }
    if (SlabEndAmountPackage == null) {
      setValidationMessageEndAmountPackage("Slab End Amount is required.");
      flag = false;
    }
    if (SlabPercentagePackage === undefined) {
      setValidationMessagePercentagePackage("Slab Percentage is required.");
      flag = false;
    }

    return flag;
  };
  const validateEmptyHealthCheckup = () => {
    var flag = true;
    if (SlabStartAmountHealthCheckup == null) {
      setValidationMessageStartAmountHealthCheckup(
        "Slab Start Amount is required."
      );
      flag = false;
    }
    if (SlabEndAmountHealthCheckup == null) {
      setValidationMessageEndAmountHealthCheckup(
        "Slab End Amount is required."
      );
      flag = false;
    }
    if (SlabPercentageHealthCheckup === undefined) {
      setValidationMessagePercentageHealthCheckup(
        "Slab Percentage is required."
      );
      flag = false;
    }

    return flag;
  };

  const add_ServiceEquipmentDiagnostics = () => {
    if (!validateEmptySevice()) {
      return false;
    }

    if (SlabStartAmountService <= SlabEndAmountService) {
      ListofServiceEquipmentDiagnostics.filter(
        (element) =>
          (SlabStartAmountService >= element.AdvanceSlabStartAmount &&
            SlabStartAmountService <= element.AdvanceSlabEndAmount) ||
          (SlabEndAmountService >= element.AdvanceSlabStartAmount &&
            SlabEndAmountService <= element.AdvanceSlabEndAmount) ||
          (element.AdvanceSlabStartAmount >= SlabStartAmountService &&
            element.AdvanceSlabStartAmount <= SlabEndAmountService) ||
          (element.AdvanceSlabEndAmount >= SlabStartAmountService &&
            element.AdvanceSlabEndAmount <= SlabEndAmountService)
      ).length > 0
        ? swal({
            title: "Error",
            text: "Slab range already exist.",
            icon: "error",
            button: "OK",
          })
        : setFieldValue("ListofServiceEquipmentDiagnostics", [
            ...ListofServiceEquipmentDiagnostics,
            {
              AdvanceSlabConfigurationIDEncrypted: "",
              AdvanceSlabType: 1,
              AdvanceSlabStartAmount: SlabStartAmountService,
              AdvanceSlabEndAmount: SlabEndAmountService,
              AdvancePercentage: SlabPercentageService,
            },
          ]);
    } else {
      swal({
        title: "",
        // text: "Slab range already exist.",
        text: "End Amount must be greater than Start Amount.",
        icon: "warning",
        button: "OK",
      });
    }
    setValidationMessagePercentageService("");
    setValidationMessageEndAmountService("");
    setValidationMessageStartAmountService("");
    setFieldValue("SlabStartAmountService", "");
    setFieldValue("SlabEndAmountService", "");
    setFieldValue("SlabPercentageService", "");
  };

  const add_Package = () => {
    if (!validateEmptyPackage()) {
      return false;
    }
    if (SlabStartAmountPackage <= SlabEndAmountPackage) {
      ListofPackage.filter(
        (element) =>
          (SlabStartAmountPackage >= element.AdvanceSlabStartAmount &&
            SlabStartAmountPackage <= element.AdvanceSlabEndAmount) ||
          (SlabEndAmountPackage >= element.AdvanceSlabStartAmount &&
            SlabEndAmountPackage <= element.AdvanceSlabEndAmount) ||
          (element.AdvanceSlabStartAmount >= SlabStartAmountPackage &&
            element.AdvanceSlabStartAmount <= SlabEndAmountPackage) ||
          (element.AdvanceSlabEndAmount >= SlabStartAmountPackage &&
            element.AdvanceSlabEndAmount <= SlabEndAmountPackage)
      ).length > 0
        ? swal({
            title: "Error",
            text: "Slab range already exist.",
            icon: "error",
            button: "OK",
          })
        : setFieldValue("ListofPackage", [
            ...ListofPackage,
            {
              AdvanceSlabConfigurationIDEncrypted: "",
              AdvanceSlabType: 2,
              AdvanceSlabStartAmount: SlabStartAmountPackage,
              AdvanceSlabEndAmount: SlabEndAmountPackage,
              AdvancePercentage: SlabPercentagePackage,
            },
          ]);
    } else {
      swal({
        title: "",
        // text: "Slab range already exist.",
        text: "End Amount must be greater than Start Amount.",
        icon: "warning",
        button: "OK",
      });
    }
    setValidationMessageEndAmountPackage("");
    setValidationMessagePercentagePackage("");
    setValidationMessageStartAmountPackage("");
    setFieldValue("SlabStartAmountPackage", "");
    setFieldValue("SlabEndAmountPackage", "");
    setFieldValue("SlabPercentagePackage", "");
    // setTextButton("Add");
  };

  const add_HealthCheckup = () => {
    if (!validateEmptyHealthCheckup()) {
      return false;
    }
    if (SlabStartAmountHealthCheckup <= SlabEndAmountHealthCheckup) {
      ListofHealthCheckup.filter(
        (element) =>
          (SlabStartAmountHealthCheckup >= element.AdvanceSlabStartAmount &&
            SlabStartAmountHealthCheckup <= element.AdvanceSlabEndAmount) ||
          (SlabEndAmountHealthCheckup >= element.AdvanceSlabStartAmount &&
            SlabEndAmountHealthCheckup <= element.AdvanceSlabEndAmount) ||
          (element.AdvanceSlabStartAmount >= SlabStartAmountHealthCheckup &&
            element.AdvanceSlabStartAmount <= SlabEndAmountHealthCheckup) ||
          (element.AdvanceSlabEndAmount >= SlabStartAmountHealthCheckup &&
            element.AdvanceSlabEndAmount <= SlabEndAmountHealthCheckup)
      ).length > 0
        ? swal({
            title: "Error",
            text: "Slab range already exist.",
            icon: "error",
            button: "OK",
          })
        : setFieldValue("ListofHealthCheckup", [
            ...ListofHealthCheckup,
            {
              AdvanceSlabConfigurationIDEncrypted: "",
              AdvanceSlabType: 3,
              AdvanceSlabStartAmount: SlabStartAmountHealthCheckup,
              AdvanceSlabEndAmount: SlabEndAmountHealthCheckup,
              AdvancePercentage: SlabPercentageHealthCheckup,
            },
          ]);
    } else {
      swal({
        title: "",
        // text: "Slab range already exist.",
        text: "End Amount must be greater than Start Amount.",
        icon: "warning",
        button: "OK",
      });
    }
    setValidationMessageEndAmountHealthCheckup("");
    setValidationMessagePercentageHealthCheckup("");
    setValidationMessageStartAmountHealthCheckup("");
    setFieldValue("SlabStartAmountHealthCheckup", "");
    setFieldValue("SlabEndAmountHealthCheckup", "");
    setFieldValue("SlabPercentageHealthCheckup", "");
    // setTextButton("Add");
  };

  const delete_ServiceEquipmentDiagnostics = (index) => {
    swal({
      title: "Are you sure you want to delete the slab range?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setFieldValue(
          "ListofServiceEquipmentDiagnostics",
          ListofServiceEquipmentDiagnostics.filter((element, i) => i !== index)
        );
      }
    });
  };

  // const edit_ServiceEquipmentDiagnostics = (index) => {
  //   setTextButton("Edit");
  //   ListofServiceEquipmentDiagnostics.filter((element, i) =>
  //     i === index
  //       ? (setFieldValue(
  //           "SlabStartAmountService",
  //           element.AdvanceSlabStartAmount
  //         ),
  //         setFieldValue("SlabEndAmountService", element.AdvanceSlabEndAmount),
  //         setFieldValue("SlabPercentageService", element.AdvancePercentage))
  //       : ""
  //   );
  //   console.log(
  //     "ListofServiceEquipmentDiagnostics",
  //     ...ListofServiceEquipmentDiagnostics,
  //     ListofServiceEquipmentDiagnostics.filter((element, i) => i === index)
  //   );
  // };

  const delete_Package = (index) => {
    swal({
      title: "Are you sure you want to delete the slab range?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setFieldValue(
          "ListofPackage",
          ListofPackage.filter((element, i) => i !== index)
        );
      }
    });
  };

  // const edit_Package = (index) => {
  //   setTextButton("Edit");
  //   ListofPackage.filter((element, i) =>
  //     i === index
  //       ? (setFieldValue(
  //           "SlabStartAmountPackage",
  //           element.AdvanceSlabStartAmount
  //         ),
  //         setFieldValue("SlabEndAmountPackage", element.AdvanceSlabEndAmount),
  //         setFieldValue("SlabPercentagePackage", element.AdvancePercentage))
  //       : ""
  //   );
  //   console.log(
  //     "ListofPackage",
  //     ...ListofPackage,
  //     ListofPackage.filter((element, i) => i === index)
  //   );
  // };
  const delete_HealthCheckup = (index) => {
    swal({
      title: "Are you sure you want to delete the slab range?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setFieldValue(
          "ListofHealthCheckup",
          ListofHealthCheckup.filter((element, i) => i !== index)
        );
      }
    });
  };

  // const edit_HealthCheckup = (index) => {
  //   setTextButton("Edit");
  //   ListofHealthCheckup.filter((element, i) =>
  //     i === index
  //       ? (setFieldValue(
  //           "SlabStartAmountHealthCheckup",
  //           element.AdvanceSlabStartAmount
  //         ),
  //         setFieldValue(
  //           "SlabEndAmountHealthCheckup",
  //           element.AdvanceSlabEndAmount
  //         ),
  //         setFieldValue(
  //           "SlabPercentageHealthCheckup",
  //           element.AdvancePercentage
  //         ))
  //       : ""
  //   );
  //   console.log(
  //     "ListofHealthCheckup",
  //     ...ListofHealthCheckup,
  //     ListofHealthCheckup.filter((element, i) => i === index)
  //   );
  // };
  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu="Advance Slab Configuration"
          motherMenu="Configuration"
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <h5 className="mb-4">
                        <u>Service + Equipment + Diagnostics</u>
                      </h5>
                      <Row className="mb-3">
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab Start Amount"}
                            name="SlabStartAmountService"
                            placeholder={"Enter Slab Start Amount"}
                            component={CustomInput}
                            style={{ marginBottom: "0rem !important" }}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              // marginTop: "0.05rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessageStartAmountService}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab End Amount"}
                            name="SlabEndAmountService"
                            placeholder={"Enter Slab End Amount"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessageEndAmountService}
                          </span>
                        </Col>

                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab Percentage"}
                            name="SlabPercentageService"
                            placeholder={"Enter Slab Percentage"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessagePercentageService}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <button
                            type="button"
                            className="btn mr-2 btn-sm btn-primary mt-4"
                            onClick={(values) => {
                              add_ServiceEquipmentDiagnostics();
                            }}
                          >
                            {textButton}
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={12}>
                          <div className="table-responsive ">
                            <div className="dataTables_wrapper no-footer">
                              <table
                                className="table verticle-middle table-responsive-md mb-3"
                                style={{ width: "70%" }}
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Slab Start Amount: activate to sort column ascending"
                                      style={{
                                        width: "60%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Slab Range
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Slab Percentage: activate to sort column ascending"
                                      style={{
                                        width: "30%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Slab Percentage
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="CheckBox: activate to sort column ascending"
                                      style={{
                                        width: "10%",
                                        padding: "10px",
                                      }}
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListofServiceEquipmentDiagnostics &&
                                    ListofServiceEquipmentDiagnostics.map(
                                      (slabDetail, index) => (
                                        <tr role="row" className="odd">
                                          <td>
                                            {slabDetail.AdvanceSlabStartAmount}-
                                            {slabDetail.AdvanceSlabEndAmount}
                                          </td>
                                          <td>
                                            {slabDetail.AdvancePercentage} %
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              {/* <Link
                                                to="#"
                                                className="btn btn-light shadow btn-xs sharp mr-1"
                                                title="Edit"
                                                onClick={() => {
                                                  edit_ServiceEquipmentDiagnostics(
                                                    index
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </Link> */}

                                              <Link
                                                to="#"
                                                className="btn btn-light shadow btn-xs sharp"
                                                title="Delete"
                                                onClick={() => {
                                                  delete_ServiceEquipmentDiagnostics(
                                                    index
                                                  );
                                                }}
                                              >
                                                <i
                                                  className="fa fa-trash"
                                                  style={{ color: "red" }}
                                                ></i>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  {/* <tr role="row" className="even">
                                    <td>5001 - 10000</td>
                                    <td>10%</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>

                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row" className="odd">
                                    <td>10001 - 20000</td>
                                    <td>15%</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>

                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <h5 className="mb-4">
                        <u>Package</u>
                      </h5>
                      <Row className="mb-3">
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab Start Amount"}
                            name="SlabStartAmountPackage"
                            placeholder={"Enter Slab Start Amount"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessageStartAmountPackage}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab End Amount"}
                            name="SlabEndAmountPackage"
                            placeholder={"Enter Slab End Amount"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessageEndAmountPackage}
                          </span>
                        </Col>

                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab Percentage"}
                            name="SlabPercentagePackage"
                            placeholder={"Enter Slab Percentage"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessagePercentagePackage}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <button
                            type="button"
                            className="btn mr-2 btn-sm btn-primary mt-4"
                            onClick={(values) => {
                              add_Package();
                            }}
                          >
                            {textButton}
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={12}>
                          <div className="table-responsive ">
                            <div className="dataTables_wrapper no-footer">
                              <table
                                className="table verticle-middle table-responsive-md mb-3"
                                style={{ width: "70%" }}
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Slab Start Amount: activate to sort column ascending"
                                      style={{
                                        width: "60%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Slab Range
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Slab Percentage: activate to sort column ascending"
                                      style={{
                                        width: "30%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Slab Percentage
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="CheckBox: activate to sort column ascending"
                                      style={{
                                        width: "10%",
                                        padding: "10px",
                                      }}
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListofPackage &&
                                    ListofPackage.map((slabDetail, index) => (
                                      <tr role="row" className="odd">
                                        <td>
                                          {slabDetail.AdvanceSlabStartAmount}-
                                          {slabDetail.AdvanceSlabEndAmount}
                                        </td>
                                        <td>
                                          {slabDetail.AdvancePercentage} %
                                        </td>
                                        <td>
                                          <div className="d-flex">
                                            {/* <Link
                                              to="#"
                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                              title="Edit"
                                              onClick={() => {
                                                edit_Package(index);
                                              }}
                                            >
                                              <i className="fa fa-pencil"></i>
                                            </Link> */}

                                            <Link
                                              to="#"
                                              className="btn btn-light shadow btn-xs sharp"
                                              title="Delete"
                                              onClick={() => {
                                                delete_Package(index);
                                              }}
                                            >
                                              <i
                                                className="fa fa-trash"
                                                style={{ color: "red" }}
                                              ></i>
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  {/* <tr role="row" className="even">
                                    <td>5001 - 10000</td>
                                    <td>10%</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>

                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row" className="odd">
                                    <td>10001 - 20000</td>
                                    <td>15%</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>

                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <h5 className="mb-4">
                        <u>Health Checkup</u>
                      </h5>
                      <Row className="mb-3">
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab Start Amount"}
                            name="SlabStartAmountHealthCheckup"
                            placeholder={"Enter Slab Start Amount"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessageStartAmountHealthCheckup}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab End Amount"}
                            name="SlabEndAmountHealthCheckup"
                            placeholder={"Enter Slab End Amount"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessageEndAmountHealthCheckup}
                          </span>
                        </Col>

                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Slab Percentage"}
                            name="SlabPercentageHealthCheckup"
                            placeholder={"Enter Slab Percentage"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationMessagePercentageHealthCheckup}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <button
                            type="button"
                            className="btn mr-2 btn-sm btn-primary mt-4"
                            onClick={(values) => {
                              add_HealthCheckup();
                            }}
                          >
                            {textButton}
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={12}>
                          <div className="table-responsive ">
                            <div className="dataTables_wrapper no-footer">
                              <table
                                className="table verticle-middle table-responsive-md mb-3"
                                style={{ width: "70%" }}
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Slab Start Amount: activate to sort column ascending"
                                      style={{
                                        width: "60%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Slab Range
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Slab Percentage: activate to sort column ascending"
                                      style={{
                                        width: "30%",
                                        padding: "10px 20px",
                                      }}
                                    >
                                      Slab Percentage
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example5"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="CheckBox: activate to sort column ascending"
                                      style={{
                                        width: "10%",
                                        padding: "10px",
                                      }}
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListofHealthCheckup &&
                                    ListofHealthCheckup.map(
                                      (slabDetail, index) => (
                                        <tr role="row" className="odd">
                                          <td>
                                            {slabDetail.AdvanceSlabStartAmount}-
                                            {slabDetail.AdvanceSlabEndAmount}
                                          </td>
                                          <td>
                                            {slabDetail.AdvancePercentage} %
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              {/* <Link
                                                to="#"
                                                className="btn btn-light shadow btn-xs sharp mr-1"
                                                title="Edit"
                                                onClick={() => {
                                                  edit_HealthCheckup(index);
                                                }}
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </Link> */}

                                              <Link
                                                to="#"
                                                className="btn btn-light shadow btn-xs sharp"
                                                title="Delete"
                                                onClick={() => {
                                                  delete_HealthCheckup(index);
                                                }}
                                              >
                                                <i
                                                  className="fa fa-trash"
                                                  style={{ color: "red" }}
                                                ></i>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  {/* <tr role="row" className="even">
                                    <td>5001 - 10000</td>
                                    <td>10%</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>

                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row" className="odd">
                                    <td>10001 - 20000</td>
                                    <td>15%</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp mr-1"
                                          title="Edit"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>

                                        <Link
                                          to="#"
                                          className="btn btn-light shadow btn-xs sharp"
                                          title="Delete"
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <br></br>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.push("/");
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AdvanceSlabConfigruation;
