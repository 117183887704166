import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";

import CustomSelect from "../../../Custom/CustomSelect";
import PaginationButtons from "../../../Custom/Pagination";
import { Field, FormikProvider, useFormik } from "formik";
import { Accordion, Row, Col, Modal } from "react-bootstrap";
import { Form } from "reactstrap";
import {
  POST_AdminPackageHealthCheckupScheduleList,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import * as Yup from "yup";

const SchecdulePackageAndHealthCheckup = () => {
  const [orderDetailModal, setOrderDetailModal] = useState(false);

  const [recordCount, setRecordCount] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeDefault, setActiveDefault] = useState(0);
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const [orderBookingID, setOrderBookingID] = useState("");

  const [
    schedulePackageAndHealthcheckUpList,
    setSchedulePackageAndHealthcheckUpList,
  ] = useState([]);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      IsScheduled: -1,
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CompanyID, IsScheduled },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_AdminPackageHealthCheckupScheduleList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyID: CompanyID,

      IsScheduled:
        IsScheduled == 0
          ? -1
          : IsScheduled == 1
          ? 1
          : IsScheduled == 2
          ? 0
          : -1,
    });
    if (response.IsSuccess) {
      setRecordCount(response.TotalRecordCount);
      setSchedulePackageAndHealthcheckUpList(
        response.AdminPackageHealthCheckupScheduleList
      );
      setCurrentSizePerPage(sizePerPage);
      if (
        page > 1 &&
        response?.AdminPackageHealthCheckupScheduleList?.length === 0
      ) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  const isScheduledStatusOptions = [
    { label: "All", value: 0 },
    { label: "Scheduled", value: 1 },
    { label: "Pending", value: 2 },
  ];

  useEffect(() => {
    getData();
    loadCompanyDDL();
  }, []);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            motherMenu="Transaction"
            activeMenu="Schedule Package & Health Checkup"
          />
          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Schedule Package & Health Checkup List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          <Row>
                            {objLogin?.CompanyType === 1 &&
                            objLogin?.UserType === 1 ? (
                              <Col lg={4}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            <Col lg={3}>
                              <Field
                                label={"Scheduled Status"}
                                name="IsScheduled"
                                placeholder={"Select  Status"}
                                options={isScheduledStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>
          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="SchedulePackageAndHealthCheckup_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped SchedulePackageAndHealthCheckup_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Sr No.: activate to sort column ascending"
                          style={{ width: "5%" }}
                        >
                          Sr No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Order No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient: activate to sort column ascending"
                          style={{ width: "30%" }}
                        >
                          Patient
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Package/HC Name: activate to sort column ascending"
                          style={{ width: "25%" }}
                        >
                          Package/HC Name
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Package/HC: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Type: Package/HC
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>{" "}
                    <tbody>
                      {recordCount > 0 ? (
                        schedulePackageAndHealthcheckUpList?.map(
                          (element, i) => (
                            <tr
                              role="row"
                              className="odd"
                              style={{
                                backgroundColor:
                                  element.IsScheduled === 1
                                    ? "#e4f6ee"
                                    : "#f6f6f6",
                              }}
                            >
                              <td style={{ textAlign: "center" }}>
                                {element.RowNo}
                              </td>
                              <td>{element.OrderBookingNo}</td>
                              <td>
                                {element.PatientName} ({element.MobileNo})
                              </td>
                              <td>{element.HealthCheckupOrPackageName}</td>
                              <td>{element.ScheduleType}</td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    to="#"
                                    className="btn btn-light shadow btn-xs sharp mr-1"
                                    title="Order Detail"
                                    onClick={() => {
                                      setOrderBookingID(
                                        element.OrderBookingIDEncrypt
                                      );
                                      setOrderDetailModal(true);
                                    }}
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link>

                                  {objLogin?.UserType == 1 ||
                                  (objLogin?.UserType == 2 &&
                                    UserForms?.filter(
                                      (item) =>
                                        item.FormID == 32 && item.AddAction
                                    ).length > 0) ? (
                                    element.OrderBookingStatus === 1 ? (
                                      <Link
                                        to={{
                                          pathname:
                                            element.ScheduleTypeID === 5
                                              ? `/edit-package-schedule/${window.btoa(
                                                  element.HealthCheckupOrPackageIDEncrypt
                                                )}/${window.btoa(
                                                  element.OrderBookingIDEncrypt
                                                )}`
                                              : `/edit-healthcheckup-schedule/${window.btoa(
                                                  element.HealthCheckupOrPackageIDEncrypt
                                                )}/${window.btoa(
                                                  element.OrderBookingIDEncrypt
                                                )}`,
                                        }}
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Edit"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Link>
                                    ) : (
                                      <div
                                        className="btn btn-light shadow btn-xs sharp mr-1"
                                        title="Order Closed"
                                      >
                                        <i
                                          class="fa fa-lock"
                                          aria-hidden="true"
                                          style={{ color: "red" }}
                                        ></i>
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <span
              class="badge"
              style={{ backgroundColor: "#f6f6f6", color: "#3e4954" }}
            >
              Pending
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#e4f6ee", color: "#3e4954" }}
            >
              Scheduled
            </span>
          </div>
          <Modal
            className="modal fade"
            show={orderDetailModal}
            onHide={setOrderDetailModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalOrderBookingDetail
                orderBookingID={orderBookingID}
                setOrderDetailModal={setOrderDetailModal}
              ></ModalOrderBookingDetail>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default SchecdulePackageAndHealthCheckup;
