import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import {
  isAuthenticated,
  isAuthenticatedUser,
} from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
//import "antd/dist/antd.css";

import "./i18n/config";
import * as signalR from "@microsoft/signalr";
import { NOTIFICATION_LIST } from "./store/actions/NotificationAction";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 1000);
  });
});
function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);

  const getNotificationList = async () => {
    if (localStorage.getItem("userDetails")) {
      let objLogin = JSON.parse(localStorage.getItem("userDetails"));

      let payload = {
        CurrentPage: 1,
        PageSize: 10,
        Search: "",
        Sorting: "",
        LoginIDEncrypted: objLogin?.LoginIDEncrypt,
      };
      await dispatch(NOTIFICATION_LIST(payload));
    }
  };

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_SIGNAL_R_URL) // Replace with your actual SignalR endpoint
      .build();

    // Start the connection
    connection
      .start()
      .then(() => {
        console.log("SignalR connection started.");
      })
      .catch((error) => {
        console.error("Error starting SignalR connection:", error);
      });

    // Subscribe to events from the server
    connection.on("GetEnquiryNotificationDetail", (message) => {
      //console.log("Received message GetEnquiryNotificationDetail:", message);
      /* Update your React state or perform any necessary action */
      getNotificationList();
    });

    connection.on(
      "GetNotificationLogins_ForChequeAndCashDeposit",
      (message) => {
        //console.log("Received message GetNotificationLogins_ForChequeAndCashDeposit:", message);
        /* Update your React state or perform any necessary action */
        getNotificationList();
      }
    );

    // Clean up the connection on component unmount
    return () => {
      connection.stop();
    };
  }, []);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //isAuthenticated: isAuthenticated(state),
    isAuthenticated: isAuthenticatedUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
