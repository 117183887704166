import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import avatar1 from "../../../../images/avatar/4.jpg";
import PageTitle from "../../../layouts/PageTitle";
import PaginationButtons from "../../../Custom/Pagination";
import { post, get } from "../../../Helper/api_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Field, FormikProvider, useFormik } from "formik";
import CustomSelect from "../../../Custom/CustomSelect";
import {
  POST_GetStaffsDutySlotsList,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import * as Yup from "yup";
const StaffDutySlotList = () => {
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [staffDutySlotList, setStaffDutySlotList] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmit,
    values: { CompanyID },
  } = formikFilter;

  const handleOnChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(POST_GetStaffsDutySlotsList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: search,
      Sorting: "",
      CompanyIDEncrypted: CompanyID,
    });
    setIsLoading(false);
    setRecordCount(response.TotalRecordCount);
    setStaffDutySlotList(response.StaffsDutySlotsList);
    //setCurrentPage(page);
    setCurrentSizePerPage(sizePerPage);
    if (page > 1 && response?.StaffsDutySlotsList?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadCompanyDDL();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [search]);

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Staff Duty Slots" />

          {objLogin?.CompanyType === 1 && objLogin?.UserType == 1 ? (
            <Row className="mb-3">
              <Col lg={12}>
                <Accordion
                  className="accordion accordion-no-gutter accordion-header-bg"
                  defaultActiveKey={0}
                >
                  <div className="accordion__item" key={1}>
                    <Accordion.Toggle
                      eventKey={1}
                      className={`accordion__header  ${
                        activeDefault === 1 ? "" : "collapsed"
                      }`}
                      style={{
                        minWidth: "100%",
                      }}
                      onClick={() =>
                        setActiveDefault(activeDefault === 1 ? -1 : 1)
                      }
                    >
                      <span
                        className="accordion__header--text"
                        style={{ float: "left" }}
                      >
                        <i
                          class="fa fa-filter"
                          aria-hidden="true"
                          style={{ fontSize: "20px" }}
                        ></i>
                        &nbsp;
                        {"Filter Staff Duty Slots List"}
                      </span>
                      <span className="accordion__header--indicator"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={1}>
                      <div className="accordion__body--text">
                        <FormikProvider value={formikFilter}>
                          <Form onSubmit={formikFilter.handleSubmit}>
                            <Row>
                              <Col lg={4}>
                                <Field
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                              <Col lg={4}>
                                <label></label>
                                <div className="d-flex">
                                  <div className="d-lg-block">
                                    <Link
                                      className="btn btn-primary btn-xs btn-rounded"
                                      onClick={(e) => {
                                        getData();
                                      }}
                                    >
                                      Search
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </FormikProvider>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {objLogin?.UserType == 1 ||
            (objLogin?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 6 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-lg-block">
                <Link
                  to="/add-staff-duty-slot"
                  className="btn btn-primary btn-xs btn-rounded"
                >
                  + Add Staff Duty Slot
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={search}
                onChange={handleOnChangeSearch}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="staff_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff No.: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Staff No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Name: activate to sort column ascending"
                          style={{ width: "35%" }}
                        >
                          Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Designation
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile No.: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Mobile No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="EmailID: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          EmailID
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Gender: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Gender
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {recordCount > 0 ? (
                      <tbody>
                        {staffDutySlotList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.StaffNo}</td>

                            <td>{element.StaffName}</td>
                            <td>{element.Designation}</td>
                            <td>{element.PrimaryContactNumber}</td>
                            <td>{element.EmailID}</td>
                            <td>{element.Gender}</td>

                            <td style={{ textAlign: "center" }}>
                              {objLogin?.UserType == 1 ||
                              (objLogin?.UserType == 2 &&
                                UserForms?.filter(
                                  (item) => item.FormID == 6 && item.EditAction
                                ).length > 0) ? (
                                <Link
                                  to={`/edit-staff-duty-slot/${window.btoa(
                                    element.StaffIDEncrypt
                                  )}`}
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Edit"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="mt-3 ml-3">{"No Record Found"}</div>
                    )}
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default StaffDutySlotList;
