import { POST_GetCategoryListAdminByCategoryTypeWithPagination } from "../../jsx/Helper/url_helper";

// **  Initial State
const initialState = {
  CategoryList: null,
};

const CategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_GetCategoryListAdminByCategoryTypeWithPagination:
      return {
        ...state,
        CategoryList: action.CategoryList,
      };
    default:
      return state;
  }
};

export default CategoryListReducer;
