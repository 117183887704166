import axios from "axios";
import swal from "sweetalert";
import { post } from "../jsx/Helper/api_helper";
import {
  POST_GetUserRightsByLoginID,
  POST_LOGIN,
  POST_Logout,
  POST_RevokeToken,
} from "../jsx/Helper/url_helper";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login_authentication(UserName, Password) {
  let fcmToken = localStorage.getItem("FCMToken");
  const postData = {
    UserName,
    Password,
    UserType: 1,
    DeviceType: 3,
    FCMToken: fcmToken ? fcmToken : "",
  };

  return post(POST_LOGIN, postData);
}

export async function getUserRights(LoginID) {
  await post(POST_GetUserRightsByLoginID, {
    LoginIDEncrypted: LoginID,
  })
    .then((res) => {
      if (res.IsSuccess) {
        localStorage.setItem("userRights", JSON.stringify(res.UserRightsList));
      }
    })
    .catch((error) => console.log(error));
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  /*disable runout timer by Hitesh Vaghela*/
  //   setTimeout(() => {
  //     dispatch(logout(history));
  //   }, timer);
}

export function checkAutoLogin(dispatch, history) {
  //const tokenDetailsString = localStorage.getItem("userDetails");
  //let tokenDetails = "";
  // if (!tokenDetailsString) {
  //   dispatch(logout(history));
  //   return;
  // }
  // tokenDetails = JSON.parse(tokenDetailsString);
  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();
  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  // dispatch(loginConfirmedAction(tokenDetails));
  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);

  const authUserDetail = localStorage.getItem("userDetails");
  if (!authUserDetail) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(JSON.parse(authUserDetail)));
}

export function user_logout(LoginID) {
  return post(POST_Logout, {
    LoginID,
    DeviceType: 3,
  });
}

export function revoke_token(refreshToken) {
  return post(POST_RevokeToken, {
    RefreshToken: refreshToken,
  });
}
