import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import { Row, Col, Modal, Accordion } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import CustomSelect from "../../../Custom/CustomSelect";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  POST_AdminActivityMonitoringList,
  POST_AdminActivityMonitoringUpdate,
  POST_GetClientStaffListByServiceID,
  POST_AdminActivityMonitoringCancel,
  POST_AdminEquipmentInstallationDetailByID,
  POST_StaffServiceScheduleSkipAndExtendUpdate,
  POST_AdminStaffPunchInPunchOutUpdate,
  POST_AdminActivityMonitoringComplete,
  POST_AdminStaffPunchInPunchOutUpdateAfterStatusComplete,
} from "../../../Helper/url_helper";
import { post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import moment from "moment";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import ModalPatientDetails from "../../../Custom/ModalPatientDetails";
import PaginationButtons from "../../../Custom/Pagination";

const ActivityMonitoring = () => {
  const [activeDefault, setActiveDefault] = useState(0);
  const [patientDetailModel, setPatientDetailModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [orderBookingID, setOrderBookingID] = useState("");
  const [patientID, setPatientID] = useState("");
  const [staffChangeModal, setStaffChangeModal] = useState(false);
  const [activityMonitoringList, setActivityMonitoringList] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(1000);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceStaffOption, setServiceStaffOption] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [punchInPunchOutUpdateModal, setPunchInPunchOutUpdateModal] =
    useState(false);

  const [searchValue, setSearchvalue] = useState("");

  const [equipmentPhotos, setEquipmentPhotos] = useState([]);
  const [photoModal, setPhotodModal] = useState(false);

  const [staffPunchInTime, setStaffPunchInTime] = useState("");
  const [staffPunchOutTime, setStaffPunchOutTime] = useState("");
  const [clientID, setClientID] = useState("");
  const [staffID, setStaffID] = useState("");
  const [scheduledPunchInDate, setScheduledPunchInDate] = useState("");

  const [patientAddressLatitude, setPatientAddressLatitude] = useState("");
  const [patientAddressLongitude, setPatientAddressLongitude] = useState("");

  let updatedSearchValue = searchValue;

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchemaFilter = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      FilterDate: new Date(),
    },
    validateOnBlur: false,
  });
  const {
    handleValidSubmitFilter,
    values: { FilterDate },
  } = formikFilter;

  const validationSchemaStaffChange = Yup.object().shape({
    StaffIDEncrypt: Yup.string().required("Staff Name is required field"),
    ScheduleDateTime: Yup.string().required("Date is required field"),
    ScheduledPunchIn: Yup.string().required("Start Time is required field"),
  });

  const formikStaffChange = useFormik({
    validationSchema: validationSchemaStaffChange,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitStaffChange(values, formikHelpers),
    initialValues: {
      StaffIDEncrypt: "",
      ScheduledPunchIn: new Date(),
      ScheduledPunchOut: "",
      ScheduleDateTime: "",
      PKIDEncrypt: "",
      ReferenceType: "",
      ServiceIDEncrypt: "",
      ServiceHourIDEncrypt: "",
      ScheduledPunchInDate: "",
      ScheduledPunchOutDate: "",
      OrderBookingIDEncrypt: "",
      ActivityStatus: "",
      PunchInTime: "",
      ForAllRemainingDays: false,
    },
    validateOnBlur: false,
  });
  const {
    values: {
      StaffIDEncrypt,
      ScheduledPunchIn,
      ScheduledPunchOut,
      ScheduleDateTime,
      PKIDEncrypt,
      ReferenceType,
      ServiceIDEncrypt,
      ServiceHourIDEncrypt,
      ScheduledPunchInDate,
      ScheduledPunchOutDate,
      OrderBookingIDEncrypt,
      ActivityStatus,
      PunchInTime,
      ForAllRemainingDays,
    },
  } = formikStaffChange;

  useEffect(() => {
    if (!ScheduleDateTime) {
      formikStaffChange.setFieldValue("ScheduleDateTime", "");
    }
  }, [ScheduleDateTime]);

  const getData = async (page = 1, sizePerPage = 1000) => {
    setIsLoading(true);
    let response = await post(POST_AdminActivityMonitoringList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyIDEncrypted: objLogin.CompanyIDEncrypt,
      FromDate: FilterDate
        ? `${moment(FilterDate)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`
        : "",
      ToDate: FilterDate
        ? `${moment(FilterDate)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`
        : "",
    });
    if (response.IsSuccess) {
      setRecordCount(response.TotalRecordCount);
      setActivityMonitoringList(response.AdminActivityMonitoringList);
      setCurrentSizePerPage(sizePerPage);
      if (page > 1 && response?.AdminActivityMonitoringList?.length === 0) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getEquipmentPhotos = (equipmentID) => {
    setIsLoading(true);
    post(POST_AdminEquipmentInstallationDetailByID, {
      EquipmentInstallationIDEncrypted: equipmentID,
    }).then((res) => {
      if (res.IsSuccess) {
        setEquipmentPhotos(res.AdminEquipmentInstallationPhotosList);
        setPhotodModal(true);
      }
    });
    setIsLoading(false);
  };

  const getStaffList = async () => {
    const Data = {
      CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
      CurrentPage: 1,
      PageSize: 1000,
      Search: "",
      Sorting: "",
      CityName: "",
      ServiceIDEncrypt: ServiceIDEncrypt,
      FromDate: `${moment(ScheduleDateTime)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      ToDate: `${moment(ScheduleDateTime)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      ServiceHourIDEncrypt: ServiceHourIDEncrypt,
      FromTime: moment(ScheduledPunchIn).format("h:mm A"),
      Latitude: patientAddressLatitude,
      Longitude: patientAddressLongitude,
    };
    //console.log("data", Data);
    await post(POST_GetClientStaffListByServiceID, Data)
      .then((res) => {
        if (res.IsSuccess) {
          let NewOptions = [];

          res.ClientServiceWiseStaffList.map((item) =>
            NewOptions.push({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
              staffName: item.StaffName,
              staffRate: item.SpecialRate,
              startTime: item.StartTime,
              finishTime: item.FinishTime,
              isBooked: item.IsBooked,
            })
          );
          setServiceStaffOption(NewOptions);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if ((ScheduledPunchIn && ServiceIDEncrypt) || ServiceHourIDEncrypt) {
      getStaffList();
    } else {
      setServiceStaffOption([]);
      formikStaffChange.setFieldValue("StaffIDEncrypt", "");
    }
  }, [ServiceIDEncrypt, ServiceHourIDEncrypt, ScheduledPunchIn]);

  const handleValidSubmitStaffChange = (values, { setSubmitting }) => {
    setIsLoading(true);
    const data = {
      PKIDEncrypt: PKIDEncrypt,
      ReferenceType: ReferenceType,
      StaffIDEncrypt: StaffIDEncrypt,
      ScheduleDateTime: `${moment(ScheduleDateTime)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      ScheduledPunchIn:
        ScheduledPunchInDate + " " + moment(ScheduledPunchIn).format("h:mm A"),
      ScheduledPunchOut: ScheduledPunchOutDate + " " + ScheduledPunchOut,
      ForAllRemainingDays: ForAllRemainingDays,
    };

    post(POST_AdminActivityMonitoringUpdate, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          formikStaffChange.resetForm();
          setStaffChangeModal(false);
          getData(currentPage, currentSizePerPage);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const confirmStaffPunchInTime = async (PKIDEncrypt, ReferenceType) => {
    setIsLoading(true);

    let response = await post(POST_AdminStaffPunchInPunchOutUpdate, {
      OrderBookingServiceScheduleIDEncrypt: PKIDEncrypt,
      OrderBookingIDEncrypt: orderBookingID,
      StaffIDEncrypt: staffID,
      ClientIDEncrypted: clientID,
      StaffPunchIn:
        ReferenceType === 1
          ? scheduledPunchInDate + " " + moment(PunchInTime).format("h:mm A")
          : "",
      StaffPunchOut:
        ReferenceType === 2
          ? ScheduledPunchOutDate + " " + moment(PunchInTime).format("h:mm A")
          : "",
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        text:
          ReferenceType === 1
            ? "Service has been Punch In."
            : " Service has been Punch Out",
        icon: "success",
        button: "OK",
      });
      setStaffChangeModal(false);
      setIsLoading(false);
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  const cancelActivityMonitaring = async (PKIDEncrypt, ReferenceType) => {
    setIsLoading(true);
    let response = await post(POST_AdminActivityMonitoringCancel, {
      PKIDEncrypt: PKIDEncrypt,
      ReferenceType: ReferenceType,
      OrderBookingIDEncrypted: OrderBookingIDEncrypt,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        text: "Service has been Cancelled.",
        icon: "success",
        button: "OK",
      });
      setStaffChangeModal(false);
      setIsLoading(false);
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  const skipExtendActivityMonitaring = async (activityStatus) => {
    setIsLoading(true);

    let skip = await post(POST_StaffServiceScheduleSkipAndExtendUpdate, {
      OrderBookingServiceScheduleIDEncrypt: PKIDEncrypt,
      OrderBookingIDEncrypt: OrderBookingIDEncrypt,
      StaffIDEncrypt: StaffIDEncrypt,
      ActivityStatus: activityStatus,
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
    });
    if (skip.IsSuccess) {
      swal({
        text: `Service has been ${
          activityStatus === 6
            ? "Extended"
            : activityStatus === 5
            ? "Skipped"
            : ""
        }.`,
        icon: "success",
        button: "OK",
      });
      setStaffChangeModal(false);
      getData(currentPage, currentSizePerPage);
    } else {
      swal({
        text: t(skip.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
    setIsLoading(false);
  };

  const completeActivityMonitaring = async (PKIDEncrypt, ReferenceType) => {
    setIsLoading(true);
    await post(POST_AdminActivityMonitoringComplete, {
      PKIDEncrypt: PKIDEncrypt,
      ReferenceType: ReferenceType,
      OrderBookingIDEncrypted: OrderBookingIDEncrypt,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            text: "Test has been marked as complete.",
            icon: "success",
            button: "OK",
          });
          setStaffChangeModal(false);
          setIsLoading(false);
          getData(currentPage, currentSizePerPage);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaStaffPunchInPunchOutTimeUpdate = Yup.object().shape({
    StaffPunchInTimeNew: Yup.string().required(
      "Punch In Time is required field"
    ),
    StaffPunchOutTimeNew: Yup.string().required(
      "Punch Out Time is required field"
    ),
  });

  const formikStaffPunchInPunchOutTimeUpdate = useFormik({
    validationSchema: validationSchemaStaffPunchInPunchOutTimeUpdate,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitStaffPunchInPunchOutTimeUpdate(values, formikHelpers),
    initialValues: {
      OrderBookingServiceScheduleIDEncrypt: "",
      OrderBookingIDEncrypt: "",
      StaffIDEncrypt: "",
      ClientIDEncrypt: "",
      StaffPunchInTimeNew: "",
      StaffPunchOutTimeNew: "",
      StaffPunchInDate: "",
      StaffPunchOutDate: "",
    },
    validateOnBlur: false,
  });
  const {
    values: { StaffPunchInTimeNew, StaffPunchOutTimeNew },
  } = formikStaffPunchInPunchOutTimeUpdate;

  const handleValidSubmitStaffPunchInPunchOutTimeUpdate = (values) => {
    setIsLoading(true);
    let payload = {
      OrderBookingServiceScheduleIDEncrypt:
        values.OrderBookingServiceScheduleIDEncrypt,
      StaffPunchIn:
        values.StaffPunchInDate +
        " " +
        moment(StaffPunchInTimeNew).format("h:mm A"),
      StaffPunchOut:
        values.StaffPunchOutDate +
        " " +
        moment(StaffPunchOutTimeNew).format("h:mm A"),
      OrderBookingIDEncrypt: values.OrderBookingIDEncrypt,
      StaffIDEncrypt: values.StaffIDEncrypt,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
      ClientIDEncrypted: values.ClientIDEncrypt,
    };
    post(POST_AdminStaffPunchInPunchOutUpdateAfterStatusComplete, payload)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            text: "Service Punch In / Punch Out Time has been updated.",
            icon: "success",
            button: "OK",
          });
          setPunchInPunchOutUpdateModal(false);
          setIsLoading(false);
          getData(currentPage, currentSizePerPage);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            motherMenu="Transaction"
            activeMenu="Acitvity Monitoring"
          />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          <Row>
                            <Col lg={2}>
                              <Field
                                name="FilterDate"
                                placeholder={"Select Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={4}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData(1, currentSizePerPage);
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="AcitvityMonitoring_list"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped AcitvityMonitoring_list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Sr No.: activate to sort column ascending"
                        >
                          Sr No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Name: activate to sort column ascending"
                        >
                          Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Apt. Start: activate to sort column ascending"
                        >
                          Apt. Start
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Apt. End: activate to sort column ascending"
                        >
                          Apt. End
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                        >
                          Patient Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Area: activate to sort column ascending"
                        >
                          Area
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Punch Start: activate to sort column ascending"
                        >
                          Punch Start
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Punch End: activate to sort column ascending"
                        >
                          Punch End
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Category: activate to sort column ascending"
                        >
                          Category
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Activity Name: activate to sort column ascending"
                        >
                          Activity Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                        >
                          Status
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        activityMonitoringList?.map((element, i) => (
                          <tr
                            role="row"
                            className="odd"
                            key={i}
                            style={{
                              backgroundColor:
                                element.ActivityStatus === "Pending"
                                  ? "#cecdd036"
                                  : element.ActivityStatus === "Complete"
                                  ? "#c3d9b3"
                                  : element.ActivityStatus === "Extend"
                                  ? "#dfefff"
                                  : element.ActivityStatus === "Cancel"
                                  ? "#ebf6e4"
                                  : element.ActivityStatus === "On Leave"
                                  ? "#f9ddbd"
                                  : // : element.ReferenceTypeName === "Radiology" &&
                                  //   element.ActivityStatus === "Pending"
                                  // ? "#6c9b9a36"
                                  // : element.ReferenceTypeName === "Pathology" &&
                                  //   element.ActivityStatus === "Pending"
                                  // ? "#6c9b9a36"
                                  element.ActivityStatus === "Absent"
                                  ? "#ffaaaa"
                                  : element.ActivityStatus ===
                                    "Equipment Installed"
                                  ? "#c3d9b3"
                                  : element.ActivityStatus ===
                                    "Equipment Returned"
                                  ? "#c3d9b3"
                                  : element.ActivityStatus ===
                                    "Returned Pending"
                                  ? "#fde5e5"
                                  : "",
                            }}
                          >
                            <td style={{ textAlign: "center" }}>
                              {element.RowNo}
                            </td>
                            <td>
                              {element.StaffName}{" "}
                              {element.StaffMobileNo
                                ? "(" + element.StaffMobileNo + ")"
                                : ""}
                            </td>
                            <td>{element.ScheduledPunchInTime}</td>
                            <td>{element.ScheduledPunchOutTime}</td>
                            <td>
                              {element.PatientName} ({element.PatientNo})
                            </td>
                            <td>{element.AreaName}</td>
                            <td>{element.StaffPunchInTime}</td>
                            <td>{element.StaffPunchOutTime}</td>
                            <td>{element.ReferenceTypeName}</td>
                            <td>{element.ActivityName}</td>
                            <td>
                              {/* {element.ReferenceTypeName === "Radiology"
                                ? ""
                                : element.ReferenceTypeName === "Pathology"
                                ? ""
                                : element.ActivityStatus} */}
                              {element.ActivityStatus}
                            </td>
                            <td>
                              <div className="d-flex">
                                <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Patient Detail"
                                  onClick={() => {
                                    setPatientDetailModal(true);
                                    setPatientID(element.PatientIDEncrypt);
                                  }}
                                >
                                  <i
                                    className="fa fa-info"
                                    style={{ color: "#333b3d" }}
                                  ></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Order Detail"
                                  onClick={() => {
                                    setOrderDetailModal(true);
                                    setOrderBookingID(
                                      element.OrderBookingIDEncrypt
                                    );
                                  }}
                                >
                                  <i className="fa fa-eye"></i>
                                </Link>
                                {
                                  // element.ReferenceTypeName === "Radiology" ? (
                                  //   ""
                                  // ) : element.ReferenceTypeName ===
                                  //   "Pathology" ? (
                                  //   ""
                                  // ) :
                                  // element.ReferenceTypeName === "Equipment" ? (
                                  element.ReferenceType == 2 ? (
                                    ""
                                  ) : // element.ActivityStatus === "Complete" ? (
                                  element.ActivityStatus === "Complete" &&
                                    element.ReferenceType !== 1 &&
                                    element.ReferenceType !== 5 &&
                                    element.ReferenceType !== 6 ? (
                                    ""
                                  ) : element.ActivityStatus === "Cancel" ? (
                                    ""
                                  ) : element.ActivityStatus === "Extend" ? (
                                    ""
                                  ) : objLogin?.UserType == 1 ||
                                    (objLogin?.UserType == 2 &&
                                      UserForms?.filter(
                                        (item) =>
                                          item.FormID == 31 && item.EditAction
                                      ).length > 0) ? (
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Edit"
                                      onClick={() => {
                                        setIsComplete(
                                          element.ActivityStatus ===
                                            "Complete" ||
                                            element.ActivityStatus ===
                                              "Cancel" ||
                                            element.StaffPunchInTime !== ""
                                            ? true
                                            : false
                                        );
                                        if (
                                          element.ActivityStatus ===
                                            "Complete" &&
                                          (element.ReferenceType == 1 ||
                                            element.ReferenceType == 5 ||
                                            element.ReferenceType == 6)
                                        ) {
                                          formikStaffPunchInPunchOutTimeUpdate.setValues(
                                            {
                                              OrderBookingServiceScheduleIDEncrypt:
                                                element.PKIDEncrypt,
                                              OrderBookingIDEncrypt:
                                                element.OrderBookingIDEncrypt,
                                              StaffIDEncrypt:
                                                element.StaffIDEncrypt,
                                              ClientIDEncrypt:
                                                element.ClientIDEncrypt,
                                              StaffPunchInTimeNew:
                                                element.StaffPunchInTime
                                                  ? moment(
                                                      `2023-11-27 ${element.StaffPunchInTime.trim()}`
                                                    ).format(
                                                      "DD-MMM-yyyy hh:mm A"
                                                    )
                                                  : new Date(),
                                              StaffPunchOutTimeNew:
                                                element.StaffPunchOutTime
                                                  ? moment(
                                                      `2023-11-27 ${element.StaffPunchOutTime.trim()}`
                                                    ).format(
                                                      "DD-MMM-yyyy hh:mm A"
                                                    )
                                                  : new Date(),
                                              StaffPunchInDate:
                                                element.StaffPunchInDate,
                                              StaffPunchOutDate:
                                                element.StaffPunchOutDate,
                                            }
                                          );
                                          setPunchInPunchOutUpdateModal(true);
                                        } else {
                                          setStaffChangeModal(true);
                                        }

                                        setStaffID(element.StaffIDEncrypt);
                                        setClientID(element.ClientIDEncrypt);
                                        setOrderBookingID(
                                          element.OrderBookingIDEncrypt
                                        );
                                        setStaffPunchInTime(
                                          element.StaffPunchInTime
                                        );
                                        setStaffPunchOutTime(
                                          element.StaffPunchOutTime
                                        );
                                        setScheduledPunchInDate(
                                          element.ScheduledPunchInDate
                                        );
                                        setPatientAddressLatitude(
                                          element?.Latitude
                                        );
                                        setPatientAddressLongitude(
                                          element?.Longitude
                                        );
                                        formikStaffChange.setValues({
                                          StaffIDEncrypt:
                                            element.StaffIDEncrypt,
                                          ScheduledPunchIn:
                                            element.ScheduledPunchInTime
                                              ? moment(
                                                  `2023-05-02 ${element.ScheduledPunchInTime.trim()}`
                                                ).format("DD-MMM-yyyy hh:mm A")
                                              : new Date(),
                                          ScheduledPunchOut:
                                            element.ScheduledPunchOutTime,
                                          ScheduleDateTime:
                                            element.ScheduledPunchInDate
                                              ? new Date(
                                                  element.ScheduledPunchInDate
                                                )
                                              : "",
                                          PKIDEncrypt: element.PKIDEncrypt,
                                          ReferenceType: element.ReferenceType,
                                          ServiceIDEncrypt:
                                            element.ServiceIDEncrypt,
                                          ServiceHourIDEncrypt:
                                            element.ServiceHourIDEncrypt,
                                          ScheduledPunchInDate:
                                            element.ScheduledPunchInDate,
                                          ScheduledPunchOutDate:
                                            element.ScheduledPunchOutDate,
                                          OrderBookingIDEncrypt:
                                            element.OrderBookingIDEncrypt,
                                          ActivityStatus:
                                            element.ActivityStatus,
                                          StaffPunchInTime:
                                            element.StaffPunchInTime,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                  ) : (
                                    ""
                                  )
                                }
                                {/* {element.ReferenceTypeName === "Equipment" &&
                                element.ActivityStatus !== "Pending" ? (
                                  <Link
                                    to="#"
                                    className="btn btn-light shadow btn-xs sharp mr-1"
                                    title="Photos"
                                    onClick={() => {
                                      getEquipmentPhotos(
                                        element.EquipmentInstallationIDEncrypt
                                      );
                                    }}
                                  >
                                    <i className="fa fa-picture-o"></i>
                                  </Link>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}{" "}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <span
              class="badge"
              style={{ backgroundColor: "#cecdd036", color: "#3e4954" }}
            >
              Pending
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#c3d9b3", color: "#3e4954" }}
            >
              Complete / Equipment Installed / Equipment Returned
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#dfefff", color: "#3e4954" }}
            >
              Extend
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#ffaaaa", color: "#3e4954" }}
            >
              Absent
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#ebf6e4", color: "#3e4954" }}
            >
              Cancel
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#f9ddbd", color: "#3e4954" }}
            >
              On Leave
            </span>
            &nbsp;
            {/* <span
              class="badge"
              style={{ backgroundColor: "#6887d738", color: "#3e4954" }}
            >
              Equipment Installed
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#46baba38", color: "#3e4954" }}
            >
              Equipment Returned
            </span>
            &nbsp; */}
            <span
              class="badge"
              style={{ backgroundColor: "#fde5e5", color: "#3e4954" }}
            >
              Returned Pending
            </span>
            &nbsp;
          </div>
          <Modal
            className="modal fade"
            show={orderDetailModal}
            onHide={setOrderDetailModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalOrderBookingDetail
                orderBookingID={orderBookingID}
                setOrderDetailModal={setOrderDetailModal}
              ></ModalOrderBookingDetail>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={patientDetailModel}
            onHide={setPatientDetailModal}
            size="lg"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Patient Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setPatientDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalPatientDetails
                patientID={patientID}
                setPatientDetailModal={setPatientDetailModal}
              ></ModalPatientDetails>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={staffChangeModal}
            onHide={setStaffChangeModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikStaffChange}>
                <Form onSubmit={formikStaffChange.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">
                      {/* Staff Change */}
                      Activity
                    </h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setStaffChangeModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {(ActivityStatus === "Pending" ||
                      ActivityStatus === "Reschedule") &&
                    ReferenceType !== 3 &&
                    ReferenceType !== 4 ? (
                      <>
                        <Row>
                          <Col lg={6}>
                            <Label>
                              Punch Time
                              <span className="text-danger">*</span>
                            </Label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <TimePicker
                                autoOk
                                name={"PunchInTime"}
                                placeholder={"Select Punch  Time"}
                                value={PunchInTime}
                                onChange={(newValue) => {
                                  formikStaffChange.setFieldValue(
                                    `PunchInTime`,
                                    new Date(newValue)
                                  );
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Col>
                          <Col lg={6}>
                            <Label>
                              <span className="text-danger"></span>
                            </Label>
                            {staffPunchInTime === "" ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-xs btn-primary"
                                  style={{ marginTop: "30px" }}
                                  onClick={(e) => {
                                    swal({
                                      title:
                                        "Are you sure you want to punch In the service?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((confirmPunchInTime) => {
                                      if (confirmPunchInTime) {
                                        confirmStaffPunchInTime(PKIDEncrypt, 1);
                                      }
                                    });
                                  }}
                                >
                                  <i className="flaticon-delete-1"></i> Punch In
                                </button>
                              </>
                            ) : staffPunchOutTime === "" ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-xs btn-primary"
                                  style={{ marginTop: "30px" }}
                                  onClick={(e) => {
                                    swal({
                                      title:
                                        "Are you sure you want to punch Out the service?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((confirmPunchInTime) => {
                                      if (confirmPunchInTime) {
                                        confirmStaffPunchInTime(PKIDEncrypt, 2);
                                      }
                                    });
                                  }}
                                >
                                  <i className="flaticon-delete-1"></i> Punch
                                  Out
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ) : (
                      ""
                    )}
                    {(ActivityStatus === "On Leave" ||
                      staffPunchInTime === "") &&
                    ReferenceType !== 3 &&
                    ReferenceType !== 4 ? (
                      <>
                        <Row>
                          <Col>
                            {/* <button
                            type="button"
                            onClick={(e) => {
                              swal({
                                title:
                                  "Are you sure you want to skip the service?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((confirmDelete) => {
                                if (confirmDelete) {
                                  skipExtendActivityMonitaring(5);
                                }
                              });
                            }}
                            className="btn btn-xs btn-primary"
                          >
                            <i className="flaticon-delete-1"></i> Skip
                          </button>
                          &nbsp; */}
                            <button
                              type="button"
                              onClick={(e) => {
                                swal({
                                  title:
                                    "Are you sure you want to skip & extend the service?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then((confirmDelete) => {
                                  if (confirmDelete) {
                                    skipExtendActivityMonitaring(6);
                                  }
                                });
                              }}
                              className="btn btn-xs btn-primary"
                            >
                              <i className="flaticon-delete-1"></i> Skip &
                              Extend
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-xs btn-danger"
                              onClick={(e) => {
                                swal({
                                  title:
                                    "Are you sure you want to cancel the service?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then((confirmDelete) => {
                                  if (confirmDelete) {
                                    cancelActivityMonitaring(
                                      PKIDEncrypt,
                                      ReferenceType
                                    );
                                  }
                                });
                              }}
                            >
                              Cancel Service
                            </button>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ) : (
                      ""
                    )}

                    {(ActivityStatus === "Pending" ||
                      ActivityStatus === "Reschedule") &&
                    (ReferenceType == 3 || ReferenceType == 4) ? (
                      ""
                    ) : (
                      <>
                        <h4 className="ml-0">Replace Staff</h4>
                        <Row className="mt-3">
                          <Col lg={6}>
                            <Field
                              label={"Date"}
                              name="ScheduleDateTime"
                              placeholder={"Select Date"}
                              component={CustomFlatpickr}
                              disabled
                              options={{
                                // minDate: moment(new Date()).format("YYYY-MM-DD"),
                                altInput: true,
                                altFormat: "j-M-Y",
                                dateFormat: "d-m-Y",
                              }}
                              required
                            />
                          </Col>
                          <Col lg={6}>
                            <Label>
                              Start Time
                              <span className="text-danger">*</span>
                            </Label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <TimePicker
                                autoOk
                                name={"ScheduledPunchIn"}
                                placeholder={"Select Start Time"}
                                value={ScheduledPunchIn}
                                onChange={(newValue) => {
                                  formikStaffChange.setFieldValue(
                                    `ScheduledPunchIn`,
                                    new Date(newValue)
                                  );
                                }}
                                disabled={isComplete}
                              />
                            </MuiPickersUtilsProvider>
                          </Col>

                          <Col lg={12}>
                            <Field
                              label={"Staff Name"}
                              name="StaffIDEncrypt"
                              options={serviceStaffOption}
                              placeholder={"Select Staff Name"}
                              onChange={(e) => {
                                if (e) {
                                  formikStaffChange.setFieldValue(
                                    `StaffIDEncrypt`,
                                    e?.value
                                  );
                                  formikStaffChange.setFieldValue(
                                    `ScheduledPunchOut`,
                                    e?.finishTime ? e?.finishTime : ""
                                  );
                                } else {
                                  formikStaffChange.setFieldValue(
                                    `StaffIDEncrypt`,
                                    ""
                                  );
                                }
                              }}
                              isDisabled={isComplete}
                              component={CustomSelect}
                              required
                            />
                          </Col>
                          <Col lg={12}>
                            <div
                              className="custom-control custom-checkbox  checkbox-success"
                              style={{
                                zIndex: "0",
                              }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={"ForAllRemainingDays"}
                                checked={ForAllRemainingDays}
                                onChange={(e) => {
                                  formikStaffChange.setFieldValue(
                                    "ForAllRemainingDays",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={"ForAllRemainingDays"}
                              >
                                All remaining days
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    {ActivityStatus === "Pending" &&
                    (ReferenceType == 3 || ReferenceType == 4) ? (
                      <>
                        <Row>
                          <Col>
                            <button
                              type="button"
                              onClick={(e) => {
                                swal({
                                  title:
                                    "Are you sure you want to complete mark the test?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: false,
                                }).then((confirm) => {
                                  if (confirm) {
                                    completeActivityMonitaring(
                                      PKIDEncrypt,
                                      ReferenceType
                                    );
                                  }
                                });
                              }}
                              className="btn btn-xs btn-primary"
                            >
                              <i className="flaticon-delete-1"></i> Complete
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-xs btn-danger"
                              onClick={(e) => {
                                swal({
                                  title:
                                    "Are you sure you want to cancel the test?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then((confirmDelete) => {
                                  if (confirmDelete) {
                                    cancelActivityMonitaring(
                                      PKIDEncrypt,
                                      ReferenceType
                                    );
                                  }
                                });
                              }}
                            >
                              Cancel Test
                            </button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-footer">
                    {isComplete ||
                    (ActivityStatus !== "Pending" &&
                      ActivityStatus !== "Reschedule" &&
                      ActivityStatus !== "Absent" &&
                      ActivityStatus !== "On Leave") ||
                    ReferenceType == 3 ||
                    ReferenceType == 4 ? (
                      " "
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-xs btn-primary"
                        >
                          Save
                        </button>
                      </>
                    )}
                    <button
                      type="button"
                      onClick={() => setStaffChangeModal(false)}
                      className="btn btn-xs btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Close
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>

          <Modal
            className="modal fade"
            show={photoModal}
            onHide={setPhotodModal}
            size="lg"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">
                Equipment Installation Photos
              </h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setPhotodModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="pb-1">
                  <div id="lightgallery" className="row">
                    {equipmentPhotos.map((item, index) => (
                      <a
                        // href={item.PhotoUrl}
                        data-exthumbimage={item.PhotoUrl}
                        data-src={item.PhotoUrl}
                        className="col-lg-3 col-md-6 mb-4"
                        key={index}
                      >
                        <img
                          src={item.PhotoUrl}
                          alt=""
                          style={{ width: "100%" }}
                          onClick={() => {
                            window.open(item.PhotoUrl);
                          }}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={punchInPunchOutUpdateModal}
            onHide={setPunchInPunchOutUpdateModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikStaffPunchInPunchOutTimeUpdate}>
                <Form
                  onSubmit={formikStaffPunchInPunchOutTimeUpdate.handleSubmit}
                >
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">
                      Punch In / Punch Out Time Update
                    </h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setPunchInPunchOutUpdateModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col lg={6}>
                        <Label>
                          Punch In Time
                          <span className="text-danger">*</span>
                        </Label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <TimePicker
                            autoOk
                            name={"StaffPunchInTimeNew"}
                            placeholder={"Select Punch In Time"}
                            value={StaffPunchInTimeNew}
                            onChange={(newValue) => {
                              formikStaffPunchInPunchOutTimeUpdate.setFieldValue(
                                `StaffPunchInTimeNew`,
                                new Date(newValue)
                              );
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Col>
                      <Col lg={6}>
                        <Label>
                          Punch Out Time
                          <span className="text-danger">*</span>
                        </Label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <TimePicker
                            autoOk
                            name={"StaffPunchOutTimeNew"}
                            placeholder={"Select Punch Out Time"}
                            value={StaffPunchOutTimeNew}
                            onChange={(newValue) => {
                              formikStaffPunchInPunchOutTimeUpdate.setFieldValue(
                                `StaffPunchOutTimeNew`,
                                new Date(newValue)
                              );
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Col>
                    </Row>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-xs btn-primary">
                      Update
                    </button>

                    <button
                      type="button"
                      onClick={() => setPunchInPunchOutUpdateModal(false)}
                      className="btn btn-xs btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Close
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default ActivityMonitoring;
