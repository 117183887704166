import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import PaginationButtons from "../../../Custom/Pagination";
import { post } from "../../../Helper/api_helper";
import * as url from "../../../Helper/url_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
const PatientList = () => {
  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [companyList, setCompanyList] = useState([]);

  const [companyID, setCompanyID] = useState("");
  const [companyStatus, setCompanyStatus] = useState(true);
  const [active, setActive] = useState(false);
  const [ConfirmMode, setConfirmMode] = useState("");

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    getData();
  }, [search]);

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    let response = await post(url.POST_GetCompanyList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: search,
      Sorting: "",
      LoginID: objLogin.LoginIDEncrypt,
      CityID: 0,
      StateID: 0,
    });
    setIsLoading(false);
    setRecordCount(response.TotalRecordCount);
    setCompanyList(response.CompanyList);
    //setCurrentPage(page);
    setCurrentSizePerPage(sizePerPage);
    if (page > 1 && response?.CompanyList?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  const delete_Company = (cID) => {
    setConfirmMode("Delete");
    setCompanyID(cID);
    swal({
      title: "Are you sure you want to delete the company?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setActive(true);
      }
    });
  };

  const delete_CompanyConfirm = async () => {
    setIsLoading(true);
    let response = await post(url.POST_CompanyDelete, {
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
      EncryptedCompanyID: companyID,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_Company = async (cID, cStatus) => {
    setConfirmMode("ActiveInactive");
    setCompanyID(cID);
    setCompanyStatus(cStatus);
    if (cStatus) {
      swal({
        title: "Are you sure you want to inactive the company?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setActive(true);
        }
      });
    } else {
      swal({
        title: "Are you sure you want to active the company?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setActive(true);
        }
      });
    }
  };

  useEffect(() => {
    if (active) {
      onConfirmClick();
      setActive(false);
    }
  }, [ConfirmMode, active]);

  const onConfirmClick = () => {
    if (ConfirmMode === "Delete") {
      delete_CompanyConfirm();
    } else if (ConfirmMode === "ActiveInactive") {
      ActiveInactive_CompanyConfirm();
    }
  };

  const ActiveInactive_CompanyConfirm = async () => {
    setIsLoading(true);
    let response = await post(url.POST_CompanySetActiveInActive, {
      IsActive: !companyStatus,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
      EncryptedCompanyID: companyID,
    });
    if (response.IsSuccess) {
      swal({
        title: companyStatus ? "Inactivated" : "Activated",
        text: companyStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage, currentSizePerPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const handleOnChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="Companies" />

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="mr-auto d-lg-block">
              <Link
                to="/add-company"
                className="btn btn-primary btn-xs btn-rounded"
              >
                + Add Company
              </Link>
            </div>
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={search}
                onChange={handleOnChangeSearch}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="companies_list"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Company Name: activate to sort column ascending"
                          style={{ width: "30%" }}
                        >
                          Company Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="GST No.: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          GST No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="City: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          City
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="State: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          State
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {recordCount > 0 ? (
                      <tbody>
                        {companyList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td key={i}>{element.CompanyName}</td>
                            <td>{element.GSTNo}</td>
                            <td>{element.CityName}</td>
                            <td>{element.StateName}</td>
                            <td>
                              <div className="d-flex">
                                <Link
                                  to={`/edit-company/${window.btoa(
                                    element.CompanyIDEncrypt
                                  )}`}
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Edit"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                                <Link
                                  // to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                  title={
                                    element.IsActive ? "Active" : "Inactive"
                                  }
                                  onClick={() => {
                                    ActiveInactive_Company(
                                      element.CompanyIDEncrypt,
                                      element.IsActive
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-circle mt-1"
                                    style={{
                                      color: element.IsActive ? "green" : "red",
                                    }}
                                  ></i>
                                </Link>
                                <Link
                                  // to="#"
                                  className="btn btn-light shadow btn-xs sharp"
                                  title="Delete"
                                  onClick={() => {
                                    delete_Company(element.CompanyIDEncrypt);
                                  }}
                                >
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "red" }}
                                  ></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="mt-3 ml-3">{"No Record Found"}</div>
                    )}
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default PatientList;
