import { POST_GetCategoryTypes } from "../../jsx/Helper/url_helper";

// **  Initial State
const initialState = {
  CategoryTypeList: null,
};

const CategoryTypeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_GetCategoryTypes:
      return {
        ...state,
        CategoryTypeList: action.CategoryTypeList,
      };
    default:
      return state;
  }
};

export default CategoryTypeListReducer;
