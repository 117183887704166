import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Label, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { useHistory } from "react-router-dom";
import {} from "../../../Helper/url_helper";
import { Spin } from "antd";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { Modal } from "react-bootstrap";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { get, post } from "../../../Helper/api_helper";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_AdminGetYearDDLList,
  POST_AdminStaffPayoutInsert,
} from "../../../Helper/url_helper";

const StaffPayout = () => {
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);
  const [optionYearDDLList, setOptionYearDDLList] = useState([]);

  const validationSchema = Yup.object().shape({
    Month: Yup.string().required("Month is required field."),
    Year: Yup.string().required("Year is required field."),
    SalaryType: Yup.string().required("Salary Type is required field."),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      Month: "",
      Year: "",
      SalaryType: "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {},
  } = formik;

  useEffect(() => {
    getYearDDLList();
  }, []);

  const getYearDDLList = () => {
    setIsLoading(true);
    post(POST_AdminGetYearDDLList, {
      CompanyIDEncrypt: objLogin?.CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setOptionYearDDLList(
            res.AdminGetYearDDLList.map((item) => ({
              label: item.YearName,
              value: item.YearName,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const MonthOption = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const SalaryTypeOption = [
    { label: "Hourly", value: 1 },
    { label: "Per Visit", value: 2 },
  ];

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      CompanyIDEncrypt: objLogin?.CompanyIDEncrypt,
      StaffPayoutMonth: values.Month,
      StaffPayoutYear: values.Year,
      SalaryType: values.SalaryType,
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
    };
    post(POST_AdminStaffPayoutInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle activeMenu="Staff Payout" motherMenu="Transaction" />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Month"}
                            name="Month"
                            options={MonthOption}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Year"}
                            name="Year"
                            options={optionYearDDLList}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Salary Type"}
                            name="SalaryType"
                            options={SalaryTypeOption}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.push("/");
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default StaffPayout;
