import {
  formatError,
  getUserRights,
  login,
  login_authentication,
  revoke_token,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  user_logout,
} from "../../services/AuthService";
import swal from "sweetalert";
import { t } from "i18next";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  const AuthUser = localStorage.getItem("userDetails");
  if (AuthUser) {
    revoke_token(AuthUser?.RefreshToken);
    user_logout(AuthUser?.LoginIDEncrypt);
  }
  localStorage.removeItem("userDetails");
  localStorage.removeItem("userRights");
  //localStorage.removeItem("FCMToken");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(
        //     dispatch,
        //     response.data.expiresIn * 1000,
        //     history,
        // );

        dispatch(loginConfirmedAction(response.data));
        history.push("/dashboard");
        //window.location.reload();

        //history.pushState('/index');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function login_authenticationAction(
  UserName,
  Password,
  history,
  setLoading
) {
  return (dispatch) => {
    login_authentication(UserName, Password)
      .then((response) => {
        if (response.IsSuccess) {
          localStorage.setItem("userDetails", JSON.stringify(response));
          if (response.UserType == 2) {
            Promise.all(getUserRights(response.LoginIDEncrypt));
          }
          dispatch(loginConfirmedAction(response));
          // setTimeout(() => {
          //   history.push("/dashboard");
          // }, 500);
          //window.location.reload();
          setTimeout(() => {
            window.location = "/dashboard";
          }, 500);
        } else {
          swal("Oops", t(response.MessageCode), "error");
          setLoading(false);
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
