import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import swal from "sweetalert";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Alert, Col, Form, InputGroup, Label, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { Modal } from "react-bootstrap";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { get, post } from "../../../Helper/api_helper";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import { Spin } from "antd";
import { t } from "i18next";
import moment from "moment";

import {
  POST_GetModeOfPaymentDDL,
  POST_AdminOrderBookingAdvanceReturnSearchByOrderBookingNo,
  POST_AdminOrderBookingAdvanceReturnInsert,
  POST_AdminOrderBookingAdvanceReturnDetailByID,
} from "../../../Helper/url_helper";

const AddOrderBookingAdvanceReturn = () => {
  const history = useHistory();
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [modeOfPaymentOptions, setModeOfPaymentOptions] = useState([]);
  const [orderBookingID, setOrderBookingID] = useState("");

  const validationSchema = Yup.object().shape({
    OrderBookingNo: Yup.string()
      .nullable()
      .required("Order Booking No is required field"),
    AdvanceAmountReturn: Yup.string()
      .nullable()
      .required("Advance Return Amount is required field"),
    AdvanceReturnDate: Yup.string()
      .nullable()
      .required("Advance Return Date is required field"),
    ModeofPayment: Yup.string()
      .nullable()
      .required("Mode of Payment is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      OrderBookingNo: "",
      AdvanceAmountReturn: "",
      AdvanceReturnDate: "",
      ModeofPayment: "",
      AdvanceReturnRemark: "",
      CompanyID: AuthUser?.CompanyIDEncrypt,
      AddOrderBookingID: "",
      OrderBookingNo: "",
      PatientName: "",
      PatientNo: "",
      ClientName: "",
      OrderBookingDate: "",
      TotalAdvanceAmount: "",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: {
      CompanyID,
      AddOrderBookingID,
      OrderBookingNo,
      AdvanceAmountReturn,
      AdvanceReturnDate,
      ModeofPayment,
      AdvanceReturnRemark,
      PatientName,
      PatientNo,
      ClientName,
      OrderBookingDate,
      TotalAdvanceAmount,
    },
  } = formik;

  useEffect(() => {
    loadModeOfPaymentOptionsDDL();
  }, []);

  const loadModeOfPaymentOptionsDDL = async () => {
    await post(POST_GetModeOfPaymentDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setModeOfPaymentOptions(
            res.ModeOfPaymentList?.filter((x) => x.IsNEFT || x.IsUPI)?.map(
              (item) => ({
                label: item.ModeOfPayment,
                value: item.ModeOfPaymentID,
              })
            )
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getOrderDetailsByOrderBookingNo = async () => {
    if (OrderBookingNo) {
      //alert(OrderBookingNo);
      //alert(CompanyID);
      setIsLoading(true);
      await post(POST_AdminOrderBookingAdvanceReturnSearchByOrderBookingNo, {
        OrderBookingNo: OrderBookingNo,
        CompanyID: CompanyID,
      }).then((res) => {
        if (res.IsSuccess) {
          // alert(AddOrderBookingID);
          //alert(res.OrderBookingIDEncrypt);
          setValues({
            OrderBookingNo: OrderBookingNo,
            AddOrderBookingID: res.OrderBookingIDEncrypt,
            PatientName: res.PatientName,
            PatientNo: res.PatientNo,
            ClientName: res.ClientName,
            OrderBookingDate: res.OrderBookingDate,
            TotalAdvanceAmount: res.TotalAdvanceAmount,
            CompanyID: CompanyID,
            AdvanceAmountReturn: res.TotalAdvanceReturnAmount,
            AdvanceReturnDate: "",
            ModeofPayment: "",
            AdvanceReturnRemark: "",
          });
        } else {
          swal({
            // title: "warning",
            //text: "Order Booking No. not found....!",
            text: t(res.MessageCode),
            icon: "warning",
            button: "OK",
          });
          formik.resetForm();
          formik.setFieldValue("OrderBookingNo", OrderBookingNo);
          formik.setFieldValue("CompanyID", CompanyID);
        }
      });
      setIsLoading(false);
    } else {
      // OrderBookingNo: Yup.string()
      // .nullable()
      // .required("Order Booking No is required field"),
      swal({
        //title: "warning",
        text: "Order Booking No. is required field",
        icon: "warning",
        button: "OK",
      });
      return;
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (AddOrderBookingID === "") {
      swal({
        // title: "warning",
        text: "Order Booking No. is Invalid...!",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setIsLoading(true);

    const data = {
      AdvanceReturnAmount: values.AdvanceAmountReturn.toString(),
      AdvancerReturnDate: `${moment(values.AdvanceReturnDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      AdvanceReturnRemarks: values.AdvanceReturnRemark,
      OrderBookingIDEncrypt: values.AddOrderBookingID,
      ModeOfPaymentID: values.ModeofPayment,
      CreatedByEncrypt: AuthUser.LoginIDEncrypt,
    };

    post(POST_AdminOrderBookingAdvanceReturnInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          history.push("/order-booking-advance-return");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            activeMenu="Add Advance Return"
            motherMenu={
              <Link to="/order-booking-advance-return">Advance Return</Link>
            }
          />

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col lg={4}>
                            <Field
                              label={"Order Booking No."}
                              name="OrderBookingNo"
                              placeholder={"Search here...."}
                              component={CustomInput}
                              maxLength={15}
                              required
                            />
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>&nbsp;</Label>
                              <InputGroup>
                                <button
                                  type="button"
                                  className="btn mt-1 mr-2 btn-xs btn-primary"
                                  onClick={(e) => {
                                    getOrderDetailsByOrderBookingNo();
                                  }}
                                >
                                  Search
                                </button>
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>

                        {AddOrderBookingID ? (
                          <Row>
                            <Col lg={3}>
                              <b>Patient No.</b> : &nbsp;&nbsp; {PatientNo}
                            </Col>
                            <Col lg={6}>
                              <b>Patient Name</b> : &nbsp;&nbsp; {PatientName}
                            </Col>
                            <Col lg={3} style={{ textAlign: "right" }}>
                              <button
                                className="btn mr-2 btn-xs btn-light"
                                onClick={() => {
                                  setOrderBookingID(AddOrderBookingID);
                                  setOrderDetailModal(true);
                                }}
                                // onClick={() => {
                                //   setOrderDetailModal(true);
                                // }}
                              >
                                View Order Detail
                              </button>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        <hr></hr>
                        <Row>
                          <Col lg={3}>
                            <Field
                              label={"Advance Return Amount"}
                              name="AdvanceAmountReturn"
                              placeholder={"Advance Return Amount"}
                              component={CustomInput}
                              required
                            />
                          </Col>

                          <Col lg={3}>
                            <Field
                              label={"Advance Return Date"}
                              name="AdvanceReturnDate"
                              placeholder={"Select Return Date"}
                              component={CustomFlatpickr}
                              options={{
                                maxDate: moment(new Date()).format(
                                  "YYYY-MM-DD"
                                ),
                                altInput: true,
                                altFormat: "j-M-Y",
                                dateFormat: "d-m-Y",
                              }}
                              required
                            />
                          </Col>

                          <Col lg={4}>
                            <Field
                              label={"Mode of Payment"}
                              name="ModeofPayment"
                              placeholder={"Select Mode of Payment"}
                              component={CustomSelect}
                              options={modeOfPaymentOptions}
                              required
                              isClearable={false}
                            />
                          </Col>
                          <Col lg={10}>
                            <Field
                              label={"Advance Return Remark"}
                              name="AdvanceReturnRemark"
                              placeholder={"Enter Advance Return Remark"}
                              component={CustomInput}
                              maxLength={200}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <button type="submit" className="btn mr-2 btn-primary">
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={(e) => {
                            history.goBack();
                          }}
                        >
                          Cancel
                        </button>
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            show={orderDetailModal}
            onHide={setOrderDetailModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>

            <div className="modal-body">
              <ModalOrderBookingDetail
                orderBookingID={orderBookingID}
                setOrderDetailModal={setOrderDetailModal}
              ></ModalOrderBookingDetail>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default AddOrderBookingAdvanceReturn;
