import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik, FieldArray } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Row, Col, Card, Tab, Nav, Button } from "react-bootstrap";
import {
  POST_GetStaffsDutySlotsDetailByID,
  POST_StaffsDutySlotsCreate,
  GET_CompanyDropDownList,
  POST_AdminStaffsListDDLByCompanyID,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";

const AddStaffDutySlot = () => {
  const { StaffIDEncryptParam } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchema = Yup.object().shape({
    StaffID: Yup.string().required("Staff Name is required field"),
    CompanyID: Yup.string().required("Company is required field"),
  });
  const initListOfStaffDutySlot = {
    StaffDutySlotIDEncrypt: "",
    StaffIDEncrypt: "",
    Weekday: "",
    DutySlotStartTime: new Date(),
    DutySlotEndTime: new Date(),
  };

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
      StaffID: StaffIDEncryptParam ? window.atob(StaffIDEncryptParam) : "",
      ListOfStaffDutySlots: [initListOfStaffDutySlot],
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: { ListOfStaffDutySlots, CompanyID, StaffID },
  } = formik;

  useEffect(() => {
    loadCompanyDDL();
    if (StaffIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetStaffsDutySlotsDetailByID, {
        StaffIDEncrypted: window.atob(StaffIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            StaffIDEncrypt: res.StaffIDEncrypt,
            CompanyID: res.CompanyIDEncrypt,
            StaffID: res.StaffIDEncrypt,
            ListOfStaffDutySlots: res.StaffsDutySlotsDetailList.map((x) => {
              return {
                ...x,
                // DutySlotStartTime: moment(
                //   `2023-03-21 ${x.DutySlotStartTime.trim()}`
                // ).format("DD-MMM-yyyy hh:mm A"),
                // DutySlotEndTime: moment(
                //   `2023-03-21 ${x.DutySlotEndTime.trim()}`
                // ).format("DD-MMM-yyyy hh:mm A"),
                DutySlotStartTime: moment(
                  `2023-01-01 ${x.DutySlotStartTime.trim()}`,
                  "YYYY-MM-DD hh:mm A"
                ).format("YYYY-MM-DD HH:mm"),
                DutySlotEndTime: moment(
                  `2023-01-01 ${x.DutySlotEndTime.trim()}`,
                  "YYYY-MM-DD hh:mm A"
                ).format("YYYY-MM-DD HH:mm"),
              };
            }),
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (CompanyID) {
      setFieldValue("StaffID", "");
      getStaffList(CompanyID);
    }
    // else {
    //   formik.setFieldValue("CompanyID", "");
    //   setStaffOptions([]);
    // }
  }, [CompanyID]);

  useEffect(() => {
    if (StaffID) {
      setIsLoading(true);
      post(POST_GetStaffsDutySlotsDetailByID, {
        StaffIDEncrypted: StaffID,
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            StaffIDEncrypt: StaffID,
            CompanyID: CompanyID,
            StaffID: StaffID,
            ListOfStaffDutySlots:
              res.StaffsDutySlotsDetailList?.length > 0
                ? res.StaffsDutySlotsDetailList.map((x) => {
                    return {
                      ...x,
                      // DutySlotStartTime: moment(
                      //   `2023-03-21 ${x.DutySlotStartTime.trim()}`
                      // ).format("DD-MMM-yyyy hh:mm A"),
                      // DutySlotEndTime: moment(
                      //   `2023-03-21 ${x.DutySlotEndTime.trim()}`
                      // ).format("DD-MMM-yyyy hh:mm A"),
                      DutySlotStartTime: moment(
                        `2023-01-01 ${x.DutySlotStartTime.trim()}`,
                        "YYYY-MM-DD hh:mm A"
                      ).format("YYYY-MM-DD HH:mm"),
                      DutySlotEndTime: moment(
                        `2023-01-01 ${x.DutySlotEndTime.trim()}`,
                        "YYYY-MM-DD hh:mm A"
                      ).format("YYYY-MM-DD HH:mm"),
                    };
                  })
                : [initListOfStaffDutySlot],
          });
        }
      });
      setIsLoading(false);
    } else {
      setFieldValue("ListOfStaffDutySlots", [initListOfStaffDutySlot]);
    }
  }, [StaffID]);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getStaffList = async (companyID) => {
    await post(POST_AdminStaffsListDDLByCompanyID, {
      CompanyIDEncrypt: companyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setStaffOptions(
            res.StaffsList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const optionWeekDays = [
    { label: "Monday", value: 2 },
    { label: "Tuesday", value: 3 },
    { label: "Wednesday", value: 4 },
    { label: "Thursday", value: 5 },
    { label: "Friday", value: 6 },
    { label: "Saturday", value: 7 },
    { label: "Sunday", value: 1 },
  ];

  const optionHours = [
    { label: "12 Hours", value: 1 },
    { label: "24 Hours", value: 2 },
  ];

  const handleValidSubmit = (values, { setSubmitting }) => {
    var IsValid = true;

    for (var i = 0; i < values.ListOfStaffDutySlots.length; i++) {
      for (var j = 0; j < values.ListOfStaffDutySlots.length; j++) {
        if (
          i !== j &&
          values.ListOfStaffDutySlots[i].Weekday ===
            values.ListOfStaffDutySlots[j].Weekday
        ) {
          if (
            (moment(values.ListOfStaffDutySlots[i].DutySlotStartTime).format(
              "HH"
            ) >=
              moment(values.ListOfStaffDutySlots[j].DutySlotStartTime).format(
                "HH"
              ) &&
              moment(values.ListOfStaffDutySlots[i].DutySlotStartTime).format(
                "HH"
              ) <=
                moment(values.ListOfStaffDutySlots[j].DutySlotEndTime).format(
                  "HH"
                )) ||
            (moment(values.ListOfStaffDutySlots[i].DutySlotEndTime).format(
              "HH"
            ) >=
              moment(values.ListOfStaffDutySlots[j].DutySlotStartTime).format(
                "HH"
              ) &&
              moment(values.ListOfStaffDutySlots[i].DutySlotEndTime).format(
                "HH"
              ) <=
                moment(values.ListOfStaffDutySlots[j].DutySlotEndTime).format(
                  "HH"
                )) ||
            (moment(values.ListOfStaffDutySlots[j].DutySlotStartTime).format(
              "HH"
            ) >=
              moment(values.ListOfStaffDutySlots[i].DutySlotStartTime).format(
                "HH"
              ) &&
              moment(values.ListOfStaffDutySlots[j].DutySlotStartTime).format(
                "HH"
              ) <=
                moment(values.ListOfStaffDutySlots[i].DutySlotEndTime).format(
                  "HH"
                )) ||
            (moment(values.ListOfStaffDutySlots[j].DutySlotEndTime).format(
              "HH"
            ) >=
              moment(values.ListOfStaffDutySlots[i].DutySlotStartTime).format(
                "HH"
              ) &&
              moment(values.ListOfStaffDutySlots[j].DutySlotEndTime).format(
                "HH"
              ) <=
                moment(values.ListOfStaffDutySlots[i].DutySlotEndTime).format(
                  "HH"
                ))
          ) {
            swal({
              text: "Duplicate duty slots are not allowed.",
              icon: "warning",
              button: "OK",
            });
            IsValid = false;
            return;
          } else if (
            moment(values.ListOfStaffDutySlots[i].DutySlotStartTime).format(
              "HH"
            ) ===
            moment(values.ListOfStaffDutySlots[i].DutySlotEndTime).format("HH")
          ) {
            swal({
              text: "Duplicate duty slots are not allowed.",
              icon: "warning",
              button: "OK",
            });
            IsValid = false;
            return;
          }
        }
      }
    }
    if (!IsValid) {
      return;
    }
    if (
      values.ListOfStaffDutySlots.filter(
        (x) => x.Weekday?.toString().trim() == ""
      ).length > 0
    ) {
      if (values.ListOfStaffDutySlots?.length == 1) {
        swal({
          //title: "warning",
          text: "Fill the detail of WeekDay.",
          icon: "warning",
          button: "OK",
        });
        return;
      } else {
        swal({
          //title: "warning",
          text: "Fill the details of WeekDays or remove it.",
          icon: "warning",
          button: "OK",
        });
        return;
      }
    }

    setIsLoading(true);

    const data = {
      StaffIDEncrypt: values.StaffID,
      ListOfStaffsDutySlotsDetail: values.ListOfStaffDutySlots.map((x) => {
        return {
          ...x,
          DutySlotStartTime: moment(x.DutySlotStartTime).format("h:00 A"),
          DutySlotEndTime: moment(x.DutySlotEndTime).format("h:00 A"),
        };
      }),
    };
    post(POST_StaffsDutySlotsCreate, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/staff-duty-slots");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const copyMondaySlot = () => {
    if (ListOfStaffDutySlots?.filter((x) => x.Weekday === 2).length > 0) {
      swal({
        title: "Are you sure?",
        text: "Copying Monday slots for all days will override other days slots",
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((confirmResult) => {
        if (confirmResult) {
          let newListOfStaffDutySlots = [];

          ListOfStaffDutySlots?.filter((x) => x.Weekday === 2)?.map((x) =>
            optionWeekDays?.map((element) => {
              newListOfStaffDutySlots.push({
                StaffDutySlotIDEncrypt: "",
                StaffIDEncrypt: "",
                Weekday: element.value,
                DutySlotStartTime: x.DutySlotStartTime,
                DutySlotEndTime: x.DutySlotEndTime,
              });
            })
          );
          setFieldValue("ListOfStaffDutySlots", newListOfStaffDutySlots);
        }
      });
    } else {
      swal({
        icon: "warning",
        title: "Enter Monday Slot",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            StaffIDEncryptParam
              ? "Edit Staff Duty Slots"
              : "Add Staff Duty Slots"
          }
          motherMenu={<Link to="/staff-duty-slots">Staff Duty Slots</Link>}
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Company"}
                            name="CompanyID"
                            placeholder={"Select Company"}
                            options={companyOptions}
                            component={CustomSelect}
                            required
                            isClearable={false}
                            isDisabled={StaffIDEncryptParam ? true : false}
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Staff Name"}
                            name="StaffID"
                            options={staffOptions}
                            placeholder={"Select Staff Name"}
                            component={CustomSelect}
                            isDisabled={StaffIDEncryptParam ? true : false}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <FieldArray
                        name="ListOfStaffDutySlots"
                        render={(arrayHelpers) => {
                          return (
                            <Fragment>
                              {ListOfStaffDutySlots?.map(
                                (StaffDocumentsList, index) => (
                                  <div data-repeater-list="group-a" key={index}>
                                    <Row>
                                      <Col lg={3}>
                                        <Field
                                          label={"Week Days"}
                                          name={`ListOfStaffDutySlots.[${index}].Weekday`}
                                          options={optionWeekDays}
                                          placeholder={"Select Week Days"}
                                          component={CustomSelect}
                                          required
                                        />
                                      </Col>
                                      <Col lg={2}>
                                        <Label>
                                          Start Time
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <TimePicker
                                            autoOk
                                            name={`ListOfStaffDutySlots.[${index}].DutySlotStartTime`}
                                            placeholder={"Select Start Time"}
                                            value={
                                              StaffDocumentsList.DutySlotStartTime
                                            }
                                            ampmInClock={true}
                                            onChange={(newValue) => {
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotStartTime`,
                                                new Date(newValue)
                                              );
                                            }}
                                            minutesStep={0}
                                            format={"h:00 a"}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Col>
                                      <Col lg={2}>
                                        <Label>
                                          End Time
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <TimePicker
                                            name={`ListOfStaffDutySlots.[${index}].DutySlotEndTime`}
                                            autoOk
                                            placeholder={"Select End Time"}
                                            ampm={true}
                                            value={
                                              StaffDocumentsList.DutySlotEndTime
                                            }
                                            onChange={(newValue) => {
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotEndTime`,
                                                new Date(newValue)
                                              );
                                            }}
                                            minutesStep={0}
                                            format={"h:00 a"}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Col>
                                      <Col lg={3}>
                                        <Field
                                          label={"Hours"}
                                          name={`ListOfStaffDutySlots.[${index}].Hours`}
                                          options={optionHours}
                                          placeholder={"Select Hours"}
                                          component={CustomSelect}
                                          onChange={(event) => {
                                            setFieldValue(
                                              `ListOfStaffDutySlots.[${index}].Hours`,
                                              event == null ? 0 : event?.value
                                            );
                                            if (event?.value === 1) {
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotStartTime`,
                                                "2023-03-21T08:00"
                                              );
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotEndTime`,
                                                "2023-03-21T20:00"
                                              );
                                            } else if (event?.value === 2) {
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotStartTime`,
                                                "2023-03-21T08:00"
                                              );
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotEndTime`,
                                                "2023-03-21T08:00"
                                              );
                                            } else {
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotStartTime`,
                                                new Date()
                                              );
                                              setFieldValue(
                                                `ListOfStaffDutySlots.[${index}].DutySlotEndTime`,
                                                new Date()
                                              );
                                            }
                                          }}
                                        />
                                      </Col>
                                      {StaffIDEncryptParam ? (
                                        <Col
                                          lg={2}
                                          className="align-self-center mb-3"
                                        >
                                          <div className="d-grid">
                                            <label className="form-label">
                                              <span
                                                style={{
                                                  color: "white",
                                                }}
                                              >
                                                .
                                              </span>
                                            </label>
                                            <input
                                              data-repeater-delete
                                              type="button"
                                              className="btn btn-primary btn-xs mt-3"
                                              value="Delete"
                                              onClick={() =>
                                                swal({
                                                  title:
                                                    "Are you sure you want to delete the duty slot?",
                                                  icon: "warning",
                                                  buttons: true,
                                                  dangerMode: true,
                                                }).then((confirmResult) => {
                                                  if (confirmResult) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                })
                                              }
                                            />
                                          </div>
                                        </Col>
                                      ) : (
                                        index !== 0 && (
                                          <Col
                                            lg={2}
                                            className="align-self-center mb-3"
                                          >
                                            <div className="d-grid">
                                              <label className="form-label">
                                                <span
                                                  style={{
                                                    color: "white",
                                                  }}
                                                >
                                                  .
                                                </span>
                                              </label>
                                              <input
                                                data-repeater-delete
                                                type="button"
                                                className="btn btn-primary btn-xs mt-3"
                                                value="Delete"
                                                onClick={() =>
                                                  swal({
                                                    title:
                                                      "Are you sure you want to delete the duty slot?",
                                                    icon: "warning",
                                                    buttons: true,
                                                    dangerMode: true,
                                                  }).then((confirmResult) => {
                                                    if (confirmResult) {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }
                                                  })
                                                }
                                              />
                                            </div>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </div>
                                )
                              )}
                              <Row>
                                <Col lg={12} style={{ textAlign: "right" }}>
                                  <Button
                                    color="success"
                                    className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                    onClick={() => copyMondaySlot()}
                                  >
                                    Copy Monday Slots for all week days
                                  </Button>{" "}
                                  &nbsp;
                                  <Button
                                    color="success"
                                    className="btn btn-success mt-3 mb-3 mt-lg-0 btn-xs"
                                    onClick={() =>
                                      arrayHelpers.push(initListOfStaffDutySlot)
                                    }
                                  >
                                    <i className="fa fa-plus"></i> &nbsp; Add
                                  </Button>
                                </Col>
                              </Row>
                            </Fragment>
                          );
                        }}
                      />
                      <Row>
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn mr-2 btn-primary btn-sm"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light"
                            onClick={(e) => {
                              history.goBack();
                            }}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddStaffDutySlot;
