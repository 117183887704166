//Login
export const POST_LOGIN = "/api/Authentication/Authentication";
export const POST_RefreshToken = "/api/Authentication/RefreshToken";
export const POST_RevokeToken = "/api/Authentication/RevokeToken";
export const POST_Logout = "/api/Authentication/UserLogout";

//Forgot Password
export const POST_ForgotPassword = "/api/Authentication/ForgotPassword";
export const POST_GenerateForgotPasswordOTP =
  "/api/Authentication/GenerateForgotPasswordOTP";
export const POST_VerifyOTP = "/api/Authentication/VerifyOTP";

//Change Password
export const POST_ChangePassword = "/api/Authentication/ChangePassword";

//Category
export const POST_GetCategoryListAdminByCategoryTypeWithPagination =
  "/api/Category/GetCategoryListAdminByCategoryTypeWithPagination";
export const POST_GetCategoryTypes = "/api/Category/GetCategoryTypes";
export const POST_CategoryStatusUpdate = "/api/Category/CategoryStatusUpdate";
export const POST_CategoryDelete = "/api/Category/CategoryDelete";
export const POST_CategoryInsert = "/api/Category/CategoryInsert";
export const POST_CategoryUpdate = "/api/Category/CategoryUpdate";
export const POST_GetCategoryByID = "/api/Category/GetCategoryByID";
export const POST_GetCategoryListByCategoryType =
  "/api/Category/GetCategoryListByCategoryType";

//Common
export const POST_GetStateList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=1&ParentCategoryID=0";
export const POST_GetCityList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=2&ParentCategoryID=";
export const POST_GetReligionDDL =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=3&ParentCategoryID=0";
export const POST_GetQualificationDDL =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=4&ParentCategoryID=0";
export const POST_GetDepartmentList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=7&ParentCategoryID=0";
export const POST_GetDesignationList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=8&ParentCategoryID=0";
export const POST_GetAreaList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=9&ParentCategoryID=";
export const POST_GetLanguageList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=20&ParentCategoryID=0";
export const POST_GetFollowupRemarksList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=21&ParentCategoryID=0";

export const POST_GetDocumentDDLListByCheckListType =
  "/api/Common/GetDocumentListByCheckListType";
export const GET_GetWeightDDL = "/api/Client/GetWeightDetailDDLList";
export const GET_GetHeightDDL = "/api/Client/GetHeightDetailDDLList";
export const GET_GetServiceTypeDDL = "/api/Service/GetServiceTypesList";
export const POST_GetServiceSubTypeDDL =
  "/api/Dashboard/GetClientServiceSubTypesList";
export const POST_GetBloodGroupList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=12&ParentCategoryID=0";
export const POST_GetRelationList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=13&ParentCategoryID=0";
export const POST_GetCityListBasedOnCompanies =
  "/api/Common/CityDDLBasedOnCompanies";
export const POST_GetFollowUpList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=21&ParentCategoryID=0";
export const POST_GetEquipmentTypeDDL =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=10&ParentCategoryID=0";
export const POST_GetAwardsList =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=22&ParentCategoryID=0";
export const POST_GetDocumentsDDL =
  "/api/Category/GetCategoryListByCategoryType?CategoryType=5&ParentCategoryID=0";

//Companies
export const POST_GetCompanyList = "/api/Company/GetCompanyList";
export const POST_GetCompanyDetailByID = "/api/Company/GetCompanyDetailByID";
export const POST_CompanyCreate = "/api/Company/CompanyCreate";
export const POST_CompanyUpdate = "/api/Company/CompanyUpdate";
export const POST_CompanySetActiveInActive =
  "/api/Company/CompanySetActiveInActive";
export const POST_CompanyDelete = "/api/Company/CompanyDelete";
export const POST_CompanyUpdateLogo = "/api/Company/CompanyUpdateLogo";
export const POST_CompanyLogoDelete = "/api/Company/CompanyLogoDelete";

//PathologyReport

export const POST_GetPathologyReportList =
  "/api/PathologyReport/GetPathologyReportList";
export const POST_GetPathologyReportDetailByID =
  "/api/PathologyReport/GetPathologyReportDetailByID";
export const POST_PathologyReportCreate =
  "/api/PathologyReport/PathologyReportCreate";
export const POST_PathologyReportUpdate =
  "/api/PathologyReport/PathologyReportUpdate";
export const POST_PathologyReportSetActiveInActive =
  "/api/PathologyReport/PathologyReportSetActiveInActive";
export const POST_PathologyReportDelete =
  "/api/PathologyReport/PathologyReportDelete";

//Radiology Report

export const POST_GetRadiologyReportList =
  "/api/RadiologyReport/GetRadiologyReportList";
export const POST_GetRadiologyReportDetailByID =
  "/api/RadiologyReport/GetRadiologyReportDetailByID";
export const POST_RadiologyReportCreate =
  "/api/RadiologyReport/RadiologyReportCreate";
export const POST_RadiologyReportUpdate =
  "/api/RadiologyReport/RadiologyReportUpdate";
export const POST_RadiologyReportSetActiveInActive =
  "/api/RadiologyReport/RadiologyReportSetActiveInActive";
export const POST_RadiologyReportDelete =
  "/api/RadiologyReport/RadiologyReportDelete";

//User
export const POST_UserCreate = "/api/Users/UsersCreate";
export const GET_CompanyDropDownList = "/api/Users/GetCompanyDDLList";
export const POST_UserUpdate = "/api/Users/UsersUpdate";
export const POST_UserDelete = "/api/Users/UsersDelete";
export const POST_UserActiveInactive = "/api/Users/UsersSetActiveInActive";
export const POST_GetUserDetailByID = "/api/Users/GetUsersDetailByID";
export const POST_GetUserList = "/api/Users/GetUsersList";
export const POST_UserQualificationDocumentInsert =
  "/api/Users/UserQualificationsDocumentsInsert";
export const POST_UserQualificationDocumentDelete =
  "/api/Users/UserQualificationsDocsDelete";
export const POST_GenerateOTPForUserChangeEmailOrMobile =
  "/api/Users/GenerateOTPForUserChangeEmailOrMobile";
export const POST_VerifyOTPAndUpdateEmailOrMobile =
  "/api/Users/VerifyOTPAndUpdateMobileEmailID";
export const POST_UserDocumentInsert = "/api/Users/UserDocumentInsert";
export const POST_UserDocumentDelete = "/api/Users/UserDocumentDelete";
export const POST_GetUserDocuments = "/api/Users/GetUserDocuments";
export const POST_GetFormRightsList = "/api/Users/GetFormRightsList";
export const POST_UserFormRightsSave = "/api/Users/UserFormRightsSave";
export const POST_GetUserRightsByLoginID =
  "/api/Users/GetUserFormRightsByLoginID";
export const POST_GetUserDropDownListByCompanyID =
  "/api/Users/GetUserDropDownList";

// SMTP Email Setting
export const POST_GetEmailSettings = "/api/EmailSettings/GetEmailSettings";
export const POST_SaveEmailSettings = "/api/EmailSettings/SaveEmailSettings";
export const POST_VerifyEmailSettings =
  "/api/EmailSettings/VerifyEmailSettings";

// Expenses
export const POST_GetExpensesList = "/api/Expenses/GetExpensesList";
export const POST_ExpensesInsert = "/api/Expenses/ExpensesInsert";
export const POST_ExpensesUpdate = "/api/Expenses/ExpensesUpdate";
export const POST_ExpensesStatusUpdate = "/api/Expenses/ExpensesStatusUpdate";
export const POST_ExpensesDelete = "/api/Expenses/ExpensesDelete";
export const POST_GetExpensesDetailsByID =
  "/api/Expenses/GetExpensesDetailsByID";

// GST Plans
export const POST_GSTPlanInsert = "/api/GSTPlanDetail/GSTPlanInsert";
export const POST_GSTPlanUpdate = "/api/GSTPlanDetail/GSTPlanUpdate";
export const POST_GSTPlanList = "/api/GSTPlanDetail/GSTPlanList";
export const POST_GetGSTPlanDetailByID =
  "/api/GSTPlanDetail/GetGSTPlanDetailByID";
export const POST_GSTPlanDelete = "/api/GSTPlanDetail/GSTPlanDelete";
export const POST_GSTPlanSetActiveInActive =
  "/api/GSTPlanDetail/GSTPlanSetActiveInActive";
export const POST_GSTTypeList = "/api/GSTPlanDetail/GSTTypeList";

//Health Checkup
export const POST_GetPathologyReportsDDLList =
  "/api/HealthCheckups/GetPathologyReportsDDLList";
export const POST_GetRadiologyReportsDDLList =
  "/api/HealthCheckups/GetRadiologyReportsDDLList";
export const POST_GetServiceDDLList = "/api/HealthCheckups/GetServiceDDLList";
export const POST_GetHealthCheckupsList =
  "/api/HealthCheckups/GetHealthCheckupsList";
export const POST_GetHealthCheckupsDetailByID =
  "/api/HealthCheckups/GetHealthCheckupsDetailByID";
export const POST_HealthCheckupsCreate =
  "/api/HealthCheckups/HealthCheckupsCreate";
export const POST_HealthCheckupsUpdate =
  "/api/HealthCheckups/HealthCheckupsUpdate";
export const POST_HealthCheckupsSetActiveInActive =
  "/api/HealthCheckups/HealthCheckupsSetActiveInActive";
export const POST_HealthCheckupsDelete =
  "/api/HealthCheckups/HealthCheckupsDelete";

////Service Mapping & Price List
// export const POST_GetServiceMappingAndPriceListDetailByCompanyID =
//   "/api/Service/POST_GetServiceMappingAndPriceListDetailByCompanyID";
// export const POST_ServiceMappingAndPriceListDetailSave =
//   "/api/Service/ServiceMappingAndPriceListDetailSave";

//Service Mapping
export const POST_GetServiceMappingDetailByCompanyID =
  "/api/Service/GetServiceMappingDetailByCompanyID";
export const POST_ServiceMappingDetailSave =
  "/api/Service/ServiceMappingDetailSave";

//Service Price List
export const POST_GetServicePriceListStaffWiseDetailByCompanyID =
  "/api/Service/GetServicePriceListStaffWiseDetailByCompanyID";
export const POST_ServicePriceListStaffWiseDetailSave =
  "/api/Service/ServicePriceListStaffWiseDetailSave";

//Configuration

export const GET_GetAdvanceSlabConfiguration =
  "/api/Configuration/GetAdvanceSlabConfiguration";
export const GET_GetDocumentCheckListConfiguration =
  "/api/Configuration/GetDocumentCheckListConfiguration";

export const GET_GetConfiguration = "/api/Configuration/GetConfiguration";
export const POST_ConfigurationInsert =
  "/api/Configuration/ConfigurationInsert";
export const POST_ConfigurationUpdate =
  "/api/Configuration/ConfigurationUpdate";

export const POST_AdvanceSlabConfigurationSave =
  "/api/Configuration/AdvanceSlabConfigurationSave";
export const POST_DocumentCheckListConfigurationSave =
  "/api/Configuration/DocumentCheckListConfigurationSave";
export const GET_GetGSTPlansList = "/api/Equipments/GetGSTPlansList";

//Equipments
export const POST_GetEquipmentsList = "/api/Equipments/GetEquipmentsList";
export const POST_GetEquipmentsDetailByID =
  "/api/Equipments/GetEquipmentsDetailByID";
export const POST_EquipmentsSetActiveInActive =
  "/api/Equipments/EquipmentsSetActiveInActive";
export const POST_EquipmentsDelete = "/api/Equipments/EquipmentsDelete";
export const POST_EquipmentTechnicalSpecificationsDelete =
  "/api/Equipments/EquipmentTechnicalSpecificationsDelete";
export const POST_EquipmentFeaturesDelete =
  "/Equipments/EquipmentFeaturesDelete";
export const POST_EquipmentsInsert = "/api/Equipments/EquipmentsInsert";
export const POST_EquipmentsUpdate = "/api/Equipments/EquipmentsUpdate";
export const POST_EquipmentsPhotoInsert =
  "/api/Equipments/EquipmentsPhotoInsert";
export const POST_GetEquipmentsPhotos = "/api/Equipments/GetEquipmentsPhotos";

//Staff
export const POST_GetStaffList = "/api/Staffs/GetStaffList";
export const POST_StaffActiveInactive = "/api/Staffs/StaffActiveInactive";
export const POST_StaffDelete = "/api/Staffs/StaffDelete";
export const POST_StaffInsert = "/api/Staffs/StaffInsert";
export const POST_StaffPhotoUpload = "/api/Staffs/StaffPhotoUpload";
export const POST_StaffDetailGetByID = "/api/Staffs/StaffDetailGetByID";
export const POST_StaffUpdate = "/api/Staffs/StaffUpdate";
export const POST_StaffOfficialInformationInsert =
  "/api/Staffs/StaffOfficialInformationInsert";
export const POST_StaffOfficialInformationUpdate =
  "/api/Staffs/StaffOfficialInformationUpdate";
export const POST_StaffAddressesInsert = "/api/Staffs/StaffAddressesInsert";
export const POST_StaffAddressesUpdate = "/api/Staffs/StaffAddressesUpdate";
export const POST_StaffQualificationsInsert =
  "/api/Staffs/StaffQualificationsInsert";
export const POST_StaffQualificationsDelete =
  "/api/Staffs/StaffQualificationsDelete";
export const POST_GetStaffQualifications = "/api/Staffs/GetStaffQualifications";
export const POST_StaffDocumentInsert = "/api/Staffs/StaffDocumentInsert";
export const POST_StaffDocumentDelete = "/api/Staffs/StaffDocumentDelete";
export const POST_GetStaffDocuments = "/api/Staffs/GetStaffDocuments";
export const POST_StaffDocumentVerify = "/api/Staffs/StaffDocumentVerify";
export const POST_StaffExperienceDetailInsert =
  "/api/Staffs/StaffExperienceDetailInsert";
export const POST_StaffExperienceDetailUpdate =
  "/api/Staffs/StaffExperienceDetailUpdate";
export const POST_StaffExperienceDetailDelete =
  "/api/Staffs/StaffExperienceDetailDelete";
export const POST_GetStaffExperienceDetailList =
  "/api/Staffs/GetStaffExperienceDetailList";
export const POST_GetServiceDropDownListByTypeSubTypeAndCompany =
  "/api/Staffs/GetServiceDropDownListByTypeSubTypeAndCompany";
export const POST_GetServicePriceListByServiceID =
  "/api/Staffs/GetServicePriceListByServiceID";
export const POST_StaffServiceSave = "/api/Staffs/StaffServiceSave";
export const POST_GetStaffServiceListByStaffID =
  "/api/Staffs/GetStaffServiceListByStaffID";
export const POST_GetStaffServiceDetailByID =
  "/api/Staffs/GetStaffServiceDetailByID";
export const POST_StaffServiceDelete = "/api/Staffs/StaffServiceDelete";
export const POST_CheckStaffQualificationStatus =
  "/api/Admin/CheckStaffQualificationStatus";
export const POST_StaffBankDetailSave = "/api/Staffs/StaffBankDetailSave";
export const POST_StaffProfileDownload = "/api/Staffs/StaffProfileDownload";

// Equipment Price List & mapping
export const POST_GetEquipmentMappingAndPriceListDetailByCompanyID =
  "/api/Equipments/GetEquipmentMappingAndPriceListDetailByCompanyID";
export const POST_EquipmentMappingAndPriceListDetailSave =
  "/api/Equipments/EquipmentMappingAndPriceListDetailSave";

// Package Price List & mapping
export const POST_GetPackageMappingAndPriceListDetailByCompanyID =
  "/api/Package/GetPackageMappingAndPriceListDetailByCompanyID";
export const POST_PackageMappingAndPriceListDetailSave =
  "/api/Package/PackageMappingAndPriceListDetailSave";

// Pathology Report Price List & mapping
export const POST_GetPathologyReportMappingAndPriceListDetailByCompanyID =
  "/api/PathologyReport/GetPathologyReportMappingAndPriceListDetailByCompanyID";
export const POST_PathologyReportMappingAndPriceListDetailSave =
  "/api/PathologyReport/PathologyReportMappingAndPriceListDetailSave";

// Radiology Report Price List & mapping
export const POST_GetRadiologyReportMappingAndPriceListDetailByCompanyID =
  "/api/RadiologyReport/GetRadiologyReportMappingAndPriceListDetailByCompanyID";
export const POST_RadiologyReportMappingAndPriceListDetailSave =
  "/api/RadiologyReport/RadiologyReportMappingAndPriceListDetailSave";

// Health Checkup Price List & mapping
export const POST_GetHealthCheckupMappingAndPriceListDetailByCompanyID =
  "/api/HealthCheckups/GetHealthCheckupMappingAndPriceListDetailByCompanyID";
export const POST_HealthCheckupMappingAndPriceListDetailSave =
  "/api/HealthCheckups/HealthCheckupMappingAndPriceListDetailSave";

// Package
export const POST_PackageList = "/api/Package/PackageList";
export const POST_GetPackageDetailByID = "/api/Package/GetPackageDetailByID";
export const POST_PackageInsert = "/api/Package/PackageInsert";
export const POST_PackageUpdate = "/api/Package/PackageUpdate";
export const POST_PackageUpdateActiveInactive =
  "/api/Package/PackageUpdateActiveInactive";
export const POST_PackageUpdateIcon = "/api/Package/PackageUpdateIcon";
export const POST_PackageDelete = "/api/Package/PackageDelete";

// Equipment Dropdown
export const POST_AdminEquipmentsListDDLByEquipmentsTypeID =
  "/api/Admin/AdminEquipmentsListDDLByEquipmentsTypeID";

// Services
export const POST_GetServiceList = "/api/Service/GetServiceList";
export const POST_ServiceCreate = "/api/Service/ServiceCreate";
export const POST_ServiceUpdate = "/api/Service/ServiceUpdate";
export const POST_GetServiceDetailByID = "/api/Service/GetServiceDetailByID";
export const POST_ServiceSetActiveInActive =
  "/api/Service/ServiceSetActiveInActive";
export const POST_ServiceDelete = "/api/Service/ServiceDelete";
export const POST_ServiceIconUpdate = "/api/Service/ServiceIconUpdate";
export const GET_GetServiceTypesList = "/api/Service/GetServiceTypesList";
export const GET_GetServiceSubTypesList = "/api/Service/GetServiceSubTypesList";
export const POST_GetHoursMasterDDL = "/api/Service/GetHoursMasterDDL";

//Client
export const POST_GetClientsList = "/api/Client/GetClientsList";
export const POST_ClientSetActiveInActive =
  "/api/Client/ClientSetActiveInActive";
export const POST_ClientDelete = "/api/Client/ClientDelete";
export const POST_GetClientDetailByID = "/api/Client/GetClientDetailByID";
export const POST_GetClientOrderBookingsDetailByID =
  "/api/Client/GetClientOrderBookingsDetailByID";
export const POST_GetClientPatientsList = "/api/Client/GetClientPatientsList";
export const POST_ClientPatientAddressInsert =
  "/api/Client/ClientPatientAddressInsert";
export const POST_GetPatientAddressList = "/api/Client/GetPatientAddressList";
export const POST_ClientUpdate = "/api/Client/ClientUpdate";
export const POST_ClientPatientAddressUpdate =
  "/api/Client/ClientPatientAddressUpdate";

//Client -> Family Members -> Order History
export const POST_GetPatientOrderHistoryList =
  "/api/Admin/GetPatientOrderHistoryList";
export const POST_AdminOrderBookingDelete =
  "/api/Admin/AdminOrderBookingDelete";
export const POST_AdminOrderBookingStatusUpdate =
  "/api/Admin/AdminOrderBookingStatusUpdate";

//Client -> Family Members -> Patient Active/Delete
export const POST_ClientPatientsSetActiveInActive =
  "/api/Client/ClientPatientsSetActiveInActive";
export const POST_ClientPatientsDelete = "/api/Client/ClientPatientsDelete";

//Patient
export const POST_PatientCreate = "/api/Admin/PatientCreate";
export const POST_PatientAadharCardUpload =
  "/api/Client/ClientPatientAadharCardUpdate";
export const POST_GetPatientList = "/api/Admin/GetPatientsList";
export const POST_PatientActiveInactive =
  "/api/Client/ClientPatientsSetActiveInActive";
export const POST_PatientDelete = "/api/Client/ClientPatientsDelete";
export const POST_GetPatientDetailByID = "/api/Admin/GetPatientDetailByID";
export const POST_PatientUpdate = "/api/Client/ClientPatientUpdate";
export const POST_PatientPastHistoryInsert =
  "/api/Admin/PatientPastHistoryInsert";
export const POST_PatientPastHistoryUpdate =
  "/api/Admin/PatientPastHistoryUpdate";
export const POST_GetPatientListByMobileOrEmail =
  "/api/Admin/GetPatientsListByMobileOrEmail";

// Staff Duty Slots
export const POST_GetStaffsDutySlotsList = "/api/Staffs/GetStaffsDutySlotsList";
export const POST_StaffsDutySlotsCreate = "/api/Staffs/StaffsDutySlotsCreate";
export const POST_GetStaffsDutySlotsDetailByID =
  "/api/Staffs/GetStaffsDutySlotsDetailByID";
export const POST_AdminStaffsListDDLByCompanyID =
  "/api/Admin/AdminStaffsListDDLByCompanyID";

// Staff Duty Slots Date Wise
export const POST_StaffDutySlotDateWiseList =
  "/api/Admin/AdminStaffDutySlotDateWiseList";
export const POST_StaffDutySlotDateWiseInsert =
  "/api/Admin/AdminStaffDutySlotDateWiseInsert";
export const POST_StaffDutySlotDateWiseUpdate =
  "/api/Admin/AdminStaffDutySlotDateWiseUpdate";
export const POST_StaffDutySlotDateWiseDelete =
  "/api/Admin/AdminStaffDutySlotDateWiseDelete";
export const POST_GetStaffDutySlotDateWiseDetailByID =
  "/api/Admin/AdminStaffDutySlotDateWiseDetailByID";

//Recommendations
export const POST_GetRecommendationsList = "/api/Common/GetRecommendationsList";
export const POST_RecommendationsSetActiveInActive =
  "/api/Common/RecommendationsSetActiveInactive";
export const POST_RecommendationsDelete = "/api/Common/RecommendationsDelete";
export const POST_RecommendationsDetailByID =
  "/api/Common/GetRecommendationsDetailByID";
export const POST_RecommendationsInsert = "/api/Common/RecommendationsInsert";
export const POST_RecommendationsUpdate = "/api/Common/RecommendationsUpdate";
export const POST_RecommendationsPhotoUpdate =
  "/api/Common/RecommendationsPhotoUpdate";

//Staff Leave Managment
export const POST_GetStaffLeaveRequestsList =
  "/api/Admin/AdminStaffLeaveRequestsList";
export const POST_StaffLeaveRequestsActiveInActive =
  "/api/Admin/AdminStaffLeaveRequestsActiveInActive";
export const POST_StaffLeaveRequestsDelete =
  "/api/Admin/AdminStaffLeaveRequestsDelete";
export const POST_StaffLeaveRequestsDetailByID =
  "/api/Admin/AdminStaffLeaveRequestsDetailByID";
export const POST_StaffLeaveRequestsInsert =
  "/api/Admin/AdminStaffLeaveRequestsInsert";
export const POST_StaffLeaveRequestsUpdate =
  "/api/Admin/AdminStaffLeaveRequestsUpdate";
export const POST_StaffLeaveRequestsOnGoingBookingsList =
  "/api/Admin/AdminStaffLeaveRequestsOnGoingBookingsList";

//VoucherSeries
export const POST_GetVoucherSeriesList = "/api/Common/GetRecommendationsList";
export const POST_VoucherSeriesSetActiveInActive =
  "/api/Common/RecommendationsSetActiveInactive";
export const POST_VoucherSeriesDelete = "/api/Common/RecommendationsDelete";
export const POST_VoucherSeriesDetailByID =
  "/api/Common/GetRecommendationsDetailByID";
export const POST_VoucherSeriesCreate = "/api/Common/RecommendationsInsert";
export const POST_VoucherSeriesUpdate = "/api/Common/RecommendationsUpdate";

//Advance Receipt
export const POST_AdminOrderBookingAdvancesList =
  "/api/Admin/AdminOrderBookingAdvancesList";
export const POST_AdminOrderBookingAdvancesDetailByID =
  "/api/Admin/AdminOrderBookingAdvancesDetailByID";
export const POST_AdminOrderBookingAdvancesDelete =
  "/api/Admin/AdminOrderBookingAdvancesDelete";
export const POST_AdminOrderBookingAdvancesUpdate =
  "/api/Admin/AdminOrderBookingAdvancesUpdate";
export const POST_AdminOrderBookingAdvancesInsert =
  "/api/Admin/AdminOrderBookingAdvancesInsert";
export const POST_AdminOrderBookingAdvancesSearchByOrderBookingNo =
  "/api/Admin/AdminOrderBookingAdvancesSearchByOrderBookingNo";
export const POST_GetModeOfPaymentDDL = "/api/Common/GetModeOfPaymentDDL";

//Advance Return
export const POST_AdminOrderBookingAdvanceReturnList =
  "/api/Admin/AdminOrderBookingAdvanceReturnList";
export const POST_AdminOrderBookingAdvanceReturnDetailByID =
  "/api/Admin/AdminOrderBookingAdvanceReturnDetailByID";
export const POST_AdminOrderBookingAdvanceReturnUpdate =
  "/api/Admin/AdminOrderBookingAdvanceReturnUpdate";
export const POST_AdminOrderBookingAdvanceReturnInsert =
  "/api/Admin/AdminOrderBookingAdvanceReturnInsert";
export const POST_AdminOrderBookingAdvanceReturnSearchByOrderBookingNo =
  "/api/Admin/AdminOrderBookingAdvanceReturnSearchByOrderBookingNo";

//Enquiry
export const GET_GetEnquiryTypeList = "/api/Enquiry/GetEnquiryTypeList";
export const POST_GetEnquiryList = "/api/Enquiry/GetEnquiryList";
export const POST_GetEnquiryDetailByID = "/api/Enquiry/GetEnquiryDetailByID";
export const POST_EnquiryCreate = "/api/Enquiry/EnquiryCreate";
export const POST_EnquiryRead = "/api/Enquiry/EnquiryRead";
export const POST_EnquiryFollowupsInsert =
  "/api/Enquiry/EnquiryFollowupsInsert";
export const POST_GetEnquiryTypeWiseDetail =
  "/api/Enquiry/GetEnquiryTypeWiseDetail";

//OrderBooking
export const POST_GetOrderBookingList = "/api/Admin/GetAdminOrderBookingsList";
export const POST_OrderBookingDelete = "/api/Admin/AdminOrderBookingDelete";
export const POST_OrderBookingClose = "/api/Admin/AdminOrderBookingClose";
export const POST_GetOrderBookingAdvanceDetailByOrderBookingID =
  "/api/Admin/GetOrderBookingAdvancesDetailsByOrderBookingID";
export const POST_GetServiceHoursDDLByServiceID =
  "/api/Admin/AdminServiceHoursDDLListByServiceID";
export const POST_GetClientStaffListByServiceID =
  "/api/Client/GetClientStaffListByServiceID";
export const POST_GetEquipmentsDDLByEquipmentTypeID =
  "/api/Admin/AdminEquipmentsListDDLByEquipmentsTypeID";
export const POST_GetPackagesDetailListDDLByCompanyID =
  "/api/Admin/AdminPackagesDetailListDDLByCompanyID";
export const POST_GetHealthCheckupsListDDLByCompanyID =
  "/api/Admin/AdminHealthCheckupsListDDLByCompanyID";
export const POST_GetPathologyReportsListDDLByCompanyID =
  "/api/Admin/AdminPathologyReportsListDDLByCompanyID";
export const POST_GetRadiologyReportsListDDLByCompanyID =
  "/api/Admin/AdminRadiologyReportsListDDLByCompanyID";
export const POST_OrderBookingPatientDocumentInsert =
  "/api/Admin/AdminOrderBookingsPatientDocumentsInsert";
export const POST_OrderBookingPatientDocumentDelete =
  "/api/Admin/AdminOrderBookingsPatientDocumentsDelete";
export const POST_OrderBookingPatientDocumentsList =
  "/api/Admin/AdminOrderBookingPatientDocumentsListByOrderBookingID";
export const POST_OrderBookingInsert = "/api/Admin/AdminOrderBookingCreate";
export const POST_OrderBookingEquipmentPDCFileInsert =
  "/api/Admin/AdminOrderBookingEquipmentPDCFileInsert";
export const POST_OrderBookingUpdate = "/api/Admin/AdminOrderBookingsUpdate";
export const POST_GetSLAbyOrderBookingID =
  "/api/Admin/GetOrderBookingsDetailByIDForSLAAgreement";
export const POST_GetOrderBookingDetailByID =
  "/api/Client/GetClientOrderBookingsDetailByID";
export const POST_OrderBookingAdvanceNotificationSend =
  "/api/Admin/AdminOrderBookingsAdvanceNotificationSend";

//EquipmentPDC
export const POST_GetOrderBookingEquipmentPDCDetailsList =
  "/api/Admin/GetOrderBookingEquipmentPDCDetailsList";
export const POST_OrderBookingEquipmentPDCSearchByOrderBookingNo =
  "/api/Admin/OrderBookingEquipmentPDCSearchByOrderBookingNo";
export const POST_GetOrderBookingEquipmentPDCDetailsByID =
  "/api/Admin/GetOrderBookingEquipmentPDCDetailsByID";
export const POST_AdminOrderBookingEquipmentPDCDetailsInsert =
  "/api/Admin/AdminOrderBookingEquipmentPDCDetailsInsert";
export const POST_AdminOrderBookingEquipmentPDCDetailsUpdate =
  "/api/Admin/AdminOrderBookingEquipmentPDCDetailsUpdate";
export const POST_AdminOrderBookingEquipmentPDCFileInsert =
  "/api/Admin/AdminOrderBookingEquipmentPDCFileInsert";

//Schedule Package & Health Checkup
export const POST_AdminPackageHealthCheckupScheduleList =
  "/api/Admin/AdminPackageHealthCheckupScheduleList";
export const POST_AdminHealthCheckupServiceScheduleDetail =
  "/api/Admin/AdminHealthCheckupServiceScheduleDetail";
export const POST_AdminPackageServiceScheduleDetail =
  "/api/Admin/AdminPackageServiceScheduleDetail";
export const POST_AdminPackageHealthCheckupPackageInsert =
  "/api/Admin/AdminPackageHealthCheckupPackageInsert";
export const POST_AdminPackageHealthCheckupInsert =
  "/api/Admin/AdminPackageHealthCheckupInsert";

//Voucher Series
export const POST_AdminVoucherSeriesList = "/api/Admin/AdminVoucherSeriesList";
export const POST_AdminVoucherSeriesDetailByID =
  "/api/Admin/AdminVoucherSeriesDetailByID";
export const POST_AdminVoucherSeriesRequestsActiveInActive =
  "/api/Admin/AdminVoucherSeriesRequestsActiveInActive";
export const POST_AdminVoucherSeriesInsert =
  "/api/Admin/AdminVoucherSeriesInsert";
export const POST_AdminVoucherSeriesUpdate =
  "/api/Admin/AdminVoucherSeriesUpdate";

//Equipment Installation
export const POST_AdminEquipmentInstallationList =
  "/api/Admin/AdminEquipmentInstallationList";
export const POST_AdminEquipmentInstallationDetailByID =
  "/api/Admin/AdminEquipmentInstallationDetailByID";
export const POST_AdminEquipmentInstallationSearchByOrderBookingNo =
  "/api/Admin/AdminEquipmentInstallationSearchByOrderBookingNo";
export const POST_AdminEquipmentInstallationInsert =
  "/api/Admin/AdminEquipmentInstallationInsert";
export const POST_AdminEquipmentInstallationUpdate =
  "/api/Admin/AdminEquipmentInstallationUpdate";
export const POST_AdminEquipmentInstallationPhotosInsertDelete =
  "/api/Admin/AdminEquipmentInstallationPhotosInsertDelete";

//StaffAwards
export const POST_AdminStaffAwardsList = "/api/Admin/AdminStaffAwardsList";
export const POST_AdminStaffAwardsDetailByID =
  "/api/Admin/AdminStaffAwardsDetailByID";
export const POST_AdminStaffAwardsRequestsActiveInActive =
  "/api/Admin/AdminStaffAwardsRequestsActiveInActive";
export const POST_AdminStaffAwardsDelete = "/api/Admin/AdminStaffAwardsDelete";
export const POST_AdminStaffAwardsInsert = "/api/Admin/AdminStaffAwardsInsert";
export const POST_AdminStaffAwardsUpdate = "/api/Admin/AdminStaffAwardsUpdate";

// Equipment Return
export const POST_AdminEquipmentReturnList =
  "/api/Admin/AdminEquipmentReturnList";
export const POST_AdminEquipmentReturnSearchByOrderBookingNo =
  "/api/Admin/AdminEquipmentReturnSearchByOrderBookingNo";
export const POST_AdminEquipmentReturnDetailByID =
  "/api/Admin/AdminEquipmentReturnDetailByID";
export const POST_AdminEquipmentReturnInsert =
  "/api/Admin/AdminEquipmentReturnInsert";
export const POST_AdminEquipmentReturnUpdate =
  "/api/Admin/AdminEquipmentReturnUpdate";
export const POST_AdminEquipmentReturnPhotosInsertDelete =
  "/api/Admin/AdminEquipmentReturnPhotosInsertDelete";

//Staff Payout
export const POST_AdminStaffPayoutInsert = "/api/Admin/AdminStaffPayoutInsert";
export const POST_AdminGetYearDDLList = "/api/Admin/AdminGetYearDDLList";

// Activity Monitoring
export const POST_AdminActivityMonitoringList =
  "/api/Admin/AdminActivityMonitoringList";
export const POST_AdminActivityMonitoringUpdate =
  "/api/Admin/AdminActivityMonitoringUpdate";
export const POST_AdminActivityMonitoringCancel =
  "/api/Admin/AdminActivityMonitoringCancel";
export const POST_StaffServiceScheduleSkipAndExtendUpdate =
  "/api/Staffs/StaffServiceScheduleSkipAndExtendUpdate";
export const POST_AdminActivityMonitoringComplete =
  "/api/Admin/AdminActivityMonitoringComplete";
export const POST_AdminStaffPunchInPunchOutUpdateAfterStatusComplete =
  "/api/Admin/AdminStaffPunchInPunchOutUpdateAfterStatusComplete";

// Equipment Deposite Receipt
export const POST_GetClientOrderBookingEquipmentDepositReceipt =
  "/api/Admin/GetClientOrderBookingEquipmentDepositReceipt";

// Advances Receipt
export const POST_GetClientOrderBookingAdvancesReceipt =
  "/api/Admin/GetClientOrderBookingAdvancesReceipt";

// Complaint Managment
export const POST_GetComplaintsList = "/api/Common/GetComplaintsList";
export const POST_ComplaintsClose = "/api/Common/ComplaintsClose";

//Staff Over Time
export const POST_StaffDropDownForOverTime =
  "/api/Staffs/StaffDropDownForOverTime";
export const POST_OrderBookingNoDropdDwnStaffWise =
  "/api/Staffs/OrderBookingNoDropdDwnStaffWise";
export const POST_StaffServiceScheduleDateDropDownOrderBookingWise =
  "/api/Staffs/StaffServiceScheduleDateDropDownOrderBookingWise";
export const POST_StaffOverTimeInsert = "/api/Staffs/StaffOverTimeInsert";
export const POST_StaffOverTimeUpdate = "/api/Staffs/StaffOverTimeUpdate";
export const POST_StaffOverTimeList = "/api/Staffs/StaffOverTimeList";
export const POST_AdminStaffPunchInPunchOutUpdate =
  "/api/Admin/AdminStaffPunchInPunchOutUpdate";
export const POST_GetStaffOverTimeDetailByID =
  "/api/Staffs/GetStaffOverTimeDetailByID";

// Feedback
export const POST_GetClientFeedbackList = "/api/Common/GetClientFeedbackList";
export const POST_ClientFeedbackPublished =
  "/api/Common/ClientFeedbackPublished";

//Rating and Reviews
export const POST_StaffReviewRatingList = "/api/Staffs/StaffReviewRatingList";
export const POST_ClientStaffReviewRatingPublished =
  "/api/Client/ClientStaffReviewRatingPublished";

//Order Booking Summary Download
export const POST_ClientOrderBookingsSummary =
  "/api/Client/ClientOrderBookingsSummary";

//Invoice
export const POST_GetAdminInvoiceList = "/api/Invoice/AdminInvoiceList";
export const POST_InvoiceDownload = "/api/Invoice/InvoiceDownload";
export const POST_InvoiceReceiptDownload =
  "/api/Invoice/InvoiceReceiptDownload";
export const POST_GetPatientListForInvoice =
  "/api/Invoice/AdminPatientListForInvoice";
export const POST_GetPendingOrderListForInvoiceGenerate =
  "/api/Invoice/GetPendingOrderListForInvoiceGenerate";
export const POST_GetPreGenerateInvoiceDetail =
  "/api/Invoice/GetPreGenerateInvoiceDetail";
export const GET_ExpenseDDLList = "/api/Admin/GetExpensesDDLList";
export const POST_AdminInvoiceInsert = "/api/Invoice/AdminInvoiceInsert";
export const POST_AdminOrderBookingInvoiceReceiptInsert =
  "/api/Invoice/AdminOrderBookingInvoiceReceiptInsert";
export const POST_GetInvoiceDetailsByID = "/api/Invoice/GetInvoiceDetailsByID";

//Terms & Conditions
export const GET_GetAdminTermsAndConditionDetailByID =
  "/api/Admin/AdminTermsAndConditionDetailByID";
export const POST_AdminTermsAndConditionInsert =
  "/api/Admin/AdminTermsAndConditionInsert";

//Privacy Policy
export const GET_AdminPrivacyPolicyDetailByID =
  "/api/Admin/AdminPrivacyPolicyDetailByID";
export const POST_AdminPrivacyPolicyInsert =
  "/api/Admin/AdminPrivacyPolicyInsert";

//Reports
export const POST_EnquiryStatusReportInExcel =
  "/api/Reports/EnquiryStatusReportInExcel";
export const POST_GetEnquiriesYearDDLList =
  "/api/Admin/GetEnquiriesYearDDLList";
export const POST_ClientOutstandingReportInExcel =
  "/api/Reports/ClientOutstandingReportInExcel";

export const POST_EquipmentReportInExcelDownload =
  "/api/Reports/EquipmentReportInExcelDownload";
export const POST_StaffPerformanceReportInExcel =
  "/api/Reports/StaffPerformanceReportInExcel";

export const POST_GetYearlyAnalysisDataWiseYearDDLList =
  "/api/Admin/GetYearlyAnalysisDataWiseYearDDLList";

export const POST_GetYearlyAnalysisDataReport =
  "/api/Reports/GetYearlyAnalysisDataReport";

export const POST_GSTMonthlyReportOfEquipmentByYearInExcel =
  "/api/Reports/GSTMonthlyReportOfEquipmentByYearInExcel";

export const POST_GetGSTYearlyReportOfEquipment =
  "/api/Reports/GetGSTYearlyReportOfEquipment";

export const POST_PatientMasterReportInExcelDownload =
  "/api/Reports/PatientMasterReportInExcelDownload";

export const POST_StaffListReportInExcel =
  "/api/Reports/StaffListReportInExcel";

export const POST_GetStaffUtilizationReport =
  "/api/Reports/GetStaffUtilizationReport";

export const POST_GetStaffUtilizationGenderWiseReport =
  "/api/Reports/GetStaffUtilizationGenderWiseReport";

export const POST_GetStaffUtilizationReligionWiseReport =
  "/api/Reports/GetStaffUtilizationReligionWiseReport";

export const POST_GetStaffUtilizationShiftWiseReport =
  "/api/Reports/GetStaffUtilizationShiftWiseReport";

export const POST_GetAreaListOfExstingPatientAndStaff =
  "/api/Common/GetAreaListOfExstingPatientAndStaff";

export const POST_GetRevenueSummary = "/api/Reports/GetRevenueSummary";

export const POST_GetRevenueServiceWiseData =
  "/api/Reports/GetRevenueServiceWiseData";

export const POST_GetRevenueEquipmentWiseData =
  "/api/Reports/GetRevenueEquipmentWiseData";

export const POST_GetRevenuePackageWiseData =
  "/api/Reports/GetRevenuePackageWiseData";

export const POST_GetRevenueHealthCheckupWiseData =
  "/api/Reports/GetRevenueHealthCheckupWiseData";

export const POST_GetRevenuePathologyWiseData =
  "/api/Reports/GetRevenuePathologyWiseData";

export const POST_GetRevenueRadiologyWiseData =
  "/api/Reports/GetRevenueRadiologyWiseData";

export const POST_GetStaffPayoutListForAdminData =
  "/api/Reports/GetStaffPayoutListForAdminData";

export const POST_GetEquipmentDepositCollectionPendingReportInExcel =
  "/api/Reports/EquipmentDepositCollectionPendingReportInExcel";

export const POST_GetInvoiceGeneratePendingReportInExcel =
  "/api/Reports/InvoiceGeneratePendingReportInExcel";

export const POST_GetAdvanceDueReportInExcel =
  "/api/Reports/AdvanceDueReportInExcel";

//Service Cancel Approval Reject

export const POST_AdminStaffServiceCancelRequestList =
  "/api/Staffs/AdminStaffServiceCancelRequestList";
export const POST_AdminStaffOrderBookingServiceCancelApprovalReject =
  "/api/Staffs/AdminStaffOrderBookingServiceCancelApprovalReject";

//Equipment Schedule

export const POST_AdminEquipmentInstallationScheduleList =
  "/api/Admin/AdminEquipmentInstallationScheduleList";
export const POST_AdminEquipmentReturnScheduleList =
  "/api/Admin/AdminEquipmentReturnScheduleList";

export const POST_AdminEquipmentInstallationAssignStaff =
  "/api/Admin/AdminEquipmentInstallationAssignStaff";
export const POST_AdminStaffDropDown = "/api/Admin/AdminStaffDropDown";

//Dashboard
export const POST_GetAdminDashboard = "/api/Dashboard/AdminDashboard";
export const POST_GetAdminDashboardRevenueData =
  "/api/Dashboard/GetAdminDashboardRevenueChartData";
export const POST_GetAdminDashboardRevenueMonthlyYearWise =
  "/api/Dashboard/AdminDashboardRevenueChartMonthlyYearWise";
export const POST_GetDashboardEquipmentBookingTypeWiseYearChart =
  "/api/Dashboard/GetDashboardEquipmentBookingTypeWiseYearChart";
export const POST_GetDashboardEquipmentBookingTypeWiseWeekChart =
  "/api/Dashboard/GetDashboardEquipmentBookingTypeWiseWeekChart";
export const POST_GetDashboardEquipmentBookingTypeWiseTodayChart =
  "/api/Dashboard/GetEquipmentBookingTypeWiseTodayChartData";

//Notifications
export const POST_GetNotificationsList =
  "/api/Notifications/GetNotificationsList";
export const POST_NotificationReadUpdate =
  "/api/Notifications/NotificationReadUpdate";

//Tutorial Videos
export const POST_TutorialVideoInsert = "/api/Admin/AdminTutorialVideoInsert";
export const POST_TutorialVideoUpdate = "/api/Admin/AdminTutorialVideoUpdate";
export const POST_GetTutorialVideoList = "/api/Admin/GetAdminTutorialVideoList";
export const POST_TutorialVideoActiveInactive =
  "/api/Admin/AdminTutorialVideoActiveInactive";
export const POST_TutorialVideoDelete = "/api/Admin/AdminTutorialVideoDelete";
export const POST_GetTutorialVideoDetailByID =
  "/api/Admin/GetTutorialVideoDetailsByID";
