import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Label, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import defaultIcon from "../../../../images/avatar/500x500.png";
import { useHistory } from "react-router-dom";
import {
  GET_GetConfiguration,
  POST_ConfigurationInsert,
  POST_ConfigurationUpdate,
  GET_GetGSTPlansList,
} from "../../../Helper/url_helper";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

const Configruation = () => {
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);
  const [GSTPlanListOptions, setGSTPlanListOptions] = useState([]);

  const [logoEquipment, setLogoEquipment] = useState(null);
  const [logoFileEquipment, setLogoFileEquipment] = useState(null);
  const [fileKeyLogoEquipment, setFileKeyLogoEquipment] = useState();
  const [iconPathxEquipment, setIconPathxEquipment] = useState("");
  const [validationLogoEquipment, setValidationLogoEquipment] = useState("");

  const [logoHealthCheckup, setLogoHealthCheckup] = useState(null);
  const [logoFileHealthCheckup, setLogoFileHealthCheckup] = useState(null);
  const [fileKeyLogoHealthCheckup, setFileKeyLogoHealthCheckup] = useState();
  const [iconPathxHealthCheckup, setIconPathxHealthCheckup] = useState("");
  const [validationLogoHealthCheckup, setValidationLogoHealthCheckup] =
    useState("");

  const validationSchema = Yup.object().shape({
    GSTPlanEquipmentRent: Yup.string().required(
      "Equipment Rent Default GST is required field"
    ),
    GSTPlanEquipmentSale: Yup.string().required(
      "Equipment Sale Default GST is required field"
    ),
    AdvanceBookingDays: Yup.string().required(
      "Advance Booking Days is required field"
    ),
    OrderBookingRequestAcceptDuration: Yup.string().required(
      "Order Booking Request Accept Duration is required field"
    ),
    StaffAvailabilityKM: Yup.string().required(
      "Staff Availability KM is required field"
    ),
    ContactNo: Yup.string().required("Helpline Contact No is required field"),
    EmailID: Yup.string().required("Helpline EmailID is required field"),
    StaffCallingNo: Yup.string().required("Staff Calling No is required field"),
    StaffWhatsappNo: Yup.string().required(
      "Staff Whatsapp No is required field"
    ),
    StaffDefaultMessages: Yup.string().required(
      "Staff Default Messages is required field"
    ),
    ClientCallingNo: Yup.string().required(
      "Client Calling No is required field"
    ),
    ClientWhatsappNo: Yup.string().required(
      "Client Whatsapp No is required field"
    ),
    ClientDefaultMessages: Yup.string().required(
      "Client Default Messages is required field"
    ),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      ConfigruationID: "",
      GSTPlanEquipmentRent: "",
      GSTPlanEquipmentSale: "",
      AdvanceBookingDays: "",
      OrderBookingRequestAcceptDuration: "",
      DirectPublishStaffReview: false,
      ContactNo: "",
      EmailID: "",
      StaffAvailabilityKM: "",
      StaffCallingNo: "",
      StaffWhatsappNo: "",
      StaffDefaultMessages: "",
      ClientCallingNo: "",
      ClientWhatsappNo: "",
      ClientDefaultMessages: "",

      CountryCodeContactNo: "+91",
      CountryCodeStaffCallingNo: "+91",
      CountryCodeStaffWhatsappNo: "+91",
      CountryCodeClientCallingNo: "+91",
      CountryCodeClientWhatsappNo: "+91",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      DirectPublishStaffReview,
      ConfigruationID,
      CountryCodeContactNo,
      CountryCodeStaffCallingNo,
      CountryCodeStaffWhatsappNo,
      CountryCodeClientCallingNo,
      CountryCodeClientWhatsappNo,
    },
  } = formik;

  useEffect(() => {
    get_Configuration();
    getGSTPlanList();
  }, []);

  const getGSTPlanList = () => {
    setIsLoading(true);
    get(GET_GetGSTPlansList)
      .then((res) => {
        if (res.IsSuccess) {
          setGSTPlanListOptions(
            res.GSTPlansDDLList.map((item) => ({
              label: item.GSTPlan,
              value: item.EncryptGSTPlanID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const get_Configuration = async () => {
    setIsLoading(true);
    let response = await get(GET_GetConfiguration);
    if (response.IsSuccess) {
      setValues({
        ConfigruationID: response.ConfigurationIDEncrypted,
        GSTPlanEquipmentRent: response.EquipmentRentDefaultGSTPlanIDEncrypt,
        GSTPlanEquipmentSale: response.EquipmentSaleDefaultGSTPlanIDEncrypt,
        AdvanceBookingDays: response.AdvanceBookingDays,
        OrderBookingRequestAcceptDuration:
          response.OrderBookingRequestAcceptDuration,
        DirectPublishStaffReview: response.DirectPublishStaffReview,
        //ContactNo: response.ContactNo,
        ContactNo: response.ContactNo
          ? response.ContactNo.replace(
              response.ContactNo.split(" ")[0],
              ""
            ).trim()
          : response.ContactNo,
        EmailID: response.EmailID,
        StaffAvailabilityKM: response.StaffAvailabilityKM,
        //StaffCallingNo: response.StaffCallingNo,
        StaffCallingNo: response.StaffCallingNo
          ? response.StaffCallingNo.replace(
              response.StaffCallingNo.split(" ")[0],
              ""
            ).trim()
          : response.StaffCallingNo,
        //StaffWhatsappNo: response.StaffWhatsappNo,
        StaffWhatsappNo: response.StaffWhatsappNo
          ? response.StaffWhatsappNo.replace(
              response.StaffWhatsappNo.split(" ")[0],
              ""
            ).trim()
          : response.StaffWhatsappNo,
        StaffDefaultMessages: response.StaffDefaultMessages,
        //ClientCallingNo: response.ClientCallingNo,
        ClientCallingNo: response.ClientCallingNo
          ? response.ClientCallingNo.replace(
              response.ClientCallingNo.split(" ")[0],
              ""
            ).trim()
          : response.ClientCallingNo,
        //ClientWhatsappNo: response.ClientWhatsappNo,
        ClientWhatsappNo: response.ClientWhatsappNo
          ? response.ClientWhatsappNo.replace(
              response.ClientWhatsappNo.split(" ")[0],
              ""
            ).trim()
          : response.ClientWhatsappNo,
        ClientDefaultMessages: response.ClientDefaultMessages,
        CountryCodeContactNo: response.ContactNo
          ? response.ContactNo.split(" ")[0]
          : "+91",
        CountryCodeStaffCallingNo: response.StaffCallingNo
          ? response.StaffCallingNo.split(" ")[0]
          : "+91",
        CountryCodeStaffWhatsappNo: response.StaffWhatsappNo
          ? response.StaffWhatsappNo.split(" ")[0]
          : "+91",
        CountryCodeClientCallingNo: response.ClientCallingNo
          ? response.ClientCallingNo.split(" ")[0]
          : "+91",
        CountryCodeClientWhatsappNo: response.ClientWhatsappNo
          ? response.ClientWhatsappNo.split(" ")[0]
          : "+91",
      });
      setLogoEquipment(response.EquipmentIcon);
      setLogoHealthCheckup(response.HealthCheckupIcon);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);
    if (logoFileEquipment === null && logoEquipment === null) {
      setValidationLogoEquipment("Equipment Icon requird");
      setIsLoading(false);
      return false;
    }

    if (logoFileHealthCheckup === null && logoHealthCheckup === null) {
      setValidationLogoHealthCheckup("Health Checkup Icon requird");
      setIsLoading(false);
      return false;
    }

    let formData = new FormData();
    formData.append(
      "EquipmentRentDefaultGSTPlanIDEncrypt",
      values.GSTPlanEquipmentRent
    );
    formData.append(
      "EquipmentSaleDefaultGSTPlanIDEncrypt",
      values.GSTPlanEquipmentSale
    );
    formData.append("AdvanceBookingDays", values.AdvanceBookingDays);
    formData.append(
      "OrderBookingRequestAcceptDuration",
      values.OrderBookingRequestAcceptDuration
    );
    formData.append("DirectPublishStaffReview", DirectPublishStaffReview);
    formData.append("LoginIDEncrypted", objLogin.LoginIDEncrypt);
    formData.append("EquipmentIconFile", logoFileEquipment);
    formData.append("HealthCheckupIconFile", logoFileHealthCheckup);
    formData.append("ConfigurationIDEncrypted", ConfigruationID);

    formData.append(
      "ContactNo",
      values.ContactNo
        ? "+" + CountryCodeContactNo.replace(/\+/g, "") + " " + values.ContactNo
        : ""
    );
    formData.append("EmailID", values.EmailID);
    formData.append("StaffAvailabilityKM", values.StaffAvailabilityKM);
    formData.append(
      "StaffCallingNo",
      values.StaffCallingNo
        ? "+" +
            CountryCodeStaffCallingNo.replace(/\+/g, "") +
            " " +
            values.StaffCallingNo
        : ""
    );
    formData.append(
      "StaffWhatsappNo",
      values.StaffWhatsappNo
        ? "+" +
            CountryCodeStaffWhatsappNo.replace(/\+/g, "") +
            " " +
            values.StaffWhatsappNo
        : ""
    );
    formData.append("StaffDefaultMessages", values.StaffDefaultMessages);
    formData.append(
      "ClientCallingNo",
      values.ClientCallingNo
        ? "+" +
            CountryCodeClientCallingNo.replace(/\+/g, "") +
            " " +
            values.ClientCallingNo
        : ""
    );
    formData.append(
      "ClientWhatsappNo",
      values.ClientWhatsappNo
        ? "+" +
            CountryCodeClientWhatsappNo.replace(/\+/g, "") +
            " " +
            values.ClientWhatsappNo
        : ""
    );
    formData.append("ClientDefaultMessages", values.ClientDefaultMessages);

    postFormData(
      ConfigruationID ? POST_ConfigurationUpdate : POST_ConfigurationInsert,
      formData
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };
  const uploadEquipmentIcon = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        swal("", "Select valid image file!", "warning");
        setLogoFileEquipment(null);
        setLogoEquipment(null);
        setFileKeyLogoEquipment(Math.random().toString(9).substring(2, 9));
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== 500 || width !== 500) {
              swal({
                title: "Error",
                text: "Image size must be of 500x500",
                icon: "error",
                button: "OK",
              });
              setLogoFileEquipment(null);
              setLogoEquipment(null);
            } else {
              // alert("Uploaded image has valid Height and Width.");
              setLogoFileEquipment(file);
              setIconPathxEquipment("");
              setLogoEquipment(URL.createObjectURL(file));
              setValidationLogoEquipment("");
            }
          };
        };
      }
    } else {
      setLogoFileEquipment(null);
      setLogoEquipment(null);
    }
  };

  const uploadHealthCheckupIcon = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        swal("", "Select valid image file!", "warning");
        setLogoFileHealthCheckup(null);
        setLogoHealthCheckup(null);
        setFileKeyLogoHealthCheckup(Math.random().toString(9).substring(2, 9));
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== 500 || width !== 500) {
              swal({
                title: "Error",
                text: "Image size must be of 500x500",
                icon: "error",
                button: "OK",
              });
              setLogoFileHealthCheckup(null);
              setLogoHealthCheckup(null);
            } else {
              // alert("Uploaded image has valid Height and Width.");
              setLogoFileHealthCheckup(file);
              setIconPathxHealthCheckup("");
              setLogoHealthCheckup(URL.createObjectURL(file));
              setValidationLogoHealthCheckup("");
            }
          };
        };
      }
    } else {
      setLogoFileHealthCheckup(null);
      setLogoHealthCheckup(null);
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle activeMenu="Configuration" motherMenu="Configuration" />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Equipment Rent Default GST"}
                            name="GSTPlanEquipmentRent"
                            options={GSTPlanListOptions}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            label={"Equipment Sale Default GST"}
                            name="GSTPlanEquipmentSale"
                            options={GSTPlanListOptions}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <div
                            className="custom-control custom-checkbox mb-3 checkbox-success"
                            style={{ marginTop: "35px", zIndex: 0 }}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheckBox3"
                              checked={DirectPublishStaffReview}
                              onChange={(e) => {
                                setFieldValue(
                                  "DirectPublishStaffReview",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheckBox3"
                            >
                              Direct Publish Staff Review
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={5}>
                          <Field
                            type="number"
                            label={
                              "Order Booking Request Accept Duration (In Minutes)"
                            }
                            name="OrderBookingRequestAcceptDuration"
                            placeholder={"Enter Duration In Minutes"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Advance Booking Days"}
                            name="AdvanceBookingDays"
                            placeholder={"Enter Advance Booking Days"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Staff Availability KM"}
                            name="StaffAvailabilityKM"
                            placeholder={"Enter Staff Availability KM"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={5}>
                          <Field
                            type="email"
                            label={"Helpline Email"}
                            name="EmailID"
                            placeholder={"Enter Helpline Email"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="text"
                            label={"Helpline Contact No"}
                            name="ContactNo"
                            placeholder={"Enter Helpline Contact No"}
                            component={CustomPhoneInput}
                            countryCode={CountryCodeContactNo}
                            onChange={(value, { countryCode, dialCode }) => {
                              setFieldValue("CountryCodeContactNo", dialCode);
                              setFieldValue(
                                "ContactNo",
                                value.substring(dialCode.toString().length)
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={5}>
                          <Field
                            type="textarea"
                            label={"Staff App Whatsapp Default Messages"}
                            name="StaffDefaultMessages"
                            placeholder={
                              "Enter Staff App Whatsapp Default Messages"
                            }
                            component={CustomInput}
                            rows={4}
                            style={{ resize: "none" }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="text"
                            label={"Staff Calling No"}
                            name="StaffCallingNo"
                            placeholder={"Enter Staff Calling No"}
                            component={CustomPhoneInput}
                            countryCode={CountryCodeStaffCallingNo}
                            onChange={(value, { countryCode, dialCode }) => {
                              setFieldValue(
                                "CountryCodeStaffCallingNo",
                                dialCode
                              );
                              setFieldValue(
                                "StaffCallingNo",
                                value.substring(dialCode.toString().length)
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="text"
                            label={"Staff Whatsapp No"}
                            name="StaffWhatsappNo"
                            placeholder={"Enter Staff Whatsapp No"}
                            component={CustomPhoneInput}
                            countryCode={CountryCodeStaffWhatsappNo}
                            onChange={(value, { countryCode, dialCode }) => {
                              setFieldValue(
                                "CountryCodeStaffWhatsappNo",
                                dialCode
                              );
                              setFieldValue(
                                "StaffWhatsappNo",
                                value.substring(dialCode.toString().length)
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={5}>
                          <Field
                            type="textarea"
                            label={"Client App Whatsapp Default Messages"}
                            name="ClientDefaultMessages"
                            placeholder={
                              "Enter Client App Whatsapp Default Messages"
                            }
                            component={CustomInput}
                            rows={4}
                            style={{ resize: "none" }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="text"
                            label={"Client Calling No"}
                            name="ClientCallingNo"
                            placeholder={"Enter Client Calling No"}
                            component={CustomPhoneInput}
                            countryCode={CountryCodeClientCallingNo}
                            onChange={(value, { countryCode, dialCode }) => {
                              setFieldValue(
                                "CountryCodeClientCallingNo",
                                dialCode
                              );
                              setFieldValue(
                                "ClientCallingNo",
                                value.substring(dialCode.toString().length)
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            type="text"
                            label={"Client Whatsapp No"}
                            name="ClientWhatsappNo"
                            placeholder={"Enter Client Whatsapp No"}
                            component={CustomPhoneInput}
                            countryCode={CountryCodeClientWhatsappNo}
                            onChange={(value, { countryCode, dialCode }) => {
                              setFieldValue(
                                "CountryCodeClientWhatsappNo",
                                dialCode
                              );
                              setFieldValue(
                                "ClientWhatsappNo",
                                value.substring(dialCode.toString().length)
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={4}>
                          <Label>
                            Equipment Icon{" "}
                            <span className="text-danger">*</span>{" "}
                            <span style={{ color: "red", fontSize: "10px" }}>
                              (500 x 500)
                            </span>
                          </Label>
                          {/* <div
                          className="input-group mb-3"
                          style={{ zIndex: "0" }}
                        >
                          <div className="custom-file">
                            <input type="file" className="custom-file-input" />
                            <label className="custom-file-label">
                              Choose file
                            </label>
                          </div>
                        </div> */}
                          <div className="image-placeholder serviceIcon-placeholder">
                            <div className="avatar-edit">
                              <input
                                type="file"
                                // onChange={fileHandlerEquipment}
                                id="imageUpload"
                                // onClick={(event) =>
                                //   setEquipmentFile(event.target.value)
                                // }
                                onChange={uploadEquipmentIcon}
                                key={fileKeyLogoEquipment || ""}
                              />
                              <label htmlFor="imageUpload" name=""></label>
                            </div>
                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile"
                                  src={
                                    logoFileEquipment || logoEquipment
                                      ? logoFileEquipment
                                        ? URL.createObjectURL(logoFileEquipment)
                                        : logoEquipment
                                      : defaultIcon
                                  }
                                  alt={
                                    logoFileEquipment
                                      ? logoFileEquipment.name
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationLogoEquipment}
                          </span>
                        </Col>
                        <Col lg={4}>
                          <Label>
                            Health Checkup Icon{" "}
                            <span className="text-danger">*</span>{" "}
                            <span style={{ color: "red", fontSize: "10px" }}>
                              (500 x 500)
                            </span>
                          </Label>
                          {/* <div
                          className="input-group mb-3"
                          style={{ zIndex: "0" }}
                        >
                          <div className="custom-file">
                            <input type="file" className="custom-file-input" />
                            <label className="custom-file-label">
                              Choose file
                            </label>
                          </div>
                        </div> */}
                          <div className="image-placeholder serviceIcon-placeholder">
                            <div className="avatar-edit">
                              <input
                                type="file"
                                // onChange={fileHandlerHealthCheckup}
                                id="imageUpload2"
                                // onClick={(event) =>
                                //   setHealthCheckupFile(event.target.value)
                                // }
                                onChange={uploadHealthCheckupIcon}
                                key={fileKeyLogoHealthCheckup || ""}
                              />
                              <label htmlFor="imageUpload2" name=""></label>
                            </div>
                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile"
                                  src={
                                    logoFileHealthCheckup || logoHealthCheckup
                                      ? logoFileHealthCheckup
                                        ? URL.createObjectURL(
                                            logoFileHealthCheckup
                                          )
                                        : logoHealthCheckup
                                      : defaultIcon
                                  }
                                  alt={
                                    logoFileHealthCheckup
                                      ? logoFileHealthCheckup.name
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationLogoHealthCheckup}
                          </span>
                        </Col>
                      </Row>
                      <br></br>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.push("/");
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default Configruation;
