import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Spin } from "antd";
import swal from "sweetalert";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Row, Col, Modal, Accordion } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import PaginationButtons from "../../../Custom/Pagination";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";

import { get, post } from "../../../Helper/api_helper";
import moment from "moment";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import ModalPatientDetails from "../../../Custom/ModalPatientDetails";
import ModalOrderBookingAdvancesDetail from "../../../Custom/ModalOrderBookingAdvancesDetails";

import {
  GET_CompanyDropDownList,
  POST_AdminOrderBookingAdvanceReturnList,
  POST_GetPatientDetailByID,
} from "../../../Helper/url_helper";

const OrderBookingAdvanceReturnList = () => {
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [patientDetailModal, setPatientDetailModal] = useState(false);

  const [recordCount, setRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [getOrderBookingAdvancesReturnList, setOrderBookingAdvancesReturnList] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);

  var AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [searchValue, setSearchValue] = useState("");
  let updatedSearchValue = searchValue;

  const [orderBookingID, setOrderBookingID] = useState("");
  const [patientID, setPatientID] = useState("");

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  const [advanceDetailModal, setAdvanceDetailModal] = useState(false);
  const [sendNotificationlModal, setSendNotificationModal] = useState(false);

  const validationSchema = Yup.object().shape({});

  const validationSchemaNotificationMessage = Yup.object().shape({
    NotificationMessage: Yup.string().required(
      "Notification Message is required field"
    ),
  });

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      // Search: searchValue,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyID: CompanyID,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",
    };

    await post(POST_AdminOrderBookingAdvanceReturnList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setOrderBookingAdvancesReturnList(
          response?.AdminOrderBookingAdvanceReturnList
        );
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (
          page > 1 &&
          response?.AdminOrderBookingAdvanceReturnList?.length === 0
        ) {
          setCurrentPage(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    loadCompanyDDL();
    getData();
  }, []);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Transaction" activeMenu="Advance Return" />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Advance Return"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          <Row>
                            {AuthUser?.CompanyType === 1 &&
                            AuthUser?.UserType == 1 ? (
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 30 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-lg-block">
                <Link
                  to="/add-order-booking-advance-return"
                  className="btn btn-primary btn-xs btn-rounded"
                >
                  + Add Advance Return
                </Link>
              </div>
            ) : (
              ""
            )}

            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  //setSearchValue(e.target.value);
                  updatedSearchValue = e.target.value;
                  setSearchValue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div id="order_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Order No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "35%" }}
                        >
                          Patient Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Advance Return Amount: activate to sort column ascending"
                          style={{ width: "20%", textAlign: "right" }}
                        >
                          Advance Return
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Advance Return Date: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Return Date
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        getOrderBookingAdvancesReturnList?.map((element, i) => (
                          <tr role="row" className="odd">
                            <td>{element.OrderBookingNo}</td>
                            <td>{element.PatientName}</td>
                            {/* <td>{element.PatientMobileNo}</td> */}
                            <td style={{ textAlign: "right" }}>
                              {element.AdvanceReturnAmount}
                            </td>
                            <td>{element.AdvancerReturnDate}</td>

                            <td>
                              <div className="d-flex">
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Advance Detail"
                                  onClick={() => {
                                    setOrderBookingID(
                                      element.OrderBookingIDEncrypt
                                    );
                                    setAdvanceDetailModal(true);
                                  }}
                                  // onClick={() => {
                                  //   setAdvanceDetailModal(true);
                                  // }}
                                >
                                  <i
                                    className="fa fa-eye"
                                    style={{ color: "#333b3d" }}
                                  ></i>
                                </Link>
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Patient Detail"
                                  onClick={() => {
                                    setPatientID(element.PatientIDEncrypt);
                                    setPatientDetailModal(true);
                                  }}
                                  // onClick={() => {
                                  //   setPatientDetailModal(true);
                                  // }}
                                >
                                  <i class="fa fa-wheelchair"></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Order Detail"
                                  onClick={() => {
                                    setOrderBookingID(
                                      element.OrderBookingIDEncrypt
                                    );
                                    setOrderDetailModal(true);
                                  }}

                                  // onClick={() => {
                                  //   setOrderDetailModal(true);
                                  // }}
                                >
                                  <i className="fa fa-info"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-1 ml-1">
                              {"No record found..."}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            className="modal fade"
            show={advanceDetailModal}
            onHide={setAdvanceDetailModal}
            size="lg"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Advance Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setAdvanceDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalOrderBookingAdvancesDetail
                orderBookingID={orderBookingID}
                setAdvanceDetailModal={setAdvanceDetailModal}
              ></ModalOrderBookingAdvancesDetail>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={patientDetailModal}
            onHide={setPatientDetailModal}
            size="lg"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Patient Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setPatientDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ModalPatientDetails
                patientID={patientID}
                setPatientDetailModal={setPatientDetailModal}
              ></ModalPatientDetails>
            </div>
          </Modal>

          <Modal
            className="modal fade"
            show={orderDetailModal}
            onHide={setOrderDetailModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Order Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setOrderDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>

            <div className="modal-body">
              <ModalOrderBookingDetail
                orderBookingID={orderBookingID}
                setOrderDetailModal={setOrderDetailModal}
              ></ModalOrderBookingDetail>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default OrderBookingAdvanceReturnList;
