import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { POST_GetPatientList } from "../Helper/url_helper";
import { Spin } from "antd";
import PaginationButtons from "./Pagination";
import { post } from "../Helper/api_helper";
const ModalPatientlist = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [PatientDataList, setPatientDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [searchValue, setSearchValue] = useState("");
  let updatedSearchValue = searchValue;

  const getData = async (page = 1, sizePerPage = 10) => {
    setIsLoading(true);
    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      // Search: searchValue,
      Search: updatedSearchValue,
      Sorting: "",
      CityID: 0,
    };

    await post(POST_GetPatientList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setPatientDataList(response?.PatientsList);
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (page > 1 && response?.PatientsList?.length === 0) {
          setCurrentPage(page - 1);
          getData(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <i className="flaticon-cancel-12 close"></i>
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                {AuthUser?.UserType == 1 ||
                (AuthUser?.UserType == 2 &&
                  UserForms?.filter(
                    (item) => item.FormID == 4 && item.AddAction
                  ).length > 0) ? (
                  <div className="mr-auto d-lg-block">
                    <Link
                      to="/add-patient"
                      className="btn btn-primary btn-xs btn-rounded"
                      onClick={() => {
                        localStorage.setItem("AddPatientFromOrder", true);
                      }}
                    >
                      + Add Patient
                    </Link>
                  </div>
                ) : (
                  ""
                )}

                <div className="input-group search-area ml-auto d-inline-flex mr-3 border">
                  <input
                    type="text"
                    style={{ height: "36px" }}
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      // setSearchValue(e.target.value);
                      updatedSearchValue = e.target.value;
                      setSearchValue(updatedSearchValue);
                      getData();
                    }}
                  />
                  <div className="input-group-append">
                    <button type="button" className="input-group-text">
                      <i className="flaticon-381-search-2" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="table-responsive">
                    <div
                      id="patient_list"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                        role="grid"
                        aria-describedby="example5_info"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example5"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Patient No.: activate to sort column ascending"
                              style={{ width: "15%" }}
                            >
                              Patient No.
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example5"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Patient Name: activate to sort column ascending"
                              style={{ width: "25%" }}
                            >
                              Patient Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example5"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Client Name: activate to sort column ascending"
                              style={{ width: "20%" }}
                            >
                              Client Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example5"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Mobile Number: activate to sort column ascending"
                              style={{ width: "15%" }}
                            >
                              Mobile Number
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example5"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Date of Birth: activate to sort column ascending"
                              style={{ width: "10%" }}
                            >
                              Date of Birth
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example5"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Gender: activate to sort column ascending"
                              style={{ width: "10%" }}
                            >
                              Gender
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example5"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Select: activate to sort column ascending"
                              style={{ width: "5%" }}
                            >
                              Select
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recordCount > 0 ? (
                            PatientDataList?.map((element, index) => (
                              <tr role="row" className="odd">
                                <td>{element.PatientNo}</td>
                                <td>{element.PatientName}</td>
                                <td>{element.ClientName}</td>
                                <td>{element.MobileNo}</td>
                                <td>{element.DateOfBirth}</td>
                                <td>{element.Gender}</td>
                                <td>
                                  <div className="mr-auto d-lg-block">
                                    <Link
                                      to={`/add-order-booking/${window.btoa(
                                        element.PatientIDEncrypted
                                      )}`}
                                      style={{ float: "right" }}
                                      className="btn btn-primary btn-xxs btn-rounded"
                                    >
                                      Select
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="mt-3 ml-3 mb-3">
                                  {"No Record Found"}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div
                          className="dataTables_info"
                          id="example5_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing{" "}
                          {recordCount > 0
                            ? (currentPage - 1) * currentSizePerPage + 1
                            : 0}{" "}
                          to{" "}
                          {recordCount > currentPage * currentSizePerPage
                            ? currentPage * currentSizePerPage
                            : recordCount}{" "}
                          of {recordCount} entries
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers d-flex  justify-content-center align-items-center pb-3">
                          <PaginationButtons
                            totalPages={recordCount / currentSizePerPage}
                            currentPage={currentPage}
                            getData={getData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default ModalPatientlist;
