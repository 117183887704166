import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Label, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  POST_AdminHealthCheckupServiceScheduleDetail,
  POST_AdminPackageHealthCheckupInsert,
  POST_GetClientStaffListByServiceID,
} from "../../../Helper/url_helper";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import ModalPatientDetails from "../../../Custom/ModalPatientDetails";
import moment from "moment";
import { post } from "../../../Helper/api_helper";

const AddHealthCheckupSchedule = () => {
  const { OrderBookingIDEncryptParam, HealthCheckupOrPackageIDEncryptParam } =
    useParams();
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const [isLoading, setIsLoading] = useState(false);
  const [patientDetailModel, setPatientDetailModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);

  const [orderBookingID, setOrderBookingID] = useState("");
  const [patientID, setPatientID] = useState("");
  const [isOngoing, setIsOngoing] = useState(false);

  const [isOnceCalled, setIsOnceCalled] = useState(false);

  const validationSchema = Yup.object().shape({});

  const initislListOfService = {
    ServiceName: "",
    ScheduleDateTime: "",
    ServiceHourIDEncrypt: "",
    ScheduledPunchIn: new Date(),
    ScheduledPunchOut: "",
    ScheduledTime: "",
    StaffNameID: "",
    ServiceHoursOptions: [],
    ServiceStaffOptions: [],
    ServiceIDEncrypt: "",
    ReferenceIDFEncrypt: "",
    RotationalStaffNameID: "",
    HourlyServiceType: 0,
    OrderBookingPathologyReportScheduleIDEncrypted: "",
  };

  const initislListOfRadiologyReport = {
    OrderBookingPathologyReportScheduleIDEncrypted: "",
    ReferenceIDFEncrypt: "",
    RadiologyReportIDEncrypt: "",
    RadiologyReportName: "",
    ScheduleDateTime: "",
    ScheduledTime: new Date(),
    ReferenceID: "",
    RadiologyReportIDEncrypt: "",
  };

  const initislListOfPathologyReport = {
    OrderBookingPathologyReportScheduleIDEncrypted: "",
    ReferenceIDFEncrypt: "",
    PathologyReportIDEncrypt: "",
    PathologyReportName: "",
    ScheduleDateTime: "",
    ReferenceID: "",
    PathologyReportIDEncrypt: "",
    ScheduledTime: new Date(),
  };

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      HealthCheckUpName: "",
      MobileNo: "",
      OrderBookingNo: "",
      PatientName: "",
      PatientNo: "",
      ListOfSevices: [initislListOfService],
      ListOfRadiologyReport: [initislListOfRadiologyReport],
      ListOfPathologyReport: [initislListOfPathologyReport],
      Latitude: "",
      Longitude: "",
    },
    validateOnBlur: false,
  });
  const {
    setValues,
    setFieldValue,
    values: {
      HealthCheckUpName,
      MobileNo,
      OrderBookingNo,
      PatientName,
      PatientNo,
      ListOfSevices,
      ListOfRadiologyReport,
      ListOfPathologyReport,
      Latitude,
      Longitude,
    },
  } = formik;

  useEffect(() => {
    setIsLoading(true);
    post(POST_AdminHealthCheckupServiceScheduleDetail, {
      OrderBookingIDEncrypt: window.atob(OrderBookingIDEncryptParam),
      HealthCheckupIDEncrypt: window.atob(HealthCheckupOrPackageIDEncryptParam),
      ScheduleTypeID: 6,
    }).then((res) => {
      setIsLoading(true);
      if (res.IsSuccess) {
        setIsOngoing(res.IsOngoing);
        setValues({
          HealthCheckUpName: res.HealthCheckupName,
          MobileNo: res.MobileNo,
          OrderBookingNo: res.OrderBookingNo,
          PatientName: res.PatientName,
          PatientNo: res.PatientNo,
          Latitude: res.Latitude,
          Longitude: res.Longitude,
          ListOfSevices:
            res.AdminHealthCheckupServiceScheduleList?.length > 0
              ? res.AdminHealthCheckupServiceScheduleList.map((x, i) => {
                  return {
                    ...x,
                    HourlyServiceType: x.ServiceHourIDEncrypt
                      ? x.AdminServiceHoursDDLList?.filter(
                          (y) =>
                            y.ServiceHourIDEncrypt === x.ServiceHourIDEncrypt
                        )[0]?.HourlyServiceType
                      : 0,
                    ServiceName: x.ServiceName,
                    StaffNameID: x.StaffIDEncrypt,
                    ReferenceIDFEncrypt: x.ReferenceIDFEncrypt,
                    ServiceHourIDEncrypt: x.ServiceHourIDEncrypt,
                    ScheduledPunchIn: x.ScheduledPunchIn
                      ? moment(
                          `2023-03-21 ${x.ScheduledPunchIn.trim()}`
                        ).format("DD-MMM-yyyy hh:mm A")
                      : new Date(),
                    ScheduleDateTime: x.ScheduleDateTime
                      ? new Date(x.ScheduleDateTime)
                      : "",
                    ServiceHoursOptions: x.AdminServiceHoursDDLList.map(
                      (item) => ({
                        label: item.HoursCaption,
                        value: item.ServiceHourIDEncrypt,
                        Hours: item.Hours,
                        HourlyServiceType: item.HourlyServiceType,
                      })
                    ),
                    ServiceIDEncrypt: x.ServiceIDEncrypt,
                    RotationalStaffNameID: x.RotationalStaffIDEncrypt,
                    ServiceStaffOptions: [],
                    ScheduledPunchOut: x.ScheduledPunchOut
                      ? x.ScheduledPunchOut
                      : new Date(),
                  };
                })
              : [],
          ListOfRadiologyReport:
            res.AdminHealthCheckupInclusionRadiologyReportList?.length > 0
              ? res.AdminHealthCheckupInclusionRadiologyReportList.map((x) => {
                  return {
                    ...x,
                    ReferenceIDFEncrypt: x.ReferenceIDFEncrypt,
                    RadiologyReportIDEncrypt: x.RadiologyReportIDEncrypt,
                    ScheduledTime: x.ScheduledTime
                      ? moment(`2023-03-21 ${x.ScheduledTime.trim()}`).format(
                          "DD-MMM-yyyy hh:mm A"
                        )
                      : new Date(),
                    ScheduleDateTime: x.ScheduleDate
                      ? new Date(x.ScheduleDate)
                      : "",
                  };
                })
              : [],
          ListOfPathologyReport:
            res.AdminHealthCheckupInclusionPathologyReportList?.length > 0
              ? res.AdminHealthCheckupInclusionPathologyReportList.map((x) => {
                  return {
                    ...x,
                    ReferenceIDFEncrypt: x.ReferenceIDFEncrypt,
                    PathologyReportIDEncrypt: x.PathologyReportIDEncrypt,
                    ScheduledTime: x.ScheduledTime
                      ? moment(`2023-03-21 ${x.ScheduledTime.trim()}`).format(
                          "DD-MMM-yyyy hh:mm A"
                        )
                      : new Date(),
                    ScheduleDateTime: x.ScheduleDate
                      ? new Date(x.ScheduleDate)
                      : "",
                  };
                })
              : [],
        });
        setOrderBookingID(res.OrderBookingIDEncrypt);
        setPatientID(res.PatientIDEncrypt);
        setIsLoading(false);
        setIsOnceCalled(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isOnceCalled) {
      ListOfSevices?.map((x, i) => {
        getStaffList(
          x,
          x.ServiceHourIDEncrypt,
          x.ScheduledPunchIn,
          x.ScheduleDateTime,
          i
        );
      });
    }
  }, [isOnceCalled]);

  const getStaffList = async (x, HourId, Time, Date, i) => {
    if (
      (x.ChargeType === 1 && HourId === "") ||
      Time === "" ||
      // x.ScheduleDateTime === "" ||
      Date === ""
    ) {
      setFieldValue(`ListOfSevices.[${i}].ServiceStaffOptions`, []);
    } else {
      const Data = {
        CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
        CurrentPage: 1,
        PageSize: 1000,
        Search: "",
        Sorting: "",
        CityName: "",
        ServiceIDEncrypt: x.ServiceIDEncrypt,
        FromDate: `${moment(Date)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        ToDate: `${moment(Date)
          .format("YYYY-MM-DD")
          .replace(/^\-/, "")} 00:00:00.000`,
        ServiceHourIDEncrypt: x.ChargeType === 1 ? HourId : "",
        FromTime: moment(Time).format("h:mm A"),
        Latitude: Latitude,
        Longitude: Longitude,
      };

      await post(POST_GetClientStaffListByServiceID, Data)
        .then((res) => {
          if (res.IsSuccess) {
            let NewOptions = [];

            res.ClientServiceWiseStaffList.map((item) =>
              NewOptions.push({
                label: item.StaffName,
                value: item.StaffIDEncrypt,
                staffName: item.StaffName,
                staffRate: item.SpecialRate,
                startTime: item.StartTime,
                finishTime: item.FinishTime,
                isBooked: item.IsBooked,
              })
            );

            setFieldValue(
              `ListOfSevices.[${i}].ServiceStaffOptions`,
              NewOptions
            );
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (
      ListOfSevices.filter(
        (x) => x.RotationalStaffNameID === "" && x.HourlyServiceType === 2
      ).length > 0
    ) {
      swal({
        text: "Fill the details of Inclusion Service Schedule.",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    if (
      ListOfSevices.filter(
        (x) =>
          x.ScheduleDateTime === "" ||
          x.ServiceHourIDEncrypt === "" ||
          x.StaffNameID === ""
      ).length > 0
    ) {
      swal({
        text: "Fill the details of Inclusion Service Schedule.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if (
      ListOfRadiologyReport.filter(
        (x) => x.ScheduleDateTime === "" || x.ScheduledTime === ""
      ).length > 0
    ) {
      swal({
        text: "Fill the details of Inclusion Radilogy Report Schedule.",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    if (
      ListOfPathologyReport.filter(
        (x) => x.ScheduleDateTime === "" || x.ScheduledTime === ""
      ).length > 0
    ) {
      swal({
        text: "Fill the details of Inclusion Pathology Report Schedule.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    const data = {
      OrderBookingIDEncrypt: window.atob(OrderBookingIDEncryptParam),
      ReferenceType: 6,
      AdminHealthCheckUpInclusionServicesModel: ListOfSevices.map((x) => {
        return {
          OrderBookingServiceScheduleIDEncrypted: "",
          StaffIDEncrypted: x.StaffNameID,
          ReferenceIDFEncrypted: x.ReferenceIDFEncrypt,
          ServiceIDEncrypted: x.ServiceIDEncrypt,
          RotationalStaffIDEncrypted: x.RotationalStaffNameID,
          ReferenceType: 6,
          ServiceHourIDEncrypted: x.ServiceHourIDEncrypt,
          ScheduledPunchIn: `${moment(x.ScheduleDateTime)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} ${moment(x.ScheduledPunchIn).format(
            "h:mm A"
          )}`,
          ScheduledPunchOut: `${moment(x.ScheduleDateTime)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} ${x.ScheduledPunchOut}`,
          ScheduleDateTime: `${moment(x.ScheduleDateTime).format(
            "YYYY-MM-DD h:mm A"
          )}`,
          StaffPunchIn: "",
          StaffPunchOut: "",
          ServiceRemarks: "",
        };
      }),
      AdminHealthCheckUpInclusionRadiologyReportModel:
        ListOfRadiologyReport.map((x) => {
          return {
            OrderBookingRadiologyReportScheduleIDEncrypted: "",
            ReferenceIDFEncrypted: x.ReferenceIDFEncrypt,
            RadiologyReportIDEncrypted: x.RadiologyReportIDEncrypt,
            ReferenceType: 6,
            ScheduleDateTime: `${moment(x.ScheduleDateTime)
              .format("YYYY-MM-DD")
              .replace(/^\-/, "")} ${moment(x.ScheduledTime).format("h:mm A")}`,
            BookingRemarks: "",
          };
        }),
      AdminHealthCheckUpInclusionPathologyReportModel:
        ListOfPathologyReport.map((x) => {
          return {
            OrderBookingPathologyReportScheduleIDEncrypted: "",
            ReferenceIDFEncrypted: x.ReferenceIDFEncrypt,
            PathologyReportIDEncrypted: x.PathologyReportIDEncrypt,
            ReferenceType: 6,
            ScheduleDateTime: `${moment(x.ScheduleDateTime)
              .format("YYYY-MM-DD")
              .replace(/^\-/, "")} ${moment(x.ScheduledTime).format("h:mm A")}`,
            BookingRemarks: "",
          };
        }),
    };

    post(POST_AdminPackageHealthCheckupInsert, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/schedule-package-and-healthcheckup");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu="Health CheckUp Schedule"
          motherMenu={
            <Link to="/schedule-package-and-healthcheckup">
              Schedule Package & Health Checkup
            </Link>
          }
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Order No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{OrderBookingNo}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Patient Name</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{PatientName}</Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Patient No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{PatientNo}</Col>
                          </Row>
                        </Col>

                        <Col lg={6}>
                          <Row>
                            <Col lg={4}>
                              <b>Mobile No.</b>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={7}>{MobileNo}</Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ textAlign: "end" }}>
                          <div
                            onClick={() => {
                              setOrderDetailModal(true);
                            }}
                            className="btn btn-light btn-xxs btn-rounded mt-4"
                          >
                            Order Detail
                          </div>
                          &nbsp;&nbsp;
                          <div
                            onClick={() => {
                              setPatientDetailModal(true);
                            }}
                            className="btn btn-light btn-xxs btn-rounded mt-4"
                          >
                            Patient Detail
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <Row>
                            <Col lg={3}>
                              <h6>
                                <b>Health CheckUp Name</b>
                              </h6>
                            </Col>
                            <Col lg={1}>:</Col>
                            <Col lg={8}>{HealthCheckUpName}</Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      {ListOfSevices.length > 0 ? (
                        <>
                          <h6 className="mb-3">
                            Health CheckUp Inclusion Services
                          </h6>
                          {ListOfSevices?.map((element, index) => (
                            <>
                              <Row className="mt-3">
                                <Col lg={4}>
                                  <Field
                                    label={"Service Name"}
                                    name={`ListOfSevices.[${index}].ServiceName`}
                                    placeholder={"Select Service Name"}
                                    component={CustomInput}
                                    style={{ background: "#e9ecef" }}
                                    disabled={true}
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={2}>
                                  <Field
                                    label={"Service Date"}
                                    name={`ListOfSevices.[${index}].ScheduleDateTime`}
                                    placeholder={"Service Date"}
                                    component={CustomFlatpickr}
                                    onChange={(date) => {
                                      if (date) {
                                        setFieldValue(
                                          `ListOfSevices.[${index}].ScheduleDateTime`,
                                          new Date(date)
                                        );
                                        getStaffList(
                                          element,
                                          element.ServiceHourIDEncrypt,
                                          element.ScheduledPunchIn,
                                          date ? date : "",
                                          index
                                        );
                                      } else {
                                        setFieldValue(
                                          `ListOfSevices.[${index}].ScheduleDateTime`,
                                          ""
                                        );
                                        setFieldValue(
                                          `ListOfSevices.[${index}].ServiceStaffOptions`,
                                          []
                                        );
                                      }
                                    }}
                                    disabled={isOngoing}
                                    options={{
                                      // altInput: true,
                                      altFormat: "j-M-Y",
                                      // dateFormat: "d-m-Y",
                                      dateFormat: "d-M-Y",
                                    }}
                                    required
                                  />
                                </Col>
                                <Col lg={2}>
                                  <Label>
                                    Time
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                      autoOk
                                      name={`ListOfSevices.[${index}].ScheduledPunchIn`}
                                      placeholder={"Select Start Time"}
                                      value={element.ScheduledPunchIn}
                                      ampmInClock={true}
                                      onChange={(newValue) => {
                                        if (newValue) {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ScheduledPunchIn`,
                                            new Date(newValue)
                                          );
                                          getStaffList(
                                            element,
                                            element.ServiceHourIDEncrypt,
                                            newValue ? new Date(newValue) : "",
                                            element.ScheduleDateTime,
                                            index
                                          );
                                        } else {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ScheduledPunchIn`,
                                            ""
                                          );
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceStaffOptions`,
                                            []
                                          );
                                        }
                                      }}
                                      disabled={isOngoing}
                                      format={"h:mm a"}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Col>
                                {element.ChargeType === 1 ? (
                                  <Col lg={4}>
                                    <Field
                                      label={"Service Hours"}
                                      name={`ListOfSevices.[${index}].ServiceHourIDEncrypt`}
                                      placeholder={"Select Service Hours"}
                                      options={element.ServiceHoursOptions}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceHourIDEncrypt`,
                                            event?.value
                                          );
                                          setFieldValue(
                                            `ListOfSevices.[${index}].HourlyServiceType`,
                                            event?.HourlyServiceType
                                          );
                                          getStaffList(
                                            element,
                                            event?.value,
                                            element.ScheduledPunchIn,
                                            element.ScheduleDateTime,
                                            index
                                          );
                                        } else {
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceHourIDEncrypt`,
                                            ""
                                          );
                                          setFieldValue(
                                            `ListOfSevices.[${index}].HourlyServiceType`,
                                            0
                                          );
                                          setFieldValue(
                                            `ListOfSevices.[${index}].ServiceStaffOptions`,
                                            []
                                          );
                                        }
                                      }}
                                      component={CustomSelect}
                                      isDisabled={isOngoing}
                                      required
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                <Col lg={4}>
                                  <Field
                                    //label={"Staff Name"}
                                    label={`Staff Name${
                                      element.HourlyServiceType === 2
                                        ? " (Morning)"
                                        : ""
                                    }`}
                                    name={`ListOfSevices.[${index}].StaffNameID`}
                                    placeholder={"Select Staff Name"}
                                    options={element.ServiceStaffOptions}
                                    onChange={(e) => {
                                      if (e) {
                                        setFieldValue(
                                          `ListOfSevices.[${index}].StaffNameID`,
                                          e?.value
                                        );
                                        setFieldValue(
                                          `ListOfSevices.[${index}].ScheduledPunchOut`,
                                          e?.finishTime ? e?.finishTime : ""
                                        );
                                      } else {
                                        setFieldValue(
                                          `ListOfSevices.[${index}].StaffNameID`,
                                          ""
                                        );
                                        setFieldValue(
                                          `ListOfSevices.[${index}].ScheduledPunchOut`,
                                          new Date()
                                        );
                                      }
                                    }}
                                    component={CustomSelect}
                                    isDisabled={isOngoing}
                                    required
                                  />
                                </Col>
                                {element.HourlyServiceType === 2 ? (
                                  <Col lg={4}>
                                    <Field
                                      label={"Rotational Staff Name (Evening)"}
                                      name={`ListOfSevices.[${index}].RotationalStaffNameID`}
                                      placeholder={"Select Staff Name"}
                                      options={
                                        element.ServiceStaffOptions
                                          ? element.ServiceStaffOptions.filter(
                                              (x) =>
                                                x.value !== element.StaffNameID
                                            )
                                          : element.ServiceStaffOptions
                                      }
                                      component={CustomSelect}
                                      isDisabled={isOngoing}
                                      required
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </>
                          ))}
                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}
                      {ListOfRadiologyReport.length > 0 ? (
                        <>
                          <h6 className="mb-3">
                            Health CheckUp Inclusion Radiology Report
                          </h6>
                          {ListOfRadiologyReport?.map((element, index) => (
                            <Row className="mt-3">
                              <Col lg={4}>
                                <Field
                                  label={"Radiology Report Name"}
                                  name={`ListOfRadiologyReport.[${index}].RadiologyReportName`}
                                  placeholder={"Enter Radiology Report Name"}
                                  component={CustomInput}
                                  value={element.RadiologyReportName}
                                  style={{ background: "#e9ecef" }}
                                  disabled={true}
                                  required
                                />
                              </Col>

                              <Col lg={2}>
                                <Field
                                  label={"Date"}
                                  name={`ListOfRadiologyReport.[${index}].ScheduleDateTime`}
                                  placeholder={"From Date"}
                                  component={CustomFlatpickr}
                                  onChange={(date) => {
                                    if (date) {
                                      setFieldValue(
                                        `ListOfRadiologyReport.[${index}].ScheduleDateTime`,
                                        new Date(date)
                                      );
                                    } else {
                                      setFieldValue(
                                        `ListOfRadiologyReport.[${index}].ScheduleDateTime`,
                                        ""
                                      );
                                    }
                                  }}
                                  disabled={isOngoing}
                                  options={{
                                    // altInput: true,
                                    altFormat: "j-M-Y",
                                    // dateFormat: "d-m-Y",
                                    dateFormat: "d-M-Y",
                                  }}
                                  // disabled={isOngoing.toString()}
                                  required
                                />
                              </Col>
                              <Col lg={2}>
                                <Label>
                                  Time
                                  <span className="text-danger">*</span>
                                </Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <TimePicker
                                    autoOk
                                    name={`ListOfRadiologyReport.[${index}].ScheduledTime`}
                                    placeholder={"Select Start Time"}
                                    value={element.ScheduledTime}
                                    ampmInClock={true}
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        setFieldValue(
                                          `ListOfRadiologyReport.[${index}].ScheduledTime`,
                                          new Date(newValue)
                                        );
                                      } else {
                                        setFieldValue(
                                          `ListOfRadiologyReport.[${index}].ScheduledTime`,
                                          ""
                                        );
                                      }
                                    }}
                                    format={"h:mm a"}
                                    disabled={isOngoing}
                                  />
                                </MuiPickersUtilsProvider>
                              </Col>
                            </Row>
                          ))}
                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}
                      {ListOfPathologyReport.length > 0 ? (
                        <>
                          <h6 className="mb-3">
                            Health CheckUp Inclusion Pathology Report
                          </h6>

                          {ListOfPathologyReport?.map((element, index) => (
                            <Row className="mt-3">
                              <Col lg={4}>
                                <Field
                                  label={"Pathology Report Name"}
                                  name={`ListOfPathologyReport.[${index}].PathologyReportName`}
                                  placeholder={"Enter Pathology Report Name"}
                                  component={CustomInput}
                                  value={element.PathologyReportName}
                                  style={{ background: "#e9ecef" }}
                                  disabled={true}
                                  required
                                />
                              </Col>

                              <Col lg={2}>
                                <Field
                                  label={"Date"}
                                  name={`ListOfPathologyReport.[${index}].ScheduleDateTime`}
                                  placeholder={"From Date"}
                                  component={CustomFlatpickr}
                                  onChange={(date) => {
                                    if (date) {
                                      setFieldValue(
                                        `ListOfPathologyReport.[${index}].ScheduleDateTime`,
                                        new Date(date)
                                      );
                                    } else {
                                      setFieldValue(
                                        `ListOfPathologyReport.[${index}].ScheduleDateTime`,
                                        ""
                                      );
                                    }
                                  }}
                                  disabled={isOngoing}
                                  options={{
                                    // altInput: true,
                                    altFormat: "j-M-Y",
                                    // dateFormat: "d-m-Y",
                                    dateFormat: "d-M-Y",
                                  }}
                                  // disabled={isOngoing.toString()}
                                  required
                                />
                              </Col>
                              <Col lg={2}>
                                <Label>
                                  Time
                                  <span className="text-danger">*</span>
                                </Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <TimePicker
                                    autoOk
                                    name={`ListOfPathologyReport.[${index}].ScheduledTime`}
                                    placeholder={"Select Start Time"}
                                    value={element.ScheduledTime}
                                    ampmInClock={true}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        `ListOfPathologyReport.[${index}].ScheduledTime`,
                                        new Date(newValue)
                                      );
                                    }}
                                    disabled={isOngoing}
                                    format={"h:mm a"}
                                  />
                                </MuiPickersUtilsProvider>
                              </Col>
                            </Row>
                          ))}

                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}

                      <Row className="mt-3">
                        <Col>
                          {isOngoing ? (
                            ""
                          ) : (
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                          )}{" "}
                          <button
                            type="button"
                            onClick={(e) => {
                              history.goBack();
                            }}
                            className="btn btn-light"
                          >
                            {" "}
                            <i className="flaticon-delete-1"></i> Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal fade"
          show={orderDetailModal}
          onHide={setOrderDetailModal}
          size="xl"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Order Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setOrderDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalOrderBookingDetail
              orderBookingID={orderBookingID}
              setOrderDetailModal={setOrderDetailModal}
            ></ModalOrderBookingDetail>
          </div>
        </Modal>

        <Modal
          className="modal fade"
          show={patientDetailModel}
          onHide={setPatientDetailModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Patient Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setPatientDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalPatientDetails
              patientID={patientID}
              setPatientDetailModal={setPatientDetailModal}
            ></ModalPatientDetails>
          </div>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default AddHealthCheckupSchedule;
