import React, { Fragment, useEffect, useState } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import { Spin } from "antd";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_PatientMasterReportInExcelDownload,
  POST_GetAreaListOfExstingPatientAndStaff,
} from "../../../Helper/url_helper";

const PatientMasterReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [areaReportOptions, setAreaReportOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchemaReports = Yup.object().shape({});

  const formik = useFormik({
    validationSchema: validationSchemaReports,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      AreaID: 0,
      CompanyID: props.companyID,
      Report: props.reportID,
      FromDate: "",
      ToDate: "",
    },
    validateOnBlur: false,
  });

  const {
    values: { CompanyID },
  } = formik;

  useEffect(() => {
    loadAreaDDL();
  }, []);

  // const areaReportOptions = [
  //   { label: "All", value: 0 },
  //   { label: "Bapunagar", value: 1 },
  //   { label: "Thaltej", value: 2 },
  //   { label: "Juhapura", value: 3 },
  //   { label: "South Bopal", value: 4 },
  // ];

  const loadAreaDDL = async () => {
    //await post(POST_GetAreaListOfExstingPatientAndStaff)
    await post(POST_GetAreaListOfExstingPatientAndStaff, {
      CompanyIDEncrypted: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setAreaReportOptions(
            res.AreaList.map((item) => ({
              label: item.AreaName,
              value: item.AreaID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      AreaID: values.AreaID,
      FromDate: values.FromDate,
      ToDate: values.ToDate,
      CompanyIDEncrypt: CompanyID,
    };

    //console.log("Data", Data);

    let response = await post(POST_PatientMasterReportInExcelDownload, Data);
    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} style={{ textAlign: "left", fontSize: "15px" }}>
                <div style={{ color: "black" }}>Filter</div> <br></br>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Field
                  label={"Area"}
                  name="AreaID"
                  options={areaReportOptions}
                  placeholder={"Select Area"}
                  component={CustomSelect}
                />
              </Col>
            </Row>

            <hr></hr>
            <Row className="mt-3">
              <Col lg={12}>
                <button type="submit" className="btn mr-2 btn-primary btn-sm">
                  View Report
                </button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Spin>
    </Fragment>
  );
};

export default PatientMasterReport;
