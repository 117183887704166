import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../Helper/api_helper";
import PageTitle from "../../../layouts/PageTitle";
import {
  POST_GSTPlanList,
  POST_GSTPlanDelete,
  POST_GSTPlanSetActiveInActive,
} from "../../../Helper/url_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";
const GSTPlanList = () => {
  const [data, setData] = useState(
    document.querySelectorAll("#GST_Plan_List tbody tr")
  );
  const sort = 10;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#GST_Plan_List tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [GSTPlanList, setGSTPlanList] = useState([]);
  // const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  // const handleOnChangeSearch = (e) => {
  //   setSearch(e.target.value);
  // };

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   getData();
  // }, [search]);

  const getData = async (page = 1) => {
    setIsLoading(true);
    let response = await post(POST_GSTPlanList);
    setIsLoading(false);
    setGSTPlanList(response.GSTPlans);
    setCurrentPage(page);
    setData(document.querySelectorAll("#GST_Plan_List tbody tr"));
  };

  const delete_GSTPlan = (gID) => {
    swal({
      title: "Are you sure you want to delete the GST plan?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmDelete) => {
      if (confirmDelete) {
        delete_GSTPlanConfirm(gID);
      }
    });
  };

  const delete_GSTPlanConfirm = async (gID) => {
    setIsLoading(true);
    let response = await post(POST_GSTPlanDelete, {
      EncryptedGSTPlanID: gID,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: "Deleted",
        text: "Record has been deleted.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  const ActiveInactive_GSTPlan = async (gID, gStatus) => {
    swal({
      title: `Are you sure you want to ${
        gStatus ? "inactive" : "active"
      } the GST plan?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmStatus) => {
      if (confirmStatus) {
        ActiveInactive_GSTPlanConfirm(gID, gStatus);
      }
    });
  };

  const ActiveInactive_GSTPlanConfirm = async (gID, gStatus) => {
    setIsLoading(true);
    let response = await post(POST_GSTPlanSetActiveInActive, {
      EncryptedGSTPlanID: gID,
      IsActive: !gStatus,
      EncryptedModifiedBy: objLogin.LoginIDEncrypt,
    });
    if (response.IsSuccess) {
      swal({
        title: gStatus ? "Inactivated" : "Activated",
        text: gStatus
          ? "Record has been Inactivated."
          : "Record has been Activated.",
        icon: "success",
        button: "OK",
      });
      getData(currentPage);
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Master" activeMenu="GST Plans" />

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="mr-auto d-lg-block">
              <Link
                to="/add-GST-Plan"
                className="btn btn-primary btn-xs btn-rounded"
              >
                + Add GST Plan
              </Link>
            </div>
            {/* <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={search}
                onChange={handleOnChangeSearch}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="GST_Plan_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="GST Plan Name: activate to sort column ascending"
                          style={{ width: "40%" }}
                        >
                          GST Plan Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="GST Percentage: activate to sort column ascending"
                          style={{ width: "25%" }}
                        >
                          GST Percentage
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Effective Date: activate to sort column ascending"
                          style={{ width: "25%" }}
                        >
                          Effective Date
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {GSTPlanList?.map((element, index) => (
                        <>
                          <tr role="row" className="odd" key={index}>
                            <td>{element.GSTPlan}</td>
                            <td>{element.GSTPercentage}</td>
                            <td>{element.EffectiveDate}</td>
                            <td>
                              <div className="d-flex">
                                <Link
                                  to={`/edit-GST-plan/${window.btoa(
                                    element.GSTPlanIDEncrypt
                                  )}`}
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Edit"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1 rounded-circle"
                                  title={
                                    element.IsActive ? "Active" : "Inactive"
                                  }
                                  onClick={() => {
                                    ActiveInactive_GSTPlan(
                                      element.GSTPlanIDEncrypt,
                                      element.IsActive
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-circle mt-1"
                                    style={{
                                      color: element.IsActive ? "green" : "red",
                                    }}
                                  ></i>
                                </Link>
                                <Link
                                  className="btn btn-light shadow btn-xs sharp"
                                  title="Delete"
                                  onClick={() => {
                                    delete_GSTPlan(element.GSTPlanIDEncrypt);
                                  }}
                                >
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "red" }}
                                  ></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {activePag.current * sort + 1} to{" "}
                      {data.length > (activePag.current + 1) * sort
                        ? (activePag.current + 1) * sort
                        : data.length}{" "}
                      of {data.length} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers d-flex  justify-content-center align-items-center pb-3">
                      <Link
                        // to="/patient-list"
                        className="paginate_button previous disabled"
                        aria-controls="example5"
                        data-dt-idx={0}
                        tabIndex={0}
                        id="example5_previous"
                        onClick={() =>
                          activePag.current > 0 &&
                          onClick(activePag.current - 1)
                        }
                      >
                        Previous
                      </Link>
                      <span className="d-flex">
                        {paggination.map((number, i) => (
                          <Link
                            key={i}
                            // to="/patient-list"
                            className={`paginate_button d-flex align-items-center justify-content-center ${
                              activePag.current === i ? "current" : ""
                            } ${i > 0 ? "ml-1" : ""}`}
                            aria-controls="example5"
                            data-dt-idx={1}
                            tabIndex={0}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>

                      <Link
                        // to="/patient-list"
                        className="paginate_button next disabled"
                        aria-controls="example5"
                        data-dt-idx={2}
                        tabIndex={0}
                        id="example5_next"
                        onClick={() =>
                          activePag.current + 1 < paggination.length &&
                          onClick(activePag.current + 1)
                        }
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default GSTPlanList;
