import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NOTIFICATION_LIST } from "./store/actions/NotificationAction";
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { POST_NotificationReadUpdate } from "./jsx/Helper/url_helper";
import { post } from "./jsx/Helper/api_helper";

const MyNotification = () => {
  // // Show a notification with the specified message
  // const showNotification = (message) => {
  //   toast(message, { position: toast.POSITION.BOTTOM_RIGHT });
  // };

  const dispatch = useDispatch();
  useEffect(() => {
    getNotificationList();
  }, []);

  const getNotificationList = async () => {
    if (localStorage.getItem("userDetails")) {
      let objLogin = JSON.parse(localStorage.getItem("userDetails"));

      let payload = {
        CurrentPage: 1,
        PageSize: 10,
        Search: "",
        Sorting: "",
        LoginIDEncrypted: objLogin?.LoginIDEncrypt,
      };
      await dispatch(NOTIFICATION_LIST(payload));
    }
  };

  const notificationRead = async (notificationID) => {
    await post(POST_NotificationReadUpdate, {
      NotificationIDEncrypted: notificationID,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          getNotificationList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const firebaseConfig = {
    apiKey: "AIzaSyAXjId7JU7Dyr1a_zZI3KYQN5sUUdNjLnI",
    authDomain: "shrahavclient.firebaseapp.com",
    projectId: "shrahavclient",
    storageBucket: "shrahavclient.appspot.com",
    messagingSenderId: "937987277164",
    appId: "1:937987277164:web:180643ff615e4d5b22f349",
    measurementId: "G-3JPHX379PN",
  };

  // const appfirebase = initializeApp(firebaseConfig);
  // const messaging = getMessaging(appfirebase);

  // // Request permission for notifications
  // Notification.requestPermission()
  //   .then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //       // Now you can get the FCM token
  //       getToken(messaging, {
  //         vapidKey: "BN0E636WJRWajrLqXEiShUJwX8TVwMdRbUfywgTKt1FhocPPSNOcit6ucu05NI5fYz_g38lrPRx3yceybCgsO_Q",
  //       })
  //         .then((token) => {
  //           console.log("FCM Token:", token);
  //           // Send this token to your server to save it for sending notifications.
  //         })
  //         .catch((error) => {
  //           console.error("Error getting FCM token:", error);
  //         });
  //     } else {
  //       console.error("Unable to get permission for notifications.");
  //     }
  //   })
  //   .catch((error) => {
  //     console.error("Error requesting permission for notifications:", error);
  //   });

  // onMessage(messaging, (payload) => {
  //   console.log("Message received. ", payload);
  //   // ...
  // });

  // Optionally, handle background messages
  // onBackgroundMessage(messaging, (payload) => {
  //   console.log('Received background message:', payload);
  // });

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = firebase.messaging();

  // Register the service worker
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log("Service Worker registered:", registration);

          // Request permission for notifications
          Notification.requestPermission()
            .then((permission) => {
              if (permission === "granted") {
                console.log("Notification permission granted.");
                // Now you can get the FCM token
                messaging
                  .getToken({
                    vapidKey:
                      "BN0E636WJRWajrLqXEiShUJwX8TVwMdRbUfywgTKt1FhocPPSNOcit6ucu05NI5fYz_g38lrPRx3yceybCgsO_Q",
                  })
                  .then((token) => {
                    console.log("FCM Token:", token);
                    // Send this token to your server to save it for sending notifications.
                    localStorage.setItem("FCMToken", token);
                  })
                  .catch((error) => {
                    console.error("Error getting FCM token:", error);
                  });
              } else {
                console.error("Unable to get permission for notifications.");
              }
            })
            .catch((error) => {
              console.error(
                "Error requesting permission for notifications:",
                error
              );
            });

          // Handle notifications when the app is in the foreground
          messaging.onMessage((payload) => {
            console.log("Received foreground message:", payload);
            //only login user will get the notification
            if (localStorage.getItem("userDetails")) {
              toast.info(
                <>
                  <div>
                    <h5 style={{ color: "white" }}>
                      {payload?.notification?.title}
                    </h5>
                    <span style={{ color: "#d3cdcd" }}>
                      {payload?.notification?.body}
                    </span>
                  </div>
                </>,
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  onClick: () => {
                    //alert("clicked!");
                    notificationRead(payload?.data?.NotificationID);
                  },
                }
              );
            }
          });

          // Optionally, handle background messages
          messaging.onBackgroundMessage((payload) => {
            console.log("Received background message:", payload);
            //only login user will get the notification
            if (localStorage.getItem("userDetails")) {
              toast.info(
                <>
                  <div>
                    <h5 style={{ color: "white" }}>
                      {payload?.notification?.title}
                    </h5>
                    <span style={{ color: "#d3cdcd" }}>
                      {payload?.notification?.body}
                    </span>
                  </div>
                </>,
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  onClick: () => {
                    //alert("clicked!");
                    notificationRead(payload?.data?.NotificationID);
                  },
                }
              );
            }
          });
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  }

  return (
    <>
      <ToastContainer autoClose={false} />
    </>
  );
};

export default MyNotification;
