import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const authUserDetail = JSON.parse(localStorage.getItem("userDetails"));

        /*Only HO admin allow*/
        if (
          props.location.pathname.includes("/configuration") ||
          props.location.pathname.includes("/document-check-list") ||
          props.location.pathname.includes("/email-SMTP-setting") ||
          props.location.pathname.includes("/companies") ||
          props.location.pathname.includes("/add-company") ||
          props.location.pathname.includes("/edit-company") ||
          props.location.pathname.includes("/GST-plan") ||
          props.location.pathname.includes("/add-GST-Plan") ||
          props.location.pathname.includes("/edit-GST-plan") ||
          props.location.pathname.includes("/terms-and-condition") ||
          props.location.pathname.includes("/privacy-policy") ||
          props.location.pathname.includes("/tutorial-videos") ||
          props.location.pathname.includes("/add-tutorial-video") ||
          props.location.pathname.includes("/edit-tutorial-video")
        ) {
          if (
            authUserDetail?.UserType == 2 ||
            (authUserDetail?.UserType == 1 && authUserDetail?.CompanyType != 1) //only HO Admin allow
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            );
          }
        }

        /* check for only UserType = User(2), Admin have all rights */
        if (authUserDetail?.UserType == 2) {
          const UserForms = JSON.parse(localStorage.getItem("userRights"));

          if (props.location.pathname.includes("/category-list")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 1 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/user-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 2 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-user")) {
            if (
              UserForms?.filter((item) => item.FormID == 2 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-user")) {
            if (
              UserForms?.filter((item) => item.FormID == 2 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/client-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 3 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-clients")) {
            if (
              UserForms?.filter((item) => item.FormID == 3 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/patient-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 4 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-patient")) {
            if (
              UserForms?.filter((item) => item.FormID == 4 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-patient")) {
            if (
              UserForms?.filter((item) => item.FormID == 4 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/staff-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 5 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-staff")) {
            if (
              UserForms?.filter((item) => item.FormID == 5 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-staff")) {
            if (
              UserForms?.filter((item) => item.FormID == 5 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/staff-duty-slots")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 6 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-staff-duty-slot")) {
            if (
              UserForms?.filter((item) => item.FormID == 6 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-staff-duty-slot")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 6 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/staff-duty-slots-date-wise")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 7 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/add-staff-duty-slots-date-wise")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 7 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-staff-duty-slots-date-wise")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 7 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/service-list")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 8 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-service")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 8 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-service")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 8 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/equipment-list")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 9 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-equipments")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 9 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-equipments")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 9 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/packages-list")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 10 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-package")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 10 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-package")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 10 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/pathology-reports-list")
          ) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 11 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/radiology-reports-list")
          ) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 12 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/health-checkup-list")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 13 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-health-checkup")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 13 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-health-checkup")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter((item) => item.FormID == 13 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/expense")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 14 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/recommendations")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 15 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-recommendations")) {
            if (
              UserForms?.filter((item) => item.FormID == 15 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-recommendations")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 15 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/staff-awards")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 16 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-staff-awards")) {
            if (
              UserForms?.filter((item) => item.FormID == 16 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-staff-awards")) {
            if (
              UserForms?.filter((item) => item.FormID == 16 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/service-mapping-and-price-list")
          ) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 17 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/service-price-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 18 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/equipment-mapping-and-price-list"
            )
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 19 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/package-mapping-and-price-list")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 20 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/pathology-report-mapping-and-price-list"
            )
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 21 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/radiology-report-mapping-and-price-list"
            )
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 22 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/health-checkup-mapping-and-price-list"
            )
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 23 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/enquiry-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 24 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-enquiry")) {
            if (
              UserForms?.filter((item) => item.FormID == 24 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/order-booking-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 25 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-order-booking")) {
            if (
              UserForms?.filter((item) => item.FormID == 25 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/edit-order-booking")) {
            if (
              UserForms?.filter((item) => item.FormID == 25 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/equipment-deposit-list")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 26 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/add-equipment-deposit")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 26 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-equipment-deposit")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 26 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/equipment-installation")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 27 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/add-equipment-installation")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 27 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-equipment-installation")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 27 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/equipment-return")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 28 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/add-equipment-return")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 28 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-equipment-return")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 28 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/order-booking-advance")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 29 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/add-order-booking-advance")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 29 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/order-booking-advance-return")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 30 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/add-order-booking-advance-return"
            )
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 30 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/activity-monitoring")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 31 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/schedule-package-and-healthcheckup"
            )
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 32 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-package-schedule")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 32 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-healthcheckup-schedule")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 32 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/staff-leave-managment")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 33 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/complaint-managment")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 34 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/staff-payout")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 35 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/staff-over-time")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 36 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/add-staff-over-time")) {
            if (
              UserForms?.filter((item) => item.FormID == 36 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/edit-staff-over-time")
          ) {
            if (
              UserForms?.filter((item) => item.FormID == 36 && item.EditAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/feedbacks")) {
            if (
              authUserDetail?.CompanyType != 1 || //Only for HO User
              UserForms?.filter(
                (item) =>
                  item.FormID == 37 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/ratings")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 38 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/voucher-series-list")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 39 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/reports")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 40 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/invoices")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 41 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/invoices")) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 41 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (props.location.pathname.includes("/generate-invoice")) {
            if (
              UserForms?.filter((item) => item.FormID == 41 && item.AddAction)
                .length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes("/service-cancel-approval-reject")
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 42 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/assign-staff-for-equipment-installation"
            )
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 43 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else if (
            props.location.pathname.includes(
              "/assign-staff-for-equipment-return"
            )
          ) {
            if (
              UserForms?.filter(
                (item) =>
                  item.FormID == 44 &&
                  (item.ViewAction ||
                    item.EditAction ||
                    item.DeleteAction ||
                    item.AddAction)
              ).length == 0
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
        }

        return <Component {...props} />;
      }}
    />
  );
};
export default Authmiddleware;
