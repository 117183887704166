import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Accordion, Row, Col, Modal } from "react-bootstrap";
import CustomInput from "../../../Custom/CustomInput";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import PaginationButtons from "../../../Custom/Pagination";
import {
  POST_GetComplaintsList,
  POST_ComplaintsClose,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import moment from "moment";

const ComplaintManagment = () => {
  const [complaintStatusModal, setComplaintStatusModal] = useState(false);
  const [complaintList, setComplaintList] = useState([]);

  const [recordCount, setRecordCount] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [searchValue, setSearchvalue] = useState("");
  const [attachmentURL, setAttachmentURL] = useState("");

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  let updatedSearchValue = searchValue;

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      ComplaintStatusFilter: 0,
      ComplaintTypeFilter: 0,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: {
      CompanyID,
      FromDate,
      ToDate,
      ComplaintStatusFilter,
      ComplaintTypeFilter,
    },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);
    let response = await post(POST_GetComplaintsList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      ComplaintByEncrypt: "",
      CompanyIDEncrypt: CompanyID,
      ComplaintType: ComplaintTypeFilter,
      ComplaintStatus: ComplaintStatusFilter,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",
    });
    if (response.IsSuccess) {
      setRecordCount(response.TotalRecordCount);
      setComplaintList(response.ComplaintsList);
      setCurrentSizePerPage(sizePerPage);
      if (page > 1 && response?.ComplaintsList?.length === 0) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCompanyDDL();
    getData();
  }, []);

  const complaintStatusOptions = [
    { label: "All", value: 0 },
    { label: "Open", value: 1 },
    { label: "Closed", value: 2 },
  ];

  const complaintTypeOptions = [
    { label: "All", value: 0 },
    { label: "Client", value: 1 },
    { label: "Staff", value: 2 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const validationSchemaComplaintStatus = Yup.object().shape({
    Remark: Yup.string().required("Remark is required field"),
  });

  const formikComplaintStatus = useFormik({
    validationSchema: validationSchemaComplaintStatus,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitComplaintStatus(values, formikHelpers),
    initialValues: {
      ComplaintNo: "",
      ComplaintType: "",
      ComplaintBy: "",
      OrderNo: "",
      ComplaintTopic: "",
      ComplaintRemark: "",
      Remark: "",
      ComplaintIDEncrypt: "",
      ComplaintStatus: "",
    },
    validateOnBlur: false,
  });
  const {
    values: {
      ComplaintNo,
      ComplaintType,
      ComplaintBy,
      OrderNo,
      ComplaintTopic,
      ComplaintRemark,
      Remark,
      ComplaintIDEncrypt,
      ComplaintStatus,
    },
  } = formikComplaintStatus;

  const handleValidSubmitComplaintStatus = (values, { setSubmitting }) => {
    setIsLoading(true);

    var data = {
      ComplaintIDEncrypt: ComplaintIDEncrypt,
      ComplaintCloseRemarks: Remark,
      ComplaintStatus: 2,
      ComplaintClosedByEncrypt: objLogin.LoginIDEncrypt,
    };
    post(POST_ComplaintsClose, data)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          getData();
          formikComplaintStatus.resetForm();
          setComplaintStatusModal(false);
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            motherMenu="Transaction"
            activeMenu="Complaint Managment"
          />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    // as={Card.Text}
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                      // backgroundColor: "#36C95F",
                      // borderColor: "#d1d1d1",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Complaint List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          <Row>
                            {objLogin?.CompanyType === 1 &&
                            objLogin?.UserType === 1 ? (
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            <Col lg={3}>
                              <Field
                                label={"Complaint Type"}
                                name="ComplaintTypeFilter"
                                placeholder={"Select Complaint Type"}
                                options={complaintTypeOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Complaint Status"}
                                name="ComplaintStatusFilter"
                                placeholder={"Select Complaint Status"}
                                options={complaintStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="SchedulePackageAndHealthCheckup_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped SchedulePackageAndHealthCheckup_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Complaint No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Complaint No.
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Complaint Type: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Type
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Complaint Date: activate to sort column ascending"
                          style={{ width: "14%" }}
                        >
                          Complaint Date
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Complaint By: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Complaint By
                        </th>
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No.: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Order No.
                        </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Complaint Topic: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Complaint Topic
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Complaint Remark: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Remark
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        complaintList?.map((element, i) => (
                          <tr
                            role="row"
                            className="odd"
                            style={{
                              backgroundColor:
                                element.ComplaintStatus === 2
                                  ? "#e4f6ee"
                                  : "#f6f6f6",
                            }}
                          >
                            <td>{element.ComplaintNo}</td>
                            <td>{element.ComplaintType}</td>
                            <td>{element.ComplaintDate}</td>
                            <td>{element.ComplaintName}</td>
                            {/* <td>{element.OrderBookingNo}</td> */}
                            <td>{element.ComplaintTopic}</td>
                            <td>{element.ComplaintRaiseRemarks}</td>
                            <td style={{ textAlign: "center" }}>
                              <div className="d-flex">
                                {objLogin?.UserType == 1 ||
                                (objLogin?.UserType == 2 &&
                                  UserForms?.filter(
                                    (item) =>
                                      item.FormID == 34 && item.EditAction
                                  ).length > 0) ? (
                                  element.ComplaintStatus === 1 ? (
                                    <Link
                                      className="btn btn-light shadow btn-xs sharp"
                                      title="Open"
                                      onClick={() => {
                                        formikComplaintStatus.resetForm();
                                        formikComplaintStatus.setValues({
                                          ComplaintNo: element.ComplaintNo,
                                          ComplaintType: element.ComplaintType,
                                          ComplaintBy: element.ComplaintName,
                                          OrderNo: element.OrderBookingNo,
                                          ComplaintTopic:
                                            element.ComplaintTopic,
                                          ComplaintRemark:
                                            element.ComplaintRaiseRemarks,
                                          Remark: "",
                                          ComplaintIDEncrypt:
                                            element.ComplaintIDEncrypt,
                                          ComplaintStatus:
                                            element.ComplaintStatus,
                                        });
                                        setComplaintStatusModal(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-unlock"
                                        style={{ color: "black" }}
                                      ></i>
                                    </Link>
                                  ) : (
                                    <Link
                                      className="btn btn-light shadow btn-xs sharp"
                                      title="CLosed"
                                      onClick={() => {
                                        formikComplaintStatus.setValues({
                                          ComplaintNo: element.ComplaintNo,
                                          ComplaintType: element.ComplaintType,
                                          ComplaintBy: element.ComplaintName,
                                          OrderNo: element.OrderBookingNo,
                                          ComplaintTopic:
                                            element.ComplaintTopic,
                                          ComplaintRemark:
                                            element.ComplaintRaiseRemarks,
                                          Remark: element.ComplaintCloseRemarks,
                                          ComplaintIDEncrypt:
                                            element.ComplaintIDEncrypt,
                                          ComplaintStatus:
                                            element.ComplaintStatus,
                                        });
                                        setComplaintStatusModal(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-lock"
                                        style={{ color: "red" }}
                                      ></i>
                                    </Link>
                                  )
                                ) : (
                                  ""
                                )}
                                {element.FileAttachement ? (
                                  <>
                                    &nbsp;
                                    <Link
                                      to="#"
                                      className="btn btn-light shadow btn-xs sharp mr-1"
                                      title="Attachment"
                                      onClick={() => {
                                        setAttachmentURL(
                                          element.FileAttachement
                                        );
                                        setAttachmentModal(true);
                                      }}
                                    >
                                      <i className="fa fa-picture-o"></i>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <span
              class="badge"
              style={{ backgroundColor: "#f6f6f6", color: "#3e4954" }}
            >
              Open
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#e4f6ee", color: "#3e4954" }}
            >
              Close
            </span>
          </div>
          <Modal
            className="modal fade"
            show={complaintStatusModal}
            onHide={setComplaintStatusModal}
          >
            <FormikProvider value={formikComplaintStatus}>
              <Form onSubmit={formikComplaintStatus.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Complaint</h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setComplaintStatusModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={5}>
                          <b>Complaint No.</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={6}>{ComplaintNo}</Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <Col lg={5}>
                          <b>Complaint Type</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={6}>{ComplaintType}</Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <Col lg={5}>
                          <b>Complaint By</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={6}>{ComplaintBy}</Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <Col lg={5}>
                          <b>Order No.</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={6}>{OrderNo}</Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <Col lg={5}>
                          <b>Complaint Topic</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={6}>{ComplaintTopic}</Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <Col lg={5}>
                          <b>Complaint Remark</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={6}>{ComplaintRemark}</Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <Col lg={5}>
                          <b>Complaint Status</b>
                        </Col>
                        <Col lg={1}>:</Col>
                        <Col lg={6}>
                          {ComplaintStatus === 2 ? "Closed" : "Open"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col lg={12}>
                      <Field
                        label={"Remark"}
                        type="textarea"
                        name="Remark"
                        placeholder={"Enter Remark"}
                        style={{
                          backgroundColor:
                            ComplaintStatus === 2 ? "#e9ecef" : "",
                          resize: "none",
                        }}
                        component={CustomInput}
                        disabled={ComplaintStatus === 2 ? true : false}
                        rows={3}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  {ComplaintStatus === 2 ? (
                    <button
                      type="button"
                      onClick={() => setComplaintStatusModal(false)}
                      className="btn btn-xs btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Close
                    </button>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-xs btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        onClick={() => setComplaintStatusModal(false)}
                        className="btn btn-xs btn-light"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                    </>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </Modal>
          <Modal
            className="modal fade"
            show={attachmentModal}
            onHide={setAttachmentModal}
            // size="md"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Attachment</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setAttachmentModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="pb-1">
                  <div id="lightgallery" className="row">
                    <a
                      // href={attachmentURL}
                      data-exthumbimage={attachmentURL}
                      data-src={attachmentURL}
                      className="col-lg-12 col-md-12 mb-12"
                      key={1}
                    >
                      <img
                        src={attachmentURL}
                        alt=""
                        style={{ width: "100%" }}
                        onClick={() => {
                          window.open(attachmentURL);
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {/* <Modal
            isOpen={attachmentModal}
            toggle={() => {
              // view_Image()
            }}
            centered={true}
          >
            <div className="modal-header mdl_header">
              <h5 className="modal-title mt-0">{"Attachment"}</h5>
              <Button
                type="button"
                onClick={() => {
                  setAttachmentModal(false);
                }}
                data-dismiss="modal"
                aria-label="Close"
                style={{
                  border: "2.5px solid #ffffff",
                  padding: "0.10rem 0.50rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "#00000000",
                }}
              >
                <i className="fas fa-times" style={{ color: "#ffffff" }}></i>
              </Button>
            </div>
            <div className="modal-body">
             {iconPath ? ( 
              <center>
                <div>
                  <img
                    className="avatar-lg"
                    alt="Icon"
                    src={attachmentURL}
                    style={{
                      width: "280px",
                      height: "175px",
                      borderRadius: "10px",
                    }}
                    key={1}
                  />
                </div>
              </center>
              ) : (
                      ""
                    )}
            </div>
          </Modal> */}
        </Spin>
      </Fragment>
    </>
  );
};

export default ComplaintManagment;
