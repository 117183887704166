import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import PaginationButtons from "../../../Custom/Pagination";
import { Spin } from "antd";
import {
  POST_AdminOrderBookingInvoiceReceiptInsert,
  POST_GetAdminInvoiceList,
  POST_GetInvoiceDetailsByID,
  POST_GetModeOfPaymentDDL,
  POST_InvoiceDownload,
  POST_InvoiceReceiptDownload,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import { FormikProvider, useFormik, Field } from "formik";

import { Row, Col, Button, Modal, Accordion } from "react-bootstrap";
import { Form } from "reactstrap";
import CustomInput from "../../../Custom/CustomInput";

import * as Yup from "yup";
import { t } from "i18next";
import CustomSelect from "../../../Custom/CustomSelect";
import moment from "moment";
import swal from "sweetalert";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";

const InvoiceList = () => {
  const [recordCount, setRecordCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [InvoiceDataList, setInvoiceDataList] = useState([]);
  const [paymentReceiptModal, setPaymentReceiptModal] = useState(false);

  const [advanceDetailModal, setAdvanceDetailModal] = useState(false);
  const [confirmGenerateModal, setConfirmGenerateModal] = useState(false);
  const [advancePayModal, setAdvancePayModal] = useState(false);
  const [modeOfPaymentOptions, setModeOfPaymentOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      InvoiceStatus: -1,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate, InvoiceStatus },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);

    const Payload = {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      CompanyIDEncrypted: CompanyID,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",
      InvoiceStatus:
        InvoiceStatus == 0
          ? -1
          : InvoiceStatus == 1
          ? 0
          : InvoiceStatus == 2
          ? 1
          : -1,
    };

    await post(POST_GetAdminInvoiceList, Payload)
      .then((response) => {
        setRecordCount(response?.TotalRecordCount);
        setInvoiceDataList(response?.AdminInvoiceList);
        //setCurrentPage(page);
        setCurrentSizePerPage(sizePerPage);
        setIsLoading(false);
        if (page > 1 && response?.AdminInvoiceList?.length === 0) {
          setCurrentPage(page - 1);
          getData(page - 1);
        } else {
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   getData();
  // }, [searchValue]);

  useEffect(() => {
    loadCompanyDDL();
    getData();
  }, []);

  const invoiceStatusOptions = [
    { label: "All", value: 0 },
    { label: "Unpaid", value: 1 },
    { label: "Paid", value: 2 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadModeOfPaymentOptionsDDL();
  }, []);

  const downloadInvoice = async (OrderBookingInvoiceID) => {
    setIsLoading(true);

    await post(POST_InvoiceDownload, {
      OrderBookingInvoiceIDEncrypt: OrderBookingInvoiceID,
    })
      .then((response) => {
        window.open(response.FilePath);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const formikInvoicePaymentReceipt = useFormik({
    initialValues: {
      InvoicePaymentReceiptList: [],
    },
  });

  const {
    values: { InvoicePaymentReceiptList },
  } = formikInvoicePaymentReceipt;

  const getInvoicePaymentReceipt = async (
    OrderBookingInvoiceReceiptID,
    isOriginal
  ) => {
    setIsLoading(true);

    await post(POST_InvoiceReceiptDownload, {
      OrderBookingInvoiceReceiptIDEncrypt: OrderBookingInvoiceReceiptID,
      IsOriginal: isOriginal,
      WithoutLetterHead: true,
    })
      .then((response) => {
        window.open(response.FilePath);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadModeOfPaymentOptionsDDL = () => {
    post(POST_GetModeOfPaymentDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setModeOfPaymentOptions(
            res.ModeOfPaymentList.map((item) => ({
              label: item.ModeOfPayment,
              value: item.ModeOfPaymentID,
              isCheque: item.IsCheque,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const validationSchemaPayBill = Yup.object().shape({
    ModeOfPaymentID: Yup.string().when("TotalPayable", {
      is: (TotalPayable) => parseFloat(TotalPayable) > 0,
      then: Yup.string().required("Mode of Payment is required field"),
      otherwise: Yup.string(),
    }),
    PaidAmount: Yup.number().when("TotalPayable", {
      is: (TotalPayable) => parseFloat(TotalPayable) > 0,
      then: Yup.number()
        .min(1, "Paying Amount is required field")
        .required("Paying Amount is required field"),
      otherwise: Yup.number(),
    }),
  });

  const formikPayBill = useFormik({
    validationSchema: validationSchemaPayBill,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitPayBill(values, formikHelpers),
    initialValues: {
      OrderBookingInvoiceIDEncrypt: "",
      AdvanceBalance: "0.00",
      InvoiceNetAmount: "0.00",
      PendingAmount: "0.00",
      TotalPayable: "0.00",
      ModeOfPaymentID: "",
      SettleAmount: "",
      PaidAmount: "",
      Remarks: "",
      OrderBookingInvoiceReceiptIDEncrypted: "",
      ListOfAdvanceBalanceDetail: [],
    },
    validateOnBlur: false,
  });

  const {
    values: {
      TotalPayable,
      OrderBookingInvoiceIDEncrypt,
      ListOfAdvanceBalanceDetail,
      OrderBookingInvoiceReceiptIDEncrypted,
    },
  } = formikPayBill;

  const payBill = (OrderBookingInvoiceIDEncrypt) => {
    setIsLoading(true);
    post(POST_GetInvoiceDetailsByID, {
      OrderBookingInvoiceIDEncrypt: OrderBookingInvoiceIDEncrypt,
    })
      .then((response) => {
        if (response?.IsSuccess) {
          formikPayBill.setFieldValue(
            "ListOfAdvanceBalanceDetail",
            response?.ListOfAdvanceBalanceDetail
          );
          setAdvancePayModal(true);
        } else {
          swal("Error!", t(response?.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleValidSubmitPayBill = (values, { setSubmitting }) => {
    let payload = {
      ...values,
      PaidAmount: values?.PaidAmount ? values?.PaidAmount.toString() : "0.00",
      SettleAmount: values?.SettleAmount.toString(),
      ModeOfPaymentID: values?.ModeOfPaymentID ? values?.ModeOfPaymentID : 0,
      PaymentTransactionIDEncrypt: "",
      IsSettleAgaisntAdvance: 1,
      CreatedByEncrypt: AuthUser?.LoginIDEncrypt,
      CompanyIDEncrypt: AuthUser?.CompanyIDEncrypt,
    };
    delete payload["OrderBookingInvoiceReceiptIDEncrypted"];
    delete payload["ListOfAdvanceBalanceDetail"];
    //console.log("PayBill Payload:", payload);
    OrderBookingInvoiceReceiptInsert(payload);
  };

  const OrderBookingInvoiceReceiptInsert = async (data) => {
    setIsLoading(true);
    await post(POST_AdminOrderBookingInvoiceReceiptInsert, data)
      .then((response) => {
        if (response?.IsSuccess) {
          formikPayBill.setFieldValue(
            "OrderBookingInvoiceReceiptIDEncrypted",
            response?.OrderBookingInvoiceReceiptIDEncrypted
          );
          setIsLoading(false);
          setAdvancePayModal(false);
          //swal("Success!", t(response.MessageCode), "success");
          setConfirmGenerateModal(true);
          getData(currentPage, currentSizePerPage);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Transaction" activeMenu="Invoices" />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Invoice List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          {AuthUser?.CompanyType === 1 &&
                          AuthUser?.UserType == 1 ? (
                            <Row>
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col lg={3}>
                              <Field
                                label={"Status"}
                                name="InvoiceStatus"
                                placeholder={"Select Status"}
                                options={invoiceStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 41 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-none d-lg-block">
                <Link
                  to="/generate-invoice"
                  className="btn-xs btn btn-primary btn-rounded"
                >
                  + Add New Invoice
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="invoices_list"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped invoice-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Invoice No : activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Invoice No
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Invoice Date : activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Invoice Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name : activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Patient Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Mobile No: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Mobile No
                        </th>{" "}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="InvoiceAmount: activate to sort column ascending"
                          style={{ width: "10%", textAlign: "right" }}
                        >
                          Invoice Amount
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="PendingAmount: activate to sort column ascending"
                          style={{ width: "10%", textAlign: "right" }}
                        >
                          {/* Pending Amount */}
                          Payable Amount
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                          style={{ width: "5%" }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order Numbers: activate to sort column ascending"
                          style={{ width: "15%" }}
                        >
                          Order Numbers
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        InvoiceDataList?.map((element, index) => (
                          <tr role="row" className="odd" key={index}>
                            <td>{element.InvoiceNo}</td>
                            <td>{element.InvoiceDate}</td>
                            <td>{element.PatientName}</td>
                            <td>{element.MobileNo}</td>
                            <td style={{ textAlign: "right" }}>
                              <i className="fa fa-rupee"></i>{" "}
                              {element.NetAmount}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <i className="fa fa-rupee"></i>{" "}
                              {/* {element.PendingAmount} */}
                              {parseFloat(element.PendingAmount) >
                                parseFloat(element.AdvanceBalanceAmount) &&
                              parseFloat(element.PendingAmount) > 0
                                ? parseFloat(element.PendingAmount) -
                                  parseFloat(element.AdvanceBalanceAmount)
                                : 0}
                            </td>
                            <td>{element.InvoicesPayStatus}</td>
                            <td>{element.OrderBookingNos}</td>
                            <td>
                              <div className="d-flex">
                                {/* <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Edit"
                                  to="/generate-invoice"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link> */}
                                {element?.IsCompleted == false ? (
                                  <Link
                                    className="btn btn-light shadow btn-xs sharp mr-1"
                                    title="Pay Bill"
                                    to="#"
                                    onClick={() => {
                                      let TotalPayableAmount = 0,
                                        SettledAmount = 0;
                                      if (
                                        parseFloat(element.PendingAmount) >
                                        parseFloat(element.AdvanceBalanceAmount)
                                      ) {
                                        SettledAmount = parseFloat(
                                          element.AdvanceBalanceAmount
                                        );
                                        TotalPayableAmount =
                                          parseFloat(element.PendingAmount) -
                                          parseFloat(
                                            element.AdvanceBalanceAmount
                                          );
                                      } else {
                                        SettledAmount = parseFloat(
                                          element.PendingAmount
                                        );
                                        TotalPayableAmount = 0;
                                      }
                                      formikPayBill.setValues({
                                        OrderBookingInvoiceIDEncrypt:
                                          element.OrderBookingInvoiceIDEncrypted,
                                        AdvanceBalance:
                                          element.AdvanceBalanceAmount,
                                        InvoiceNetAmount: element.NetAmount,
                                        PendingAmount: element.PendingAmount,
                                        TotalPayable: TotalPayableAmount,
                                        SettleAmount: SettledAmount,
                                        ModeOfPaymentID: "",
                                        PaidAmount: "",
                                      });
                                      payBill(
                                        element.OrderBookingInvoiceIDEncrypted
                                      );
                                    }}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Download Invoice"
                                  to="#"
                                  onClick={() => {
                                    downloadInvoice(
                                      element.OrderBookingInvoiceIDEncrypted
                                    );
                                  }}
                                >
                                  <i className="ti-download"></i>
                                </Link>
                                <Link
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Payment Receipts"
                                  to="#"
                                  onClick={() => {
                                    formikInvoicePaymentReceipt.setValues({
                                      InvoicePaymentReceiptList:
                                        element.AdminInvoiceReceiptList,
                                    });
                                    setPaymentReceiptModal(true);
                                  }}
                                >
                                  <i className="fa fa-print"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9}>
                            <div className="mt-3 ml-3 mb-3">
                              {"No Record Found"}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            show={paymentReceiptModal}
            onHide={setPaymentReceiptModal}
            size="lg"
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Invoice Payment Receipt</h4>
                <button
                  type="button"
                  className="btn close"
                  onClick={() => setPaymentReceiptModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <FormikProvider value={formikInvoicePaymentReceipt}>
                <Form onSubmit={formikInvoicePaymentReceipt.handleSubmit}>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="table-responsive">
                          <div
                            id="Payment_receipt_list"
                            className="dataTables_wrapper no-footer"
                          >
                            <table
                              id="example5"
                              className="table table-striped payment-receipt-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                              role="grid"
                              aria-describedby="example5_info"
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Receipt No: activate to sort column ascending"
                                    style={{
                                      width: "40%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Receipt No
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Receipt Date: activate to sort column ascending"
                                    style={{
                                      width: "40%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Receipt Date
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Receipt Amount: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      textAlign: "right",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Receipt Amount
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Origional
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Duplicate
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {InvoicePaymentReceiptList?.length > 0 ? (
                                  InvoicePaymentReceiptList?.map((receipt) => (
                                    <>
                                      <tr role="row" className="odd">
                                        <td>{receipt.ReceiptNo}</td>
                                        <td>{receipt.ReceiptDate}</td>
                                        <td style={{ textAlign: "right" }}>
                                          <i className="fa fa-rupee"></i>{" "}
                                          {receipt.PaidAmount}
                                        </td>
                                        <td>
                                          <center>
                                            <Link
                                              to="#"
                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                              title="Reprint Origional Receipt"
                                              onClick={() => {
                                                getInvoicePaymentReceipt(
                                                  receipt.OrderBookingInvoiceReceiptIDEncrypted,
                                                  true
                                                );
                                              }}
                                            >
                                              <i
                                                class="fa fa-print"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Link
                                              to="#"
                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                              title="Reprint Duplicate Receipt"
                                              onClick={() => {
                                                getInvoicePaymentReceipt(
                                                  receipt.OrderBookingInvoiceReceiptIDEncrypted,
                                                  false
                                                );
                                              }}
                                            >
                                              <i
                                                class="fa fa-print"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </center>
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={5}>
                                      <div className="mt-1 ml-1 mb-3">
                                        {"No Record Found..."}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={confirmGenerateModal}
            onHide={setConfirmGenerateModal}
            backdrop={"static"}
            centered
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              {/* <div className="modal-header">
              <h4 className="modal-title fs-20">Invoice</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setConfirmGenerateModal(false)}
              >
                <span>×</span>
              </button>
            </div> */}
              <div className="modal-body">
                <div className="swal-icon swal-icon--success">
                  <span className="swal-icon--success__line swal-icon--success__line--long"></span>
                  <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

                  <div className="swal-icon--success__ring"></div>
                  <div className="swal-icon--success__hide-corners"></div>
                </div>
                <div className="swal-title">Success</div>
                <div class="text-center">
                  <button
                    type="button"
                    className="btn btn-primary m-1"
                    onClick={() =>
                      downloadInvoice(OrderBookingInvoiceIDEncrypt)
                    }
                  >
                    Invoice PDF
                  </button>
                  {OrderBookingInvoiceReceiptIDEncrypted ? (
                    <button
                      type="button"
                      className="btn btn-primary m-1"
                      onClick={() =>
                        getInvoicePaymentReceipt(
                          OrderBookingInvoiceReceiptIDEncrypted,
                          true
                        )
                      }
                    >
                      Invoice Payment Receipt
                    </button>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    className="btn btn-info m-1"
                    onClick={() => {
                      setConfirmGenerateModal(false);
                      formikPayBill.resetForm();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={advancePayModal}
            onHide={setAdvancePayModal}
            backdrop={"static"}
            centered
            size="lg"
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Invoice Payment</h4>
                <button
                  type="button"
                  className="btn close"
                  onClick={() => setAdvancePayModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <FormikProvider value={formikPayBill}>
                  <Form onSubmit={formikPayBill.handleSubmit}>
                    <Row>
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Net Amount"}
                          name="InvoiceNetAmount"
                          placeholder={"Net Amount"}
                          component={CustomInput}
                          readOnly
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Pending Amount"}
                          name="PendingAmount"
                          placeholder={"Pending Amount"}
                          min={0}
                          component={CustomInput}
                          readOnly
                        />
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Advance Balance"}
                          name="AdvanceBalance"
                          placeholder={"Advance Balance"}
                          component={CustomInput}
                          readOnly
                        />
                        <span style={{ float: "right", marginTop: "-90px" }}>
                          <button
                            type="button"
                            className="btn mr-1 btn-xxs btn-light pl-2 pr-2 pt-1 pb-1"
                            onClick={() => {
                              setAdvanceDetailModal(true);
                            }}
                          >
                            Advance Detail
                          </button>
                        </span>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className="mt-3">
                      <Col lg={4}>
                        <Field
                          type="number"
                          label={"Payable Amount"}
                          name="TotalPayable"
                          placeholder={"Total Payable"}
                          min={0}
                          component={CustomInput}
                          readOnly
                        />
                      </Col>

                      {parseFloat(TotalPayable) > 0 ? (
                        <>
                          {" "}
                          <Col lg={4}>
                            <Field
                              label={"Mode of Payment"}
                              name="ModeOfPaymentID"
                              placeholder={"Mode Of Payment"}
                              component={CustomSelect}
                              options={modeOfPaymentOptions}
                              required
                            />
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="number"
                              label={"Paying Amount"}
                              name="PaidAmount"
                              placeholder={"Paying Amount"}
                              min={0}
                              component={CustomInput}
                              required
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      <Col lg={8}>
                        <Field
                          type="text"
                          label={"Remarks"}
                          name="Remarks"
                          placeholder={"Remarks"}
                          component={CustomInput}
                          maxLength={500}
                        />
                      </Col>
                    </Row>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setAdvancePayModal(false)}
                        className="btn btn-light"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Cancel
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={advanceDetailModal}
            onHide={setAdvanceDetailModal}
            size="lg"
            centered
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Advance Detail</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => setAdvanceDetailModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <div className="table-responsive">
                  <div
                    id="advance_list"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="example5"
                      className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <div className="table-responsive ">
                        <div className="dataTables_wrapper no-footer">
                          <table className="table verticle-middle table-responsive-md mb-3">
                            <thead className="thead-light">
                              <tr role="row">
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Date: activate to sort column ascending"
                                  style={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Amount: activate to sort column ascending"
                                  style={{
                                    textAlign: "right",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Amount
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Settle: activate to sort column ascending"
                                  style={{
                                    textAlign: "right",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Settle
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Return: activate to sort column ascending"
                                  style={{
                                    textAlign: "right",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Return
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example5"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Balance: activate to sort column ascending"
                                  style={{
                                    textAlign: "right",
                                    padding: "10px 20px",
                                  }}
                                >
                                  Balance
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ListOfAdvanceBalanceDetail?.length > 0 ? (
                                ListOfAdvanceBalanceDetail?.map((x) => (
                                  <tr>
                                    <td>{x.AdvanceReceivedDate}</td>
                                    <td style={{ textAlign: "right" }}>
                                      <i className="fa fa-rupee"></i>{" "}
                                      {x.AdvanceAmount}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      <i className="fa fa-rupee"></i>{" "}
                                      {x.SettledAmount}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      <i className="fa fa-rupee"></i>{" "}
                                      {x.ReturnAmount}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      <i className="fa fa-rupee"></i>{" "}
                                      {x.BalanceAmount}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={5}>No record found...</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </table>
                  </div>
                </div>
              </Row>
              <Row style={{ float: "right" }}>
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => setAdvanceDetailModal(false)}
                >
                  Close
                </button>
              </Row>
            </div>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default InvoiceList;
