export const starZero = (
  <ul className="star-rating">
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
  </ul>
);

export const starOne = (
  <ul className="star-rating">
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
  </ul>
);

export const starTwo = (
  <ul className="star-rating">
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
  </ul>
);

export const starThree = (
  <ul className="star-rating">
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
  </ul>
);

export const starFour = (
  <ul className="star-rating">
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1" style={{ color: "#d9d9d9" }} />
    </li>
  </ul>
);

export const starFive = (
  <ul className="star-rating">
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
    <li className="mr-sm-1">
      <i className="fa fa-star mr-1 text-orange" style={{ color: "#f90" }} />
    </li>
  </ul>
);
