import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Spin } from "antd";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Row, Col, Accordion } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import PaginationButtons from "../../../Custom/Pagination";
import { get, post } from "../../../Helper/api_helper";
import moment from "moment";
import swal from "sweetalert";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";

import {
  POST_StaffOverTimeList,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";

const StaffOverTime = () => {
  const [recordCount, setRecordCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [staffOverTimeList, setStaffOverTimeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  var AuthUser = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  let updatedSearchValue = searchValue;

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      StaffOverTimeStatus: -1,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: { CompanyID, FromDate, ToDate, StaffOverTimeStatus },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);
    let response = await post(POST_StaffOverTimeList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      StaffIDEncrypted: "",
      CompanyIDEncrypt: CompanyID,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",

      StaffOverTimeStatus:
        StaffOverTimeStatus == 0
          ? -1
          : StaffOverTimeStatus == 1
          ? 0
          : StaffOverTimeStatus == 2
          ? 1
          : StaffOverTimeStatus == 3
          ? 2
          : StaffOverTimeStatus == 4
          ? 3
          : -1,
    });
    setIsLoading(false);
    setRecordCount(response.TotalRecordCount);
    setStaffOverTimeList(response.StaffOverTimeList);
    setCurrentSizePerPage(sizePerPage);
    if (page > 1 && response?.StaffOverTimeList?.length === 0) {
      setCurrentPage(page - 1);
      getData(page - 1);
    } else {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    loadCompanyDDL();
    getData();
  }, []);

  const staffOverTimeStatusOptions = [
    { label: "All", value: 0 },
    { label: "Pending", value: 1 },
    { label: "Approved", value: 2 },
    { label: "Rejected", value: 3 },
    { label: "Cancel", value: 4 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle motherMenu="Transaction" activeMenu="Staff Over Time" />

          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Staff Over Time List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          {AuthUser?.CompanyType === 1 &&
                          AuthUser?.UserType == 1 ? (
                            <Row>
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col lg={3}>
                              <Field
                                label={"Status"}
                                name="StaffOverTimeStatus"
                                placeholder={"Select Status"}
                                options={staffOverTimeStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>

                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            {AuthUser?.UserType == 1 ||
            (AuthUser?.UserType == 2 &&
              UserForms?.filter((item) => item.FormID == 36 && item.AddAction)
                .length > 0) ? (
              <div className="mr-auto d-lg-block">
                <Link
                  to="/add-staff-over-time"
                  className="btn btn-primary btn-xs btn-rounded"
                >
                  + Add Staff Over Time
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  updatedSearchValue = e.target.value;
                  setSearchValue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="StaffOverTime_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped StaffOverTime_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="StaffName: activate to sort column ascending"
                          style={{ minWidth: "220px" }}
                        >
                          Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="OrderNo: activate to sort column ascending"
                          style={{ minWidth: "150px" }}
                        >
                          Order No
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="PatientName: activate to sort column ascending"
                          style={{ minWidth: "200px" }}
                        >
                          Patient Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="ServiceName: activate to sort column ascending"
                          style={{ minWidth: "200px" }}
                        >
                          Service Name
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="ServiceDate: activate to sort column ascending"
                          style={{ minWidth: "130px" }}
                        >
                          Duty Date
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="PunchInTime: activate to sort column ascending"
                          style={{ minWidth: "50px" }}
                        >
                          Punch In
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="PunchOutTime: activate to sort column ascending"
                          style={{ minWidth: "50px" }}
                        >
                          Punch Out
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="OverTime: activate to sort column ascending"
                          style={{ minWidth: "50px", textAlign: "right" }}
                        >
                          Over Time
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Amount: activate to sort column ascending"
                          style={{ minWidth: "80px", textAlign: "right" }}
                        >
                          Amount
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                          style={{ minWidth: "80px" }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "100px" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        staffOverTimeList?.map((element, i) => (
                          <tr>
                            <td>{element.StaffName}</td>
                            <td>{element.OrderBookingNo}</td>
                            <td>{element.PatientName}</td>
                            <td>{element.ServiceName}</td>
                            <td>{element.ScheduledPunchInDate}</td>
                            <td>{element.StaffPunchIn}</td>
                            <td>{element.StaffPunchOut}</td>
                            <td style={{ textAlign: "right" }}>
                              {element.StaffOverTimeHours}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {element.OverTimeAmount}
                            </td>
                            <td>{element.StaffOverTimeStatusName}</td>
                            <td style={{ textAlign: "center" }}>
                              {AuthUser?.UserType == 1 ||
                              (AuthUser?.UserType == 2 &&
                                UserForms?.filter(
                                  (item) => item.FormID == 36 && item.EditAction
                                ).length > 0) ? (
                                <Link
                                  to={`/edit-staff-over-time/${window.btoa(
                                    element.StaffOverTimeIDEncrypt
                                  )}`}
                                  className="btn btn-light shadow btn-xs sharp mr-1"
                                  title="Edit"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <div className="mt-1 ml-1">
                              {"No record found..."}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    </>
  );
};

export default StaffOverTime;
