import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import { Row, Col } from "react-bootstrap";
import { get, post } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

import {
  GET_CompanyDropDownList,
  POST_ClientOutstandingReportInExcel,
  POST_GetYearlyAnalysisDataWiseYearDDLList,
  POST_GetYearlyAnalysisDataReport,
  POST_GetEquipmentDepositCollectionPendingReportInExcel,
} from "../../../Helper/url_helper";
import EnquiryStatusReport from "./EnquiryStatusReport";
import EquipmentReport from "./EquipmentReport";
import StaffPerformanceReport from "./StaffPerformanceReport";
import GSTReport from "./GSTReport";
import PatientMasterReport from "./PatientMasterReport";
import StaffListReport from "./StaffListReport";
import UtilizationStaffReport from "./UtilizationStaffReport";
import RevenueReport from "./RevenueReport";
import StaffPayoutReport from "./StaffPayoutReport";

const ReportsMain = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);

  const [yearReportAnalysisOptions, setYearAnalysisReportOptions] = useState(
    []
  );

  var AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchema = Yup.object().shape({
    CompanyID: Yup.string().required("Company is required field"),
    // Report: Yup.string().required("Report is required field"),
    Year: Yup.string().when("Report", {
      is: (Report) => Report == 7,
      then: Yup.string().required("Year is required field"),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: AuthUser?.CompanyIDEncrypt,
      Report: "",
      FromDate: "",
      ToDate: "",
      Year: "",
    },
    validateOnBlur: false,
  });

  const {
    values: { CompanyID, Report },
  } = formik;

  useEffect(() => {
    loadCompanyDDL();
    loadYearlyAnalysisDataDDL();
  }, []);

  useEffect(() => {
    formik.setFieldValue("Year", "");
    formik.setFieldValue("Report", "");
    loadYearlyAnalysisDataDDL();
  }, [CompanyID]);

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const reportOptions = [
    { label: "Enquiry Status Report", value: 1 },
    { label: "Revenue Report", value: 2 },
    { label: "Client Outstanding Report", value: 3 },
    { label: "Staff Performance Report", value: 4 },
    { label: "Staff List Report", value: 5 },
    { label: "Utilization Staff Report", value: 6 },
    { label: "Yearly Analysis Data", value: 7 },
    { label: "Equipment Report", value: 8 },
    { label: "GST Report", value: 9 },
    { label: "Patient Master Report", value: 10 },
    { label: "Staff Payout Report", value: 11 },
    { label: "Equipment Deposit Pending Report", value: 12 },
  ];

  const loadYearlyAnalysisDataDDL = () => {
    setIsLoading(true);
    post(POST_GetYearlyAnalysisDataWiseYearDDLList, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setYearAnalysisReportOptions(
            res.AdminGetYearDDLList.map((item) => ({
              label: item.YearName,
              value: item.YearName,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      FromDate: "",
      ToDate: "",
      CompanyIDEncrypt: CompanyID,
      Year: values.Year ? values.Year : 0,
    };

    // console.log("Data", Data);
    let response = "";

    if (Report == 3) {
      response = await post(POST_ClientOutstandingReportInExcel, Data);
    } else if (Report == 7) {
      response = await post(POST_GetYearlyAnalysisDataReport, Data);
    } else if (Report == 12) {
      response = await post(
        POST_GetEquipmentDepositCollectionPendingReportInExcel,
        {
          CompanyIDEncrypt: CompanyID,
        }
      );
    }

    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle motherMenu="Reports" activeMenu="Reports" />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      {AuthUser?.CompanyType === 1 &&
                      AuthUser?.UserType == 1 ? (
                        <Row>
                          <Col lg={4}>
                            <Field
                              label={"Company"}
                              name="CompanyID"
                              placeholder={"Select Company"}
                              options={companyOptions}
                              component={CustomSelect}
                              required
                              isClearable={false}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col lg={6}>
                          <Field
                            label={"Report Name"}
                            name="Report"
                            options={reportOptions}
                            placeholder={"Select Report Name"}
                            component={CustomSelect}
                            required
                          />
                        </Col>
                      </Row>

                      <hr></hr>
                      {Report == 7 ? (
                        <Row>
                          <Col lg={3}>
                            <Field
                              label={"Year"}
                              name="Year"
                              options={yearReportAnalysisOptions}
                              placeholder={"Select Year"}
                              component={CustomSelect}
                              required
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {Report == 3 || Report == 7 || Report == 12 ? (
                        <Row className="mt-3">
                          <Col lg={12}>
                            <button
                              type="submit"
                              className="btn mr-2 btn-primary btn-sm"
                            >
                              View Report
                            </button>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Form>
                  </FormikProvider>
                </div>
                {Report == 1 ? (
                  <EnquiryStatusReport
                    companyID={CompanyID}
                    reportID={Report}
                  />
                ) : Report == 2 ? (
                  <RevenueReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></RevenueReport>
                ) : Report == 4 ? (
                  <StaffPerformanceReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></StaffPerformanceReport>
                ) : Report == 5 ? (
                  <StaffListReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></StaffListReport>
                ) : Report == 6 ? (
                  <UtilizationStaffReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></UtilizationStaffReport>
                ) : Report == 8 ? (
                  <EquipmentReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></EquipmentReport>
                ) : Report == 9 ? (
                  <GSTReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></GSTReport>
                ) : Report == 10 ? (
                  <PatientMasterReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></PatientMasterReport>
                ) : Report == 11 ? (
                  <StaffPayoutReport
                    companyID={CompanyID}
                    reportID={Report}
                  ></StaffPayoutReport>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default ReportsMain;
