import React, { Fragment, useEffect, useState } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import { Row, Col } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import { Spin } from "antd";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_EquipmentReportInExcelDownload,
  POST_GetEquipmentTypeDDL,
} from "../../../Helper/url_helper";

const EquipmentReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchemaReports = Yup.object().shape({});

  const formik = useFormik({
    validationSchema: validationSchemaReports,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      EquipmentTypeID: 0,
      CompanyID: props.companyID,
      Report: props.reportID,
      FromDate: "",
      ToDate: "",
      Status: 0,
    },
    validateOnBlur: false,
  });

  const {
    values: { CompanyID, EquipmentTypeID },
  } = formik;

  useEffect(() => {
    loadEquipmentTypeDDL();
  }, []);

  const statusReportOptions = [
    { label: "All", value: 0 },
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
  ];

  const loadEquipmentTypeDDL = () => {
    setIsLoading(true);
    post(POST_GetEquipmentTypeDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setEquipmentTypeOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };
  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      EquipmentTypeID: values.EquipmentTypeID ? EquipmentTypeID : 0,
      FromDate: values.FromDate,
      ToDate: values.ToDate,
      Status:
        values.Status == 0
          ? -1
          : values.Status == 1
          ? 1
          : values.Status == 2
          ? 0
          : -1,
      CompanyIDEncrypt: CompanyID,
    };

    // console.log("Data", Data);

    let response = await post(POST_EquipmentReportInExcelDownload, Data);
    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} style={{ textAlign: "left", fontSize: "15px" }}>
                <div style={{ color: "black" }}>Filter</div> <br></br>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Field
                  label={"Equipment Type"}
                  name="EquipmentTypeID"
                  options={equipmentTypeOptions}
                  placeholder={"Select Equipment Type"}
                  component={CustomSelect}
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Status"}
                  name="Status"
                  options={statusReportOptions}
                  placeholder={"Select Status"}
                  component={CustomSelect}
                />
              </Col>
            </Row>

            <hr></hr>
            <Row className="mt-3">
              <Col lg={12}>
                <button type="submit" className="btn mr-2 btn-primary btn-sm">
                  View Report
                </button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Spin>
    </Fragment>
  );
};

export default EquipmentReport;
