import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import { Row, Col, InputGroup } from "react-bootstrap";
import { AvForm } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import { Modal } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import {
  POST_AdminEquipmentInstallationDetailByID,
  POST_AdminEquipmentInstallationSearchByOrderBookingNo,
  POST_AdminEquipmentInstallationInsert,
  POST_AdminEquipmentInstallationUpdate,
  POST_AdminEquipmentInstallationPhotosInsertDelete,
  POST_AdminStaffsListDDLByCompanyID,
} from "../../../Helper/url_helper";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import ModalPatientDetails from "../../../Custom/ModalPatientDetails";
import moment from "moment";

const AddEquipmentInstallation = () => {
  const { EquipmentInstallationIDEncryptParam } = useParams();
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentPhotoDetail, setEquipmentPhotoDetail] = useState([]);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [patientDetailModel, setPatientDetailModal] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [orderBookingID, setOrderBookingID] = useState("");
  const [patientID, setPatientID] = useState("");

  const [selectedFiles, setselectedFiles] = useState([]);
  const [deletePhotoIDs, setDeletePhotoIDs] = useState([]);

  const validationSchema = Yup.object().shape({
    OrderBookingNo: Yup.string().required(
      "Order Booking No. is required field"
    ),
    InstallationDate: Yup.string().required(
      "Installation Date is required field"
    ),
    Staff: Yup.string().required("Staff is required field"),
    EquipmentID: Yup.string().required("Equipment is required field."),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      EquipmentName: "",
      EquipmentID: "",
      OrderBookingNo: "",
      InstallationDate: "",
      Staff: "",
      ISReturn: false,
      PatientName: "",
      PatientNo: "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      ISReturn,
      OrderBookingNo,
      PatientNo,
      PatientName,
      EquipmentID,
      InstallationDate,
    },
  } = formik;

  useEffect(() => {
    getStaffList();
    if (EquipmentInstallationIDEncryptParam) {
      setIsLoading(true);
      post(POST_AdminEquipmentInstallationDetailByID, {
        EquipmentInstallationIDEncrypted: window.atob(
          EquipmentInstallationIDEncryptParam
        ),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            OrderBookingNo: res.OrderBookingNo,
            Staff: res.StaffIDEncrypt,
            InstallationDate: res.InstallationDate
              ? new Date(res.InstallationDate)
              : "",
            ISReturn: res.IsReturnStatus,
            PatientName: res.PatientName,
            PatientNo: res.PatientNo,
            EquipmentName: res.EquipmentName,
            EquipmentID: res.EquipmentIDEncrypt,
          });
          setEquipmentPhotoDetail(res.AdminEquipmentInstallationPhotosList);
          setOrderBookingID(res.OrderBookingIDEncrypt);
          setPatientID(res.PatientIDEncrypt);
        }
      });
      setIsLoading(false);
    }
  }, []);

  const getStaffList = async () => {
    await post(POST_AdminStaffsListDDLByCompanyID, {
      CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setStaffOptions(
            res.StaffsList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const getEquipmentInstallationByOrderBookingNo = async () => {
    if (OrderBookingNo) {
      setIsLoading(true);
      await post(POST_AdminEquipmentInstallationSearchByOrderBookingNo, {
        OrderBookingNo: OrderBookingNo,
        CompanyIDEncrypt: objLogin.CompanyIDEncrypt,
      }).then((res) => {
        if (res.IsSuccess) {
          setFieldValue("PatientName", res.PatientName);
          setFieldValue("PatientNo", res.PatientNo);

          setEquipmentOptions(
            res.AdminEquipmentsList.map((item) => ({
              label: item.EquipmentName,
              value: item.EquipmentIDEncrypt,
            }))
          );
          setOrderBookingID(res.OrderBookingIDEncrypt);
          setPatientID(res.PatientIDEncrypt);
        } else {
          swal({
            // title: "warning",
            text: t(res.MessageCode),
            icon: "warning",
            button: "OK",
          });
          formik.resetForm();
        }
      });
      setIsLoading(false);
    } else {
      swal({
        // title: "Error",
        text: "Order Booking No. is required field",
        icon: "warning",
        button: "OK",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!OrderBookingNo) {
      formik.resetForm();
      setOrderBookingID("");
    }
  }, [OrderBookingNo]);
  function handleAcceptedFiles(files) {
    var pattern = /(\.|\/)(jpe?g|png|webp|svg|gif|ico)$/;
    var patternValid = true;
    files.map((file) => {
      if (!file.type.match(pattern)) {
        swal("", "Only image files are allowed!", "warning");
        patternValid = false;
      }
    });
    if (patternValid) {
      var InvalidFile = false;
      var msgInvalidShow = true;
      files.map((file, i) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            // if (height !== 500 || width !== 500) {
            //   InvalidFile = true;
            // } else {
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            });
            setselectedFiles((selectedFiles) => [...selectedFiles, file]);
            // }
            // if (InvalidFile && msgInvalidShow) {
            //   msgInvalidShow = false;
            //   swal({
            //     title: "Error",
            //     text: "Image size must be of 500x500",
            //     icon: "error",
            //     button: "OK",
            //   });
            // }
          };
        };
      });
    }
  }

  useEffect(() => {
    if (!InstallationDate) {
      setFieldValue("InstallationDate", "");
    }
  }, [InstallationDate]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const SavePhotos = async (eID) => {
    // if (selectedFiles?.length > 0 || deletePhotoIDs.length > 0) {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("EncryptedEquipmentInstallationID", eID);
    if (selectedFiles?.length > 0) {
      Array.prototype.forEach.call(selectedFiles, (child) =>
        formData.append("Photos", child)
      );
    }
    if (deletePhotoIDs?.length > 0) {
      formData.append("EquipmentInstallationPhotosIDs", deletePhotoIDs);
    }
    postFormData(POST_AdminEquipmentInstallationPhotosInsertDelete, formData)
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          setIsLoading(false);
          history.push("/equipment-installation");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    // } else {
    //   swal({
    //     text: "Photos are required",
    //     icon: "warning",
    //     button: "OK",
    //   });
    // }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    const data = {
      EquipmentInstallationIDEncrypt: EquipmentInstallationIDEncryptParam
        ? window.atob(EquipmentInstallationIDEncryptParam)
        : "",
      OrderBookingIDEncrypt: orderBookingID,
      EquipmentIDEncrypt: EquipmentID,
      InstallationDate: `${moment(values.InstallationDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      StaffIDEncrypt: values.Staff,
      IsReturnStatus: ISReturn,
      CreatedByEncrypt: objLogin.LoginIDEncrypt,
    };
    if (selectedFiles?.length > 0 || equipmentPhotoDetail?.length > 0) {
      post(
        EquipmentInstallationIDEncryptParam
          ? POST_AdminEquipmentInstallationUpdate
          : POST_AdminEquipmentInstallationInsert,
        data
      )
        .then((response) => {
          if (response.IsSuccess) {
            if (selectedFiles?.length > 0 || deletePhotoIDs.length > 0) {
              SavePhotos(
                EquipmentInstallationIDEncryptParam
                  ? window.atob(EquipmentInstallationIDEncryptParam)
                  : response.EncryptID
              );
            } else {
              swal({
                title: "Success",
                text: t(response.MessageCode),
                icon: "success",
                button: "OK",
              });
              setIsLoading(false);
              history.push("/equipment-installation");
            }
          } else {
            swal({
              title: "Error",
              text: t(response.MessageCode),
              icon: "error",
              button: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      swal({
        text: "Photos are required",
        icon: "warning",
        button: "OK",
      });
    }
    setIsLoading(false);
  };
  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            EquipmentInstallationIDEncryptParam
              ? "Edit Equipment Installation"
              : "Add Equipment Installation"
          }
          motherMenu={
            <Link to="/equipment-installation">Equipment Installation</Link>
          }
        />

        <div className="custom-tab-1">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col lg={4}>
                            <Field
                              label={"Order Booking No."}
                              name="OrderBookingNo"
                              placeholder={"Search here...."}
                              component={CustomInput}
                              maxLength={15}
                              disabled={
                                EquipmentInstallationIDEncryptParam
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {!EquipmentInstallationIDEncryptParam ? (
                            <Col lg={2}>
                              <div className="mb-3">
                                <Label>&nbsp;</Label>
                                <InputGroup>
                                  <button
                                    type="button"
                                    className="btn mt-1 mr-2 btn-xs btn-primary"
                                    onClick={() => {
                                      getEquipmentInstallationByOrderBookingNo();
                                    }}
                                  >
                                    Search
                                  </button>
                                </InputGroup>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        <hr></hr>
                        <Row>
                          {EquipmentInstallationIDEncryptParam ? (
                            <Col lg={3}>
                              <Field
                                label={"Equipment"}
                                name="EquipmentName"
                                placeholder={"Enter Equipment"}
                                component={CustomInput}
                                disabled={
                                  EquipmentInstallationIDEncryptParam
                                    ? true
                                    : false
                                }
                                required
                              />
                            </Col>
                          ) : (
                            <Col lg={3}>
                              <Field
                                label={"Equipment"}
                                name="EquipmentID"
                                placeholder={"Select Equipment"}
                                component={CustomSelect}
                                options={equipmentOptions}
                                disabled={
                                  EquipmentInstallationIDEncryptParam
                                    ? true
                                    : false
                                }
                                required
                              />
                            </Col>
                          )}
                          <Col lg={3}>
                            <Field
                              label={"Installation Date"}
                              name="InstallationDate"
                              placeholder={"Select Installation Date"}
                              component={CustomFlatpickr}
                              options={{
                                altInput: true,
                                altFormat: "j-M-Y",
                                dateFormat: "d-m-Y",
                              }}
                              required
                            />
                          </Col>
                          <Col lg={4}>
                            <Field
                              label={"Staff"}
                              name="Staff"
                              placeholder={"Select Staff"}
                              component={CustomSelect}
                              options={staffOptions}
                              required
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        {orderBookingID ? (
                          <>
                            <Row>
                              <Col lg={3}>
                                <b>Patient No.</b> : &nbsp;&nbsp; {PatientNo}
                              </Col>
                              <Col lg={4}>
                                <b>Patient Name</b> : &nbsp;&nbsp; {PatientName}
                              </Col>
                              <Col lg={3}>
                                <b>Return Status </b> : &nbsp;&nbsp;{" "}
                                {ISReturn ? "Yes" : "No"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12} style={{ textAlign: "end" }}>
                                <div
                                  onClick={() => {
                                    setOrderDetailModal(true);
                                  }}
                                  className="btn btn-light btn-xxs btn-rounded mt-4"
                                >
                                  Order Detail
                                </div>
                                &nbsp;&nbsp;
                                <div
                                  onClick={() => {
                                    setPatientDetailModal(true);
                                  }}
                                  className="btn btn-light btn-xxs btn-rounded mt-4"
                                >
                                  Patient Detail
                                </div>
                              </Col>
                            </Row>

                            <hr></hr>
                          </>
                        ) : (
                          ""
                        )}

                        <h6>
                          Upload Equipment Installed Photos{" "}
                          <span className="text-danger">*</span>{" "}
                          {/* <span style={{ color: "red", fontSize: "10px" }}>
                            (500 x 500)
                          </span> */}
                        </h6>
                        <Row className="mt-3">
                          <Col md={12} lg={12} xl={12}>
                            <AvForm className="needs-validation">
                              <Row>
                                <Dropzone
                                  onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      className="dropzone col-12"
                                      style={{
                                        minHeight: "150px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                        className="dz-message needsclick m-0"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-1">
                                          <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                                        </div>
                                        <h4>Drop here or click to upload.</h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div
                                  className="dropzone-previews mt-1 row"
                                  id="file-previews"
                                  style={{ minWidth: "100%" }}
                                >
                                  <Row style={{ marginLeft: "20px" }}>
                                    {selectedFiles.map((f, i) => {
                                      return (
                                        <div
                                          className="
                                          mt-0 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  width="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                                <Link
                                                  style={{
                                                    float: "right",
                                                    marginTop: "-9px",
                                                  }}
                                                  onClick={() => {
                                                    setselectedFiles(
                                                      selectedFiles.filter(
                                                        (item, index) =>
                                                          index !== i
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className="mdi mdi-delete"
                                                    style={{
                                                      fontSize: "20px",
                                                      color: "red",
                                                    }}
                                                  ></i>
                                                </Link>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </Row>
                                </div>
                              </Row>
                              {equipmentPhotoDetail.length > 0 ? (
                                <>
                                  <hr
                                    style={{
                                      marginTop: "2rem",
                                      backgroundColor: "black",
                                    }}
                                  ></hr>
                                  <h5>Uploaded Images</h5>
                                </>
                              ) : (
                                ""
                              )}
                              <Row>
                                {equipmentPhotoDetail?.map((item, index) => {
                                  if (item) {
                                    return (
                                      <Col md={2}>
                                        <div className="mb-3">
                                          <img
                                            src={item.PhotoUrl}
                                            width="90%"
                                            alt="upload"
                                          />

                                          <a
                                            className="link"
                                            title="Delete"
                                            onClick={() => {
                                              setDeletePhotoIDs([
                                                ...deletePhotoIDs,
                                                item.EquipmentInstallationPhotosID,
                                              ]);
                                              setEquipmentPhotoDetail(
                                                equipmentPhotoDetail?.filter(
                                                  (x, i) => i !== index
                                                )
                                              );
                                            }}
                                            style={{
                                              position: "absolute",
                                            }}
                                          >
                                            <i
                                              className="fa fa-window-close"
                                              style={{
                                                fontSize: "20px",
                                                transform: "translateX(5px)",
                                                color: "red",
                                              }}
                                            ></i>
                                          </a>
                                        </div>
                                      </Col>
                                    );
                                  }
                                })}
                              </Row>
                            </AvForm>
                          </Col>
                        </Row>
                        {/* </Row> */}
                        <Row className="mt-3">
                          <button
                            className="btn mt-2 btn-primary btn-sm"
                            type="submit"
                          >
                            Submit
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn mt-2 btn-primary btn-sm"
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            Cancel
                          </button>{" "}
                        </Row>
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal fade"
          show={orderDetailModal}
          onHide={setOrderDetailModal}
          size="xl"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Order Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setOrderDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalOrderBookingDetail
              orderBookingID={orderBookingID}
              setOrderDetailModal={setOrderDetailModal}
            ></ModalOrderBookingDetail>
          </div>
        </Modal>
        <Modal
          className="modal fade"
          show={patientDetailModel}
          onHide={setPatientDetailModal}
          size="lg"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Patient Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setPatientDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalPatientDetails
              patientID={patientID}
              setPatientDetailModal={setPatientDetailModal}
            ></ModalPatientDetails>
          </div>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default AddEquipmentInstallation;
