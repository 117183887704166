import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card, CardBody, Container, Form } from "reactstrap";
import { Field, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import CustomInput from "../../Custom/CustomInput";
import { Spin } from "antd";
import { t } from "i18next";
import swal from "sweetalert";
import * as url from "../../Helper/url_helper";
import { post } from "../../Helper/api_helper";
import { useHistory } from "react-router-dom";

const ChangePassword = () => {
  const [oldIsPassword, setOldIsPassword] = useState(true);
  const [newIsPassword, setNewIsPassword] = useState(true);
  const [confirmIsPassword, setConfirmIsPassword] = useState(true);

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //DEFINE SUBMIT FORM FUNCTION AND CALL API
  const handleSubmit = async (formData, { setSubmitting }) => {
    setIsLoading(true);
    try {
      setError(false);
      const result = await post(url.POST_ChangePassword, formData);
      if (!result.IsSuccess) {
        swal("Error!", t(result.MessageCode), "error");
        setSubmitting(false);
        setIsLoading(false);
      } else {
        swal("Success!", t(result.MessageCode), "success");
        setTimeout(() => {
          history.push("/dashboard");
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      swal("Error!", error, "error");
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  // validation schema
  const validationSchema = yup.object().shape({
    OldPassword: yup.string().required(t("Old Password is required")),
    NewPassword: yup.string().required(t("New Password is required")),
    ConfirmPassword: yup
      .string()
      .oneOf([yup.ref("NewPassword"), null], t("Passwords must match")),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      LoginID: objLogin.LoginIDEncrypt,
      OldPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
    },
    validateOnBlur: false,
  });

  const { isSubmitting } = formik;

  return (
    <React.Fragment>
      <div className="page-content">
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <Container fluid={true}>
            <Row>
              <Col xl="2"></Col>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col md="12">
                            <Field
                              type={oldIsPassword ? "password" : "text"}
                              label={"Old Password"}
                              name="OldPassword"
                              id="OldPassword"
                              placeholder={"Enter Old Password"}
                              component={CustomInput}
                              required
                              passwordEye={true}
                              setIsPassword={setOldIsPassword}
                            />
                          </Col>
                          <Col md="12">
                            <Field
                              type={newIsPassword ? "password" : "text"}
                              label={"New Password"}
                              name="NewPassword"
                              id="NewPassword"
                              placeholder={"Enter New password"}
                              component={CustomInput}
                              required
                              passwordEye={true}
                              setIsPassword={setNewIsPassword}
                            />
                          </Col>
                          <Col md="12">
                            <Field
                              type={confirmIsPassword ? "password" : "text"}
                              label={"Confirm Password"}
                              name="ConfirmPassword"
                              id="ConfirmPassword"
                              placeholder={"Confirm New Password"}
                              component={CustomInput}
                              required
                              passwordEye={true}
                              setIsPassword={setConfirmIsPassword}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="12">
                            <Button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn mb-2 mt-4 btn-xs"
                            >
                              {"Change Password"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                  </CardBody>
                </Card>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
