import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import PaginationButtons from "../../../Custom/Pagination";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import * as Yup from "yup";
import { Row, Col, Modal, Accordion } from "react-bootstrap";
import CustomInput from "../../../Custom/CustomInput";

import {
  POST_AdminStaffServiceCancelRequestList,
  POST_AdminStaffOrderBookingServiceCancelApprovalReject,
  GET_CompanyDropDownList,
} from "../../../Helper/url_helper";
import { get, post } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import CustomSelect from "../../../Custom/CustomSelect";
import moment from "moment";
import swal from "sweetalert";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";

const ServiceCancelApprovalRejectList = () => {
  const [serviceCancelApprovalModal, setServiceCancelApprovalModal] =
    useState(false);
  const [staffServiceCancelRequestList, setStaffServiceCancelRequestList] =
    useState([]);
  const [recordCount, setRecordCount] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchvalue] = useState("");
  let updatedSearchValue = searchValue;

  const [activeDefault, setActiveDefault] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));
  var UserForms = JSON.parse(localStorage.getItem("userRights"));

  const validationSchema = Yup.object().shape({});

  const formikFilter = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
      FromDate: "",
      ToDate: "",
      StaffServiceCancelType: 0,
      StaffServiceCancelRequestStatus: 0,
    },
    validateOnBlur: false,
  });

  const {
    handleValidSubmit,
    values: {
      CompanyID,
      FromDate,
      ToDate,
      StaffServiceCancelType,
      StaffServiceCancelRequestStatus,
    },
  } = formikFilter;

  const getData = async (page = 1, sizePerPage = 10) => {
    if (
      `${moment(FromDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000` >
      `${moment(ToDate).format("YYYY-MM-DD").replace(/^\-/, "")} 00:00:00.000`
    ) {
      swal({
        title: "warning",
        text: "To Date must be greater then  From Date!",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setIsLoading(true);
    let response = await post(POST_AdminStaffServiceCancelRequestList, {
      CurrentPage: page,
      PageSize: sizePerPage,
      Search: updatedSearchValue,
      Sorting: "",
      StaffIDEncrypted: "",
      CompanyIDEncrypt: CompanyID,
      FromDate: FromDate ? moment(FromDate).format("DD-MMM-YYYY") : "",
      ToDate: ToDate ? moment(ToDate).format("DD-MMM-YYYY") : "",
      StaffServiceCancelType: StaffServiceCancelType,
      StaffServiceCancelRequestStatus: StaffServiceCancelRequestStatus,
    });
    if (response?.IsSuccess) {
      setRecordCount(response?.TotalRecordCount);
      setStaffServiceCancelRequestList(
        response?.AdminStaffServiceCancelRequestList
      );
      setCurrentSizePerPage(sizePerPage);
      if (
        page > 1 &&
        response?.AdminStaffServiceCancelRequestList?.length === 0
      ) {
        setCurrentPage(page - 1);
        getData(page - 1);
      } else {
        setCurrentPage(page);
      }
      setIsLoading(false);
    } else {
      swal("Error!", t(response.MessageCode), "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCompanyDDL();
    getData();
  }, []);

  const staffServiceCancelTypeOptions = [
    { label: "All", value: 0 },
    { label: "Single Day", value: 1 },
    { label: "All Remaining Days", value: 2 },
  ];

  const staffServiceCancelRequestStatusOptions = [
    { label: "All", value: 0 },
    { label: "Pending", value: 1 },
    { label: "Approved", value: 2 },
    { label: "Rejected", value: 3 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const validationSchemaServiceApproval = Yup.object().shape({
    CancelApprovalRejectRemarks: Yup.string()
      .nullable()
      .required("Remarks is required field"),
  });

  const formikServiceCancelApproval = useFormik({
    validationSchema: validationSchemaServiceApproval,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitServiceApproval(values, formikHelpers),
    initialValues: {
      OrderBookingServiceScheduleID: "",
      OrderBookingID: "",
      ServiceID: "",
      StaffID: "",
      StaffServiceCancelRequestStatus: 0,
      ServiceCancelType: 0,
      CancelApprovalRejectRemarks: "",
      StaffNo: "",
      StaffName: "",
      PatientName: "",
      ServiceName: "",
      submitType: "",
      StaffServiceCancelRequestStatusName: "",
      StaffServiceCancelTypeName: "",
      MobileNo: "",
      CancelRemarks: "",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,

    values: {
      StaffID,
      StaffNo,
      StaffName,
      ServiceName,
      PatientName,
      submitType,
      StaffServiceCancelRequestStatusName,
      StaffServiceCancelTypeName,
      MobileNo,
      CancelRemarks,
    },
  } = formikServiceCancelApproval;

  const handleValidSubmitServiceApproval = (values, { setSubmitting }) => {
    setIsLoading(true);

    let ServiceCancelRequestStatus = 0;
    if (submitType === "Approve") {
      ServiceCancelRequestStatus = 2;
    } else if (submitType === "Reject") {
      ServiceCancelRequestStatus = 3;
    }

    const data = {
      OrderBookingServiceScheduleIDEncrypt:
        values.OrderBookingServiceScheduleID,
      OrderBookingIDEncrypt: values.OrderBookingID,
      ServiceIDEncrypt: values.ServiceID,
      StaffIDEncrypt: values.StaffID,
      StaffServiceCancelRequestStatus: ServiceCancelRequestStatus,
      ServiceCancelType: values.ServiceCancelType,
      CancelApprovalRejectRemarks: values.CancelApprovalRejectRemarks,
      ApprovalRejectByEncrypt: objLogin?.LoginIDEncrypt,
    };

    // console.log("Data", data);

    post(POST_AdminStaffOrderBookingServiceCancelApprovalReject, data)
      .then((response) => {
        if (response?.IsSuccess) {
          swal({
            title: "Success",
            text: t(response?.MessageCode),
            icon: "success",
            button: "OK",
          });

          formikServiceCancelApproval.resetForm();
          setServiceCancelApprovalModal(false);
          getData(1, currentSizePerPage);
        } else {
          swal({
            title: "Error",
            text: t(response?.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <PageTitle
            motherMenu="Transaction"
            activeMenu="Staff Service Cancel Request"
          />
          <Row className="mb-3">
            <Col lg={12}>
              <Accordion
                className="accordion accordion-no-gutter accordion-header-bg"
                defaultActiveKey={0}
              >
                <div className="accordion__item" key={1}>
                  <Accordion.Toggle
                    eventKey={1}
                    className={`accordion__header  ${
                      activeDefault === 1 ? "" : "collapsed"
                    }`}
                    style={{
                      minWidth: "100%",
                    }}
                    onClick={() =>
                      setActiveDefault(activeDefault === 1 ? -1 : 1)
                    }
                  >
                    <span
                      className="accordion__header--text"
                      style={{ float: "left" }}
                    >
                      <i
                        class="fa fa-filter"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      &nbsp;
                      {"Filter Staff Service Cancel Request List"}
                    </span>
                    <span className="accordion__header--indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={1}>
                    <div className="accordion__body--text">
                      <FormikProvider value={formikFilter}>
                        <Form onSubmit={formikFilter.handleSubmit}>
                          <Row>
                            {objLogin?.CompanyType === 1 &&
                            objLogin?.UserType == 1 ? (
                              <Col lg={3}>
                                <Field
                                  label={"Company"}
                                  name="CompanyID"
                                  placeholder={"Select Company"}
                                  options={companyOptions}
                                  component={CustomSelect}
                                  isClearable={false}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            <Col lg={3}>
                              <Field
                                label={"Service Cancel Type"}
                                name="StaffServiceCancelType"
                                placeholder={"Select Service Cancel Type"}
                                options={staffServiceCancelTypeOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Status"}
                                name="StaffServiceCancelRequestStatus"
                                placeholder={"Select Status"}
                                options={staffServiceCancelRequestStatusOptions}
                                component={CustomSelect}
                                isClearable={false}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"From Date"}
                                name="FromDate"
                                placeholder={"Select From Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                type="text"
                                label={"To Date"}
                                name="ToDate"
                                placeholder={"Select To Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <label></label>
                              <div className="d-flex">
                                <div className="d-lg-block">
                                  <Link
                                    className="btn btn-primary btn-xs btn-rounded"
                                    onClick={(e) => {
                                      getData();
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </Col>
          </Row>

          <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="input-group search-area ml-auto d-inline-flex mr-3">
              <input
                type="text"
                style={{ height: "36px" }}
                className="form-control"
                placeholder="Search here"
                value={searchValue}
                onChange={(event) => {
                  updatedSearchValue = event.target.value;
                  setSearchvalue(updatedSearchValue);
                  getData();
                }}
              />
              <div className="input-group-append">
                <button type="button" className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <div
                  id="StaffServiceCancel_List"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="table table-striped StaffServiceCancel_List mb-4 dataTablesCard fs-14 dataTable no-footer"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Order No.: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Order No.
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Patient Name
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Staff Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Staff Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Service Name: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Service Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Service Date: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Service Date
                        </th>
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Cancel Remarks: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Remarks
                        </th> */}
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Service Cancel Type: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Service Cancel Type
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                          style={{ width: "20%" }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                          style={{ width: "10%" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recordCount > 0 ? (
                        staffServiceCancelRequestList?.map((element, i) => (
                          <tr
                            role="row"
                            className="odd"
                            style={{
                              backgroundColor:
                                element.StaffServiceCancelRequestStatus === 1
                                  ? "#cecdd036"
                                  : element.StaffServiceCancelRequestStatus ===
                                    2
                                  ? "#c3d9b3"
                                  : element.StaffServiceCancelRequestStatus ===
                                    3
                                  ? "#fde5e5"
                                  : // : element.StaffServiceCancelRequestStatus === 3
                                    // ? "#ebf6e4"

                                    "",
                            }}
                          >
                            <td>{element.OrderBookingNo}</td>
                            <td>{element.PatientName}</td>
                            <td>{element.StaffName}</td>
                            <td>{element.ServiceName}</td>
                            <td>{element.ServiceDate}</td>
                            {/* <td>{element.CancelRemarks}</td> */}
                            <td>{element.StaffServiceCancelTypeName}</td>
                            <td>{element.StaffServiceCancelStatusName}</td>

                            <td style={{ textAlign: "center" }}>
                              {element.StaffServiceCancelRequestStatus == 1 ? (
                                <div className="d-flex">
                                  <div
                                    className="btn btn-light shadow btn-xs sharp mr-1"
                                    title="Edit"
                                    onClick={() => {
                                      formikServiceCancelApproval.setFieldValue(
                                        "OrderBookingServiceScheduleID",
                                        element.OrderBookingServiceScheduleIDEncrypt
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "OrderBookingID",
                                        element.OrderBookingIDEncrypt
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "StaffID",
                                        element.StaffIDEncrypt
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "ServiceID",
                                        element.ServiceIDEncrypt
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "StaffNo",
                                        element.StaffNo
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "StaffName",
                                        element.StaffName
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "ServiceName",
                                        element.ServiceName
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "PatientName",
                                        element.PatientName
                                      );

                                      formikServiceCancelApproval.setFieldValue(
                                        "StaffServiceCancelRequestStatusName",
                                        element.StaffServiceCancelStatusName
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "StaffServiceCancelRequestStatus",
                                        element.StaffServiceCancelRequestStatus
                                      );

                                      formikServiceCancelApproval.setFieldValue(
                                        "StaffServiceCancelTypeName",
                                        element.StaffServiceCancelTypeName
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "ServiceCancelType",
                                        element.StaffServiceCancelType
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "MobileNo",
                                        element.MobileNo
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "CancelRemarks",
                                        element.CancelRemarks
                                      );
                                      formikServiceCancelApproval.setFieldValue(
                                        "CancelApprovalRejectRemarks",
                                        ""
                                      );

                                      setServiceCancelApprovalModal(true);
                                    }}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="mt-3 ml-3">{"No Record Found"}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div
                      className="dataTables_info"
                      id="example5_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {recordCount > 0
                        ? (currentPage - 1) * currentSizePerPage + 1
                        : 0}{" "}
                      to{" "}
                      {recordCount > currentPage * currentSizePerPage
                        ? currentPage * currentSizePerPage
                        : recordCount}{" "}
                      of {recordCount} entries
                    </div>
                    <div className="paging_simple_numbers d-flex  justify-content-center align-items-center">
                      <PaginationButtons
                        totalPages={recordCount / currentSizePerPage}
                        currentPage={currentPage}
                        getData={getData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <span
              class="badge"
              style={{ backgroundColor: "#cecdd036", color: "#3e4954" }}
            >
              Pending
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#c3d9b3", color: "#3e4954" }}
            >
              Accepted
            </span>
            &nbsp;
            <span
              class="badge"
              style={{ backgroundColor: "#fde5e5", color: "#3e4954" }}
            >
              Rejected
            </span>
            &nbsp;
            {/* <span
              class="badge"
              style={{ backgroundColor: "#ebf6e4", color: "#3e4954" }}
            >
              Cancel
            </span> */}
          </div>

          <Modal
            className="modal fade"
            show={serviceCancelApprovalModal}
            onHide={setServiceCancelApprovalModal}
            size="lg"
          >
            <FormikProvider value={formikServiceCancelApproval}>
              <Form onSubmit={formikServiceCancelApproval.handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">
                    Staff Service Cancel Approval/Reject
                  </h4>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setServiceCancelApprovalModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Staff No. </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{StaffNo}</Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Staff Name </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{StaffName}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Service Name </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{ServiceName}</Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Patient Name </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>

                        <Col lg={5}>{PatientName}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Staff Mobile No. </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{MobileNo}</Col>
                      </Row>
                    </Col>

                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Status </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={5}>{StaffServiceCancelRequestStatusName}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b> Service Cancel Type </b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>
                        <Col lg={6}>{StaffServiceCancelTypeName}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="mt-3">
                    {" "}
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <b>Cancel Remarks :</b>
                        </Col>
                        <Col lg={1} style={{ marginLeft: "-35px" }}>
                          :
                        </Col>

                        <Col lg={5}>{CancelRemarks}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={6}>
                      <Field
                        label={"Approval/Reject Remarks"}
                        type="textarea"
                        name="CancelApprovalRejectRemarks"
                        placeholder={"Enter Remark"}
                        component={CustomInput}
                        rows={4}
                        required
                      />
                    </Col>
                  </Row>
                  <hr></hr>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-xs btn-primary"
                    onClick={(e) => {
                      setFieldValue("submitType", "Approve");
                    }}
                  >
                    Approve
                  </button>

                  <button
                    type="submit"
                    className="btn btn-xs btn-danger"
                    onClick={(e) => {
                      setFieldValue("submitType", "Reject");
                    }}
                  >
                    Reject
                  </button>

                  <button
                    type="button"
                    onClick={() => setServiceCancelApprovalModal(false)}
                    className="btn btn-xs btn-light"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </Modal>
        </Spin>
      </Fragment>
    </>
  );
};

export default ServiceCancelApprovalRejectList;
