import React, { Fragment, useEffect, useState } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import { Row, Col } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import { Spin } from "antd";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_GetStaffUtilizationReport,
  POST_GetStaffUtilizationGenderWiseReport,
  POST_GetStaffUtilizationReligionWiseReport,
  POST_GetStaffUtilizationShiftWiseReport,
  POST_GetYearlyAnalysisDataWiseYearDDLList,
  POST_GetDepartmentList,
  POST_GetDesignationList,
  POST_GetReligionDDL,
  POST_GetAreaListOfExstingPatientAndStaff,
} from "../../../Helper/url_helper";

const UtilizationStaffReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [yearReportAnalysisOptions, setYearAnalysisReportOptions] = useState(
    []
  );

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [areaReportOptions, setAreaReportOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchemaReports = Yup.object().shape({
    UtilizationReportID: Yup.string().required(
      "Utilization Report Type is required field"
    ),
    Year: Yup.string().required("Year is required field"),
    UtilizedStatus: Yup.string().required("Utilized Status is required field"),
  });

  const formik = useFormik({
    validationSchema: validationSchemaReports,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: props.companyID,
      Report: props.reportID,
      UtilizationReportID: "",
      FromDate: "",
      ToDate: "",
      Year: "",
      Month: 0,
      UtilizedStatus: "",
      AreaID: 0,
      DepartmentID: 0,
      DesignationID: 0,
      GenderID: 0,
      ReligionID: 0,
    },
    validateOnBlur: false,
  });

  const {
    values: { CompanyID, UtilizationReportID },
  } = formik;

  useEffect(() => {
    loadYearlyAnalysisDataDDL();
    loadDepartmentDDL();
    loadDesignationDDL();
    loadReligionDDL();
    loadAreaDDL();
  }, []);

  useEffect(() => {
    formik.setFieldValue("Year", "");
    formik.setFieldValue("Month", 0);
    formik.setFieldValue("UtilizedStatus", "");
  }, [UtilizationReportID]);

  const utilizationReportOptions = [
    { label: "Utilization Staff  Report", value: 1 },
    { label: "Utilization Staff Gender Wise Summary Report", value: 2 },
    { label: "Utilization Staff Religion Wise Summary Report", value: 3 },
    { label: "Utilization Staff Shifts(Hours) Wise Summary Report", value: 4 },
  ];

  const utilizationTypeReportOptions = [
    { label: "All", value: 0 },
    { label: "Utilized Staff", value: 1 },
    { label: "Unutilized Staff", value: 2 },
  ];

  const monthReportOptions = [
    { label: "All", value: 0 },
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const optionGender = [
    { label: "All", value: 0 },
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
    { label: "Transgender", value: 3 },
  ];

  const loadAreaDDL = async () => {
    //await post(POST_GetAreaListOfExstingPatientAndStaff)
    await post(POST_GetAreaListOfExstingPatientAndStaff, {
      CompanyIDEncrypted: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setAreaReportOptions(
            res.AreaList.map((item) => ({
              label: item.AreaName,
              value: item.AreaID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadReligionDDL = async () => {
    await post(POST_GetReligionDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setReligionOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadYearlyAnalysisDataDDL = () => {
    setIsLoading(true);
    post(POST_GetYearlyAnalysisDataWiseYearDDLList, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setYearAnalysisReportOptions(
            res.AdminGetYearDDLList.map((item) => ({
              label: item.YearName,
              value: item.YearName,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const loadDepartmentDDL = async () => {
    await post(POST_GetDepartmentList)
      .then((res) => {
        if (res.IsSuccess) {
          setDepartmentOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadDesignationDDL = async () => {
    await post(POST_GetDesignationList)
      .then((res) => {
        if (res.IsSuccess) {
          setDesignationOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      Year: values.Year,
      Month: values.Month,
      CompanyIDEncrypt: CompanyID,
      FromDate: values.FromDate,
      ToDate: values.ToDate,
      UtilizedStatus: values.UtilizedStatus,
      AreaID: values.AreaID,
      DepartmentID: values.DepartmentID,
      DesignationID: values.DesignationID,
      GenderID: values.GenderID,
      ReligionID: values.ReligionID,
    };

    // console.log("Data", Data);

    //let response = await post(POST_EquipmentReportInExcelDownload, Data);

    let response = "";

    if (UtilizationReportID == 1) {
      response = await post(POST_GetStaffUtilizationReport, Data);
    } else if (UtilizationReportID == 2) {
      response = await post(POST_GetStaffUtilizationGenderWiseReport, Data);
    } else if (UtilizationReportID == 3) {
      response = await post(POST_GetStaffUtilizationReligionWiseReport, Data);
    } else if (UtilizationReportID == 4) {
      response = await post(POST_GetStaffUtilizationShiftWiseReport, Data);
    }

    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} style={{ textAlign: "left", fontSize: "15px" }}>
                <div style={{ color: "black" }}>Filters</div> <br></br>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <Field
                  label={"Utilization Staff Report Type"}
                  name="UtilizationReportID"
                  options={utilizationReportOptions}
                  placeholder={"Select Utilization Staff Report Type"}
                  component={CustomSelect}
                  isClearable={false}
                  required
                />
              </Col>

              <Col lg={3}>
                <Field
                  label={"Year"}
                  name="Year"
                  options={yearReportAnalysisOptions}
                  placeholder={"Select Year"}
                  component={CustomSelect}
                  isClearable={false}
                  required
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Month"}
                  name="Month"
                  options={monthReportOptions}
                  placeholder={"Select Month"}
                  component={CustomSelect}
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Utilized Status"}
                  name="UtilizedStatus"
                  options={utilizationTypeReportOptions}
                  placeholder={"Select Utilized Status"}
                  component={CustomSelect}
                  required
                />
              </Col>
            </Row>
            {UtilizationReportID === 1 ? (
              <>
                <Row>
                  {" "}
                  <Col lg={3}>
                    <Field
                      label={"Department"}
                      name="DepartmentID"
                      options={departmentOptions}
                      placeholder={"Select Department"}
                      component={CustomSelect}
                    />
                  </Col>
                  <Col lg={3}>
                    <Field
                      label={"Designation"}
                      name="DesignationID"
                      options={designationOptions}
                      placeholder={"Select Designation"}
                      component={CustomSelect}
                    />
                  </Col>
                  <Col lg={3}>
                    <Field
                      label={"Area"}
                      name="AreaID"
                      options={areaReportOptions}
                      placeholder={"Select Area"}
                      component={CustomSelect}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Field
                      label={"Religion"}
                      name="ReligionID"
                      options={religionOptions}
                      placeholder={"Select Religion"}
                      component={CustomSelect}
                    />
                  </Col>
                  <Col lg={3}>
                    <Field
                      label={"Gender"}
                      name="GenderID"
                      options={optionGender}
                      placeholder={"Select Gender"}
                      component={CustomSelect}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <hr></hr>
            <Row className="mt-3">
              <Col lg={12}>
                <button type="submit" className="btn mr-2 btn-primary btn-sm">
                  View Report
                </button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Spin>
    </Fragment>
  );
};

export default UtilizationStaffReport;
