import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { Col, Label, Row } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import defaultIcon from "../../../../images/avatar/500x500.png";
import { useHistory } from "react-router-dom";
import { get, post } from "../../../Helper/api_helper";
import {
  POST_GetEmailSettings,
  POST_SaveEmailSettings,
  POST_VerifyEmailSettings,
} from "../../../Helper/url_helper";
import swal from "sweetalert";
import { t } from "i18next";
import { Spin } from "antd";

const EmailSetting = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchema = Yup.object().shape({
    DisplayName: Yup.string().required("Display Name is required field"),
    UserName: Yup.string().required("User Name is required field"),
    FromEmail: Yup.string()
      .required("From Email is required field")
      .email("Enter a valid email"),
    Password: Yup.string().required("Password is required field"),
    ReTypePassword: Yup.string()
      .required("Re-Type Password is required field")
      .oneOf([Yup.ref("Password"), null], "Passwords must match"),
    ServerName: Yup.string().required("Server Name is required field"),
    SMTPPort: Yup.string().required("SMTP Port is required field"),
  });

  const formikEmailSetting = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      EmailSettingsID: "",
      DisplayName: "",
      UserName: "",
      FromEmail: "",
      Password: "",
      ReTypePassword: "",
      ServerName: "",
      SMTPPort: "",
      EnableSSL: true,
      submitType: "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: { EmailSettingsID, submitType, EnableSSL },
  } = formikEmailSetting;

  useEffect(() => {
    get_GetEmailSettings();
  }, []);

  const get_GetEmailSettings = async () => {
    setIsLoading(true);
    let response = await get(POST_GetEmailSettings);
    if (response.IsSuccess) {
      setValues({
        EmailSettingsID: response.EmailSettingsIDEncrypted,
        DisplayName: response.DisplayName,
        UserName: response.SMTPUserName,
        FromEmail: response.SMTPFromEmailID,
        Password: response.SMTPPassword,
        ServerName: response.SMTPServerName,
        SMTPPort: response.SMTPPort,
        EnableSSL: response.SMTPEnableSSL,
        ReTypePassword: "",
      });
    } else {
      swal("Error!", t(response.MessageCode), "error");
    }
    setIsLoading(false);
  };
  const handleValidSubmit = async (values) => {
    const Data = {
      EmailSettingsIDEncrypted: EmailSettingsID,
      DisplayName: values.DisplayName,
      SMTPUserName: values.UserName,
      SMTPFromEmailID: values.FromEmail,
      SMTPPassword: values.Password,
      SMTPServerName: values.ServerName,
      SMTPPort: values.SMTPPort,
      SMTPEnableSSL: EnableSSL,
      LoginIDEncrypted: objLogin.LoginIDEncrypt,
    };

    if (submitType == "Verify") {
      setIsLoading(true);
      let response = await post(POST_VerifyEmailSettings, Data);
      if (response.IsSuccess) {
        setIsLoading(false);
        swal({
          title: "Success",
          text: t(response.MessageCode),
          icon: "success",
          button: "OK",
        });
      } else {
        setIsLoading(false);
        swal({
          title: "Error",
          text: t(response.MessageCode),
          icon: "error",
          button: "OK",
        });
      }
    } else {
      setIsLoading(true);

      let response = await post(POST_SaveEmailSettings, Data);
      if (response.IsSuccess) {
        setIsLoading(false);
        swal({
          title: "Success",
          text: t(response.MessageCode),
          icon: "success",
          button: "OK",
        });
        if (EmailSettingsID == 0) {
          get_GetEmailSettings();
        }
      } else {
        setIsLoading(false);
        swal({
          title: "Error",
          text: t(response.MessageCode),
          icon: "error",
          button: "OK",
        });
      }
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle activeMenu="Email SMTP Setting" motherMenu="Configuration" />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formikEmailSetting}>
                    <Form onSubmit={formikEmailSetting.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"Display Name"}
                            name="DisplayName"
                            placeholder={"Enter Display Name"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"User Name"}
                            name="UserName"
                            placeholder={"Enter User Name"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"From Email"}
                            name="FromEmail"
                            placeholder={"Enter From Email"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="password"
                            label={"Password"}
                            name="Password"
                            placeholder={"Enter Password"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="password"
                            label={"Re-Type Password"}
                            name="ReTypePassword"
                            placeholder={"Re-Type Password"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"Server Name"}
                            name="ServerName"
                            placeholder={"Enter Server Name"}
                            component={CustomInput}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="number"
                            label={"SMTP Port"}
                            name="SMTPPort"
                            placeholder={"Enter SMTP Port"}
                            component={CustomInput}
                            min={0}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <div
                            className="custom-control custom-checkbox mb-3 checkbox-success"
                            style={{ marginTop: "47px", zIndex: 0 }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked
                              className="custom-control-input"
                              id="EnableSSL"
                              name="EnableSSL"
                              onChange={(e) => {
                                formikEmailSetting.setFieldValue(
                                  "EnableSSL",
                                  e.target.checked
                                );
                              }}
                              checked={EnableSSL}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="EnableSSL"
                            >
                              Enable SSL
                            </label>
                          </div>
                        </Col>
                      </Row>

                      <button
                        type="submit"
                        className="btn mr-2 btn-md btn-primary"
                        onClick={(e) => {
                          setFieldValue("submitType", "Verify");
                        }}
                      >
                        Verify
                      </button>
                      <button
                        type="submit"
                        className="btn btn-md mr-2 btn-primary"
                        onClick={(e) => {
                          setFieldValue("submitType", "Save");
                        }}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-md btn-light"
                        onClick={(e) => {
                          history.push("/");
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default EmailSetting;
