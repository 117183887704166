import React, { Fragment, useEffect, useState } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";

import { Row, Col } from "react-bootstrap";
import { post } from "../../../Helper/api_helper";
import { Spin } from "antd";

import swal from "sweetalert";
import { t } from "i18next";

import {
  POST_EnquiryStatusReportInExcel,
  POST_GetFollowupRemarksList,
  POST_GetEnquiriesYearDDLList,
} from "../../../Helper/url_helper";

const EnquiryStatusReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [followupRemarksReportOptions, setFollowupRemarksReportOptions] =
    useState([]);

  const [yearReportOptions, setYearReportOptions] = useState([]);

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchemaReports = Yup.object().shape({
    EnquiryReport: Yup.string().required("Enquiry Report is required field"),
    Year: Yup.string().required("Year is required field"),
    // Month: Yup.string().required("Month is required field"),
  });

  const formik = useFormik({
    validationSchema: validationSchemaReports,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      EnquiryReport: "",
      CompanyID: props.companyID,
      Report: props.reportID,
      Month: 0,
      Year: "",
      EnquiryStatus: 0,
      EnquiryType: 0,
      FollowupRemarks: 0,
    },
    validateOnBlur: false,
  });

  const {
    values: {
      EnquiryReport,
      CompanyID,

      EnquiryStatus,
      EnquiryType,
      FollowupRemarks,
    },
  } = formik;

  useEffect(() => {
    loadEnquiriesYearsDDL();
    loadFollowupRemarksDDL();
  }, []);

  useEffect(() => {
    formik.setFieldValue("FollowupRemarks", 0);
    // loadFollowupRemarksDDL();
    //loadEnquiriesYearsDDL();
  }, [EnquiryReport]);

  const enquiryReportOptions = [
    { label: "Enquiry Status Summary Report", value: 1 },
    { label: "Enquiry Status Detail Report", value: 2 },
    { label: "Enquiry Status Summary Followup Remark Wise Report", value: 3 },
  ];

  const enquiryStatusReportOptions = [
    { label: "All", value: 0 },
    { label: "Open", value: 1 },
    { label: "Close", value: 2 },
  ];

  const enquiryTypeReportOptions = [
    { label: "All", value: 0 },
    { label: "Service", value: 1 },
    { label: "Equipments", value: 2 },
    { label: "Pathology", value: 3 },
    { label: "Radiology", value: 4 },
    { label: "Packages", value: 5 },
    { label: "Health Checkup", value: 6 },
    { label: "Others", value: 7 },
  ];

  // const yearReportOptions = [
  //   { label: "2021", value: 2021 },
  //   { label: "2022", value: 2022 },
  //   { label: "2023", value: 2023 },
  // ];

  const monthReportOptions = [
    { label: "All", value: 0 },
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const loadEnquiriesYearsDDL = () => {
    setIsLoading(true);
    post(POST_GetEnquiriesYearDDLList, {
      CompanyIDEncrypt: props.companyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setYearReportOptions(
            res.AdminGetYearDDLList.map((item) => ({
              label: item.YearName,
              value: item.YearName,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };
  // const loadEnquiriesYearsDDL = async () => {
  //   await post(POST_GetEnquiriesYearDDLList)
  //     .then((res) => {
  //       if (res.IsSuccess) {
  //         setYearReportOptions(
  //           res.List.map((item) => ({
  //             label: item.YearName,
  //             value: item.YearName,
  //           }))
  //         );
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // };

  const loadFollowupRemarksDDL = async () => {
    await post(POST_GetFollowupRemarksList)
      .then((res) => {
        if (res.IsSuccess) {
          setFollowupRemarksReportOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  // const followupRemarksReportOptions = [
  //   { label: "All", value: 0 },
  //   { label: "Interested", value: 1 },
  //   { label: "Not Responds", value: 2 },
  //   { label: "Price Issue", value: 3 },
  // ];

  const handleValidSubmit = async (values) => {
    setIsLoading(true);
    const Data = {
      Month: values.Month,
      Year: values.Year,
      EnquiryStatus: EnquiryStatus,
      EnquiryType: EnquiryType,
      FollowupRemarksID: FollowupRemarks,
      EnquiryReportType: EnquiryReport,
      CompanyIDEncrypt: CompanyID,
    };
    //  console.log("Data", Data);

    let response = await post(POST_EnquiryStatusReportInExcel, Data);
    if (response.IsSuccess) {
      setIsLoading(false);
      window.open(response.FilePath);
    } else {
      setIsLoading(false);
      swal({
        title: "Error",
        text: t(response.MessageCode),
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} style={{ textAlign: "left", fontSize: "15px" }}>
                <div style={{ color: "black" }}>Filters</div> <br></br>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Field
                  label={"Enquiry Report Name"}
                  name="EnquiryReport"
                  options={enquiryReportOptions}
                  placeholder={"Select Enquiry Report Name"}
                  component={CustomSelect}
                  isClearable={false}
                  required
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Year"}
                  name="Year"
                  options={yearReportOptions}
                  placeholder={"Select Year"}
                  component={CustomSelect}
                  isClearable={false}
                  required
                />
              </Col>
              <Col lg={3}>
                <Field
                  label={"Month"}
                  name="Month"
                  options={monthReportOptions}
                  placeholder={"Select Month"}
                  component={CustomSelect}
                />
              </Col>
            </Row>
            <Row>
              {EnquiryReport == 2 ? (
                <>
                  <Col lg={3}>
                    <Field
                      label={"Enquiry Status"}
                      name="EnquiryStatus"
                      options={enquiryStatusReportOptions}
                      placeholder={"Select Enquiry Status"}
                      component={CustomSelect}
                    />
                  </Col>
                  <Col lg={3}>
                    <Field
                      label={"Enquiry Type"}
                      name="EnquiryType"
                      options={enquiryTypeReportOptions}
                      placeholder={"Select Enquiry Type"}
                      component={CustomSelect}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
              {EnquiryReport == 2 || EnquiryReport == 3 ? (
                <Col lg={5}>
                  <Field
                    label={"Followup Remarks"}
                    name="FollowupRemarks"
                    options={followupRemarksReportOptions}
                    placeholder={"Select  Followup Remarks"}
                    component={CustomSelect}
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
            <hr></hr>
            <Row className="mt-3">
              <Col lg={12}>
                <button type="submit" className="btn mr-2 btn-primary btn-sm">
                  View Report
                </button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Spin>
    </Fragment>
  );
};

export default EnquiryStatusReport;
