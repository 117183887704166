import React, { Fragment, useEffect, useState, useRef } from "react";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Form, Label } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import { Row, Col, Tab, Nav, Button, Modal, Accordion } from "react-bootstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import StaffImage from "../../../../images/avatar/500x500.png";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";
import moment from "moment";
import Webcam from "react-webcam";
import {
  GET_CompanyDropDownList,
  GET_GetHeightDDL,
  GET_GetServiceTypeDDL,
  GET_GetWeightDDL,
  POST_CheckStaffQualificationStatus,
  POST_GetAreaList,
  POST_GetCityList,
  POST_GetDepartmentList,
  POST_GetDesignationList,
  POST_GetDocumentDDLListByCheckListType,
  POST_GetLanguageList,
  POST_GetQualificationDDL,
  POST_GetReligionDDL,
  POST_GetServiceDropDownListByTypeSubTypeAndCompany,
  POST_GetServicePriceListByServiceID,
  POST_GetServiceSubTypeDDL,
  POST_GetStaffServiceDetailByID,
  POST_GetStateList,
  POST_StaffAddressesInsert,
  POST_StaffAddressesUpdate,
  POST_StaffBankDetailSave,
  POST_StaffDetailGetByID,
  POST_StaffDocumentDelete,
  POST_StaffDocumentInsert,
  POST_StaffDocumentVerify,
  POST_StaffExperienceDetailDelete,
  POST_StaffExperienceDetailInsert,
  POST_StaffExperienceDetailUpdate,
  POST_StaffInsert,
  POST_StaffOfficialInformationInsert,
  POST_StaffOfficialInformationUpdate,
  POST_StaffPhotoUpload,
  POST_StaffQualificationsDelete,
  POST_StaffQualificationsInsert,
  POST_StaffServiceDelete,
  POST_StaffServiceSave,
  POST_StaffUpdate,
} from "../../../Helper/url_helper";

import Cropper from "react-cropper";
import { dataUrlToFile, dataUrlToFileUsingFetch } from "../../../Custom/Utils";
import "cropperjs/dist/cropper.css";

import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { List } from "antd";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

const videoConstraints = {
  width: 600,
  height: 600,
  facingMode: "environment",
};

const StaffDetails = () => {
  const webcamRef = useRef(null);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  //const [mode, setMode] = useState(0);

  const [activeDefault, setActiveDefault] = useState(0);

  const { StaffIDEncryptParam } = useParams();
  const AuthUser = JSON.parse(localStorage.getItem("userDetails"));

  const [tab, setTab] = useState(1);

  const [qualificationModal, setQualificationModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [experienceModal, setExperienceModal] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [heightOptions, setHeightOptions] = useState([]);
  const [weightOptions, setWeightOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptionsPresent, setCityOptionsPresent] = useState([]);
  const [cityOptionsPermanent, setCityOptionsPermanent] = useState([]);
  const [areaOptionsPresent, setAreaOptionsPresent] = useState([]);
  const [areaOptionsPermanent, setAreaOptionsPermanent] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [serviceSubTypeOptions, setServiceSubTypeOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);

  const [qualificationFile, setQualificationFile] = useState(null);
  const [fileKeyQualification, setFileKeyQualification] = useState();
  const [documentFile, setDocumentFile] = useState(null);
  const [fileKeyDocument, setFileKeyDocument] = useState();

  const [staffQualificationList, setStaffQualificationList] = useState([]);
  const [staffDocumentList, setStaffDocumentList] = useState([]);
  const [staffServiceList, setStaffServiceList] = useState([]);
  const [staffExperienceList, setStaffExperienceList] = useState([]);

  const [countryPrimary, setCountryPrimary] = useState("in");
  const [countryEmergency, setCountryEmergency] = useState("in");

  const [
    permanentAddressSuggesionDivStatus,
    setPermanentAddressSuggesionDivStatus,
  ] = useState(false);
  const [addressSuggesionDivStatus, setAddressSuggesionDivStatus] =
    useState(false);

  const { placePredictions, getPlacePredictions, isPlacePredictionLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const optionGender = [
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
  ];

  const optionMaritalStatus = [
    { label: "Unmarried", value: 1 },
    { label: "Married", value: 2 },
    { label: "Divorced", value: 3 },
    { label: "Widow", value: 4 },
  ];

  const optionEmploymentType = [
    { label: "Permanent", value: 1 },
    { label: "Freelancer", value: 2 },
  ];

  const optionSalaryType = [
    { label: "Fix", value: 1 },
    { label: "Hourly", value: 2 },
    { label: "Per Visit", value: 3 },
  ];

  const optionVerificationStatus = [
    { label: "Pending", value: 0 },
    { label: "Verified", value: 1 },
    { label: "Rejected", value: 2 },
  ];

  const loadCompanyDDL = async () => {
    await get(GET_CompanyDropDownList)
      .then((res) => {
        if (res.IsSuccess) {
          setCompanyOptions(
            res.CompanyDDLList.map((item) => ({
              label: item.CompanyName,
              value: item.CompanyIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadReligionDDL = async () => {
    await post(POST_GetReligionDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setReligionOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadLanguageDDL = async () => {
    await post(POST_GetLanguageList)
      .then((res) => {
        if (res.IsSuccess) {
          setLanguageOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadQualificationDDL = async () => {
    await post(POST_GetQualificationDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setQualificationOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadDocumentDDL = async () => {
    await post(POST_GetDocumentDDLListByCheckListType, { CheckListType: 2 })
      .then((res) => {
        if (res.IsSuccess) {
          setDocumentOptions(
            res.DocumentList.map((item) => ({
              label: item.DocumentName,
              value: item.DocumentID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadStateDDL = async () => {
    await post(POST_GetStateList)
      .then((res) => {
        if (res.IsSuccess) {
          setStateOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadCityDDLPermanent = async (selectedStateID) => {
    await post(POST_GetCityList + selectedStateID)
      .then((res) => {
        if (res.IsSuccess) {
          setCityOptionsPermanent(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadCityDDLPresent = async (selectedStateID) => {
    await post(POST_GetCityList + selectedStateID)
      .then((res) => {
        if (res.IsSuccess) {
          setCityOptionsPresent(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadAreaDDLPermanent = async (selectedCityID) => {
    await post(POST_GetAreaList + selectedCityID)
      .then((res) => {
        if (res.IsSuccess) {
          setAreaOptionsPermanent(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            PermanentAreaID &&
            res.List?.filter((x) => x.CategoryID == PermanentAreaID).length == 0
          ) {
            formikAddress.setFieldValue("PermanentAreaID", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadAreaDDLPresent = async (selectedCityID) => {
    await post(POST_GetAreaList + selectedCityID)
      .then((res) => {
        if (res.IsSuccess) {
          setAreaOptionsPresent(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            PresentAreaID &&
            res.List?.filter((x) => x.CategoryID == PresentAreaID).length == 0
          ) {
            formikAddress.setFieldValue("PresentAreaID", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadDepartmentDDL = async () => {
    await post(POST_GetDepartmentList)
      .then((res) => {
        if (res.IsSuccess) {
          setDepartmentOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadDesignationDDL = async () => {
    await post(POST_GetDesignationList)
      .then((res) => {
        if (res.IsSuccess) {
          setDesignationOptions(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadHeightDDL = async () => {
    await get(GET_GetHeightDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setHeightOptions(
            res.HeightDetailDDLList.map((item) => ({
              label: item.Height + " - " + item.HeightCms + " cms",
              value: item.HeightDetailID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadWeightDDL = async () => {
    await get(GET_GetWeightDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setWeightOptions(
            res.WeightDetailDDLList.map((item) => ({
              label: item.Weight,
              value: item.WeightDetailID,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadServiceTypeDDL = async () => {
    await get(GET_GetServiceTypeDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setServiceTypeOptions(
            res.ServiceTypesList.map((item) => ({
              label: item.ServiceTypeEnglish,
              value: item.ServiceTypeIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const loadServiceSubTypeDDL = async (selectedServiceType) => {
    await post(POST_GetServiceSubTypeDDL, {
      ServiceTypeIDEncrypt: selectedServiceType,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setServiceSubTypeOptions(
            res.ClientServiceSubTypesList.map((item) => ({
              label: item.ServiceSubTypeEnglish,
              value: item.ServiceSubTypeIDEncrypt,
            }))
          );
          if (res.ClientServiceSubTypesList?.length === 0) {
            loadServiceDDL(selectedServiceType, "");
          } else {
            setServiceOptions([]);
            if (!StaffServiceIDEncrypted) {
              //formikStaffServices.setFieldValue("ServicePriceList", []);
              formikStaffServices.setFieldValue(
                "CurrentEffectivePriceList",
                []
              );
              formikStaffServices.setFieldValue("NextEffectivePriceList", []);
              formikStaffServices.setFieldValue(
                "CurrentEffectiveDate",
                new Date()
              );
              formikStaffServices.setFieldValue("NextEffectiveDate", "");
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadServiceDDL = async (
    selectedServiceType,
    selectedServiceSubType
  ) => {
    await post(POST_GetServiceDropDownListByTypeSubTypeAndCompany, {
      ServiceTypeIDEncrypted: selectedServiceType,
      ServiceSubTypeIDEncrypted: selectedServiceSubType,
      CompanyIDEncrypted: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setServiceOptions(
            res.ServiceList.map((item) => ({
              label: item.ServiceName,
              value: item.ServiceIDEncrypted,
            }))
          );
          if (
            res.ServiceList?.length > 0 &&
            ServiceIDEncrypted &&
            res.ServiceList?.filter(
              (x) => x.ServiceIDEncrypted == ServiceIDEncrypted
            ).length == 0
          ) {
            formikStaffServices.setFieldValue("ServiceIDEncrypted", "");
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const loadServicePriceList = async (selectedServiceID) => {
    await post(POST_GetServicePriceListByServiceID, {
      ServiceIDEncrypted: selectedServiceID,
      CompanyIDEncrypted: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          // formikStaffServices.setFieldValue("ServicePriceList", [
          //   ...res.ServicePriceList,
          // ]);
          formikStaffServices.setFieldValue("CurrentEffectivePriceList", [
            ...res.ServicePriceList,
          ]);
          formikStaffServices.setFieldValue("NextEffectivePriceList", [
            ...res.ServicePriceList,
          ]);
        }
      })
      .catch((error) => console.log(error));
  };

  const validationSchemaStaffInformation = Yup.object().shape({
    CompanyID: Yup.string().required("Company is required field"),
    FirstName: Yup.string().required("First Name is required field"),
    MiddleName: Yup.string().required("Middle Name is required field"),
    LastName: Yup.string().required("Last Name is required field"),
    Gender: Yup.string().required("Gender is required field"),
    EmailID: Yup.string(), //.required("EmailID is required field"),
    PrimaryMobileNo: Yup.string().required(
      "Primary Mobile No. is required field"
    ),
    DateOfBirth: Yup.string().required("Date Of Birth is required field"),
    MaritalStatus: Yup.string(), //.required("Marital Status is required field"),
    HeightID: Yup.string(), //.required("Height is required field"),
    WeightID: Yup.string(), //.required("Weight is required field"),
    Religion: Yup.string().required("Religion is required field"),
    FatherName: Yup.string().required("Father Name is required field"),
    EmergencyMobileNo: Yup.string().required(
      "Emergency Mobile No. is required field"
    ),
    // BackgroundVerificationStatus: Yup.string().required(
    //   "Background Verification Status is required field"
    // ),
    // BackgroundVerificationRemarks: Yup.string().required(
    //   "Background Verification Remarks is required field"
    // ),
    Languages: Yup.array()
      .required("Languages Known is required field")
      .nullable(),
    Speciality: Yup.string().required("Speciality is required field"),
  });

  const formik = useFormik({
    validationSchema: validationSchemaStaffInformation,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      StaffIDEncrypt: StaffIDEncryptParam
        ? window.atob(StaffIDEncryptParam)
        : "",
      CompanyID: AuthUser?.CompanyIDEncrypt,
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Gender: "",
      HeightID: "",
      WeightID: "",
      EmailID: "",
      CountryCodePrimary: "+91",
      PrimaryMobileNo: "",
      DateOfBirth: "",
      MaritalStatus: "",
      Religion: "",
      CountryCodeEmergency: "+91",
      EmergencyMobileNo: "",
      FatherName: "",
      MotherName: "",
      SpouseName: "",
      // BackgroundVerificationStatus: "",
      // BackgroundVerificationRemarks: "",
      IsSpecialRate: true,
      Languages: "",
      Speciality: "",
    },
    validateOnBlur: false,
  });

  const {
    setFieldValue,
    setValues,
    values: {
      StaffIDEncrypt,
      CompanyID,
      CountryCodePrimary,
      CountryCodeEmergency,
      IsSpecialRate,
      DateOfBirth,
    },
  } = formik;

  useEffect(() => {
    if (!DateOfBirth) {
      formik.setFieldValue("DateOfBirth", "");
    }
  }, [DateOfBirth]);

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (staffPhotoFile === null && staffPhoto === null) {
      setValidationStaffPhoto("Staff Photo is required");
      return false;
    }

    setIsLoading(true);

    var paylod = {
      ...values,
      HeightID: values.HeightID ? values.HeightID : 0,
      WeightID: values.WeightID ? values.WeightID : 0,
      MaritalStatus: values.MaritalStatus ? values.MaritalStatus : 0,
      DateOfBirth: `${moment(values.DateOfBirth)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      LanguagesIDs: Array.from(values.Languages, (x) => x.value).join(","),
      CountryCodePrimary: "+" + CountryCodePrimary.replace(/\+/g, ""),
      CountryCodeEmergency: "+" + CountryCodeEmergency.replace(/\+/g, ""),
      CompanyIDEncrypted: CompanyID,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
      StaffIDEncrypted: StaffIDEncrypt,
    };
    //console.log("payload: ", paylod);

    post(StaffIDEncrypt ? POST_StaffUpdate : POST_StaffInsert, paylod)
      .then((response) => {
        if (response.IsSuccess) {
          if (!StaffIDEncrypt) {
            setFieldValue("StaffIDEncrypt", response.StaffIDEncrypted);
          }
          if (staffPhotoFile) {
            setIsLoading(true);
            let formData = new FormData();
            formData.append(
              "StaffIDEncrypted",
              !StaffIDEncrypt ? response.StaffIDEncrypted : StaffIDEncrypt
            );
            formData.append("LoginIDEncrypted", AuthUser?.LoginIDEncrypt);
            formData.append("Photo", staffPhotoFile);

            postFormData(POST_StaffPhotoUpload, formData)
              .then((res) => {
                if (res.IsSuccess) {
                  setIsLoading(false);
                } else {
                  console.log(res);
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
              });
          }
          swal("Success!", t(response.MessageCode), "success");
          setTab(2);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaStaffOfficialInformation = Yup.object().shape({
    DepartmentID: Yup.string().required("Department is required field"),
    DesignationID: Yup.string().required("Designation is required field"),
    EmploymentType: Yup.string().required("Employment Type is required field"),
    SalaryType: Yup.string().required("Salary Type is required field"),
    DateOfJoining: Yup.string().required("Date Of Joining is required field"),
    SalaryAmount: Yup.string().required("Salary Amount is required field"),
    StaffCategory: Yup.string().required("Staff Category is required field"),
    BackgroundVerificationStatus: Yup.string().required(
      "Background Verification Status is required field"
    ),
    BackgroundVerificationRemarks: Yup.string().required(
      "Background Verification Remarks is required field"
    ),
  });

  const formikStaffOfficialInformation = useFormik({
    validationSchema: validationSchemaStaffOfficialInformation,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitStaffOfficialInformation(values, formikHelpers),
    initialValues: {
      StaffOfficialInformationIDEncrypted: "",
      DepartmentID: "",
      DesignationID: "",
      EmploymentType: "",
      DateOfJoining: "",
      DateOfLeaving: "",
      ReasonOfLeaving: "",
      ReferredBy: "",
      SalaryType: "",
      SalaryAmount: "",
      Remarks: "",
      StaffCategory: "",
      BackgroundVerificationStatus: "",
      BackgroundVerificationRemarks: "",
      BackgroundVerificationStatusPrevious: "",
    },
    validateOnBlur: false,
  });

  const {
    values: {
      StaffOfficialInformationIDEncrypted,
      BackgroundVerificationStatusPrevious,
      DateOfJoining,
      SalaryType,
    },
  } = formikStaffOfficialInformation;

  useEffect(() => {
    if (!DateOfJoining) {
      formikStaffOfficialInformation.setFieldValue("DateOfJoining", "");
    }
  }, [DateOfJoining]);

  const handleValidSubmitStaffOfficialInformation = (
    values,
    { setSubmitting }
  ) => {
    var paylod = {
      ...values,
      DateOfJoining: `${moment(values.DateOfJoining)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      DateOfLeaving: values.DateOfLeaving
        ? `${moment(values.DateOfLeaving)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`
        : "",
      StaffIDEncrypted: StaffIDEncrypt,
    };

    //console.log("payload: ", paylod);

    if (
      values.BackgroundVerificationStatus === 1 &&
      values.BackgroundVerificationStatus !==
        BackgroundVerificationStatusPrevious
    ) {
      check_StaffQualificationStatus(paylod);
    } else {
      if (values.SalaryType !== 1) {
        check_StaffQualificationStatus(paylod);
      } else {
        saveStaffOfficialInformation(paylod);
      }
    }
  };

  const check_StaffQualificationStatus = (payload) => {
    setIsLoading(true);

    post(POST_CheckStaffQualificationStatus, {
      StaffIDEncrypt: StaffIDEncrypt,
      SalaryType: SalaryType,
    })
      .then((response) => {
        if (response.IsSuccess) {
          if (response.IsServiceWiseSalaryAmountSet === false) {
            swal(
              "",
              "Please set the Hourly / Per Visit service wise staff salary amount in service section.",
              "warning"
            );
          } else {
            if (
              response.IsQualificationRequired === true &&
              response.IsStaffHavingQualification === false
            ) {
              swal({
                title:
                  "Are you sure you want to set the Background Verification status as Verified?",
                text: "Staff is having service which requires the qualification!",
                icon: "warning",
                buttons: true,
                dangerMode: false,
              }).then((willSave) => {
                if (willSave) {
                  saveStaffOfficialInformation(payload);
                }
              });
            } else {
              saveStaffOfficialInformation(payload);
            }
          }
        } else {
          console.log(response.MessageCode);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const saveStaffOfficialInformation = (payload) => {
    setIsLoading(true);

    post(
      StaffOfficialInformationIDEncrypted
        ? POST_StaffOfficialInformationUpdate
        : POST_StaffOfficialInformationInsert,
      payload
    )
      .then((response) => {
        if (response.IsSuccess) {
          if (!StaffOfficialInformationIDEncrypted) {
            setFieldValue(
              "StaffOfficialInformationIDEncrypted",
              response.StaffOfficialInformationID
            );
          }
          swal("Success!", t(response.MessageCode), "success");
          history.push("/staff-list");
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validationSchemaAddress = Yup.object().shape({
    PermanentAddress: Yup.string().required("Address is required field"),
    PermanentStateID: Yup.string().required("State is required field"),
    PermanentCityID: Yup.string().required("City is required field"),
    PermanentAreaID: Yup.string().required("Area is required field"),
    PermanentPinCode: Yup.string().required("Pincode is required field"),
    PermanentLocation: Yup.string().required("Location is required field"),
    PresentAddress: Yup.string().required("Address is required field"),
    PresentStateID: Yup.string().required("State is required field"),
    PresentCityID: Yup.string().required("City is required field"),
    PresentAreaID: Yup.string().required("Area is required field"),
    PresentPinCode: Yup.string().required("Pincode is required field"),
    PresentLocation: Yup.string().required("Location is required field"),
  });

  const formikAddress = useFormik({
    validationSchema: validationSchemaAddress,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitAddress(values, formikHelpers),
    initialValues: {
      PermanentAddressIDEncrypted: "",
      PermanentAddress: "",
      PermanentStateID: "",
      PermanentCityID: "",
      PermanentAreaID: "",
      PermanentPinCode: "",
      PermanentAddressFlag: 1,
      PermanentLocation: "",
      PermanentLatitude: "",
      PermanentLongitude: "",
      PresentAddressIDEncrypted: "",
      PresentAddress: "",
      PresentStateID: "",
      PresentCityID: "",
      PresentAreaID: "",
      PresentPinCode: "",
      PresentAddressFlag: 1,
      PresentLocation: "",
      PresentLatitude: "",
      PresentLongitude: "",
    },
    validateOnBlur: false,
  });

  const {
    values: {
      PermanentStateID,
      PresentStateID,
      PermanentCityID,
      PresentCityID,
      PermanentAddressFlag,
      PresentAddressFlag,
      PresentAddress,
      PresentAreaID,
      PresentPinCode,
      PresentLocation,
      PresentLatitude,
      PresentLongitude,
      PermanentAddressIDEncrypted,
      PresentAddressIDEncrypted,
      PermanentAreaID,
    },
  } = formikAddress;

  const handleValidSubmitAddress = (values, { setSubmitting }) => {
    setIsLoading(true);

    var paylod = {
      ...values,
      StaffIDEncrypted: StaffIDEncrypt,
      PermanentLatitude: values.PermanentLatitude.toString(),
      PermanentLongitude: values.PermanentLongitude.toString(),
      PresentLatitude: values.PresentLatitude.toString(),
      PresentLongitude: values.PresentLongitude.toString(),
    };
    //console.log("payload: ", paylod);

    post(
      PresentAddressIDEncrypted
        ? POST_StaffAddressesUpdate
        : POST_StaffAddressesInsert,
      paylod
    )
      .then((response) => {
        if (response.IsSuccess) {
          if (!PresentAddressIDEncrypted) {
            setFieldValue(
              "PresentAddressIDEncrypted",
              response.PresentAddressID
            );
          }
          if (!PermanentAddressIDEncrypted) {
            setFieldValue(
              "PermanentAddressIDEncrypted",
              response.PermanentAddressID
            );
          }
          swal("Success!", t(response.MessageCode), "success");
          setTab(3);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const schemaStaffServices = Yup.object().shape({
    // ServiceTypeIDEncrypted: Yup.string()
    //   .trim()
    //   .required("Sevice Type is a required field"),
    // ServiceSubTypeIDEncrypted: Yup.string()
    //   .trim()
    //   .required("Sevice Sub Type is a required field"),
    ServiceIDEncrypted: Yup.string()
      .trim()
      .required("Sevice Name is a required field"),
  });

  const formikStaffServices = useFormik({
    initialValues: {
      StaffServiceIDEncrypted: "",
      ServiceTypeIDEncrypted: "",
      ServiceSubTypeIDEncrypted: "",
      ServiceIDEncrypted: "",
      Remarks: "",
      CurrentEffectiveDate: new Date(),
      NextEffectiveDate: "",
      //ServicePriceList: [],
      CurrentEffectivePriceList: [],
      NextEffectivePriceList: [],
    },
    validationSchema: schemaStaffServices,
    onSubmit: (values, formikHelpers) =>
      handleSubmitStaffServices(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: {
      StaffServiceIDEncrypted,
      ServiceTypeIDEncrypted,
      ServiceSubTypeIDEncrypted,
      ServiceIDEncrypted,
      //ServicePriceList,
      CurrentEffectivePriceList,
      NextEffectivePriceList,
    },
  } = formikStaffServices;

  const handleSubmitStaffServices = (values, { setSubmitting }) => {
    // // if (
    // //   ServicePriceList.filter((x) => x.ServiceSpecialRate === "").length > 0
    // // ) {
    // //   swal(
    // //     "",
    // //     "Special Rate is required! Please fill Special Rate for all Charge Type.",
    // //     "warning"
    // //   );
    // //   return;
    // // }

    // if (
    //   CurrentEffectivePriceList.filter(
    //     (x) => x.SalaryAmount === "" || x.PatientAmount === ""
    //   ).length > 0
    // ) {
    //   swal("", "Current Effective: Service Amount is required!", "warning");
    //   return;
    // } else if (values.NextEffectiveDate) {
    //   if (
    //     NextEffectivePriceList.filter(
    //       (x) => x.SalaryAmount === "" || x.PatientAmount === ""
    //     ).length > 0
    //   ) {
    //     swal("", " Next Effective: Service Amount is required!", "warning");
    //     return;
    //   }
    // }
    if (
      CurrentEffectivePriceList.filter((x) => x.PatientAmount === "").length > 0
    ) {
      swal("", "Current Effective: Service Amount is required!", "warning");
      return;
    } else if (
      CurrentEffectivePriceList.filter((x) => x.SalaryAmount === "").length >
        0 &&
      parseInt(SalaryType) !== 1
      // || !SalaryType
    ) {
      swal("", "Current Effective: Service Amount is required!", "warning");
      return;
    } else if (values.NextEffectiveDate) {
      if (
        NextEffectivePriceList.filter((x) => x.PatientAmount === "").length > 0
      ) {
        swal("", " Next Effective: Service Amount is required!", "warning");
        return;
      } else if (
        (NextEffectivePriceList.filter((x) => x.SalaryAmount === "").length >
          0 &&
          parseInt(SalaryType) !== 1) ||
        !SalaryType
      ) {
        swal("", "Next Effective: Service Amount is required!", "warning");
        return;
      }
    }
    setIsLoading(true);

    var payload = {
      ...values,
      StaffIDEncrypted: StaffIDEncrypt,
      LoginIDEncrypted: AuthUser?.LoginIDEncrypt,
      // //SpecialServicePriceList: values.ServicePriceList,
      // SpecialServicePriceList: values.ServicePriceList.map((x) => {
      //   return { ...x, ServicePrice: x.ServiceSpecialRate.toString() };
      // }),
      CurrentEffectiveDate: `${moment(values.CurrentEffectiveDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      CurrentEffectivePriceList: CurrentEffectivePriceList.map((x) => {
        if (x.SalaryAmount === "") {
          return { ...x, SalaryAmount: "0" };
        } else {
          return { ...x };
        }
      }),
      NextEffectiveDate: values.NextEffectiveDate
        ? `${moment(values.NextEffectiveDate)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`
        : "",
      NextEffectivePriceList: values.NextEffectiveDate
        ? NextEffectivePriceList.map((x) => {
            if (x.SalaryAmount === "") {
              return { ...x, SalaryAmount: "0" };
            } else {
              return { ...x };
            }
          })
        : [],
    };
    //console.log(payload);

    post(POST_StaffServiceSave, payload)
      .then((response) => {
        if (response?.IsSuccess) {
          setStaffServiceList([...response?.StaffServiceList]);
          swal("Success!", t(response.MessageCode), "success");
          setServiceModal(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const resetServiceDetailForm = () => {
    formikStaffServices.resetForm();
  };

  const edit_StaffService = (StaffServiceID) => {
    if (StaffServiceID) {
      setIsLoading(true);
      post(POST_GetStaffServiceDetailByID, {
        StaffServiceIDEncrypted: StaffServiceID,
      })
        .then((response) => {
          if (response?.IsSuccess) {
            formikStaffServices.setValues({
              ...response,
              CurrentEffectiveDate: new Date(response.CurrentEffectiveDate),
              CurrentEffectivePriceList: [
                ...response.CurrentEffectivePriceList,
              ],
              NextEffectiveDate: response.NextEffectiveDate
                ? new Date(response.NextEffectiveDate)
                : "",
              NextEffectivePriceList: [...response.NextEffectivePriceList],
            });
          }
          setIsLoading(false);
          setServiceModal(true);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const delete_StaffService = (StaffServiceID, ServiceID) => {
    swal({
      title: "Are you sure you want to delete the staff service?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_StaffServiceConfirm(StaffServiceID, ServiceID);
      }
    });
  };

  const delete_StaffServiceConfirm = async (StaffServiceID, ServiceID) => {
    setIsLoading(true);
    await post(POST_StaffServiceDelete, {
      StaffIDEncrypted: StaffIDEncrypt,
      StaffServiceIDEncrypted: StaffServiceID,
      ServiceIDEncrypted: ServiceID,
      LoginIDEncrypted: AuthUser.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setStaffServiceList([...response.StaffServiceList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const schemaStaffQualification = Yup.object().shape({
    QualificationID: Yup.string()
      .trim()
      .required("Qualification is a required field"),
  });

  const formikStaffQualification = useFormik({
    initialValues: {
      QualificationID: "",
    },
    validationSchema: schemaStaffQualification,
    onSubmit: (values, formikHelpers) =>
      handleSubmitStaffQualification(values, formikHelpers),
    validateOnBlur: false,
  });

  const {} = formikStaffQualification;

  const uploadQualificationFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /(\.|\/)(jpe?g|png|webp|pdf|svg|gif|ico)$/;
      if (!file.type.match(pattern)) {
        setQualificationFile(null);
        setFileKeyQualification(Math.random().toString(9).substring(2, 9));
        swal("", "Select valid file format (PDF/Image)", "warning");
      } else {
        setQualificationFile(file);
        setFileKeyQualification(Math.random().toString(9).substring(2, 9));
      }
    } else {
      setQualificationFile(null);
      setFileKeyQualification(Math.random().toString(9).substring(2, 9));
    }
  };

  const handleSubmitStaffQualification = async (values) => {
    // if (qualificationFile) {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("QualificationID", values.QualificationID);
    formData.append("StaffIDEncrypt", StaffIDEncrypt);
    formData.append("Files", qualificationFile);

    await postFormData(POST_StaffQualificationsInsert, formData)
      .then((response) => {
        if (response.IsSuccess) {
          setStaffQualificationList([...response.StaffQualificationsList]);
          setQualificationModal(false);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
          resetQualificationUploadForm();
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    // }
  };

  const resetQualificationUploadForm = () => {
    formikStaffQualification.resetForm();
    setQualificationFile(null);
    setFileKeyQualification(Math.random().toString(9).substring(2, 9));
  };

  const delete_StaffQualification = (StaffQualificationID) => {
    swal({
      title: "Are you sure you want to delete the staff qualification?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_StaffQualificationConfirm(StaffQualificationID);
      }
    });
  };

  const delete_StaffQualificationConfirm = async (StaffQualificationID) => {
    setIsLoading(true);
    await post(POST_StaffQualificationsDelete, {
      StaffIDEncrypt: StaffIDEncrypt,
      StaffQualificationIDEncrypt: StaffQualificationID,
      EncryptModifiedBy: AuthUser.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setStaffQualificationList([...response.StaffQualificationsList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const schemaStaffDocuments = Yup.object().shape({
    DocumentID: Yup.string().trim().required("Document is a required field"),
    DocumentReceiveDate: Yup.string()
      .trim()
      .required("Receive Date is a required field"),
  });

  const formikStaffDocuments = useFormik({
    initialValues: {
      DocumentID: "",
      DocumentReceiveDate: "",
      IsVerified: false,
      Remarks: "",
    },
    validationSchema: schemaStaffDocuments,
    onSubmit: (values, formikHelpers) =>
      handleSubmitStaffDocuments(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: { IsVerified },
  } = formikStaffDocuments;

  const uploadDocumentFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      setDocumentFile(file);
      setFileKeyDocument(Math.random().toString(9).substring(2, 9));
    } else {
      setDocumentFile(null);
      setFileKeyDocument(Math.random().toString(9).substring(2, 9));
    }
  };

  const handleSubmitStaffDocuments = async (values) => {
    if (documentFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("DocumentID", values.DocumentID);
      formData.append("StaffIDEncrypt", StaffIDEncrypt);
      formData.append(
        "DocumentReceiveDate",
        values.DocumentReceiveDate
          ? `${moment(values.DocumentReceiveDate)
              .format("YYYY-MM-DD")
              .replace(/^\-/, "")} 00:00:00.000`
          : ""
      );
      formData.append("IsVerified", IsVerified);
      formData.append("Remarks", values.Remarks);
      formData.append("LoginIDEncrypt", AuthUser?.LoginIDEncrypt);
      formData.append("Files", documentFile);

      await postFormData(POST_StaffDocumentInsert, formData)
        .then((response) => {
          if (response.IsSuccess) {
            setStaffDocumentList([...response.StaffDocumentsList]);
            setDocumentModal(false);
            swal("Success!", t(response.MessageCode), "success");
            setIsLoading(false);
            resetDocumentUploadForm();
          } else {
            swal("Error!", t(response.MessageCode), "error");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const resetDocumentUploadForm = () => {
    formikStaffDocuments.resetForm();
    setDocumentFile(null);
    setFileKeyDocument(Math.random().toString(9).substring(2, 9));
  };

  const delete_StaffDocument = (StaffDocumentID) => {
    swal({
      title: "Are you sure you want to delete the staff document?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_StaffDocumentConfirm(StaffDocumentID);
      }
    });
  };

  const delete_StaffDocumentConfirm = async (StaffDocumentID) => {
    setIsLoading(true);
    await post(POST_StaffDocumentDelete, {
      StaffIDEncrypt: StaffIDEncrypt,
      StaffCheckListDocumentIDEncrypted: StaffDocumentID,
      EncryptModifiedBy: AuthUser.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setStaffDocumentList([...response.StaffDocumentsList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const verify_StaffDocument = (StaffDocumentID) => {
    swal({
      title: "Are you sure you want to Verify?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then((willVerify) => {
      if (willVerify) {
        verify_StaffDocumentConfirm(StaffDocumentID);
      }
    });
  };

  const verify_StaffDocumentConfirm = async (StaffDocumentID) => {
    setIsLoading(true);
    await post(POST_StaffDocumentVerify, {
      StaffIDEncrypt: StaffIDEncrypt,
      StaffCheckListDocumentIDEncrypted: StaffDocumentID,
      LoginIDEncrypted: AuthUser.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setStaffDocumentList([...response.StaffDocumentsList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const schemaStaffExperienceDetail = Yup.object().shape({
    CompanyName: Yup.string().required(
      "Company / Hospital Name is a required field"
    ),
    Designation: Yup.string().required("Designation is a required field"),
    ExperienceDuration: Yup.string().required("Duration is a required field"),
    ExperienceRemarks: Yup.string(),
  });

  const formikStaffExperienceDetail = useFormik({
    initialValues: {
      StaffExperienceDetailIDEncrypted: "",
      CompanyName: "",
      Designation: "",
      ExperienceDuration: "",
      ExperienceRemarks: "",
    },
    validationSchema: schemaStaffExperienceDetail,
    onSubmit: (values, formikHelpers) =>
      handleSubmitStaffExperienceDetail(values, formikHelpers),
    validateOnBlur: false,
  });

  const {
    values: { StaffExperienceDetailIDEncrypted },
  } = formikStaffExperienceDetail;

  const handleSubmitStaffExperienceDetail = async (
    values,
    { setSubmitting }
  ) => {
    setIsLoading(true);
    var payload = { ...values, StaffIDEncrypted: StaffIDEncrypt };
    //console.log(payload);

    await post(
      StaffExperienceDetailIDEncrypted
        ? POST_StaffExperienceDetailUpdate
        : POST_StaffExperienceDetailInsert,
      payload
    )
      .then((response) => {
        if (response.IsSuccess) {
          setStaffExperienceList([...response.StaffExperienceList]);
          setExperienceModal(false);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
          resetExperienceDetailForm();
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const resetExperienceDetailForm = () => {
    formikStaffExperienceDetail.resetForm();
  };

  const edit_StaffExperience = (StaffExperienceID) => {
    formikStaffExperienceDetail.setValues(
      ...staffExperienceList?.filter(
        (x) => x.StaffExperienceDetailIDEncrypted === StaffExperienceID
      )
    );
    setExperienceModal(true);
  };

  const delete_StaffExperience = (StaffExperienceID) => {
    swal({
      title: "Are you sure you want to delete the staff experience?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        delete_StaffExperienceConfirm(StaffExperienceID);
      }
    });
  };

  const delete_StaffExperienceConfirm = async (StaffExperienceID) => {
    setIsLoading(true);
    await post(POST_StaffExperienceDetailDelete, {
      StaffIDEncrypted: StaffIDEncrypt,
      StaffExperienceDetailIDEncrypted: StaffExperienceID,
      LoginIDEncrypted: AuthUser.LoginIDEncrypt,
    })
      .then((response) => {
        if (response.IsSuccess) {
          setStaffExperienceList([...response.StaffExperienceList]);
          swal("Success!", t(response.MessageCode), "success");
          setIsLoading(false);
        } else {
          swal("Error!", t(response.MessageCode), "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    loadCompanyDDL();
    loadReligionDDL();
    loadLanguageDDL();
    loadHeightDDL();
    loadWeightDDL();
    loadDepartmentDDL();
    loadDesignationDDL();
    loadStateDDL();
    loadQualificationDDL();
    loadDocumentDDL();
    loadServiceTypeDDL();
    if (StaffIDEncryptParam) {
      post(POST_StaffDetailGetByID, {
        StaffIDEncrypted: window.atob(StaffIDEncryptParam),
      })
        .then((response) => {
          if (response?.IsSuccess) {
            var languageList = response.LanguageList;
            setValues({
              ...response,
              StaffIDEncrypt: response.StaffIDEncrypted,
              CompanyID: response.CompanyIDEncrypted,
              DateOfBirth: new Date(response.DateOfBirth),
              PrimaryMobileNo: response.PrimaryContactNumber,
              EmergencyMobileNo: response.EmergencyContactNumber,
              Languages:
                languageList.length > 0
                  ? languageList.map((x) => ({
                      label: x.CategoryName,
                      value: x.CategoryID,
                    }))
                  : "",
              Religion: response.Religion == 0 ? "" : response.Religion,
            });
            if (response.Photo) {
              setStaffPhoto(response.Photo);
            }
            formikStaffOfficialInformation.setValues({
              ...response.OfficialInformations,
              DateOfJoining: new Date(
                response.OfficialInformations.DateOfJoining
              ),
              DateOfLeaving:
                response.OfficialInformations.DateOfLeaving === ""
                  ? ""
                  : new Date(response.OfficialInformations.DateOfLeaving),
              StaffCategory:
                response.OfficialInformations.StaffCategory === 0
                  ? ""
                  : response.OfficialInformations.StaffCategory,
              BackgroundVerificationStatusPrevious:
                response.OfficialInformations.BackgroundVerificationStatus,
            });
            var permanentAdr = response.AddressList?.filter(
              (x) => x.AddressType === 1
            )?.[0];
            var presentAdr = response.AddressList?.filter(
              (x) => x.AddressType === 2
            )?.[0];
            formikAddress.setValues({
              PermanentAddressIDEncrypted: permanentAdr
                ? permanentAdr.StaffAddressIDEncrypted
                : "",
              PermanentAddress: permanentAdr ? permanentAdr.Address : "",
              PermanentStateID: permanentAdr ? permanentAdr.StateID : "",
              PermanentCityID: permanentAdr ? permanentAdr.CityID : "",
              PermanentAreaID: permanentAdr ? permanentAdr.AreaID : "",
              PermanentPinCode: permanentAdr ? permanentAdr.PinCode : "",
              PermanentAddressFlag: permanentAdr
                ? permanentAdr.AddressFlag
                : "",
              PermanentLocation: permanentAdr ? permanentAdr.Location : "",
              PermanentLatitude: permanentAdr ? permanentAdr.Latitude : "",
              PermanentLongitude: permanentAdr ? permanentAdr.Longitude : "",
              PresentAddressIDEncrypted: presentAdr
                ? presentAdr.StaffAddressIDEncrypted
                : "",
              PresentAddress: presentAdr ? presentAdr.Address : "",
              PresentStateID: presentAdr ? presentAdr.StateID : "",
              PresentCityID: presentAdr ? presentAdr.CityID : "",
              PresentAreaID: presentAdr ? presentAdr.AreaID : "",
              PresentPinCode: presentAdr ? presentAdr.PinCode : "",
              PresentAddressFlag: presentAdr ? presentAdr.AddressFlag : "",
              PresentLocation: presentAdr ? presentAdr.Location : "",
              PresentLatitude: presentAdr ? presentAdr.Latitude : "",
              PresentLongitude: presentAdr ? presentAdr.Longitude : "",
            });
            setStaffQualificationList([...response.QualificationList]);
            setStaffDocumentList([...response.DocumentList]);
            setStaffServiceList([...response.ServiceList]);
            setStaffExperienceList([...response.ExperienceList]);
            formikStaffBankDetails.setValues({
              ...response?.BankDetail,
              ConfirmAccountNumber: "",
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadCityDDLPermanent(PermanentStateID ? PermanentStateID : 0);
  }, [PermanentStateID]);

  useEffect(() => {
    loadCityDDLPresent(PresentStateID ? PresentStateID : 0);
  }, [PresentStateID]);

  useEffect(() => {
    loadAreaDDLPermanent(PermanentCityID ? PermanentCityID : 0);
  }, [PermanentCityID]);

  useEffect(() => {
    loadAreaDDLPresent(PresentCityID ? PresentCityID : 0);
  }, [PresentCityID]);

  useEffect(() => {
    if (ServiceTypeIDEncrypted) {
      loadServiceSubTypeDDL(
        ServiceTypeIDEncrypted ? ServiceTypeIDEncrypted : ""
      );
    } else {
      setServiceSubTypeOptions([]);
      setServiceOptions([]);
      formikStaffServices.setFieldValue("ServiceIDEncrypted", "");
      if (!StaffServiceIDEncrypted) {
        //formikStaffServices.setFieldValue("ServicePriceList", []);
        formikStaffServices.setFieldValue("CurrentEffectivePriceList", []);
        formikStaffServices.setFieldValue("NextEffectivePriceList", []);
        formikStaffServices.setFieldValue("CurrentEffectiveDate", new Date());
        formikStaffServices.setFieldValue("NextEffectiveDate", "");
      }
    }
  }, [ServiceTypeIDEncrypted]);

  useEffect(() => {
    if (ServiceTypeIDEncrypted && ServiceSubTypeIDEncrypted) {
      loadServiceDDL(ServiceTypeIDEncrypted, ServiceSubTypeIDEncrypted);
    } else {
      setServiceOptions([]);
      formikStaffServices.setFieldValue("ServiceIDEncrypted", "");
      if (!StaffServiceIDEncrypted) {
        //formikStaffServices.setFieldValue("ServicePriceList", []);
        formikStaffServices.setFieldValue("CurrentEffectivePriceList", []);
        formikStaffServices.setFieldValue("NextEffectivePriceList", []);
        formikStaffServices.setFieldValue("CurrentEffectiveDate", new Date());
        formikStaffServices.setFieldValue("NextEffectiveDate", "");
      }
    }
  }, [ServiceSubTypeIDEncrypted]);

  useEffect(() => {
    if (ServiceIDEncrypted && !StaffServiceIDEncrypted) {
      loadServicePriceList(ServiceIDEncrypted);
    } else {
      if (!StaffServiceIDEncrypted) {
        //formikStaffServices.setFieldValue("ServicePriceList", []);
        formikStaffServices.setFieldValue("CurrentEffectivePriceList", []);
        formikStaffServices.setFieldValue("NextEffectivePriceList", []);
        formikStaffServices.setFieldValue("CurrentEffectiveDate", new Date());
        formikStaffServices.setFieldValue("NextEffectiveDate", "");
      }
    }
  }, [ServiceIDEncrypted]);

  // //For Image upload in ListBlog
  // const [file, setFile] = React.useState(null);
  // const fileHandler = (e) => {
  //   setFile(e.target.files[0]);
  //   // setTimeout(function () {
  //   //   var src = document.getElementById("saveImageFile").getAttribute("src");
  //   //   addFormData.image = src;
  //   // }, 200);
  // };
  const [validationStaffPhoto, setValidationStaffPhoto] = useState("");
  const [staffPhoto, setStaffPhoto] = useState(null);
  const [staffPhotoFile, setStaffPhotoFile] = useState(null);

  const [fileKeyStaffPhoto, setFileKeyStaffPhoto] = useState();

  const [zoomStaffPhoto, setZoomStaffPhoto] = useState(0.5);

  const [openCropperModalStaffPhoto, setOpenCropperModalStaffPhoto] =
    useState(false);
  const [openWebCamModalStaffPhoto, setOpenWebCamModalStaffPhoto] =
    useState(false);
  const [imageStaffPhoto, setImageStaffPhoto] = useState();
  const [cropperStaffPhoto, setCropperStaffPhoto] = useState();

  const getCropDataStaffPhoto = () => {
    if (typeof cropperStaffPhoto !== "undefined") {
      handleUploadStaffPhoto(cropperStaffPhoto.getCroppedCanvas().toDataURL());
      setStaffPhoto(cropperStaffPhoto.getCroppedCanvas().toDataURL());
      // setStaffPhotox("")
    }
  };

  const handleUploadStaffPhoto = async (url) => {
    const outputFileStaffPhoto = await dataUrlToFileUsingFetch(
      url,
      "output.png",
      "image/png"
    );
    //const file = dataUrlToFile(url, "output.png");
    setStaffPhotoFile(outputFileStaffPhoto);
    setValidationStaffPhoto("");
  };

  const uploadStaffPhoto = (event) => {
    if (event.target.files.length === 1) {
      var pattern = /image-*/;
      if (event.target.files[0].type.match(pattern)) {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
          files = event.dataTransfer.files;
        } else if (event.target) {
          files = event.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImageStaffPhoto(reader.result);
        };
        reader.readAsDataURL(files[0]);
        setOpenCropperModalStaffPhoto(true);
      }
    }
  };

  const optionsStaffCategory = [
    { label: "L1", value: 1 },
    { label: "L2", value: 2 },
    { label: "L3", value: 3 },
    { label: "Not Applicable", value: 4 },
  ];

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    if (imageSrc !== null) {
      setStaffPhoto(imageSrc);
      const outputFileStaffPhotoCapture = await dataUrlToFileUsingFetch(
        imageSrc,
        "output.png",
        "image/png"
      );
      setStaffPhotoFile(outputFileStaffPhotoCapture);
    } else {
      swal("", "Webcam device not found.", "warning");
    }
    setOpenWebCamModalStaffPhoto(false);
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };

  const validationSchemaStaffBankDetails = Yup.object().shape({
    BeneficiaryName: Yup.string().required(
      "Beneficiary Name is required field"
    ),
    BankName: Yup.string().required("Bank Name is required field"),
    AccountNumber: Yup.string().required("Account Number is required field"),
    ConfirmAccountNumber: Yup.string()
      .required("Re-enter Account Number is required field")
      .oneOf(
        [Yup.ref("AccountNumber"), null],
        "Re-enter Account Number does not match"
      ),
    IFSC: Yup.string().required("IFSC is required field"),
  });

  const formikStaffBankDetails = useFormik({
    validationSchema: validationSchemaStaffBankDetails,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitStaffBankDetails(values, formikHelpers),
    initialValues: {
      BeneficiaryName: "",
      BankName: "",
      AccountNumber: "",
      IFSC: "",
      ConfirmAccountNumber: "",
    },
    validateOnBlur: false,
  });

  const {
    values: {},
  } = formikStaffBankDetails;

  const handleValidSubmitStaffBankDetails = (values) => {
    setIsLoading(true);
    let bankPayload = {
      ...values,
      StaffIDEncrypt: StaffIDEncrypt,
      LoginEncrypt: AuthUser?.LoginIDEncrypt,
    };
    delete bankPayload["ConfirmAccountNumber"];
    //console.log("Bank Payload: ", bankPayload);

    post(POST_StaffBankDetailSave, bankPayload)
      .then((response) => {
        if (response.IsSuccess) {
          swal("Success!", t(response.MessageCode), "success");
          setTab(8);
        } else {
          swal("Error!", t(response.MessageCode), "error");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={`${StaffIDEncryptParam ? "Edit" : "Add"} Staff`}
          motherMenu={<Link to="/staff-list">Staffs</Link>}
        />

        <div className="custom-tab-1">
          <Tab.Container activeKey={tab}>
            <Nav as="ul" className="nav-tabs">
              <Nav.Item as="li" key={1}>
                <Nav.Link eventKey={1} onClick={() => setTab(1)}>
                  {"Staff Information"}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" key={2}>
                <Nav.Link
                  eventKey={2}
                  disabled={StaffIDEncrypt ? false : true}
                  onClick={() => setTab(2)}
                >
                  {"Addresses"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={3}>
                <Nav.Link
                  eventKey={3}
                  disabled={StaffIDEncrypt ? false : true}
                  onClick={() => setTab(3)}
                >
                  {"Services"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={4}>
                <Nav.Link
                  eventKey={4}
                  disabled={StaffIDEncrypt ? false : true}
                  onClick={() => setTab(4)}
                >
                  {"Qualification"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={5}>
                <Nav.Link
                  eventKey={5}
                  disabled={StaffIDEncrypt ? false : true}
                  onClick={() => setTab(5)}
                >
                  {"Documents"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={6}>
                <Nav.Link
                  eventKey={6}
                  disabled={StaffIDEncrypt ? false : true}
                  onClick={() => setTab(6)}
                >
                  {"Experience"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={7}>
                <Nav.Link
                  eventKey={7}
                  disabled={StaffIDEncrypt ? false : true}
                  onClick={() => setTab(7)}
                >
                  {"Bank Details"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={8}>
                <Nav.Link
                  eventKey={8}
                  disabled={StaffIDEncrypt ? false : true}
                  onClick={() => setTab(8)}
                >
                  {"Official Information"}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={1} key={1} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <Field
                              label={"Company"}
                              name="CompanyID"
                              placeholder={"Select Company"}
                              options={companyOptions}
                              component={CustomSelect}
                              required
                              isClearable={false}
                              isDisabled={StaffIDEncrypt ? true : false}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"First Name"}
                              name="FirstName"
                              placeholder={"Enter First Name"}
                              component={CustomInput}
                              required
                              onChange={(e) => {
                                let fName = e.target.value;
                                setFieldValue(
                                  "FirstName",
                                  fName.charAt(0).toUpperCase() + fName.slice(1)
                                );
                              }}
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Middle Name"}
                              name="MiddleName"
                              placeholder={"Enter Middle Name"}
                              component={CustomInput}
                              required
                              onChange={(e) => {
                                let mName = e.target.value;
                                setFieldValue(
                                  "MiddleName",
                                  mName.charAt(0).toUpperCase() + mName.slice(1)
                                );
                              }}
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Last Name"}
                              name="LastName"
                              placeholder={"Enter Last Name"}
                              component={CustomInput}
                              required
                              onChange={(e) => {
                                let lName = e.target.value;
                                setFieldValue(
                                  "LastName",
                                  lName.charAt(0).toUpperCase() + lName.slice(1)
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Date of Birth"}
                              name="DateOfBirth"
                              placeholder={"Select Date of Birth"}
                              component={CustomFlatpickr}
                              options={{
                                altInput: true,
                                altFormat: "j-M-Y",
                                dateFormat: "d-m-Y",
                                //maxDate: new Date(),
                                maxDate: new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - parseInt(18)
                                  )
                                ),
                              }}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Gender"}
                              name="Gender"
                              options={optionGender}
                              placeholder={"Select Gender"}
                              component={CustomSelect}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Marital Status"}
                              name="MaritalStatus"
                              options={optionMaritalStatus}
                              placeholder={"Select Marital Status"}
                              component={CustomSelect}
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Religion"}
                              name="Religion"
                              options={religionOptions}
                              placeholder={"Select Religion"}
                              component={CustomSelect}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Height"}
                              name="HeightID"
                              placeholder={"Select Height"}
                              options={heightOptions}
                              component={CustomSelect}
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Weight"}
                              name="WeightID"
                              placeholder={"Select Weight"}
                              options={weightOptions}
                              component={CustomSelect}
                            />
                          </Col>
                          {/* <Col lg={3}>
                            <div
                              className="custom-control custom-checkbox mb-3 checkbox-success"
                              style={{ marginTop: "40px", zIndex: 0 }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="chkSpecialRate"
                                checked={IsSpecialRate}
                                onChange={(e) => {
                                  setFieldValue(
                                    "IsSpecialRate",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="chkSpecialRate"
                              >
                                Special Rate
                              </label>
                            </div>
                          </Col>*/}
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={6}>
                            <Field
                              label={"Languages Known"}
                              name="Languages"
                              placeholder={"Select Language"}
                              options={languageOptions}
                              component={CustomSelect}
                              isMulti={true}
                              required
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text"
                              label={"Speciality"}
                              name="Speciality"
                              placeholder={"Enter Speciality"}
                              component={CustomInput}
                              maxLength={200}
                              required
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Primary Mobile No."}
                              name="PrimaryMobileNo"
                              placeholder={"Enter Primary Mobile No."}
                              country={countryPrimary}
                              countryCode={CountryCodePrimary}
                              component={CustomPhoneInput}
                              onChange={(value, { countryCode, dialCode }) => {
                                //setCountryPrimary(countryCode);
                                setFieldValue("CountryCodePrimary", dialCode);
                                setFieldValue(
                                  "PrimaryMobileNo",
                                  value.substring(dialCode.toString().length)
                                );
                              }}
                              required
                              disabled={StaffIDEncrypt ? true : false}
                            />
                          </Col>

                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Emergency Mobile No."}
                              name="EmergencyMobileNo"
                              placeholder={"Enter Emergency Mobile No."}
                              country={countryEmergency}
                              countryCode={CountryCodeEmergency}
                              component={CustomPhoneInput}
                              onChange={(value, { countryCode, dialCode }) => {
                                //setCountryEmergency(countryCode);
                                setFieldValue("CountryCodeEmergency", dialCode);
                                setFieldValue(
                                  "EmergencyMobileNo",
                                  value.substring(dialCode.toString().length)
                                );
                              }}
                              required
                            />
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="email"
                              label={"EmailID"}
                              name="EmailID"
                              placeholder={"Enter EmailID"}
                              component={CustomInput}
                              //required
                              disabled={StaffIDEncrypt ? true : false}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label>
                              Upload Photo{" "}
                              <span className="text-danger">*</span>{" "}
                              <span style={{ color: "red", fontSize: "10px" }}>
                                (500 x 500)
                              </span>
                            </Label>
                            {/* <div className="input-group">
                          <div className="custom-file">
                            <input type="file" className="custom-file-input" />
                            <label className="custom-file-label">
                              Choose file
                            </label>
                          </div>
                        </div> */}
                            <div className="image-placeholder staffPhoto-placeholder">
                              <div
                                className="avatar-edit"
                                style={{ right: "-45px", top: "20px" }}
                              >
                                <input
                                  type="file"
                                  //onChange={fileHandler}
                                  id="imageUpload"
                                  //onClick={(event) => setFile(event.target.value)}
                                  accept="image/png, image/gif, image/jpeg"
                                  onChange={uploadStaffPhoto}
                                  key={fileKeyStaffPhoto || ""}
                                />
                                <label htmlFor="imageUpload" name=""></label>
                              </div>
                              <div
                                id="webCam"
                                className="avatar-camera"
                                onClick={() => {
                                  setOpenWebCamModalStaffPhoto(true);
                                }}
                              >
                                <label htmlFor="webCam" name=""></label>
                              </div>
                              <div className="avatar-preview">
                                <div id="imagePreview">
                                  <img
                                    id="saveImageFile"
                                    src={staffPhoto ? staffPhoto : StaffImage}
                                    alt={"Staff Photo"}
                                  />
                                </div>
                              </div>
                            </div>
                            <span
                              style={{
                                color: "#ec4561",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                              }}
                            >
                              {validationStaffPhoto}
                            </span>
                          </Col>
                          <Col lg={9}>
                            <Row>
                              <Col lg={4}>
                                <Field
                                  type="text"
                                  label={"Father Name"}
                                  name="FatherName"
                                  placeholder={"Enter Father Name"}
                                  component={CustomInput}
                                  required
                                />
                              </Col>
                              <Col lg={4}>
                                <Field
                                  type="text"
                                  label={"Mother Name"}
                                  name="MotherName"
                                  placeholder={"Enter Mother Name"}
                                  component={CustomInput}
                                />
                              </Col>
                              <Col lg={4}>
                                <Field
                                  type="text"
                                  label={"Spouse Name"}
                                  name="SpouseName"
                                  placeholder={"Enter Spouse Name"}
                                  component={CustomInput}
                                />
                              </Col>
                            </Row>
                            {/* <hr></hr> 
                             <Row>
                              <Col lg={4}>
                                <Field
                                  label={"Background Verification"}
                                  name="BackgroundVerificationStatus"
                                  placeholder={"Select Status"}
                                  options={optionVerificationStatus}
                                  component={CustomSelect}
                                  required
                                />
                              </Col>
                              <Col lg={8}>
                                <Field
                                  label={"Background Verification Remarks"}
                                  name="BackgroundVerificationRemarks"
                                  placeholder={
                                    "Enter Background Verification Remarks"
                                  }
                                  component={CustomInput}
                                  required
                                />
                              </Col>
                            </Row> */}
                          </Col>
                        </Row>

                        <button
                          type="submit"
                          className="btn btn-sm mr-2 btn-primary mt-2"
                        >
                          Submit
                        </button>
                        {StaffIDEncrypt ? (
                          <button
                            type="button"
                            className="btn mr-2 btn-primary btn-sm mt-2"
                            onClick={() => {
                              setTab(2);
                            }}
                          >
                            Next
                          </button>
                        ) : (
                          ""
                        )}
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey={2} key={2}>
                <div className="basic-form mt-4">
                  <FormikProvider value={formikAddress}>
                    <Form onSubmit={formikAddress.handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <div className="card">
                            <div className="card-body">
                              <Row>
                                <Col lg={6} style={{ textAlign: "left" }}>
                                  <div style={{ color: "black" }}>
                                    Present Address
                                  </div>
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row>
                                <Col lg={12}>
                                  <Field
                                    type="textarea"
                                    label={"Address"}
                                    name="PresentAddress"
                                    placeholder={"Enter Address"}
                                    rows={2}
                                    component={CustomInput}
                                    required
                                    style={{ resize: "none" }}
                                  />
                                </Col>

                                <Col lg={6}>
                                  <Field
                                    label={"State"}
                                    name="PresentStateID"
                                    placeholder={"Select State"}
                                    component={CustomSelect}
                                    options={stateOptions}
                                    required
                                  />
                                </Col>
                                <Col lg={6}>
                                  <Field
                                    label={"City"}
                                    name="PresentCityID"
                                    placeholder={"Select City"}
                                    options={cityOptionsPresent}
                                    component={CustomSelect}
                                    required
                                  />
                                </Col>
                                <Col lg={6}>
                                  <Field
                                    label={"Area"}
                                    name="PresentAreaID"
                                    placeholder={"Enter Area"}
                                    component={CustomSelect}
                                    options={areaOptionsPresent}
                                    required
                                  />
                                </Col>

                                <Col lg={6}>
                                  <Field
                                    type="text"
                                    label={"Location"}
                                    name="PresentLocation"
                                    placeholder={"Select Location"}
                                    component={CustomInput}
                                    onChange={(e) => {
                                      getPlacePredictions({
                                        input: e.target.value,
                                      });
                                      formikAddress.setFieldValue(
                                        "PresentLocation",
                                        e.target.value
                                      );
                                      setAddressSuggesionDivStatus(true);
                                    }}
                                    loading={isPlacePredictionLoading}
                                    required
                                  />
                                  {addressSuggesionDivStatus &&
                                  placePredictions.length > 0 ? (
                                    <div
                                      style={{
                                        background: "white",
                                        borderRadius: "0.3rem",
                                        position: "absolute",
                                        // left: "40px",
                                        overflow: "auto",
                                        border: "1px solid rgb(207, 207, 207)",
                                        boxShadow:
                                          "rgb(28 28 28 / 8%) 0px 2px 8px",
                                        visibility: "visible",
                                        opacity: "1",
                                        transition:
                                          "opacity 0.25s ease 0s, top 0.25s ease 0s",
                                        zIndex: "10",
                                        transform: "translateY(-0.6rem)",
                                        width: "94%",
                                      }}
                                    >
                                      <List
                                        style={{
                                          padding: "0px 10px",
                                          textAlign: "left",
                                        }}
                                        dataSource={placePredictions}
                                        renderItem={(item) => (
                                          <List.Item
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              formikAddress.setFieldValue(
                                                "PresentLocation",
                                                item.description
                                              );
                                              setAddressSuggesionDivStatus(
                                                false
                                              );
                                              geocodeByPlaceId(item.place_id)
                                                .then((results) => {
                                                  formikAddress.setFieldValue(
                                                    "PresentLatitude",
                                                    results[0].geometry.location.lat()
                                                  );
                                                  formikAddress.setFieldValue(
                                                    "PresentLongitude",
                                                    results[0].geometry.location.lng()
                                                  );
                                                })
                                                .catch((error) =>
                                                  console.error(error)
                                                );
                                            }}
                                          >
                                            <List.Item.Meta
                                              title={item.description}
                                            />
                                          </List.Item>
                                        )}
                                      ></List>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                {/* <Col lg={6}>
                                <Field
                                  type="text"
                                  label={"Latitude"}
                                  name="Latitude"
                                  id="Latitude"
                                  placeholder={"Enter Latitude"}
                                  component={CustomInput}
                                />
                              </Col>
                              <Col lg={6}>
                                <Field
                                  type="text"
                                  label={"Longitude"}
                                  name="Longitude"
                                  id="Longitude"
                                  placeholder={"Enter Longitude"}
                                  component={CustomInput}
                                />
                              </Col> */}
                                <Col lg={6}>
                                  <Field
                                    type="number"
                                    label={"Pincode"}
                                    name="PresentPinCode"
                                    placeholder={"Enter Pincode"}
                                    component={CustomInput}
                                    required
                                    maxLength={6}
                                    onChange={(e) => {
                                      formikAddress.setFieldValue(
                                        "PresentPinCode",
                                        e.target.value.slice(0, 6)
                                      );
                                    }}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <label
                                    className="radio-inline mr-3"
                                    style={{ marginTop: "20%" }}
                                  >
                                    <input
                                      type="radio"
                                      name="PresentAddressFlag"
                                      value={1}
                                      checked={
                                        PresentAddressFlag === 1 ? true : false
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          formikAddress.setFieldValue(
                                            "PresentAddressFlag",
                                            1
                                          );
                                        }
                                      }}
                                    />{" "}
                                    Residence
                                  </label>
                                  <label
                                    className="radio-inline mr-3"
                                    style={{ marginTop: "20%" }}
                                  >
                                    <input
                                      type="radio"
                                      name="PresentAddressFlag"
                                      value={2}
                                      checked={
                                        PresentAddressFlag === 2 ? true : false
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          formikAddress.setFieldValue(
                                            "PresentAddressFlag",
                                            2
                                          );
                                        }
                                      }}
                                    />{" "}
                                    Work
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="card">
                            <div className="card-body">
                              <Row>
                                <Col lg={6} style={{ textAlign: "left" }}>
                                  <div style={{ color: "black" }}>
                                    Permanent Address
                                  </div>
                                </Col>
                                <Col lg={6} style={{ textAlign: "right" }}>
                                  <div className="custom-control custom-checkbox  checkbox-success">
                                    <input
                                      type="checkbox"
                                      defaultChecked={false}
                                      className="custom-control-input"
                                      id="chkSameAsPresentAddress"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          formikAddress.setFieldValue(
                                            "PermanentAddress",
                                            PresentAddress
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentStateID",
                                            PresentStateID
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentCityID",
                                            PresentCityID
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentAreaID",
                                            PresentAreaID
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentPinCode",
                                            PresentPinCode
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentAddressFlag",
                                            PresentAddressFlag
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentLocation",
                                            PresentLocation
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentLatitude",
                                            PresentLatitude
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentLongitude",
                                            PresentLongitude
                                          );
                                        } else {
                                          formikAddress.setFieldValue(
                                            "PermanentAddress",
                                            ""
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentStateID",
                                            ""
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentCityID",
                                            ""
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentAreaID",
                                            ""
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentPinCode",
                                            ""
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentAddressFlag",
                                            1
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentLocation",
                                            ""
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentLatitude",
                                            ""
                                          );
                                          formikAddress.setFieldValue(
                                            "PermanentLongitude",
                                            ""
                                          );
                                        }
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="chkSameAsPresentAddress"
                                    >
                                      Same as Present
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row>
                                <Col lg={12}>
                                  <Field
                                    type="textarea"
                                    label={"Address"}
                                    name="PermanentAddress"
                                    placeholder={"Enter Address"}
                                    rows={2}
                                    component={CustomInput}
                                    required
                                    style={{ resize: "none" }}
                                  />
                                </Col>

                                <Col lg={6}>
                                  <Field
                                    label={"State"}
                                    name="PermanentStateID"
                                    placeholder={"Select State"}
                                    component={CustomSelect}
                                    options={stateOptions}
                                    required
                                  />
                                </Col>
                                <Col lg={6}>
                                  <Field
                                    label={"City"}
                                    name="PermanentCityID"
                                    placeholder={"Select City"}
                                    options={cityOptionsPermanent}
                                    component={CustomSelect}
                                    required
                                  />
                                </Col>
                                <Col lg={6}>
                                  <Field
                                    label={"Area"}
                                    name="PermanentAreaID"
                                    placeholder={"Enter Area"}
                                    component={CustomSelect}
                                    options={areaOptionsPermanent}
                                    required
                                  />
                                </Col>
                                <Col lg={6}>
                                  <Field
                                    type="text"
                                    label={"Location"}
                                    name="PermanentLocation"
                                    placeholder={"Select Location"}
                                    component={CustomInput}
                                    onChange={(e) => {
                                      getPlacePredictions({
                                        input: e.target.value,
                                      });
                                      formikAddress.setFieldValue(
                                        "PermanentLocation",
                                        e.target.value
                                      );
                                      setPermanentAddressSuggesionDivStatus(
                                        true
                                      );
                                    }}
                                    loading={isPlacePredictionLoading}
                                    required
                                  />
                                  {permanentAddressSuggesionDivStatus &&
                                  placePredictions.length > 0 ? (
                                    <div
                                      style={{
                                        background: "white",
                                        borderRadius: "0.3rem",
                                        position: "absolute",
                                        // left: "40px",
                                        overflow: "auto",
                                        border: "1px solid rgb(207, 207, 207)",
                                        boxShadow:
                                          "rgb(28 28 28 / 8%) 0px 2px 8px",
                                        visibility: "visible",
                                        opacity: "1",
                                        transition:
                                          "opacity 0.25s ease 0s, top 0.25s ease 0s",
                                        zIndex: "10",
                                        transform: "translateY(-0.6rem)",
                                        width: "94%",
                                      }}
                                    >
                                      <List
                                        style={{
                                          padding: "0px 10px",
                                          textAlign: "left",
                                        }}
                                        dataSource={placePredictions}
                                        renderItem={(item) => (
                                          <List.Item
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              formikAddress.setFieldValue(
                                                "PermanentLocation",
                                                item.description
                                              );
                                              setPermanentAddressSuggesionDivStatus(
                                                false
                                              );
                                              geocodeByPlaceId(item.place_id)
                                                .then((results) => {
                                                  formikAddress.setFieldValue(
                                                    "PermanentLatitude",
                                                    results[0].geometry.location.lat()
                                                  );
                                                  formikAddress.setFieldValue(
                                                    "PermanentLongitude",
                                                    results[0].geometry.location.lng()
                                                  );
                                                })
                                                .catch((error) =>
                                                  console.error(error)
                                                );
                                            }}
                                          >
                                            <List.Item.Meta
                                              title={item.description}
                                            />
                                          </List.Item>
                                        )}
                                      ></List>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                {/* <Col lg={6}>
                                <Field
                                  type="text"
                                  label={"Latitude"}
                                  name="Latitude"
                                  id="Latitude"
                                  placeholder={"Enter Latitude"}
                                  component={CustomInput}
                                />
                              </Col>
                              <Col lg={6}>
                                <Field
                                  type="text"
                                  label={"Longitude"}
                                  name="Longitude"
                                  id="Longitude"
                                  placeholder={"Enter Longitude"}
                                  component={CustomInput}
                                />
                              </Col> */}
                                <Col lg={6}>
                                  <Field
                                    type="number"
                                    label={"Pincode"}
                                    name="PermanentPinCode"
                                    placeholder={"Enter Pincode"}
                                    component={CustomInput}
                                    required
                                    maxLength={6}
                                    onChange={(e) => {
                                      formikAddress.setFieldValue(
                                        "PermanentPinCode",
                                        e.target.value.slice(0, 6)
                                      );
                                    }}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <label
                                    className="radio-inline mr-3"
                                    style={{ marginTop: "20%" }}
                                  >
                                    <input
                                      type="radio"
                                      name="PermanentAddressFlag"
                                      value={1}
                                      checked={
                                        PermanentAddressFlag === 1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          formikAddress.setFieldValue(
                                            "PermanentAddressFlag",
                                            1
                                          );
                                        }
                                      }}
                                    />{" "}
                                    Residence
                                  </label>
                                  <label
                                    className="radio-inline mr-3"
                                    style={{ marginTop: "20%" }}
                                  >
                                    <input
                                      type="radio"
                                      name="PermanentAddressFlag"
                                      value={2}
                                      checked={
                                        PermanentAddressFlag === 2
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          formikAddress.setFieldValue(
                                            "PermanentAddressFlag",
                                            2
                                          );
                                        }
                                      }}
                                    />{" "}
                                    Work
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>

                      <button
                        type="button"
                        className="btn mr-2 btn-primary btn-sm"
                        onClick={() => {
                          setTab(1);
                        }}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        className="btn mr-2 btn-primary btn-sm"
                        onClick={() => {
                          setTab(3);
                        }}
                      >
                        Next
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={3} key={3} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive ">
                          <div className="dataTables_wrapper no-footer">
                            <table
                              className="table verticle-middle table-responsive-md mb-3"
                              style={{ width: "95%" }}
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Service Name: activate to sort column ascending"
                                    style={{
                                      width: "45%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Service Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Remarks: activate to sort column ascending"
                                    style={{
                                      width: "45%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Remarks
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {staffServiceList?.length > 0 ? (
                                  staffServiceList?.map((item, index) => (
                                    <tr role="row">
                                      <td>{item.ServiceName}</td>
                                      <td>{item.Remarks}</td>
                                      <td>
                                        <div className="d-flex">
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="Edit"
                                            onClick={() => {
                                              edit_StaffService(
                                                item.StaffServiceIDEncrypted
                                              );
                                            }}
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </Link>
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp"
                                            title="Delete"
                                            onClick={() => {
                                              delete_StaffService(
                                                item.StaffServiceIDEncrypted,
                                                item.ServiceIDEncrypted
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                            ></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      <div className="mt-2 mb-3">
                                        {"No Record Found"}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-xs btn-rounded mb-2"
                          onClick={() => {
                            resetServiceDetailForm();
                            setServiceModal(true);
                          }}
                        >
                          + Add Service
                        </button>
                      </Col>
                    </Row>
                    <br></br>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(2);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(4);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={4} key={4} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive ">
                          <div className="dataTables_wrapper no-footer">
                            <table
                              className="table verticle-middle table-responsive-md mb-3"
                              style={{ width: "60%" }}
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Qualification: activate to sort column ascending"
                                    style={{
                                      width: "80%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Qualification
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "20%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {staffQualificationList?.length > 0 ? (
                                  staffQualificationList?.map((item, index) => (
                                    <tr role="row">
                                      <td>{item.Qualification}</td>
                                      <td>
                                        <div className="d-flex">
                                          {item.FilePath ? (
                                            <Link
                                              className="btn btn-light shadow btn-xs sharp mr-1"
                                              title="View"
                                              onClick={() => {
                                                window.open(
                                                  item.FilePath,
                                                  "_blank"
                                                );
                                              }}
                                            >
                                              <i className="fa fa-eye"></i>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp"
                                            title="Delete"
                                            onClick={() => {
                                              delete_StaffQualification(
                                                item.StaffQualificationIDEncrypted
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                            ></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="mt-2 mb-3">
                                        {"No Record Found"}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-xs btn-rounded mb-2"
                          onClick={() => {
                            resetQualificationUploadForm();
                            setQualificationModal(true);
                          }}
                        >
                          + Add Qualification
                        </button>
                      </Col>
                    </Row>
                    <br></br>

                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(3);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(5);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey={5} key={5} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive ">
                          <div className="dataTables_wrapper no-footer">
                            <table
                              className="table verticle-middle table-responsive-md mb-3"
                              style={{ width: "98%" }}
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Document Name: activate to sort column ascending"
                                    style={{
                                      width: "30%",
                                      padding: "10px 15px",
                                    }}
                                  >
                                    Document Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Document Receive Date: activate to sort column ascending"
                                    style={{
                                      width: "20%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Receive Date
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Is Verified: activate to sort column ascending"
                                    style={{
                                      width: "15%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Is Verified
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Remarks: activate to sort column ascending"
                                    style={{
                                      width: "25%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Remarks
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {staffDocumentList?.length > 0 ? (
                                  staffDocumentList?.map((item, index) => (
                                    <tr role="row">
                                      <td>{item.DocumentName}</td>
                                      <td>{item.DocumentReceivedDate}</td>
                                      <td>
                                        {item.IsVerified ? (
                                          "Verified"
                                        ) : (
                                          <button
                                            className="btn btn-primary btn-xss btn-rounded"
                                            style={{ lineHeight: "10px" }}
                                            onClick={(e) => {
                                              verify_StaffDocument(
                                                item.StaffCheckListDocumentIDEncrypted
                                              );
                                            }}
                                          >
                                            Verify
                                          </button>
                                        )}
                                      </td>
                                      <td>{item.Remarks}</td>
                                      <td>
                                        <div className="d-flex">
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="View"
                                            onClick={() => {
                                              window.open(
                                                item.DocumentPath,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp"
                                            title="Delete"
                                            onClick={() => {
                                              delete_StaffDocument(
                                                item.StaffCheckListDocumentIDEncrypted
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                            ></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={5}>
                                      <div className="mt-2 mb-3">
                                        {"No Record Found"}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-xs btn-rounded mb-2"
                          onClick={() => {
                            resetDocumentUploadForm();
                            setDocumentModal(true);
                          }}
                        >
                          + Add Document
                        </button>
                      </Col>
                    </Row>
                    <br></br>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(4);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(6);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey={6} key={6} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive ">
                          <div className="dataTables_wrapper no-footer">
                            <table
                              className="table verticle-middle table-responsive-md mb-3"
                              style={{ width: "98%" }}
                            >
                              <thead className="thead-light">
                                <tr role="row">
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Company / Hospital Name: activate to sort column ascending"
                                    style={{
                                      width: "30%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Company / Hospital Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Designation: activate to sort column ascending"
                                    style={{
                                      width: "20%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Designation
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Duration: activate to sort column ascending"
                                    style={{
                                      width: "15%",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    Duration
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Remarks: activate to sort column ascending"
                                    style={{
                                      width: "25%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Remarks
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{
                                      width: "10%",
                                      padding: "10px 30px",
                                    }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {staffExperienceList?.length > 0 ? (
                                  staffExperienceList?.map((item, index) => (
                                    <tr role="row">
                                      <td>{item.CompanyName}</td>
                                      <td>{item.Designation}</td>
                                      <td>
                                        {item.ExperienceDuration} {" Years"}
                                      </td>
                                      <td>{item.ExperienceRemarks}</td>
                                      <td>
                                        <div className="d-flex">
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp mr-1"
                                            title="Edit"
                                            onClick={() => {
                                              edit_StaffExperience(
                                                item.StaffExperienceDetailIDEncrypted
                                              );
                                            }}
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </Link>
                                          <Link
                                            className="btn btn-light shadow btn-xs sharp"
                                            title="Delete"
                                            onClick={() => {
                                              delete_StaffExperience(
                                                item.StaffExperienceDetailIDEncrypted
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                            ></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={5}>
                                      <div className="mt-2 mb-3">
                                        {"No Record Found"}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-primary btn-xs btn-rounded mb-2"
                          onClick={() => {
                            resetExperienceDetailForm();
                            setExperienceModal(true);
                          }}
                        >
                          + Add Experience
                        </button>
                      </Col>
                    </Row>
                    <br></br>
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(5);
                      }}
                    >
                      Previous
                    </button>
                    {/* <button
                      type="button"
                      className="btn mr-2 btn-light btn-sm"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Close
                    </button> */}
                    <button
                      type="button"
                      className="btn mr-2 btn-primary btn-sm"
                      onClick={() => {
                        setTab(7);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey={7} key={7} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <FormikProvider value={formikStaffBankDetails}>
                      <Form onSubmit={formikStaffBankDetails.handleSubmit}>
                        <Row>
                          <Col lg={4}>
                            <Field
                              type="text"
                              label={"Beneficiary Name"}
                              name="BeneficiaryName"
                              placeholder={"Enter Beneficiary Name"}
                              component={CustomInput}
                              required
                              maxLength={200}
                            />
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              label={"Bank Name"}
                              name="BankName"
                              placeholder={"Enter Bank Name"}
                              component={CustomInput}
                              required
                              maxLength={200}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <Field
                              type="password"
                              label={"Account Number"}
                              name="AccountNumber"
                              placeholder={"Enter Account Number"}
                              component={CustomInput}
                              required
                              maxLength={50}
                            />
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              label={"Re-enter Account Number"}
                              name="ConfirmAccountNumber"
                              placeholder={"Re-Enter Account Number"}
                              component={CustomInput}
                              required
                              maxLength={50}
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"IFSC"}
                              name="IFSC"
                              placeholder={"Enter IFSC"}
                              component={CustomInput}
                              required
                              maxLength={20}
                            />
                          </Col>
                        </Row>
                        <br></br>
                        <button
                          type="submit"
                          className="btn mr-2 btn-primary btn-sm"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn mr-2 btn-primary btn-sm"
                          onClick={() => {
                            setTab(6);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn mr-2 btn-primary btn-sm"
                          onClick={() => {
                            setTab(8);
                          }}
                        >
                          Next
                        </button>
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey={8} key={8} className="mt-4">
                <div className="card">
                  <div className="card-body">
                    <FormikProvider value={formikStaffOfficialInformation}>
                      <Form
                        onSubmit={formikStaffOfficialInformation.handleSubmit}
                      >
                        <Row>
                          <Col lg={3}>
                            <Field
                              label={"Department"}
                              name="DepartmentID"
                              placeholder={"Select Department"}
                              component={CustomSelect}
                              options={departmentOptions}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Designation"}
                              name="DesignationID"
                              placeholder={"Select Designation"}
                              component={CustomSelect}
                              options={designationOptions}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Employment Type"}
                              name="EmploymentType"
                              //placeholder={"Select Employment Type"}
                              component={CustomSelect}
                              options={optionEmploymentType}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Salary Type"}
                              name="SalaryType"
                              placeholder={"Select Salary Type"}
                              component={CustomSelect}
                              options={optionSalaryType}
                              required
                              onChange={(e) => {
                                if (e) {
                                  formikStaffOfficialInformation.setFieldValue(
                                    "SalaryType",
                                    e?.value
                                  );
                                  formikStaffOfficialInformation.setFieldValue(
                                    "SalaryAmount",
                                    parseInt(e?.value) === 1 ? "" : "0"
                                  );
                                  if (parseInt(e?.value) !== 1) {
                                    swal(
                                      "",
                                      "You will have to set Hourly / Per Visit service wise staff salary amount in service section. Ignore if already done.",
                                      "info"
                                    );
                                  }
                                } else {
                                  formikStaffOfficialInformation.setFieldValue(
                                    "SalaryType",
                                    ""
                                  );
                                  formikStaffOfficialInformation.setFieldValue(
                                    "SalaryAmount",
                                    ""
                                  );
                                }
                              }}
                            />
                          </Col>
                          <Col
                            lg={3}
                            style={{
                              display: SalaryType
                                ? parseInt(SalaryType) === 1
                                  ? ""
                                  : "none"
                                : "",
                            }}
                          >
                            <Field
                              type="number"
                              label={"Salary Amount"}
                              name="SalaryAmount"
                              placeholder={"Enter Salary Amount"}
                              component={CustomInput}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Date of Joining"}
                              name="DateOfJoining"
                              placeholder={"Select Date Of Joining"}
                              component={CustomFlatpickr}
                              options={{
                                altInput: true,
                                altFormat: "j-M-Y",
                                dateFormat: "d-m-Y",
                              }}
                              required
                            />
                          </Col>
                          <Col lg={3}>
                            <Field
                              label={"Staff Category"}
                              name="StaffCategory"
                              placeholder={"Select Staff Category"}
                              options={optionsStaffCategory}
                              component={CustomSelect}
                              required
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Date of Leaving"}
                              name="DateOfLeaving"
                              placeholder={"Select Date Of Leaving"}
                              component={CustomFlatpickr}
                              options={{
                                altInput: true,
                                altFormat: "j-M-Y",
                                dateFormat: "d-m-Y",
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text"
                              label={"Reason of Leaving"}
                              name="ReasonOfLeaving"
                              placeholder={"Enter Reason of Leaving"}
                              component={CustomInput}
                              maxLength={100}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Field
                              type="text"
                              label={"Referred By"}
                              name="ReferredBy"
                              placeholder={"Enter Referred By"}
                              component={CustomInput}
                              maxLength={100}
                            />
                          </Col>

                          <Col lg={6}>
                            <Field
                              type="textarea"
                              label={"Remark"}
                              name="Remarks"
                              placeholder={"Enter Remark"}
                              component={CustomInput}
                              rows={3}
                              style={{ resize: "none" }}
                              maxLength={200}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Field
                              label={"Background Verification"}
                              name="BackgroundVerificationStatus"
                              placeholder={"Select Status"}
                              options={optionVerificationStatus}
                              component={CustomSelect}
                              required
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              label={"Background Verification Remarks"}
                              name="BackgroundVerificationRemarks"
                              placeholder={
                                "Enter Background Verification Remarks"
                              }
                              component={CustomInput}
                              required
                            />
                          </Col>
                        </Row>

                        <button type="submit" className="btn mr-2 btn-primary">
                          Submit
                        </button>
                        {/* <button
                          type="button"
                          className="btn mr-2 btn-warning btn-sm"
                        >
                          Approve
                        </button> */}
                        <button
                          type="button"
                          className="btn mr-2 btn-primary btn-sm"
                          onClick={() => {
                            setTab(7);
                          }}
                        >
                          Previous
                        </button>
                        {/* <button
                          type="button"
                          className="btn mr-2 btn-primary btn-sm"
                          onClick={() => {
                            setTab(3);
                          }}
                        >
                          Next
                        </button> */}
                        <button
                          type="button"
                          className="btn mr-2 btn-light btn-sm"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Close
                        </button>
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <Modal
            className="fade text-center"
            show={openCropperModalStaffPhoto}
            onHide={setOpenCropperModalStaffPhoto}
          >
            <Modal.Header>
              <Modal.Title>Staff Photo</Modal.Title>
              <button
                type="button"
                className="btn close"
                onClick={() => setOpenCropperModalStaffPhoto(false)}
              >
                <span>×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Cropper
                    //style={{ height: 400, width: 400 }}
                    // zoomTo={0.5}
                    zoomTo={zoomStaffPhoto}
                    initialAspectRatio={1 / 1}
                    aspectRatio={1 / 1}
                    src={imageStaffPhoto}
                    viewMode={1}
                    minCropBoxHeight={500}
                    minCropBoxWidth={500}
                    maxCropBoxHeight={500}
                    maxCropBoxWidth={500}
                    background={false}
                    responsive={true}
                    // autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                      setCropperStaffPhoto(instance);
                    }}
                    //guides={false}
                    checkCrossOrigin={false}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Row style={{ width: "100%" }}>
                <Col md="4">
                  <Button
                    className="btn btn-success btn-block btn-xs mt-2"
                    onClick={() => {
                      setOpenCropperModalStaffPhoto(false);
                      setFileKeyStaffPhoto(
                        Math.random().toString(9).substring(2, 9)
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md="2">
                  <Button
                    className="btn btn-success btn-block btn-xs mt-2"
                    onClick={() => {
                      setZoomStaffPhoto(zoomStaffPhoto + 0.5);
                    }}
                  >
                    <i className="fa fa-search-plus"></i>
                  </Button>
                </Col>
                <Col md="2">
                  <Button
                    className="btn btn-success btn-block btn-xs mt-2"
                    onClick={() => {
                      setZoomStaffPhoto(
                        zoomStaffPhoto > 0
                          ? zoomStaffPhoto - 0.5
                          : zoomStaffPhoto
                      );
                    }}
                  >
                    <i className="fa fa-search-minus"></i>
                  </Button>
                </Col>
                <Col md="4">
                  <Button
                    className="btn btn-success btn-block btn-xs mt-2 "
                    onClick={() => {
                      getCropDataStaffPhoto();
                      setOpenCropperModalStaffPhoto(false);
                    }}
                  >
                    Crop Image
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
          <Modal
            className="fade text-center"
            show={openWebCamModalStaffPhoto}
            onHide={setOpenWebCamModalStaffPhoto}
          >
            <Modal.Header>
              <Modal.Title>Staff Photo</Modal.Title>
              <button
                type="button"
                className="btn close"
                onClick={() => setOpenWebCamModalStaffPhoto(false)}
              >
                <span>×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Webcam
                    ref={webcamRef}
                    audio={false}
                    mirrored={true}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    onUserMedia={onUserMedia}
                    className="webCam"
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Row style={{ width: "100%" }}>
                <Col md="4">
                  <Button
                    className="btn btn-success btn-block btn-xs mt-2"
                    onClick={capturePhoto}
                  >
                    Capture
                  </Button>
                </Col>
                <Col md="4"></Col>
                <Col md="4">
                  <Button
                    className="btn btn-success btn-block btn-xs mt-2 "
                    onClick={() => {
                      setOpenWebCamModalStaffPhoto(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
          <Modal
            className="modal fade"
            show={qualificationModal}
            onHide={setQualificationModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikStaffQualification}>
                <Form onSubmit={formikStaffQualification.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Staff Qualification</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setQualificationModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          <Col lg={12}>
                            <Field
                              name={"QualificationID"}
                              label={"Qualification"}
                              placeholder="Select Qualification"
                              component={CustomSelect}
                              options={qualificationOptions}
                            />
                          </Col>
                          <Col lg={12}>
                            <Label>Upload Document</Label>
                            <div className="input-group mb-3">
                              <div
                                className="custom-file"
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type="file"
                                  id="fileControlQualification"
                                  name="fileControlQualification"
                                  className="custom-file-input"
                                  onChange={(e) => {
                                    uploadQualificationFile(e);
                                  }}
                                  key={fileKeyQualification || ""}
                                  // required={qualificationFile ? false : true}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="fileControlQualification"
                                >
                                  {qualificationFile
                                    ? qualificationFile?.name
                                    : "Choose file"}
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Upload
                    </button>
                    <button
                      type="button"
                      onClick={() => setQualificationModal(false)}
                      className="btn btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={documentModal}
            onHide={setDocumentModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikStaffDocuments}>
                <Form onSubmit={formikStaffDocuments.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Staff Document</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setDocumentModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          <Col lg={12}>
                            <Field
                              name={"DocumentID"}
                              label={"Document"}
                              placeholder="Select Document"
                              component={CustomSelect}
                              options={documentOptions}
                              required
                            />
                          </Col>
                          <Col lg={12}>
                            <Label>Upload Document</Label>
                            <div className="input-group mb-3">
                              <div
                                className="custom-file"
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type="file"
                                  id="fileControlDocument"
                                  name="fileControlDocument"
                                  className="custom-file-input"
                                  onChange={(e) => {
                                    uploadDocumentFile(e);
                                  }}
                                  key={fileKeyDocument || ""}
                                  required={documentFile ? false : true}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="fileControlDocument"
                                >
                                  {documentFile
                                    ? documentFile?.name
                                    : "Choose file"}
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text"
                              label={"Document Receive Date"}
                              name="DocumentReceiveDate"
                              placeholder={"Document Receive Date"}
                              component={CustomFlatpickr}
                              options={{
                                altInput: true,
                                altFormat: "j-M-Y",
                                dateFormat: "d-m-Y",
                              }}
                              required
                            />
                          </Col>
                          <Col lg={6}>
                            <div
                              className="custom-control custom-checkbox  checkbox-success"
                              style={{ marginTop: "35px" }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="chkIsVerified"
                                checked={IsVerified}
                                onChange={(e) => {
                                  formikStaffDocuments.setFieldValue(
                                    "IsVerified",
                                    e.target.checked
                                  );
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="chkIsVerified"
                              >
                                Is Verified
                              </label>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Field
                              type="textarea"
                              name="Remarks"
                              label={"Remarks"}
                              placeholder="Enter Remarks"
                              component={CustomInput}
                              maxLength={100}
                              style={{ resize: "none" }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Upload
                    </button>
                    <button
                      type="button"
                      onClick={() => setDocumentModal(false)}
                      className="btn btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={serviceModal}
            onHide={setServiceModal}
            size="lg"
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikStaffServices}>
                <Form onSubmit={formikStaffServices.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Staff Service</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setServiceModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          <Col lg={6}>
                            <Field
                              name={"ServiceTypeIDEncrypted"}
                              label={"Service Type"}
                              placeholder="Select Service Type"
                              component={CustomSelect}
                              options={serviceTypeOptions}
                              required
                              isDisabled={
                                StaffServiceIDEncrypted ? true : false
                              }
                            />
                          </Col>
                          {ServiceTypeIDEncrypted &&
                          serviceSubTypeOptions?.length === 0 ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <Field
                                name={"ServiceSubTypeIDEncrypted"}
                                label={"Service Sub Type"}
                                placeholder="Select Service Sub Type"
                                component={CustomSelect}
                                options={serviceSubTypeOptions}
                                required
                                isDisabled={
                                  StaffServiceIDEncrypted ? true : false
                                }
                              />
                            </Col>
                          )}

                          <Col lg={6}>
                            <Field
                              name={"ServiceIDEncrypted"}
                              label={"Service Name"}
                              placeholder="Select Service"
                              component={CustomSelect}
                              options={serviceOptions}
                              required
                              isDisabled={
                                StaffServiceIDEncrypted ? true : false
                              }
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="textarea"
                              name={"Remarks"}
                              label={"Remark"}
                              placeholder="Enter Remark"
                              component={CustomInput}
                              rows={3}
                              // required
                              style={{ resize: "none" }}
                              maxLength={100}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} className="mt-2">
                            <h6>
                              {/* <u>Special Rate</u> */}
                              <u>Service Price</u>
                            </h6>
                          </Col>
                          <Col lg={12}>
                            <Row className="mt-2">
                              <Col lg={12}>
                                {/* <div className="table-responsive">
                                  <div
                                    id="specialRate_list"
                                    className="dataTables_wrapper no-footer"
                                  >
                                    <table
                                      id="example5"
                                      className="table patient-list mb-1 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                                      role="grid"
                                      aria-describedby="example5_info"
                                    >
                                      <thead className="thead-light">
                                        <tr role="row">
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Charge Type: activate to sort column ascending"
                                            style={{
                                              width: "40%",
                                              padding: "10px 15px",
                                            }}
                                          >
                                            Charge Type
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Default Rate: activate to sort column ascending"
                                            style={{
                                              width: "25%",
                                              padding: "10px 10px",
                                            }}
                                          >
                                            Default Rate
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Special Rate: activate to sort column ascending"
                                            style={{
                                              width: "35%",
                                              padding: "10px 20px",
                                            }}
                                          >
                                            Special Rate
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {ServicePriceList &&
                                          ServicePriceList?.map(
                                            (priceList, index) => (
                                              <tr role="row" className="even">
                                                <td>{priceList.ServiceHour}</td>
                                                <td
                                                  style={{
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <i className="fa fa-rupee"></i>{" "}
                                                  {priceList.ServicePrice}
                                                </td>
                                                <td>
                                                  <Field
                                                    type="number"
                                                    name={`ServicePriceList.[${index}].ServiceSpecialRate`}
                                                    placeholder={"Enter Rate"}
                                                    component={CustomInput}
                                                    min={0}
                                                  />
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div> */}

                                {/*`````````````````````````````````````````````````````````````````````````````````````````` */}

                                <Accordion
                                  className="accordion accordion-no-gutter accordion-header-bg"
                                  defaultActiveKey={1}
                                >
                                  <div className="accordion__item" key={1}>
                                    <Accordion.Toggle
                                      eventKey={1}
                                      className={`accordion__header  ${
                                        activeDefault === 1 ? "" : "collapsed"
                                      }`}
                                      style={{
                                        minWidth: "100%",
                                      }}
                                      onClick={() =>
                                        setActiveDefault(
                                          activeDefault === 1 ? -1 : 1
                                        )
                                      }
                                    >
                                      <span
                                        className="accordion__header--text"
                                        style={{ float: "left" }}
                                      >
                                        {"Current Effective Date"}
                                      </span>
                                      <span className="accordion__header--indicator"></span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={1}>
                                      <div className="accordion__body--text">
                                        <Row>
                                          <Col lg={4}>
                                            <Field
                                              label={"Current Effective Date"}
                                              name="CurrentEffectiveDate"
                                              placeholder={
                                                "Select Current Effective Date"
                                              }
                                              component={CustomFlatpickr}
                                              options={{
                                                altInput: true,
                                                altFormat: "j-M-Y",
                                                dateFormat: "d-m-Y",
                                                maxDate: new Date(),
                                              }}
                                              disabled
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mt-2">
                                          <Col lg={12}>
                                            <div className="table-responsive">
                                              <div
                                                id="specialRate_list"
                                                className="dataTables_wrapper no-footer"
                                              >
                                                <table
                                                  id="example5"
                                                  className="table patient-list mb-1 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                                                  role="grid"
                                                  aria-describedby="example5_info"
                                                >
                                                  <thead className="thead-light">
                                                    <tr role="row">
                                                      <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="example5"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Charge Type: activate to sort column ascending"
                                                        style={{
                                                          width: "40%",
                                                          padding: "10px 15px",
                                                        }}
                                                      >
                                                        Charge Type
                                                      </th>
                                                      <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="example5"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Salary Amount: activate to sort column ascending"
                                                        style={{
                                                          width: "30%",
                                                          padding: "10px 20px",
                                                          display: SalaryType
                                                            ? parseInt(
                                                                SalaryType
                                                              ) !== 1
                                                              ? ""
                                                              : "none"
                                                            : "",
                                                        }}
                                                      >
                                                        Salary Amount
                                                      </th>
                                                      <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="example5"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Patient Amount: activate to sort column ascending"
                                                        style={{
                                                          //width: "30%",
                                                          width: SalaryType
                                                            ? parseInt(
                                                                SalaryType
                                                              ) !== 1
                                                              ? "30%"
                                                              : "20%"
                                                            : "30%",
                                                          padding: "10px 20px",
                                                        }}
                                                      >
                                                        Patient Amount
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {CurrentEffectivePriceList?.length >
                                                    0 ? (
                                                      CurrentEffectivePriceList?.map(
                                                        (
                                                          currentList,
                                                          index
                                                        ) => (
                                                          <tr role="row">
                                                            <td>
                                                              {
                                                                currentList.ServiceHour
                                                              }
                                                            </td>
                                                            <td
                                                              style={{
                                                                display:
                                                                  SalaryType
                                                                    ? parseInt(
                                                                        SalaryType
                                                                      ) !== 1
                                                                      ? ""
                                                                      : "none"
                                                                    : "",
                                                              }}
                                                            >
                                                              <Field
                                                                type="number"
                                                                name={`CurrentEffectivePriceList.[${index}].SalaryAmount`}
                                                                component={
                                                                  CustomInput
                                                                }
                                                                placeholder="Enter Amount"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  formikStaffServices.setFieldValue(
                                                                    `CurrentEffectivePriceList.[${index}].SalaryAmount`,
                                                                    e.target.value.slice(
                                                                      0,
                                                                      6
                                                                    )
                                                                  );
                                                                }}
                                                                min={0}
                                                                disabled={
                                                                  SalaryType
                                                                    ? parseInt(
                                                                        SalaryType
                                                                      ) !== 1
                                                                      ? false
                                                                      : true
                                                                    : false
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                type="number"
                                                                name={`CurrentEffectivePriceList.[${index}].PatientAmount`}
                                                                component={
                                                                  CustomInput
                                                                }
                                                                placeholder="Enter Amount"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  formikStaffServices.setFieldValue(
                                                                    `CurrentEffectivePriceList.[${index}].PatientAmount`,
                                                                    e.target.value.slice(
                                                                      0,
                                                                      6
                                                                    )
                                                                  );
                                                                }}
                                                                min={0}
                                                              />
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    ) : (
                                                      <tr>
                                                        <td colSpan={3}>
                                                          <div className="mt-2 ml-3 mb-3">
                                                            {"No Record Found"}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Accordion.Collapse>
                                  </div>
                                  <div className="accordion__item" key={2}>
                                    <Accordion.Toggle
                                      eventKey={2}
                                      className={`accordion__header ${
                                        activeDefault === 2 ? "" : "collapsed"
                                      }`}
                                      style={{
                                        minWidth: "100%",
                                        backgroundColor: "#d1d1d1",
                                      }}
                                      onClick={() =>
                                        setActiveDefault(
                                          activeDefault === 2 ? -1 : 2
                                        )
                                      }
                                    >
                                      <span
                                        className="accordion__header--text"
                                        style={{
                                          float: "left",
                                        }}
                                      >
                                        {"Next Effective Date"}
                                      </span>
                                      <span className="accordion__header--indicator"></span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={2}>
                                      <div className="accordion__body--text">
                                        {" "}
                                        <Row>
                                          <Col lg={4}>
                                            <Field
                                              label={"Next Effective Date"}
                                              name="NextEffectiveDate"
                                              placeholder={
                                                "Select Next Effective Date"
                                              }
                                              component={CustomFlatpickr}
                                              options={{
                                                minDate: new Date(),
                                                altInput: true,
                                                altFormat: "j-M-Y",
                                                dateFormat: "d-m-Y",
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mt-2">
                                          <Col lg={12}>
                                            <div className="table-responsive">
                                              <div
                                                id="specialRate_list"
                                                className="dataTables_wrapper no-footer"
                                              >
                                                <table
                                                  id="example5"
                                                  className="table patient-list mb-1 dataTablesCard fs-14 dataTable SubDataTable no-footer"
                                                  role="grid"
                                                  aria-describedby="example5_info"
                                                >
                                                  <thead className="thead-light">
                                                    <tr role="row">
                                                      <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="example5"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Charge Type: activate to sort column ascending"
                                                        style={{
                                                          width: "40%",
                                                          padding: "10px 15px",
                                                        }}
                                                      >
                                                        Charge Type
                                                      </th>
                                                      <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="example5"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Salary Amount: activate to sort column ascending"
                                                        style={{
                                                          width: "30%",
                                                          padding: "10px 20px",
                                                          display: SalaryType
                                                            ? parseInt(
                                                                SalaryType
                                                              ) !== 1
                                                              ? ""
                                                              : "none"
                                                            : "",
                                                        }}
                                                      >
                                                        Salary Amount
                                                      </th>
                                                      <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="example5"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Patient Amount: activate to sort column ascending"
                                                        style={{
                                                          //width: "30%",
                                                          width: SalaryType
                                                            ? parseInt(
                                                                SalaryType
                                                              ) !== 1
                                                              ? "30%"
                                                              : "20%"
                                                            : "30%",
                                                          padding: "10px 20px",
                                                        }}
                                                      >
                                                        Patient Amount
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {NextEffectivePriceList?.length >
                                                    0 ? (
                                                      NextEffectivePriceList?.map(
                                                        (nextList, index) => (
                                                          <tr role="row">
                                                            <td>
                                                              {
                                                                nextList.ServiceHour
                                                              }
                                                            </td>
                                                            <td
                                                              style={{
                                                                display:
                                                                  SalaryType
                                                                    ? parseInt(
                                                                        SalaryType
                                                                      ) !== 1
                                                                      ? ""
                                                                      : "none"
                                                                    : "",
                                                              }}
                                                            >
                                                              <Field
                                                                type="number"
                                                                name={`NextEffectivePriceList.[${index}].SalaryAmount`}
                                                                component={
                                                                  CustomInput
                                                                }
                                                                placeholder="Enter Amount"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  formikStaffServices.setFieldValue(
                                                                    `NextEffectivePriceList.[${index}].SalaryAmount`,
                                                                    e.target.value.slice(
                                                                      0,
                                                                      6
                                                                    )
                                                                  );
                                                                }}
                                                                min={0}
                                                                disabled={
                                                                  SalaryType
                                                                    ? parseInt(
                                                                        SalaryType
                                                                      ) !== 1
                                                                      ? false
                                                                      : true
                                                                    : false
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                type="number"
                                                                name={`NextEffectivePriceList.[${index}].PatientAmount`}
                                                                component={
                                                                  CustomInput
                                                                }
                                                                placeholder="Enter Amount"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  formikStaffServices.setFieldValue(
                                                                    `NextEffectivePriceList.[${index}].PatientAmount`,
                                                                    e.target.value.slice(
                                                                      0,
                                                                      6
                                                                    )
                                                                  );
                                                                }}
                                                                min={0}
                                                              />
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    ) : (
                                                      <tr>
                                                        <td colSpan={3}>
                                                          <div className="mt-2 ml-3 mb-3">
                                                            {"No Record Found"}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Accordion.Collapse>
                                  </div>
                                </Accordion>
                                {/*`````````````````````````````````````````````````````````````````````````````````````````` */}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => setServiceModal(false)}
                      className="btn btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
          <Modal
            className="modal fade"
            show={experienceModal}
            onHide={setExperienceModal}
          >
            <Spin size="large" spinning={isLoading} tip={"Loading..."}>
              <FormikProvider value={formikStaffExperienceDetail}>
                <Form onSubmit={formikStaffExperienceDetail.handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Staff Experience</h4>
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setExperienceModal(false)}
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <Row>
                          <Col lg={12}>
                            <Field
                              name={"CompanyName"}
                              label={"Company / Hospital Name"}
                              placeholder="Enter Company / Hospital Name"
                              component={CustomInput}
                              required
                              maxLength={200}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              name={"Designation"}
                              label={"Designation"}
                              placeholder="Designation"
                              component={CustomInput}
                              required
                              maxLength={100}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="number"
                              name={"ExperienceDuration"}
                              label={"Duration (Year)"}
                              placeholder="Duration"
                              component={CustomInput}
                              required
                              maxLength={3}
                              onChange={(e) => {
                                formikStaffExperienceDetail.setFieldValue(
                                  "ExperienceDuration",
                                  e.target.value.slice(0, 3)
                                );
                              }}
                            />
                          </Col>
                          <Col lg={12}>
                            <Field
                              type={"textarea"}
                              name={"ExperienceRemarks"}
                              label={"Remarks"}
                              placeholder="Enter Remarks"
                              component={CustomInput}
                              rows={3}
                              // required
                              style={{ resize: "none" }}
                              maxLength={100}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => setExperienceModal(false)}
                      className="btn btn-light"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Cancel
                    </button>
                  </div>
                </Form>
              </FormikProvider>
            </Spin>
          </Modal>
        </div>
      </Spin>
    </Fragment>
  );
};

export default StaffDetails;
