import { ErrorMessage } from "formik";
import { Label } from "reactstrap";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

const CustomFlatpickr = ({
  field: { name, value },
  form: { setFieldValue },
  label,
  required,
  placeholder,
  onChange,
  ...props
}) => {
  return (
    <div className="mb-3">
      <Label>
        {label} {required && <span className="text-danger">*</span>}
      </Label>
      <Flatpickr
        {...props}
        value={value}
        className="form-control"
        placeholder={placeholder}
        // onChange={([start]) => setFieldValue(name, start)}
        onChange={([start]) => {
          if (onChange) {
            onChange(start);
          } else {
            setFieldValue(name, start);
          }
        }}
      />
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
};

export default CustomFlatpickr;
