import { post } from "../../jsx/Helper/api_helper";
import { POST_GetNotificationsList } from "../../jsx/Helper/url_helper";

export const NOTIFICATION_LIST = (payload) => async (dispatch) => {
  try {
    const response = await post(POST_GetNotificationsList, payload);
    if (response?.IsSuccess) {
      dispatch({
        type: POST_GetNotificationsList,
        NotificationList: response?.NotificationList,
        TotalUnreadCount: response?.TotalUnreadCount,
      });
    }
    return response;
  } catch (err) {
    console.log("Error!", err?.message, "error");
  }
};
