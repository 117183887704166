import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Input, Label, Row } from "reactstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import companyLogo from "../../../../images/avatar/CompanyLogo.png";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import * as url from "../../../Helper/url_helper";
import { post, postFormData } from "../../../Helper/api_helper";
import { Spin } from "antd";
import swal from "sweetalert";
import { t } from "i18next";

const AddCompanies = () => {
  const { CompanyIDEncryptParam } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [iconPath, setIconPath] = useState("");

  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [optionState, setOptionState] = useState([]);
  const [optionCity, setOptionCity] = useState([]);

  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [fileKeyLogo, setFileKeyLogo] = useState();
  const [iconPathx, setIconPathx] = useState("");
  const [validationLogo, setValidationLogo] = useState("");

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const validationSchema = Yup.object().shape({
    CompanyName: Yup.string().required("Company Name is required field"),
    GSTNumber: Yup.string()
      .required("GST Number is required field")
      .matches(
        "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
        "GST Number is invalid"
      ),
    Address: Yup.string().required("Address is required field"),
    State: Yup.string().required("State is required field"),
    City: Yup.string().required("City is required field"),
    CompanyPrefixNo: Yup.string().required("Company Prefix is required field"),
    ContactPersonName: Yup.string().required(
      "Contact Person Name is required field"
    ),
    EmailID: Yup.string()
      .required("Email is a required field")
      .email("Enter a valid email"),
    MobileNo: Yup.string().required("Mobile No is required field"),
    // Location: Yup.string().required("Location is required field"),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: CompanyIDEncryptParam
        ? window.atob(CompanyIDEncryptParam)
        : "",
      CompanyName: "",
      GSTNumber: "",
      Address: "",
      State: "",
      City: "",
      Location: "",
      Latitude: "",
      Longitude: "",
      CompanyPrefixNo: "",
      StaffPrefixNo: "",
      ContactPersonName: "",
      EmailID: "",
      MobileNo: "",
      Status: true,
      CountryCode: "+91",
    },
    validateOnBlur: false,
  });

  const {
    isSubmitting,
    setValues,
    setFieldValue,
    values: { State, CompanyID, CountryCode, City },
  } = formik;

  useEffect(() => {
    if (State) {
      getCityList(State);
    } else {
      formik.setFieldValue("State", "");
      formik.setFieldValue("City", "");
      setOptionCity([]);
    }
  }, [State]);

  const getStateList = () => {
    setIsLoading(true);
    post(url.POST_GetStateList)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionState(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  const getCityList = (stateId) => {
    setIsLoading(true);
    post(url.POST_GetCityList + stateId)
      .then((res) => {
        if (res.IsSuccess) {
          setOptionCity(
            res.List.map((item) => ({
              label: item.CategoryName,
              value: item.CategoryID,
            }))
          );
          if (
            res.List?.length > 0 &&
            City &&
            res.List?.filter((x) => x.CategoryID == City).length == 0
          ) {
            formik.setFieldValue("City", "");
          }
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error), setIsLoading(false));
  };

  useEffect(() => {
    getStateList();
    if (CompanyIDEncryptParam) {
      setIsLoading(true);
      post(url.POST_GetCompanyDetailByID, {
        CompanyIDEncrypt: window.atob(CompanyIDEncryptParam),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            CompanyID: window.atob(CompanyIDEncryptParam),
            CompanyName: res.CompanyName,
            GSTNumber: res.GSTNo,
            Address: res.Address,
            State: res.StateID,
            City: res.CityID,
            // Location: res.,
            Latitude: res.Latitude,
            Longitude: res.Longitude,
            CompanyPrefixNo: res.CompanyPrefixNo,
            StaffPrefixNo: res.StaffPrefixNo,
            ContactPersonName: res.ContactPersonName,
            EmailID: res.EmailID,
            MobileNo: res.MobileNo,
            Status: res.IsActive,
            CountryCode: res.CountryCode,
          });
          setLogo(res.CompanyLogo);
          getCityList(res.StateID);
        }
      });
      setIsLoading(false);
    }
  }, []);

  const uploadLogo = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        setLogoFile(null);
        setLogo(null);
        setFileKeyLogo(Math.random().toString(9).substring(2, 9));
        swal("", "Select valid image file!", "warning");
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== 120 || width !== 400) {
              swal({
                title: "Error",
                text: "Image size must be of 400x120",
                icon: "error",
                button: "OK",
              });
              setLogoFile(null);
              setLogo(null);
            } else {
              // alert("Uploaded image has valid Height and Width.");
              setLogoFile(file);
              setIconPathx("");
              setLogo(URL.createObjectURL(file));
              setValidationLogo("");
            }
          };
        };
      }
    } else {
      setLogoFile(null);
      setLogo(null);
    }
  };

  const SaveUploadImages = (cID) => {
    if (logoFile) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("EncryptCompanyID", cID);
      formData.append("EncryptCreatedBy", objLogin.LoginIDEncrypt);
      formData.append("Logo", logoFile);

      postFormData(url.POST_CompanyUpdateLogo, formData)
        .then((response) => {
          if (response.IsSuccess) {
            // event.target.value = []
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (logoFile === null && logo === null) {
      setValidationLogo("Company Logo is required");
      return false;
    }
    setIsLoading(true);
    const data = {
      CompanyIDEncrypt: CompanyID,
      CompanyName: values.CompanyName,
      GSTNo: values.GSTNumber,
      Address: values.Address,
      StateID: values.State ? values.State : 0,
      CityID: values.City ? values.City : 0,
      CompanyType: 2,
      Latitude: 0,
      Longitude: 0,
      CompanyPrefixNo: values.CompanyPrefixNo,
      StaffPrefixNo: "SF",
      EmailID: values.EmailID,
      MobileNo: values.MobileNo,
      ContactPersonName: values.ContactPersonName,
      IsActive: values.Status,
      ModifiedByEncrypt: objLogin.LoginIDEncrypt,
      CountryCode: "+" + CountryCode.replace(/\+/g, ""),
    };
    post(CompanyID ? url.POST_CompanyUpdate : url.POST_CompanyCreate, data)
      .then((response) => {
        if (response.IsSuccess) {
          SaveUploadImages(response.EncryptID);

          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });

          formik.resetForm();
          history.push("/companies");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={CompanyIDEncryptParam ? "Edit Company" : "Add Company"}
          motherMenu={<Link to="/companies">Companies</Link>}
        />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"Company Name"}
                            name="CompanyName"
                            placeholder={"Enter Company Name"}
                            component={CustomInput}
                            // onChange={(e) => {
                            //   setFieldValue(
                            //     "CompanyName",
                            //     e.target.value.replace(/[^A-Za-z , () - ]/g, "")
                            //   );
                            // }}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"GST Number"}
                            name="GSTNumber"
                            placeholder={"Enter GST Number"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "GSTNumber",
                                e.target.value
                                  .replace(/[^A-Za-z0-9]/g, "")
                                  ?.toUpperCase()
                              );
                            }}
                            style={{ textTransform: "Uppercase" }}
                            maxLength={15}
                            //minLength={15}
                            required
                          />
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            label={"Contact Person Name"}
                            name="ContactPersonName"
                            placeholder={"Enter Contact Person Name"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "ContactPersonName",
                                e.target.value.replace(/[^A-Za-z ]/g, "")
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={8}>
                          <Row>
                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"Email ID"}
                                name="EmailID"
                                placeholder={"Enter Email ID"}
                                component={CustomInput}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                type="text"
                                label={"Mobile No"}
                                name="MobileNo"
                                placeholder={"Enter Mobile No"}
                                country={country}
                                countryCode={CountryCode}
                                component={CustomPhoneInput}
                                onChange={(
                                  value,
                                  { countryCode, dialCode }
                                ) => {
                                  setFieldValue("CountryCode", dialCode);
                                  setFieldValue(
                                    "MobileNo",
                                    value.substring(dialCode.toString().length)
                                  );
                                }}
                                required
                              />
                            </Col>
                          </Row>
                          <hr></hr>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Label>
                            Company Logo <span className="text-danger">*</span>{" "}
                            <span style={{ color: "red", fontSize: "10px" }}>
                              (400 x 120)
                            </span>
                          </Label>
                          {/* <div className="input-group">
                          <div className="custom-file">
                            <input type="file" className="custom-file-input" />
                            <label className="custom-file-label">
                              Choose file
                            </label>
                          </div>
                        </div> */}
                          <div className="image-placeholder companyLogo-placeholder">
                            <div className="avatar-edit">
                              <input
                                type="file"
                                // onChange={fileHandler}
                                id="imageUpload"
                                // onClick={(event) => setFile(event.target.value)}
                                onChange={uploadLogo}
                                key={fileKeyLogo || ""}
                              />
                              <label htmlFor="imageUpload" name=""></label>
                            </div>
                            <div className="avatar-preview">
                              <div id="imagePreview">
                                <img
                                  id="saveImageFile"
                                  src={
                                    logoFile || logo
                                      ? logoFile
                                        ? URL.createObjectURL(logoFile)
                                        : logo
                                      : companyLogo
                                  }
                                  alt={logoFile ? logoFile.name : null}
                                />
                              </div>
                            </div>
                          </div>
                          <span
                            style={{
                              color: "#ec4561",
                              marginTop: "0.25rem",
                              fontSize: "80%",
                            }}
                          >
                            {validationLogo}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Address"}
                            name="Address"
                            type="textarea"
                            placeholder={"Enter Address"}
                            rows={5}
                            component={CustomInput}
                            // onChange={(e) => {
                            //   setFieldValue(
                            //     "Address",
                            //     e.target.value.replace(/[^A-Za-z 0-9 , -]/g, "")
                            //   );
                            // }}
                            required
                            style={{ resize: "none" }}
                          />
                        </Col>
                        <Col lg={8}>
                          <Row>
                            <Col lg={6}>
                              <Field
                                label={"State"}
                                name="State"
                                placeholder={"Select State"}
                                options={optionState}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                label={"City"}
                                name="City"
                                placeholder={"Select City"}
                                options={optionCity}
                                component={CustomSelect}
                                required
                              />
                            </Col>
                            {/* <Col lg={6}>
                              <Field
                                label={"Location"}
                                name="Location"
                                type="text"
                                placeholder={"Select Location"}
                                component={CustomInput}
                                required
                              />
                            </Col> */}
                            {/* <Col lg={4}>
                              <Field
                                label={"Latitude"}
                                name="Latitude"
                                type="text"
                                placeholder={"Enter Latitude"}
                                component={CustomInput}
                              />
                            </Col>
                            <Col lg={4}>
                              <Field
                                label={"Longitude"}
                                name="Longitude"
                                type="text"
                                placeholder={"Enter Longitude"}
                                component={CustomInput}
                              />
                            </Col> */}
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row className="mb-3">
                        <Col lg={3}>
                          <Field
                            label={"Company Prefix"}
                            name="CompanyPrefixNo"
                            type="text"
                            placeholder={"Enter Company Prefix"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "CompanyPrefixNo",
                                e.target.value
                                  .replace(/[^A-Za-z]/g, "")
                                  ?.toUpperCase()
                              );
                            }}
                            style={{ textTransform: "Uppercase" }}
                            required
                            maxLength={3}
                          />
                        </Col>
                        {/* <Col lg={3}>
                          <Field
                            label={"Staff Prefix"}
                            name="StaffPrefixNo"
                            type="text"
                            placeholder={"Enter Staff Prefix"}
                            component={CustomInput}
                            required
                            maxLength={2}
                          />
                        </Col> */}
                      </Row>

                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default AddCompanies;
