import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import * as Yup from "yup";
import CustomInput from "../../../Custom/CustomInput";
import { Field, FormikProvider, useFormik } from "formik";
import { Col, Form, Label, Row } from "reactstrap";
import CustomFlatpickr from "../../../Custom/CustomFlatpicker";
import { InputGroup, Modal } from "react-bootstrap";
import CustomSelect from "../../../Custom/CustomSelect";
import CustomPhoneInput from "../../../Custom/CustomPhoneInput";
import {
  POST_OrderBookingEquipmentPDCSearchByOrderBookingNo,
  POST_GetOrderBookingEquipmentPDCDetailsByID,
  POST_AdminOrderBookingEquipmentPDCDetailsInsert,
  POST_AdminOrderBookingEquipmentPDCDetailsUpdate,
  POST_AdminStaffsListDDLByCompanyID,
  POST_AdminOrderBookingEquipmentPDCFileInsert,
  POST_GetModeOfPaymentDDL,
} from "../../../Helper/url_helper";
import { post, postFormData } from "../../../Helper/api_helper";
import { t } from "i18next";
import { Spin } from "antd";
import swal from "sweetalert";
import ModalOrderBookingDetail from "../../../Custom/ModalOrderBookingDetail";
import moment from "moment";

const AddEquipmentPDC = () => {
  const { OrderBookingEquipmentPDCDetailIDEncryptParam } = useParams();

  const history = useHistory();

  const [orderDetailModal, setOrderDetailModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [orderBookingID, setOrderBookingID] = useState("");
  const [staffOptions, setStaffOptions] = useState([]);
  const [modeOfPaymentOptions, setModeOfPaymentOptions] = useState([]);

  const [file, setFile] = useState(null);
  const [fileKey, setFileKey] = useState();
  const [viewPDC, setViewPDC] = useState(null);
  const [validationPDCFile, setValidationPDCFile] = useState("");

  const [countryGivenBy, setCountryGivenBy] = useState("in");
  const [countryReturnTo, setCountryReturnTo] = useState("in");

  var objLogin = JSON.parse(localStorage.getItem("userDetails"));

  const loadModeOfPaymentOptionsDDL = async () => {
    await post(POST_GetModeOfPaymentDDL)
      .then((res) => {
        if (res.IsSuccess) {
          setModeOfPaymentOptions(
            res.ModeOfPaymentList.map((item) => ({
              label: item.ModeOfPayment,
              value: item.ModeOfPaymentID,
              isCheque: item.IsCheque,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const validationSchema = Yup.object().shape({
    OrderBookingNo: Yup.string().required("Order Booking No. is required"),
    PDCReceivedDate: Yup.string().required("Deposit Received Date is required"),
    PDCRemarks: Yup.string().required("Deposit Remark is required"),
    PDCRecievedByStaff: Yup.string().required(
      "Deposit Recieved By Staff is required"
    ),
    PDCGivenBy: Yup.string().required("Deposit Given By is required"),
    PDCGivenByMobileNo: Yup.string().required(
      "Deposit Given By Mobile No is required"
    ),
    EquipmentDepositAmount: Yup.number()
      .positive("Equipment Deposit Amount is requried")
      .integer("Equipment Deposit Amount is requried")
      .required("Equipment Deposit Amount is requried"),
    PDCReturnDate: Yup.string().when("IsPDCReturned", {
      is: true,
      then: Yup.string().required("Deposit Return Date is required"),
    }),
    PDCReturnRemark: Yup.string().when("IsPDCReturned", {
      is: true,
      then: Yup.string().required("Deposit Return Remark is required"),
    }),
    PDCReturnByStaff: Yup.string().when("IsPDCReturned", {
      is: true,
      then: Yup.string().required("Deposit Return By Staff is required"),
    }),
    PDCReturnTo: Yup.string().when("IsPDCReturned", {
      is: true,
      then: Yup.string().required("Deposit Return To is required"),
    }),
    PDCReturnToMobileNo: Yup.string().when("IsPDCReturned", {
      is: true,
      then: Yup.string().required("Deposit Return To MobileNo is required"),
    }),
    ModeOfPaymentID: Yup.string().required("Mode Of Payment is required"),
    EquipmentDepositReturnAmount: Yup.number()
      .positive("Deposit Return Amount is requried")
      .integer("Deposit Retutn Amount is requried")
      .when("IsPDCReturned", {
        is: true,
        then: Yup.number().required("Deposit Return Amount is requried"),
      }),
    ReturnModeOfPaymentID: Yup.string().when("IsPDCReturned", {
      is: true,
      then: Yup.string().required("Mode Of Payment is required"),
    }),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CompanyID: objLogin?.CompanyIDEncrypt,
      OrderBookingNo: "",
      PDCReceivedDate: "",
      PDCRemarks: "",
      PDCReturnDate: "",
      PDCReturnRemark: "",
      PDCRecievedByStaff: "",
      PDCGivenBy: "",
      PDCGivenByMobileNo: "",
      IsPDCReturned: false,
      PDCReturnByStaff: "",
      PDCReturnTo: "",
      PDCReturnToMobileNo: "",
      EquipmentDepositAmount: "",
      PatientNo: "",
      PatientName: "",
      CountryCodeGivenBy: "+91",
      CountryCodeReturnTo: "+91",
      ModeOfPaymentID: "",
      PaymentTransactionID: "0",
      IsDepositInPDC: false,
      EquipmentDepositReturnAmount: "",
      ReturnModeOfPaymentID: "",
      ReturnPaymentTransactionID: "0",
    },
    validateOnBlur: false,
  });

  const {
    setValues,
    setFieldValue,
    values: {
      IsPDCReturned,
      PatientNo,
      PatientName,
      OrderBookingNo,
      CompanyID,
      CountryCodeGivenBy,
      CountryCodeReturnTo,
      PDCReceivedDate,
      PDCReturnDate,
      IsDepositInPDC,
    },
  } = formik;

  const handleChangeIsPDCReturned = (event) => {
    setFieldValue("IsPDCReturned", event.target.checked);
  };

  useEffect(() => {
    getStaffList();
    loadModeOfPaymentOptionsDDL();
    if (OrderBookingEquipmentPDCDetailIDEncryptParam) {
      setIsLoading(true);
      post(POST_GetOrderBookingEquipmentPDCDetailsByID, {
        OrderBookingEquipmentPDCDetailID: window.atob(
          OrderBookingEquipmentPDCDetailIDEncryptParam
        ),
      }).then((res) => {
        if (res.IsSuccess) {
          setValues({
            ...res,
            PDCReceivedDate: new Date(res.PDCReceivedDate),
            PDCReturnDate: res.PDCReturnDate ? new Date(res.PDCReturnDate) : "",
            PDCReturnRemark: res.PDCRetrunRemarks,
            PDCRecievedByStaff: res.PDCReceivedByEncrypt,
            PDCReturnByStaff: res.PDCReturnedByEncrypt,
            CountryCodeGivenBy: res.PDCGivenByCountryCode,
            CountryCodeReturnTo: res.PDCReturnCountryCode,
          });
          //setViewPDC(res.PDCFilePath);
          if (res.PDCFilePath) {
            setViewPDC(res.PDCFilePath);
            setFieldValue("IsDepositInPDC", true);
          }
          setOrderBookingID(res.OrderBookingIDEncrypt);
        }
      });
      setIsLoading(false);
    }
  }, []);

  const getStaffList = async () => {
    await post(POST_AdminStaffsListDDLByCompanyID, {
      CompanyIDEncrypt: CompanyID,
    })
      .then((res) => {
        if (res.IsSuccess) {
          setStaffOptions(
            res.StaffsList.map((item) => ({
              label: item.StaffName,
              value: item.StaffIDEncrypt,
            }))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const uploadFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      var pattern = /(\.|\/)(jpe?g|png|webp|pdf|svg|gif|ico)$/;
      if (!file.type.match(pattern)) {
        setFile(null);
        setFileKey(Math.random().toString(9).substring(2, 9));
        swal("", "Select valid file format (PDF/Image)", "warning");
      } else {
        setFile(file);
        setFileKey(Math.random().toString(9).substring(2, 9));
        setValidationPDCFile("");
      }
    } else {
      setFile(null);
      setFileKey(Math.random().toString(9).substring(2, 9));
    }
  };

  const handleValidSubmit = (values, { setSubmitting }) => {
    if (orderBookingID === "") {
      swal({
        title: "warning",
        text: "Order Booking No. is Invalid...!",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    if (file === null && viewPDC === null && IsDepositInPDC) {
      setValidationPDCFile("PDC File is required");
      return false;
    }
    setIsLoading(true);
    const data = {
      OrderBookingIDEncrypt: orderBookingID ? orderBookingID : "",
      OrderBookingEquipmentPDCDetailIDEncrypt:
        OrderBookingEquipmentPDCDetailIDEncryptParam
          ? window.atob(OrderBookingEquipmentPDCDetailIDEncryptParam)
          : "",
      PDCReceivedDate: `${moment(values.PDCReceivedDate)
        .format("YYYY-MM-DD")
        .replace(/^\-/, "")} 00:00:00.000`,
      PDCRemarks: values.PDCRemarks,
      PDCReceivedByEncrypt: values.PDCRecievedByStaff,
      IsPDCReturned: IsPDCReturned,
      PDCReturnDate: IsPDCReturned
        ? `${moment(values.PDCReturnDate)
            .format("YYYY-MM-DD")
            .replace(/^\-/, "")} 00:00:00.000`
        : "",
      PDCRetrunRemarks: IsPDCReturned ? values.PDCReturnRemark : "",
      PDCReturnedByEncrypt: IsPDCReturned ? values.PDCReturnByStaff : "",
      EquipmentDepositAmount: values.EquipmentDepositAmount,
      PDCGivenBy: values.PDCGivenBy,
      PDCGivenByMobileNo: values.PDCGivenByMobileNo,
      PDCReturnTo: IsPDCReturned ? values.PDCReturnTo : "",
      PDCReturnToMobileNo: IsPDCReturned ? values.PDCReturnToMobileNo : "",
      PDCGivenByCountryCode: "+" + CountryCodeGivenBy.replace(/\+/g, ""),
      PDCReturnCountryCode: "+" + CountryCodeReturnTo.replace(/\+/g, ""),
      ModeOfPaymentID: values.ModeOfPaymentID,
      EquipmentDepositReturnAmount: values.EquipmentDepositReturnAmount,
      ReturnModeOfPaymentID: values.ReturnModeOfPaymentID,
      CreatedByEncrypt: objLogin?.LoginIDEncrypt,
      MofifiedByEncrypt: objLogin?.LoginIDEncrypt,
    };

    post(
      OrderBookingEquipmentPDCDetailIDEncryptParam
        ? POST_AdminOrderBookingEquipmentPDCDetailsUpdate
        : POST_AdminOrderBookingEquipmentPDCDetailsInsert,
      data
    )
      .then((response) => {
        if (response.IsSuccess) {
          swal({
            title: "Success",
            text: t(response.MessageCode),
            icon: "success",
            button: "OK",
          });
          SaveUploadFile(
            OrderBookingEquipmentPDCDetailIDEncryptParam
              ? window.atob(OrderBookingEquipmentPDCDetailIDEncryptParam)
              : response.EncryptID
          );
          formik.resetForm();
          history.push("/equipment-deposit-list");
        } else {
          swal({
            title: "Error",
            text: t(response.MessageCode),
            icon: "error",
            button: "OK",
          });
          formik.resetForm();
          setFile(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const SaveUploadFile = (oID) => {
    if (file) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("EncryptOrderBookingEquipmentPDCDetailID", oID);
      formData.append("PDCFilePath", file);

      postFormData(POST_AdminOrderBookingEquipmentPDCFileInsert, formData)
        .then((response) => {
          if (response.IsSuccess) {
            setIsLoading(false);
          } else {
            console.log(response);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!PDCReceivedDate) {
      setFieldValue("PDCReceivedDate", "");
    }
  }, [PDCReceivedDate]);

  useEffect(() => {
    if (!PDCReturnDate) {
      setFieldValue("PDCReturnDate", "");
    }
  }, [PDCReturnDate]);

  useEffect(() => {
    if (!OrderBookingNo) {
      formik.resetForm();
      setOrderBookingID("");
    }
  }, [OrderBookingNo]);

  const getEquipmentPDCDetailByOrderBookingNo = async () => {
    if (OrderBookingNo) {
      setIsLoading(true);
      await post(POST_OrderBookingEquipmentPDCSearchByOrderBookingNo, {
        OrderBookingNo: OrderBookingNo,
        CompanyID: CompanyID,
      }).then((res) => {
        if (res.IsSuccess) {
          setFieldValue("PatientName", res.PatientName);
          setFieldValue("PatientNo", res.PatientNo);
          setFieldValue("EquipmentDepositAmount", res.EquipmentDeposit);
          setOrderBookingID(res.OrderBookingIDEncrypt);
        } else {
          swal({
            // title: "warning",
            text: t(res.MessageCode),
            icon: "warning",
            button: "OK",
          });
          formik.resetForm();
        }
      });
      setIsLoading(false);
    } else {
      swal({
        // title: "Error",
        text: "Order Booking No. is required field",
        icon: "warning",
        button: "OK",
      });
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <PageTitle
          activeMenu={
            OrderBookingEquipmentPDCDetailIDEncryptParam
              ? "Edit Equipment Deposit"
              : "Add Equipment Deposit"
          }
          motherMenu={
            <Link to="/equipment-deposit-list">Equipment Deposit</Link>
          }
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={4}>
                          <Field
                            label={"Order Booking No."}
                            name="OrderBookingNo"
                            placeholder={"Search here...."}
                            component={CustomInput}
                            maxLength={15}
                            disabled={
                              OrderBookingEquipmentPDCDetailIDEncryptParam
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {!OrderBookingEquipmentPDCDetailIDEncryptParam ? (
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>&nbsp;</Label>
                              <InputGroup>
                                <button
                                  type="button"
                                  className="btn mt-1 mr-2 btn-xs btn-primary"
                                  onClick={() => {
                                    getEquipmentPDCDetailByOrderBookingNo();
                                  }}
                                >
                                  Search
                                </button>
                              </InputGroup>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      <hr></hr>
                      {orderBookingID ? (
                        <>
                          <Row>
                            <Col lg={3}>
                              <b>Patient No.</b> : &nbsp;&nbsp; {PatientNo}
                            </Col>
                            <Col lg={6}>
                              <b>Patient Name</b> : &nbsp;&nbsp; {PatientName}
                            </Col>
                            <Col lg={3} style={{ textAlign: "right" }}>
                              <button
                                type="button"
                                className="btn mr-2 btn-xs btn-light"
                                onClick={() => {
                                  setOrderDetailModal(true);
                                }}
                              >
                                View Order Detail
                              </button>
                            </Col>
                          </Row>

                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col lg={3}>
                          <Field
                            type="number"
                            label={"Equipment Deposit Amount"}
                            name="EquipmentDepositAmount"
                            placeholder={"Deposit Amount"}
                            component={CustomInput}
                            min={0}
                            onChange={(e) => {
                              setFieldValue(
                                "EquipmentDepositAmount",
                                e.target.value.slice(0, 7)
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Mode of Payment"}
                            name="ModeOfPaymentID"
                            placeholder={"Select Mode of Payment"}
                            component={CustomSelect}
                            options={modeOfPaymentOptions}
                            required
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("ModeOfPaymentID", e?.value);
                                setFieldValue("IsDepositInPDC", e?.isCheque);
                              } else {
                                setFieldValue("ModeOfPaymentID", "");
                                setFieldValue("IsDepositInPDC", false);
                              }
                            }}
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Deposit Received Date"}
                            name="PDCReceivedDate"
                            placeholder={"Select Deposit Received Date"}
                            component={CustomFlatpickr}
                            options={{
                              altInput: true,
                              altFormat: "j-M-Y",
                              dateFormat: "d-m-Y",
                            }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Deposit Recieved By Staff"}
                            name="PDCRecievedByStaff"
                            placeholder={"Deposit Recieved By Staff"}
                            component={CustomSelect}
                            options={staffOptions}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Deposit Given By"}
                            name="PDCGivenBy"
                            placeholder={"Enter Deposit Given By Name"}
                            component={CustomInput}
                            onChange={(e) => {
                              setFieldValue(
                                "PDCGivenBy",
                                e.target.value.replace(/[^A-Za-z ]/g, "")
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col lg={3}>
                          <Field
                            label={"Deposit Given By Mobile No."}
                            name="PDCGivenByMobileNo"
                            placeholder={"Enter Mobile No."}
                            country={countryGivenBy}
                            countryCode={CountryCodeGivenBy}
                            component={CustomPhoneInput}
                            onChange={(value, { countryCode, dialCode }) => {
                              setFieldValue("CountryCodeGivenBy", dialCode);
                              setFieldValue(
                                "PDCGivenByMobileNo",
                                value.substring(dialCode.toString().length)
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col lg={6}>
                          <Field
                            label={"Deposit Remark"}
                            name="PDCRemarks"
                            placeholder={"Enter Deposit Remark"}
                            component={CustomInput}
                            maxLength={200}
                            required
                          />
                        </Col>
                        {IsDepositInPDC ? (
                          <>
                            <Col lg={4}>
                              <Label>
                                Upload PDC{" "}
                                {IsDepositInPDC ? (
                                  <span className="text-danger">*</span>
                                ) : (
                                  ""
                                )}
                              </Label>
                              <div
                                className="input-group mb-3"
                                style={{ zIndex: "0" }}
                              >
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    name="uploadFile"
                                    onChange={(e) => {
                                      uploadFile(e);
                                    }}
                                    key={fileKey || ""}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="uploadFile"
                                  >
                                    {file ? file?.name : "Choose file"}
                                  </label>
                                </div>
                              </div>
                              <span
                                style={{
                                  color: "#ec4561",
                                  marginTop: "0.25rem",
                                  fontSize: "80%",
                                }}
                              >
                                {validationPDCFile}
                              </span>
                            </Col>
                            {viewPDC ? (
                              <Col lg={2}>
                                <Label>&nbsp;</Label>
                                <div className="input-group mb-3">
                                  <Link
                                    className="btn btn-light shadow sharp mr-1"
                                    onClick={() => {
                                      window.open(viewPDC, "_blank");
                                    }}
                                  >
                                    View PDC
                                  </Link>
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={3}>
                          <div
                            className="custom-control custom-checkbox mb-3 mt-1 checkbox-success"
                            style={{ zIndex: 0 }}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="IsPDCReturned"
                              onChange={handleChangeIsPDCReturned}
                              checked={IsPDCReturned}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="IsPDCReturned"
                            >
                              Is Deposit Returned
                            </label>
                          </div>
                        </Col>
                        {IsPDCReturned ? (
                          <>
                            <Col lg={3}>
                              <Field
                                type="number"
                                label={"Deposit Return Amount"}
                                name="EquipmentDepositReturnAmount"
                                placeholder={"Deposit Return Amount"}
                                component={CustomInput}
                                min={0}
                                onChange={(e) => {
                                  setFieldValue(
                                    "EquipmentDepositReturnAmount",
                                    e.target.value.slice(0, 7)
                                  );
                                }}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Return Mode of Payment"}
                                name="ReturnModeOfPaymentID"
                                placeholder={"Select Mode of Payment"}
                                component={CustomSelect}
                                options={modeOfPaymentOptions}
                                required
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Deposit Return Date"}
                                name="PDCReturnDate"
                                placeholder={"Select Deposit Return Date"}
                                component={CustomFlatpickr}
                                options={{
                                  altInput: true,
                                  altFormat: "j-M-Y",
                                  dateFormat: "d-m-Y",
                                }}
                                required={IsPDCReturned}
                              />
                            </Col>
                            <Col lg={6}>
                              <Field
                                label={"Deposit Return Remark"}
                                name="PDCReturnRemark"
                                placeholder={"Enter Deposit Return Remark"}
                                component={CustomInput}
                                maxLength={200}
                                required={IsPDCReturned}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Deposit Return By Staff"}
                                name="PDCReturnByStaff"
                                placeholder={"Deposit Return By Staff"}
                                component={CustomSelect}
                                options={staffOptions}
                                required={IsPDCReturned}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Deposit Return To"}
                                name="PDCReturnTo"
                                placeholder={"Enter Deposit Return To Name"}
                                component={CustomInput}
                                maxLength={200}
                                required={IsPDCReturned}
                                onChange={(e) => {
                                  setFieldValue(
                                    "PDCReturnTo",
                                    e.target.value.replace(/[^A-Za-z ]/g, "")
                                  );
                                }}
                              />
                            </Col>
                            <Col lg={3}>
                              <Field
                                label={"Deposit Return To Mobile No."}
                                name="PDCReturnToMobileNo"
                                placeholder={"Enter Mobile No."}
                                country={countryReturnTo}
                                countryCode={CountryCodeReturnTo}
                                component={CustomPhoneInput}
                                onChange={(
                                  value,
                                  { countryCode, dialCode }
                                ) => {
                                  setFieldValue(
                                    "CountryCodeReturnTo",
                                    dialCode
                                  );
                                  setFieldValue(
                                    "PDCReturnToMobileNo",
                                    value.substring(dialCode.toString().length)
                                  );
                                }}
                                required={IsPDCReturned}
                              />
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                      <hr></hr>
                      <button type="submit" className="btn mr-2 btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal fade"
          show={orderDetailModal}
          onHide={setOrderDetailModal}
          size="xl"
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">Order Detail</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => setOrderDetailModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <ModalOrderBookingDetail
              orderBookingID={orderBookingID}
              setOrderDetailModal={setOrderDetailModal}
            ></ModalOrderBookingDetail>
          </div>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default AddEquipmentPDC;
