import todoReducers from "./Reducers";
import { combineReducers } from "redux";
import CategoryListReducer from "./CategoryListReducer";
import CategoryTypeListReducer from "./CategoryTypeReducer";

const rootReducers = combineReducers({
  todoReducers,
  CategoryListReducer,
  CategoryTypeListReducer,
  
});

export default rootReducers;
